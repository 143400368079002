import * as assestsConstants from '../constants/assestsConstant';

export const getAssestsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_ASSETS_DETAILS_WS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_ASSETS_DETAILS_WS_SUCCESS:
      return {
        loading: false,
        assetsData: action.payload['global.tickers'],
        updatedAt: Date.now(),
      };
    case assestsConstants.GET_ASSETS_DETAILS_WS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getRangoOrderBookReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_RANGO_ORDERBOOK_WS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_RANGO_ORDERBOOK_WS_SUCCESS:
      return {
        loading: false,
        orderBookData: action.payload,
        reRender: {},
      };
    case assestsConstants.GET_RANGO_ORDERBOOK_WS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_NOTIFICATION_DETAILS_WS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_NOTIFICATION_DETAILS_WS_SUCCESS:
      return {
        loading: false,
        notificationData: action.payload,
      };
    case assestsConstants.GET_NOTIFICATION_DETAILS_WS_FAIL:
      return { loading: false, error: action.payload };
    case assestsConstants.GET_NOTIFICATION_DETAILS_WS_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const getCoinsDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_COINS_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_COINS_DETAILS_SUCCESS:
      return { loading: false, coinsDetails: action.payload };
    case assestsConstants.GET_COINS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getDepositDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_DEPOSIT_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_DEPOSIT_DETAILS_SUCCESS:
      return { loading: false, details: action.payload };
    case assestsConstants.GET_DEPOSIT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBannerDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_BANNER_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_BANNER_DETAILS_SUCCESS:
      return { loading: false, bannerDetails: action.payload };
    case assestsConstants.GET_BANNER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_NEWS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_NEWS_SUCCESS:
      return { loading: false, news: action.payload };
    case assestsConstants.GET_NEWS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getChartDataReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_CHART_DATA_REQUEST:
      return { loading: true };
    case assestsConstants.GET_CHART_DATA_SUCCESS:
      return { loading: false, chartData: action.payload };
    case assestsConstants.GET_CHART_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getUserWatchListReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_WATCHLIST_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_WATCHLIST_DETAILS_SUCCESS:
      return { loading: false, response: action.payload };
    case assestsConstants.GET_WATCHLIST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getUserFeatureFlagReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_USER_FLAG_FEATURE_REQUEST:
      return { loading: true };
    case assestsConstants.GET_USER_FLAG_FEATURE_SUCCESS:
      return { loading: false, response: action.payload };
    case assestsConstants.GET_USER_FLAG_FEATURE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const storeUserMsgReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.STORE_NOTIFICATION_SUCCESS:
      return { loading: false, state: action.payload };
    case assestsConstants.STORE_NOTIFICATION_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const showWebSocketErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.SHOW_WEBSOCKET_ERROR:
      return { state: action.payload };
    default:
      return state;
  }
};
