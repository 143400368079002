import axios from 'axios';
import { baseUrl } from '../utils/urls';
import * as walletConstant from '../constants/walletConstant';
import { objToParams } from '../utils/query-helper';

export const cryptoDepositStart = async (
  usertoken,
  amount,
  token_id,
  stark_key,
  deposit_blockchain_hash,
  deposit_blockchain_nonce,
  vault_id
) => {
  // console.log({ token, tx_id });
  const AuthStr = 'JWT '.concat(usertoken);
  try {
    const config = {
      headers: { Authorization: AuthStr },
    };
    const data = await axios.post(
      `${baseUrl}/payment/stark/start/`,
      {
        amount,
        token_id,
        stark_key,
        deposit_blockchain_hash,
        deposit_blockchain_nonce,
        vault_id,
      },
      config
    );
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const crossChainDepositStart = async (
  token,
  amount,
  currency,
  deposit_blockchain_hash,
  deposit_blockchain_nonce,
  network = 'POLYGON'
) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const data = await axios.post(
      `${baseUrl}/deposits/crosschain/create/`,
      {
        amount,
        currency,
        network: network?.toUpperCase(),
        deposit_blockchain_hash: deposit_blockchain_hash,
        deposit_blockchain_nonce: deposit_blockchain_nonce,
      },
      config
    );
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const getVaultId = async (token, coin) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/user/create_vault/`,
      { coin: coin },
      config
    );
    return data;
  } catch (error) {
    throw error.message;
  }
};

export const startWithdrawalDex = async (
  token,
  amount,
  symbol,
  network = 'ETHEREUM'
) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    const data = await axios.post(
      `${baseUrl}/payment/withdrawals/v1/initiate/`,
      { amount: amount, token_id: symbol, network: network },
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const validateWithdrawalDex = async (token, obj) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    const data = await axios.post(
      `${baseUrl}/payment/withdrawals/v1/validate/`,
      obj,
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

//Fast withdrawal

export const getFastVaultId = async (token, coin) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/user/list-fast-withdrawal-vaults/`,
      {
        coin: coin,
      },
      config
    );
    return data;
  } catch (error) {
    throw error.message;
  }
};

export const startFastWithdrawalDex = async (
  token,
  amount,
  symbol,
  estimated_gas_fee,
  force_skip_gas_fee_estimation,
  network = 'ETHEREUM',
  cc_address = null
) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    let body;
    if (estimated_gas_fee) {
      body = {
        amount: amount,
        token_id: symbol,
        estimated_gas_fee,
        force_skip_gas_fee_estimation,
        network: network,
      };
    } else {
      body = {
        amount: amount,
        token_id: symbol,
        network: network,
      };
    }
    if (cc_address) {
      body['cc_address'] = cc_address;
    }
    const data = await axios.post(
      `${baseUrl}/payment/fast-withdrawals/v2/initiate/`,
      body,
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const validateFastWithdrawalDex = async (token, obj) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    const data = await axios.post(
      `${baseUrl}/payment/fast-withdrawals/v2/process/`,
      obj,
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const getWithdrawalVaultId = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: walletConstant.GET_WITHDRAWAL_VAULT_ID_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.get(
      `${baseUrl}/user/list-withdrawal-vaults/`,
      config
    );

    dispatch({
      type: walletConstant.GET_WITHDRAWAL_VAULT_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: walletConstant.GET_WITHDRAWAL_VAULT_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const L2WithdrawalStart = async (token, amount, token_id, hash) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    const data = await axios.post(
      `${baseUrl}/payment/l1-withdrawals/initiate/`,
      {
        amount: amount,
        token_id: token_id,
        l1_withdrawal_blockchain_hash: hash,
      },
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const claimfreeUsdc = async (token) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    const data = await axios.post(
      `${baseUrl}/payment/receive_usdc/`,
      {},
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const getLayerSwapQuote = async (
  token,
  source_network = 'STARKNET',
  token_id = 'usdc'
) => {
  // token_id, source_network
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let reqParams = {
      token_id,
      source_network,
    };

    let queryParams = objToParams(reqParams);

    const { data } = await axios.get(
      `${baseUrl}/payment/layer-swap/deposit/fee/?${queryParams}`,
      config
    );

    return data;
  } catch (error) {
    throw error.response.data?.error?.message;
  }
};

export const handleInitaiteLayerSwapTransaction = async (
  token,
  amount,
  token_id,
  cc_address,
  fee_meta,
  source_network
) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl}/payment/layer-swap/deposit/`,
      {
        amount,
        token_id,
        cc_address,
        fee_meta,
        source_network,
      },
      config
    );

    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const handleInitaiteLayerSwapSave = async (
  token,
  ref_id,
  transaction_hash
) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl}/payment/layer-swap/deposit/save/`,
      {
        ref_id,
        transaction_hash,
      },
      config
    );

    return data;
  } catch (error) {
    // throw error.response && error.response.data.message
    //   ? error.response.data.message
    //   : error.message;
    throw error.response.data?.error?.message;
  }
};
