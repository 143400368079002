import { useAnalytics, useSegment, useViewport } from '../../../../utils/hooks';
import CustomButton from '../../../common/custom-button/custom-button';
import TableCell from '../login-details/table-cell/table-cell';
import referAnimationData from '../../../../assests/json/refer.json';
import Lottie from 'react-lottie';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleSetting } from '../../../../actions/settingAction';
import { images } from '../../../../utils/image/img';
import Image from '../../../common/img/image';

function HelpAndFeedBack({ setOpenFeedBackPopup }) {
  // const { analytics } = useAnalytics();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sendTrackEvent } = useSegment();
  const location = useLocation();

  const HelpAndFeedBackData = [
    // {
    //   name: 'FAQ',
    //   link: 'https://letskrypto.com/faq',
    // },
    {
      name: 'Help and support',
      link: '/help',
    },
    // {
    //   name: 'Contact us',
    //   link: 'mailto:support@brine.fi',
    // },
    // {
    //   name: 'Telegram',
    //   link: 'https://t.me/letskryptoofficial',
    // },
  ];

  const SocialMediaData = [
    // {
    //   name: 'Instagram',
    //   link: 'https://instagram.com/letskrypto',
    // },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/channel/UCUG2L75yvKJBK9QFUaXTdyA',
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/BrineFinance',
    },
    {
      name: 'Discord',
      link: 'https://discord.com/invite/wuPHBr9e9y',
    },
  ];

  const redirectFunc = (link) => {
    window.open(link, '_blank');
  };

  const animationData = {
    loop: false,
    autoplay: true,
    animationData: referAnimationData,
  };

  const handleOpenFeedbackPopup = () => {
    dispatch(toggleSetting());
    setOpenFeedBackPopup(true);
  };

  return (
    <>
      <div className='popup__box'>
        <div
          className='popup__title h6'
          style={{
            marginBottom: 20,
          }}
        >
          Reach out to us
        </div>
        <table className='popup__table'>
          <tbody>
            {HelpAndFeedBackData.map((data, idx) => (
              <TableCell data={data} key={idx} redirectFunc={redirectFunc} />
            ))}
            <TableCell
              data={{
                name: 'Send Feedback',
                link: '',
              }}
              redirectFunc={handleOpenFeedbackPopup}
              disableLink={true}
            />
          </tbody>
        </table>
      </div>
      <div className='popup__box' style={{ marginBottom: 10 }}>
        <div
          className='popup__title h6'
          style={{
            marginBottom: 20,
          }}
        >
          Social Media
        </div>
        <table className='popup__table'>
          <tbody>
            {SocialMediaData.map((data, idx) => (
              <TableCell data={data} key={idx} redirectFunc={redirectFunc} />
            ))}
          </tbody>
        </table>
      </div>
      <div className='refer__earn_setting'>
        <div>
          <h1>Earn while you sleep</h1>
          <p>
            You earn up to 40% of your referees' trading fees and they get 10%
            too!
          </p>
          <CustomButton
            title='Refer and earn'
            onClick={() => {
              history.push({
                pathname: '/referral',
                search: location.search,
              });
              dispatch(toggleSetting());
              sendTrackEvent('buttonClicked', {
                title: 'Refer and earn',
                action: 'Routing to referral page',
              });
            }}
          />
        </div>
        <div>
          {/* <Lottie
            options={animationData}
            height={width < 700 ? 100 : 150}
            width={width < 700 ? 100 : 150}
          /> */}
          <Image src={images.earnWhileSleep} />
        </div>
      </div>
    </>
  );
}

export default HelpAndFeedBack;
