import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNonce,
  storeKey,
  storeUserViaMethod,
  walletLogin,
} from '../../../actions/userAction';
import { toast } from 'react-toastify';
import { useMeta, useSegment, useWalletMethods } from '../../../utils/hooks';
import { ethers } from 'ethers';
import { USER_LOGIN_RESET } from '../../../constants/userConstant';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import { USER_ADD_KEY_CLEANUP } from '../../../constants/userConstant';
import { useWeb3React } from '@web3-react/core';
import { useEagerConnect, useInactiveListener } from '../../../utils/hooks';
import { walletconnect } from '../../../utils/connectors/connectors';
import { WalletLoginInfo } from '../../../components';
import {
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  staticMsg,
} from '../../../utils/constants';
import { walletConnect } from '../../../utils/connectors-v8/walletConnect';

function WalletConnectLogin({
  history,
  setSelectedOption,
  loginState,
  setLoginState,
}) {
  const [openInstructionPopUp, setOpenInstructionPopUp] = useState(false);
  const dispatch = useDispatch();
  const { connectWallet, connectedAccount } = useMeta();
  const [stateLoading, setStateLoading] = useState(false);
  const [clickedVerfiy, setClickedVerfiy] = useState(false);
  const [signedKey, setSignedKey] = useState(null);
  const [connectedSignature, setConnectSignature] = useState(null);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [openNetworkPrompt, setOpenNetworkPrompt] = useState(false);
  const {
    signMessageL1,
    METAMASK_POSSIBLE_ERRORS,
    provider: providerMeta,
    get0X0to0X,
  } = useMeta();
  // const {
  //   userKey: { state },
  // } = useSelector((state) => state);
  const { state } = useSelector((state) => state.userKey);
  const { changeNetwork, walletDeactivate } = useWalletMethods();
  const context = useWeb3React();
  const {
    connector,
    provider: library,
    chainId,
    account,
    activate,
    deactivate,
    connectorName,
    active,
    error: contextError,
  } = context;
  const [activatingConnector, setActivatingConnector] = useState();
  const { analytics, sendTrackEvent } = useSegment();

  useEffect(() => {
    if (
      activatingConnector &&
      activatingConnector === connector &&
      library?.connection?.url !== 'metamask'
    ) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo: userLoginInfo } = userLogin;

  const handleSubmit = (signature) => {
    if (networkCheck()) {
      sendTrackEvent('finalLoginRequestInitiated', {
        walletType: 'wallet_connect',
        address: account,
      });
      dispatch(walletLogin(account, signature, get0X0to0X(signedKey), state));
    }
  };

  const handleLoginWithReferral = () => {
    sendTrackEvent('finalLoginRequestInitiated', {
      walletType: 'wallet_connect',
      address: account,
    });
    dispatch(
      walletLogin(
        account,
        connectedSignature,
        get0X0to0X(signedKey),
        state,
        referralCode
      )
    );
  };

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const getNonceAPIcall = async (account) => {
    sendTrackEvent('loginNonceRequestInitiated', {
      walletType: 'wallet_connect',
      address: account,
    });
    try {
      const data = await getNonce(account);
      sendTrackEvent('loginNonceRequestSuccess', {
        walletType: 'wallet_connect',
        address: account,
      });
      return data;
    } catch (error) {
      sendTrackEvent('loginNonceRequestFailure', {
        walletType: 'wallet_connect',
        address: account,
        error: error,
      });
      toast.error(error ? error : 'Something went wrong');
      setStateLoading(false);
    }
  };

  const handleGetNonce = async () => {
    if (!clickedVerfiy) {
      return toast.error('Click the toggle button to verify you’re human.');
    }

    dispatch({
      type: USER_LOGIN_RESET,
    });

    try {
      setStateLoading(true);
      const data = await getNonceAPIcall(account);

      sendTrackEvent('secondMessageSignRequestInitiated', {
        walletType: 'wallet_connect',
        address: account,
      });

      const sig = await signMsg(data.payload);
      if (sig) {
        sendTrackEvent('secondMessageSignRequestSuccess', {
          walletType: 'wallet_connect',
          address: account,
        });
        handleSubmit(sig);
        setConnectSignature(sig);
        // console.log(sig);
      }
      setStateLoading(false);
    } catch (error) {
      sendTrackEvent('secondMessageSignRequestFailure', {
        walletType: 'wallet_connect',
        address: account,
        error: error,
      });
      toast.error(error ? error : 'Something went wrong');
      setStateLoading(false);
    }
  };

  const signMsg = (msg) => {
    if (!!(library && account)) {
      return library.getSigner(account).signMessage(msg);
    }
  };

  const connectWalletConnect = () => {
    try {
      if (library?.connection?.url !== 'metamask') {
        sendTrackEvent('brineWalletConnectionInitiated', {
          walletType: 'wallet_connect',
        });
        // setActivatingConnector(walletconnect);
        walletConnect.activate().catch((error) => {
          if (error) {
            sendTrackEvent('brineWalletConnectionFailure', {
              error: error,
              walletType: 'wallet_connect',
            });
            if (
              error.name === 'UnsupportedChainIdError' ||
              error.message?.search(/Unsupported chain/i) !== -1
            ) {
              setOpenNetworkPrompt(true);
            }
            // toast.error(
            //   error.name === 'UnsupportedChainIdError'
            //     ? `Oops, You seem to be on the wrong lane, Kindly change your network to ${ALLOWED_NETWORK_NAME}`
            //     : error.message,
            //   {
            //     toastId: 'network__error',
            //   }
            // );
            setActivatingConnector(undefined);
            if (error.message === 'The user rejected the request.') {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          } else {
            sendTrackEvent('brineWalletConnectionSuccess', {
              walletType: 'wallet_connect',
            });
          }
        });
        // activate(walletconnect);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (clickedVerfiy) {
      dispatch({
        type: USER_ADD_KEY_CLEANUP,
      });
      if (!(library && account)) {
        connectWalletConnect();
        setClickedVerfiy(false);
      } else {
        if (networkCheck()) {
          sendTrackEvent('brineWalletConnectionSuccess', {
            walletType: 'wallet_connect',
          });
          sendTrackEvent('walletFirstSignRequestInitiated', {
            walletType: 'wallet_connect',
            address: account,
          });
          signMsg(staticMsg)
            .then((data) => {
              toast.dismiss('meta_first_open');
              dispatch(storeKey(data));
              let stark_private_key =
                getKeyPairFromSignature(data).getPrivate();
              let stark_public_key = getKeyPairFromSignature(data)
                .getPublic()
                .getX();
              // console.log(`Private Key: ${stark_private_key}`);
              // console.log(`Public Key: ${stark_public_key}`);
              const finalKey = ethers.BigNumber.from(
                stark_public_key.toString()
              ).toHexString();

              setSignedKey(finalKey);
              sendTrackEvent('walletFirstSignRequestSuccess', {
                walletType: 'wallet_connect',
                address: account,
              });
            })
            .catch((e) => {
              toast.dismiss('meta_first_open');
              toast.error(METAMASK_POSSIBLE_ERRORS[e.code]?.message);
              setClickedVerfiy(false);
              sendTrackEvent('brineWalletConnectionFailure', {
                walletType: 'wallet_connect',
                error: e,
                address: account,
              });
              sendTrackEvent('walletFirstSignRequestFailure', {
                walletType: 'wallet_connect',
                address: account,
                error: e,
              });
            });
        }
      }
    }
  }, [clickedVerfiy]);

  useEffect(() => {
    if (error && error !== 'Please enter referral code') {
      toast.error(error);
      setStateLoading(false);
      sendTrackEvent('finalLoginRequestFailure', {
        walletType: 'wallet_connect',
        address: account,
        error: error,
      });
    }
    if (error === 'Please enter referral code') {
      setOpenLoginPopup(true);
    }
    if (userLoginInfo && userLoginInfo.token) {
      sendTrackEvent('finalLoginRequestSuccess', {
        walletType: 'wallet_connect',
        address: account,
      });
      history.push('/');
      dispatch(
        storeUserViaMethod({
          via: 'wallet_connect',
          address: account,
        })
      );
      setStateLoading(false);
    }
  }, [userLoginInfo, history, error]);

  useEffect(() => {
    if (library?.connection?.url === 'metamask') {
      // disconnect();
      // providerMeta.disconnect();
      walletDeactivate();
    }
  }, [library]);

  useEffect(() => {
    if (signedKey && clickedVerfiy) {
      handleGetNonce();
    }
  }, [signedKey, clickedVerfiy]);

  const networkCheck = () => {
    if (chainId && chainId !== ALLOWED_NETWORK_ID && !openNetworkPrompt) {
      // Handle unsupported chainId
      // For example, display an error message to the user
      setClickedVerfiy(false);
      setSignedKey(null);
      setOpenNetworkPrompt(true);
      return false;
      // Alternatively, you can automatically disconnect the user's wallet by calling:
      // deactivate();
    } else {
      setOpenNetworkPrompt(false);
      return true;
    }
  };

  useEffect(() => {
    networkCheck();
  }, [chainId]);

  return (
    <WalletLoginInfo
      setLoginState={setLoginState}
      setSelectedOption={setSelectedOption}
      deactivate={deactivate}
      dispatch={dispatch}
      WalletImg={
        'https://test.bcmhunt.com/static/media/wallet-connect.dcbdafe7.ico'
      }
      account={account}
      clickedVerfiy={clickedVerfiy}
      setClickedVerfiy={setClickedVerfiy}
      setOpenNetworkPrompt={setOpenNetworkPrompt}
      openNetworkPrompt={openNetworkPrompt}
      loading={loading}
      stateLoading={stateLoading}
      library={library}
      handleGetNonce={handleGetNonce}
      connectWalletLink={connectWalletConnect}
      setOpenInstructionPopUp={setOpenInstructionPopUp}
      openInstructionPopUp={openInstructionPopUp}
      openLoginPopup={openLoginPopup}
      setOpenLoginPopup={setOpenLoginPopup}
      referralCode={referralCode}
      setReferralCode={setReferralCode}
      handleLoginWithReferral={handleLoginWithReferral}
      signedKey={signedKey}
    />
  );
}

export default withRouter(WalletConnectLogin);
