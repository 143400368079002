import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBalance, useWebSocketData } from '../../utils/hooks';
import { DotRenderer, HelmetContainer } from '../../components';
import ExchangeCellDropdown from './exchange-dropdown/exchange-dropdown';

function ExchangeCell({ selectedtab, setSelectedCell, selectedCell }) {
  const [openselected, setOpenselectedCell] = useState(false);
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { symbol } = useParams();

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { bannerDetails } = useSelector((state) => state.getBanner);

  const {
    getHigh,
    getLow,
    getStatus,
    priceChangePercent,
    lastPrice,
    getAvgPrice,
    getVolume,
  } = useWebSocketData();

  const { formatCurrency, getInfoAboutCoins } = useBalance();

  useEffect(() => {
    if (searchText.length === 0) {
      setResult(bannerDetails?.payload?.markets_with_slash);
    } else {
      const data = bannerDetails?.payload?.markets_with_slash.filter(
        (data) =>
          getInfoAboutCoins(data?.split('/')[0])
            ?.name.toLocaleLowerCase()
            .includes(searchText?.toLocaleLowerCase()) ||
          data.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
      setResult(data);
    }
  }, [searchText, coinsDetails?.convertedArray, bannerDetails]);

  return (
    <div
      className='exchange__top'
      // style={{
      //   display:
      //     selectedtab === 'market__tabs' && width < breakpoint
      //       ? 'inherit'
      //       : 'none',
      // }}
    >
      <HelmetContainer
        title={`${selectedCell.symbol?.toUpperCase()} | ${formatCurrency(
          lastPrice(`${symbol?.replace('-', '')}`)
        )} ${symbol?.split('-')[1].toUpperCase()}  `}
      />
      {coinsDetails?.convertedArray && (
        <ExchangeCellDropdown
          openState={openselected}
          closeState={setOpenselectedCell}
          defaultValue={selectedCell}
          options={bannerDetails?.payload?.markets_with_slash}
          onChange={setSelectedCell}
          result={result}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      )}
      <div className='exchange__cell'>
        <div className='exchange__info'>Last price</div>
        <div className='exchange__price'>
          {selectedCell.symbol === 'inr'
            ? '0'
            : '' +
              formatCurrency(lastPrice(`${symbol?.replace('-', '')}`))}{' '}
          {symbol?.split('-')[1].toUpperCase()}
        </div>
      </div>
      <div className='exchange__cell'>
        <div className='exchange__info'>24h change</div>
        <DotRenderer>
          <div
            style={{ textTransform: 'uppercase' }}
            className={`status ${
              selectedCell.symbol === 'inr'
                ? 'negative'
                : getStatus(`${symbol?.replace('-', '')}`)
            }`}
          >
            {selectedCell.symbol === 'inr'
              ? 'fiat'
              : priceChangePercent(`${symbol?.replace('-', '')}`)}
          </div>
        </DotRenderer>
      </div>

      <div className='exchange__cell'>
        <div className='exchange__info'>24h Low</div>
        <DotRenderer>
          <div className='exchange__price'>
            {selectedCell.symbol === 'inr'
              ? '0'
              : '' + formatCurrency(getLow(`${symbol?.replace('-', '')}`))}{' '}
            {symbol?.split('-')[1]?.toUpperCase()}
          </div>
        </DotRenderer>
      </div>
      <div className='exchange__cell'>
        <div className='exchange__info'>24h High</div>
        <DotRenderer>
          <div className='exchange__price'>
            {selectedCell.symbol === 'inr'
              ? '0'
              : '' +
                formatCurrency(getHigh(`${symbol?.replace('-', '')}`))}{' '}
            {symbol?.split('-')[1]?.toUpperCase()}
          </div>
        </DotRenderer>
      </div>
      <div className='exchange__cell'>
        <div className='exchange__info'>Average Price</div>
        <DotRenderer>
          <div className='exchange__price'>
            {selectedCell.symbol === 'inr'
              ? '0'
              : '' +
                formatCurrency(getAvgPrice(`${symbol?.replace('-', '')}`))}{' '}
            {symbol?.split('-')[1]?.toUpperCase()}
          </div>
        </DotRenderer>
      </div>
      <div className='exchange__cell'>
        <div className='exchange__info'>24h Volume</div>
        <DotRenderer>
          <div className='exchange__price'>
            {formatCurrency(getVolume(`${symbol?.replace('-', '')}`))}{' '}
            {symbol?.split('-')[1]?.toUpperCase()}
          </div>
        </DotRenderer>
      </div>
    </div>
  );
}

export default ExchangeCell;
