import {
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  ENVIRONMENT,
} from './constants';

export const url = 'dev';

export const prodUrl = 'https://api.brine.fi/main';
export const devUrl = 'https://api-testnet.brine.fi/main';
export const postalUrl = 'https://api.postalpincode.in/pincode/';
export const devWsUrl = 'wss://api-testnet.brine.fi';
export const prodWsUrl = 'wss://api.brine.fi';
export const newsUrl = 'https://api-testnet.brine.fi';

export const googleAuthKey = process.env.REACT_APP_GOOGLE_AUTH_KEY;
export const analyticsKey = process.env.REACT_APP_ANALYTICS_KEY;
export const payTmKey = process.env.REACT_APP_PAYTM_KEY;
export const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
export const dnsKey = process.env.REACT_APP_DSN_KEY;

export const baseUrl =
  process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? prodUrl : devUrl;
export const webSocketUrl =
  process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? prodWsUrl : devWsUrl;
export const etherscanDomain =
  process.env.REACT_APP_ENVIRONMENT === 'mainnet'
    ? 'https://etherscan.io/'
    : `https://${ALLOWED_NETWORK_NAME?.toLowerCase()}.etherscan.io/`;
export const starkscanDomain =
  ENVIRONMENT === 'testnet'
    ? 'https://testnet.starkscan.co/'
    : 'https://starkscan.co/';
export const scrollscanDomain =
  ENVIRONMENT === 'testnet'
    ? 'https://sepolia.scrollscan.dev/'
    : 'https://scrollscan.com/';

export const polygonscanDomain =
  process.env.REACT_APP_ENVIRONMENT === 'mainnet'
    ? 'https://polygonscan.com/'
    : `https://mumbai.polygonscan.com/`;
export const optimismscanDomain =
  process.env.REACT_APP_ENVIRONMENT === 'mainnet'
    ? 'https://optimistic.etherscan.io/'
    : `https://goerli-optimism.etherscan.io/`;

export const networkName =
  process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 'mainnet' : 'testnet';

// api-testnet.brine.finance -> staging url
