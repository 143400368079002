import React from 'react';

function ProfileFormInput({
  label,
  isFileInput,
  selectedValue,
  addClassName,
  ...otherProps
}) {
  // //console.log(selectedValue, 'vvvvvvvv');
  return (
    <>
      {!isFileInput ? (
        <div className='popup__field'>
          <div className='popup__label'>{label}</div>
          <div className='popup__wrap'>
            <input
              className={`popup__input ${addClassName ? addClassName : ''}`}
              {...otherProps}
            />
          </div>
        </div>
      ) : (
        <>
          <div className='popup__field'>
            <div className='popup__label'>{label}</div>
            <div className='popup__wrap'>
              <input
                className='file_input__document'
                type='file'
                {...otherProps}
              />
            </div>
            <div className='popup__input'></div>
            <p style={{ textAlign: 'center', fontSize: 10, marginTop: -39 }}>
              {selectedValue
                ? selectedValue.name
                : 'Click to upload the document'}
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default ProfileFormInput;
