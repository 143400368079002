import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { initializeConnector } from '@web3-react/core';

import { URLS } from '../web3-react-utils/chains';
import { ENVIRONMENT } from '../constants';

export const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: ENVIRONMENT === 'mainnet' ? URLS[1][0] : URLS[5][0],
        appName: 'Brine.Fi',
      },
    })
);
