import axios from 'axios';
import * as kycConstants from '../constants/kycConstant';
import { baseUrl } from '../utils/urls';

export const networkCheck = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: kycConstants.NETWORK_CHECK_KYC_REQUEST,
    });

    const { data } = await axios.get(`${baseUrl}/user/account/`, config);

    dispatch({
      type: kycConstants.NETWORK_CHECK_KYC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: kycConstants.NETWORK_CHECK_KYC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
