import React, { useEffect } from 'react';
import Popup from '../popup/popup';
import Image from '../img/image';
import { images } from '../../../utils/image/img';
import {
  useAccount,
  useConnectors,
  useSignTypedData,
  useNetwork,
} from '@starknet-react/core';
import { truncate } from '../../../utils/string-methods';
import { disconnect } from 'process';
import { useStarknet } from '../../../utils/hooks';
import { useWeb3React } from '@web3-react/core';
import CustomButton from '../custom-button/custom-button';
import { toast } from 'react-toastify';
import {
  ALLOWED_STARKNET_NETWORK_ID,
  ENVIRONMENT,
} from '../../../utils/constants';

function StarknetConnectPopup({ handleCallBackConnect, setRequestedNetwork }) {
  const { connect, connectors, disconnect, available } = useConnectors();
  const { account, address, status } = useAccount();
  const { chain } = useNetwork();

  function capitalizeFLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // const handleSign = async () => {
  //   try {
  //     let msg = await signTypedData(message);
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  // const handleSendTransaction = async () => {
  //   try {
  //     let res = await sendTransaction();
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  // let layerSwap = `https://layerswap.io/app/?
  //  addressSource=Rjh+27z9Mv+GYV50+6hfgeZZssyvkXA+in2M2AhZPJGC8DTibxUkUyIp4HnryG5d9vewXabN+cAeNLbbaUPTpg
  //  &to=BRINE_MAINNET
  //  &from=STARKNET_MAINNET
  //  &destAddress=${ethAddress}
  //  &amount=27
  //  &asset=USDC
  //  &actionButtonText=Deposit`;

  // // console.log(`https://layerswap.io/app/?
  // // addressSource=Rjh+27z9Mv+GYV50+6hfgeZZssyvkXA+in2M2AhZPJGC8DTibxUkUyIp4HnryG5d9vewXabN+cAeNLbbaUPTpg
  // // &to=BRINE_MAINNET
  // // &from=STARKNET_MAINNET
  // // &destAddress=${ethAddress}
  // // &amount=27
  // // &asset=USDC
  // // &actionButtonText=Deposit`);

  // // console.log({ layerSwap });

  // // let layerSwap = `https://layerswap.io/app/?addressSource=Rjh+27z9Mv+GYV50+6hfgeZZssyvkXA+in2M2AhZPJGC8DTibxUkUyIp4HnryG5d9vewXabN+cAeNLbbaUPTpg&to=BRINE_MAINNET&from=STARKNET_MAINNET&destAddress=${ethAddress}&amount=27&asset=USDC&actionButtonText=Deposit`;

  // let baseUrl = 'https://layerswap.io/app/';

  // // Define your query parameters as an object
  // var queryParams = {
  //   addressSource:
  //     'Rjh+27z9Mv+GYV50+6hfgeZZssyvkXA+in2M2AhZPJGC8DTibxUkUyIp4HnryG5d9vewXabN+cAeNLbbaUPTpg',
  //   to: 'BRINE_MAINNET',
  //   from: 'STARKNET_MAINNET',
  //   destAddress: ethAddress,
  //   amount: 5,
  //   asset: 'USDC',
  //   actionButtonText: 'Deposit',
  //   hideAddress: true,
  //   lockAddress: true,
  //   lockFrom: true,
  //   hideTo: true,
  //   lockTo: true,
  //   lockAsset: true,
  // };

  // // Convert the query parameters object to a query string
  // let queryString = Object.keys(queryParams)
  //   .map(
  //     (key) =>
  //       encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
  //   )
  //   .join('&');

  // // Combine the base URL and query string to create the final URL
  // let finalUrl = baseUrl + '?' + queryString;

  useEffect(() => {
    if (chain?.id) {
      if (chain?.id !== ALLOWED_STARKNET_NETWORK_ID) {
        toast.error(
          `Please change your starknet wallet network to ${ENVIRONMENT} to continue.`,
          {
            toastId: 'switch__starknet_toast',
          }
        );
        setRequestedNetwork('ethereum');
        disconnect();
      }
    }
  }, [chain?.id]);

  useEffect(() => {
    if (chain?.id && account && status) {
      if (chain?.id === ALLOWED_STARKNET_NETWORK_ID) {
        handleCallBackConnect();
      }
    }
  }, [account, status, chain?.id]);

  const handleDisconnect = () => {
    try {
      disconnect();
      setRequestedNetwork('ethereum');
      handleCallBackConnect();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleConnect = (connector) => {
    try {
      connect(connector);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      {status !== 'connected' ? (
        <>
          <div className='h6' style={{ marginBottom: 38 }}>
            Connect your Starknet Wallet 🚀
          </div>
          <Image src={images.starkWareLogo} style={{ maxWidth: '145px' }} />
          <>
            {available?.length === 0 ? (
              <p style={{ marginBottom: 10, marginTop: 10, fontSize: 13 }}>
                To proceed with your deposit, please install the{' '}
                <a
                  href='https://www.argent.xyz/argent-x/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ color: '#8173E0' }}
                >
                  ArgentX
                </a>{' '}
                or{' '}
                <a
                  href='https://braavos.app/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ color: '#8173E0' }}
                >
                  Braavos
                </a>{' '}
                wallets. Please refresh once you have installed any extension.
              </p>
            ) : (
              <>
                <p style={{ fontSize: 12, color: '#808191', marginBottom: 20 }}>
                  Pick a wallet to continue.
                </p>
                {available?.map((connector) => {
                  return (
                    <button
                      className={`card__btn btn btn_orange disconnect_btn`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      key={connector.id}
                      onClick={() => handleConnect(connector)}
                    >
                      <div>
                        <Image
                          src={images.starknetWalletLogo[connector.id]}
                          style={{ width: 20, height: 20, marginBottom: 0 }}
                        />
                      </div>
                      <div>{capitalizeFLetter(connector.id)}</div>
                    </button>
                  );
                })}
              </>
            )}
          </>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src={images.starkWareLogo} style={{ maxWidth: '145px' }} />
          <p>Connected account - {truncate(address, 20)}</p>
          {/* <p>Network: {chain.name}</p> */}
          {/* <button
            className={`card__btn btn btn_orange disconnect_btn`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
            onClick={handleSign}
          >
            <div>Sign Msg</div>
          </button> */}
          {/* 
          <button
            className={`card__btn btn btn_orange disconnect_btn`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: 15,
            }}
            onClick={handleSendTransaction}
          >
            <div>Send Transaction</div>
          </button> */}

          <button
            className={`card__btn btn btn_orange disconnect_btn`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
            onClick={handleDisconnect}
          >
            <div>Disconnect</div>
          </button>
        </div>
      )}
      {/* {ethAddress?.length && (
        <iframe style={{ height: 500, width: '100%' }} src={finalUrl}></iframe>
      )} */}
      {/* <CustomButton
        title={'Redirect'}
        onClick={() => window.open(finalUrl, '_blank')}
      /> */}
    </>
  );
}

export default StarknetConnectPopup;
