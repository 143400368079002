import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function FormInput({
  label,
  value,
  active,
  showInformativeIcon,
  focus,
  ...otherProps
}) {
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);
  // const {
  //   theme: { isdark },
  // } = useSelector((state) => state);

  const { isdark } = useSelector((state) => state.theme);

  useEffect(() => {
    if (
      (document.hasFocus() && ref.current.contains(document.activeElement)) ||
      focus
    ) {
      setFocus(true);
    }
  }, [focus]);

  return (
    <div
      className={`login__field field js-field " ${
        hasFocus || value?.length > 0 || active ? 'active' : ''
      }`}
    >
      <div className='field__wrap' style={{ position: 'relative' }}>
        <div className='field__label'>{label}</div>
        <input
          className='field__input js-field-input'
          ref={ref}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          {...otherProps}
        />
        {showInformativeIcon && (
          <span
            data-tip='For updating you on important announcements, events & platform related updates.'
            data-for='about_email'
            style={{
              position: 'absolute',
              right: 20,
              top: 30,
              cursor: 'pointer',
              backgroundColor: !isdark
                ? 'rgba(228, 228, 228, 0.3)'
                : 'rgba(228, 228, 228, 0.03)',
            }}
          >
            <svg
              className='icon icon-label'
              width='100%'
              height='100%'
              viewBox='0 0 1097 1024'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                display: 'inline-block',
                fill: !isdark ? '#000' : '#fff',
              }}
            >
              <path d='M759.932 24.396h-422.714c-165.672 0-276.236 118.618-276.236 288.475v398.288c0 170.015 110.269 288.478 276.236 288.478h422.67c166.012 0 276.334-118.462 276.334-288.478v-398.288c0-170.008-110.314-288.475-276.29-288.475zM337.219 97.538h422.714c124.372 0 203.147 84.601 203.147 215.333v398.288c0 130.738-78.782 215.335-203.191 215.335h-422.67c-124.355 0-203.094-84.59-203.094-215.335v-398.288c0-130.566 79.011-215.333 203.094-215.333zM548.566 290.348c20.198 0 36.571 16.374 36.571 36.571 0 18.515-13.758 33.816-31.609 36.238l-5.45 0.334c-20.198 0-36.571-16.374-36.571-36.571 0-18.515 13.758-33.816 31.609-36.238l5.45-0.334zM548.079 445.058c18.515 0 33.815 13.759 36.237 31.609l0.334 4.962v215.479c0 20.198-16.374 36.573-36.571 36.573-18.515 0-33.816-13.76-36.238-31.61l-0.334-4.963v-215.479c0-20.198 16.373-36.571 36.571-36.571z'></path>
            </svg>
            <ReactTooltip
              place='right'
              id='about_email'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles email_details'
            />
          </span>
        )}
      </div>
    </div>
  );
}

export default FormInput;
