import React from 'react';
import { motion } from 'framer-motion';
import { useViewport } from '../../../utils/hooks';

function MotionContainer({ children }) {
  const { width } = useViewport();
  return (
    <>
      {width <= 750 ? (
        <>{children}</>
      ) : (
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {children}
        </motion.div>
      )}
    </>
  );
}

export default MotionContainer;
