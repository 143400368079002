import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesCell from './activities-cell/activities-cell';
import {
  getWithdrawsData,
  getOrderData,
  getTradesData,
  getDEXDepositsData,
  getDEXFastWithdrawData,
} from '../../../actions/historyAction';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getQueryParams } from '../../../utils/query-helper';
import { useViewport, useAnalytics, useSegment } from '../../../utils/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { CANCEL_ORDER_CLEANUP } from '../../../constants/exchangeConstant';
import { HISTORY_PAGE_RESET } from '../../../constants/historyConstant';
import {
  getCoinsDetails,
  getBannerDetails,
} from '../../../actions/assestsAction';
import {
  CustomButton,
  NotFoundedAnimation,
  LoadingBtn,
  SkeletonContainer,
} from '../../../components';

function ActivitiesHistory({
  handleRemoveExtraQueryParams,
  openState,
  handleSideBarToggle,
  sideBardata,
  selectedFilter,
  setSideBarData,
  setSelectedFilter,
  setOpenSideBar,
  sortingOptions,
  refreshHistoryData,
  setRefreshHistoryData,
  withdrawsData,
  setWithDrawsData,
  tradesData,
  setTradesData,
  depositsData,
  ordersData,
  setOrdersData,
  fastWithdrawsData,
  setFastWithdrawData,
  isLoading,
  setIsLoading,
  orderPageCount,
  setOrderPageCount,
  fastWithdrawPageCount,
  setFastWithdrawCount,
  tradePageCount,
  setTradePageCount,
  withdrawsPageCount,
  setWithdrawsPageCount,
  depositsPageCount,
  setDepositsPageCount,
  setDepositsData,
  filterByOptions,
  setFilterByOptions,
  checkedValue,
  setCheckedValue,
  marketPair,
  setMarketPair,
  pendingOrderFilter,
  setPendingOrderFilter,
  orderTypeFilter,
  setOrderTypeFilter,
  initialState,
  currentMarketPair,
  depositNetworkFilter,
  disableRefetch,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const {
    loading,
    error,
    response: orderResponse,
  } = useSelector((state) => state.orderData);

  const {
    loading: tradeLoading,
    error: tradeError,
    response: tradeResponse,
  } = useSelector((state) => state.tradeData);

  const {
    loading: depositLoading,
    error: depositError,
    response: depositResponse,
  } = useSelector((state) => state.dexDepositData);

  const {
    loading: fastWithDrawLoading,
    error: fastWithDrawError,
    response: fastWithDrawResponse,
  } = useSelector((state) => state.fastWithDrawData);

  const {
    loading: withDrawLoading,
    error: withDrawError,
    response: withDrawResponse,
  } = useSelector((state) => state.withDrawData);

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { bannerDetails } = useSelector((state) => state.getBanner);

  const {
    response: cancelOrderResponse,
    loading: cancelOrderLoading,
    error: cancelOrderError,
  } = useSelector((state) => state.cancelOrder);

  const [selectedFilterData, setSelectedFilterData] = useState(null);
  const [selectedFilterDataError, setSelectedFilterDataError] = useState(null);
  const [selectedFilterDataLoading, setSelectedFilterDataLoading] =
    useState(null);
  const { width } = useViewport();
  const { sendTrackEvent } = useSegment();
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const [btnDisable, setBtnDisable] = useState(false);
  // const { analytics } = useAnalytics();
  // const { loading, error, response: orderResponse } = orderData;
  // const {
  //   loading: tradeLoading,
  //   error: tradeError,
  //   response: tradeResponse,
  // } = tradeData;

  // const {
  //   loading: depositLoading,
  //   error: depositError,
  //   response: depositResponse,
  // } = dexDepositData;

  // const {
  //   loading: withDrawLoading,
  //   error: withDrawError,
  //   response: withDrawResponse,
  // } = withDrawData;

  // const {
  //   loading: fastWithDrawLoading,
  //   error: fastWithDrawError,
  //   response: fastWithDrawResponse,
  // } = fastWithDrawData;

  const handleLoadMore = () => {
    dispatch({
      type: CANCEL_ORDER_CLEANUP,
      payload: null,
    });

    // analytics?.track('User clicked load more!', {
    //   from: 'History Page',
    //   uid: userInfo?.payload?.uid,
    //   keyword: selectedFilter.value,
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });

    if (selectedFilter.value === 'Withdraws') {
      dispatch(getWithdrawsData(withdrawsPageCount + 1, false));
      if (!withDrawResponse?.payload?.next) {
        setWithdrawsPageCount(0);
        setBtnDisable(true);
      } else {
        setWithdrawsPageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'Deposits') {
      dispatch(getDEXDepositsData(depositsPageCount + 1, depositNetworkFilter));
      if (!depositResponse?.payload?.next) {
        setDepositsPageCount(0);
        setBtnDisable(true);
      } else {
        setDepositsPageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'Trades') {
      dispatch(getTradesData(tradePageCount + 1, currentMarketPair));
      if (tradeResponse?.payload.length < 10) {
        setTradePageCount(0);
        setBtnDisable(true);
      } else {
        setTradePageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'Orders') {
      dispatch(
        getOrderData(
          orderPageCount + 1,
          pendingOrderFilter,
          currentMarketPair,
          orderTypeFilter
        )
      );
      if (orderResponse?.payload.length < 10) {
        setOrderPageCount(0);
        setBtnDisable(true);
      } else {
        setOrderPageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'fast-withdraws') {
      dispatch(
        getDEXFastWithdrawData(fastWithdrawPageCount + 1, depositNetworkFilter)
      );
      if (!fastWithDrawResponse?.payload?.next) {
        setFastWithdrawCount(0);
        setBtnDisable(true);
      } else {
        setFastWithdrawCount((pre) => pre + 1);
      }
    }
  };

  useEffect(() => {
    if (!userInfo?.token) {
      history.push('/');
    }
  }, [userInfo?.token]);

  // const handleRefreshToast = (selectedFilter, selectedFilterData) => {
  //   if (selectedFilter?.value == 'Deposits' && selectedFilterData) {
  //     selectedFilterData?.slice(0, 10).forEach((item) => {
  //       if (
  //         (item?.blockchain_deposit_status &&
  //           item?.blockchain_deposit_status !== 'Success') ||
  //         (item?.brine_deposit_status &&
  //           item?.brine_deposit_status !== 'Success')
  //       ) {
  //         return toast.loading(<Msg1 />, {
  //           toastId: 'history_refresh',
  //           closeOnClick: false,
  //           draggable: false,
  //           isLoading: false,
  //         });
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (
      selectedFilter.value === 'Withdraws' &&
      withDrawResponse?.payload &&
      withDrawResponse?.status !== 'error'
    ) {
      const data = [...withdrawsData, ...withDrawResponse?.payload?.results];
      const uniqData = _.uniqBy(data, 'id');
      setWithDrawsData(uniqData);
      setSelectedFilterDataError(withDrawError);
      setSelectedFilterData(uniqData);
      setSelectedFilterDataLoading(withDrawLoading);
      setIsLoading(false);
    } else if (
      selectedFilter.value === 'Deposits' &&
      depositResponse?.payload &&
      depositResponse?.status !== 'error'
    ) {
      const data = [...depositsData, ...depositResponse?.payload?.results];
      const uniqData = _.uniqBy(data, 'deposit_blockchain_hash');
      setDepositsData(uniqData);
      setSelectedFilterDataError(depositError);
      setSelectedFilterData(uniqData);
      setSelectedFilterDataLoading(depositLoading);
      setIsLoading(false);
    } else if (
      selectedFilter.value === 'Trades' &&
      tradeResponse?.payload &&
      tradeResponse?.status !== 'error'
    ) {
      const data = [...tradesData, ...tradeResponse?.payload];
      const uniqData = _.uniqBy(data, 'id');
      setTradesData(uniqData);
      setSelectedFilterDataError(tradeError);
      setSelectedFilterData(uniqData);
      setSelectedFilterDataLoading(tradeLoading);
      setIsLoading(false);
    } else if (
      selectedFilter.value === 'Orders' &&
      orderResponse?.payload &&
      orderResponse?.status !== 'error'
    ) {
      const data = cancelOrderResponse
        ? orderResponse?.payload
        : [...ordersData, ...orderResponse?.payload];
      const uniqData = _.uniqBy(data, 'id');
      setSelectedFilterDataError(error);
      setSelectedFilterDataLoading(loading);
      setOrdersData(uniqData);
      setSelectedFilterData(uniqData);
      setIsLoading(false);
    } else if (
      selectedFilter.value === 'fast-withdraws' &&
      fastWithDrawResponse?.payload &&
      fastWithDrawResponse?.status !== 'error'
    ) {
      const data = [
        ...fastWithdrawsData,
        ...fastWithDrawResponse?.payload?.results,
      ];
      const uniqData = _.uniqBy(data, 'id');
      setSelectedFilterDataError(error);
      setSelectedFilterDataLoading(loading);
      setFastWithdrawData(uniqData);
      setSelectedFilterData(uniqData);
      setIsLoading(false);
    }
  }, [
    loading,
    withDrawLoading,
    tradeLoading,
    depositLoading,
    selectedFilter,
    withDrawResponse,
    tradeResponse,
    orderResponse,
    depositResponse,
    fastWithDrawResponse,
  ]);

  const cleanUpState = () => {
    setDepositsPageCount(1);
    setOrderPageCount(1);
    setTradePageCount(1);
    setWithdrawsPageCount(1);
    setFastWithdrawCount(1);
    setDepositsData([]);
    setOrdersData([]);
    setTradesData([]);
    setWithDrawsData([]);
    setFastWithdrawData([]);
    setBtnDisable(false);
  };

  useEffect(() => {
    cleanUpState();
  }, [
    dispatch,
    selectedFilter,
    currentMarketPair,
    pendingOrderFilter,
    orderTypeFilter,
    depositNetworkFilter,
  ]);

  useEffect(() => {
    if (disableRefetch) {
      setSelectedFilterData([]);
      cleanUpState();
      setIsLoading(true);
    }
  }, [disableRefetch]);

  // useEffect(() => {
  //   if (depositError || withDrawError || tradeError || error) {
  //     if (
  //       depositError == 'Unknown Error Occurred' ||
  //       withDrawError == 'Unknown Error Occurred' ||
  //       tradeError == 'Unknown Error Occurred' ||
  //       error == 'Unknown Error Occurred'
  //     )
  //       toast.info(
  //         'Complete your KYC and 2FA to start investing and get FREE crypto rewards 🎉',
  //         {
  //           toastId: 'history',
  //         }
  //       );
  //   } else {
  //     toast.error(depositError || withDrawError || tradeError || error);
  //   }
  // }, [depositError, withDrawError, tradeError, error]);

  useEffect(() => {
    setSelectedFilterData([]);
    setIsLoading(true);
  }, [selectedFilter]);

  useEffect(() => {
    const data = getQueryParams();
    if (data.tab == 'deposits' && data.value && depositsData.length != 0) {
      const sidebardata = depositsData?.find((item) => {
        return item.deposit_blockchain_hash === data.value;
      });
      if (sidebardata) {
        setSideBarData(sidebardata);
        setOpenSideBar(true);
      }
    }
    if (data.tab == 'withdrawals' && data.value && withdrawsData.length != 0) {
      const sidebardata = withdrawsData?.find((item) => {
        return item.id == data.value;
      });

      if (sidebardata) {
        setSideBarData(sidebardata);
        setOpenSideBar(true);
      }
    }
    if (
      data.tab == 'fast-withdrawals' &&
      data.value &&
      fastWithdrawsData.length != 0
    ) {
      const sidebardata = fastWithdrawsData?.find((item) => {
        return item.id == data.value;
      });

      if (sidebardata) {
        setSideBarData(sidebardata);
        setOpenSideBar(true);
      }
    }
  }, [location, history, depositsData, withdrawsData, fastWithdrawsData]);

  useEffect(() => {
    const data = getQueryParams();
    if (data.clear == 'true') {
      setOpenSideBar(false);
    }
  }, [location]);

  // useEffect(() => {
  //   if (refreshHistoryData) {
  //     cleanUpState();
  //     console.log('WORKING!');
  //     setRefreshHistoryData(false);
  //   }
  // }, [refreshHistoryData]);

  useEffect(() => {
    dispatch({ type: HISTORY_PAGE_RESET });
    cleanUpState();
    if (selectedFilter?.value == 'Orders') {
      dispatch(getOrderData(1));
    }
    if (selectedFilter?.value == 'Trades') {
      dispatch(getTradesData(1));
    }
    if (selectedFilter?.value == 'Withdraws') {
      dispatch(getWithdrawsData());
    }
    if (selectedFilter?.value == 'fast-withdraws') {
      dispatch(getDEXFastWithdrawData(1));
    }
    if (selectedFilter?.value == 'Deposits') {
      dispatch(getDEXDepositsData(1));
    }
    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }
    if (!bannerDetails) {
      dispatch(getBannerDetails());
    }
  }, [selectedFilter]);

  useEffect(() => {
    dispatch({ type: HISTORY_PAGE_RESET });
    cleanUpState();

    if (selectedFilter?.value == 'Orders') {
      dispatch(
        getOrderData(1, pendingOrderFilter, currentMarketPair, orderTypeFilter)
      );
    }

    if (selectedFilter?.value == 'Trades') {
      dispatch(getTradesData(1, currentMarketPair));
    }

    if (selectedFilter?.value == 'Withdraws') {
      dispatch(getWithdrawsData(1));
    }

    if (selectedFilter?.value == 'fast-withdraws') {
      dispatch(getDEXFastWithdrawData(1));
    }

    if (selectedFilter?.value == 'Deposits') {
      dispatch(getDEXDepositsData(1));
    }
  }, [cancelOrderResponse]);

  const toastBtnStyle = {
    padding: '5px 10px',
    background: '#6c5dd3',
    color: '#fff',
    borderRadius: 5,
    fontSize: '12px',
    fontFamily: 'Poppins',
  };

  // const Msg1 = ({ closeToast }) => (
  //   <div>
  //     Refresh to fetch updated data
  //     <br />
  //     <div style={{ display: 'flex', flexDirection: 'row' }}>
  //       <button
  //         style={toastBtnStyle}
  //         onClick={() => {
  //           setOpenSideBar(false);
  //           handleRemoveExtraQueryParams();
  //           window.location.reload();
  //           localStorage.setItem(
  //             'history__section',
  //             JSON.stringify(selectedFilter)
  //           );
  //           localStorage.setItem('set_throttle', true);
  //           closeToast();
  //         }}
  //       >
  //         Refresh
  //       </button>{' '}
  //       <button
  //         style={{
  //           ...toastBtnStyle,
  //           marginLeft: 5,
  //           backgroundColor: 'rgb(247 242 242)',
  //           color: '#000',
  //         }}
  //         onClick={() => {
  //           closeToast();
  //         }}
  //       >
  //         Close
  //       </button>
  //     </div>
  //   </div>
  // );

  // useEffect(() => {
  //   let refreshTimer = setInterval(() => {
  //     handleRefreshToast(selectedFilter, selectedFilterData);
  //   }, 60000);
  //   return () => {
  //     clearInterval(refreshTimer);
  //   };
  // }, [selectedFilter, selectedFilterData]);

  useEffect(() => {
    if (selectedFilter) {
      sendTrackEvent('tabChangeDetected', { tab: selectedFilter?.label });
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedFilter && Object(sideBardata)?.keys?.length) {
      sendTrackEvent('listItemClicked', { selectedTab: selectedFilter?.label });
    }
  }, [selectedFilter, sideBardata]);

  return (
    <>
      {(loading ||
        withDrawLoading ||
        depositLoading ||
        tradeLoading ||
        coinsLoading ||
        fastWithDrawLoading) &&
      selectedFilterData?.length === 0 ? (
        <div>
          {Array.from(Array(7).keys()).map((item) => (
            <SkeletonContainer
              key={item}
              style={{
                width: '100%',
                height: 60,
                borderRadius: 10,
                marginTop: 20,
              }}
            />
          ))}
        </div>
      ) : (
        <>
          <>
            {(selectedFilterData?.length === 0 || selectedFilterDataError) &&
            !selectedFilterDataLoading &&
            !isLoading ? (
              <div
                className='h3'
                style={{
                  textAlign: 'center',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <NotFoundedAnimation
                  text={`Oops, No ${selectedFilter?.label} Found`}
                  size={width < 750 ? 'sm' : 'lg'}
                />
              </div>
            ) : (
              <>
                {!selectedFilterDataError && (
                  <>
                    <div className='activity__stage h6 mobile-hide'>
                      History
                    </div>
                    <div className='activity__table'>
                      <div className='activity__row activity__row_head'>
                        <div className='activity__cell'></div>
                        <div className='activity__cell'>TYPE</div>
                        <div className='activity__cell'>DATE</div>
                        {selectedFilter.value !== 'Trades' &&
                          selectedFilter.value !== 'Orders' && (
                            <>
                              <div className='activity__cell'>AMOUNT </div>
                              {selectedFilter.value !== 'Deposits' &&
                                selectedFilter.value !== 'Withdraws' &&
                                selectedFilter.value !== 'fast-withdraws' && (
                                  <div className='activity__cell'>
                                    ADDRESS / TRANSACTION ID
                                  </div>
                                )}
                              {(selectedFilter.value == 'Deposits' ||
                                selectedFilter.value == 'Withdraws' ||
                                selectedFilter.value == 'fast-withdraws') && (
                                <div className='activity__cell'>Status</div>
                              )}
                            </>
                          )}
                        {selectedFilter.value === 'Trades' && (
                          <>
                            <div className='activity__cell'> PRICE</div>
                            <div className='activity__cell'>TOTAL</div>
                          </>
                        )}
                        {selectedFilter.value === 'Orders' && (
                          <>
                            <div className='activity__cell'>PRICE</div>
                            <div className='activity__cell'>AMOUNT</div>
                            <div className='activity__cell'>EXECUTED </div>
                          </>
                        )}
                      </div>
                      <>
                        {selectedFilterData &&
                          selectedFilterData.map((data, idx) => (
                            <ActivitiesCell
                              data={data}
                              sideBardata={sideBardata}
                              key={idx}
                              selectedFilterValue={selectedFilter.value}
                              openState={openState}
                              handleSideBarToggle={handleSideBarToggle}
                            />
                          ))}
                      </>
                    </div>
                  </>
                )}
              </>
            )}
          </>
          <>
            {loading ||
            withDrawLoading ||
            depositLoading ||
            tradeLoading ||
            coinsLoading ? (
              <div
                style={{ display: 'grid', placeItems: 'center', padding: 40 }}
              >
                <LoadingBtn />
              </div>
            ) : (
              <>
                {selectedFilterData?.length >= 10 && (
                  <>
                    {!btnDisable && (
                      <div
                        style={{
                          display: 'grid',
                          placeItems: 'center',
                          padding: 40,
                        }}
                      >
                        <CustomButton
                          title='Load More'
                          onClick={handleLoadMore}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </>
      )}
    </>
  );
}

export default ActivitiesHistory;
