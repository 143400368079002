import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { images } from '../../../utils/image/img';
import { Image } from '../../../components';

function ExchangeFilter({
  enableMarketFilter,
  setEnableMarketFilter,
  market,
  handleCB,
}) {
  return (
    // <div
    //   className='header__indicator market__filter'
    //   //   data-tip={
    //   //     !enableMarketFilter
    //   //       ? 'Toogle to see all orders'
    //   //       : `Toogle to see only ${market?.replace('-', '').toUpperCase()}`
    //   //   }
    //   // style={{ width: 'fit-content' }}
    //   data-for={'toggle_trade_mode'}
    //   onClick={() => {
    //     // localStorage.setItem('brine_mode', !enableMarketFilter);
    //     setEnableMarketFilter((prev) => !prev);
    //     // ReactTooltip.hide();
    //     handleCB(!enableMarketFilter);
    //   }}
    // >
    //   <div
    //     className='header__img'
    //     style={{ display: !enableMarketFilter ? 'flex' : 'none' }}
    //   >
    //     {/* <Image src={images.pulse} alt='pulse' /> */}
    //   </div>
    //   <span
    //     style={{
    //       // marginLeft: enableMarketFilter ? 5 : 8,
    //       marginLeft: 5,
    //       fontSize: 11,
    //       fontFamily: 'Poppins',
    //     }}
    //     className='unselectable'
    //   >
    //     {enableMarketFilter ? market : 'All'}
    //   </span>
    //   <div
    //     className='header__img'
    //     style={{
    //       marginLeft: enableMarketFilter ? 5 : 0,
    //       marginRight: enableMarketFilter ? -5 : 0,
    //       display: enableMarketFilter ? 'flex' : 'none',
    //     }}
    //   >
    //     {/* <Image src={images.pulse} alt='pulse' /> */}
    //   </div>
    //   {/* <ReactTooltip
    //     place='bottom'
    //     type={isdark ? 'light' : 'dark'}
    //     className='tooltip_styles'
    //     id={'toggle_trade_mode'}
    //   /> */}
    // </div>

    <div
      className='custom_checkbox login_verify market__filter'
      style={{
        marginTop: 0,
      }}
    >
      <input
        type='checkbox'
        checked={enableMarketFilter}
        onChange={(e) => {
          setEnableMarketFilter((prev) => !prev);
          handleCB(!enableMarketFilter);
        }}
        // style={{
        //   pointerEvents: clickedVerfiy ? 'none' : 'all',
        // }}
      />
      <p
        style={{ width: 100, fontSize: 11 }}
        className='unselectable'
        onClick={() => {
          setEnableMarketFilter((prev) => !prev);
          handleCB(!enableMarketFilter);
        }}
      >
        {/* {enableMarketFilter ? market : 'HIDE OTHER PAIRS'} */}
        HIDE OTHER PAIRS
      </p>
    </div>
  );
}

export default ExchangeFilter;
