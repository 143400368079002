import React, { useState } from 'react';
import { StepperItem } from '../../wallets/wallet-withdraw/wallet-withdraw';
import { NORMAL_WITHDRAWAL_CONFIRMATION_HOURS } from '../../../utils/constants';
import FormInput from '../form-input/forminput';
import CustomButton from '../../common/custom-button/custom-button';
import {
  useSegment,
  useViewport,
  useWalletMethods,
} from '../../../utils/hooks';
import { useWeb3React } from '@web3-react/core';

export default function LoginStepper({
  stage = 1,
  setClickedVerfiy,
  account,
  setStage,
  signedKey,
  clickedVerfiy,
}) {
  const { width } = useViewport();
  const { connector } = useWeb3React();
  const { sendTrackEvent } = useSegment();
  const { walletDeactivate } = useWalletMethods();

  // const stepsArray = [
  //   {
  //     label: 'Connect your wallet',
  //     status: 'completed',
  //   },
  //   {
  //     label: 'Toogle to verify you OWN this wallet',
  //     status: 'visited',
  //   },
  //   {
  //     label: 'To enable secure spot trading by using our infra.',
  //     status: 'unvisited',
  //   },
  // ];

  // state -> active, completed

  return (
    // <Stepper
    //   steps={stepsArray}
    //   currentStepIndex={currentStepIndex}
    //   onStepClick={handleStepClick}
    //   labelPosition='right'
    //   styles={{
    //     LineSeparator: (step, index) => ({ height: '50px' }),
    //     LabelTitle: (step, stepIndex) => ({
    //       fontFamily: 'Poppins',
    //     }),
    //   }}
    // />
    <div className='step-track login-stepper'>
      <StepperItem
        htmlElement={
          account?.length ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
                // marginBottom: 8,
                // transition: 'visibility 0s, opacity 0.2s linear, width 0.2s',
                visibility: account ? 'visible' : 'hidden',
                opacity: account ? 1 : 0,
                height: !account ? 0 : 'fit-content',
              }}
            >
              <div>
                <FormInput
                  readOnly
                  value={`${account?.substring(
                    0,
                    width <= 750 ? 4 : 10
                  )}...${account?.substring(account?.length - 4)}`}
                  style={{
                    height: '42px',
                    padding: `0px 22px 0`,
                    width: width <= 750 ? '130px' : '190px',
                  }}
                />
              </div>
              <div>
                <CustomButton
                  onClick={() => {
                    if (connector) {
                      walletDeactivate();
                    }
                    walletDeactivate();
                    setClickedVerfiy(false);
                    setStage(0);
                  }}
                  addClassName='btn-small'
                  title='Disconnect'
                  style={{ marginLeft: 12 }}
                />
              </div>
            </div>
          ) : (
            <p className={`login__stepper_text ${stage === 0 ? 'active' : ''}`}>
              Connect your wallet
            </p>
          )
        }
        // style={{
        //   height: account?.length ? '7rem' : '4rem',
        // }}
        // lineStyles={{
        //   height: account?.length ? '6rem' : '2.4rem',
        // }}
        style={{
          height: '4rem',
        }}
        lineStyles={{
          height: '2.4rem',
        }}
        isDone={stage >= 1}
        // describeHTMLElement={
        //   <div
        //     style={{
        //       display: 'flex',
        //       alignItems: 'center',
        //       // justifyContent: 'center',
        //       marginBottom: 8,
        //       // transition: 'visibility 0s, opacity 0.2s linear, width 0.2s',
        //       visibility: account ? 'visible' : 'hidden',
        //       opacity: account ? 1 : 0,
        //       height: !account ? 0 : 'fit-content',
        //     }}
        //   >
        //     <div>
        //       <FormInput
        //         readOnly
        //         value={`${account?.substring(
        //           0,
        //           width <= 750 ? 4 : 10
        //         )}...${account?.substring(account?.length - 4)}`}
        //         style={{
        //           height: '47px',
        //           padding: `0px 22px 0`,
        //           width: width <= 750 ? '130px' : '190px',
        //         }}
        //       />
        //     </div>
        //     <div>
        //       <CustomButton
        //         onClick={() => {
        //           if (connector) {
        //             walletDeactivate();
        //           }
        //           walletDeactivate();
        //           setClickedVerfiy(false);
        //           setStage(0);
        //         }}
        //         addClassName='btn-small'
        //         title='Disconnect'
        //         style={{ marginLeft: 12 }}
        //       />
        //     </div>
        //   </div>
        // }
      />
      <StepperItem
        htmlElement={
          <p
            className={`login__stepper_text ${
              !account || (clickedVerfiy && signedKey) ? '' : 'active'
            }`}
          >
            Toggle to verify you own this wallet
          </p>
        }
        isDone={stage >= 2}
        style={{
          height: '4rem',
        }}
        lineStyles={{
          height: '2.4rem',
        }}
      />
      <StepperItem
        htmlElement={
          <p
            className={`login__stepper_text ${
              !account || !signedKey ? '' : 'active'
            }`}
          >
            To enable secure spot trading by using our infra.
          </p>
        }
        isDone={stage >= 3}
        style={{
          height: '4rem',
        }}
        lineStyles={{
          height: '2.4rem',
        }}
      />
    </div>
  );
}
