import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from '../../../components';

function HeaderLogo({ logo }) {
  return (
    <Link
      className='header__logo'
      to={(location) => ({ ...location, pathname: '/' })}
    >
      <Image src={logo} alt='' />
    </Link>
  );
}

export default HeaderLogo;
