import React, { useState } from 'react';
import { Icon } from '../../../components';

function HeaderDropDown({
  addclassName,
  iconName,
  children,
  isNotificated,
  heading,
  bodyStyle,
  styles,
  title,
  changeNotificatedState,
  ...otherProps
}) {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`header__item  ${addclassName}`}
      {...otherProps}
      style={{ ...styles }}
      onMouseOver={() => {
        if (iconName === 'notification') {
          changeNotificatedState();
        }
        setActive(true);
      }}
      onMouseOut={() => {
        setActive(false);
      }}
    >
      <button className={`header__head ${isNotificated ? ' active' : ''}`}>
        {iconName ? (
          <div className='icon icon-arrow-down-square'>
            <Icon icon={iconName} className='icon' />
          </div>
        ) : (
          <div className={active ? 'active__notification' : ''}>{heading}</div>
        )}
      </button>
      {children && (
        <div
          className='header__body'
          onMouseOver={() => {
            if (heading) {
              setActive(true);
            }
          }}
          onMouseOut={() => {
            if (heading) {
              setActive(false);
            }
          }}
          style={{ ...bodyStyle }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default HeaderDropDown;
