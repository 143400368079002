import { useSelector } from 'react-redux';
import {
  CustomButton,
  Popup,
  Toast,
  FormInput,
  LoadingBtn,
} from '../../../components';
import { networkName } from '../../../utils/urls';
import { toast } from 'react-toastify';

function ReferralFormPopup({
  open,
  setOpen,
  inputValue,
  setInputValue,
  handleLogin,
}) {
  const { loading: loginLoading } = useSelector((state) => state.userLogin);

  const styles = {
    textStyle: {
      fontFamily: 'Poppins',
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 20,
    },
  };

  const capitalize = (str) => {
    if (str?.length) {
      const error = str.charAt(0).toUpperCase() + str?.slice(1);
      return error;
    }
  };

  return (
    <div className='referral__popup'>
      {/* {open && <Toast />} */}
      <Popup state={open} setState={setOpen}>
        <div className='forgot_password__innercontainer'>
          <p style={styles.textStyle}>
            Brine’s Beta {capitalize(networkName)} is currently invite only,
            please enter your referral code to join!
          </p>
          <FormInput
            value={inputValue}
            label='Please enter your referral code'
            onChange={(e) => setInputValue(e.target.value)}
          />
          <div style={{ display: 'grid', placeItems: 'center' }}>
            {loginLoading ? (
              <LoadingBtn size='full' />
            ) : (
              <CustomButton
                title='Login'
                onClick={() => {
                  if (!inputValue?.length) {
                    return toast.error('Please enter your referral code');
                  }
                  handleLogin();
                }}
                style={{ width: '100%' }}
              />
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ReferralFormPopup;
