import * as rewardsConstants from '../constants/rewardsConstant';

export const getReferralCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case rewardsConstants.GET_REFERRAL_CODE_REQUEST:
      return { loading: true };
    case rewardsConstants.GET_REFERRAL_CODE_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case rewardsConstants.GET_REFERRAL_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getReferralCodeEarningReducer = (state = {}, action) => {
  switch (action.type) {
    case rewardsConstants.GET_REFERRAL_CODE_EARNING_REQUEST:
      return { loading: true };
    case rewardsConstants.GET_REFERRAL_CODE_EARNING_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case rewardsConstants.GET_REFERRAL_CODE_EARNING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getScratchCardReducer = (state = {}, action) => {
  switch (action.type) {
    case rewardsConstants.GET_SCRATCH_CARD_REQUEST:
      return { loading: true };
    case rewardsConstants.GET_SCRATCH_CARD_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case rewardsConstants.GET_SCRATCH_CARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTotalReferralsReducer = (state = {}, action) => {
  switch (action.type) {
    case rewardsConstants.GET_TOTAL_REFERRAL_REQUEST:
      return { loading: true };
    case rewardsConstants.GET_TOTAL_REFERRAL_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case rewardsConstants.GET_TOTAL_REFERRAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFeeInfoForClaimReducer = (state = {}, action) => {
  switch (action.type) {
    case rewardsConstants.GET_CLAIM_FEE_REQUEST:
      return { loading: true };
    case rewardsConstants.GET_CLAIM_FEE_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case rewardsConstants.GET_CLAIM_FEE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getExternalCampaignListReducer = (state = {}, action) => {
  switch (action.type) {
    case rewardsConstants.GET_EXTERNAL_CAMPAIGNS_REQUEST:
      return { loading: true };
    case rewardsConstants.GET_EXTERNAL_CAMPAIGNS_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case rewardsConstants.GET_EXTERNAL_CAMPAIGNS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
