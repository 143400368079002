import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useBalance,
  useWebSocketData,
  useAnalytics,
  useSegment,
} from '../../../utils/hooks';
import { scroller } from 'react-scroll';
import _ from 'lodash';
import { Icon, SkeletonContainer } from '../../../components';
import { getQueryParams } from '../../../utils/query-helper';
import { baseCurrency } from '../../../utils/constants';
import { toast } from 'react-toastify';

function WalletsAssestsTotal({
  visibleSideBar,
  setVisibleSideBar,
  selectedData,
  setSelectedData,
  remainingItem,
}) {
  // const {
  // getAssests,
  // userGetBalance,
  // userLogin: { userInfo },
  // getCoins: { loading: coinsLoading, error: coinsError, coinsDetails },
  // } = useSelector((state) => state);
  const { loading, error, balance } = useSelector(
    (state) => state.userGetBalance
  );
  const {
    loading: assetLoading,
    error: assetError,
    assetsData,
  } = useSelector((state) => state.getAssests);
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { priceChangePercent, getStatus } = useWebSocketData();
  const [sortedBalance, setSortedBalance] = useState([]);
  const [remainingItemState, setRemainingState] = useState(remainingItem);
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { sendTrackEvent } = useSegment();
  const { getSocketFunctionalityInfo } = useWebSocketData();

  const {
    getBalanceToUSDC,
    getInfoAboutCoins,
    lockedBalance,
    roundUpWithSymbol,
  } = useBalance();

  const scrollToSection = () => {
    scroller.scrollTo('header', {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  function sortByBalance() {
    const newArray = [];
    let removedItem = null;
    let removedItemInRemainingCoin = null;
    balance?.payload.forEach((item) => {
      if (item.currency === baseCurrency) {
        newArray.push({
          inrBalance: item.balance,
          balance: item.balance,
          currency: item.currency,
          locked: item.locked,
        });
      } else {
        newArray.push({
          balance: item.balance,
          inrBalance: getBalanceToUSDC(item),
          currency: item.currency,
          locked: item.locked,
        });
      }
    });

    const containsUSDT = newArray?.find(
      (item) => item.currency === baseCurrency
    );

    if (containsUSDT?.currency === baseCurrency) {
      removedItem = _.remove(newArray, function (o) {
        return o.currency === baseCurrency;
      });
    }

    if (!containsUSDT) {
      removedItemInRemainingCoin = _.remove(remainingItem, function (o) {
        return o.symbol === baseCurrency;
      });
    }
    setRemainingState(remainingItem);

    const sortedArray = _.orderBy(
      newArray,
      function (o) {
        return o.inrBalance;
      },
      ['desc']
    );

    if (removedItem?.length > 0) {
      sortedArray.unshift(removedItem[0]);
      return setSortedBalance(sortedArray);
    }

    if (removedItemInRemainingCoin?.length > 0) {
      let newObj = {
        inrBalance: 0,
        balance: 0,
        currency: baseCurrency,
      };
      sortedArray.unshift(newObj);
      return setSortedBalance(sortedArray);
    }

    setSortedBalance(sortedArray);
  }

  useEffect(() => {
    sortByBalance();
  }, [balance, remainingItem, coinsDetails, userInfo?.token]);

  // useEffect(() => {
  //   analytics?.track('Clicked coin', {
  //     symbol: selectedData?.currency,
  //     from: 'Wallet Page',
  //     uid: userInfo?.payload?.uid,
  //     QueryParams: { ...getQueryParams() },
  //     ip: ip,
  //   });
  // }, [selectedData]);

  useEffect(() => {
    if (searchText.length !== 0) {
      const data = sortedBalance.filter((data) => {
        return (
          getInfoAboutCoins(data.currency)
            ?.name.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          getInfoAboutCoins(data.currency)
            ?.symbol.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        );
      });

      const data2 = remainingItemState.filter((data) => {
        return (
          getInfoAboutCoins(data.symbol)
            ?.name.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          getInfoAboutCoins(data.symbol)
            ?.symbol.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        );
      });
      if (data.length > 0 || data2.length > 0) {
        setResult([...data2, ...data]);
      }
    }
  }, [searchText, sortedBalance, remainingItemState]);

  return (
    <div className='balances'>
      {(loading || assetLoading) && (
        <>
          {[1, 2, 3, 4].map((data, idx) => (
            <SkeletonContainer
              key={idx}
              height={'70px'}
              style={{ borderRadius: 20, marginBottom: 10 }}
            />
          ))}
        </>
      )}
      {!loading && !error && !assetLoading && (
        <>
          <div className='wallets_search__container'>
            <div className='balances__title h6'>Asset Balances</div>
            <div style={{ position: 'relative' }}>
              <div>
                <input
                  className='actions__input exchange_search__input active'
                  type='search'
                  placeholder='Search'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              {!searchText.length && (
                <div style={{ position: 'absolute', right: 10, top: 10 }}>
                  <Icon
                    icon='search'
                    className='icon'
                    size={15}
                    color='#808191'
                  />
                </div>
              )}
            </div>
          </div>

          <div className={`balances__table ${visibleSideBar ? 'small' : ''}`}>
            <div className='balances__row balances__row_head'>
              {/* <div className="balances__cell"></div> */}
              <div className='balances__cell'>ASSET</div>
              <div className='balances__cell'>CURRENCY</div>
              <div className='balances__cell'>24H MARKET</div>
              <div className='balances__cell'>Locked Balance</div>
              {/* <div className="balances__cell">ON ORDERS</div> */}
              <div className='balances__cell'>AVAILABLE BALANCE</div>
              <div className='balances__cell'>
                {baseCurrency?.toLowerCase()}
              </div>
            </div>

            {searchText.length === 0 &&
              sortedBalance &&
              sortedBalance?.map((data, idx) => {
                // if (data.currency !== 'usdt' && ALLOWED_NETWORK_ID != 1) {
                return (
                  <div
                    key={idx}
                    className='balances__row'
                    onClick={() => {
                      if (!userInfo?.token) {
                        toast.info('Connect your wallet to start deposit');
                        scrollToSection();
                        return;
                      }
                      setVisibleSideBar('total-balance');
                      setSelectedData(data);
                      scrollToSection();
                      sendTrackEvent('assetItemClicked', {
                        asset: data?.currency,
                      });
                    }}
                  >
                    {/* <div className="balances__cell">
                  <button className="favorite"></button>
                </div> */}
                    <div className='balances__cell'>
                      <div className='balances__company'>
                        <div className='balances__logo'>
                          <img
                            src={getInfoAboutCoins(data.currency)?.logo}
                            alt=''
                          />
                        </div>
                        <div className='balances__text'>
                          {getInfoAboutCoins(data.currency)?.name}
                        </div>
                      </div>
                    </div>
                    <div
                      className='balances__cell'
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      <div className='balances__text'>{data.currency}</div>
                    </div>
                    {getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial ? (
                      <div className='balances__cell'>
                        {data.currency !== 'usdt' &&
                        data.currency !== 'usdc' ? (
                          <div
                            className={`status ${getStatus(
                              `${data.currency}${baseCurrency}`
                            )}`}
                          >
                            {priceChangePercent(
                              `${data.currency}${baseCurrency}`
                            )}
                          </div>
                        ) : (
                          <div
                            className='negative'
                            style={{
                              marginLeft: 20,
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                            }}
                          >
                            <p>Fiat</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='balances__cell'>NA</div>
                    )}
                    {userInfo?.token ? (
                      <div className='balances__cell'>
                        <div className='balances__number'>
                          {lockedBalance(data.currency)}{' '}
                          {data?.currency?.toUpperCase()}
                        </div>
                        {/* <div className='balances__price'>$2,134.325</div> */}
                      </div>
                    ) : (
                      <div className='balances__cell'>
                        <div className='balances__number'>---</div>
                      </div>
                    )}

                    {userInfo?.token ? (
                      <>
                        {getInfoAboutCoins(data.currency)?.name !== 'Rupee' ? (
                          <div className='balances__cell'>
                            <div className='balances__number'>
                              {roundUpWithSymbol(
                                data.balance,
                                data.currency.toLowerCase()
                              ) +
                                ' ' +
                                data.currency.toUpperCase()}
                            </div>
                          </div>
                        ) : (
                          <div className='balances__cell'>
                            <div className='balances__number'>
                              {roundUpWithSymbol(
                                data.balance,
                                data.currency.toLowerCase()
                              )}
                            </div>
                            <div className='balances__price'>
                              {roundUpWithSymbol(
                                data.balance,
                                data.currency.toLowerCase()
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className='balances__cell'>
                        <div className='balances__number'>---</div>
                      </div>
                    )}
                    <div className='balances__cell'>
                      {userInfo?.token ? (
                        <>
                          {getSocketFunctionalityInfo().full ||
                          getSocketFunctionalityInfo().partial ? (
                            <div className='balances__number'>
                              {getInfoAboutCoins(data.currency)?.symbol ===
                              'usdt'
                                ? roundUpWithSymbol(data.balance, 'usdt')
                                : getInfoAboutCoins(data.currency)?.symbol ===
                                  'usdc'
                                ? roundUpWithSymbol(data.balance, 'usdc')
                                : isNaN(getBalanceToUSDC(data))
                                ? 0
                                : Number(getBalanceToUSDC(data))?.toFixed(
                                    3
                                  )}{' '}
                            </div>
                          ) : (
                            <div className='balances__number'>NA</div>
                          )}
                        </>
                      ) : (
                        <div className='balances__number'>---</div>
                      )}
                    </div>
                  </div>
                );
                // }
              })}

            {searchText.length === 0 &&
              remainingItemState &&
              remainingItemState?.map((data, idx) => {
                const newData = {
                  currency: data.symbol,
                  balance: userInfo?.token ? '0' : '---',
                  locked: data?.locked,
                };
                // if (data.currency !== 'usdt' && ALLOWED_NETWORK_ID != 1) {
                return (
                  <div
                    key={idx}
                    className='balances__row'
                    onClick={() => {
                      if (!userInfo?.token) {
                        toast.info('Connect your wallet to start deposit');
                        scrollToSection();
                        return;
                      }
                      setVisibleSideBar('total-balance');
                      setSelectedData(newData);
                      scrollToSection();
                    }}
                  >
                    <div className='balances__cell'>
                      <div className='balances__company'>
                        <div className='balances__logo'>
                          <img
                            src={getInfoAboutCoins(data.symbol)?.logo}
                            alt=''
                          />
                        </div>
                        <div className='balances__text'>
                          {getInfoAboutCoins(data.symbol)?.name}
                        </div>
                      </div>
                    </div>
                    <div
                      className='balances__cell'
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      {data.symbol}
                    </div>
                    {getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial ? (
                      <div className='balances__cell'>
                        {data.symbol !== 'usdt' && data?.symbol !== 'usdc' ? (
                          <div
                            className={`status ${getStatus(
                              `${data.symbol}${baseCurrency}`
                            )}`}
                          >
                            {priceChangePercent(
                              `${data.symbol}${baseCurrency}`
                            )}
                          </div>
                        ) : (
                          <div
                            className='negative'
                            style={{
                              marginLeft: 20,
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                            }}
                          >
                            <p>Fiat</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='balances__cell'>NA</div>
                    )}
                    {/* <div className='balances__cell'>
                        <div className='balances__number'>19,266.6454898</div>
                        <div className='balances__price'>$2,134.325</div>
                      </div> */}
                    {userInfo?.token ? (
                      <>
                        {getInfoAboutCoins(data.symbol)?.name !== 'Rupee' ? (
                          <div className='balances__cell'>
                            <div className='balances__number'>
                              {`0.00` + ' ' + data.symbol?.toUpperCase()}
                            </div>
                          </div>
                        ) : (
                          <div className='balances__cell'>
                            <div className='balances__number'>0.00</div>
                          </div>
                        )}
                        <div className='balances__cell'>
                          <div className='balances__number'>0.00</div>
                        </div>
                        <div className='balances__cell'>
                          <div className='balances__number'>0.00</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='balances__cell'>
                          <div className='balances__number'>---</div>
                        </div>
                        <div className='balances__cell'>
                          <div className='balances__number'>---</div>
                        </div>
                        <div className='balances__cell'>
                          <div className='balances__number'>---</div>
                        </div>
                      </>
                    )}
                  </div>
                );
                // }
              })}

            {searchText.length > 0 &&
              result.length > 0 &&
              result?.map((data, idx) => {
                const newData = {
                  currency: data.symbol ? data.symbol : data.currency,
                  balance: data.balance ? data.balance : 0,
                  locked: data.locked ? data.locked : 0,
                };
                if (data?.currency !== 'inr') {
                  return (
                    <div
                      key={idx}
                      className='balances__row'
                      onClick={() => {
                        if (!userInfo?.token) {
                          toast.info('Connect your wallet to start deposit');
                          scrollToSection();
                          return;
                        }
                        setVisibleSideBar('total-balance');
                        setSelectedData(newData);
                        scrollToSection();
                      }}
                    >
                      {/* <div className="balances__cell">
               <button className="favorite"></button>
             </div> */}
                      <div className='balances__cell'>
                        <div className='balances__company'>
                          <div className='balances__logo'>
                            <img
                              src={getInfoAboutCoins(newData.currency)?.logo}
                              alt=''
                            />
                          </div>
                          <div className='balances__text'>
                            {getInfoAboutCoins(newData.currency)?.name}
                          </div>
                        </div>
                      </div>
                      <div
                        className='balances__cell'
                        style={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                      >
                        {newData.currency}
                      </div>
                      {getSocketFunctionalityInfo().full ||
                      getSocketFunctionalityInfo().partial ? (
                        <div className='balances__cell'>
                          {newData.currency !== 'usdt' &&
                          newData.currency !== 'usdc' ? (
                            <div
                              className={`status ${getStatus(
                                `${newData.currency}${baseCurrency}`
                              )}`}
                            >
                              {priceChangePercent(
                                `${newData.currency}${baseCurrency}`
                              )}
                            </div>
                          ) : (
                            <div
                              className='negative'
                              style={{
                                marginLeft: 20,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                              }}
                            >
                              <p>Fiat</p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className='balances__cell'>
                          <div className='balances__number'>NA</div>
                        </div>
                      )}

                      {/* <div className="balances__cell">
                <div className="balances__number">19,266.6454898</div>
                <div className="balances__price">$2,134.325</div>
              </div> */}

                      {userInfo?.token ? (
                        <>
                          <div className='balances__cell'>
                            <div className='balances__number'>
                              {lockedBalance(newData.currency)}{' '}
                              {data?.currency?.toUpperCase()}
                            </div>
                            {/* <div className='balances__price'>$2,134.325</div> */}
                          </div>
                          {newData.currency !== 'inr' ? (
                            <div className='balances__cell'>
                              {newData.balance != 0 ? (
                                <div className='balances__number'>
                                  {roundUpWithSymbol(
                                    data.balance,
                                    newData.currency.toLowerCase()
                                  ) +
                                    ' ' +
                                    newData.currency.toUpperCase()}
                                </div>
                              ) : (
                                <div className='balances__number'>
                                  {0 + ' ' + newData.currency.toUpperCase()}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className='balances__cell'>
                              {newData.currency != 0 ? (
                                <div className='balances__number'>
                                  {' '}
                                  {roundUpWithSymbol(
                                    data.balance,
                                    newData.currency.toLowerCase()
                                  )}
                                </div>
                              ) : (
                                <div className='balances__number'>
                                  {0 + '' + newData.currency.toLowerCase()}
                                </div>
                              )}
                              {newData.currency != 0 ? (
                                <div className='balances__price'>
                                  {roundUpWithSymbol(
                                    data.balance,
                                    newData.currency.toLowerCase()
                                  )}
                                </div>
                              ) : (
                                <div className='balances__price'>
                                  {0 + '' + newData.currency.toUpperCase()}
                                </div>
                              )}
                            </div>
                          )}
                          <div className='balances__cell'>
                            {newData.currency != 0 ? (
                              <div className='balances__number'>
                                {' '}
                                {getInfoAboutCoins(newData.currency)?.name ===
                                'Tether'
                                  ? roundUpWithSymbol(data.balance, 'usdt')
                                  : isNaN(getBalanceToUSDC(data))
                                  ? 0
                                  : roundUpWithSymbol(
                                      getBalanceToUSDC(data),
                                      'usdt'
                                    )}
                              </div>
                            ) : (
                              <div className='balances__cell'>
                                <div className='balances__number'>0.00</div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='balances__cell'>
                            <div className='balances__number'>---</div>
                          </div>{' '}
                          <div className='balances__cell'>
                            <div className='balances__number'>---</div>
                          </div>{' '}
                          <div className='balances__cell'>
                            <div className='balances__number'>---</div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                }
              })}
          </div>

          {/* <div className="balances__btns">
            <CustomButton title="Load More" />
          </div> */}
        </>
      )}
    </div>
  );
}

export default WalletsAssestsTotal;
