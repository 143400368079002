import React from 'react';

function WarnBox({ children, ...otherStyleProps }) {
  return (
    <div className='warning__box network_error_container' {...otherStyleProps}>
      {children}
    </div>
  );
}

export default WarnBox;
