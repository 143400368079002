import Popup from '../popup/popup';
import Image from '../img/image';
import { images } from '../../../utils/image/img';
import CustomButton from '../custom-button/custom-button';
import { CountdownTimer } from '../../home/slider/slider';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function LeaderboardBannerPopup({
  openLeaderboardPrompt,
  setOpenLeaderboardPrompt,
}) {
  // const {
  //   campaignsList: {
  //     response: campaignsResponse,
  //     // loading: campaignsLoading,
  //     // error: campaignsError,
  //   },
  //   userVia: { state: userVia },
  // } = useSelector((state) => state);

  const history = useHistory();

  const { state: userVia } = useSelector((state) => state.userVia);
  const { response: campaignsResponse } = useSelector(
    (state) => state.campaignsList
  );

  const setLeaderboardPopup = () => {
    if (campaignsResponse?.payload[0]?.campaign_uid && userVia?.address) {
      localStorage.setItem(
        `${campaignsResponse?.payload[0]?.campaign_uid}-${userVia?.address}`,
        'true'
      );
    }
  };

  return (
    <div
      className='share__popup network__error lb_popup'
      style={{ paddingBottom: '49px' }}
    >
      <Popup
        state={openLeaderboardPrompt}
        setState={setOpenLeaderboardPrompt}
        clearState={setLeaderboardPopup}
      >
        <div className='h6' style={{ marginBottom: 35, textAlign: 'center' }}>
          {campaignsResponse?.payload[0]?.name} Challenge is now{' '}
          <span style={{ color: '#8173E0' }}>LIVE!</span>
        </div>
        <Image src={images.LbPopupImage} style={{ maxWidth: 140 }} />
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <CountdownTimer endTime={campaignsResponse?.payload[0]?.end_time} />
        </div>
        <p style={{ fontSize: 13, marginBottom: 22 }}>
          Leaderboard competition is now live!
          <br />
          Start trading your way to the top 📈
        </p>
        <CustomButton
          title={`Win Now!`}
          onClick={() => {
            setLeaderboardPopup();
            history.push('/leaderboard');
          }}
        />
      </Popup>
    </div>
  );
}
