import React, { useState } from 'react';
import { useViewport } from '../../../utils/hooks';
import OrderTable from './order-table/order-table';
import AllTrades from '../all-trades/all-trades';
import { useSelector } from 'react-redux';

function OrderBook({
  selectedtab,
  selectedCell,
  tradeMode,
  orderBookPrice,
  setOrderBookPrice,
}) {
  const { width } = useViewport();
  const breakpoint = 790;
  // const {
  // theme: { isdark },
  // userLogin: { userInfo },
  // } = useSelector((state) => state);
  const { isdark } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state.userLogin);

  const [isOrderActive, setIsOrderActive] = useState(false);
  const [isTradeActive, setIsTradeActive] = useState(false);
  const [showAllTrades, setShowAllTrades] = useState(false);
  const orderHeadingStyle = {
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '0px 10px',
    },
    orderBookStyles: {
      opacity: !showAllTrades || isOrderActive ? 1 : 0.5,
      cursor: 'pointer',
      color: !showAllTrades
        ? '#8173E0'
        : isOrderActive
        ? isdark
          ? '#fff'
          : '#000'
        : '#808191',
      backgroundColor: !showAllTrades
        ? isdark
          ? '#191b20'
          : 'rgba(228, 228, 228, 0.3)'
        : isdark
        ? '#22242c'
        : 'transparent',
      flex: 1,
      padding: '5px 5px',
      borderRadius: 20,
      margin: '5px 5px',
      textAlign: 'center',
    },
    tradesStyles: {
      opacity: showAllTrades || isTradeActive ? 1 : 0.5,
      cursor: 'pointer',
      color: showAllTrades
        ? '#6c5dd3'
        : isTradeActive
        ? isdark
          ? '#fff'
          : '#000'
        : '#808191',
      backgroundColor: showAllTrades
        ? isdark
          ? '#191b20'
          : 'rgba(228, 228, 228, 0.3)'
        : isdark
        ? '#22242c'
        : 'transparent',
      flex: 1,
      padding: '5px 5px',
      borderRadius: 20,
      margin: '5px 5px',
      textAlign: 'center',
    },
  };
  return (
    <div>
      {(width < 790 || tradeMode) && (
        <div
          className='orders'
          style={{
            display:
              (selectedtab === 'orders' && width < breakpoint) || tradeMode
                ? 'block'
                : 'none',
          }}
        >
          <div
            className='orders__head'
            style={orderHeadingStyle.headerContainer}
          >
            <div
              className='orders__title h6'
              style={orderHeadingStyle.orderBookStyles}
              onClick={() => {
                if (!userInfo?.token) return;
                setShowAllTrades(false);
              }}
              onMouseEnter={() => setIsOrderActive(true)}
              onMouseLeave={() => setIsOrderActive(false)}
            >
              Order Book
            </div>
            {/* <hr
              style={{
                borderRight: '0.5px solid #808191',
                height: '30px',
              }}
            /> */}
            <div
              className='orders__title h6 active'
              style={{
                ...orderHeadingStyle.tradesStyles,
                cursor: !userInfo?.token ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                if (!userInfo?.token) return;
                setShowAllTrades(true);
              }}
              onMouseEnter={() => {
                if (userInfo?.token?.access) {
                  setIsTradeActive(true);
                }
              }}
              onMouseLeave={() => {
                if (userInfo?.token?.access) {
                  setIsTradeActive(false);
                }
              }}
            >
              Trades
            </div>
            {/* <OrderSort /> */}
          </div>
          {!showAllTrades ? (
            <OrderTable
              selectedCell={selectedCell}
              tradeMode={tradeMode}
              orderBookPrice={orderBookPrice}
              setOrderBookPrice={setOrderBookPrice}
              selectedtab={selectedtab}
            />
          ) : (
            <AllTrades
              selectedCell={selectedCell}
              tradeMode={tradeMode}
              orderBookPrice={orderBookPrice}
              setOrderBookPrice={setOrderBookPrice}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default OrderBook;
