import { useState } from 'react';
import ComingSoonAnimationData from '../../../assests/json/coming-soon.json';
import Lottie from 'react-lottie';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { images } from '../../../utils/image/img';
import {
  Image,
  HeaderDropDown,
  HeaderIndicator,
  HeaderNotification,
  CustomButton,
} from '../../../components';
import { changeNotificatedState } from '../../../actions/userAction';
import { useSegment } from '../../../utils/hooks';

function HeaderGroup({
  isExchangePage,
  tradeMode,
  setTradeMode,
  handle,
  layout,
  setLayout,
}) {
  // const {
  //   theme: { isdark },
  //   userMsg: { state: notificationData },
  //   is_notificated: { notificated: isNotificatedState },
  //   userLogin: { userInfo },
  // } = useSelector((state) => state);
  const { isdark } = useSelector((state) => state.theme);
  const { state: notificationData } = useSelector((state) => state.userMsg);
  const { notificated: isNotificatedState } = useSelector(
    (state) => state.is_notificated
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  const { sendTrackEvent } = useSegment();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ComingSoonAnimationData,
  };

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const handleNotificatedState = () => {
    dispatch(changeNotificatedState(true));
  };

  return (
    <div className='header__group'>
      {isExchangePage && (
        <HeaderIndicator
          tradeMode={tradeMode}
          setTradeMode={setTradeMode}
          handle={handle}
        />
      )}
      {notificationData?.length !== 0 && (
        <HeaderDropDown
          addclassName='header__item_notifications'
          iconName='notification'
          isNotificated={isNotificatedState}
          changeNotificatedState={handleNotificatedState}
          bodyStyle={{
            maxHeight: '420px',
            overflowY: 'scroll',
            zIndex: 200,
          }}
        >
          <div
            className='header__info'
            style={{ marginTop: 20, fontFamily: 'Poppins', fontSize: 20 }}
          >
            Notifications{' '}
            <span
              style={{
                background: '#6c5dd3',
                padding: '3px 10px',
                borderRadius: 30,
                fontSize: 15,
                color: '#fff',
              }}
            >
              {notificationData?.length}
            </span>{' '}
          </div>
          <div className='header__notifications'>
            {reverseArr(notificationData)?.map((data, idx) => (
              <HeaderNotification key={idx} data={data} />
            ))}
          </div>
        </HeaderDropDown>
      )}
      <div className={`header__item  header__item_download`}>
        <button
          className={`header__head`}
          data-tip={'Help center'}
          data-for={'title'}
          onClick={() => {
            history.push({
              pathname: '/help',
              search: location.search,
            });
          }}
        >
          <div className='icon icon-arrow-down-square'>
            <Image src={images.help} alt='' />
          </div>
          <ReactTooltip
            place='bottom'
            type={isdark ? 'light' : 'dark'}
            className='tooltip_styles price_details'
            id={'title'}
          />
        </button>
      </div>

      {/* {!tradeMode && (
        <HeaderDropDown
          addclassName='header__item_download'
          iconName='arrow-down-square'
        >
          <div style={{ display: 'grid', alignItems: 'center' }}>
            <div
              className='header__category'
              style={{
                textAlign: 'center',
                marginBottom: 0,
                fontFamily: 'Poppins',
              }}
            >
              Download the brine app
            </div>
            <Lottie options={defaultOptions} height={100} width={100} />
          </div>
          <div className='header__row'>
            <div className='header__col'>
              <div className='header__category'>Downloads</div>
              <div className='header__downloads'>
                <a
                  className='header__download'
                  href='https://apps.apple.com/in/app/krypto-buy-sell-bitcoin/id1513381502'
                  target='_blank'
                  referrerPolicy='no-referrer'
                >
                  <Image src={AppStore} alt='' />
                </a>
                <a
                  className='header__download'
                  href='https://play.google.com/store/apps/details?id=com.krypto'
                  target='_blank'
                  referrerPolicy='no-referrer'
                >
                  <Image src={GooglePlay} alt='' />
                </a>
              </div>
            </div>
            <div className='header__col'>
              <div className='header__category'>Scan Code</div>
              <div
                className='header__code'
                style={{ width: '100px', height: '100px' }}
              >
                <QRCodeComponent value='letskrypto.com/links' size={100} />
              </div>
            </div>
          </div>
        </HeaderDropDown>
      )} */}
      {/* <HeaderDropDown
        addclassName={`header__item_lang ${toggleList ? 'active' : ''}`}
        heading={selectedlanguage}
        onClick={() => setToggleList((prev) => !prev)}
      >
        <div className="header__lang">
          <div className="header__cell">
            {languages.map((data, index) => (
              <button
                className={`header__link ${
                  data.language + '/' + data.currency === selectedlanguage
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  setSelectedLanguage(`${data.language}/${data.currency}`)
                }
                key={index}
              >
                <span className="header__flag">{data.emoji}</span>
                {data.language}
              </button>
            ))}
          </div>

          <div className="header__cell">
            {currency.map((data, idx) => (
              <a className="header__link" href="/" key={idx}>
                {data.name}
              </a>
            ))}
          </div>
        </div>
      </HeaderDropDown> */}
      {tradeMode && handle ? (
        <>
          <div className={`header__item`}>
            <div
              className='icon icon-arrow-down-square'
              style={{ cursor: 'pointer' }}
              data-tip={
                handle.active ? 'Exit Full Screen' : 'Enter Full Screen'
              }
              data-for={'fullscreen'}
              onClick={
                handle.active
                  ? () => {
                      sendTrackEvent('proScreenFullScreenStateChanged');
                      handle.exit();
                    }
                  : () => {
                      sendTrackEvent('proScreenFullScreenStateChanged');
                      handle.enter();
                    }
              }
            >
              {handle.active ? (
                <Image src={images.exitScreen} alt='' />
              ) : (
                <Image src={images.fullScreen} alt='' />
              )}
            </div>
            <ReactTooltip
              place='bottom'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles price_details'
              id={'fullscreen'}
            />
          </div>
          <div
            className={`header__item`}
            style={{ marginLeft: 20, cursor: 'pointer' }}
            data-tip={'Change Layout'}
            data-for={'layout'}
            onClick={() => {
              sendTrackEvent('proScreenLayoutChanged');
              setLayout((pre) => !pre);
              localStorage.setItem('brine_layout', !layout);
            }}
          >
            <div
              className='icon icon-arrow-down-square'
              style={{ transform: layout ? 'scaleX(1)' : 'scaleX(-1)' }}
            >
              <Image src={images.layout} alt='' />
            </div>
            <ReactTooltip
              place='bottom'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles price_details'
              id={'layout'}
            />
          </div>
        </>
      ) : (
        <>
          {!userInfo?.token && (
            <div
              className={`header__item  header__item_download meta_instruction_item`}
            >
              <CustomButton
                title='Connect Wallet'
                onClick={() => {
                  sendTrackEvent('buttonClicked', {
                    title: 'Connect Wallet',
                    action: 'Routing to Login Page',
                  });
                  history.push({
                    pathname: '/signin',
                    search: location.search,
                    state: { from: location },
                  });
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HeaderGroup;
