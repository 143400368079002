import * as userConstants from '../constants/userConstant';
import axios from 'axios';
import { prefix } from '../store';
import { baseUrl, devUrl, url, metaUrl, networkName } from '../utils/urls';

const prodLogin = (email, password, token) => async (dispatch, getState) => {
  try {
    const {
      rememberMeFlag: { remember },
    } = getState();

    dispatch({
      type: userConstants.USER_LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/captcha/auth/login/`, {
      email,
      password,
      token,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    if (!remember) {
      sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    } else {
      localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: userConstants.USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const prodOtpLogin =
  (email, password, otp, token) => async (dispatch, getState) => {
    //console.log(email, password, otp);
    const {
      rememberMeFlag: { remember },
    } = getState();
    try {
      dispatch({
        type: userConstants.USER_LOGIN_REQUEST,
      });
      const { data } = await axios.post(`${baseUrl}/captcha/auth/login/otp/`, {
        email,
        password,
        otp: otp,
        token: token,
      });
      dispatch({
        type: userConstants.USER_LOGIN_SUCCESS,
        payload: data,
      });
      if (!remember) {
        sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
      } else {
        localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
      }
    } catch (error) {
      dispatch({
        type: userConstants.USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

const prodRegister = (email, password, token) => async (dispatch, getState) => {
  try {
    dispatch({
      type: userConstants.USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(`${baseUrl}/captcha/auth/signup/`, {
      email,
      password,
      token,
    });

    dispatch({
      type: userConstants.USER_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });

    sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const forgotPassword = (email, token) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_FORGOT_PASSWORD_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/captcha/auth/reset/password/`,
      {
        email,
        token: token,
      }
    );
    dispatch({
      type: userConstants.USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
const devLogin = (email, password, token) => async (dispatch, getState) => {
  try {
    const {
      rememberMeFlag: { remember },
    } = getState();

    dispatch({
      type: userConstants.USER_LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/auth/login/`, {
      email,
      password,
      token,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    if (!remember) {
      sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    } else {
      localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: userConstants.USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const devOtpLogin =
  (email, password, otp, token) => async (dispatch, getState) => {
    //console.log(email, password, otp);
    const {
      rememberMeFlag: { remember },
    } = getState();
    try {
      dispatch({
        type: userConstants.USER_LOGIN_REQUEST,
      });
      const { data } = await axios.post(`${baseUrl}/auth/login/otp/`, {
        email,
        password,
        otp: otp,
        token: token,
      });
      dispatch({
        type: userConstants.USER_LOGIN_SUCCESS,
        payload: data,
      });
      if (!remember) {
        sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
      } else {
        localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
      }
    } catch (error) {
      dispatch({
        type: userConstants.USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

const devRegister = (email, password, token) => async (dispatch, getState) => {
  const {
    rememberMeFlag: { remember },
  } = getState();
  try {
    dispatch({
      type: userConstants.USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(`${baseUrl}/auth/signup/`, {
      email,
      password,
      token,
    });

    dispatch({
      type: userConstants.USER_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    if (!remember) {
      sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    } else {
      localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: userConstants.USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const googleLogin = (token, otp) => async (dispatch, getState) => {
  try {
    const {
      rememberMeFlag: { remember },
    } = getState();

    dispatch({
      type: userConstants.GOOGLE_LOGIN_REQUEST,
    });

    const reqData = {};
    if (otp) {
      reqData['token'] = token;
      reqData['otp'] = otp;
    } else {
      reqData['token'] = token;
    }
    const { data } = await axios.post(`${baseUrl}/auth/login/oauth/`, reqData);
    dispatch({
      type: userConstants.GOOGLE_LOGIN_SUCCESS,
      payload: data,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    if (!remember) {
      sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    } else {
      localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: userConstants.GOOGLE_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = (forceReload) => (dispatch, getState) => {
  const {
    rememberMeFlag: { remember },
  } = getState();
  if (!remember) {
    sessionStorage.removeItem(`${prefix}_userInfo`);
    sessionStorage.removeItem(`${prefix}.key`);
    sessionStorage.removeItem(`${prefix}.method`);
    sessionStorage.removeItem(`${prefix}.eth-network`);
  } else {
    localStorage.removeItem(`${prefix}_userInfo`);
    localStorage.removeItem(`${prefix}.key`);
    localStorage.removeItem(`${prefix}.method`);
    localStorage.removeItem(`${prefix}.eth-network`);
  }
  localStorage.removeItem('krypto.theme');
  sessionStorage.removeItem('pending_allowance');
  sessionStorage.removeItem(`${prefix}.msg`);
  localStorage.removeItem(prefix + '.notificated-state');
  sessionStorage.clear();

  dispatch({ type: userConstants.USER_LOGOUT });
  dispatch({ type: userConstants.USER_DETAILS_CLEAN });
  dispatch({ type: userConstants.USER_ADD_KEY_CLEANUP });
  dispatch({ type: userConstants.STORE_WEB3_AUTH_PROVIDER_CLEANUP });

  if (forceReload) {
    window.location.reload();
  }
};

export const addName =
  (name, email = null, referral_code = null) =>
  async (dispatch, getState) => {
    let userData;
    // if (stark_key) {
    //   userData = {
    //     name: name?.trim(),
    //     stark_key: stark_key,
    //     email,
    //   };
    // }
    if (!email) {
      userData = {
        name: name?.trim(),
      };
    } else {
      userData = {
        name: name?.trim(),
        email,
      };
    }

    if (referral_code) {
      userData['referral_code'] = referral_code;
    }

    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: userConstants.USER_ADD_NAME_REQUEST,
      });

      const { data } = await axios.post(
        `${baseUrl}/user/profile/edit/`,
        userData,
        config
      );

      dispatch({
        type: userConstants.USER_ADD_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: userConstants.USER_ADD_NAME_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addUniqueUserName = (username) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_ADD_UNIQUE_USERNAME_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/profile/edit/`,
      {
        username: username?.trim(),
      },
      config
    );
    dispatch({
      type: userConstants.USER_ADD_UNIQUE_USERNAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_ADD_UNIQUE_USERNAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBalance =
  (currency = undefined) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (!userInfo?.token) {
        return;
      }

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: userConstants.USER_GET_BALANCE_REQUEST,
      });
      const { data } = await axios.get(`${baseUrl}/user/getbalances/`, config);

      // data.payload.forEach((item) => {
      //   item.balance = '5087.13619399999992';
      // });

      dispatch({
        type: userConstants.USER_GET_BALANCE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: userConstants.USER_GET_BALANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`${baseUrl}/user/profile/`, config);

    dispatch({
      type: userConstants.USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const gettotalReferralEaring = async (token, type) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl}/referral/list_referral_trading_fee_rewards/?status=${type}`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateBankNumber =
  (accountnumber) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };
      dispatch({
        type: userConstants.UPDATE_BANK_ACCOUNT_NUMBER_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/bank/edit/`,
        { account_number: accountnumber },
        config
      );
      dispatch({
        type: userConstants.UPDATE_BANK_ACCOUNT_NUMBER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: userConstants.UPDATE_BANK_ACCOUNT_NUMBER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateUPIDetail = (upiId) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    dispatch({
      type: userConstants.UPDATE_UPI_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/bank/edit/`,
      { upi_id: upiId },
      config
    );
    dispatch({
      type: userConstants.UPDATE_UPI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.UPDATE_UPI_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserPermissionDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    dispatch({
      type: userConstants.USER_PERMISSION_LIST_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}/auth/permissions/`, config);
    dispatch({
      type: userConstants.USER_PERMISSION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_PERMISSION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ifsc_code: ifscCode,

export const updateIFSCDetail = (ifscCode) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    dispatch({
      type: userConstants.UPDATE_IFSC_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/bank/edit/`,
      { ifsc_code: ifscCode },
      config
    );
    dispatch({
      type: userConstants.UPDATE_IFSC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.UPDATE_IFSC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePassword = async (token, password, oldPassword) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    // dispatch({
    //   type: userConstants.UPDATE_PASSWORD_REQUEST,
    // });
    const { data } = await axios.post(
      `${baseUrl}/user/password/update/`,
      { old_password: oldPassword, password: password },
      config
    );
    // dispatch({
    //   type: userConstants.UPDATE_PASSWORD_SUCCESS,
    //   payload: data,
    // });
    return data;
  } catch (error) {
    // dispatch({
    //   type: userConstants.UPDATE_PASSWORD_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
    throw error.response.data.message;
  }
};

export const resendMail = (email, token) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: userConstants.SEND_EMAIL_CONFIRMATION_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/captcha/auth/verify/email/`, {
      email: email,
      token: token,
    });
    dispatch({
      type: userConstants.SEND_EMAIL_CONFIRMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.SEND_EMAIL_CONFIRMATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const uploadUserProfileImage = async (token, file) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let formData = new FormData();
    let name = file.name.replace(/[^\w\s\.]|_/g, '').replace(/\s+/g, '');

    const renamedFile = new File([file], name);

    formData.append('file', renamedFile);

    const { data } = await axios.post(
      `${baseUrl}/user/image/${name}/`,
      formData,
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const getPortolio = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_GET_PORTFOLIO_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}/user/getpnl/`, config);

    dispatch({
      type: userConstants.USER_GET_PORTFOLIO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_GET_PORTFOLIO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDepositLevel = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_DEPOSIT_CHECK_REQUEST,
    });
    const { data } = await axios.get(
      `${baseUrl}/payment/has_deposited/`,
      config
    );
    dispatch({
      type: userConstants.USER_DEPOSIT_CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_DEPOSIT_CHECK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserHasEnabled2FA = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: userConstants.USER_2FA_CHECK_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/auth/is_2fa_enabled/`, {
      email,
    });
    dispatch({
      type: userConstants.USER_2FA_CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_2FA_CHECK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const walletLogin =
  (eth_address, user_signature, stark_key, static_sign, referral_code = null) =>
  async (dispatch, getState) => {
    const {
      rememberMeFlag: { remember },
    } = getState();

    try {
      dispatch({
        type: userConstants.USER_LOGIN_REQUEST,
      });
      let reqBody;
      if (!referral_code) {
        reqBody = {
          eth_address,
          user_signature,
          stark_key,
          static_user_signature: static_sign,
        };
      } else {
        reqBody = {
          eth_address,
          user_signature,
          referral_code,
          stark_key,
          static_user_signature: static_sign,
        };
      }
      const { data } = await axios.post(
        `${baseUrl}/auth/login/wallet/`,
        reqBody
      );
      dispatch({
        type: userConstants.USER_LOGIN_SUCCESS,
        payload: data,
      });
      if (!remember) {
        sessionStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
      } else {
        localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
      }
    } catch (error) {
      dispatch({
        type: userConstants.USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getVaultIdList = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_GET_VAULT_ID_LIST_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/list_vaults/`,
      {},
      config
    );
    dispatch({
      type: userConstants.USER_GET_VAULT_ID_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_GET_VAULT_ID_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getNonce = async (eth_address) => {
  if (!eth_address) {
    return;
  }
  try {
    const { data } = await axios.post(`${baseUrl}/auth/nonce_get/`, {
      eth_address,
    });
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const storeKey = (token) => async (dispatch, getState) => {
  const {
    rememberMeFlag: { remember },
  } = getState();
  dispatch({
    type: userConstants.USER_ADD_KEY_SUCCESS,
    payload: token,
  });
  if (!remember) {
    sessionStorage.setItem(`${prefix}.key`, token);
  } else {
    localStorage.setItem(`${prefix}.key`, token);
  }
};

export const storeUserViaMethod = (token) => async (dispatch, getState) => {
  const {
    rememberMeFlag: { remember },
  } = getState();
  dispatch({
    type: userConstants.USER_VIA_WALLET_SUCCESS,
    payload: token,
  });
  if (!remember) {
    sessionStorage.setItem(`${prefix}.method`, JSON.stringify(token));
  } else {
    localStorage.setItem(`${prefix}.method`, JSON.stringify(token));
  }
};

export const storeWeb3AuthProvider = (provider) => async (dispatch) => {
  dispatch({
    type: userConstants.STORE_WEB3_AUTH_PROVIDER_SUCCESS,
    payload: provider,
  });
};

export const getUserIPAddress = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: userConstants.USER_GET_IP_ADDRESS_REQUEST,
    });
    const response = await fetch('https://api.ipify.org/');
    const data = await response.text();

    dispatch({
      type: userConstants.USER_GET_IP_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_GET_IP_ADDRESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const changeNotificatedState = (state) => (dispatch) => {
  dispatch({
    type: 'CHANGE_USER_NOTIFICATED_STATE',
    payload: !state,
  });
  localStorage.setItem(prefix + '.notificated-state', !state);
};

export const changeCurrentNetworkState = (state) => (dispatch, getState) => {
  const {
    rememberMeFlag: { remember },
  } = getState();
  dispatch({
    type: 'CHANGE_CURRENT_NETWORK',
    payload: state,
  });
  if (!remember) {
    sessionStorage.setItem(prefix + '.eth-network', JSON.stringify(state));
  } else {
    localStorage.setItem(prefix + '.eth-network', JSON.stringify(state));
  }
};

export const changeRemainderMeState = (state) => (dispatch) => {
  console.log({ state });
  dispatch({
    type: 'CHANGE_REMEMBER_ME',
    payload: !state,
  });
  localStorage.setItem(prefix + '.CHANGE_REMEMBER_ME', JSON.stringify(!state));
};

// export const changeTickerFetchState = (state) => (dispatch) => {
//   dispatch({
//     type: 'TICKER_START_FETCH_REDUCER',
//     payload: state,
//   });
// };

export const login = url == 'dev' ? devLogin : prodLogin;
export const Register = url == 'dev' ? devRegister : prodRegister;
export const otpLogin = url == 'dev' ? devOtpLogin : prodOtpLogin;
