import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../../utils/query-helper';
import {
  useAnalytics,
  useBalance,
  useSegment,
  useWebSocketData,
} from '../../../../utils/hooks';

function MarketWidgetsCard({ data }) {
  const {
    lastPrice,
    priceChangePercent,
    getStatus,
    getSocketFunctionalityInfo,
  } = useWebSocketData();
  const { getInfoAboutCoins } = useBalance();
  const { formatCurrency, roundUpWithSymbol } = useBalance();
  const history = useHistory();
  const location = useLocation();
  const { sendTrackEvent } = useSegment();

  return (
    <>
      {data?.length && (
        <div
          onClick={() => {
            history.push({
              pathname: `/exchange/${data?.replace('/', '-')}`,
              search: location.search,
            });
            sendTrackEvent('coinMarketItemClick', { market: data });
            // analytics?.track('Clicked the coin!', {
            //   symbol: data,
            //   uid: userInfo?.payload?.uid,
            //   from: 'Homepage',
            //   QueryParams: { ...getQueryParams() },
            //   ip: ip,
            // });
          }}
          className='widgets__item'
          style={{ overflow: 'hidden', cursor: 'pointer' }}
        >
          <div className='widgets__head'>
            <div className='widgets__company'>
              <div className='widgets__logo'>
                <img
                  src={getInfoAboutCoins(data?.split('/')[0])?.logo}
                  alt=''
                />
              </div>
              <div className='widgets__details'>
                <div className='widgets__category'>{data?.toUpperCase()}</div>
                <div
                  className='widgets__info'
                  style={{ textTransform: 'uppercase' }}
                >
                  {data?.symbol}
                </div>
              </div>
            </div>
            {/* <div className="widgets__time">{formatTime(data?.symbol)}</div> */}
            {getSocketFunctionalityInfo().full ? (
              <div className='widgets__time'>24h</div>
            ) : (
              <div className='widgets__time'>
                <div
                  className='dot-flashing small'
                  style={{ marginTop: 17, marginRight: 20 }}
                ></div>
              </div>
            )}
          </div>
          <div className='widgets__body' style={{ marginBottom: 20 }}>
            <div className='widgets__line'>
              <div className='widgets__price' style={{ maxWidth: '170px' }}>
                {formatCurrency(lastPrice(`${data?.replace('/', '')}`))}{' '}
                {data?.split('/')[1]?.toUpperCase()}
              </div>
              {getSocketFunctionalityInfo().full && (
                <>
                  {data?.symbol == 'usdc' ? (
                    <div
                      className={`${
                        data != 'usdc/usdt' ? 'status' : ''
                      } ${getStatus(`${data?.replace('/', '')}`)}`}
                      style={{ fontFamily: 'Poppins', fontWeight: 600 }}
                    >
                      {priceChangePercent(`${data?.replace('/', '')}`)}{' '}
                    </div>
                  ) : (
                    <div
                      className={`${getStatus(`${data?.replace('/', '')}`)}`}
                      style={{ fontFamily: 'Poppins', fontWeight: 600 }}
                    >
                      {priceChangePercent(`${data?.replace('/', '')}`)}{' '}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <Link
            className='widgets__btn btn btn_orange btn_wide'
            to={(location) => ({
              ...location,
              pathname: `/exchange/${data?.replace('/', '-')}`,
            })}
            // onClick={() => {
            //   analytics?.track('Clicked the coin!', {
            //     symbol: data?.symbol,
            //     from: 'Homepage',
            //     uid: userInfo?.payload?.uid,
            //     QueryParams: { ...getQueryParams() },
            //     ip: ip,
            //   });
            // }}
          >
            View Asset
          </Link>
        </div>
      )}
    </>
  );
}

export default MarketWidgetsCard;
