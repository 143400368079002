import * as saltConstants from '../constants/saltConstant';

export const getSaltLeaderboardReducer = (state = {}, action) => {
  switch (action.type) {
    case saltConstants.GET_SALT_LEADERBOARD_REQUEST:
      return { loading: true };
    case saltConstants.GET_SALT_LEADERBOARD_SUCCESS:
      return { loading: false, response: action.payload };
    case saltConstants.GET_SALT_LEADERBOARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSaltScoreReducer = (state = {}, action) => {
  switch (action.type) {
    case saltConstants.GET_SALT_SCORE_REQUEST:
      return { loading: true };
    case saltConstants.GET_SALT_SCORE_SUCCESS:
      return { loading: false, response: action.payload };
    case saltConstants.GET_SALT_SCORE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSaltScoreFlagReducer = (state = {}, action) => {
  switch (action.type) {
    case saltConstants.GET_SALT_SCORE_FLAG_REQUEST:
      return { loading: true };
    case saltConstants.GET_SALT_SCORE_FLAG_SUCCESS:
      return { loading: false, response: action.payload };
    case saltConstants.GET_SALT_SCORE_FLAG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
