import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBalance, listenForOutsideClicks } from '../../../../utils/hooks';
import { Icon } from '../../../../components';
import ProExchangeCellDropdownOptions from '../pro-exchange-cell-dropdown-options/pro-exchange-cell-dropdown-options';

function ProExchangeDropdown({
  openState,
  closeState,
  defaultValue,
  options,
  onChange,
  result,
  setSearchText,
  searchText,
}) {
  const { symbol } = useParams();
  const { getInfoAboutCoins } = useBalance();
  const coinsDropdownRef = useRef(null);
  const [listening, setListening] = useState(false);

  const handleClose = () => {
    closeState(false);
  };

  useEffect(
    listenForOutsideClicks(
      listening,
      setListening,
      coinsDropdownRef,
      handleClose
    )
  );
  return (
    <div className='exchange__cell' ref={coinsDropdownRef}>
      <div className={`exchange__company ${openState ? 'active' : ''}`}>
        <div
          className='exchange__head'
          onClick={() => closeState((prev) => !prev)}
        >
          <div className='exchange__logo'>
            <div
              style={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'flex-end',
              }}
            >
              <img
                src={
                  getInfoAboutCoins(symbol?.replace('-', '/')?.split('/')[0])
                    ?.logo
                }
                alt=''
              />
              <img
                src={
                  getInfoAboutCoins(symbol?.replace('-', '/')?.split('/')[1])
                    ?.logo
                }
                alt=''
                style={{ width: 20, marginLeft: -12 }}
              />
            </div>
          </div>
          <div className='exchange__details'>
            <div className='exchange__currency'>{defaultValue.name}</div>
            <div
              className='exchange__info'
              style={{ textTransform: 'uppercase' }}
            >
              {symbol?.replace('-', '/')}
            </div>
          </div>
          <div className='exchange__arrow'>
            <Icon icon='arrow-down' className='icon' />
          </div>
        </div>
        <div className='exchange__body'>
          <div className='f-center'>
            <input
              className='actions__input exchange_search__input active'
              type='search'
              placeholder='Search'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {!searchText?.length && (
              <div style={{ position: 'absolute', right: 15, top: 22 }}>
                <Icon
                  icon='search'
                  className='icon'
                  size={15}
                  color='#808191'
                />
              </div>
            )}
          </div>

          {result?.map((data, idx) => (
            <ProExchangeCellDropdownOptions
              data={data}
              key={idx}
              setSelectedCell={onChange}
              setOpenselectedCell={closeState}
              setSearchText={setSearchText}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProExchangeDropdown;
