import { useState, useEffect } from 'react';
import ExchangeBanner from '../exchange-banner/exchange-banner';
import ExchangeCell from './pro-exchange-cell/pro-exchange-cell';
import OrderBook from '../order-book/order-book';
import ExchangeAction from '../exchange-action/exchange-action';
import ExchangeMarket from '../exchange-market/exchange-market';
import ExchangeFooter from '../exchange-footer/exchange-footer';
import { useSelector } from 'react-redux';
import { useViewport } from '../../../utils/hooks';
import { useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

function ProPortfolio({
  parseFloat,
  currentUsdtAmount,
  investedAmount,
  formatCurrency,
  investedData,
  coinsLoading,
  loading,
  assetsData,
  percentage,
  userInfo,
}) {
  return (
    <div className='profit_container'>
      <div>
        <h6>Invested Value</h6>
        {userInfo?.token ? (
          <p
            className={
              parseFloat(currentUsdtAmount) < parseFloat(investedAmount)
                ? 'negative'
                : 'positive'
            }
          >
            {(investedAmount || investedAmount == 0) && !isNaN(investedAmount)
              ? formatCurrency(investedAmount)
              : '--'}
          </p>
        ) : (
          <p className={'positive'}>--</p>
        )}
      </div>
      <div>
        <h6>Current Value</h6>
        {userInfo?.token ? (
          <p
            className={
              parseFloat(currentUsdtAmount) < parseFloat(investedAmount)
                ? 'negative'
                : 'positive'
            }
          >
            {investedData?.balance && !isNaN(investedData?.balance)
              ? formatCurrency(Number(currentUsdtAmount)?.toFixed(3))
              : '--'}
          </p>
        ) : (
          <p className={'positive'}>--</p>
        )}
      </div>
      {(!coinsLoading || !loading) && assetsData && (
        <div className='profit'>
          <h6>Return</h6>
          {investedData?.balance && userInfo?.token ? (
            <p
              className={
                parseFloat(currentUsdtAmount) < parseFloat(investedAmount)
                  ? 'negative'
                  : 'positive'
              }
            >
              {' '}
              {investedData?.balance == 0.0 ||
              currentUsdtAmount === '0' ||
              isNaN(investedData?.balance) ||
              isNaN(currentUsdtAmount)
                ? `0%`
                : percentage(investedAmount, currentUsdtAmount)}
            </p>
          ) : (
            <p
              className={
                parseFloat(currentUsdtAmount) < parseFloat(investedAmount)
                  ? 'negative'
                  : 'positive'
              }
            >
              --
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default function ProMode({
  coinsLoading,
  loading,
  assetsData,
  selectedtab,
  setSelectedCell,
  selectedCell,
  selectedOption,
  ip,
  setSelectedOption,
  symbolOfCoins,
  tradeMode,
  setTradeMode,
  fetchOrderTable,
  setFetchOrderTable,
  // analytics,
  userInfo,
  getQueryParams,
  investedData,
  investedAmount,
  currentUsdtAmount,
  formatCurrency,
  parseFloat,
  percentage,
  setSelectedTab,
  orderBookPrice,
  setOrderBookPrice,
}) {
  const { width } = useViewport();
  const proModeActionRef = useRef();
  const [layout, setLayout] = useState(false);

  const handle = useFullScreenHandle();

  useEffect(() => {
    let screenLayout = localStorage.getItem('brine_layout')
      ? localStorage.getItem('brine_layout')
      : null;

    if (screenLayout == 'true') {
      setLayout(true);
    } else {
      setLayout(false);
    }
  }, [layout]);

  const handleUserScroll = () => {
    let allInputElement = document.querySelectorAll('input');
    allInputElement?.forEach((item) => {
      if (item.type === 'number') {
        item.blur();
      }
    });
  };

  useEffect(() => {
    if (proModeActionRef?.current) {
      proModeActionRef?.current?.addEventListener('scroll', handleUserScroll);
      return () => {
        proModeActionRef?.current?.removeEventListener(
          'scroll',
          handleUserScroll
        );
      };
    }
  }, [proModeActionRef]);

  return (
    <FullScreen handle={handle}>
      <div className='pro_mode_exchange exchange'>
        <div>
          <>
            <ExchangeCell
              selectedtab={selectedtab}
              setSelectedCell={setSelectedCell}
              selectedCell={selectedCell}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setTradeMode={setTradeMode}
              tradeMode={tradeMode}
              handle={handle}
              layout={layout}
              setLayout={setLayout}
            />
            <div className={`exchange__row ${layout ? 'pro_reverse' : ''}`}>
              <div className={`exchange__container`}>
                <div className={`pro_flex ${layout ? 'pro_reverse' : ''}`}>
                  <div
                    className={`pro_exchange_action `}
                    ref={proModeActionRef}
                  >
                    <ExchangeAction
                      selectedtab={selectedtab}
                      setSelectedCell={setSelectedCell}
                      selectedCell={selectedCell}
                      tradeMode={tradeMode}
                      setTradeMode={setTradeMode}
                      fetchOrderTable={fetchOrderTable}
                      setFetchOrderTable={setFetchOrderTable}
                      orderBookPrice={orderBookPrice}
                      setOrderBookPrice={setOrderBookPrice}
                    />
                    <ProPortfolio
                      parseFloat={parseFloat}
                      currentUsdtAmount={currentUsdtAmount}
                      investedAmount={investedAmount}
                      formatCurrency={formatCurrency}
                      investedData={investedData}
                      coinsLoading={coinsLoading}
                      loading={loading}
                      assetsData={assetsData}
                      percentage={percentage}
                      userInfo={userInfo}
                    />
                  </div>
                  {selectedCell?.symbol && tradeMode && (
                    <div>
                      <OrderBook
                        selectedtab={selectedtab}
                        selectedCell={selectedCell}
                        tradeMode={tradeMode}
                        orderBookPrice={orderBookPrice}
                        setOrderBookPrice={setOrderBookPrice}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='exchange__sidebar'>
                <ExchangeMarket
                  params={symbolOfCoins}
                  selectedtab={selectedtab}
                  selectedCell={selectedCell}
                  tradeMode={tradeMode}
                  setTradeMode={setTradeMode}
                  fetchOrderTable={fetchOrderTable}
                  setFetchOrderTable={setFetchOrderTable}
                />
                {!tradeMode && (
                  <ExchangeBanner
                    title='Trading & Asset Updates'
                    date='November 2020'
                    btnValue='Explore Now'
                    link={'/pricedetails/eth'}
                    // onClick={() => {
                    //   analytics?.track('Explore Now button clicked', {
                    //     coinsymbol: symbolOfCoins,
                    //     uid: userInfo?.payload?.uid,
                    //     from: 'Exchange page',
                    //     QueryParams: { ...getQueryParams() },
                    //     ip: ip,
                    //   });
                    // }}
                  />
                )}
              </div>
            </div>
            <ExchangeFooter
              selectedtab={selectedtab}
              setSelectedTab={setSelectedTab}
              tradeMode={tradeMode}
              setTradeMode={setTradeMode}
            />
          </>
        </div>
      </div>
    </FullScreen>
  );
}
