import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { images } from '../../../utils/image/img';
import CustomButton from '../../common/custom-button/custom-button';
import { useSegment } from '../../../utils/hooks';

function StaticConnect() {
  const [socialImg, setSocialImg] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const { sendTrackEvent } = useSegment();

  const socialMediaImg = [
    images.metamask,
    images.walletConnect,
    images.walletLink,
  ];

  useEffect(() => {
    const randomImgGen = setInterval(() => {
      const random = Math.floor(Math.random() * socialMediaImg.length);
      setSocialImg(socialMediaImg[random]);
    }, 2500);
    return () => {
      clearInterval(randomImgGen);
    };
  }, []);

  return (
    <div className='card card_widget text-center'>
      <div className='card__inner'>
        <div className='card__title h6'>We welcome you anon!</div>
        <img
          src={socialImg ? socialImg : images.metamask}
          alt=''
          className='card__image'
          style={{ width: 150 }}
        />
        <div className='card__description'>
          Connect your Ethereum wallet to deposit and start trading on Brine.
        </div>
        <div style={{ marginTop: 12, display: 'block' }}>
          <CustomButton
            title='Connect Wallet'
            style={{
              minWidth: '100%',
              marginTop: 10,
            }}
            onClick={() => {
              history.push({
                pathname: `/signin`,
                search: location.search,
              });
              sendTrackEvent('buttonClicked', {
                title: 'Connect Wallet',
                action: 'Routing to Login Page',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default StaticConnect;
