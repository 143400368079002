import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from '../../../components';
import { useSegment } from '../../../utils/hooks';
import { images } from '../../../utils/image/img';

function HeaderNotification({ data }) {
  const history = useHistory();
  const { sendTrackEvent } = useSegment();

  return (
    <div
      className='header__notification'
      onClick={() => {
        sendTrackEvent('notificationClicked', {
          type: data.type,
        });
        if (data.type === 'deposit') {
          if (history?.location?.pathname !== '/history') {
            history.push(`/history?tab=deposits`);
          }
        } else if (data.type === 'withdrawal') {
          if (history?.location?.pathname !== '/history') {
            history.push(`/history?tab=withdrawals&hightlight=withdrawal`);
          }
        } else if (data.type === 'fast_withdrawal') {
          if (history?.location?.pathname !== '/history') {
            history.push(`/history?tab=fast-withdrawals`);
          }
        } else {
          history.push(`/referral`);
        }
      }}
    >
      <div className='header__icon'>
        <Image src={data.logo} alt='' />
        {data?.network ? (
          <Image
            src={
              data?.network === 'ETHEREUM' ? images.ethereum : images.polygon
            }
            alt=''
            style={{
              position: 'absolute',
              left: 30,
              width: 25,
              height: 25,
              bottom: 0,
            }}
          />
        ) : null}
      </div>
      <div className='header__details'>
        <div className='header__info'>{data.msg}</div>
        <div className='header__line'>
          <div className='header__time'>{data.time}</div>
          {/* <div className='header__status'></div> */}
        </div>
      </div>
    </div>
  );
}

export default HeaderNotification;
