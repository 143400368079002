import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { ENVIRONMENT, baseCurrency } from '../constants';
import { showWSErrorMsg } from '../../actions/assestsAction';
import Decimal from 'decimal.js';
import { getDecimalJsInstance } from '../decimal';

function useWebSocketData() {
  const {
    assetsData,
    loading: assetsLoading,
    error: assetsError,
  } = useSelector((state) => state.getAssests);
  const dispatch = useDispatch();

  const { coinsDetails } = useSelector((state) => state.getCoins);
  const { response: watchListData } = useSelector(
    (state) => state.userWatchList
  );
  // const {
  //   getBanner: { bannerDetails },
  //   getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
  //   showErrorMsg: { state: errorMsgState },
  // } = useSelector((state) => state);
  const { bannerDetails } = useSelector((state) => state.getBanner);
  const {
    loading: orderLoading,
    error: orderError,
    orderBookData,
  } = useSelector((state) => state.getOrderBook);
  const { state: errorMsgState } = useSelector((state) => state.showErrorMsg);

  // function lastPrice(key) {
  //   if (
  //     key === 'usdtusdc' ||
  //     key === 'usdcusdc' ||
  //     key === 'usdcusdt' ||
  //     key === 'usdcusdc'
  //   ) {
  //     return '1.0';
  //   }
  //   let { full, partial } = getSocketFunctionalityInfo();
  //   if (full) {
  //     if (assetsData) {
  //       const price = assetsData[key]?.last;
  //       return parseFloat(price).toFixed(3);
  //     } else if (assetsLoading) {
  //       return '--';
  //     } else if (assetsError) {
  //       return '0.00';
  //     }
  //   } else if (partial) {
  //     let orderData = orderBookData[key];
  //     if (orderData) {
  //       const newOrderData = orderData;
  //       const data = newOrderData?.asks
  //         ?.sort((a, b) => Number(a[0]) - Number(b[0]))
  //         ?.slice(0, 5);

  //       if (data?.length) {
  //         return parseFloat(data[0][0]).toFixed(3);
  //       }
  //     } else {
  //       return parseFloat(0).toFixed(3);
  //     }
  //   } else {
  //     return '0.00';
  //   }
  // }

  function toFixedTrunc(x, n) {
    if (x && n) {
      // Create Decimal objects for x and the factor
      const decimalX = getDecimalJsInstance(x);
      const factor = getDecimalJsInstance(10).pow(n);

      // Multiply x by the factor, truncate, and then divide by the factor
      const result = decimalX.times(factor).trunc().div(factor);

      return result.toString(); // Convert the result back to a string
    }
  }

  function lastPrice(key) {
    // if (key === 'usdtusdc' || key === 'usdcusdc' || key === 'usdcusdc') {
    //   return getDecimalJsInstance('1000.0').toString();
    // }
    let { full, partial } = getSocketFunctionalityInfo();
    if (full) {
      if (assetsData) {
        const price = assetsData[key]?.last;
        if (price !== undefined) {
          return toFixedTrunc(getDecimalJsInstance(price).toString(), 3);
        } else if (assetsLoading) {
          return '--';
        } else if (assetsError) {
          return getDecimalJsInstance('1.00').toString();
        } else {
          return getDecimalJsInstance('1.00').toString();
        }
      }
    } else if (partial) {
      let orderData = orderBookData[key];
      if (orderData) {
        const newOrderData = orderData;
        const data = newOrderData?.asks
          ?.sort((a, b) => Number(a[0]) - Number(b[0]))
          ?.slice(0, 5);
        if (data?.length) {
          return toFixedTrunc(getDecimalJsInstance(data[0][0]).toString(), 3);
        }
      } else {
        return getDecimalJsInstance('1.00').toString();
      }
    } else {
      return getDecimalJsInstance('1.00').toString();
    }
  }

  const checkItInWatchList = (data) => {
    const filteredItem = watchListData?.payload?.find(
      (item) => item?.name === 'watch-list-1'
    );
    const checkdata = filteredItem?.coins?.coins?.filter((item) => {
      return item === data.symbol;
    });

    if (checkdata?.length) {
      return true;
    } else {
      return false;
    }
  };

  function priceChangePercent(key) {
    // if (key == 'usdcusdt') return 'fiat'.toUpperCase();
    if (assetsData) {
      let res = assetsData[key]?.price_change_percent;
      if (res == undefined) {
        return 'fiat'.toUpperCase();
      }
      return res;
    } else if (assetsLoading) {
      return '--';
    } else if (assetsError) {
      return 'NA';
    }
  }

  function getHigh(key) {
    // if (key === 'usdtusdc' || key === 'usdcusdc' || key === 'usdcusdc') {
    //   return '1.00';
    // }
    if (assetsData) {
      const price = assetsData[key]?.high;
      if (price == undefined) {
        return '1.00';
      }
      return key === 'shib'
        ? parseFloat(price).toFixed(5)
        : parseFloat(price).toFixed(3);
    } else if (assetsLoading) {
      return '--';
    } else if (assetsError) {
      return '0.00';
    }
  }

  function getLow(key) {
    // if (key === 'usdtusdc' || key === 'usdcusdc' || key === 'usdcusdc') {
    //   return '1.00';
    // }
    if (assetsData) {
      const price = assetsData[key]?.low;
      if (price == undefined) {
        return '1.00';
      }
      return key === 'shib'
        ? parseFloat(price).toFixed(5)
        : parseFloat(price).toFixed(3);
    } else if (assetsLoading) {
      return '--';
    } else if (assetsError) {
      return '0.00';
    }
  }

  function getStatus(key) {
    // if (key === 'usdtusdc' || key === 'usdcusdc' || key === 'usdcusdc') {
    //   return 'negative';
    // }
    if (assetsData) {
      const value = assetsData[key]?.price_change_percent;
      if (value == undefined) {
        return 'negative';
      }
      const status = value?.charAt(0) === '-' ? 'negative' : 'positive';
      return status;
    } else {
      return 'negative';
    }
  }

  function getVolume(key) {
    // if (key === 'usdtusdc' || key === 'usdcusdc' || key === 'usdcusdc') {
    //   return '1.00';
    // }

    if (assetsData) {
      const price = assetsData[key]?.volume;
      if (price == undefined) {
        return '1.00';
      }
      return key === 'shib'
        ? parseFloat(price).toFixed(5)
        : parseFloat(price).toFixed(3);
    } else if (assetsLoading) {
      return '--';
    } else if (assetsError) {
      return '0.00';
    }
  }

  function getAvgPrice(key) {
    // if (key === 'usdtusdc' || key === 'usdcusdc' || key === 'usdcusdc') {
    //   return '1.00';
    // }
    if (assetsData) {
      const price = assetsData[key]?.avg_price;
      if (price == undefined) {
        return '1.00';
      }
      return key === 'shib'
        ? parseFloat(price).toFixed(5)
        : parseFloat(price).toFixed(3);
    } else if (assetsLoading) {
      return '--';
    } else if (assetsError) {
      return '0.00';
    }
  }

  function formatTime(key) {
    if (key === 'usdtusdt') return moment.unix(1643376152).format('h:mm a');
    if (key === 'usdcusdt') return moment.unix(1643376152).format('h:mm a');

    if (assetsData) {
      const value = assetsData[key]?.at;
      let date = moment.unix(value).format('h:mm a');
      return date;
    }
  }

  const getTopLooser = () => {
    if (coinsDetails?.convertedArray && assetsData) {
      const result = _.filter(coinsDetails?.convertedArray, function (o) {
        return (
          assetsData[o.symbol + baseCurrency]?.price_change_percent?.charAt(
            0
          ) === '-'
        );
      });
      const leastValue = _.orderBy(
        result,
        function (o) {
          return parseInt(
            assetsData[o.symbol + baseCurrency]?.price_change_percent
          );
        },
        ['asc']
      );

      return leastValue;
    }
  };

  const getTopGainer = () => {
    if (coinsDetails?.convertedArray && assetsData) {
      const result = _.filter(coinsDetails?.convertedArray, function (o) {
        return (
          assetsData[o.symbol + baseCurrency]?.price_change_percent?.charAt(
            0
          ) === '+'
        );
      });
      const topValue = _.orderBy(
        result,
        function (o) {
          return parseInt(
            assetsData[o.symbol + baseCurrency]?.price_change_percent
          );
        },
        ['desc']
      );
      return topValue;
    }
  };

  const getMarketPrecision = (pair) => {
    if (bannerDetails) {
      return {
        amountPrecision: bannerDetails?.payload[pair]?.amount_precision,
        pricePrecision: bannerDetails?.payload[pair]?.price_precision,
      };
    }
  };

  const getMarketsWithSlash = () => {
    let marketObj = {};
    if (bannerDetails) {
      bannerDetails?.payload?.markets_with_slash?.forEach((item) => {
        marketObj[item] = false;
      });

      return marketObj;
    }
  };

  const getSocketFunctionalityInfo = () => {
    if (assetsData && orderBookData) {
      if (errorMsgState !== false) {
        dispatch(showWSErrorMsg(false));
      }
      return {
        full: true,
        partial: false,
      };
    } else if (
      !assetsData &&
      !assetsLoading &&
      assetsError &&
      orderBookData &&
      !orderLoading
    ) {
      for (const key in orderBookData) {
        if (!orderBookData[key]?.bids?.length) {
          if (errorMsgState !== true) {
            dispatch(showWSErrorMsg(true));
          }
          return {
            full: false,
            partial: false,
          };
        }
      }
      if (errorMsgState !== false) {
        dispatch(showWSErrorMsg(false));
      }
      return {
        full: false,
        partial: true,
      };
    } else {
      return {
        full: false,
        partial: false,
      };
    }
  };

  const minutesDiff = (unixTimestamp) => {
    const currentTime = new Date();
    const unixMinutes = Math.floor(unixTimestamp / 60000);
    const currentMinutes = Math.floor(currentTime.getTime() / 60000);
    return currentMinutes - unixMinutes;
  };

  const formatMarketPair = (symbol) => {
    if (symbol) {
      if (ENVIRONMENT === 'mainnet') {
        if (symbol === 'usdt') {
          return `${symbol}usdc`;
        } else {
          return `${symbol}${baseCurrency}`;
        }
      } else {
        if (symbol === 'usdc') {
          return `${symbol}usdt`;
        } else {
          return `${symbol}${baseCurrency}`;
        }
      }
    }
  };

  return {
    getTopLooser,
    lastPrice,
    getVolume,
    getStatus,
    priceChangePercent,
    getTopGainer,
    formatTime,
    getHigh,
    getLow,
    getAvgPrice,
    checkItInWatchList,
    getMarketPrecision,
    getSocketFunctionalityInfo,
    getMarketsWithSlash,
    minutesDiff,
    formatMarketPair,
  };
}

export default useWebSocketData;
