import React, { useEffect, useRef, useState } from 'react';
import brineAPI from '../../../../utils/charts/trade-view/api';
import Loader from '../../../common/loader/loader';
import { networkName } from '../../../../utils/urls';
import { widget } from '../../../../utils/tv/charting_library';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

let bfAPI = new brineAPI({ debug: false });

const BrineTVChartContainer = ({ symbol, themeColor }) => {
  const chartContainerRef = useRef();
  const [isChartReady, setIsChartReady] = useState(false);
  const [chartWidget, setChartWidget] = useState(null);

  const defaultProps = {
    interval: 'D',
    // datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: '/scripts/charting_library/',
    // chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    // clientId: 'tradingview.com',
    // userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    // studiesOverrides: {},
    timezone: 'Asia/Kolkata',
  };

  let disabled_features = [
    'study_templates',
    // 'border_around_the_chart',
    // 'chart_property_page_background',
    // 'chart_property_page_scales',
    // 'chart_property_page_style',
    // 'chart_property_page_timezone_sessions',
    // 'compare_symbol',
    // // 'header_resolutions',
    // 'control_bar',
    // 'countdown',
    // // 'create_volume_indicator_by_default',
    // 'display_market_status',
    // 'edit_buttons_in_legend',
    // 'go_to_date',
    'header_chart_type',
    // 'header_compare',
    // // 'header_settings',
    'header_saveload',
    // // 'header_fullscreen_button',
    // 'legend_widget',
    // 'header_screenshot',
    'header_symbol_search',
    'header_undo_redo',
    // 'header_widget_dom_node',
    // 'hide_last_na_study_output',
    // 'hide_left_toolbar_by_default',
    // 'legend_context_menu',
    // 'main_series_scale_menu',
    // 'pane_context_menu',
    // // 'show_chart_property_page',
    'study_dialog_search_control',
    'save_chart_properties_to_local_storage',
    'symbol_info',
    'timeframes_toolbar',
    'timezone_menu',
    'header_toolbar_study_templates',
    'volume_force_overlay',
  ];

  useEffect(() => {
    setIsChartReady(false);
    const widgetOptions = {
      symbol: symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: bfAPI,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      timezone: 'Asia/Kolkata',
      locale: getLanguageFromURL() || 'en',
      disabled_features: disabled_features,
      enabled_features: [
        // 'volume_force_overlay',
        'use_localstorage_for_settings',
      ],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      // studies_overrides: defaultProps.studiesOverrides,
      interval: networkName === 'mainnet' ? '120' : '240',
    };

    const tvWidget = new widget(widgetOptions);
    if (tvWidget?.onChartReady) {
      tvWidget?.onChartReady(() => {
        tvWidget?.headerReady().then(() => {
          // const button = tvWidget.createButton();
          // button.setAttribute('title', 'Click to show a notification popup');
          // button.classList.add('apply-common-tooltip');
          // button.addEventListener('click', () =>
          //   tvWidget.showNoticeDialog({
          //     title: 'Notification',
          //     body: 'TradingView Charting Library API works correctly',
          //     callback: () => {
          //       console.log('Noticed!');
          //     },
          //   })
          // );
          // button.innerHTML = 'Check API';
          setChartWidget(tvWidget);
          setIsChartReady(true);
          // tvWidget.changeTheme(themeColor);
          tvWidget?.symbolSync();
        });
      });

      return () => {
        tvWidget?.remove();
      };
    }
  }, [symbol]);

  useEffect(() => {
    if (chartWidget && isChartReady) {
      chartWidget?.changeTheme(themeColor);
    }
  }, [themeColor, chartWidget, isChartReady]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {!isChartReady && (
        <div className='chart__loader'>
          <Loader />
        </div>
      )}
      <div
        ref={chartContainerRef}
        className={'TVChartContainer'}
        style={{
          visibility: !isChartReady ? 'hidden' : 'visible',
        }}
      />
    </div>
  );
};

export default BrineTVChartContainer;
