import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { themeReducer } from './reducers/themeReducer';
import { navbarReducer } from './reducers/navbarReducer';
import {
  get2FAReducer,
  settingReducer,
  verify2FAReducer,
} from './reducers/settingsReducer';
import {
  userAddNameReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userRegisterReducer,
  userAddUniqueNameReducer,
  userGetBalanceReducer,
  userDetailsReducer,
  updateBankNumberReducer,
  updateUPIDetailReducer,
  updateIFSCDetailReducer,
  updatePasswordReducer,
  resendMailReducer,
  portfolioReducer,
  googleLoginReducer,
  userDepositLevelReducer,
  user2FALevelReducer,
  userKeyReducer,
  getVaultIdListReducer,
  userViaMethodReducer,
  getUserIPAddressReducer,
  storeWeb3AuthProviderReducer,
  notificatedReducer,
  networkReducer,
  // tickerStartFetchReducer,
  remainingMeReducer,
  getUserPermissionDetailsReducer,
} from './reducers/userReducer';
import {
  getAssestsReducer,
  getBannerDetailsReducer,
  getChartDataReducer,
  getCoinsDetailsReducer,
  getNewsReducer,
  getNotificationReducer,
  getUserWatchListReducer,
  showWebSocketErrorReducer,
  storeUserMsgReducer,
  getRangoOrderBookReducer,
  getDepositDetailsReducer,
  getUserFeatureFlagReducer,
} from './reducers/assestsReducer';
import {
  cancelOrderReducer,
  createOrderReducer,
  // getOrderBookReducer,
} from './reducers/exchangeReducer';
import {
  getSaltLeaderboardReducer,
  getSaltScoreFlagReducer,
  getSaltScoreReducer,
  // getOrderBookReducer,
} from './reducers/saltReducers';
import {
  addPersonalInfoReducer,
  kycViewDetailsReducer,
  networkCheckReducer,
  sendPhoneCodeReducer,
  verifyCodeReducer,
} from './reducers/kycReducer';
import {
  getDepositDataReducer,
  getDEXDepositDataReducer,
  getDEXFastWithdrawDataReducer,
  getOrderDataReducer,
  getPendingOrderDataReducer,
  getTradeDataReducer,
  getWithrawDataReducer,
} from './reducers/historyReducer';
import {
  getExternalCampaignListReducer,
  getFeeInfoForClaimReducer,
  getReferralCodeEarningReducer,
  getReferralCodeReducer,
  getScratchCardReducer,
  getTotalReferralsReducer,
} from './reducers/rewardsReducer';
import { getWithdrawalVaultIdReducer } from './reducers/walletReducer';
import {
  getArchivedLeaderboardCampaignsReducer,
  getLeaderboardCampaignsReducer,
} from './reducers/leaderboardReducer';
import { ALLOWED_NETWORK_ID } from './utils/constants';

export const prefix = 'krypto-brine';

const reducer = combineReducers({
  theme: themeReducer,
  navbar: navbarReducer,
  setting: settingReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgotPassword: userForgotPasswordReducer,
  userAddName: userAddNameReducer,
  userAddUniqueName: userAddUniqueNameReducer,
  getAssests: getAssestsReducer,
  // getRangoOrderBook: getRangoOrderBookReducer,
  getCoins: getCoinsDetailsReducer,
  getBanner: getBannerDetailsReducer,
  news: getNewsReducer,
  userGetBalance: userGetBalanceReducer,
  userDepositState: userDepositLevelReducer,
  getPortfolio: portfolioReducer,
  getChartData: getChartDataReducer,
  createOrder: createOrderReducer,
  cancelOrder: cancelOrderReducer,
  getOrderBook: getRangoOrderBookReducer,
  userDetails: userDetailsReducer,
  userWatchList: getUserWatchListReducer,
  updateBankNumber: updateBankNumberReducer,
  updateUPIDetail: updateUPIDetailReducer,
  updateIFSCDetail: updateIFSCDetailReducer,
  updatePassword: updatePasswordReducer,
  getOtpKyc: sendPhoneCodeReducer,
  verifyOtpKyc: verifyCodeReducer,
  addPersonalInfoKyc: addPersonalInfoReducer,
  networkCheck: networkCheckReducer,
  resendMail: resendMailReducer,
  orderData: getOrderDataReducer,
  pendingOrderData: getPendingOrderDataReducer,
  tradeData: getTradeDataReducer,
  depositData: getDepositDataReducer,
  withDrawData: getWithrawDataReducer,
  fastWithDrawData: getDEXFastWithdrawDataReducer,
  get2FA: get2FAReducer,
  verify2FA: verify2FAReducer,
  kycViewDetails: kycViewDetailsReducer,
  googleAuth: googleLoginReducer,
  getReferralCode: getReferralCodeReducer,
  getReferralEarning: getReferralCodeEarningReducer,
  getTotalReferrals: getTotalReferralsReducer,
  getScratchCard: getScratchCardReducer,
  twoFAData: user2FALevelReducer,
  userKey: userKeyReducer,
  vaultList: getVaultIdListReducer,
  dexDepositData: getDEXDepositDataReducer,
  claimfee: getFeeInfoForClaimReducer,
  userVia: userViaMethodReducer,
  withdrawalIds: getWithdrawalVaultIdReducer,
  userIpAddress: getUserIPAddressReducer,
  campaignsList: getLeaderboardCampaignsReducer,
  archivedCampaignsList: getArchivedLeaderboardCampaignsReducer,
  web3AuthProvider: storeWeb3AuthProviderReducer,
  notificationDetails: getNotificationReducer,
  userMsg: storeUserMsgReducer,
  is_notificated: notificatedReducer,
  showErrorMsg: showWebSocketErrorReducer,
  externalCampaignList: getExternalCampaignListReducer,
  depositDetails: getDepositDetailsReducer,
  ethNetworkDetails: networkReducer,
  saltLeaderBoard: getSaltLeaderboardReducer,
  saltScore: getSaltScoreReducer,
  saltScoreFlag: getSaltScoreFlagReducer,
  userFeatureFlag: getUserFeatureFlagReducer,
  rememberMeFlag: remainingMeReducer,
  userPermission: getUserPermissionDetailsReducer,
  // startTickerFetch: tickerStartFetchReducer,
});

const getValuefromStorage = (key, defaultValue, shouldParse = true) => {
  try {
    let res = localStorage.getItem(prefix + '.CHANGE_REMEMBER_ME')
      ? JSON.parse(localStorage.getItem(prefix + '.CHANGE_REMEMBER_ME'))
      : false;

    if (res) {
      let value = localStorage.getItem(key)
        ? shouldParse
          ? JSON.parse(localStorage.getItem(key))
          : localStorage.getItem(key)
        : defaultValue;
      return value;
    } else {
      let value = sessionStorage.getItem(key)
        ? shouldParse
          ? JSON.parse(sessionStorage.getItem(key))
          : sessionStorage.getItem(key)
        : defaultValue;
      return value;
    }
  } catch (error) {
    console.log({ error }, '+', key, defaultValue);
  }
};

// const userKey = sessionStorage.getItem(`${prefix}.key`)
//   ? sessionStorage.getItem(`${prefix}.key`)
//   : null;
// const userInfoFromStorage = sessionStorage.getItem(`${prefix}_userInfo`)
//   ? JSON.parse(sessionStorage.getItem(`${prefix}_userInfo`))
//   : null;

const getTheme = localStorage.getItem(prefix + '.theme') === 'true' || false;
const getIsUserNotificated =
  localStorage.getItem(prefix + '.notificated-state') === 'true' || false;

const userInfoFromStorage = getValuefromStorage(`${prefix}_userInfo`, null);
const userKey = getValuefromStorage(`${prefix}.key`, null, false);
const userWatchList = getValuefromStorage(`watchlist`, []);
const userVia = getValuefromStorage(`${prefix}.method`, null);
const currentNetwork = getValuefromStorage(`${prefix}.eth-network`, {
  queuedCoin: null,
  network: 'ethereum',
  loading: false,
  chainId: ALLOWED_NETWORK_ID,
});
// const userWatchList = sessionStorage.getItem(`watchlist`)
//   ? JSON.parse(sessionStorage.getItem(`watchlist`))
//   : [];

// const userVia = sessionStorage.getItem(`${prefix}.method`)
//   ? JSON.parse(sessionStorage.getItem(`${prefix}.method`))
//   : null;

const userMsg = sessionStorage.getItem(`${prefix}.msg`)
  ? JSON.parse(sessionStorage.getItem(`${prefix}.msg`))
  : [];

const rememberMeFlag = localStorage.getItem(prefix + '.CHANGE_REMEMBER_ME')
  ? JSON.parse(localStorage.getItem(prefix + '.CHANGE_REMEMBER_ME'))
  : false;

// const currentNetwork = sessionStorage.getItem(`${prefix}.eth-network`)
//   ? JSON.parse(sessionStorage.getItem(`${prefix}.eth-network`))
//   : {
//       queuedCoin: null,
//       network: 'ethereum',
//       loading: false,
//       chainId: ALLOWED_NETWORK_ID,
//     };

const initialState = {
  theme: { isdark: getTheme },
  userLogin: { userInfo: userInfoFromStorage },
  userWatchList: { data: userWatchList },
  userKey: { state: userKey },
  userVia: { state: userVia },
  userMsg: { state: userMsg },
  is_notificated: { notificated: getIsUserNotificated },
  showErrorMsg: { state: false },
  ethNetworkDetails: { state: currentNetwork },
  rememberMeFlag: { remember: rememberMeFlag },
  // startTickerFetch: { state: false },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
