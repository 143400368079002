import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addWatchList,
  getWatchList,
  removeWatchList,
} from '../../../actions/assestsAction';
import { getQueryParams } from '../../../utils/query-helper';
import {
  useAnalytics,
  useViewport,
  useWebSocketData,
} from '../../../utils/hooks';
import {
  Icon,
  SkeletonContainer,
  Image,
  DotRenderer,
} from '../../../components';
import { baseCurrency } from '../../../utils/constants';

function DetailsWidgetCard({ data, scrollToSection }) {
  const {
    getStatus,
    priceChangePercent,
    lastPrice,
    checkItInWatchList,
    getSocketFunctionalityInfo,
  } = useWebSocketData();
  // const { analytics } = useAnalytics();
  const location = useLocation();

  // const {
  //   // theme: { isdark },
  //   // userWatchList: { loading, response },
  //   // userLogin: { userInfo },
  //   // userIpAddress: { ip },
  // } = useSelector((state) => state);

  const { isdark } = useSelector((state) => state.theme);
  const { loading, response } = useSelector((state) => state.userWatchList);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { ip } = useSelector((state) => state.userIpAddress);

  const [oppositeStar, setOppositeStar] = useState(false);

  const history = useHistory();

  const { width } = useViewport();

  const dispatch = useDispatch();

  const handleWatchList = async (coin) => {
    //console.log(data.symbol, response?.payload?.coins.coins, 'data');

    if (response) {
      const filteredItem = response?.payload?.find(
        (item) => item?.name === 'watch-list-1'
      );
      const checkdata = filteredItem?.coins.coins?.filter((item) => {
        return item == coin.symbol;
      });
      //console.log(checkdata);
      if (checkdata.length > 0) {
        try {
          const data = await removeWatchList(
            userInfo.token.access,
            coin.symbol
          );
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          // analytics?.track('Remove watchlist success', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: coin?.symbol,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
        } catch (e) {
          //console.log(e);
          // analytics?.track('Remove watchlist failed', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: coin?.symbol,
          //   reason: e,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
          toast.error(e);
        }
      } else {
        try {
          const data = await addWatchList(userInfo.token.access, coin.symbol);
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          // analytics?.track('Add watchlist success', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: coin?.symbol,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
        } catch (e) {
          // analytics?.track('Add watchlist failed', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: coin?.symbol,
          //   reason: e,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
          toast.error(e);
        }
      }
    }
  };

  useEffect(() => {
    if (userInfo?.token) {
      dispatch(getWatchList(userInfo.token.access));
    }
  }, [userInfo?.token]);

  return (
    <>
      <div className={`${isdark ? 'dark' : ''}  widgets__box`}>
        <div
          onClick={() => {
            history.push({
              pathname: `/pricedetails/${data.symbol}`,
              search: location.search,
            });
            width < 700 && scrollToSection();
          }}
          className='widget__link'
        >
          <div className='widgets__top'>
            <div className='widgets__company'>
              <div className='widgets__logo'>
                <Image src={data.logo} alt='' />
              </div>
              <div className='widgets__details'>
                <div className='widgets__category'>{data.name}</div>
                <div
                  className='widgets__info'
                  style={{ textTransform: 'uppercase' }}
                >
                  {data.symbol}
                </div>
              </div>
            </div>
            <div className='widgets__group'>
              <DotRenderer style={{ marginBottom: 15 }}>
                <div
                  style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}
                  className={`${
                    data.symbol != 'usdt' ? 'status' : ''
                  } ${getStatus(`${data.symbol}${baseCurrency}`)}`}
                >
                  {priceChangePercent(`${data.symbol}${baseCurrency}`)}
                </div>
              </DotRenderer>
              <div className='widgets__price'>
                {lastPrice(`${data.symbol}${baseCurrency}`)}{' '}
                {baseCurrency?.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="widgets__chart">
          <WidgetLineChart
            color={data.status === 'positive' ? '#3DBAA2' : '#FF7A68'}
          />
        </div> */}
        {loading ? (
          <div className='widgets__favorite'>
            <SkeletonContainer
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                marginTop: 5,
                marginLeft: 6,
              }}
            />
          </div>
        ) : (
          <button
            className='widgets__favorite'
            onClick={() => handleWatchList(data)}
          >
            {checkItInWatchList(data) ? (
              <Icon icon='star-fill' className='icon' />
            ) : (
              <Icon icon='star' className='icon' />
            )}
          </button>
        )}
      </div>
    </>
  );
}

export default DetailsWidgetCard;
