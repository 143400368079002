import React from 'react';
import { CustomButton, Image, Popup } from '../../../components';
import { useLocation } from 'react-router-dom';
import animationData from '../../../assests/json/close-animation.json';
import Lottie from 'react-lottie';

export function CancelAllConfirmationPopup({
  openCancelAllPopup,
  setOpenCancelAllPopup,
  handleCancelAllOrder,
  handleCancelSpecficOrder,
  symbol,
}) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className='share__popup network__error'>
      <Popup state={openCancelAllPopup} setState={setOpenCancelAllPopup}>
        <div className='h6'>
          <span style={{ color: '#ff7a68' }}>Cancel</span> pending orders
        </div>
        {/* <Image src={images.networkError} /> */}
        <Lottie options={defaultOptions} height={150} width={150} />

        <p style={{ fontSize: 12, marginTop: 12 }}>
          Are you sure you want to cancel all of{' '}
          {/* <span style={{ color: '#ff7a68' }}>{symbol?.toUpperCase()}</span>  */}
          your pending orders? If yes, then you can proceed by choosing cancel
          below.
        </p>
        <CustomButton
          title={`Cancel ${symbol?.toUpperCase()} Orders`}
          addClassName='disconnect_btn btn_red mute_hover'
          style={{ height: 45, width: '90%' }}
          onClick={() => {
            handleCancelSpecficOrder();
            setOpenCancelAllPopup((prev) => !prev);
          }}
        />
        <CustomButton
          title={`Cancel All Orders`}
          addClassName='btn_red mute_hover'
          style={{ height: 45, width: '90%' }}
          onClick={() => {
            handleCancelAllOrder();
            setOpenCancelAllPopup(false);
          }}
        />
      </Popup>
    </div>
  );
}

export default CancelAllConfirmationPopup;
