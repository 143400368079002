import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useBalance } from '../../../../utils/hooks';

function ProExchangeCellDropdownOptions({
  setSelectedCell,
  setOpenselectedCell,
  data,
  setSearchText,
  ...otherProps
}) {
  const history = useHistory();
  const location = useLocation();

  const { getInfoAboutCoins } = useBalance();

  return (
    <>
      {data.symbol !== 'inr' && (
        <button
          {...otherProps}
          className='exchange__item'
          onClick={() => {
            history.push({
              pathname: `/exchange/${data?.replace('/', '-')}`,
              search: location.search,
            });
            setOpenselectedCell((prev) => !prev);
            setSearchText('');
          }}
          style={{ width: '100%' }}
        >
          <>
            <div className='exchange__logo'>
              <div
                style={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <img
                  src={getInfoAboutCoins(data?.split('/')[0])?.logo}
                  alt={getInfoAboutCoins(data?.split('/')[0])?.info}
                />
                <img
                  style={{ width: 20, marginLeft: -12 }}
                  src={getInfoAboutCoins(data?.split('/')[1])?.logo}
                  alt={getInfoAboutCoins(data?.split('/')[1])?.info}
                />
              </div>
            </div>
            <div className='exchange__details'>
              <div className='exchange__currency'>
                {getInfoAboutCoins(data?.split('/')[0]).name}
              </div>
              <div
                className='exchange__info'
                style={{
                  textTransform: 'uppercase',
                  marginTop: 3,
                }}
              >
                {data}
              </div>
            </div>
          </>
        </button>
      )}
    </>
  );
}

export default ProExchangeCellDropdownOptions;
