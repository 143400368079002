import axios from 'axios';
import * as assestsConstants from '../constants/assestsConstant';
import { baseUrl, newsUrl, webSocketUrl } from '../utils/urls';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { prefix } from '../store';
import { objToParams } from '../utils/query-helper';
import { ENVIRONMENT } from '../utils/constants';
import { formatOrderBook, orderBookCleanup } from '../utils/order-book';
import { WebSocketTimeoutHelper } from '../utils/websocket-timeout-helper';
import * as Sentry from '@sentry/react';
import uniqBy from 'lodash/unionBy';

let queueObj = {};
let isSending = false;
let throttleTimeOut = 3000;

export let ws = new ReconnectingWebSocket(
  `${webSocketUrl}/public?stream=global.tickers`
);
// ws://18.138.227.25:8080

// 1ms
const websocketTimeoutDuration = 60000;

const globalTickerTimeoutHelper = new WebSocketTimeoutHelper(
  websocketTimeoutDuration,
  'global.tickers'
);

export const getAssests = () => async (dispatch) => {
  ws.onopen = () => {
    // on connecting, do nothing but log it to the console
    // dispatch({
    //   type: assestsConstants.GET_ASSETS_DETAILS_WS_REQUEST,
    // });
    //console.log('connected');
    globalTickerTimeoutHelper.startTimeout();
  };

  ws.onmessage = (evt) => {
    // listen to data sent from the websocket server
    if (evt.data !== 'Connection established with Brine') {
      const message = JSON.parse(evt.data);
      if (
        message?.['global.tickers'] &&
        Object.keys(message?.['global.tickers']).length > 0
      ) {
        globalTickerTimeoutHelper.clearTimeoutAndResolve();
        dispatch({
          type: assestsConstants.GET_ASSETS_DETAILS_WS_SUCCESS,
          payload: message,
        });
      }
    }
  };

  ws.onclose = () => {
    //console.log('disconnected');
    // dispatch({
    //   type: assestsConstants.GET_ASSETS_DETAILS_WS_FAIL,
    //   // payload: 'Reconnecting....',
    // });
    // setTimeout(() => {
    //   dispatch(getAssests());
    // }, 1000);
    globalTickerTimeoutHelper.clearTimeoutAndResolve();
  };

  ws.onerror = (err) => {
    console.error(
      'Livewire Socket encountered error: ',
      err.message,
      'Closing socket'
    );
    // dispatch({
    //   type: assestsConstants.GET_ASSETS_DETAILS_WS_FAIL,
    //   payload: 'Something went wrong',
    // });
    Sentry.captureMessage(
      `[global.ticker] websocket is throwing an error. ${
        err ? JSON.stringify(err) : ''
      }`
    );
    globalTickerTimeoutHelper.clearTimeoutAndResolve();
    // setTimeout(() => {
    //   dispatch(getAssests());
    // }, 1000);
  };
};

export const throttleData = (dispatch, data) => {
  queueObj = formatOrderBook(data);
  if (!isSending) {
    isSending = true;
    setTimeout(() => {
      isSending = false;
      dispatch({
        type: assestsConstants.GET_RANGO_ORDERBOOK_WS_SUCCESS,
        payload: queueObj,
      });
    }, throttleTimeOut);
  }
};

const formatAssestsData = (data) => {
  const convertedData = {};
  for (const key in data) {
    const ticker = data[key].ticker;
    convertedData[key] = { ...ticker, at: data[key].at };
  }
  return { 'global.tickers': convertedData };
};

export const getTickerDetails =
  (isBackground = false) =>
  async (dispatch) => {
    try {
      if (!isBackground) {
        dispatch({
          type: assestsConstants.GET_ASSETS_DETAILS_WS_REQUEST,
        });
      }
      const config = {
        headers: {},
      };
      const { data } = await axios.get(`${baseUrl}/v1/market/tickers/`, config);
      let assetsData = formatAssestsData(data?.payload);
      dispatch({
        type: assestsConstants.GET_ASSETS_DETAILS_WS_SUCCESS,
        payload: assetsData,
      });
    } catch (error) {
      Sentry.captureMessage(
        `Ticker endpoint is throwing an error. ${
          error ? JSON.stringify(error) : ''
        }`
      );
      dispatch({
        type: assestsConstants.GET_ASSETS_DETAILS_WS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getRangoOrderBook = () => async (dispatch) => {
  let rangoOrderBookWS = new ReconnectingWebSocket(
    `${webSocketUrl}/public`,
    [],
    {
      maxRetries: 1,
      // debug: true,
    }
  );
  rangoOrderBookWS.onopen = () => {
    // on connecting, do nothing but log it to the console
    dispatch({
      type: assestsConstants.GET_RANGO_ORDERBOOK_WS_REQUEST,
    });
    rangoOrderBookWS.send(
      JSON.stringify({
        event: 'subscribe',
        streams:
          ENVIRONMENT === 'mainnet'
            ? ['ethusdt.ob-inc', 'btcusdt.ob-inc', 'usdtusdc.ob-inc']
            : [
                'ethusdt.ob-inc',
                'btcusdt.ob-inc',
                'usdcusdt.ob-inc',
                'ethusdc.ob-inc',
                'btcusdc.ob-inc',
                'maticusdc.ob-inc',
              ],
      })
    );
    //console.log('connected');
  };

  rangoOrderBookWS.onmessage = (evt) => {
    // listen to data sent from the websocket server
    if (!evt.data['success']) {
      // due to the malform function, parse twice
      const message = JSON.parse(evt.data);
      let formattedData = formatOrderBook(message);
      if (Object.keys(message)[0]?.split('.')[1] == 'ob-snap') {
        dispatch({
          type: assestsConstants.GET_RANGO_ORDERBOOK_WS_SUCCESS,
          payload: formattedData,
        });
      } else {
        // setTimeout(() => {
        //   dispatch({
        //     type: assestsConstants.GET_RANGO_ORDERBOOK_WS_SUCCESS,
        //     payload: formattedData,
        //   });
        // }, 3000);
        throttleData(dispatch, message);
      }
    }
  };

  rangoOrderBookWS.onclose = () => {
    //console.log('disconnected');
    dispatch({
      type: assestsConstants.GET_RANGO_ORDERBOOK_WS_FAIL,
      // payload: 'Reconnecting....',
    });
  };

  rangoOrderBookWS.onerror = (err) => {
    console.error('Socket encountered error: ', err.message, 'Closing socket');

    Sentry.captureMessage(
      `Rango orderwire websocket is throwing an error. ${
        err ? JSON.stringify(err) : ''
      }`
    );

    dispatch({
      type: assestsConstants.GET_RANGO_ORDERBOOK_WS_FAIL,
      payload: 'Something went wrong',
    });
  };
};

export const getCoinsDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: assestsConstants.GET_COINS_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        // Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(`${baseUrl}/stat/v2/coins/`, {}, config);
    let datas = {
      normalData: data,
      convertedArray: Object.keys(data?.payload).map((key) => {
        return data?.payload[key];
      }),
    };
    dispatch({
      type: assestsConstants.GET_COINS_DETAILS_SUCCESS,
      payload: datas,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_COINS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

function removeTokenFromPolygon(orginalData, tokenToRemove) {
  if (
    orginalData.payload &&
    orginalData.payload.network_config &&
    orginalData.payload.network_config.POLYGON
  ) {
    const polygonConfig = orginalData.payload.network_config.POLYGON;

    if (polygonConfig.tokens && polygonConfig.tokens[tokenToRemove]) {
      delete polygonConfig.tokens[tokenToRemove];
    }
  }
  return orginalData;
}

export const getDepositDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: assestsConstants.GET_DEPOSIT_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        // Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/stat/v2/app-and-markets/`,
      {},
      config
    );
    // let datas = {
    //   normalData: data,
    //   convertedArray: Object.keys(data?.payload).map((key) => {
    //     return data?.payload[key];
    //   }),
    // };
    dispatch({
      type: assestsConstants.GET_DEPOSIT_DETAILS_SUCCESS,
      payload: removeTokenFromPolygon(data, 'matic'),
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_DEPOSIT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBannerDetails = () => async (dispatch, getState) => {
  try {
    const {
      // userLogin: { userInfo },
    } = getState();

    dispatch({
      type: assestsConstants.GET_BANNER_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        // Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/stat/v2/markets/`,
      {},
      config
    );

    dispatch({
      type: assestsConstants.GET_BANNER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_BANNER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// function makeListUniqueByURL(data) {
//   if (!data || !data.payload || !Array.isArray(data.payload)) {
//     return data;
//   }
//   const uniquePayload = uniqBy(data.payload, 'url');
//   return {
//     ...data,
//     payload: uniquePayload,
//   };
// }

export const getNews = (coin) => async (dispatch) => {
  try {
    dispatch({
      type: assestsConstants.GET_NEWS_REQUEST,
    });

    const { data } = await axios.get(`${newsUrl}/news/news/?coin=${coin}`);

    dispatch({
      type: assestsConstants.GET_NEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_NEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getChartData =
  (coin, inter = '', from_date = '', to_date = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: assestsConstants.GET_CHART_DATA_REQUEST,
      });

      let params = {
        market: coin,
        limit: '10',
        period: inter,
        start_time: from_date,
        end_time: to_date,
      };

      let queryParams = objToParams(params);

      const { data } = await axios.get(
        `${baseUrl}/market/kline/?${queryParams}`
      );

      dispatch({
        type: assestsConstants.GET_CHART_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: assestsConstants.GET_CHART_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getWatchList = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: assestsConstants.GET_WATCHLIST_DETAILS_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}/watchlist/`, config);
    dispatch({
      type: assestsConstants.GET_WATCHLIST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_WATCHLIST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addWatchList = async (token, symbol) => {
  //console.log(token, symbol);
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/watchlist/add/`,
      { name: 'watch-list-1', coin: symbol },
      config
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const cancelAll = async (token, symbol) => {
  //console.log(token, symbol);
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    let resBody = {};
    if (symbol) {
      resBody['market'] = symbol;
    }
    const { data } = await axios.post(
      `${baseUrl}/orders/bulk_cancel/`,
      resBody,
      config
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const removeWatchList = async (token, symbol) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/watchlist/remove/`,
      { coin: symbol, name: 'watch-list-1' },
      config
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const healthCheck = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/`);
    return data;
  } catch (error) {
    throw error?.request?.status
      ? error?.request?.status
      : error?.response?.status;
  }
};

export const getNotificationDetails = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  let ws = new ReconnectingWebSocket(
    `${webSocketUrl}/private?auth_header=${userInfo.token.access}`
  );

  ws.onopen = () => {
    // on connecting, do nothing but log it to the console
    dispatch({
      type: assestsConstants.GET_NOTIFICATION_DETAILS_WS_REQUEST,
    });
    ws.send(
      JSON.stringify({
        event: 'subscribe',
        streams: [
          'referral_reward',
          'deposit',
          'fast_withdrawal',
          'trade',
          'order',
        ],
      })
    );
    //console.log('connected');
  };

  ws.onmessage = (evt) => {
    // listen to data sent from the websocket server
    if (evt.data !== 'Connection established with Brine') {
      const message = JSON.parse(evt.data);
      dispatch({
        type: assestsConstants.GET_NOTIFICATION_DETAILS_WS_SUCCESS,
        payload: message,
      });
    }
  };

  ws.onclose = () => {
    //console.log('disconnected');
    dispatch({
      type: assestsConstants.GET_NOTIFICATION_DETAILS_WS_FAIL,
      // payload: 'Reconnecting....',
    });
    // setTimeout(() => {
    //   dispatch(getAssests());
    // }, 1000);
  };

  ws.onerror = (err) => {
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    dispatch({
      type: assestsConstants.GET_NOTIFICATION_DETAILS_WS_FAIL,
      // payload: 'Something went wrong',
    });
  };
};

export const storeMsg = (msgList) => async (dispatch) => {
  dispatch({
    type: assestsConstants.STORE_NOTIFICATION_SUCCESS,
    payload: msgList,
  });
  sessionStorage.setItem(`${prefix}.msg`, JSON.stringify(msgList));
};

export const showWSErrorMsg = (state) => async (dispatch) => {
  dispatch({
    type: assestsConstants.SHOW_WEBSOCKET_ERROR,
    payload: state,
  });
};

export const getUserFeatureFlagDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: assestsConstants.GET_USER_FLAG_FEATURE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.get(
      `${baseUrl}/feature-flags/user-status/`,
      config
    );

    dispatch({
      type: assestsConstants.GET_USER_FLAG_FEATURE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_USER_FLAG_FEATURE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
