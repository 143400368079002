import { useEffect, Component } from 'react';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { SkeletonContainer, Image, StarkNetConnect } from '../../../components';
import {
  useViewport as useViewPort,
  useAnalytics,
  useSegment,
} from '../../../utils/hooks';
import { getBannerDetails } from '../../../actions/assestsAction';
import Lottie from 'react-lottie';
import animationDataWhite from '../../../assests/json/kyc-verify-white.json';
import animationDataDark from '../../../assests/json/kyc-verify-dark.json';
import animationDataForDeposit from '../../../assests/json/deposit-1.json';
import animationDataForShare from '../../../assests/json/share.json';
import { useHistory, useLocation } from 'react-router';
import { getQueryParams } from '../../../utils/query-helper';
import { Link } from 'react-router-dom';
import { images } from '../../../utils/image/img';
import { getTimeDiff } from '../../leaderboard/leaderboard-campaign-time/leaderboard-campaign-time';
import { compareTimestamp } from '../../../pages/salt-page/salt-page';
import { tempStaticTime } from '../../../utils/constants';
import { sanitizePrizeData } from '../../../pages/leaderboard-page/leaderboard-page';

const sliderResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

export class CountdownTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.updateTimer();
    // if (this.props?.endTime !== 'no-date') {
    this.interval = setInterval(this.updateTimer, 1000);
    // }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateTimer = () => {
    if (this.props?.endTime) {
      const targetDate = new Date(this.props?.endTime);

      const currentDate = new Date();

      const timeDifference = targetDate - currentDate;

      if (timeDifference <= 0) {
        // Timer has expired
        clearInterval(this.interval);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      this.setState({ days, hours, minutes, seconds });
    }
  };

  render() {
    const { days, hours, minutes, seconds } = this.state;

    return (
      <div className='discount-container'>
        <div className='countdown-timer'>
          <div className='group'>
            <div className='value days'>{days}</div>
            <div className='unit'>Days</div>
          </div>

          <div className='group'>
            <div className='value hours'>{hours}</div>
            <div className='unit'>Hrs</div>
          </div>

          <div className='group'>
            <div className='value minutes'>{minutes}</div>
            <div className='unit'>Min</div>
          </div>
          <div className='group'>
            <div className='value seconds'>{seconds}</div>
            <div className='unit'>Sec</div>
          </div>
        </div>
      </div>
    );
  }
}

const SliderItemWithLottie = ({
  // analytics,
  userInfo,
  animation,
  userData,
  link,
  title,
  description,
  btnTitle,
  img,
  setOpenDepositModal,
  campaignsResponse,
  addExtra24HrsForLB,
}) => {
  const userNameStyle = {
    fontSize: 12,
    letterSpacing: 1,
    marginBottom: 30,
  };

  const { sendTrackEvent } = useSegment();

  return (
    <div className='slider__container'>
      <div className='slider__item'>
        <div className='slider__wrap'>
          <div className='slider__date' style={userNameStyle}>
            Hey{' '}
            {userData?.payload?.name
              ? userData?.payload?.name?.split(' ')[0]
              : 'There'}{' '}
            👋
          </div>
          <div className='slider__title'>{title}</div>
          {btnTitle === 'View Leaderboard' ? (
            <CountdownTimer
              endTime={
                !addExtra24HrsForLB
                  ? campaignsResponse?.[0]?.end_time
                  : tempStaticTime
              }
              addExtra24HrsForLB={addExtra24HrsForLB}
            />
          ) : null}
          <div className='slider__info'>{description}</div>
          {btnTitle === 'Deposit Now' ? (
            <button
              className='slider__btn btn btn_white'
              onClick={() => {
                setOpenDepositModal(true);
                sendTrackEvent('buttonClicked', {
                  title: 'Deposit',
                  action: 'Opening Deposit popup',
                });
              }}
              style={{
                background: '#6c5dd3',
                color: '#fff',
              }}
            >
              {btnTitle}
            </button>
          ) : (
            <Link
              // to={link}
              to={(location) => ({ ...location, pathname: link })}
              className='slider__btn btn btn_white'
              onClick={() => {
                // analytics?.track('Invite Now', {
                //   uid: userInfo?.payload?.uid,
                //   from: 'Homepage',
                //   QueryParams: { ...getQueryParams() },
                //   ip: ip,
                // });
                sendTrackEvent('buttonClicked', {
                  title: 'Invite Now',
                  action: 'Opening referral page',
                });
              }}
              style={{
                background: '#6c5dd3',
                color: '#fff',
              }}
            >
              {btnTitle}
            </Link>
          )}
        </div>
        <div
          className={`slider__preview lottie__money ${
            btnTitle == 'Invite Now' ? 'lottie_share' : ''
          } ${btnTitle === 'View Leaderboard' ? 'lottie_leaderboard' : ''} ${
            btnTitle === 'Claim my $SALT' ? 'slider__salt' : ''
          }`}
        >
          {/* <Lottie
            options={animation}
            width={btnTitle == 'Invite Now' ? 370 : 350}
          /> */}
          <Image
            src={img}
            style={{
              width:
                btnTitle === 'Claim my $SALT'
                  ? 205
                  : btnTitle === 'Invite Now' || btnTitle === 'View Leaderboard'
                  ? 320
                  : 250,
            }}
          />
        </div>
        {/* {btnTitle === 'Claim my $SALT' ? (
          <div className='banner__leaderboard_timeline'>
            Last Date to claim 6th OCTOBER
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

const Slider = ({
  setOpenDepositModal,
  campaignsResponse,
  campaignsLoading,
}) => {
  const dispatch = useDispatch();
  const { width } = useViewPort();
  // const { analytics } = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  // const {
  // getBanner,
  // userDetails: { userData, loading: uLoading },
  // userDepositState: {
  //   state,
  //   loading: depositStateLoading,
  //   error: depositStateError,
  // },
  // theme: { isdark },
  // userLogin: { userInfo },
  // userIpAddress: { ip },
  // saltLeaderBoard: {
  //   response: saltLeaderboardResponse,
  //   loading: saltLeaderboardLoading,
  //   error: saltLeaderboardError,
  // },
  // } = useSelector((state) => state);
  // const { loading, error, bannerDetails } = getBanner;

  const { loading, error, bannerDetails } = useSelector(
    (state) => state.getBanner
  );

  const {
    response: saltLeaderboardResponse,
    loading: saltLeaderboardLoading,
    error: saltLeaderboardError,
  } = useSelector((state) => state.saltLeaderBoard);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { isdark } = useSelector((state) => state.theme);
  const {
    state,
    loading: depositStateLoading,
    error: depositStateError,
  } = useSelector((state) => state.userDepositState);
  const { userData, loading: uLoading } = useSelector(
    (state) => state.userDetails
  );

  const widthbreakpoint = 766;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: isdark ? animationDataDark : animationDataWhite,
  };

  const defaultOptionsForDeposit = {
    loop: true,
    autoplay: true,
    animationData: animationDataForDeposit,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsForShare = {
    loop: true,
    autoplay: true,
    animationData: animationDataForShare,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const sliderData = [
    {
      title: 'Make your first deposit!',
      desc: 'Get started with Brine and experience the joy of gasless, risk-free investing!',
      animation: defaultOptionsForDeposit,
      link: '/wallet',
      buttonTitle: 'Deposit Now',
    },
    {
      title: 'Invite sprint is live',
      desc: "When you refer your friends, you'll get 40% on every trade they make and they get 10% - invite now!",
      animation: defaultOptionsForShare,
      link: '/referral',
      buttonTitle: 'Invite Now',
    },
    {
      title: 'Trading Competition is live',
      desc: `Trade more and get a chance to win up to ${
        sanitizePrizeData(campaignsResponse?.[0]?.prizes?.prizes)
          ?.totalPrizeAmount || '---'
      } USDC.`,
      animation: defaultOptionsForShare,
      link: '/leaderboard',
      buttonTitle: 'View Leaderboard',
    },
    {
      title: '$SALT drop is live',
      desc: 'Claim your $SALT and get a chance to win up to 1000 USDC.',
      animation: defaultOptionsForShare,
      link: '/salt',
      buttonTitle: 'Claim my $SALT',
    },
    {
      title: 'New Trading event starts in',
      desc: '2x prize money, shorter time frame and a chance to win up to 2,000 USDC',
      animation: defaultOptionsForShare,
      link: '/leaderboard',
      buttonTitle: 'View Leaderboard',
      addExtra24HrsForLB: true,
    },
  ];

  useEffect(() => {
    if (!bannerDetails) {
      dispatch(getBannerDetails());
    }
  }, [dispatch]);

  const getBannerCount = () => {
    let count = 0;

    // if (campaignsResponse?.length) {
    //   count++;
    // }

    if (compareTimestamp(tempStaticTime)) {
      count++;
    }

    if (saltLeaderboardResponse?.payload?.length) {
      count++;
    }

    if (userInfo?.token?.access) {
      count++;
    }

    return count;
  };

  return (
    <>
      <div className='slider slider_home'>
        {depositStateLoading || campaignsLoading || saltLeaderboardLoading ? (
          <SkeletonContainer
            delay={1}
            width={'100%'}
            height={width <= widthbreakpoint ? '200px' : '400px'}
            style={{ borderRadius: 30 }}
            className='box__shadow'
          />
        ) : (
          <Carousel
            responsive={sliderResponsive}
            autoPlay={getBannerCount() > 1 ? true : false}
            showDots={getBannerCount() > 1 ? true : false}
            draggable={getBannerCount() > 1 ? true : false}
            arrows={false}
            infinite={getBannerCount() > 1 ? true : false}
          >
            {/* {campaignsResponse?.length ? ( */}
            {compareTimestamp(tempStaticTime) && (
              <SliderItemWithLottie
                key={sliderData[4].title}
                animation={sliderData[4].animation}
                // analytics={analytics}
                userInfo={userInfo}
                sliderResponsive={sliderResponsive}
                userData={userData}
                title={sliderData[4].title}
                description={sliderData[4].desc}
                btnTitle={sliderData[4].buttonTitle}
                link={sliderData[4].link}
                img={images.bannerLeaderboard}
                // campaignsResponse={campaignsResponse}
                addExtra24HrsForLB={sliderData[4]?.addExtra24HrsForLB}
              />
            )}
            {/* ) : null} */}

            {/* {campaignsResponse?.length ? (
              <SliderItemWithLottie
                key={sliderData[2].title}
                animation={sliderData[2].animation}
                // analytics={analytics}
                userInfo={userInfo}
                sliderResponsive={sliderResponsive}
                userData={userData}
                title={sliderData[2].title}
                description={sliderData[2].desc}
                btnTitle={sliderData[2].buttonTitle}
                link={sliderData[2].link}
                img={images.bannerLeaderboard}
                campaignsResponse={campaignsResponse}
              />
            ) : null} */}

            {saltLeaderboardResponse?.payload?.length ? (
              <SliderItemWithLottie
                key={sliderData[3].title}
                animation={sliderData[3].animation}
                // analytics={analytics}
                userInfo={userInfo}
                sliderResponsive={sliderResponsive}
                userData={userData}
                title={sliderData[3].title}
                description={sliderData[3].desc}
                btnTitle={sliderData[3].buttonTitle}
                link={sliderData[3].link}
                img={images.saltScoreBanner}
              />
            ) : null}

            {userInfo?.token?.access && (
              <>
                {!state?.payload?.has_deposited && !depositStateLoading ? (
                  <SliderItemWithLottie
                    key={sliderData[0].title}
                    animation={sliderData[0].animation}
                    // analytics={analytics}
                    userInfo={userInfo}
                    sliderResponsive={sliderResponsive}
                    userData={userData}
                    title={sliderData[0].title}
                    description={sliderData[0].desc}
                    btnTitle={sliderData[0].buttonTitle}
                    link={sliderData[0].link}
                    img={images.bannerDeposit}
                    setOpenDepositModal={setOpenDepositModal}
                  />
                ) : (
                  <SliderItemWithLottie
                    key={sliderData[1].title}
                    animation={sliderData[1].animation}
                    // analytics={analytics}
                    userInfo={userInfo}
                    sliderResponsive={sliderResponsive}
                    userData={userData}
                    title={sliderData[1].title}
                    description={sliderData[1].desc}
                    btnTitle={sliderData[1].buttonTitle}
                    link={sliderData[1].link}
                    img={images.referralShare}
                  />
                )}
              </>
            )}
          </Carousel>
          // <>
          //   {campaignsResponse?.length ||  (
          //     <Carousel
          //       responsive={sliderResponsive}
          //       autoPlay={true}
          //       showDots={true}
          //       arrows={false}
          //     >
          //       {!state?.payload?.has_deposited && !depositStateLoading ? (
          //         <SliderItemWithLottie
          //           key={sliderData[0].title}
          //           animation={sliderData[0].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[0].title}
          //           description={sliderData[0].desc}
          //           btnTitle={sliderData[0].buttonTitle}
          //           link={sliderData[0].link}
          //           img={images.bannerDeposit}
          //           setOpenDepositModal={setOpenDepositModal}
          //         />
          //       ) : (
          //         <SliderItemWithLottie
          //           key={sliderData[1].title}
          //           animation={sliderData[1].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[1].title}
          //           description={sliderData[1].desc}
          //           btnTitle={sliderData[1].buttonTitle}
          //           link={sliderData[1].link}
          //           img={images.referralShare}
          //         />
          //       )}
          //       <SliderItemWithLottie
          //         key={sliderData[2].title}
          //         animation={sliderData[2].animation}
          //         // analytics={analytics}
          //         userInfo={userInfo}
          //         sliderResponsive={sliderResponsive}
          //         userData={userData}
          //         title={sliderData[2].title}
          //         description={sliderData[2].desc}
          //         btnTitle={sliderData[2].buttonTitle}
          //         link={sliderData[2].link}
          //         img={images.bannerLeaderboard}
          //       />
          //       {saltLeaderboardResponse?.payload?.length ? (
          //         <SliderItemWithLottie
          //           key={sliderData[3].title}
          //           animation={sliderData[3].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[3].title}
          //           description={sliderData[3].desc}
          //           btnTitle={sliderData[3].buttonTitle}
          //           link={sliderData[3].link}
          //           img={images.saltScoreBanner}
          //         />
          //       ) : null}
          //       {/* {!state?.payload?.has_deposited && !depositStateLoading ? (
          //         <SliderItemWithLottie
          //           key={sliderData[0].title}
          //           animation={sliderData[0].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[0].title}
          //           description={sliderData[0].desc}
          //           btnTitle={sliderData[0].buttonTitle}
          //           link={sliderData[0].link}
          //           img={images.bannerDeposit}
          //           setOpenDepositModal={setOpenDepositModal}
          //         />
          //       ) : (
          //         <SliderItemWithLottie
          //           key={sliderData[1].title}
          //           animation={sliderData[1].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[1].title}
          //           description={sliderData[1].desc}
          //           btnTitle={sliderData[1].buttonTitle}
          //           link={sliderData[1].link}
          //           img={images.referralShare}
          //         />
          //       )} */}

          //       {campaignsResponse?.length ? (
          //         <SliderItemWithLottie
          //           key={sliderData[2].title}
          //           animation={sliderData[2].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[2].title}
          //           description={sliderData[2].desc}
          //           btnTitle={sliderData[2].buttonTitle}
          //           link={sliderData[2].link}
          //           img={images.bannerLeaderboard}
          //         />
          //       ) : null}
          //       {saltLeaderboardResponse?.payload?.length ? (
          //         <SliderItemWithLottie
          //           key={sliderData[3].title}
          //           animation={sliderData[3].animation}
          //           // analytics={analytics}
          //           userInfo={userInfo}
          //           sliderResponsive={sliderResponsive}
          //           userData={userData}
          //           title={sliderData[3].title}
          //           description={sliderData[3].desc}
          //           btnTitle={sliderData[3].buttonTitle}
          //           link={sliderData[3].link}
          //           img={images.saltScoreBanner}
          //         />
          //       ) : null}
          //     </Carousel>
          //   ) : (
          //     <DepositAndReferralBanner />
          //   )}
          // </>
        )}
      </div>
    </>
  );
};

export default Slider;
