import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../icons/icons';

function IconBtn({ iconName, className, text, color }) {
  const { isdark } = useSelector((state) => state.theme);

  return (
    <>
      <Icon
        icon={iconName}
        className={className}
        color={color ? color : isdark ? '#fff' : '#000'}
      />
      {text && <span className='btn__text'>{text}</span>}
    </>
  );
}

export default IconBtn;
