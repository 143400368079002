function SendMail() {
  return (
    <div className='sidebar__mail'>
      {/* <button className="sidebar__close">
        <Icon className="icon icon_sendmail" icon="close" />
      </button> */}
      <div className='sidebar__info'>
        Download the Brine app to trade on the go
      </div>
      <button
        className='sidebar__btn btn btn_sm'
        style={{ pointerEvents: 'none', cursor: 'revert' }}
      >
        {/* <a
          target="_blank"
          style={{
            pointerEvents: 'none',
          }}
          referrerPolicy="no-referrer"
          href="#"
        > */}
        Coming soon 🚧
        {/* </a> */}
      </button>
    </div>
  );
}

export default SendMail;
