import axios from 'axios';
import * as historyConstants from '../constants/historyConstant';
import { objToParams } from '../utils/query-helper';
import { baseUrl, productionUrl } from '../utils/urls';

const PAGE_LIMIT = 10;

export const getOrderData =
  (page = 1, pending = false, market = null, ord_type = null) =>
  async (dispatch, getState) => {
    let body = {
      params: {
        limit: PAGE_LIMIT,
        page: page,
        market: market ? market : null,
        ord_type: ord_type ? ord_type : null,
      },
    };
    if (pending) {
      body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
          state: 'wait',
          market: market ? market : null,
          ord_type: ord_type ? ord_type : null,
        },
      };
    }

    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_ORDERDATA_REQUEST,
      });

      let queryParams = objToParams(body.params);

      const { data } = await axios.get(
        `${baseUrl}/user/getorders/?${queryParams}`,
        config
      );

      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_ORDERDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_ORDERDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getPendingOrderData =
  (page = 1, pending = true, market = null, ord_type = null) =>
  async (dispatch, getState) => {
    let body = {
      params: {
        limit: PAGE_LIMIT,
        page: page,
        market: market ? market : null,
        ord_type: ord_type ? ord_type : null,
      },
    };
    if (pending) {
      body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
          state: 'wait',
          market: market ? market : null,
          ord_type: ord_type ? ord_type : null,
        },
      };
    }

    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_PENDING_ORDERDATA_REQUEST,
      });

      let queryParams = objToParams(body.params);

      const { data } = await axios.get(
        `${baseUrl}/user/getorders/?${queryParams}`,
        config
      );

      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_PENDING_ORDERDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_PENDING_ORDERDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getWithdrawsData =
  (page = 1, currency = false) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      let body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      if (currency) {
        body = {
          params: {
            limit: PAGE_LIMIT,
            page: page,
            currency: currency,
          },
        };
      }

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_WITHDRAWDATA_REQUEST,
      });
      const { data } = await axios.get(
        `${baseUrl}/payment/withdrawals/?page=${page}`,
        config
      );
      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_WITHDRAWDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_WITHDRAWDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getDepositsData =
  (page = 1, currency = false) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      let body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      if (currency) {
        body = {
          params: {
            limit: PAGE_LIMIT,
            page: page,
            currency: currency,
          },
        };
      }

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_DEPOSITSDATA_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/getdeposits/`,
        body,
        config
      );
      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_DEPOSITSDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_DEPOSITSDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTradesData =
  (page = 1, market = null) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };
      const body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
          market: market ? market : null,
        },
      };
      dispatch({
        type: historyConstants.GET_TRADEDATA_REQUEST,
      });

      let queryParams = objToParams(body.params);

      const { data } = await axios.get(
        `${baseUrl}/user/gettrades/?${queryParams}`,
        config
      );

      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_TRADEDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_TRADEDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getDEXDepositsData =
  (page = 1, network = null) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      let body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      if (network) {
        body = {
          params: {
            limit: PAGE_LIMIT,
            page: page,
            network: network?.toUpperCase(),
          },
        };
      }
      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
        params: body.params,
      };
      dispatch({
        type: historyConstants.GET_DEX_DEPOSIT_DATA_REQUEST,
      });
      const { data } = await axios.get(`${baseUrl}/deposits/all`, config);
      dispatch({
        type: historyConstants.GET_DEX_DEPOSIT_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_DEX_DEPOSIT_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getDEXFastWithdrawData =
  (page = 1, network, currency = false) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      let body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      if (currency) {
        body = {
          params: {
            limit: PAGE_LIMIT,
            page: page,
            currency: currency,
          },
        };
      }

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
        params: {
          network: network?.toUpperCase(),
        },
      };
      dispatch({
        type: historyConstants.GET_DEX_FAST_WITHDRAW_DATA_REQUEST,
      });
      const { data } = await axios.get(
        `${baseUrl}/payment/fast-withdrawals/?page=${page}`,
        config
      );
      dispatch({
        type: historyConstants.GET_DEX_FAST_WITHDRAW_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_DEX_FAST_WITHDRAW_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTenPendingOrderData = async (
  token,
  page = 1,
  market = null,
  pending = false
) => {
  let body = {
    params: {
      limit: PAGE_LIMIT,
      page: page,
    },
  };
  if (pending) {
    body = {
      params: {
        limit: PAGE_LIMIT,
        page: page,
        state: 'wait',
        market,
      },
    };
  }
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let queryParams = objToParams(body.params);

    const { data } = await axios.get(
      `${baseUrl}/user/getorders/?${queryParams}`,
      config
    );

    return data.payload.slice(0, 10);
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
