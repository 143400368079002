/* global BigInt */
import React from 'react';
import { useProvider, useNetwork, useAccount } from '@starknet-react/core';
import { Signer, Account, Contract, cairo, uint256 } from 'starknet';
import { starkErc20Abi } from '../constants';
import useBalance from './useBalance';
import { useWalletMethods } from './useWallets';
import { ethers } from 'ethers';

function useStarknet() {
  const { provider } = useProvider();
  const { address, account } = useAccount();
  const { chain } = useNetwork();
  const { dequantize } = useWalletMethods();
  const { preventScientific } = useBalance();

  function quantize(number, decimals) {
    const factor = 10 ** decimals;
    return number * factor;
  }

  const sendTransaction = async (
    to = '0x3f7ad96ce900d50180902a759fbd929b539b4f02f1c57a96d3385eceafbb5e',
    amount = '0.000001',
    tokenAddress = '0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7'
  ) => {
    if (!provider) {
      return console.log(`Provider not found...`);
    }

    // console.log({ testnety: Number(chain.id) });

    // if (!chain.testnet) {
    //   return console.log(`User is on the wrong chain...`);
    // }

    const erc20 = new Contract(starkErc20Abi, tokenAddress, account.signer);
    erc20.connect(account);

    const receiverAddressBigInt = BigInt(to).toString();
    const balanceOfCoin = await erc20.balanceOf(account.address);

    // console.log({ balanceOfCoin, aa: account.address });
    const { transaction_hash: transferTxHash } = await erc20.transfer(
      receiverAddressBigInt,
      cairo.uint256(quantize(amount, 18))
    );
    // console.log({ transferTxHash });
    return transferTxHash;
  };

  const executeTransaction = async (data) => {
    if (!provider) {
      return console.log(`Provider not found...`);
    }

    // console.log(
    //   { testnety: Number(chain.id), data, sss: JSON.parse(data) },
    //   'executeTransaction'
    // );

    // if (!chain.testnet) {
    //   return console.log(`User is on the wrong chain...`);
    // }

    // const erc20 = new Contract(starkErc20Abi, tokenAddress, account.signer);
    // erc20.connect(account);

    // const receiverAddressBigInt = BigInt(to).toString();
    // const balanceOfCoin = await erc20.balanceOf(account.address);

    // console.log({ balanceOfCoin, aa: account.address });
    // const { transaction_hash: transferTxHash } = await erc20.transfer(
    //   receiverAddressBigInt,
    //   cairo.uint256(quantize(amount, 18))
    // );
    // console.log({ transferTxHash });

    // const { transaction_hash: transferTxHash } = await account.execute(
    //   JSON.parse(data)
    // );

    const res = await account.execute(JSON.parse(data));
    return res;
  };

  const getBalance = async (tokenAddress, decimal) => {
    if (!provider && !account?.address) {
      return console.log(`Provider not found...`);
    }

    // if (!chain.testnet) {
    //   return console.log(`User is on the wrong chain...`);
    // }

    const erc20 = new Contract(starkErc20Abi, tokenAddress, provider);
    const res = await erc20.balanceOf(account.address);
    const balanceInWei = uint256.uint256ToBN(res.balance).toString();
    const balance = dequantize(balanceInWei, decimal);

    // console.log('balance:SS ' + balance + balanceInWei);
    return balance;
  };

  return { sendTransaction, getBalance, executeTransaction };
}

export default useStarknet;
