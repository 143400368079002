import React, { useEffect, useState } from 'react';
import {
  CustomButton,
  CustomSelect,
  DepositLinkText,
  HelmetContainer,
  Image,
  Layout,
  NotFoundedAnimation,
  Popup,
  SkeletonContainer,
  Toast,
} from '../../components';
import Carousel from 'react-multi-carousel';
import { images } from '../../utils/image/img';
import { useDispatch, useSelector } from 'react-redux';
import SaltScoreConnect from '../../components/salt/salt-score-connect/salt-score-connect';
import { LeaderBoardTags } from '../../components/leaderboard/leaderboard-tags/leaderboard-tags';
import {
  CampaignTimeLine,
  getTimeDiff,
} from '../../components/leaderboard/leaderboard-campaign-time/leaderboard-campaign-time';
import { LeaderBoardCell } from '../../components/leaderboard/leaderboard-cell/leaderboard-cell';
import { useSegment, useViewport } from '../../utils/hooks';
import {
  getSaltLeaderBoard,
  getSaltScore,
  getSaltScoreFlag,
} from '../../actions/saltAction';
import { SaltLeaderBoardCell } from '../../components/salt/salt-leaderboard-cell/salt-leaderboard-cell';
import { truncate } from '../../utils/string-methods';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { StepperItem } from '../../components/wallets/wallet-withdraw/wallet-withdraw';

export function compareTimestamp(timestamp) {
  if (timestamp) {
    const providedTime = new Date(timestamp);
    const currentTime = new Date();
    if (currentTime <= providedTime) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function SaltPrizeClaimStepper({ showNFT }) {
  // Go to the Referral section on Brine.
  // Click on the "Unclaimed" tab; you will see all pending claims.
  // Click on the "Claim" button under "NFT" option.
  // Your NFT will be transferred to your wallet in a few days and your reward will be credited to your Brine wallet.
  return (
    <div className='step-track'>
      <StepperItem
        style={{
          height: '4rem',
        }}
        lineStyles={{
          height: '2.4rem',
        }}
        htmlElement={
          <p style={{ marginTop: 3 }}>
            Go to the <span style={{ color: '#8173E0' }}>Referral section</span>{' '}
            on Brine.
          </p>
        }
      />
      <StepperItem
        htmlElement={
          <p>
            Click on the{' '}
            <span style={{ color: '#8173E0' }}>Unclaimed tab;</span>
            you will see all pending claims.
          </p>
        }
      />
      <StepperItem
        htmlElement={
          <p>
            Click on the <span style={{ color: '#8173E0' }}>Claim</span> button
            under{' '}
            <span style={{ color: '#8173E0' }}>{showNFT ? 'NFT' : 'USDC'}</span>{' '}
            option.
          </p>
        }
      />
      {showNFT ? (
        <StepperItem
          htmlElement={
            <p>
              Your NFT will be transferred to your wallet in a few days and your
              reward will be credited to your Brine wallet.
            </p>
          }
        />
      ) : (
        <StepperItem
          htmlElement={
            <p>Your reward will be credited to your Brine wallet.</p>
          }
        />
      )}
    </div>
  );
}

function SaltConnectAndTimeline({
  openInstructionForClaim,
  setOpenInstructionForClaim,
}) {
  const [saltScore, setSaltScore] = useState(null);
  const [isSaltScoreLoading, setIsSaltScoreLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [stage, setStage] = useState('stage1');
  const [isSaltFetchRunning, setIsSaltFetchRunning] = useState(false);
  const [cachedStage, setCacheStage] = useState(null);
  const [isCachedLoading, setIsCachedLoading] = useState(null);
  const { sendPageEvent, analytics, sendTrackEvent } = useSegment();

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollThreshold = 120;

  // const {
  //   userLogin: { userInfo },
  //   saltScoreFlag: {
  //     response: saltScoreFlagResponse,
  //     loading: saltScoreFlagLoading,
  //     error: saltScoreFlagError,
  //   },
  //   userDetails: { userData },
  //   userVia: { state: userVia },
  // } = useSelector((state) => state);

  const { state: userVia } = useSelector((state) => state.userVia);
  const { userData } = useSelector((state) => state.userDetails);
  const {
    response: saltScoreFlagResponse,
    loading: saltScoreFlagLoading,
    error: saltScoreFlagError,
  } = useSelector((state) => state.saltScoreFlag);
  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let intervalId;

    if (isSaltFetchRunning) {
      intervalId = setInterval(() => {
        handleGetSaltScore();
      }, 30000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isSaltFetchRunning]);

  const startInterval = () => {
    setIsSaltFetchRunning(true);
  };

  const stopInterval = () => {
    setIsSaltFetchRunning(false);
  };

  useEffect(() => {
    setIsCachedLoading(true);
    let stageOfSaltCalculation =
      sessionStorage.getItem(
        `brine.salt__reveal_stage${userVia?.address?.toLowerCase()}`
      ) || null;
    setCacheStage(stageOfSaltCalculation);
    if (stageOfSaltCalculation) {
      setStage(stageOfSaltCalculation);
      if (stageOfSaltCalculation === 'stage2') {
        startInterval();
      }
    }
    setIsCachedLoading(false);
  }, []);

  const handleGetSaltScore = async (
    is_manual_click = false,
    isUserEffectCall = false
  ) => {
    setIsSaltScoreLoading(true);
    try {
      if (is_manual_click) {
        sendTrackEvent('userClaimSaltScoreInitialized');
      }
      const res = await getSaltScore(userInfo?.token?.access);
      if (res?.status === 'pending') {
        setIsSaltScoreLoading(false);
        // return toast.info(
        //   'Your salt score is being generated; please check back in a couple of minutes.',
        //   {
        //     toastId: 'salt__generating',
        //   }
        // );
        startInterval();
        if (is_manual_click) {
          sendTrackEvent('userClaimSaltScorePending');
          sessionStorage.setItem(
            `brine.salt__reveal_stage${userVia?.address?.toLowerCase()}`,
            'stage2'
          );
        }
        setStage('stage2');
        return;
      }
      if (!isUserEffectCall || stage === 'stage2') {
        setStage('stage3');
        sendTrackEvent('userClaimSaltScoreSuccess');
      }
      if (is_manual_click) {
        sessionStorage.setItem(
          `brine.salt__reveal_stage${userVia?.address?.toLowerCase()}`,
          'stage3'
        );
        sendTrackEvent('userClaimSaltScoreSuccess');
      }
      stopInterval();
      setSaltScore(res?.payload);
      setIsSaltScoreLoading(false);
      // if (force_refresh) {
      //   setOpenZeroTradePopup(true);
      // }
      // if (force_refresh) {
      //   dispatch(getSaltScoreFlag());
      // }
    } catch (error) {
      console.log({ error });
      toast.error(error);
      setIsSaltScoreLoading(false);
      sendTrackEvent('userClaimSaltScoreFailure', {
        error: error,
      });
    }
  };

  const showScore = () => {
    sessionStorage.removeItem(
      `brine.salt__reveal_stage${userVia?.address?.toLowerCase()}`
    );
    setCacheStage(null);
    // setOpenZeroTradePopup(true);
    dispatch(getSaltScoreFlag());
  };

  useEffect(() => {
    if (
      userInfo?.token?.access &&
      saltScoreFlagResponse?.payload?.is_rank_generated === true
    ) {
      handleGetSaltScore(false, true);
    }
  }, [
    userInfo?.token?.access,
    saltScoreFlagResponse?.payload?.is_rank_generated,
  ]);

  useEffect(() => {
    if (stage === 'stage3') {
      showScore();
    }
  }, [stage]);

  if (saltScoreFlagLoading || isCachedLoading) {
    return (
      <SkeletonContainer
        style={{
          borderRadius: 20,
          width: '100%',
          height: 270,
          marginBottom: 30,
        }}
      />
    );
  }

  // let hoursDiff =
  //   getTimeDiff(saltScoreFlagResponse?.payload?.trade_fee_reward_expiry).hours +
  //   getTimeDiff(saltScoreFlagResponse?.payload?.trade_fee_reward_expiry).days *
  //     24 +
  //   (getTimeDiff(saltScoreFlagResponse?.payload?.trade_fee_reward_expiry)
  //     .minutes >= 55
  //     ? 1
  //     : 0);

  return (
    <div
      className={`salt__score_fixed ${
        scrollPosition > scrollThreshold ? 'fixed' : ''
      }`}
    >
      {!userInfo?.token?.access ||
      (saltScoreFlagResponse?.payload?.is_rank_generated === false &&
        !saltScoreFlagLoading) ||
      cachedStage ? (
        <>
          <SaltScoreConnect
            isRankGenerated={
              saltScoreFlagResponse?.payload?.is_rank_generated === true
            }
            isUserLoggedIn={userInfo?.token?.access}
            handleGetSaltScore={handleGetSaltScore}
            saltScoreFlagLoading={saltScoreFlagLoading}
            isSaltScoreCalLoading={isSaltScoreLoading}
            stage={stage}
            showScore={showScore}
          />
          {/* <div className='salt_timeline_wrapper'>
            <CampaignTimeLine
              selectedFilter={{
                label: 'SALT',
              }}
            />
          </div> */}
        </>
      ) : (
        <>
          <div className='card card_widget' style={{ alignContent: 'start' }}>
            <div className='card__inner salt__profile_wrapper'>
              <div className='salt_profile'>
                <Image
                  src={images.getIdenticonImg(4)}
                  style={{ borderRadius: 20, width: 40, height: 40 }}
                />
                <div
                  className='salt_profile_username'
                  style={{ marginLeft: 10 }}
                >
                  <div className='h5'>
                    {saltScore?.eth_address
                      ? truncate(saltScore.eth_address, 15)
                      : '-----'}
                  </div>
                  <div className='h6'>
                    {userData?.payload?.username
                      ? userData?.payload?.username
                      : '--'}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div className='salt_profile_username'>
                  <div className='h3 text-mask'>
                    #{saltScore?.rank ? saltScore?.rank : '---'}
                  </div>
                  <div className='h6'>Your SALT rank</div>
                </div>
                <div
                  className='salt_profile_username'
                  style={{ marginTop: 30 }}
                >
                  <div className='h4'>
                    {saltScore?.salt_score || saltScore?.salt_score === 0
                      ? saltScore?.salt_score
                      : '--'}
                  </div>
                  <div className='h6'>Your SALT score</div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 26, width: '100%' }}>
              {/* {compareTimestamp(
                saltScoreFlagResponse?.payload?.trade_fee_reward_expiry
              ) ? (
                <div className='zero__fee_text_container'>
                  <span>You can trade at</span> 0% trading fee 📈{' '}
                  <span>for the next</span> {hoursDiff > 0 ? hoursDiff : 0}{' '}
                  hours ⌛️
                </div>
              ) : null} */}

              {saltScoreFlagResponse?.payload?.last_sprint_rank ? (
                <CustomButton
                  title='Claim your prize now 🎉'
                  style={{ width: '100%' }}
                  onClick={() => setOpenInstructionForClaim(true)}
                />
              ) : (
                <CustomButton
                  title='View Trading Leaderboard'
                  style={{ width: '100%' }}
                  onClick={() => history?.push('/leaderboard')}
                />
              )}
            </div>
            <DepositLinkText
              text='How to increase my SALT Score?'
              link='https://www.brine.fi/salt-score'
              style={{ marginTop: 15, marginLeft: 6 }}
            />
          </div>

          {/* <div className='salt_timeline_wrapper'>
            <CampaignTimeLine
              selectedFilter={{
                label: 'SALT',
              }}
            />
          </div> */}
          <div className='share__popup'>
            <div className='notes__instant withdraw__instruction'>
              <Popup
                state={openInstructionForClaim}
                setState={setOpenInstructionForClaim}
              >
                <div
                  className='h6'
                  style={{ textAlign: 'center', marginTop: 45, fontSize: 16 }}
                >
                  How to claim your{' '}
                  {saltScoreFlagResponse?.payload?.last_sprint_rank <= 3
                    ? 'NFT and'
                    : ''}
                  reward
                </div>
                <div
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    marginTop: 40,
                  }}
                >
                  <Image
                    src={images.informationIcon}
                    style={{ width: 110, height: 110 }}
                  />
                </div>
                <SaltPrizeClaimStepper
                  showNFT={
                    saltScoreFlagResponse?.payload?.last_sprint_rank <= 3
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 19,
                  }}
                >
                  <CustomButton
                    title='Understood, I want to proceed'
                    style={{ minWidth: '100%' }}
                    onClick={() => {
                      // backController();
                      history?.push('/referral');
                    }}
                  />
                </div>
              </Popup>
            </div>
          </div>
        </>
      )}
      <div className='salt_timeline_wrapper'>
        <div
          className='event_timeline'
          style={{ padding: '25px 38px 25px 39px' }}
        >
          <h1 className='text-mask'>#SALT SPRINT ENDED</h1>
          {/* <div className='campaign__timer'>
                <div>
                  <div>
                    <h1 className='text-mask'>
                      {timeFunc[
                        selectedFilter?.isActive ? 'active' : 'inactive'
                      ](selectedFilter?.end_time).days > 0
                        ? timeFunc[
                            selectedFilter?.isActive ? 'active' : 'inactive'
                          ](selectedFilter?.end_time).days
                        : 0}
                    </h1>
                  </div>
                  <div>
                    <p>days</p>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <div>
                    <h1 className='text-mask'>
                      {timeFunc[
                        selectedFilter?.isActive ? 'active' : 'inactive'
                      ](selectedFilter?.end_time).hours > 0
                        ? timeFunc[
                            selectedFilter?.isActive ? 'active' : 'inactive'
                          ](selectedFilter?.end_time).hours
                        : 0}
                    </h1>
                  </div>
                  <div>
                    <p>hours</p>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <div>
                    <h1 className='text-mask'>
                      {timeFunc[
                        selectedFilter?.isActive ? 'active' : 'inactive'
                      ](selectedFilter?.end_time).minutes > 0
                        ? timeFunc[
                            selectedFilter?.isActive ? 'active' : 'inactive'
                          ](selectedFilter?.end_time).minutes
                        : 0}
                    </h1>
                  </div>
                  <div>
                    <p>mins</p>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
    </div>
  );
}

const sliderResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const prizeSliderResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1920 },
    items: 5,
  },
  xl: {
    breakpoint: { max: 1910, min: 1710 },
    items: 4,
    paritialVisibilityGutter: 60,
  },
  desktop: {
    breakpoint: { max: 1709, min: 1024 },
    items: 3.5,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.7,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.9,
    paritialVisibilityGutter: 30,
  },
};

const options = [
  {
    label: 'Monthly',
    value: 'monthly',
    id: 'monthly',
  },
  {
    label: 'All Time HIGH',
    value: 'all_time_high',
    id: 'all_time_high',
  },
];

const prizeDetails = [
  {
    img: images.saltPrize1,
    salt: '💰 $250 USDC',
    prize: ['🚀 Brinerd NFT X 1', 'No winners yet.'],
    winner: 'No winners yet.',
  },
  {
    img: images.saltPrize2,
    salt: '💰 $100 USDC',
    prize: ['🚀 Brinerd NFT X 1', 'No winners yet.'],
    winner: 'No winners yet.',
  },
  {
    img: images.saltPrize3,
    salt: '💰 $50 USDC',
    prize: ['🚀 Brinerd NFT X 1', 'No winners yet.'],
    winner: 'No winners yet.',
  },
];

const fourthPrice = {
  img: images.priceUsdt,
  salt: '💰 $25 USDC',
  prize: ['No winners yet.'],
  winner: 'No winners yet.',
};

const randomImg = () => {
  const random = Math.floor(Math.random() * 4);
  return images.getIdenticonImg(Number(random) + 1);
};

function SaltPage() {
  const { isdark } = useSelector((state) => state.theme);
  // const [selectedFilter, setSelectedFilter] = useState(options[0]);
  const [randomImgs, setRandomImgs] = useState([]);
  const { width } = useViewport();
  // const [openZeroTradePopup, setOpenZeroTradePopup] = useState(false);
  const {
    response: saltLeaderboardResponse,
    loading: saltLeaderboardLoading,
    error: saltLeaderboardError,
  } = useSelector((state) => state.saltLeaderBoard);
  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const { sendPageEvent, analytics, sendTrackEvent } = useSegment();
  const [openInstructionForClaim, setOpenInstructionForClaim] = useState(false);

  const getBannerImg = () => {
    if (width >= 750) {
      return isdark
        ? [
            images.saltBanner3Dark,
            images.saltBanner1Dark,
            images.saltBanner2Dark,
          ]
        : [
            images.saltBanner3Light,
            images.saltBanner1Light,
            images.saltBanner2Light,
          ];
    } else {
      return isdark
        ? [
            images.saltBanner3SMDark,
            images.saltBanner1SMDark,
            images.saltBanner2SMDark,
          ]
        : [
            images.saltBanner3SMLight,
            images.saltBanner1SMLight,
            images.saltBanner2SMLight,
          ];
    }
  };

  useEffect(() => {
    localStorage.setItem('show_salt_ripple_animation', 'false');
  }, []);

  useEffect(() => {
    let imgs = [];
    for (let i = 0; i <= 100; i++) {
      imgs.push(randomImg());
    }
    setRandomImgs(imgs);
  }, []);

  useEffect(() => {
    if (analytics) {
      sendPageEvent('saltPageVisited');
    }
  }, [analytics]);

  // useEffect(() => {
  //   dispatch(getSaltLeaderBoard());
  // }, [dispatch]);

  let bannerImages = getBannerImg();

  useEffect(() => {
    if (userInfo?.token) {
      dispatch(getSaltScoreFlag());
    }
  }, [dispatch, userInfo?.token]);

  return (
    <Layout showborder={true} reduceZIndex={openInstructionForClaim}>
      <Toast />
      <HelmetContainer title='SALT score' />
      <div className='page__row'>
        {/* First Layout */}
        <div className='page__col'>
          <div className='categories__title h4' style={{ marginBottom: '8px' }}>
            SALT score board
            {/* <div
              className='activity__select notifications__select leaderboard__select tablet-show'
              style={{ marginBottom: 20 }}
            >
              {selectedFilter?.value && (
                <CustomSelect
                  defaultValue={selectedFilter}
                  options={options}
                  onChange={(data) => {
                    setSelectedFilter(data);
                  }}
                />
              )}
            </div> */}
            {/* <LeaderBoardTags
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              options={options}
              addTagclassName='activity__tags tablet-hide'
              addLinksclassName='activity__link leader_board_link'
              style={{ marginLeft: 0, marginTop: 15, marginBottom: 15 }}
            /> */}
          </div>
          <Carousel
            responsive={sliderResponsive}
            className='salt__carousel_container'
          >
            {bannerImages.map((image, idx) => (
              <div key={idx} className='salt__banner-container'>
                <img src={image} className='salt__banner-img' />
              </div>
            ))}
          </Carousel>
          <div className='salt__page_second_col mobile-show'>
            <SaltConnectAndTimeline
              openInstructionForClaim={openInstructionForClaim}
              setOpenInstructionForClaim={setOpenInstructionForClaim}
            />
          </div>
          <div
            style={{
              marginTop: 30,
              marginBottom: 49,
            }}
          >
            {/* Title */}
            <div
              className='categories__title h5'
              style={{
                fontSize: 21,
                marginBottom: 0,
              }}
            >
              Prizes
            </div>
            {/* <div className='mobile-show'> */}
            <Carousel
              responsive={prizeSliderResponsive}
              itemClass='prize__salt_item'
            >
              {prizeDetails.map((prize, idx) => (
                <div className='prize salt__prize' key={idx}>
                  <div className='salt__rank'>
                    <div className='h5 text-mask'>#{idx + 1}</div>
                  </div>
                  <Image src={prize.img} alt='' />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h5 className='salt__score'>{prize.salt}</h5>
                    {prize.prize.map((item) => (
                      <p
                        className='salt__winner'
                        style={{ marginTop: 5 }}
                        key={item}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
              <div className='prize salt__prize'>
                <div
                  className='salt__rank'
                  style={{ width: 'fit-content', padding: 10 }}
                >
                  <div className='h5 text-mask'>#4-10</div>
                </div>
                <Image
                  src={fourthPrice.img}
                  alt=''
                  style={{ visiblity: 'hidden' }}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h5 className='salt__score'>{fourthPrice.salt}</h5>
                  {fourthPrice.prize.map((item, idx) => (
                    <p
                      className='salt__winner'
                      style={{ marginTop: 5 }}
                      key={idx}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </Carousel>
            {/* </div> */}
            {/* Prizes */}
            {/* <div className='prize__event_container'>
              <div
                className='prize__wrapper mobile-hide'
                style={{
                  marginTop: 6,
                  marginBottom: 20,
                }}
              >
                <div className='prize__container'>
                  {prizeDetails.map((prize, idx) => (
                    <div className='prize salt__prize' key={idx}>
                      <div className='salt__rank'>
                        <div className='h5 text-mask'>#{idx + 1}</div>
                      </div>
                      <Image src={prize.img} alt='' />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h5 className='salt__score'>{prize.salt}</h5>
                        {prize.prize.map((item) => (
                          <p className='salt__winner' style={{ marginTop: 5 }}>
                            {item}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className='brinard__nft card card_widget'>
            <div className='brinard__nft_text'>
              <div className='h4' style={{ marginTop: -20 }}>
                Brinerds NFT
              </div>
              <div className='h6' style={{ fontSize: 14, marginTop: 10 }}>
                given out in the past.
              </div>
            </div>
            <div className='nft__list'>
              {prizeDetails.map((prize, idx) => (
                <Image src={prize.img} key={idx} />
              ))}
            </div>
          </div> */}

          <div style={{ marginTop: 45 }}>
            <div
              className='leaderboard__title'
              style={{ flexDirection: 'row' }}
            >
              <div className='categories__title h5' style={{ fontSize: 21 }}>
                SALT Leaderboard
              </div>
              {/* <DepositLinkText
                text='Past winner'
                link='#'
                style={{ marginTop: 0 }}
              /> */}
            </div>

            {saltLeaderboardLoading ? (
              <div>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <SkeletonContainer
                    key={item}
                    style={{
                      height: 60,
                      borderRadius: 10,
                      marginBottom: 15,
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className='prices__container'>
                {!saltLeaderboardResponse?.payload?.length ||
                saltLeaderboardError ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <NotFoundedAnimation
                      size={'sm'}
                      text='No salt leaderboard events happening right now.'
                    />
                  </div>
                ) : (
                  <div className='prices__table'>
                    <div className='prices__row prices__row_head leaderBoard__row_head'>
                      <div className='prices__cell'>Username</div>
                      <div className='prices__cell'>address</div>
                      <div className='prices__cell'>Score</div>
                      <div className='prices__cell'>Rank</div>
                    </div>
                    {saltLeaderboardResponse?.payload?.map((item, idx) => {
                      let formattedData = {
                        name: item?.username,
                        address: truncate(item?.eth_address, 10),
                        score: item?.salt_score,
                        rank: item?.rank,
                      };
                      return (
                        <SaltLeaderBoardCell
                          key={idx}
                          item={formattedData}
                          idx={idx}
                          randomImgs={randomImgs}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* Second layout */}
        <div className='page__col salt__page_second_col'>
          <div className='mobile-hide'>
            <SaltConnectAndTimeline
              openInstructionForClaim={openInstructionForClaim}
              setOpenInstructionForClaim={setOpenInstructionForClaim}
            />
          </div>
        </div>
      </div>

      {/* <div className='share__popup network__error'>
        <Popup
          state={openZeroTradePopup}
          setState={setOpenZeroTradePopup}
          confetti={true}
        >
          <div className='h6'>You just claimed 0% trading fees 🥳</div>
          <Image src={images.tradeZeroFee} />
          <p>
            You can now trade at 0% trading fee for the next 48 hours ⌛️ How
            exciting!
          </p>
          <CustomButton
            title='Close'
            addClassName='disconnect_btn'
            onClick={() => {
              setOpenZeroTradePopup(false);
            }}
          />
        </Popup>
      </div> */}
    </Layout>
  );
}

export default SaltPage;
