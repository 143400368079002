import { USER_LOGIN_RESET } from '../../../constants/userConstant';
import { Link } from 'react-router-dom';
import {
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  ALLOWED_POLYGON_NETWORK_ID,
} from '../../../utils/constants';
import {
  Image,
  LoadingBtn,
  AuthButton,
  Toast,
  ReferralFormPopup,
  MetaPopup,
  FormInput,
  CustomButton,
  Popup,
  LoginStepper,
  DepositLinkText,
} from '../../../components';
import { useWeb3React } from '@web3-react/core';
import { images } from '../../../utils/image/img';
import {
  useSegment,
  useViewport,
  useWalletMethods,
} from '../../../utils/hooks';
import NetworkChangePopup from '../../common/network-switch/network-switch';
import { WithdrawalGuide } from '../../activities/withdrawal-guide/withdrawal-guide';
import { InstructionForWithdrawal } from '../../wallets/wallet-withdraw/wallet-withdraw';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeRemainderMeState } from '../../../actions/userAction';

export function NetworkErrorPrompt({ setOpenPopup, changeNetwork }) {
  return (
    <div className='network_error_container'>
      <h3>
        ⚠️ Oops, You seem to be on the wrong lane. Kindly change your network to{' '}
        {ALLOWED_NETWORK_NAME} by clicking on the link{' '}
        <a
          href='#'
          onClick={() => {
            changeNetwork()
              .then((item) => {
                setOpenPopup((prev) => !prev);
              })
              .catch((e) => {
                console.log(e);
              });
          }}
        >
          Change network
        </a>
      </h3>
      {/* <i
        className="fas fa-times"
        
      ></i> */}
    </div>
  );
}

export default function WalletLoginInfoV2({
  showMetaMaskPopup,
  setLoginState,
  setSelectedOption,
  dispatch,
  WalletImg,
  account,
  clickedVerfiy,
  setClickedVerfiy,
  loading,
  stateLoading,
  provider: library,
  handleGetNonce,
  connectWalletLink,
  setOpenInstructionPopUp,
  openInstructionPopUp,
  openLoginPopup,
  setOpenLoginPopup,
  referralCode,
  setReferralCode,
  handleLoginWithReferral,
  setOpenNetworkPrompt,
  openNetworkPrompt,
  WalletImageComponent,
  signedKey,
}) {
  const { connector } = useWeb3React();
  const { sendTrackEvent } = useSegment();
  const { walletDeactivate } = useWalletMethods();
  const [stage, setStage] = useState(0);

  useEffect(() => {
    // if (account && signedKey && clickedVerfiy) {
    //   setStage(3);
    // }

    if (account && signedKey) {
      setStage(2);
    } else if (account) {
      setStage(1);
    }
  }, [account, signedKey]);

  return (
    <div className='login__col'>
      <div
        className='login__back'
        onClick={() => {
          setLoginState('1');
          setSelectedOption(null);
          walletDeactivate();
          localStorage.removeItem('user_via');
          dispatch({
            type: USER_LOGIN_RESET,
          });
        }}
      >
        <i className='fas fa-arrow-left'></i>
        <span>Back</span>
      </div>
      <div className='login__form'>
        {openNetworkPrompt && (
          // <NetworkErrorPrompt
          //   setOpenPopup={setOpenNetworkPrompt}
          //   changeNetwork={changeNetwork}
          // />
          <NetworkChangePopup
            openNetworkPrompt={openNetworkPrompt}
            setOpenNetworkPrompt={setOpenNetworkPrompt}
            onDisconnect={() => setStage(0)}
          />
        )}
        <div
          className='login__stage h4'
          style={{
            textAlign: 'center',
          }}
        >
          {/* Sign in to Brine Fi */}
          Connect wallet
        </div>
        <div className='meta_login'>
          {WalletImageComponent ? (
            WalletImageComponent
          ) : (
            <Image src={WalletImg} alt='' />
          )}
          <LoginStepper
            stage={stage}
            setClickedVerfiy={setClickedVerfiy}
            account={account}
            setStage={setStage}
            signedKey={signedKey}
            clickedVerfiy={clickedVerfiy}
          />
          <RememberMe disable={account?.length} />
          <p
            style={{
              color: '#808191',
              fontSize: 12,
              marginBottom: 7,
              fontFamily: 'Poppins',
              marginTop: 17,
            }}
          >
            Please note that wallet signatures are free of any charges
          </p>
          {!account || (clickedVerfiy && signedKey) ? null : (
            <div
              className='custom_checkbox login_verify'
              style={{
                marginBottom: 5,
                marginTop: 2,
              }}
            >
              <input
                type='checkbox'
                checked={clickedVerfiy}
                onChange={(e) => setClickedVerfiy(e.target.checked)}
                style={{
                  pointerEvents: clickedVerfiy ? 'none' : 'all',
                }}
              />
              <p style={{ fontSize: 14, marginLeft: 20 }}>Toggle to verify</p>
            </div>
          )}

          {/* <div className='h6'>
            Great to have you here anon, let’s get to trading and DEX it right!
          </div>
          {!account && (
            <div className='indication__container'>
              <div className='indication__login'></div>
              <p>Not connected</p>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 8,
              // transition: 'visibility 0s, opacity 0.2s linear, width 0.2s',
              visibility: account ? 'visible' : 'hidden',
              opacity: account ? 1 : 0,
              height: !account ? 0 : 'fit-content',
            }}
          >
            <div>
              <FormInput
                readOnly
                value={`${account?.substring(0, 13)}...${account?.substring(
                  account.length - 4
                )}`}
                style={{
                  height: '47px',
                  padding: `0px 22px 0`,
                }}
              />
            </div>
            {!showMetaMaskPopup && (
              <div>
                <CustomButton
                  onClick={() => {
                    if (connector) {
                      walletDeactivate();
                    }
                    walletDeactivate();
                    setClickedVerfiy(false);
                  }}
                  addClassName='btn-small'
                  title='Disconnect'
                  style={{ marginLeft: 12 }}
                />
              </div>
            )}
          </div>
          <div
            className='custom_checkbox login_verify'
            style={{
              marginBottom: 24,
              marginTop: 14,
            }}
          >
            <input
              type='checkbox'
              checked={clickedVerfiy}
              onChange={(e) => setClickedVerfiy(e.target.checked)}
              style={{
                pointerEvents: clickedVerfiy ? 'none' : 'all',
              }}
            />
            <p>Click the toggle button to verfiy you're a human</p>
          </div>

          {loading || stateLoading ? (
            <LoadingBtn size='full' />
          ) : (
            <AuthButton
              title='Get Started'
              disabled={!clickedVerfiy || !signedKey}
              onClick={
                !!(library && account)
                  ? () => handleGetNonce()
                  : () => connectWalletLink()
              }
            />
          )} */}

          {account ? null : (
            <>
              {loading || stateLoading ? (
                <LoadingBtn size='full' />
              ) : (
                <AuthButton
                  title='Connect'
                  // disabled={!clickedVerfiy || !signedKey}
                  style={{ minWidth: '85%' }}
                  onClick={connectWalletLink}
                />
              )}
            </>
          )}

          <>
            {!account || !signedKey ? null : (
              <>
                {loading || stateLoading ? (
                  <LoadingBtn size='full' style={{ minWidth: '85%' }} />
                ) : (
                  <AuthButton
                    title='Enable'
                    style={{ minWidth: '85%' }}
                    disabled={!clickedVerfiy || !signedKey}
                    onClick={handleGetNonce}
                  />
                )}
              </>
            )}
          </>
        </div>

        {/* {showMetaMaskPopup && (
          <div className='login__flex'>
            <div className='login__text'>
              Don't have Metamask?{' '}
              <Link
                className='login__link'
                onClick={() => {
                  sendTrackEvent('buttonClicked', {
                    title: "Don't have Metamask?",
                    action: 'Opening metamask instruction popup',
                  });
                  setOpenInstructionPopUp((prev) => !prev);
                }}
                to={(location) => ({
                  ...location,
                  pathname: '/signin',
                })}
              >
                {'  '} Click Here
              </Link>
            </div>
          </div>
        )} */}
        <DepositLinkText
          text='Watch a video to learn more'
          link='https://www.youtube.com/watch?v=v71EcisLfak'
          style={{
            marginTop: 20,
            textAlign: 'center',
          }}
        />
      </div>
      <MetaPopup
        openInstructionPopUp={openInstructionPopUp}
        setOpenInstructionPopUp={setOpenInstructionPopUp}
      />
      <div className='share__popup network__error'>
        <ReferralFormPopup
          open={openLoginPopup}
          setOpen={setOpenLoginPopup}
          inputValue={referralCode}
          setInputValue={setReferralCode}
          handleLogin={handleLoginWithReferral}
        />
      </div>
    </div>
  );
}

function RememberMe({ disable }) {
  const { remember } = useSelector((s) => s.rememberMeFlag);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (disable) {
      return;
    }
    dispatch(changeRemainderMeState(remember));
  };

  return (
    <div
      style={{
        marginRight: 'auto',
        marginLeft: 19,
        opacity: disable ? 0.5 : 1,
        cursor: disable ? 'not-allowed' : 'pointer',
      }}
    >
      <label
        className='checkbox exchange__item login__wallet_remember_me'
        style={{
          cursor: disable ? 'not-allowed' : 'pointer',
        }}
      >
        <input
          className='checkbox__input'
          name={'item'}
          type='checkbox'
          onChange={(e) => handleChange(e)}
          checked={remember}
        />
        <span className='checkbox__in'>
          <span className='checkbox__tick'></span>
          <span
            className='checkbox__text exchange__currency'
            style={{
              fontSize: 13,
              color: '#808191',
              fontFamily: 'Poppins',
              fontWeight: 'normal',
            }}
          >
            Remember me
          </span>
        </span>
      </label>
    </div>
  );
}
