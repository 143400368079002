import { useSelector } from 'react-redux';

function useReferrals() {
  // const { getAssests } = useSelector((state) => state);
  const { assetsData } = useSelector((state) => state.getAssests);

  const { response: earningResponse } = useSelector(
    (state) => state.getReferralEarning
  );

  function getTotalReferralInINR() {
    let total = 0;
    if (assetsData && earningResponse && earningResponse.payload.length > 0) {
      earningResponse.payload.forEach((item) => {
        total =
          parseFloat(
            assetsData[item['currency_type'].toLowerCase() + 'usdt']?.last *
              item['total_earning']
          ) + total;
      });
      return total;
    } else {
      return '0.00';
    }
  }

  return {
    getTotalReferralInINR,
  };
}

export default useReferrals;
