export let data_ruby_server = {};

export const isMultiDim = (arr) => {
  // this will give length of multi dim array, if its multi dim array
  if (arr.filter(Array.isArray)?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const orderBookCleanup = () => {
  data_ruby_server = {};
};

export const formatOrderBook = (rawData) => {
  if (!rawData['success']) {
    const [marketKey] = Object.keys(rawData);
    const actData = rawData[marketKey];
    const marketToAppend = marketKey.split('.')[0];

    if (!actData?.message) {
      if (
        !data_ruby_server[marketToAppend] &&
        marketKey.split('.')[1] === 'ob-snap'
      ) {
        data_ruby_server[marketToAppend] = {
          asks: actData['asks'],
          bids: actData['bids'],
        };
      } else {
        ['asks', 'bids'].forEach((val) => {
          if (actData[val] && actData[val].length > 0) {
            if (data_ruby_server[marketToAppend][val].length === 0) {
              if (actData[val][1] !== '') {
                if (!isMultiDim(actData[val])) {
                  data_ruby_server[marketToAppend][val].push(actData[val]);
                }
                sortArray(data_ruby_server[marketToAppend][val], val);
              }
            } else {
              const index = data_ruby_server[marketToAppend][val].findIndex(
                (price) => price[0] == actData[val][0]
              );
              if (index >= 0) {
                if (actData[val][1] === '') {
                  data_ruby_server[marketToAppend][val].splice(index, 1);
                } else {
                  data_ruby_server[marketToAppend][val][index] = actData[val];
                }
              } else if (actData[val][1] !== '') {
                if (!isMultiDim(actData[val])) {
                  data_ruby_server[marketToAppend][val].push(actData[val]);
                }
                sortArray(data_ruby_server[marketToAppend][val], val);
              }
            }
          }
        });
      }
    }
  }

  return data_ruby_server;
};

function sortArray(array, type) {
  array.sort((a, b) => {
    const aVal = parseFloat(a);
    const bVal = parseFloat(b);
    return type === 'bids' ? bVal - aVal : aVal - bVal;
  });
}
