import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getQueryParams } from '../../../utils/query-helper';
import { useAnalytics, useBalance, useSegment } from '../../../utils/hooks';
import { CustomButton } from '../../../components';
import DetailsWidgetCard from '../../price-details/details-widget-card/details-widget-card';
import WalletsBankPopup from '../wallets-bank-popup/wallets-bank-popup';
import { baseCurrency } from '../../../utils/constants';

function WalletTotalBalanceCard({
  setVisibleSideBar,
  selectedData,
  openBankDetailsPopUp,
  setOpenBankDetailsPopup,
}) {
  const [openState, setOpenState] = useState(false);
  const [selectedOption, setSelectedOption] = useState('24h Markets');
  const [selectedInfoData, setSelectedInfoData] = useState(null);
  const history = useHistory();
  const { analytics, sendTrackEvent } = useSegment();
  const location = useLocation();

  const { getInfoAboutCoins, roundUp } = useBalance();

  useEffect(() => {
    if (selectedData) {
      const value = getInfoAboutCoins(selectedData.currency);
      setSelectedInfoData(value);
    }
  });

  return (
    <div className='card' style={{ marginTop: 30 }}>
      {selectedData.currency !== 'usdt' && selectedData.currency !== 'usdc' && (
        <>
          <div className='card__head'>
            <div className='card__title h6'>24h Markets</div>
            {/* <CustomFilterDropDown
          openState={openState}
          setOpenState={setOpenState}
          options={[
            { name: '24h Markets' },
            { name: '16h Markets' },
            { name: ' 8h Markets' },
            { name: '4h Markets' },
          ]}
          selectedOption={selectedOption}
          setSelectedOptions={setSelectedOption}
        /> */}
          </div>
          <div className='widgets'>
            {selectedInfoData && <DetailsWidgetCard data={selectedInfoData} />}
          </div>
        </>
      )}
      {selectedData.currency !== 'usdt' && selectedData.currency !== 'usdc' && (
        <div style={{ marginTop: 22, display: 'grid' }}>
          <CustomButton
            onClick={() => {
              history.push({
                pathname: `/exchange/${selectedData.currency}-${baseCurrency}`,
                search: location.search,
              });
              // analytics?.track('Clicked Buy/Sell button!', {
              //   from: 'Wallet Page',
              //   uid: userInfo?.payload?.uid,
              //   symbol: selectedData.currency,
              //   QueryParams: { ...getQueryParams() },
              //   ip: ip,
              // });
              sendTrackEvent('buttonClicked', {
                title: 'Buy/Sell',
                action: 'Routing to exchange',
              });
            }}
            title={`Buy/Sell ${selectedData?.currency?.toUpperCase()}`}
          />
        </div>
      )}
      <WalletsBankPopup
        state={openBankDetailsPopUp}
        setState={setOpenBankDetailsPopup}
      />
    </div>
  );
}

export default WalletTotalBalanceCard;
