import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

function SkeletonContainer({ className = null, ...otherProps }) {
  const { isdark } = useSelector((state) => state.theme);

  return (
    <SkeletonTheme
      className={className}
      color={isdark ? '#242731' : '#ededed'}
      highlightColor={isdark ? '#191b20' : 'inherit'}
    >
      <Skeleton {...otherProps} />
    </SkeletonTheme>
  );
}

export default SkeletonContainer;
