import React from 'react';

function LoadingBtn({ size, addClassName, bgcolor, text, ...otherProps }) {
  return (
    <button
      className={`more__btn btn btn_orange ${
        size ? 'btn_wide' : ''
      } ${addClassName} ${text?.length ? 'more__text_btn' : ''}`}
      style={{ backgroundColor: bgcolor && bgcolor }}
      {...otherProps}
    >
      <div className='loader'></div>
      {text?.length ? <p>{text}</p> : null}
    </button>
  );
}

export default LoadingBtn;
