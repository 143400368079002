import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { useViewport } from '../../../utils/hooks';

function Popup({
  state,
  setState,
  children,
  disabledClosebtn,
  clearState,
  confetti,
  closeonAnyPopupState,
}) {
  const { width, height } = useViewport();
  useEffect(() => {
    if (state && closeonAnyPopupState) {
      closeonAnyPopupState();
    }
  }, [closeonAnyPopupState, state]);
  return (
    <div>
      {state && (
        <>
          <div className='mfp-bg mfp-zoom-in mfp-ready'></div>
          <div
            className='mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-zoom-in mfp-ready'
            style={{ overflow: 'hidden auto' }}
          >
            <div className='mfp-container mfp-s-ready mfp-inline-holder'>
              {confetti && <Confetti width={width} height={height} />}
              <div className='mfp-content'>
                {confetti && <Confetti width={width} height={height} />}
                <div className='popup popup_settings'>
                  {children}
                  {!disabledClosebtn && (
                    <button
                      title='Close (Esc)'
                      type='button'
                      className='mfp-close'
                      onClick={() => {
                        setState(false);
                        if (clearState) {
                          clearState();
                        }
                      }}
                    >
                      ×
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Popup;
