/* global BigInt */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
// import { walletlink, walletconnect, injected } from '../connectors/connectors';
import { ethers } from 'ethers';
import erc_20_abi from '../contracts/erc20_abi.json';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/userAction';
import {
  ALLOWED_CHAIN_ID,
  ALLOWED_NETWORK_ID,
  ALLOWED_POLYGON_NETWORK_ID,
  ENVIRONMENT,
  MAX_INT_ALLOWANCE,
  STARK_CONTRACT,
  polygon_abi,
  fastWithdrawalABI,
  fastWithdrawalContractAddress,
  fastWithdrawalOwnerAddress,
  rpcURL,
  stark_abi,
  ALLOWED_STARKNET_NETWORK_ID,
  ALLOWED_SCROLL_NETWORK_ID,
  ALLOWED_OPTIMISM_NETWORK_ID,
} from '../constants';
import { getVaultId } from '../../actions/walletAction';
import { getKeyPairFromSignature } from '../contracts/app';
import { sanitizeProvider } from '../sanitize-provider';
import useSegment from './useSegment';
import { walletConnect } from '../connectors-v8/walletConnect';
import { coinbaseWallet } from '../connectors-v8/coinbaseWallet';
import { metaMask } from '../connectors-v8/metaMask';
import { walletConnectV2 } from '../connectors-v8/walletConnectV2';
import Web3 from 'web3';
import {
  etherscanDomain,
  optimismscanDomain,
  polygonscanDomain,
  scrollscanDomain,
  starkscanDomain,
} from '../urls';
import { images } from '../image/img';
import { METAMASK_POSSIBLE_ERRORS } from './useMeta';
import { toast } from 'react-toastify';

export let networkMap = {
  80001: 'polygon',
  137: 'polygon',
  5: 'ethereum',
  1: 'ethereum',
  534352: 'scroll',
  534351: 'scroll',
  420: 'optimism',
  10: 'optimism',
};

export function useEagerConnect() {
  const { isActive } = useWeb3React();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (!tried && isActive) {
      setTried(true);
    }
  }, [tried, isActive]);
  return tried;
}

export function useInactiveListener(suppress = false) {
  const { isActive, error, activate, connector } = useWeb3React();

  const walletDeactivate = () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const { ethereum } = window;
  //   if (ethereum && ethereum.on && !isActive && !error && !suppress) {
  //     const handleConnect = () => {
  //       //console.log("Handling 'connect' event");
  //     };
  //     const handleChainChanged = (chainId) => {
  //       //console.log("Handling 'chainChanged' event with payload", chainId);
  //       walletDeactivate();
  //       dispatch(logout(true));
  //     };
  //     const handleAccountsChanged = (accounts) => {
  //       //console.log("Handling 'accountsChanged' event with payload", accounts);
  //       walletDeactivate();
  //       dispatch(logout(true));
  //     };
  //     const handleNetworkChanged = (networkId) => {
  //       //console.log("Handling 'networkChanged' event with payload", networkId);
  //       walletDeactivate();
  //       dispatch(logout(true));
  //     };
  //     ethereum.on('connect', handleConnect);
  //     ethereum.on('chainChanged', handleChainChanged);
  //     ethereum.on('accountsChanged', handleAccountsChanged);
  //     ethereum.on('networkChanged', handleNetworkChanged);

  //     return () => {
  //       if (ethereum.removeListener) {
  //         ethereum.removeListener('connect', handleConnect);
  //         ethereum.removeListener('chainChanged', handleChainChanged);
  //         ethereum.removeListener('accountsChanged', handleAccountsChanged);
  //         ethereum.removeListener('networkChanged', handleNetworkChanged);
  //       }
  //     };
  //   }
  // }, [isActive, error, suppress, activate, dispatch]);
}

export let networkOptions = [
  {
    value: 'Ethereum',
    chainId: ALLOWED_NETWORK_ID,
    network: 'ethereum',
    symbol: 'eth',
    label: (
      <div className='deposit_dropdown'>
        <img src={images.ethereum} className='dropdown-item' />
        {'Ethereum'}
        {/* <span>{item?.symbol?.toUpperCase()}</span> */}
      </div>
    ),
  },
  {
    value: 'Polygon',
    chainId: ALLOWED_POLYGON_NETWORK_ID,
    symbol: 'matic',
    network: 'polygon',
    label: (
      <div className='deposit_dropdown'>
        <img src={images.polygon} className='dropdown-item' />
        {'Polygon'}
        {/* <span>New ✨</span> */}
      </div>
    ),
  },
  {
    value: 'Scroll',
    chainId: ALLOWED_SCROLL_NETWORK_ID,
    symbol: 'eth',
    network: 'scroll',
    label: (
      <div className='deposit_dropdown'>
        <img src={images.scrollLogo} className='dropdown-item' />
        {'Scroll'}
        {/* <span>New ✨</span> */}
      </div>
    ),
  },
  {
    value: 'Optimism',
    chainId: ALLOWED_OPTIMISM_NETWORK_ID,
    symbol: 'eth',
    network: 'optimism',
    label: (
      <div className='deposit_dropdown'>
        <img src={images.optimismLogo} className='dropdown-item' />
        {'Optimism'}
        <span>New ✨</span>
      </div>
    ),
  },
  {
    value: 'Starknet',
    chainId: ALLOWED_STARKNET_NETWORK_ID,
    symbol: 'eth',
    network: 'starknet',
    label: (
      <div className='deposit_dropdown'>
        <img src={images.starkWareLogo} className='dropdown-item' />
        {'Starknet'}
        {/* <span>New ✨</span> */}
      </div>
    ),
  },
];

export const useWalletMethods = (coin) => {
  const {
    isActive,
    deactivate,
    account,
    provider: libraryProvider,
    connector,
    chainId,
    isActivating,
  } = useWeb3React();
  const [selectedData, setSelectedData] = useState(null);
  const [ethData, setEthData] = useState(null);
  const [ethereum, setEthereum] = useState(null);
  // const { ethereum } = window;
  const token_contract = selectedData ? selectedData?.token_contract : null;
  const [vaultIdDeposit, setVaultDeposit] = useState(null);
  const dispatch = useDispatch();
  const { sendTrackEvent } = useSegment();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { state: userVia } = useSelector((state) => state.userVia);
  const { coinsDetails, loading: coinsLoading } = useSelector(
    (state) => state.getCoins
  );
  const { state } = useSelector((state) => state.userKey);
  const { details } = useSelector((state) => state.depositDetails);

  const { state: currentNetworkData } = useSelector((s) => s.ethNetworkDetails);

  const stark_contract = STARK_CONTRACT;
  const max_int_allowance = MAX_INT_ALLOWANCE;
  const polygon_contract =
    details?.payload?.network_config?.POLYGON?.deposit_contract;
  const optimism_contract =
    details?.payload?.network_config?.OPTIMISM?.deposit_contract;

  let signer;
  let provider;
  let library;

  if (libraryProvider) {
    provider = new ethers.providers.Web3Provider(libraryProvider?.provider);
    signer = libraryProvider.getSigner();
    library = libraryProvider;
  }

  let contract_final = null;
  let stark_contract_final = null;

  if (selectedData && library && signer) {
    if (token_contract && token_contract !== '') {
      contract_final = new ethers.Contract(token_contract, erc_20_abi, signer);
    }
    stark_contract_final = new ethers.Contract(
      stark_contract,
      stark_abi,
      signer
    );
  }

  useEffect(() => {
    if (coin && coin !== 'inr') {
      const data = coinsDetails?.convertedArray?.find(
        (item) => item?.symbol === coin
      );
      setSelectedData(data);
    }
  }, [coin, coinsDetails]);

  useEffect(() => {
    if (coin) {
      const data = coinsDetails?.convertedArray?.find((item) => {
        return item?.symbol === 'eth';
      });
      setEthData(data);
    }
  }, [coin, coinsDetails]);

  function getNonce() {
    if (signer && provider) {
      let baseNonce = provider.getTransactionCount(signer.getAddress());
      let nonceOffset = 0;
      return baseNonce.then((nonce) => nonce + nonceOffset++);
    }
  }

  useEffect(() => {
    if (userVia && userVia?.via === 'wallet_connect' && !isActive) {
      try {
        walletConnect.activate().catch((error) => {
          if (error.message === 'The user rejected the request.') {
            walletDeactivate();
            dispatch(logout(true));
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  }, [userVia, dispatch, isActive]);

  useEffect(() => {
    if (
      userVia &&
      userVia?.via === 'wallet_connect_v2' &&
      !isActive &&
      !isActivating
    ) {
      try {
        walletConnectV2.connectEagerly().catch((error) => {
          //console.log({ error });
          if (error.message === 'The user rejected the request.') {
            walletDeactivate();
            dispatch(logout(true));
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  }, [userVia, dispatch, isActive, isActivating]);

  useEffect(() => {
    if (
      userVia &&
      userVia?.via === 'wallet_link' &&
      !isActive &&
      !isActivating
    ) {
      coinbaseWallet.activate().catch((error) => {
        if (error.message === 'User denied account authorization') {
          walletDeactivate();
          dispatch(logout(true));
        }
      });
    }
  }, [userVia, dispatch, isActive, isActivating]);

  useEffect(() => {
    if (userVia && userVia?.via === 'metamask' && !isActive && !isActivating) {
      // console.log('log -1');
      metaMask.activate().catch((error) => {
        // console.log({ error }, 'sxjkahdkhskdsjfhscdjkjdsckhscds');
        if (error.message === 'User denied account authorization') {
          walletDeactivate();
          dispatch(logout(true));
        }
      });
    }
  }, [userVia, dispatch, isActive]);

  useEffect(() => {
    // console.log('log -2');
    sanitizeProvider(setEthereum);
  }, []);

  function dequantize(number, decimals) {
    const factor = 10 ** decimals;
    return number / factor;
  }

  const getAllowance = async (symbol, network) => {
    try {
      if (account) {
        let contractAddress = networkConfig[network]?.contractAddress;
        let erc20abi = networkConfig[network]?.erc20Abi;
        const coinInfo = getInfoAboutCoins(symbol, network);
        let contract = new ethers.Contract(
          coinInfo?.token_contract,
          erc20abi,
          signer
        );
        let allowance = await contract.allowance(account, contractAddress);
        return dequantize(allowance, selectedData?.blockchain_decimal);
      }
    } catch (error) {
      throw error;
    }
  };

  const approveUnlimitedAllowance = async (symbol, network) => {
    // console.log('approveUnlimitedAllowance');
    // console.log('log -4');
    if (!networkConfig[network]?.contractAddress) {
      return;
    }
    let contractAddress = networkConfig[network]?.contractAddress;
    const coinInfo = getInfoAboutCoins(symbol, network);
    let erc20abi = networkConfig[network]?.erc20Abi;
    let contract = new ethers.Contract(
      coinInfo?.token_contract,
      erc20abi,
      signer
    );

    let approval = await contract.approve(
      contractAddress,
      ethers.BigNumber.from(max_int_allowance)
    );
    return approval;
  };

  const getInfoAboutCoins = (currency, network = 'ethereum') => {
    // console.log('log -5');

    if (coinsDetails?.convertedArray) {
      if (network === 'ethereum') {
        const { convertedArray } = coinsDetails;
        const data = convertedArray.find((data) => data.symbol === currency);
        return data;
      } else if (network === 'polygon') {
        if (details?.payload) {
          let polygonCoinDetails =
            details?.payload['network_config']['POLYGON']['tokens'];

          const convertedArr = Object.keys(polygonCoinDetails).map((key) => {
            polygonCoinDetails[key]['symbol'] = key;
            return polygonCoinDetails[key];
          });
          const { convertedArray } = coinsDetails;
          let formattedData = convertedArr?.map((item) => {
            const externalCoinInfo = convertedArray.find(
              (data) => data.symbol === item?.symbol
            );
            // let externalCoinInfo = getInfoAboutCoins(item?.symbol);
            return {
              ...externalCoinInfo,
              ...item,
              chainId: ALLOWED_POLYGON_NETWORK_ID,
              network: 'polygon',
            };
          });

          let filteredData = formattedData.find(
            (item) => item.symbol === currency
          );
          return filteredData || null;
        } else {
          return null;
        }
      } else if (network === 'scroll') {
        if (details?.payload) {
          let scrollCoinDetails =
            details?.payload['network_config']['SCROLL']['tokens'];

          const convertedArr = Object.keys(scrollCoinDetails).map((key) => {
            scrollCoinDetails[key]['symbol'] = key;
            return scrollCoinDetails[key];
          });
          const { convertedArray } = coinsDetails;
          let formattedData = convertedArr?.map((item) => {
            const externalCoinInfo = convertedArray.find(
              (data) => data.symbol === item?.symbol
            );
            // let externalCoinInfo = getInfoAboutCoins(item?.symbol);
            return {
              ...externalCoinInfo,
              ...item,
              chainId: ALLOWED_SCROLL_NETWORK_ID,
              network: 'scroll',
            };
          });

          let filteredData = formattedData.find(
            (item) => item.symbol === currency
          );
          return filteredData || null;
        } else {
          return null;
        }
      } else if (network === 'optimism') {
        if (details?.payload) {
          let optimismCoinDetails =
            details?.payload['network_config']['OPTIMISM']['tokens'];

          const convertedArr = Object.keys(optimismCoinDetails).map((key) => {
            optimismCoinDetails[key]['symbol'] = key;
            return optimismCoinDetails[key];
          });
          const { convertedArray } = coinsDetails;
          let formattedData = convertedArr?.map((item) => {
            const externalCoinInfo = convertedArray.find(
              (data) => data.symbol === item?.symbol
            );
            // let externalCoinInfo = getInfoAboutCoins(item?.symbol);
            return {
              ...externalCoinInfo,
              ...item,
              chainId: ALLOWED_OPTIMISM_NETWORK_ID,
              network: 'optimism',
            };
          });

          let filteredData = formattedData.find(
            (item) => item.symbol === currency
          );
          return filteredData || null;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  const getSingleCoinAllowance = async (symbol, network = 'ethereum') => {
    try {
      if (!signer && symbol) {
        return;
      }
      let contractAddress = networkConfig[network]?.contractAddress;
      let erc20abi = networkConfig[network]?.erc20Abi;
      let coinInfo = getInfoAboutCoins(symbol, network);
      console.log({ coinInfo }, 'getSingleCoinAllowance');
      if (coinInfo) {
        let contract = new ethers.Contract(
          coinInfo?.token_contract,
          erc20abi,
          signer
        );
        let allowance = await contract.allowance(account, contractAddress);
        console.log(
          { allowance: allowance?.toString() },
          'getSingleCoinAllowance'
        );
        return dequantize(Number(allowance), coinInfo?.blockchain_decimal);
      }
    } catch (error) {
      throw error;
    }
  };

  // const getSingleCoinAllowancev2 = async (symbol, network = 'ethereum') => {
  //   console.log({ symbol, network }, 'getSingleCoinAllowance');
  //   if (
  //     !networkConfig[network]?.contractAddress ||
  //     !userVia?.address ||
  //     !account
  //   ) {
  //     return;
  //   }
  //   // console.log('calling getAllowance inside hoooks');
  //   const rpcUrl = networkConfig[network].rpcUrl;
  //   console.log({ rpcUrl });
  //   const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  //   let contractAddress = networkConfig[network]?.contractAddress;
  //   let erc20abi = networkConfig[network]?.erc20Abi;
  //   let coinInfo = getInfoAboutCoins(symbol, network);

  //   console.log({
  //     coinInfo,
  //     contractAddress,
  //     cond: contractAddress === polygon_contract,
  //     tokenc: coinInfo?.token_contract,
  //   });

  //   if (coinInfo) {
  //     let contract = new ethers.Contract(
  //       coinInfo?.token_contract,
  //       erc20abi,
  //       provider
  //     );
  //     let allowance = await contract.allowance(account, contractAddress);

  //     return {
  //       allowance: dequantize(Number(allowance), coinInfo?.blockchain_decimal),
  //       symbol: symbol,
  //       network,
  //       chainId,
  //     };
  //   }
  // };

  const startDeposit = async (stark_key, vaultId, amount) => {
    if (selectedData && vaultId) {
      const parsedAmount = ethers.utils.parseEther(amount);

      const quanitizatedAmount = ethers.utils.parseUnits(
        amount?.toString(),
        Number(selectedData?.quanitization)
      );

      let gwei = ethers.utils.formatUnits(parsedAmount, 'gwei');

      try {
        let overrides = {
          value: parsedAmount,
          nonce: getNonce(),
        };

        let deposited_erc_response;

        if (selectedData?.stark_asset_id === ethData?.stark_asset_id) {
          deposited_erc_response = await stark_contract_final.depositEth(
            stark_key,
            selectedData?.stark_asset_id,
            vaultId,
            overrides
          );
        } else {
          deposited_erc_response = await stark_contract_final.depositERC20(
            stark_key,
            selectedData?.stark_asset_id,
            vaultId,
            quanitizatedAmount
          );
        }

        let tx_backend_feed = {
          amount: coin === 'eth' ? gwei * 10 : quanitizatedAmount,
          stark_key: ethers.BigNumber.from(stark_key).toHexString(),
          token_id: ethers.BigNumber.from(
            selectedData?.stark_asset_id
          ).toHexString(),
          type: 'DepositRequest',
          vault_id: vaultId,
          deposit_blockchain_hash: deposited_erc_response['hash'],
          deposit_blockchain_nonce: deposited_erc_response['nonce'],
        };

        return tx_backend_feed;
      } catch (e) {
        throw e;
      }
    }
  };

  const getCrossChainDepositContractAddress = (network) => {
    // Available networks
    // * polygon
    // * optimism

    if (!network) {
      console.log('Pass arg for `getCrossChainDepositContractAddress`');
    }

    const polygon_contract =
      details?.payload?.network_config?.POLYGON?.deposit_contract;
    const optimism_contract =
      details?.payload?.network_config?.OPTIMISM?.deposit_contract;

    let data = {
      polygon: polygon_contract,
      optimism: optimism_contract,
    };

    return data[network];
  };

  const startBrineCrossChainDeposit = async (selectedData, value) => {
    let polygon_contract = new ethers.Contract(
      getCrossChainDepositContractAddress(selectedData?.network),
      polygon_abi.abi,
      signer
    );

    try {
      if (polygon_contract) {
        let res;
        let amount = value * 10 ** 18;
        let address = userVia?.address;

        if (selectedData?.symbol === 'matic') {
          res = await polygon_contract.depositNative({
            from: address,
            value: amount,
          });
        } else {
          res = await polygon_contract?.deposit(
            selectedData?.token_contract,
            String(value * 10 ** Number(selectedData?.blockchain_decimal)),
            {
              from: address?.toString(),
            }
          );
        }
        return res;
      }
    } catch (error) {
      throw error;
    }
  };

  const getBalance = async (symbol, network) => {
    try {
      // console.log('log -7');
      if (!account || !symbol || !network) {
        return;
      }

      let provider = new ethers.providers.Web3Provider(
        libraryProvider?.provider
      );
      let signer = libraryProvider.getSigner();

      if (
        (symbol === 'eth' && network === 'ethereum') ||
        (symbol === 'matic' && network === 'polygon') || 
        (symbol === 'eth' && network === 'optimism') 
      ) {
        const ethBalance = await library.getBalance(account);
        return ethers.utils.formatEther(ethBalance);
      } else {
        const coinInfo = getInfoAboutCoins(symbol, network);
        if (coinInfo) {
          // console.log({ coinInfo });
          const contract = new ethers.Contract(
            coinInfo?.token_contract,
            erc_20_abi,
            provider
          );
          const address = await signer.getAddress();
          const balance = (await contract.balanceOf(address)).toString();

          const normalBalance =
            balance / Math.pow(10, coinInfo?.blockchain_decimal);
          // console.log({ normalBalance });
          return Number(normalBalance);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const getBalanceV2 = async (symbol, network) => {
    try {
      if (!account && userVia?.via === 'metamask') {
        toast.error('Your session has expired; please log in again', {
          toastId: 'session_logout',
        });
        setTimeout(() => {
          dispatch(logout(true));
        }, 2000);
        return;
      }
      if (!account || !symbol || !network || !libraryProvider) {
        return;
      }

      let provider = new ethers.providers.Web3Provider(
        libraryProvider?.provider
      );

      let signer = libraryProvider.getSigner();

      if (
        (symbol === 'eth' && network === 'ethereum') ||
        (symbol === 'matic' && network === 'polygon')
      ) {
        const ethBalance = await library.getBalance(account);
        return {
          balance: ethers.utils.formatEther(ethBalance),
          symbol,
          network,
        };
      } else {
        const coinInfo = getInfoAboutCoins(symbol, network);
        if (coinInfo) {
          const contract = new ethers.Contract(
            coinInfo?.token_contract,
            erc_20_abi,
            provider
          );
          const address = await signer.getAddress();
          const balance = (await contract.balanceOf(address)).toString();
          const normalBalance =
            balance / Math.pow(10, coinInfo?.blockchain_decimal);
          // console.log({ normalBalance, balance });
          return { balance: Number(normalBalance), symbol, network };
        }
      }
    } catch (error) {
      // console.log({ error });
      throw error;
    }
  };

  const getBalanceWithdraw = async (stark_key, token_contract) => {
    try {
      // console.log('log -8');

      let provider = null;
      if (ALLOWED_CHAIN_ID != Number(chainId)) {
        const rpcUrl = networkConfig['ethereum'].rpcUrl;
        provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      } else {
        provider = new ethers.providers.Web3Provider(libraryProvider?.provider);
      }
      // const rpcUrl = networkConfig['ethereum'].rpcUrl;
      // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      if (provider) {
        let stark_contract_final = new ethers.Contract(
          stark_contract,
          stark_abi,
          provider
        );

        if (stark_key && token_contract) {
          let res = await stark_contract_final.getWithdrawalBalance(
            ethers.BigNumber.from(stark_key),
            ethers.BigNumber.from(token_contract)
          );
          return ethers.BigNumber.from(res);
        }
      }
    } catch (error) {
      console.log(error, 'getBalanceWithdraw');
      throw error;
    }
  };

  const startWithdrawal = async (symbol, key) => {
    let stark_contract_final = new ethers.Contract(
      stark_contract,
      stark_abi,
      signer
    );

    const assetId = getInfoAboutCoins(symbol)?.stark_asset_id;

    // console.log({ key, assetId, symbol });

    if (key && assetId) {
      try {
        const res = await stark_contract_final.withdraw(key, assetId);
        return res;
      } catch (error) {
        throw error;
      }
    }
  };

  const handleGetVaultId = async () => {
    // console.log('log -9');

    try {
      const data = await getVaultId(userInfo?.token?.access, 'eth');
      return data;
    } catch (error) {
      //console.log(error);
    }
  };

  const getGasFee = async (symbol) => {
    // console.log('getGasFee');
    // console.log('log -10');
    try {
      if (selectedData) {
        if (!state) {
          return;
        }

        let stark_public_key = getKeyPairFromSignature(state)
          .getPublic()
          .getX();
        const stark_key = ethers.BigNumber.from(stark_public_key).toHexString();

        let vaultId;
        if (!vaultIdDeposit) {
          const vaultRes = await handleGetVaultId(symbol);
          vaultId = vaultRes?.payload?.id;
          if (!vaultId) {
            return 0;
          } else {
            setVaultDeposit(vaultId);
          }
        } else {
          vaultId = vaultIdDeposit;
        }
        const parsedAmount = ethers.utils.parseEther('0.0000001');
        const ethAssestId = getInfoAboutCoins('eth')?.stark_asset_id;
        let overrides = {
          value: parsedAmount,
          nonce: getNonce(),
        };

        if (provider) {
          const gasPrice = await provider.getGasPrice();
          const functionGasFees =
            await stark_contract_final?.estimateGas?.depositEth(
              stark_key,
              ethAssestId,
              vaultId,
              overrides
            );
          const finalGasPrice = gasPrice * functionGasFees;
          return ethers.utils.formatEther(finalGasPrice);
        }
      }
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  const getChainId = async () => {
    // console.log('getChainId');
    // console.log('log -11');
    if (!provider) {
      return;
    }
    try {
      const chainId = await provider.getNetwork();
      return chainId;
    } catch (error) {
      //console.log(error);
    }
  };

  const getGasFeev2 = async (selectedData, stark_key, vaultId) => {
    // console.log('getGasFeev2');
    // console.log('log -12');

    let provider = new ethers.providers.Web3Provider(libraryProvider?.provider);
    let signer = libraryProvider.getSigner();

    let stark_contract_final = new ethers.Contract(
      stark_contract,
      stark_abi,
      signer
    );

    if (!stark_contract_final || !selectedData?.symbol) {
      return;
    }

    let amountBasedOnCoin = {
      eth: '0.00001',
      btc: '0.000001',
      usdc: '1',
      usdt: '1',
    };

    if (selectedData && vaultId) {
      let amount = '0';
      try {
        const parsedAmount = ethers.utils.parseEther(amount);
        let overrides = {
          value: parsedAmount,
          nonce: getNonce(),
        };

        let functionGasFees;
        const gasPrice = await provider.getGasPrice();

        if (selectedData?.stark_asset_id === ethData?.stark_asset_id) {
          functionGasFees = await stark_contract_final?.estimateGas?.depositEth(
            stark_key,
            selectedData?.stark_asset_id,
            vaultId,
            overrides
          );
        } else {
          functionGasFees =
            await stark_contract_final?.estimateGas?.depositERC20(
              stark_key,
              selectedData?.stark_asset_id,
              vaultId,
              parseFloat(amount) *
                Math.pow(10, parseFloat(selectedData?.quanitization))
            );
        }
        // console.log({ functionGasFees, gasPrice, stark_contract_final });
        if (functionGasFees) {
          const finalGasPrice = gasPrice * functionGasFees;
          // console.log({ functionGasFees, symbol: selectedData?.symbol });
          return ethers.utils.formatEther(finalGasPrice.toString());
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  };

  const getBrineCrossChainGasFeeV2 = async (selectedData) => {
    // console.log(
    //   'getBrineCrossChainGasFeeV2',
    //   polygon_contract,
    //   userVia?.address,
    //   selectedData
    // );
    // console.log('log -13');

    if (
      (chainId == ALLOWED_POLYGON_NETWORK_ID ||
        chainId == ALLOWED_OPTIMISM_NETWORK_ID) &&
      account &&
      selectedData?.symbol
    ) {
      // console.log('log -14');
      let provider = new ethers.providers.Web3Provider(
        libraryProvider?.provider
      );
      let signer = libraryProvider.getSigner();

      let functionGasFees = 0;

      let polygon_contract_final = new ethers.Contract(
        selectedData?.network !== 'polygon'
          ? optimism_contract
          : polygon_contract,
        polygon_abi.abi,
        signer
      );

      try {
        if (polygon_contract_final) {
          let amount = 0.0000001 * 10 ** 18;
          let address = userVia?.address;

          if (selectedData?.symbol !== 'matic') {
            functionGasFees =
              await polygon_contract_final?.estimateGas?.depositNative({
                from: address,
                value: amount,
              });
          } else {
            functionGasFees =
              await polygon_contract_final?.estimateGas?.deposit(
                selectedData?.token_contract,
                0.000001 * 10 ** Number(selectedData?.blockchain_decimal),
                {
                  from: address,
                }
              );
          }
          const gasPrice = await provider.getGasPrice();
          // console.log({ functionGasFees, gasPrice });
          if (functionGasFees) {
            const finalGasPrice = gasPrice * functionGasFees;
            // console.log({
            //   finalGasPrice: ethers.utils.formatEther(finalGasPrice.toString()),
            // });
            return ethers.utils.formatEther(finalGasPrice.toString());
          }
        }
      } catch (error) {
        console.log({ error }, 'getBrineCrossChainGasFeeV2');
      }
    }
  };

  const getCurrentBlock = async () => {
    // console.log('log -14');
    if (provider) {
      try {
        let blockNumber = await provider.getBlockNumber();
        return blockNumber;
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const waitAddon = async (txHash, blockNumber) => {
    // console.log('log -15');
    if (provider) {
      try {
        let res = await provider.waitForTransaction(txHash, blockNumber);
        return res;
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const getTransactionByHash = async (hash) => {
    // console.log('log -16');
    if (provider) {
      try {
        let block = await provider.getTransaction(hash);
        return block?.blockNumber;
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const capitalize = (str) => {
    if (str?.length) {
      const error = str.charAt(0).toUpperCase() + str.slice(1);
      return error;
    }
  };

  const walletDeactivate = () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
  };

  const switchChain = async (desiredChainId) => {
    // console.log('log -17');
    // console.log({ desiredChainId });
    try {
      await connector.activate(desiredChainId);
      // await connector.provider.request({
      //   method: 'wallet_switchEthereumChain',
      //   params: [{ chainId: '0x13881' }],
      // });
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  const addNetwork = async (network = 'polygon') => {
    const chainConfigs = {
      polygon: {
        mainnet: {
          chainId: '0x89',
          chainName: 'Polygon Mainnet',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://polygon-rpc.com'],
          blockExplorerUrls: ['https://polygonscan.com'],
        },
        testnet: {
          chainId: '0x13881',
          chainName: 'Polygon Testnet',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://polygon-mumbai-bor.publicnode.com'],
          blockExplorerUrls: ['https://mumbai.polygonscan.com'],
        },
      },
      scroll: {
        mainnet: {
          chainId: '0x82750',
          chainName: 'Scroll',
          nativeCurrency: {
            name: 'ETHEREUM',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.scroll.io'],
          blockExplorerUrls: ['https://scrollscan.com'],
        },
        testnet: {
          chainId: '0x8274f',
          chainName: 'Scroll Sepolia',
          nativeCurrency: {
            name: 'ETHEREUM',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://sepolia-rpc.scroll.io'],
          blockExplorerUrls: ['https://sepolia.scrollscan.com'],
        },
      },
      optimism: {
        mainnet: {
          chainId: '0xa',
          chainName: 'Optimism',
          nativeCurrency: {
            name: 'ETHEREUM',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://optimism.llamarpc.com'],
          blockExplorerUrls: ['https://optimistic.etherscan.io'],
        },
        testnet: {
          chainId: '0x1a4',
          chainName: 'Optimism Goerli Testnet',
          nativeCurrency: {
            name: 'ETHEREUM',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://endpoints.omniatech.io/v1/op/goerli/public'],
          blockExplorerUrls: ['https://optimism-goerli.blockscout.com'],
        },
      },
    };

    try {
      await connector.provider.request({
        method: 'wallet_addEthereumChain',
        params: [chainConfigs[network][ENVIRONMENT]],
      });
    } catch (error) {
      //console.log({ error });
      throw error;
    }
  };

  const getPopulatedData = (isDeposit = false) => {
    let finalArr = [];
    let ethData = [];
    let polygonData = [];
    let starknetData = [];
    let scrollData = [];
    let optimismData = [];

    // console.log('getPopulatedData');
    // console.log('log -19');

    if (coinsDetails && !coinsLoading) {
      coinsDetails?.convertedArray.forEach((item) => {
        let formattedData = {
          value: item?.symbol + '' + item?.name + '  eth',
          network: 'ethereum',
          chainId: ALLOWED_NETWORK_ID,
          currency: item?.symbol,
          label: (
            <div className='deposit_dropdown'>
              <img src={item?.logo} className='dropdown-item' />
              <img src={images?.ethereum} className='dropdown-network' />
              {item?.name}
              <span>{item?.symbol?.toUpperCase()}</span>
            </div>
          ),
          ...item,
        };
        finalArr.push(formattedData);
        ethData.push(formattedData);
      });
    }

    if (details?.payload?.network_config) {
      let polygonCoinDetails =
        details?.payload['network_config']['POLYGON']['tokens'];
      let starknetCoinDetails =
        details?.payload['network_config']['STARKNET']['tokens'];
      let scrollCoinDetails =
        details?.payload['network_config']['SCROLL']['tokens'];
      let optimismCoinDetails =
        details?.payload['network_config']['OPTIMISM']['tokens'];

      const convertedPolygonArr = Object.keys(polygonCoinDetails).map((key) => {
        polygonCoinDetails[key]['symbol'] = key;
        return polygonCoinDetails[key];
      });

      const convertedStarknetArr = Object.keys(starknetCoinDetails).map(
        (key) => {
          starknetCoinDetails[key]['symbol'] = key;
          return starknetCoinDetails[key];
        }
      );

      const convertedScrollArr = Object.keys(scrollCoinDetails).map((key) => {
        scrollCoinDetails[key]['symbol'] = key;
        return scrollCoinDetails[key];
      });

      const convertedOptimismArr = Object.keys(optimismCoinDetails).map(
        (key) => {
          optimismCoinDetails[key]['symbol'] = key;
          return optimismCoinDetails[key];
        }
      );

      convertedPolygonArr?.forEach((item) => {
        let externalCoinInfo = getInfoAboutCoins(item?.symbol);
        let formattedData = {
          ...externalCoinInfo,
          value: item?.symbol + '' + externalCoinInfo?.name + ' pol',
          chainId: ALLOWED_POLYGON_NETWORK_ID,
          network: 'polygon',
          currency: item?.symbol,
          label: (
            <div className='deposit_dropdown'>
              <img src={externalCoinInfo?.logo} className='dropdown-item' />
              {/* <img src={images.polygon} className='dropdown-network' /> */}
              {externalCoinInfo?.name}
              <span>{externalCoinInfo?.symbol?.toUpperCase()}</span>
            </div>
          ),
          ...item,
        };
        polygonData.push(formattedData);
        finalArr.push(formattedData);
      });
      if (isDeposit) {
        convertedScrollArr?.forEach((item) => {
          let externalCoinInfo = getInfoAboutCoins(item?.symbol);
          let formattedData = {
            ...externalCoinInfo,
            value: item?.symbol + '' + externalCoinInfo?.name + ' scroll',
            chainId: ALLOWED_SCROLL_NETWORK_ID,
            network: 'scroll',
            currency: item?.symbol,
            label: (
              <div className='deposit_dropdown'>
                <img src={externalCoinInfo?.logo} className='dropdown-item' />
                {/* <img src={images.scrollLogo} className='dropdown-network' /> */}
                {externalCoinInfo?.name}
                <span>{externalCoinInfo?.symbol?.toUpperCase()}</span>
              </div>
            ),
            ...item,
          };
          scrollData.push(formattedData);
          finalArr.push(formattedData);
        });
      }

      convertedOptimismArr?.forEach((item) => {
        let externalCoinInfo = getInfoAboutCoins(item?.symbol);
        let formattedData = {
          ...externalCoinInfo,
          value: item?.symbol + '' + externalCoinInfo?.name + ' optimism',
          chainId: ALLOWED_OPTIMISM_NETWORK_ID,
          network: 'optimism',
          currency: item?.symbol,
          label: (
            <div className='deposit_dropdown'>
              <img src={externalCoinInfo?.logo} className='dropdown-item' />
              {/* <img src={images.scrollLogo} className='dropdown-network' /> */}
              {externalCoinInfo?.name}
              <span>{externalCoinInfo?.symbol?.toUpperCase()}</span>
            </div>
          ),
          ...item,
        };
        optimismData.push(formattedData);
        finalArr.push(formattedData);
      });
      // if (ENVIRONMENT !== 'testnet') {
      convertedStarknetArr?.forEach((item) => {
        let externalCoinInfo = getInfoAboutCoins(item?.symbol);
        let formattedData = {
          ...externalCoinInfo,
          value: item?.symbol + '' + externalCoinInfo?.name + ' stark',
          chainId: ALLOWED_STARKNET_NETWORK_ID,
          network: 'starknet',
          currency: item?.symbol,
          label: (
            <div className='deposit_dropdown'>
              <img src={externalCoinInfo?.logo} className='dropdown-item' />
              {/* <img src={images.starkWareLogo} className='dropdown-network' /> */}
              {externalCoinInfo?.name}
              <span>{externalCoinInfo?.symbol?.toUpperCase()}</span>
            </div>
          ),
          ...item,
        };

        starknetData.push(formattedData);
        // if (isDeposit) {
        finalArr.push(formattedData);
        // }
      });
      // }
    }

    return {
      allData: finalArr,
      ethData,
      polygonData,
      starknetData,
      scrollData: scrollData,
      optimismData,
    };
  };

  const getFastWithdrawalGasFee = async (symbol, network = 'ethereum') => {
    try {
      const web3 = new Web3(rpcURL[network][ENVIRONMENT]);
      let coinInfo = getInfoAboutCoins(symbol, network);

      const contract = new web3.eth.Contract(
        fastWithdrawalABI,
        fastWithdrawalContractAddress[network][ENVIRONMENT]
      );

      let gasEstimate; // gas limit

      if (symbol === 'eth') {
        gasEstimate = await contract.methods
          .withdrawNative(account, '0', '9999999999999')
          .estimateGas({
            from: fastWithdrawalOwnerAddress[network][ENVIRONMENT],
          });
      } else {
        gasEstimate = await contract.methods
          .withdraw(coinInfo?.token_contract, account, '0', '9999999999999')
          .estimateGas({
            from: fastWithdrawalOwnerAddress[network][ENVIRONMENT],
          });
      }
      const gasPrice = await web3.eth.getGasPrice();
      const gasFeeWei = web3.utils
        .toBN(gasEstimate)
        .mul(web3.utils.toBN(gasPrice));
      const gasFeeEther = web3.utils.fromWei(gasFeeWei, 'ether');

      // console.log(`Token Contract : ${coinInfo?.token_contract}`);
      // console.log(`account : ${account}`);
      // console.log(`Gas Estimate: ${gasEstimate}`);
      // console.log(`Gas Price in WEI : ${gasPrice}`);
      // console.log(`Gas Price: ${web3.utils.fromWei(gasPrice, 'gwei')} Gwei`);
      // console.log(`Gas Fee: ${gasFeeEther} ETH`);

      return gasFeeEther;
    } catch (error) {
      console.log({ getFastWithdrawalGasFee: error });
    }
  };

  const getNetworkOptionByName = (nameOfNetwork) => {
    // Available networks
    // * ethereum
    // * polygon
    // * scroll
    // * starknet
    // * optimism

    if (!nameOfNetwork) {
      console.log('Pass arg for `getNetworkOptionByName`');
    }

    // Note: Remove this once starknet is available for the testnet from layerswap
    let networkList = networkOptions;
    // ENVIRONMENT === 'testnet' ? networkOptions?.slice(0, 3) : networkOptions;

    const filteredNetwork = networkList?.find(
      (item) => item.network === nameOfNetwork
    );

    return filteredNetwork || null;
  };

  const getCoinListByNetworkName = (nameOfNetwork, isDeposit = false) => {
    // Available networks
    // * ethereum
    // * polygon
    // * scroll
    // * starknet
    // * optimism

    if (!nameOfNetwork) {
      console.log('Pass arg for `getCoinListByNetworkName`');
    }

    let { starknetData, polygonData, scrollData, ethData, optimismData } =
      getPopulatedData(isDeposit);

    let coinDataObj = {
      scroll: scrollData,
      ethereum: ethData,
      starknet: starknetData,
      polygon: polygonData,
      optimism: optimismData,
    };

    return coinDataObj[nameOfNetwork] || null;
  };

  const getAllowedDepositCoinListByNetwork = (nameOfNetwork) => {
    // Available networks
    // * ethereum
    // * polygon
    // * scroll
    // * optimism

    if (!nameOfNetwork) {
      console.log('Pass arg for `getAllowedDepositCoinListByNetwork`');
    }

    let allowedPolygonList =
      details?.payload?.network_config?.POLYGON?.allowed_tokens_for_deposit;
    let allowedScrollList =
      details?.payload?.network_config?.SCROLL?.allowed_tokens_for_deposit;
    let allowedEthereumList = coinsDetails?.convertedArray.map(
      (item) => item.symbol
    );
    let allowedOptimismList =
      details?.payload?.network_config?.OPTIMISM?.allowed_tokens_for_deposit;

    let coinDataObj = {
      scroll: allowedScrollList,
      ethereum: allowedEthereumList,
      polygon: allowedPolygonList,
      optimism: allowedOptimismList,
    };

    return coinDataObj[nameOfNetwork] || null;
  };

  const getAllowedWithdrawalCoinListByNetwork = (nameOfNetwork) => {
    // Available networks
    // * ethereum
    // * polygon
    // * scroll
    // * optimism

    if (!nameOfNetwork) {
      console.log('Pass arg for `getAllowedWithdrawalCoinListByNetwork`');
    }

    let allowedPolygonList =
      details?.payload?.network_config?.POLYGON?.allowed_tokens_for_fast_wd;
    let allowedScrollList =
      details?.payload?.network_config?.SCROLL?.allowed_tokens_for_fast_wd;
    let allowedEthereumList = coinsDetails?.convertedArray.map(
      (item) => item.symbol
    );
    let allowedOptimismList =
      details?.payload?.network_config?.OPTIMISM?.allowed_tokens_for_fast_wd;

    let coinDataObj = {
      scroll: allowedScrollList,
      ethereum: allowedEthereumList,
      polygon: allowedPolygonList,
      optimism: allowedOptimismList,
    };

    return coinDataObj[nameOfNetwork] || null;
  };

  const signEVMTransaction = async (txData) => {
    let tokenAddress = txData?.tokenAddress;
    let recipientAddress = txData?.to;

    const ethBalance = await library.getBalance(account);
    let balanceOnNativeCoin = ethers.utils.formatEther(ethBalance);

    if (balanceOnNativeCoin == 0) {
      throw Error(
        `Your Ethereum balance is insufficient to process the request`
      );
    }

    try {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ['function transfer(address to, uint256 amount)'],
        signer
      );

      const abi = new ethers.utils.Interface(erc_20_abi);
      const decodedData = abi.decodeFunctionData(
        'transfer(address,uint256)',
        txData?.data
      );

      // console.log('To:', decodedData._to);
      // console.log('Value:', decodedData._value.toString());

      // Encode the transfer function data
      const data = tokenContract.interface.encodeFunctionData('transfer', [
        decodedData?._to,
        decodedData._value.toString(),
      ]);

      // Create an Ethereum transaction
      const transaction = {
        to: recipientAddress,
        data: data,
      };

      // Send the transaction
      const txResponse = await signer.sendTransaction(transaction);
      // console.log('Transaction sent. Transaction hash:', txResponse.hash);
      txResponse['transaction_hash'] = txResponse.hash;
      return txResponse;
    } catch (error) {
      throw error;
    }
  };

  let networkConfig = {
    polygon: {
      chainId: ALLOWED_POLYGON_NETWORK_ID,
      depositAbi: polygon_abi,
      erc20Abi: erc_20_abi,
      contractAddress: getCrossChainDepositContractAddress('polygon'),
      nativeCurrency: 'matic',
      nativeSymbol: 'matic',
      rpcUrl:
        ENVIRONMENT !== 'testnet'
          ? 'https://polygon-mainnet.g.alchemy.com/v2/R5-9Oy4phjw1P2jdxZ_g9P1xlOr6F1RE'
          : 'https://polygon-mumbai.g.alchemy.com/v2/R5-9Oy4phjw1P2jdxZ_g9P1xlOr6F1RE',
      blockExplorerUrl: polygonscanDomain,
      image: images.polygon,
      uiOption: getNetworkOptionByName('polygon'),
    },
    ethereum: {
      chainId: ALLOWED_NETWORK_ID,
      depositAbi: stark_abi,
      erc20Abi: erc_20_abi,
      contractAddress: STARK_CONTRACT,
      nativeCurrency: 'ethereum',
      nativeSymbol: 'eth',
      scan: 'Etherscan',
      rpcUrl:
        ENVIRONMENT === 'testnet'
          ? 'https://goerli.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6'
          : 'https://mainnet.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6',
      blockExplorerUrl: etherscanDomain,
      image: images.ethereum,
      uiOption: getNetworkOptionByName('ethereum'),
    },
    starknet: {
      chainId: ALLOWED_STARKNET_NETWORK_ID,
      depositAbi: '',
      erc20Abi: '',
      contractAddress: '',
      nativeCurrency: '',
      nativeSymbol: '',
      scan: 'Starkscan',
      rpcUrl: '',
      blockExplorerUrl: starkscanDomain,
      image: images.starkWareLogo,
      uiOption: getNetworkOptionByName('starknet'),
    },
    scroll: {
      chainId: ALLOWED_SCROLL_NETWORK_ID,
      depositAbi: '',
      erc20Abi: '',
      contractAddress: '',
      nativeCurrency: '',
      nativeSymbol: '',
      scan: 'Scrollscan',
      rpcUrl: '',
      blockExplorerUrl: scrollscanDomain,
      image: images.scrollLogo,
      uiOption: getNetworkOptionByName('scroll'),
    },
    optimism: {
      chainId: ALLOWED_OPTIMISM_NETWORK_ID,
      depositAbi: polygon_abi,
      erc20Abi: erc_20_abi,
      contractAddress: getCrossChainDepositContractAddress('optimism'),
      nativeCurrency: 'ethereum',
      nativeSymbol: 'eth',
      rpcUrl:
        ENVIRONMENT !== 'testnet'
          ? 'https://optimism-goerli.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6'
          : 'https://optimism-mainnet.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6',
      blockExplorerUrl: optimismscanDomain,
      image: images.optimismLogo,
      uiOption: getNetworkOptionByName('optimism'),
    },
  };

  return {
    provider,
    contract_final,
    getBalanceWithdraw,
    // changeNetwork,
    startDeposit,
    getBalance,
    getAllowance,
    approveUnlimitedAllowance,
    startWithdrawal,
    getSingleCoinAllowance,
    getGasFee,
    getCurrentBlock,
    getTransactionByHash,
    getGasFeev2,
    capitalize,
    waitAddon,
    walletDeactivate,
    signer,
    switchChain,
    addNetwork,
    startBrineCrossChainDeposit,
    getBrineCrossChainGasFeeV2,
    starkContract: stark_contract_final,
    // polygonContract: polygon_contract_final,
    getChainId,
    networkConfig,
    getPopulatedData,
    // getSingleCoinAllowancev2,
    getFastWithdrawalGasFee,
    getBalanceV2,
    dequantize,
    getNetworkOptionByName,
    getCoinListByNetworkName,
    getAllowedDepositCoinListByNetwork,
    signEVMTransaction,
    getAllowedWithdrawalCoinListByNetwork,
  };
};
