import React from 'react';
import { Link } from 'react-router-dom';

export function WithdrawalGuide({ stage = 'stage-4', activeAll = true }) {
  return (
    <div className={`register-stepper ${activeAll ? 'active_all' : ''} `}>
      <div
        className={`step ${stage === 'stage-2' ? 'editing' : ''} ${
          stage === 'stage-3' ? 'done' : ''
        }  ${activeAll ? 'editing' : stage === 'stage-4' ? 'done' : ''}`}
      >
        <div className='step-circle'>
          <span>1</span>
        </div>
        <div className='step-title h6'>
          Initiate your Withdrawal request in the{' '}
          <Link
            to={{
              pathname: '/wallet',
              search: '?tab=withdrawal',
            }}
          >
            <span style={{ color: '#8173E0', textDecoration: 'underline' }}>
              Wallet tab
            </span>
          </Link>{' '}
          .
        </div>
        {/* <div
            style={{ fontSize: 10, textAlign: 'center', fontFamily: 'Poppins' }}
          >
            Initiate your Withdrawal request in the Wallet tab.
          </div> */}
        <div className='step-bar-left'></div>
        <div className='step-bar-right'></div>
      </div>
      <div
        className={`step ${stage === 'stage-3' ? 'editing' : ''} ${
          activeAll ? 'editing' : stage === 'stage-4' ? 'done' : ''
        }`}
      >
        <div className='step-circle'>
          <span>2</span>
        </div>
        <div className='step-title h6'>
          WAIT for upto <span style={{ color: '#8173E0' }}>24 hours</span>.
        </div>
        {/* <div
            style={{ fontSize: 10, textAlign: 'center', fontFamily: 'Poppins' }}
          >
            Document Verfication unloack all Krypto feature !
          </div> */}
        <div className='step-bar-left'></div>
        <div className='step-bar-right'></div>
      </div>
      <div
        className={`step ${
          activeAll ? 'editing' : stage === 'stage-4' ? 'editing' : ''
        } 
        `}
      >
        <div className='step-circle'>
          <span>3</span>
        </div>
        <div className='step-title h6'>
          {' '}
          The status for your Withdrawal request is now{' '}
          <span style={{ color: '#4fbf67' }}>READY</span> and will show up
          <span style={{ color: '#8173E0' }}> below</span>.
        </div>
        {/* <div
            style={{ fontSize: 10, textAlign: 'center', fontFamily: 'Poppins' }}
          ></div> */}
        <div className='step-bar-left'></div>
        <div className='step-bar-right'></div>
      </div>
      <div
        className={`step ${
          activeAll ? 'editing' : stage === 'stage-4' ? 'editing' : ''
        } 
        `}
      >
        <div className='step-circle'>
          <span>4</span>
        </div>
        <div className='step-title h6'>
          {' '}
          Click on <span style={{ color: '#8173E0' }}>Withdraw</span> below to
          complete the Withdrawal transaction.
        </div>
        {/* <div
            style={{ fontSize: 10, textAlign: 'center', fontFamily: 'Poppins' }}
          ></div> */}
        <div className='step-bar-left'></div>
        <div className='step-bar-right'></div>
      </div>
    </div>
  );
}
