import CustomButton from '../custom-button/custom-button';
import { images } from '../../../utils/image/img';
import Image from '../img/image';

export default function ServerDown({
  title = "Oops, there's a problem with the connection.",
  subtitle = '',
}) {
  return (
    <div className='server__down'>
      <div className='h2'>{title}</div>
      <Image src={images.serverDown} />
      <p>
        Kindly check if you're not using a VPN/proxy and you're connected to the
        internet, incase you're then we're sorry we are down for maintanence and
        will be back soon.
      </p>
      <CustomButton
        title='Try again'
        onClick={() => window.location.reload()}
      />
    </div>
  );
}
