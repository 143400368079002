import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toggleSetting } from '../../../actions/settingAction';
import {
  Icon,
  NavItem,
  NavSearch,
  SendMail,
  Tooltip,
  Image,
  CustomButton,
} from '../../../components';
import { useViewport } from '../../../utils/hooks';
import { toggleNavbar } from '../../../actions/navbarAction';
import { images } from '../../../utils/image/img';

function NavContainer({ routes, location, isExchangePage }) {
  const dispatch = useDispatch();
  const { width } = useViewport();
  const breakpoint = 1000;
  // const {
  // navbar: { open },
  // userLogin: { userInfo },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { open } = useSelector((state) => state.navbar);

  return (
    <div className='sidebar__body'>
      <nav className='sidebar__nav'>
        {routes.map((route, index) => {
          return (
            <NavItem
              route={route}
              key={index}
              isExchangePage={isExchangePage}
              active={
                route.path.includes('exchange')
                  ? location.pathname.includes('exchange')
                    ? true
                    : false
                  : location.pathname === route.path
              }
            />
          );
        })}
        {userInfo?.token && (
          <div
            className='sidebar__item'
            style={{ fontSize: 14, cursor: 'pointer' }}
            onClick={() => dispatch(toggleSetting())}
            data-tip='Settings'
          >
            <div className='sidebar__icon'>
              <Icon icon='settings' className='icon' />
            </div>
            {(!open || isExchangePage) && <Tooltip />}
            <div className='sidebar__text'>Settings</div>
          </div>
        )}

        <div className='mobile-show'>
          <Link
            className='sidebar__item'
            style={{ fontSize: 14 }}
            to={(location) => ({ ...location, pathname: '/help' })}
          >
            <div className='sidebar__icon'>
              <Image src={images.help} alt='' style={{ width: 20 }} />
            </div>
            <div className='sidebar__text'>Help</div>
          </Link>
        </div>
      </nav>
      <SendMail />
      <NavSearch />
    </div>
  );
}

export default withRouter(NavContainer);
