import axios from 'axios';
import { baseUrl } from '../utils/urls';
import * as saltConstants from '../constants/saltConstant';

// export const getSaltScore = async (token) => {
//   try {
//     const config = {
//       headers: {
//         Authorization: `JWT ${token}`,
//       },
//     };
//     const { data } = await axios.post(
//       `${baseUrl}/salt/salt-score/`,
//       {},
//       config
//     );
//     return data;
//   } catch (error) {
//     throw error.response.data.message;
//   }
// };

// export const getSaltLeaderBoard = async () => {
//   try {
//     const config = {
//       headers: {},
//     };
//     const { data } = await axios.get(
//       `${baseUrl}/salt/leaderboard/?top=50`,
//       {},
//       config
//     );
//     return data;
//   } catch (error) {
//     throw error.response.data.message;
//   }
// };

// export const getSaltScore = () => async (dispatch, getState) => {
//   try {
//     const {
//       userLogin: { userInfo },
//     } = getState();

//     const config = {
//       headers: {
//         Authorization: `JWT ${userInfo.token.access}`,
//       },
//     };

//     dispatch({
//       type: saltConstants.GET_SALT_SCORE_REQUEST,
//     });
//     const { data } = await axios.post(
//       `${baseUrl.replace('/main', '')}/salt/salt-score/`,
//       {},
//       config
//     );
//     //@todo want to connect API
//     dispatch({
//       type: saltConstants.GET_SALT_SCORE_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: saltConstants.GET_SALT_SCORE_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

export const getSaltScore = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl.replace('/main', '')}/salt/salt-score/`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const getSaltLeaderBoard = () => async (dispatch) => {
  try {
    const config = {
      headers: {},
    };

    dispatch({
      type: saltConstants.GET_SALT_LEADERBOARD_REQUEST,
    });

    const { data } = await axios.get(
      `${baseUrl.replace('/main', '')}/salt/leaderboard/?top=50`,
      config
    );

    dispatch({
      type: saltConstants.GET_SALT_LEADERBOARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: saltConstants.GET_SALT_LEADERBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSaltScoreFlag = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: saltConstants.GET_SALT_SCORE_FLAG_REQUEST,
    });

    const { data } = await axios.get(
      `${baseUrl.replace('/main', '')}/salt/user-info/`,
      config
    );

    dispatch({
      type: saltConstants.GET_SALT_SCORE_FLAG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: saltConstants.GET_SALT_SCORE_FLAG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
