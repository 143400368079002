import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getBalance } from '../../../../actions/userAction';
import { CANCEL_ORDER_CLEANUP } from '../../../../constants/exchangeConstant';
import { useViewport, useBalance } from '../../../../utils/hooks';
import { useParams } from 'react-router-dom';
import { LoadingBtn, CustomButton } from '../../../../components';
import CancelConfirmationPopup from '../../../exchange/cancel-confirmation-popup/cancel-confirmation-popup';
import ReactTooltip from 'react-tooltip';

function CancelOrderButton({
  setCancelId,
  data,
  setOpenCancelPopup,
  isAllOrderTab,
  tempId,
}) {
  const { isdark } = useSelector((state) => state.theme);
  return (
    <>
      {isAllOrderTab ? (
        <>
          {tempId.includes(data.id) ? (
            <div className='market__cell'>
              <span className='more__btn btn'>
                <div className='loader'></div>
              </span>
            </div>
          ) : (
            <div
              className='market__cell'
              onClick={() => {
                setCancelId(data.id);
                setOpenCancelPopup(true);
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  padding: '7px 4px',
                  fontSize: 10,
                  borderRadius: 5,
                  fontWeight: 600,
                }}
                className='btn_red'
                data-tip={'Click here to cancel the order'}
                data-for='cancel_order'
              >
                CANCEL
                <ReactTooltip
                  place='bottom'
                  id='cancel_order'
                  type={isdark ? 'light' : 'dark'}
                  className='tooltip_styles max-withdrawal'
                />
              </span>
            </div>
          )}
        </>
      ) : (
        <div className='market__cell'>
          <button
            className='btn btn_orange btn_red'
            style={{
              width: 40,
              height: 40,
              margin: 5,
            }}
            onClick={() => {
              setCancelId(data.id);
              setOpenCancelPopup(true);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
}

function ExchangeMarketTable({
  selectedlabel,
  orderPendingPageCount,
  allOrders,
  trade,
  pendingOrder,
  handleCancelOrder,
  tradeMode,
  btnDisable,
  handleLoadMore,
  tempId,
  orderItem,
}) {
  const { width } = useViewport();
  const { formatCurrency } = useBalance();
  // const [orderItem, setOrderItem] = useState(null);
  const { symbol } = useParams();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const [elementHovered, setElementHovered] = useState({
    state: false,
    id: null,
  });

  let toggleHover = (data, mouse_enter = true) => {
    setElementHovered({
      id: mouse_enter ? data.id : null,
      state: mouse_enter ? true : false,
    });
  };

  // const {
  // orderData: {
  //   loading: orderLoading,
  //   response: orderResponse,
  //   error: orderError,
  // },
  // tradeData: {
  //   loading: tradeLoading,
  //   response: tradeResponse,
  //   error: tradeError,
  // },
  // cancelOrder: {
  //   response: cancelOrderResponse,
  //   loading: cancelOrderLoading,
  //   error: cancelOrderError,
  // },
  // theme: { isdark },
  // } = useSelector((state) => state);

  const {
    loading: orderLoading,
    response: orderResponse,
    error: orderError,
  } = useSelector((state) => state.orderData);

  const {
    loading: tradeLoading,
    response: tradeResponse,
    error: tradeError,
  } = useSelector((state) => state.tradeData);

  const {
    response: cancelOrderResponse,
    loading: cancelOrderLoading,
    error: cancelOrderError,
  } = useSelector((state) => state.cancelOrder);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cancelOrderError) {
      toast.error(cancelOrderError);
    }
    if (cancelOrderResponse) {
      toast.success(cancelOrderResponse.message);
    }
  }, [cancelOrderLoading, cancelOrderResponse, cancelOrderError]);

  useEffect(() => {
    return () => {
      dispatch({
        type: CANCEL_ORDER_CLEANUP,
        payload: null,
      });
      setCancelId(null);
      setOpenCancelPopup(false);
    };
  }, []);

  useEffect(() => {
    if (cancelOrderResponse) {
      // dispatch(
      //   getOrderData(orderPendingPageCount, true, symbol?.replace('-', ''))
      // );
      setTimeout(() => {
        dispatch(getBalance());
      }, 2000);
      setCancelId(null);
      setOpenCancelPopup(false);
    }
  }, [cancelOrderResponse]);

  return (
    <>
      {selectedlabel.value !== 'trades' ? (
        <>
          {selectedlabel.value === 'pending_orders' ? (
            <div className='table_height_control'>
              <div className='market__table'>
                <div className='market__row market__row_head'>
                  <div className='market__cell'>
                    Market
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>
                  <div className={`market__cell`}>time</div>
                  <div
                    className={`market__cell ${
                      selectedlabel.value === 'pending_orders'
                        ? 'mobile-hide'
                        : ''
                    }`}
                  >
                    price
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>
                  <div className='market__cell'>
                    amount
                    {/* <span className="mobile-hide">(btc)</span> */}
                  </div>
                  <div className='market__cell'>
                    side
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>
                  <div
                    className={`market__cell ${
                      selectedlabel.value === 'pending_orders'
                        ? 'mobile-hide'
                        : ''
                    } ${width < 750 ? 'mobile-hide' : ''}`}
                  >
                    status
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>

                  {selectedlabel.value === 'pending_orders' && (
                    <div className='market__cell'>
                      cancel Order
                      {/* <span className="mobile-hide">(usdt)</span> */}
                    </div>
                  )}
                </div>
                {pendingOrder?.map((data, idx) => {
                  return (
                    <div
                      className='market__row'
                      key={idx}
                      onMouseEnter={() => toggleHover(data, true)}
                      onMouseLeave={() => toggleHover(data, false)}
                    >
                      <div className='market__cell'>
                        {data.market?.toUpperCase()}
                      </div>
                      {data?.is_ws_data ? (
                        <div className='market__cell'>
                          {moment.unix(data.updated_at).format('llll')}
                        </div>
                      ) : (
                        <div className={`market__cell`}>
                          {width > 750
                            ? moment(data.updated_at).format('llll')
                            : moment(data.updated_at).format('LL')}
                        </div>
                      )}
                      <div className={`market__cell `}>
                        {data.price
                          ? formatCurrency(parseFloat(data.price))
                          : Number(data?.avg_price)
                          ? formatCurrency(
                              parseFloat(data.avg_price)?.toFixed(2)
                            )
                          : '--'}
                      </div>
                      <div
                        className={`market__cell ${
                          selectedlabel.value === 'pending_orders'
                            ? 'mobile-hide'
                            : ''
                        }`}
                      >
                        {data.origin_volume}
                      </div>
                      <div
                        className={`market__cell ${
                          data.side === 'buy' ? 'positive' : 'negative'
                        }`}
                      >
                        {data?.side?.toUpperCase()}
                      </div>
                      {elementHovered.state &&
                      elementHovered.id === data.id &&
                      selectedlabel.value !== 'pending_orders' &&
                      data.state === 'wait' ? (
                        <>
                          <CancelOrderButton
                            setCancelId={setCancelId}
                            tempId={tempId}
                            data={data}
                            isAllOrderTab={true}
                            setOpenCancelPopup={setOpenCancelPopup}
                          />
                        </>
                      ) : (
                        <div
                          className={`market__cell ${
                            data.state === 'done' ? 'positive' : 'negative'
                          } ${
                            selectedlabel.value === 'pending_orders'
                              ? 'mobile-hide'
                              : ''
                          } ${width < 750 ? 'mobile-hide' : ''}`}
                        >
                          {data.state?.toUpperCase()}
                        </div>
                      )}
                      {selectedlabel.value === 'pending_orders' && (
                        <>
                          {tempId.includes(data.id) ? (
                            <div
                              className='market__cell'
                              style={{ margin: 5, padding: 5 }}
                            >
                              <LoadingBtn addClassName='btn_red' />
                            </div>
                          ) : (
                            <CancelOrderButton
                              tempId={tempId}
                              setCancelId={setCancelId}
                              data={data}
                              setOpenCancelPopup={setOpenCancelPopup}
                            />
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {tradeMode && (
                <div
                  style={{ display: 'grid', placeItems: 'center', padding: 10 }}
                >
                  {tradeLoading || orderLoading ? (
                    <div
                      className='market__cell'
                      style={{ margin: 5, padding: 5 }}
                    >
                      <LoadingBtn />
                    </div>
                  ) : (
                    <div>
                      {!orderLoading &&
                        !tradeLoading &&
                        ((selectedlabel.value === 'pending_orders' &&
                          pendingOrder?.length >= 10) ||
                          (selectedlabel.value === 'all_orders' &&
                            allOrders?.length >= 10) ||
                          (selectedlabel.value === 'trades' &&
                            trade?.length >= 10)) && (
                          <>
                            {!btnDisable && (
                              <CustomButton
                                title='Load More'
                                onClick={handleLoadMore}
                              />
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className='table_height_control'>
              <div className='market__table'>
                <div className='market__row market__row_head'>
                  <div className='market__cell'>
                    Market
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>
                  <div className={`market__cell`}>time</div>
                  <div
                    className={`market__cell ${
                      selectedlabel.value === 'pending_orders'
                        ? 'mobile-hide'
                        : ''
                    }`}
                  >
                    price
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>
                  <div className='market__cell'>
                    amount
                    {/* <span className="mobile-hide">(btc)</span> */}
                  </div>
                  <div className='market__cell'>
                    side
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>
                  <div
                    className={`market__cell ${
                      selectedlabel.value === 'pending_orders'
                        ? 'mobile-hide'
                        : ''
                    } ${width < 750 ? 'mobile-hide' : ''}`}
                  >
                    status
                    {/* <span className="mobile-hide">(usdt)</span> */}
                  </div>

                  {selectedlabel.value === 'pending_orders' && (
                    <div className='market__cell'>
                      cancel Order
                      {/* <span className="mobile-hide">(usdt)</span> */}
                    </div>
                  )}
                </div>
                {orderItem?.map((data, idx) => (
                  <div
                    className='market__row'
                    key={idx}
                    onMouseEnter={() => toggleHover(data, true)}
                    onMouseLeave={() => toggleHover(data, false)}
                  >
                    <div className='market__cell'>
                      {data.market?.toUpperCase()}
                    </div>
                    {data?.is_ws_data ? (
                      <div className='market__cell'>
                        {moment.unix(data.updated_at).format('llll')}
                      </div>
                    ) : (
                      <div className={`market__cell`}>
                        {width > 750
                          ? moment(data.updated_at).format('llll')
                          : moment(data.updated_at).format('LL')}
                      </div>
                    )}
                    <div className={`market__cell `}>
                      {data.price
                        ? formatCurrency(parseFloat(data.price))
                        : Number(data?.avg_price)
                        ? formatCurrency(parseFloat(data.avg_price)?.toFixed(2))
                        : '--'}
                    </div>
                    <div
                      className={`market__cell ${
                        selectedlabel.value === 'pending_orders'
                          ? 'mobile-hide'
                          : ''
                      }`}
                    >
                      {data.origin_volume}
                    </div>
                    <div
                      className={`market__cell ${
                        data.side === 'buy' ? 'positive' : 'negative'
                      }`}
                    >
                      {data?.side?.toUpperCase()}
                    </div>
                    {elementHovered.state &&
                    elementHovered.id === data.id &&
                    selectedlabel.value !== 'pending_orders' &&
                    data.state === 'wait' ? (
                      <>
                        <CancelOrderButton
                          setCancelId={setCancelId}
                          tempId={tempId}
                          data={data}
                          isAllOrderTab={true}
                          setOpenCancelPopup={setOpenCancelPopup}
                        />
                      </>
                    ) : (
                      <div
                        className={`market__cell ${
                          data.state === 'done' ? 'positive' : 'negative'
                        } ${
                          selectedlabel.value === 'pending_orders'
                            ? 'mobile-hide'
                            : ''
                        } ${width < 750 ? 'mobile-hide' : ''}`}
                      >
                        {data.state?.toUpperCase()}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {tradeMode && (
                <div
                  style={{ display: 'grid', placeItems: 'center', padding: 10 }}
                >
                  {tradeLoading || orderLoading ? (
                    <div
                      className='market__cell'
                      style={{ margin: 5, padding: 5 }}
                    >
                      <LoadingBtn />
                    </div>
                  ) : (
                    <div>
                      {!orderLoading &&
                        !tradeLoading &&
                        ((selectedlabel.value === 'pending_orders' &&
                          pendingOrder?.length >= 10) ||
                          (selectedlabel.value === 'all_orders' &&
                            allOrders?.length >= 10) ||
                          (selectedlabel.value === 'trades' &&
                            trade?.length >= 10)) && (
                          <>
                            {!btnDisable && (
                              <CustomButton
                                title='Load More'
                                onClick={handleLoadMore}
                              />
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className='table_height_control'>
          <div className='market__table'>
            <div className='market__row market__row_head'>
              <div className='market__cell'>market</div>
              <div className='market__cell'>time</div>
              <div className='market__cell mobile-hide'>
                price
                {/* <span className="mobile-hide">(usdt)</span> */}
              </div>
              <div className='market__cell'>
                amount
                {/* <span className="mobile-hide">(btc)</span> */}
              </div>
              <div className='market__cell'>
                side
                {/* <span className="mobile-hide">(usdt)</span> */}
              </div>
            </div>
            {trade?.map((data, idx) => (
              <div className='market__row' key={idx}>
                <div className='market__cell'>{data.market?.toUpperCase()}</div>
                <div className='market__cell'>
                  {width > 750
                    ? moment(data.created_at).format('llll')
                    : moment(data.created_at).format('LL')}
                </div>
                <div className={`market__cell mobile-hide`}>
                  {data.price && formatCurrency(parseFloat(data.price))}
                </div>
                <div className='market__cell '>
                  {data?.market === 'shibinr'
                    ? parseFloat(data.total)?.toFixed(5)
                    : parseFloat(data.total)?.toFixed(2)}
                </div>
                <div
                  className={`market__cell ${
                    data.side === 'buy' ? 'positive' : 'negative'
                  }`}
                >
                  {data?.side?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>
          {tradeMode && (
            <div style={{ display: 'grid', placeItems: 'center', padding: 10 }}>
              {tradeLoading || orderLoading ? (
                <div className='market__cell' style={{ margin: 5, padding: 5 }}>
                  <LoadingBtn />
                </div>
              ) : (
                <div>
                  {!orderLoading &&
                    !tradeLoading &&
                    ((selectedlabel.value === 'pending_orders' &&
                      pendingOrder?.length >= 10) ||
                      (selectedlabel.value === 'all_orders' &&
                        allOrders?.length >= 10) ||
                      (selectedlabel.value === 'trades' &&
                        trade?.length >= 10)) && (
                      <>
                        {!btnDisable && (
                          <CustomButton
                            title='Load More'
                            onClick={handleLoadMore}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className='referral__popup'>
        <CancelConfirmationPopup
          openCancelPopup={openCancelPopup}
          setOpenCancelPopup={setOpenCancelPopup}
          setCancelId={setCancelId}
          cancelId={cancelId}
          handleCancelOrder={handleCancelOrder}
        />
      </div>
    </>
  );
}

export default ExchangeMarketTable;
