/* global BigInt */
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { baseCurrency } from '../constants';
import useWebSocketData from './useWebSocketData';
import Decimal from 'decimal.js';
import { getDecimalJsInstance } from '../decimal';

function useBalance() {
  const { getAssests, userGetBalance, getPortfolio } = useSelector(
    (state) => state
  );
  const { loading, error, balance } = userGetBalance;
  const { loading: assetLoading, error: assetError, assetsData } = getAssests;
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const {
    loading: getPortfolioLoading,
    error: getPortfolioError,
    response: getPortfolioResponse,
  } = getPortfolio;

  const { lastPrice, getSocketFunctionalityInfo } = useWebSocketData();

  const getInfoAboutCoins = (currency) => {
    if (coinsDetails?.convertedArray) {
      const { convertedArray } = coinsDetails;
      const data = convertedArray.find((data) => data.symbol === currency);
      return data;
    }
  };

  const getFastWithdrawalFee = (currency) => {
    if (coinsDetails?.convertedArray) {
      const { convertedArray } = coinsDetails;
      const data = convertedArray.find((data) => data.symbol === currency);
      return data?.fast_withdrawal_fee || 0;
    }
  };

  const getInfoAboutCoinsByTokenId = (id) => {
    if (coinsDetails?.convertedArray) {
      const { convertedArray } = coinsDetails;
      const data = convertedArray.find((data) => data.stark_asset_id === id);
      return data;
    }
  };

  const roundUp = (number) => {
    var rounded = Math.round((number + Number.EPSILON) * 100) / 100;
    return rounded;
  };

  function truncateToDecimalPlaces(x, n) {
    return Number(x).toFixed(n);
  }

  function removeTrailingZeros(number) {
    const decimalNumber = getDecimalJsInstance(number);
    const formattedNumber = decimalNumber
      .toString()
      .replace(/(\.\d*?[1-9])0+$/, '$1');
    return formattedNumber;
  }

  // const roundUpWithSymbol = (number, symbol) => {
  //   if (coinsDetails?.convertedArray && number && symbol) {
  //     const { convertedArray } = coinsDetails;
  //     const info = convertedArray.find((item) => item.symbol === symbol);
  //     if (info) {
  //       let tempValue = Number(preventScientific(number));
  //       let rounded = toFixedTrunc(tempValue, parseInt(info?.quanitization));
  //       // .toFixed(
  //       //   parseInt(info?.quanitization)
  //       // );
  //       // let rounded = tempValue.toFixed(parseInt(info?.quanitization));
  //       return preventScientific(removeTrailingZeros(Number(rounded)));
  //     } else {
  //       return preventScientific(removeTrailingZeros(Number(number)));
  //     }
  //   } else {
  //     return preventScientific(removeTrailingZeros(Number(number)));
  //   }
  // };

  const roundUpWithSymbol = (number, symbol) => {
    if (coinsDetails?.convertedArray && number && symbol) {
      const { convertedArray } = coinsDetails;
      const info = convertedArray.find((item) => item.symbol === symbol);
      if (info) {
        const tempValue = getDecimalJsInstance(number);
        const quanitization = getDecimalJsInstance(info?.quanitization);
        if (quanitization.isInteger()) {
          const rounded = tempValue.toDecimalPlaces(
            quanitization.toNumber(),
            Decimal.ROUND_DOWN
          );
          return preventScientific(removeTrailingZeros(rounded.toString()));
        } else {
          const rounded = tempValue.toExponential();
          return preventScientific(removeTrailingZeros(rounded));
        }
      } else {
        return preventScientific(
          removeTrailingZeros(Number(number).toString())
        );
      }
    } else {
      return preventScientific(removeTrailingZeros(Number(number).toString()));
    }
  };

  // function getCurrencyBalances() {
  //   let values = 0;
  //   if (!loading && !error) {
  //     balance?.payload.forEach((data) => {
  //       if (
  //         getSocketFunctionalityInfo()?.full ||
  //         getSocketFunctionalityInfo()?.partial
  //       ) {
  //         let str = data.currency + baseCurrency;
  //         let totalBalance = Number(data?.balance);
  //         if (
  //           str === 'usdtusdc' ||
  //           str === 'usdcusdc' ||
  //           str === 'usdcusdt' ||
  //           str === 'usdcusdc'
  //         ) {
  //           values = totalBalance + values;
  //         } else {
  //           console.log({ totalBalance });
  //           let value = parseFloat(lastPrice(str)) * totalBalance;
  //           console.log(totalBalance, '*', lastPrice(str), '=', value, 'test');
  //           console.log({ value }, 'total');
  //           values = values + parseFloat(value);
  //         }
  //       }
  //     });
  //   }
  //   console.log({ total: roundUpWithSymbol(values, 'usdc') });
  //   console.log({ values }, 'ssssss');
  //   return roundUpWithSymbol(values, 'usdc');
  // }

  function getCurrencyBalances(includeLockedBalance = false) {
    let values = getDecimalJsInstance(0); // Use Decimal for precision
    if (!loading && !error) {
      balance?.payload.forEach((data) => {
        if (
          getSocketFunctionalityInfo()?.full ||
          getSocketFunctionalityInfo()?.partial
        ) {
          let str = data.currency + baseCurrency;
          let totalBalance;

          if (includeLockedBalance) {
            totalBalance = getDecimalJsInstance(data?.balance).plus(
              data?.locked
            );
          } else {
            totalBalance = getDecimalJsInstance(data?.balance);
          }

          if (
            str === 'usdtusdc' ||
            str === 'usdcusdc' ||
            str === 'usdcusdt' ||
            str === 'usdcusdc'
          ) {
            values = values.plus(totalBalance);
          } else {
            let value = getDecimalJsInstance(lastPrice(str)).times(
              totalBalance
            );
            values = values.plus(value);
          }
        }
      });
    }
    return roundUpWithSymbol(values.toString(), 'usdc');
  }

  function getTotalLockedBalances() {
    let totalLockedBalance = 0;
    if (!loading && !error) {
      balance?.payload.forEach((data) => {
        if (
          getSocketFunctionalityInfo()?.full ||
          getSocketFunctionalityInfo()?.partial
        ) {
          let str = data.currency + baseCurrency;
          let totalBalance = Number(data?.locked);
          if (
            str === 'usdtusdc' ||
            str === 'usdcusdc' ||
            str === 'usdcusdt' ||
            str === 'usdcusdc'
          ) {
            totalLockedBalance = totalBalance + totalLockedBalance;
          } else {
            let value = parseFloat(lastPrice(str)) * totalBalance;
            totalLockedBalance = totalLockedBalance + parseFloat(value);
          }
        }
      });
    }
    return roundUpWithSymbol(totalLockedBalance, 'usdc');
  }

  const formatCurrency = (num) => {
    if (num) {
      // var x = num;
      // x = x.toString();
      // var afterPoint = '';
      // if (x.indexOf('.') > 0)
      //   afterPoint = x.substring(x.indexOf('.'), x.length);
      // x = Math.floor(x);
      // x = x.toString();
      // var lastThree = x.substring(x.length - 3);
      // var otherNumbers = x.substring(0, x.length - 3);
      // if (otherNumbers != '') lastThree = ',' + lastThree;
      // var res =
      //   otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      //   lastThree +
      //   afterPoint;

      // return res;
      return Number(num)?.toLocaleString('en-US');
    }
  };

  // const getBalanceToUSDC = (data) => {
  //   if (
  //     getSocketFunctionalityInfo().full ||
  //     getSocketFunctionalityInfo().partial
  //   ) {
  //     let str = data.currency + baseCurrency;
  //     let value = parseFloat(lastPrice(str)) * parseFloat(data.balance);
  //     return data.currency === 'usdc' ? parseFloat(data.balance) : value;
  //   }
  // };

  const getBalanceToUSDC = (data) => {
    if (
      getSocketFunctionalityInfo().full ||
      getSocketFunctionalityInfo().partial
    ) {
      let str = data.currency + baseCurrency;

      // Use Decimal for precise calculations
      let value = getDecimalJsInstance(lastPrice(str)).times(
        getDecimalJsInstance(data.balance)
      );

      return data.currency === 'usdc'
        ? getDecimalJsInstance(data.balance)
        : value.toNumber();
    }
  };

  const getBalanceDataBySymbol = (symbol) => {
    if (balance) {
      let filterRes = balance?.payload?.find(
        (item) => item?.currency === symbol
      );
      return filterRes;
    }
  };

  // function getChartData() {
  //   const chartData = [];
  //   balance?.payload.forEach((data) => {
  //     if (
  //       getSocketFunctionalityInfo().full ||
  //       getSocketFunctionalityInfo().partial
  //     ) {
  //       let str = data.currency + baseCurrency;
  //       if (
  //         str === 'usdtusdc' ||
  //         str === 'usdcusdc' ||
  //         str === 'usdcusdt' ||
  //         str === 'usdcusdc'
  //       ) {
  //         chartData.push({ currency: data.currency, balance: data.balance });
  //       } else {
  //         let value = lastPrice(str) * parseFloat(data.balance);
  //         chartData.push({ currency: data.currency, balance: value });
  //       }
  //     }
  //   });
  //   const chartValue = _.orderBy(
  //     chartData,
  //     function (o) {
  //       return o?.balance;
  //     },
  //     'desc'
  //   );
  //   return chartValue;
  // }

  function getChartData() {
    const chartData = [];
    balance?.payload.forEach((data) => {
      if (
        getSocketFunctionalityInfo().full ||
        getSocketFunctionalityInfo().partial
      ) {
        let str = data.currency + baseCurrency;
        if (
          str === 'usdtusdc' ||
          str === 'usdcusdc' ||
          str === 'usdcusdt' ||
          str === 'usdcusdc'
        ) {
          chartData.push({
            currency: data.currency,
            balance: getDecimalJsInstance(data.balance).toNumber(),
          });
        } else {
          let value = getDecimalJsInstance(lastPrice(str)).times(
            getDecimalJsInstance(data.balance)
          );
          chartData.push({
            currency: data.currency,
            balance: value.toNumber(),
          });
        }
      }
    });
    const chartValue = _.orderBy(
      chartData,
      function (o) {
        return o?.balance;
      },
      'desc'
    );
    return chartValue;
  }

  // function getInvestedPrice() {
  //   let invested = 0;
  //   if (!getPortfolioLoading && !getPortfolioError && !loading && !error) {
  //     getPortfolioResponse?.payload.forEach((item) => {
  //       balance.payload.forEach((balanceRes) => {
  //         if (`${balanceRes.currency}` === item.currency) {
  //           invested =
  //             parseFloat(item.average_buy_price) *
  //               parseFloat(balanceRes.balance) +
  //             invested;
  //         }
  //       });
  //     });
  //     return invested || 0;
  //   }
  // }

  function getInvestedPrice() {
    let invested = getDecimalJsInstance(0);
    if (!getPortfolioLoading && !getPortfolioError && !loading && !error) {
      getPortfolioResponse?.payload.forEach((item) => {
        balance.payload.forEach((balanceRes) => {
          if (balanceRes.currency === item.currency) {
            invested = invested.plus(
              getDecimalJsInstance(item.average_buy_price).times(
                getDecimalJsInstance(balanceRes.balance)
              )
            );
          }
        });
      });
      return invested.toNumber() ? invested?.toString() : 0;
    }
  }

  // function getInvestedSingleCoin(symbol) {
  //   let invested = 0;
  //   let res = getBalanceDataBySymbol(symbol);
  //   if (!getPortfolioLoading && !getPortfolioError && !loading && !error) {
  //     getPortfolioResponse?.payload.forEach((item) => {
  //       if (symbol === item.currency) {
  //         invested =
  //           parseFloat(item.average_buy_price) * parseFloat(res?.balance);
  //       }
  //     });
  //     return invested || 0;
  //   }
  // }

  function getInvestedSingleCoin(symbol) {
    let invested = getDecimalJsInstance(0);
    let res = getBalanceDataBySymbol(symbol);

    if (!getPortfolioLoading && !getPortfolioError && !loading && !error) {
      getPortfolioResponse?.payload.forEach((item) => {
        if (symbol === item.currency) {
          const averageBuyPriceDecimal = getDecimalJsInstance(
            item.average_buy_price
          );
          const balanceDecimal = getDecimalJsInstance(res?.balance);
          // Calculate the invested amount with Decimal.js
          invested = averageBuyPriceDecimal.times(balanceDecimal);
        }
      });

      // Ensure the invested amount is not NaN
      if (invested.isNaN()) {
        invested = getDecimalJsInstance(0);
      }
      return invested.toString();
    }
    return '0'; // Return '0' as a string if the conditions are not met
  }

  function getSingleCoinsBalanceInUSDC(symbol) {
    let value = 0;
    if (
      (getSocketFunctionalityInfo()?.full ||
        getSocketFunctionalityInfo()?.partial) &&
      balance
    ) {
      balance.payload.forEach((balance) => {
        if (balance.currency === symbol) {
          if (symbol === 'usdt' || symbol === 'usdc') {
            value = parseFloat(balance.balance);
          } else {
            const price = lastPrice(symbol + baseCurrency);
            value = parseFloat(balance.balance) * parseFloat(price);
          }
        }
      });
      return value > 0 ? formatCurrency(value?.toFixed(3)) : '0.00';
    }
  }

  function getUSDCinBalance(symbol) {
    let s = symbol || 'usdc';
    if (!loading && !error) {
      const usdtBalance = balance?.payload?.find((item) => item.currency === s);
      if (usdtBalance) {
        return Number(usdtBalance?.balance) || 0;
      } else {
        return 0.0;
      }
    }
  }

  function isDecimal(num) {
    return !!(num % 1);
  }

  // function toFixedTrunc(x, n) {
  //   // if (x && n) {
  //   //   if (isDecimal(x)) {
  //   //     const v = (typeof x === 'string' ? x : x?.toString())?.split('.');
  //   //     if (n <= 0) return v[0];
  //   //     let f = v ? v[1] : '';
  //   //     if (f?.length > n) return `${v[0]}.${f?.substr(0, n)}`;
  //   //     while (f?.length < n) f += '0';
  //   //     return `${v[0]}.${f}`;
  //   //   } else {
  //   //     return x;
  //   //   }
  //   // } else {
  //   //   return 0;
  //   // }
  //   const factor = 10 ** n;
  //   return Math.trunc(x * factor) / factor;
  // }

  function toFixedTrunc(x, n) {
    if (x === 0) {
      return Number(x).toFixed(n);
    }
    if (x && n) {
      // Create Decimal objects for x and the factor
      const decimalX = getDecimalJsInstance(x);
      const factor = getDecimalJsInstance(10).pow(n);

      // Multiply x by the factor, truncate, and then divide by the factor
      const result = decimalX.times(factor).trunc().div(factor);

      return result.toString(); // Convert the result back to a string
    }
  }

  function getAvgPriceBalance(symbol) {
    if (!getPortfolioLoading && !getPortfolioError) {
      let isthere = getPortfolioResponse?.payload.find(
        (item) => item.currency === `${symbol}`
      );
      return isthere?.average_buy_price || null;
    }
  }

  function lockedBalance(symbol) {
    let data = balance?.payload?.find((item) => item.currency === symbol);
    if (data) {
      return data?.locked;
    } else {
      return '0.00';
    }
  }

  function preventScientific(num) {
    return ('' + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }

  function getSingleCoinsBalance(symbol) {
    let value = 0;
    let decimal = getInfoAboutCoins(symbol)?.quanitization;

    if (
      (getSocketFunctionalityInfo().full ||
        getSocketFunctionalityInfo().partial) &&
      balance
    ) {
      balance.payload.forEach((balance) => {
        if (balance.currency === symbol) {
          value = balance.balance;
        }
      });
      return value > 0 ? toFixedTrunc(value, decimal) : '0.00';
    }
  }

  return {
    getInvestedSingleCoin,
    getChartData,
    roundUpWithSymbol,
    getBalanceToUSDC,
    getInfoAboutCoins,
    getCurrencyBalances,
    roundUp,
    lockedBalance,
    formatCurrency,
    getSingleCoinsBalanceInUSDC,
    getInvestedPrice,
    toFixedTrunc,
    isDecimal,
    getUSDCinBalance,
    getInfoAboutCoinsByTokenId,
    getAvgPriceBalance,
    getFastWithdrawalFee,
    preventScientific,
    getSingleCoinsBalance,
    removeTrailingZeros,
    getTotalLockedBalances,
    truncateToDecimalPlaces,
  };
}

export default useBalance;
