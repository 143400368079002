import { price_bumper_ask } from './helper';
import brineWS from './socketClient';
import axios from 'axios';
import { baseUrl, networkName } from '../../urls';
import moment from 'moment';
import { objToParams, convertedTimeStamp } from '../../query-helper';

export default class brineAPI {
  constructor(options) {
    this.debug = options.debug || false;
    this.ws = new brineWS();
  }

  mergeMatchingEntries(data1, data2) {
    const mergedData = {};

    for (const key in data1) {
      if (data1.hasOwnProperty(key)) {
        const entry = data1[key];
        // for finding the symbol of currency
        const currencySell = entry.sell.currency_sell;

        if (data2.hasOwnProperty(currencySell)) {
          const matchingData2Entry = data2[currencySell];
          mergedData[key] = { ...entry, ...matchingData2Entry };
        }
      }
    }

    return mergedData;
  }

  // use markets -> arr
  filterEntriesWithBuyAndSell(data) {
    const filteredData = {};
    data['markets']?.forEach((market) => {
      filteredData[market] = data[market];
    });
    return filteredData;
  }

  marketDetails() {
    return fetch(`${baseUrl}` + '/stat/v2/markets/', {
      method: 'POST',
    })
      .then(async (res) => {
        let symbols = await res.json();
        return symbols;
      })
      .then((json) => {
        let formatedData = this.filterEntriesWithBuyAndSell(json?.payload);
        return formatedData;
      });
  }

  async brineSymbols() {
    let data = await this.marketDetails();
    return fetch(`${baseUrl}` + '/stat/v2/coins/', {
      method: 'POST',
    })
      .then(async (res) => {
        let symbols = await res.json();
        return symbols;
      })
      .then((json) => {
        let jsonObject = json?.payload;
        const symbolObject = {};
        for (const symbol in jsonObject) {
          const { symbol: symbolValue, ...rest } = jsonObject[symbol];
          symbolObject[symbolValue] = { ...rest, symbol: symbolValue };
        }
        return this.mergeMatchingEntries(data, symbolObject);
      });
  }

  periodsMapNumber = {
    '1m': 1,
    '5m': 5,
    '15m': 15,
    '30m': 30,
    '1h': 60,
    '2h': 120,
    '4h': 240,
    '6h': 360,
    '12h': 720,
    '1d': 1440,
    '3d': 4320,
    '1w': 10080,
    '1M': 43800,
  };

  async brineKlines(symbol, interval, startTime, endTime, limit) {
    try {
      let params = {
        market: symbol?.toLowerCase(),
        limit: limit,
        period: this.periodsMapNumber[interval],
        start_time: convertedTimeStamp(startTime),
        end_time: convertedTimeStamp(endTime),
      };

      let queryParams = objToParams(params);

      const { data } = await axios.get(
        `${baseUrl}/market/kline/?${queryParams}`
      );

      return data?.payload;
    } catch (error) {
      const errorRes =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      console.log(errorRes);
    }
  }

  // chart specific functions below, impt that their function names stay same
  onReady(callback) {
    this.brineSymbols()
      .then((symbols) => {
        this.symbols = symbols;
        callback({
          supports_marks: false,
          supports_timescale_marks: false,
          supports_time: true,
          supported_resolutions: [
            '1',
            // '3',
            '5',
            '15',
            '30',
            '60',
            '120',
            '240',
            '360',
            // '480',
            '720',
            '1D',
            '3D',
            '1W',
            // '1M',
          ],
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    userInput = userInput.toUpperCase();
    const arrayWithSymbols = Object.keys(this.symbols).map((key) => ({
      ...this.symbols[key],
      symbol: key,
    }));
    onResultReadyCallback(
      arrayWithSymbols
        .filter((symbol) => {
          return symbol.symbol.toUpperCase().indexOf(userInput) >= 0;
        })
        .map((symbol) => {
          return {
            symbol: symbol.symbol,
            full_name: symbol.symbol,
            description: symbol.description,
            ticker: symbol.symbol,
            exchange: 'Brine',
            type: 'crypto',
          };
        })
    );
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    // console.log('👉 resolveSymbol:', symbolName);

    const comps = symbolName.split(':');
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

    // function pricescale(symbol) {
    //   for (let filter of symbol.filters) {
    //     if (filter.filterType == 'PRICE_FILTER') {
    //       return Math.round(1 / parseFloat(filter.tickSize));
    //     }
    //   }
    //   return 1;
    // }

    for (let symbol in this.symbols) {
      let symbolData = this.symbols[symbol];
      if (symbol?.toLowerCase() == symbolName?.toLowerCase()) {
        setTimeout(() => {
          onSymbolResolvedCallback({
            name: symbol,
            description: symbol?.toUpperCase(),
            ticker: symbolName,
            exchange: 'Brine',
            listed_exchange: 'Brine',
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: Math.pow(10, symbolData.price_precision),
            timezone: 'UTC',
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            has_no_volume: false,
            // for finding the sell currency
            currency_code: symbolData['buy']['currency_buy'],
          });
        }, 0);
        return;
      }
    }
    // minmov/pricescale will give the value of decimal places that will be shown on y-axis of the chart
    //
    onResolveErrorCallback('not found');
  }

  getBars(
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) {
    const { from, to, firstDataRequest } = periodParams;
    const interval = this.ws.tvIntervals[resolution];
    if (!interval) {
      onErrorCallback('Invalid interval');
    }

    let totalKlines = [];
    const kLinesLimit = 100;

    const finishKlines = (symbolInfo) => {
      // console.log(symbolInfo, 'symbolInfo');
      if (totalKlines.length === 0) {
        onHistoryCallback([], { noData: true });
      } else {
        let historyCBArray = totalKlines.map((kline) => ({
          time: kline[0] * 1000,
          // open: parseFloat(kline[1]),
          open: parseFloat(kline[1]),
          high: parseFloat(kline[2]),
          low: parseFloat(kline[3]),
          close: parseFloat(kline[4]),
          volume: parseFloat(kline[5]),
        }));
        onHistoryCallback(historyCBArray, { noData: false });
      }
    };

    const getKlines = async (from, to) => {
      try {
        const data = await this.brineKlines(
          symbolInfo.name,
          interval,
          from,
          to,
          kLinesLimit
        );
        totalKlines = totalKlines.concat(data);
        if (data.length === kLinesLimit) {
          from = data[data.length - 1][0] + 1;
          getKlines(from, to);
        } else {
          finishKlines(symbolInfo);
        }
      } catch (e) {
        console.error(e);
        onErrorCallback(`Error in 'getKlines' func`);
      }
    };

    // from *= 1000;
    // to *= 1000;
    getKlines(from, to);
  }

  subscribeBars(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) {
    this.ws.subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback
    );
  }

  unsubscribeBars(subscriberUID) {
    this.ws.unsubscribeFromStream(subscriberUID);
  }
}
