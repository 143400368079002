import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  SkeletonContainer,
  Popup,
  CustomButton,
  WalletColumn,
  WalletsBankPopup,
  Image,
  IconBtn,
  LockedBalance,
} from '../../../components';
import {
  useBalance,
  useAnalytics,
  useWebSocketData,
  listenForOutsideClicks,
  useViewport,
  useWalletMethods,
  useSegment,
} from '../../../utils/hooks';
import { toast } from 'react-toastify';
import { scroller } from 'react-scroll';
import { getQueryParams } from '../../../utils/query-helper';
import ReactTooltip from 'react-tooltip';
import { getBalance } from '../../../actions/userAction';
import { useWeb3React } from '@web3-react/core';
import { claimfreeUsdc } from '../../../actions/walletAction';
import { images } from '../../../utils/image/img';
import { ALLOWED_NETWORK_ID, baseCurrency } from '../../../utils/constants';
import { useHistory, useLocation } from 'react-router';

function FreeUsdcClaimNotesPopup({
  claimPopup,
  setClaimPopup,
  handleClaimFreeUsdc,
  disableUsdtbtn,
}) {
  const { getInfoAboutCoins } = useBalance();
  return (
    <Popup state={claimPopup} setState={setClaimPopup}>
      <div
        className='popup__box'
        style={{
          paddingTop: 32,
        }}
      >
        <div
          className='notes__instant'
          style={{
            marginTop: 20,
          }}
        >
          <div
            className='h6'
            style={{
              textAlign: 'center',
            }}
          >
            Notes for getting free assets
          </div>
          <ul style={{ listStyleType: 'revert' }}>
            <li>To claim 100 USDC</li>
            <li>Open your wallet</li>
            <li>Click on “import token”</li>
            <li>
              In token address enter “
              {getInfoAboutCoins('usdc')?.token_contract}”
            </li>
            <li>Click Confirm</li>
          </ul>
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              marginTop: 20,
            }}
          >
            <CustomButton
              title='Confirm'
              onClick={handleClaimFreeUsdc}
              disabled={disableUsdtbtn == true}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
              color: '#808191',
              padding: '0px 25px 0px 25px',
            }}
          >
            <hr
              style={{
                width: '20%',
                height: '1px',
                backgroundColor: '#808191',
                border: 'none',
              }}
            />
            <h3 style={{ padding: '1px 11px 4px' }}>and</h3>
            <hr
              style={{
                width: '20%',
                height: '1px',
                backgroundColor: '#808191',
                border: 'none',
              }}
            />
          </div>
          <ul style={{ listStyleType: 'revert' }}>
            <li>To claim 0.5 ETH from Goerli Faucet.</li>
          </ul>
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              marginTop: 20,
            }}
          >
            <CustomButton
              title='Claim'
              onClick={() =>
                window.open('https://faucet.paradigm.xyz/', {
                  target: '_blank',
                })
              }
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}

function FreeUSDC({ setClaimPopup }) {
  return (
    <div className='free_usdc'>
      <p>
        {/* Claim 0.5 ETH from Goerli Faucet. */}
        🎉 Get Free assests for trading
        <span onClick={() => setClaimPopup((prev) => !prev)}>Claim</span>{' '}
        {/* <a href="https://faucet.paradigm.xyz/" target="blank">
          Click here
        </a> */}
      </p>
    </div>
  );
}

// function DepositDropdown({
//   getCurrencyBalances,
//   roundUp,
//   getUSDCinBalance,
//   setOpenDepositModal,
//   depositDropdownRef,
// }) {
//   const styles = {
//     dropDownContainer: {
//       width: 280,
//       position: 'relative',
//     },
//     depositBtn: {
//       right: 10,
//     },
//     depositInnerContainer: {
//       fill: '#fff',
//       display: 'flex',
//       flexDirection: 'row',
//       justifyContent: 'center',
//       alignItems: 'center',
//       marginLeft: 3,
//     },
//     dropDownItem: {
//       width: '100%',
//     },
//     dropDownItemInnerContainer: {
//       display: 'flex',
//       placeItems: 'flex-end',
//     },
//     dropDownItemInnerContainerInfo: {
//       textTransform: 'uppercase',
//       marginTop: '3px',
//       fontSize: 11,
//       fontFamily: 'Poppins',
//       fontWeight: 600,
//     },
//   };
//   const { width } = useViewport();
//   return (
//     <div
//       className='wallets__btns'
//       ref={depositDropdownRef}
//       style={{
//         bottom:
//           getCurrencyBalances() - roundUp(getUSDCinBalance()) == 0
//             ? '11px'
//             : '32px',
//         display: 'flex',
//         flexDirection: 'column',
//       }}
//     >
//       <div style={styles.dropDownContainer}>
//         <div className='wallet_deposit_container'>
//           <button
//             className='wallets__btn btn btn_orange'
//             onClick={() => setOpenDepositModal((prev) => !prev)}
//             style={styles.depositBtn}
//           >
//             <span className='btn__text' style={styles.depositInnerContainer}>
//               <svg
//                 xmlns='http://www.w3.org/2000/svg'
//                 width='17'
//                 height='17'
//                 data-name='Layer 1'
//                 viewBox='0 0 24 24'
//                 style={{ marginRight: 10 }}
//               >
//                 <path d='M16 12v2c0 1.103-.897 2-2 2h-4c-1.103 0-2-.897-2-2v-2H0v9c0 1.654 1.346 3 3 3h18c1.654 0 3-1.346 3-3v-9h-8zm6 9a1 1 0 01-1 1H3a1 1 0 01-1-1v-7h4c0 2.206 1.794 4 4 4h4c2.206 0 4-1.794 4-4h4v7zM10.586 10.414L7.293 7.121l1.414-1.414L11 8V0h2v8l2.293-2.293 1.414 1.414-3.293 3.293c-.39.39-.902.585-1.414.585s-1.024-.195-1.414-.585z'></path>
//               </svg>
//               {width < 1339 ? 'Deposit' : 'Click here to Deposit'}
//             </span>
//           </button>
//         </div>
//       </div>
//       {/* <div
//         className={` exchange__company ${
//           depositOpen ? 'active' : ''
//         } wallet_dropdown`}
//       >
//         <div className='exchange__body wallet_body wallet_dropdown'>
//           <input
//             className='actions__input exchange_search__input active'
//             type='search'
//             value={searchText}
//             placeholder='Search'
//             style={{ marginTop: 3 }}
//             onChange={(e) => setSearchText(e.target.value)}
//           />
//           <div className='wallet_coin__dropdown'>
//             {result?.map((item, idx) => (
//               <button
//                 className='exchange__item'
//                 style={styles.dropDownItem}
//                 key={idx}
//                 onClick={() => handleOpenDeposit(item?.symbol)}
//               >
//                 <div className='exchange__logo'>
//                   <div style={styles.dropDownItemInnerContainer}>
//                     <Image src={item.logo} />
//                   </div>
//                 </div>
//                 <div className='exchange__details'>
//                   <div className='exchange__currency'>{item.name}</div>
//                   <div
//                     className='exchange__info'
//                     style={styles.dropDownItemInnerContainerInfo}
//                   >
//                     {' '}
//                     {getSingleCoinsBalanceInUSDC(item.symbol)}{' '}
//                     {item.symbol == 'usdt' ? 'USDT' : 'USDC'}
//                   </div>
//                 </div>
//               </button>
//             ))}
//           </div>
//         </div>
//       </div> */}
//     </div>
//   );
// }

function WalletsTotalBalances({
  setVisibleSideBar,
  openPopUp,
  setOpenPopUp,
  setSelectedData,
  initiator,
  setInitiatorDepositState,
  initiatorDepositState,
  visibleSideBar,
  openBankDetailsWalletPopUp,
  setOpenDepositModal,
  setOpenBankDetailsWalletPopUp,
  setOpenWithdrawModal,
  handleDepositOpen,
}) {
  // const {
  // getAssests,
  // userGetBalance,
  // getPortfolio,
  //   theme: { isdark },
  //   userIpAddress: { ip },
  //   userVia: { state: userVia },
  // } = useSelector((state) => state);

  const { state: userVia } = useSelector((state) => state.userVia);
  const { ip } = useSelector((state) => state.userIpAddress);
  const { isdark } = useSelector((state) => state.theme);

  const [selectedWData, setSelectedWData] = useState(null);
  const { analytics, sendTrackEvent } = useSegment();
  const { account, provider: library } = useWeb3React();
  const { getBalance: getBalanceWC } = useWalletMethods();
  const { state: currentNetworkData } = useSelector((s) => s.ethNetworkDetails);
  const {
    getCurrencyBalances,
    roundUp,
    formatCurrency,
    getChartData,
    getInvestedPrice,
    getUSDCinBalance,
    roundUpWithSymbol,
    // getInfoAboutCoins,
    getTotalLockedBalances,
    toFixedTrunc,
  } = useBalance();
  const { getSocketFunctionalityInfo } = useWebSocketData();
  const { width } = useViewport();
  const { loading, error, balance } = useSelector(
    (state) => state.userGetBalance
  );
  const {
    loading: assetLoading,
    error: assetError,
    assetsData,
  } = useSelector((state) => state.getAssests);
  // const [openBankPopup, setOpenBankPopUp] = useState(false);
  // const [usdtData, setUsdtData] = useState(null);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [disableRefetch, setDisableRefetch] = useState(false);
  const [usdcBalance, setUsdcBalance] = useState(null);
  const [claimPopup, setClaimPopup] = useState(false);
  const [disableUsdtbtn, setDisableUsdtbtn] = useState(false);
  const [totalBalance, setTotalBalance] = useState('0');
  // const [depositOpen, setDepositOpen] = useState(false);
  // const [searchText, setSearchText] = useState('');
  // const [result, setResult] = useState([]);
  // const depositDropdownRef = useRef(null);
  const [openLockBalance, setOpenLockBalance] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    loading: getPortfolioLoading,
    error: getPortfolioError,
    response: getPortfolioResponse,
  } = useSelector((state) => state.getPortfolio);
  // const {
  //   userLogin: { userInfo },
  //   getCoins: { coinsDetails },
  //   getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const {
    loading: orderLoading,
    error: orderError,
    orderBookData,
  } = useSelector((state) => state.getOrderBook);

  useEffect(() => {
    if (userInfo?.token) {
      setSelectedWData(balance?.payload[0]);
    }
  }, [balance, userInfo]);

  function percentage(partialValue, totalValue) {
    // |7300000 - 3700000|/7300000 = 3600000/7300000 = 0.49315068493151 = 49.315068493151% if - == increase | + == decrease
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    //console.log(value);
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  useEffect(() => {
    if (userInfo?.token) {
      setCurrentAmount(getCurrencyBalances());
      setInvestedAmount(getInvestedPrice());
      setTotalBalance(getCurrencyBalances(true));
    }
  }, [
    balance,
    coinsDetails,
    assetsData,
    getPortfolioResponse,
    userInfo,
    orderBookData,
  ]);

  // useEffect(() => {
  //   if (userInfo?.token) {
  //     const data = getInfoAboutCoins('usdt');
  //     setUsdtData(data);
  //   }
  // }, [coinsDetails, userInfo]);

  // const scrollToSection = () => {
  //   scroller.scrollTo('header', {
  //     duration: 400,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //   });
  // };

  // const handleOpenDeposit = (symbol) => {
  //   // analytics?.track('Deposit USDT button clicked ', {
  //   //   from: 'Wallet Page',
  //   //   uid: userInfo?.payload?.uid,
  //   //   QueryParams: { ...getQueryParams() },
  //   //   ip: ip,
  //   // });

  //   const containsInr = balance?.payload.find(
  //     (item) => item.currency === symbol
  //   );

  //   const data = {};

  //   if (containsInr) {
  //     data['inrBalance'] = containsInr.balance;
  //     data['balance'] = containsInr.balance;
  //     data['currency'] = containsInr.currency;
  //     data['locked'] = containsInr.locked;
  //   } else {
  //     data['inrBalance'] = 0;
  //     data['balance'] = 0;
  //     data['currency'] = symbol;
  //     data['locked'] = '0.0';
  //   }
  //   setVisibleSideBar('deposit');
  //   setSelectedData(data);
  //   scrollToSection();
  //   // setDepositOpen((prev) => !prev);
  //   // setSearchText('');
  // };

  const refetchBalance = () => {
    if (disableRefetch) return '';
    setVisibleSideBar(false);
    setSelectedData(false);
    dispatch(getBalance());
    setDisableRefetch(true);
    setTimeout(() => {
      setDisableRefetch(false);
    }, 5000);
  };

  const getWalletBalance = async () => {
    if (userVia?.via !== 'web3_auth') {
      await getBalanceWC('usdc')
        .then((data) => {
          setUsdcBalance(roundUpWithSymbol(data, 'usdc'));
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (coinsDetails) {
      getWalletBalance();
    }
    return () => {
      setUsdcBalance(null);
    };
  }, [balance, coinsDetails, userVia, currentNetworkData?.network]);

  const handleClaimFreeUsdc = async () => {
    setDisableUsdtbtn(true);
    try {
      const { data } = await claimfreeUsdc(userInfo?.token?.access);
      setClaimPopup(false);
      setTimeout(() => {
        getWalletBalance();
        setDisableUsdtbtn(false);
      }, 5000);
      toast.success(data?.message);
    } catch (error) {
      toast.error(error);
      getWalletBalance();
      setDisableUsdtbtn(false);
    }
  };

  // useEffect(() => {
  //   if (searchText.length === 0) {
  //     setResult(coinsDetails?.convertedArray);
  //   } else {
  //     const data = coinsDetails.convertedArray.filter(
  //       (data) =>
  //         data.name
  //           .toLocaleLowerCase()
  //           .includes(searchText.toLocaleLowerCase()) ||
  //         data.symbol
  //           .toLocaleLowerCase()
  //           .includes(searchText.toLocaleLowerCase())
  //     );
  //     setResult(data);
  //   }
  // }, [searchText, coinsDetails?.convertedArray]);

  // const handleClose = () => {
  //   setDepositOpen(false);
  //   setSearchText('');
  // };

  // useEffect(
  //   listenForOutsideClicks(
  //     listening,
  //     setListening,
  //     depositDropdownRef,
  //     handleClose
  //   )
  // );

  return (
    <>
      {userInfo?.token ? (
        <>
          <div className='wallets__row'>
            <div className='wallets__total'>
              <div className='wallets__title h6'>
                Total Balance
                <span
                  data-tip={
                    'Total balance = Locked balance + Available balance'
                  }
                  data-for='total_balance_tip'
                >
                  <Icon
                    icon='info-circle'
                    className='icon wallets__title_icon'
                    size={'12px'}
                    color='#6C5DD3'
                  />
                </span>
                <ReactTooltip
                  place='bottom'
                  id='total_balance_tip'
                  type={isdark ? 'light' : 'dark'}
                  className='tooltip_styles price_details'
                />
              </div>
              {loading || !balance ? (
                <div style={{ marginBottom: 30 }}>
                  <SkeletonContainer
                    width={'150px'}
                    height='50px'
                    style={{ borderRadius: 20 }}
                  />
                  <SkeletonContainer
                    width={'100px'}
                    height='20px'
                    style={{ borderRadius: 20 }}
                  />
                </div>
              ) : (
                <>
                  <div className='wallets__flex'>
                    {getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial ? (
                      <>
                        <div
                          className={`wallets__number ${
                            visibleSideBar ? 'h3' : 'h2'
                          }`}
                        >
                          {formatCurrency(Number(totalBalance))
                            ? formatCurrency(toFixedTrunc(totalBalance, 3))
                            : '0.00'}
                        </div>
                        <div className='wallets__currency'>
                          {baseCurrency?.toUpperCase()}
                        </div>
                      </>
                    ) : (
                      <div
                        className={`wallets__number h3`}
                        style={{ display: 'flex', alignItems: 'flex-end' }}
                      >
                        <div>Processing</div>
                        <div
                          className='dot-flashing'
                          style={{ marginLeft: 17, marginRight: 20 }}
                        ></div>
                      </div>
                    )}
                    <div
                      className={`refresh__balance__wallet ${
                        disableRefetch ? 'disable_btn' : ''
                      }`}
                      style={{ top: '42px', right: '28px', cursor: 'pointer' }}
                    >
                      <div onClick={refetchBalance}>
                        <i
                          className='fas fa-redo-alt'
                          data-tip={'Refresh after 5s'}
                          data-for='refresh'
                        ></i>
                      </div>
                      {disableRefetch && (
                        <ReactTooltip
                          place='bottom'
                          id='refresh'
                          type={isdark ? 'light' : 'dark'}
                          className='tooltip_styles'
                        />
                      )}
                    </div>
                  </div>
                  {(getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial) && (
                    <div
                      // style={{
                      //   display: 'flex',
                      //   flexDirection: 'row',
                      //   alignItems: 'center',
                      //   marginBottom: 8,
                      //   marginLeft: 4,
                      // }}
                      className={`total__balance_wrapper ${
                        width <= 1000 || visibleSideBar ? 'column' : ''
                      }`}
                    >
                      {getTotalLockedBalances() && (
                        <div className='wallets__code total__balance_item'>
                          <div>
                            <Image
                              src={images.lockedBalanceIcon}
                              style={{ width: 15, marginRight: 5 }}
                            />
                          </div>
                          <div>
                            Locked balance -{' '}
                            {toFixedTrunc(getTotalLockedBalances(), 3)}{' '}
                            {baseCurrency?.toUpperCase()}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setOpenLockBalance((prev) => !prev)
                              }
                            ></span>
                          </div>
                        </div>
                      )}
                      <div className='vl'></div>
                      {currentAmount && (
                        <div className='wallets__code total__balance_item'>
                          <div>
                            <Image
                              src={images.avalBalance}
                              style={{ width: 15, marginRight: 5 }}
                            />
                          </div>
                          <div>
                            Available balance - {toFixedTrunc(currentAmount, 3)}{' '}
                            {baseCurrency?.toUpperCase()}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setOpenLockBalance((prev) => !prev)
                              }
                            >
                              {/* <Icon
                            icon='info-circle'
                            className='icon'
                            size={'12px'}
                            color='#6C5DD3'
                          /> */}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            {balance && !error && (
              <>
                {loading || !balance ? (
                  <SkeletonContainer width={'100%'} />
                ) : (
                  <WalletColumn chartData={getChartData()} />
                )}
              </>
            )}
          </div>
          {Number(currentAmount) - roundUp(getUSDCinBalance()) !== 0 && (
            <>
              {loading || !balance || getPortfolioLoading ? (
                <div
                  style={{
                    marginTop: '52px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    maxWidth: '50%',
                  }}
                >
                  <SkeletonContainer
                    width={'100%'}
                    height={'100px'}
                    style={{ borderRadius: '20px' }}
                  />
                  <SkeletonContainer
                    width={'100%'}
                    height={'100px'}
                    style={{ marginLeft: 12, borderRadius: '20px' }}
                  />
                </div>
              ) : (
                <>
                  {(getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial) && (
                    <div className='wallets__inner' style={{ marginTop: 0 }}>
                      <div className='wallets__list'>
                        <div className='wallets__item'>
                          <div className='wallets__icon'>
                            <Image src={images.circleBlue} alt='' />
                          </div>
                          <div className='wallets__details'>
                            <div className='wallets__info'>
                              <ReactTooltip
                                place='bottom'
                                type={isdark ? 'light' : 'dark'}
                                className='tooltip_styles price_details'
                                id={'Invested'}
                              />
                              Invested
                              <span
                                data-tip={`Total investment made in this asset (In ${baseCurrency?.toUpperCase()})`}
                                data-for={'Invested'}
                              >
                                <Icon icon='info-circle' className='icon' />
                              </span>
                            </div>
                            <div
                              className='wallets__number h6'
                              style={{ fontSize: 15 }}
                            >
                              {' '}
                              {isNaN(roundUp(Number(investedAmount)))
                                ? 0
                                : formatCurrency(
                                    toFixedTrunc(investedAmount, 3),
                                    baseCurrency
                                  )}
                            </div>
                            <div
                              className={`wallets__price ${
                                Number(currentAmount) < Number(investedAmount)
                                  ? 'negative'
                                  : ''
                              } small`}
                            >
                              Returns{' '}
                              {Number(currentAmount) > 0
                                ? percentage(
                                    Number(investedAmount),
                                    Number(currentAmount)
                                  )
                                : `0%`}
                            </div>
                          </div>
                        </div>
                        <div className='wallets__item'>
                          <div className='wallets__icon'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='12'
                                cy='12'
                                r='8'
                                stroke='#ff9f38'
                                strokeWidth='8'
                                strokeLinecap='round'
                                strokeDasharray='266 179'
                              />
                            </svg>
                          </div>
                          <div className='wallets__details'>
                            <div className='wallets__info'>
                              Current
                              <span
                                data-tip={`Present value of this asset held in your wallet (In ${baseCurrency?.toUpperCase()})`}
                                data-for={'Current'}
                              >
                                <Icon icon='info-circle' className='icon' />
                                <ReactTooltip
                                  place='bottom'
                                  type={isdark ? 'light' : 'dark'}
                                  className='tooltip_styles price_details'
                                  id={'Current'}
                                />
                              </span>
                            </div>
                            <div
                              className='wallets__number h6'
                              style={{ fontSize: 15 }}
                            >
                              {' '}
                              {isNaN(roundUp(Number(currentAmount))) ||
                              formatCurrency(Number(currentAmount)) == ''
                                ? 0
                                : formatCurrency(
                                    toFixedTrunc(currentAmount, 3)
                                  )}
                            </div>
                            <div
                              className={`wallets__price ${
                                Number(currentAmount) < Number(investedAmount)
                                  ? 'negative'
                                  : ''
                              } small`}
                            >
                              Total{' '}
                              {Number(currentAmount) < Number(investedAmount)
                                ? 'Loss'
                                : 'Profit'}{' '}
                              {/* {currentAmount < investedAmount ? '' : '+'}{' '} */}
                              {!isNaN(
                                Number(currentAmount) - Number(investedAmount)
                              )
                                ? formatCurrency(
                                    Number(currentAmount).toFixed(3) -
                                      Number(investedAmount).toFixed(3) ==
                                      0
                                      ? '0'
                                      : toFixedTrunc(
                                          String(
                                            Number(currentAmount) -
                                              Number(investedAmount)
                                          )?.replace('-', ''),
                                          3
                                        )
                                  )
                                : '0'}{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {!loading && balance && !getPortfolioLoading && (
            <>
              {Number(currentAmount) - roundUp(getUSDCinBalance()) === 0 && (
                <div className='wallets__inner' style={{ marginTop: 0 }}>
                  <div className='wallets__list'>
                    <div className='wallets__item'>
                      <div className='wallets__icon'>
                        <Image src={images.circleBlue} alt='' />
                      </div>
                      <div className='wallets__details'>
                        <div className='wallets__info'>
                          <ReactTooltip
                            place='bottom'
                            type={isdark ? 'light' : 'dark'}
                            className='tooltip_styles price_details'
                            id={'Invested'}
                          />
                          Invested
                          <span
                            data-tip={`Total investment made in this asset (In ${baseCurrency?.toUpperCase()})`}
                            data-for={'Invested'}
                          >
                            <Icon icon='info-circle' className='icon' />
                          </span>
                        </div>
                        <div
                          className='wallets__number h6'
                          style={{ fontSize: 15 }}
                        >
                          {' '}
                          0
                        </div>
                        <div className={`wallets__price small`}>Returns 0%</div>
                      </div>
                    </div>
                    <div className='wallets__item'>
                      <div className='wallets__icon'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle
                            cx='12'
                            cy='12'
                            r='8'
                            stroke='#ff9f38'
                            strokeWidth='8'
                            strokeLinecap='round'
                            strokeDasharray='266 179'
                          />
                        </svg>
                      </div>
                      <div className='wallets__details'>
                        <div className='wallets__info'>
                          Current
                          <span
                            data-tip={`Present value of this asset held in your wallet (In ${baseCurrency?.toUpperCase()})`}
                            data-for={'Current'}
                          >
                            <Icon icon='info-circle' className='icon' />
                            <ReactTooltip
                              place='bottom'
                              type={isdark ? 'light' : 'dark'}
                              className='tooltip_styles price_details'
                              id={'Current'}
                            />
                          </span>
                        </div>
                        <div
                          className='wallets__number h6'
                          style={{ fontSize: 15 }}
                        >
                          0
                        </div>
                        <div className={`wallets__price small`}>
                          Total Profit 0
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {(!loading || !getPortfolioLoading || !assetLoading) &&
            (getSocketFunctionalityInfo().full ||
              getSocketFunctionalityInfo().partial) &&
            !visibleSideBar && (
              <div
                className='wallets__btns card__btns card__btns_mt20'
                style={{ width: 300 }}
              >
                <button
                  className='card__btn btn btn_orange'
                  onClick={() => {
                    setOpenDepositModal(true);
                    sendTrackEvent('buttonClicked', {
                      tittle: 'Deposit',
                      action: 'Opening deposit popup',
                    });
                  }}
                >
                  <IconBtn
                    className='icon'
                    iconName='deposit'
                    text={'Deposit'}
                    color='#fff'
                  />
                </button>
                <button
                  className='card__btn btn btn_border'
                  onClick={() => {
                    setOpenWithdrawModal(true);
                    sendTrackEvent('buttonClicked', {
                      tittle: 'Withdraw',
                      action: 'Opening withdraw popup',
                    });
                    // analytics?.track('Clicked withdraw section', {
                    //   from: 'Wallet Page',
                    //   uid: userInfo?.payload?.uid,
                    //   QueryParams: { ...getQueryParams() },
                    //   ip: ip,
                    // });
                  }}
                >
                  <IconBtn
                    text={'Withdraw'}
                    className='icon'
                    iconName='withdraw'
                  />
                </button>
              </div>
            )}
          {ALLOWED_NETWORK_ID != 1 && (
            <>
              {(!loading || !getPortfolioLoading || !assetLoading) &&
                assetsData &&
                usdcBalance !== null && (
                  <>
                    {usdcBalance == 0 && Number(currentAmount) == 0 && (
                      <FreeUSDC setClaimPopup={setClaimPopup} />
                    )}
                  </>
                )}
            </>
          )}
          <WalletsBankPopup
            setState={setOpenBankDetailsWalletPopUp}
            state={openBankDetailsWalletPopUp}
          />
          <FreeUsdcClaimNotesPopup
            claimPopup={claimPopup}
            setClaimPopup={setClaimPopup}
            handleClaimFreeUsdc={handleClaimFreeUsdc}
            disableUsdtbtn={disableUsdtbtn}
          />
        </>
      ) : (
        <div className='static__wallet_container'>
          <div className='static__wallet_image'>
            <Image src={images.walletBanner} />
          </div>
          <div className='static__wallet_content'>
            <div className='h5'>
              Connect your wallet to deposit assets on Brine.
            </div>
            <div className='h6'>
              Seems like you haven’t connected your wallet yet. To get started
              with trading on Brine, please connect your wallet and deposits
              assets
            </div>
            <CustomButton
              title={'Connect Wallet'}
              onClick={() => {
                history.push({
                  pathname: `/signin`,
                  search: location.search,
                });
                sendTrackEvent('buttonClicked', {
                  title: 'Connect Wallet',
                  action: 'Routing to Login Page',
                });
              }}
            />
          </div>
        </div>
      )}
      <LockedBalance state={openLockBalance} setState={setOpenLockBalance} />
    </>
  );
}

export default WalletsTotalBalances;
