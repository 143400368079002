import { useSelector } from 'react-redux';
import { Popup } from '../../../components';
import TableCell from '../../settings/settings-tabs/login-details/table-cell/table-cell';

function WalletsBankPopup({ state, setState }) {
  const { userData } = useSelector((state) => state.userDetails);

  return (
    <Popup state={state} setState={setState}>
      <div className='popup__box' style={{ paddingTop: 32 }}>
        <div className='popup__title h6' style={{ textAlign: 'center' }}>
          Instant Bank Transfer
        </div>
        <table className='popup__table'>
          <tbody>
            <TableCell
              isaccountDetails={true}
              name='ACCOUNT NUMBER'
              value={`KRPT${userData?.payload?.unique_identifier}`}
            />
            <TableCell
              isaccountDetails={true}
              name='BANK IFSC'
              value={userData?.payload?.bank_ifsc}
            />
            <TableCell
              isaccountDetails={true}
              name='ACCOUNT NAME'
              value={'InventPhile Ventures Pvt.Ltd'.toUpperCase()}
            />
          </tbody>
        </table>
        <div className='notes__instant'>
          <div className='h6'>Notes for deposits</div>
          <ul>
            <li>
              Only transfer funds using your online bank portal through
              NEFT,RTGS and IMPS mode to the given account.
            </li>
            <li>
              Minumum deposit Rs.500 and maximum deposit Rs.5,00,000 (Important)
            </li>
            <li>
              Not Sure?{' '}
              <a
                href='https://kryptoex.s3.ap-south-1.amazonaws.com/static/KryptoUserAgreement.pdf'
                className='why__kyc'
                target='_blank'
                referrerPolicy='no-referrer'
              >
                click here for video tutorial.
              </a>
            </li>
            <li>
              Your funds will be deposited in your krypto wallet within 1 minute
              from when we recieve your deposit.
            </li>
            <li>
              If your deposit takes more than 24hrs.kindly contact
              <a href='mailto: payments@letskrypto.com' className='why__kyc'>
                {' '}
                payments@letskrypto.com{' '}
              </a>
              for instant support
            </li>
          </ul>
        </div>
      </div>
    </Popup>
  );
}

export default WalletsBankPopup;
