import moment from 'moment';

function getOneDayTimeStamp() {
  let beforeDay = new Date();
  let presentDay = Math.round(beforeDay.getTime() / 1000);
  beforeDay.setDate(beforeDay.getDate() - 1);
  let unixDay = beforeDay.getTime() / 1000.0;
  return { beforeDay: unixDay.toFixed(), presentDay };
}

function getOneHourTimeStamp() {
  let currentTime = new Date();
  let presentTime = currentTime.getTime() / 1000.0;
  let beforeTime = presentTime - 3600;
  return {
    beforeDay: Number(beforeTime.toFixed()),
    presentDay: Number(presentTime.toFixed()),
  };
}

function getMonthTimeStamp() {
  let presentTime = new Date();
  const beforeTime = moment().subtract(1, 'months').unix();
  presentTime.setDate(presentTime.getDate() - 1);
  presentTime = Math.round(presentTime.getTime() / 1000);
  return { beforeDay: beforeTime, presentDay: presentTime };
}

function getYearTimeStamp() {
  let presentTime = new Date();
  const beforeTime = moment().subtract(1, 'years').unix();
  presentTime.setDate(presentTime.getDate() - 1);
  presentTime = Math.round(presentTime.getTime() / 1000);
  return { beforeDay: beforeTime, presentDay: presentTime };
}

export {
  getOneDayTimeStamp,
  getOneHourTimeStamp,
  getMonthTimeStamp,
  getYearTimeStamp,
};
