import { useSelector } from 'react-redux';

export const FEATURE_FLAG = {
  deposit: 'DEPOSIT',
  orders: 'ORDERS',
  referral: 'REFERRAL_REWARD',
  withdrawal: 'WITHDRAWAL',
  fastWithdrawal: 'FAST_WITHDRAWAL',
  // cross chain deposit
  ccDeposit: 'CC_DEPOSIT',
};

function useFeature() {
  const {
    response: userFeatureFlagResponse,
    error: userFeatureFlagError,
    loading: userFeatureFlagLoading,
  } = useSelector((s) => s.userFeatureFlag);

  const getStatusByFeatureKey = (key) => {
    if (userFeatureFlagLoading || userFeatureFlagError) {
      return {
        status: true,
        reason: '',
      };
    }

    const platformFlag = findFeatureStatusByKey('platform_feature_status', key);
    const userFlag = findFeatureStatusByKey('user_feature_status', key);

    if (!platformFlag?.status || !userFlag?.status) {
      return {
        feature: platformFlag.feature_id,
        status: false,
        reason: platformFlag.public_reason || userFlag?.public_reason,
      };
    } else {
      return {
        feature: platformFlag.feature_id,
        status: true,
        reason: '',
      };
    }
  };

  const findFeatureStatusByKey = (flagType, key) => {
    return userFeatureFlagResponse?.payload[flagType]?.find(
      (item) => item.feature_id === key
    );
  };

  return { getStatusByFeatureKey };
}

export default useFeature;
