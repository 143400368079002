import * as walletConstant from '../constants/walletConstant';

export const getWithdrawalVaultIdReducer = (state = {}, action) => {
  switch (action.type) {
    case walletConstant.GET_WITHDRAWAL_VAULT_ID_REQUEST:
      return { loading: true };
    case walletConstant.GET_WITHDRAWAL_VAULT_ID_SUCCESS:
      return { loading: false, state: action.payload };
    case walletConstant.GET_WITHDRAWAL_VAULT_ID_FAIL:
      return { loading: false, error: action.payload };
    case walletConstant.GET_WITHDRAWAL_VAULT_ID_CLEANUP:
      return {};
    default:
      return state;
  }
};
