import Lottie from 'react-lottie';
import { useHistory } from 'react-router';
import { CustomButton } from '../../components';
import notFoundedAnimationData from '../../assests/json/404.json';

function NotFound() {
  const history = useHistory();
  const NotFoundedAnimationData = {
    loop: false,
    autoplay: true,
    animationData: notFoundedAnimationData,
  };
  return (
    <div className='not__found'>
      {/* <div className="not__found__img"> */}
      <Lottie options={NotFoundedAnimationData} height={400} width={400} />
      {/* </div> */}
      {/* <img src={NotFoundImg} alt="not_found" className="" /> */}
      <div className='h1'>
        Not <span style={{ color: '#8173E0' }}>found</span>
      </div>
      <CustomButton
        title='Go Back to Home'
        onClick={() => history.push('/')}
        style={{ marginTop: 40, marginBottom: 20 }}
      />
    </div>
  );
}

export default NotFound;
