import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeMsg } from '../../actions/assestsAction';
import { changeNotificatedState } from '../../actions/userAction';
import useSegment from './useSegment';
import useBalance from './useBalance';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useWalletMethods } from './useWallets';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import uniqBy from 'lodash/unionBy';

const NotificationToastContainer = ({
  closeToast,
  itemList,
  textContent,
  linkContent,
  link,
  type,
}) => {
  const { sendTrackEvent } = useSegment();
  const { getInfoAboutCoins } = useBalance();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {itemList.length === 1 ? (
          <img
            src={getInfoAboutCoins(itemList[itemList.length - 1].symbol)?.logo}
            alt=''
            style={{ width: 40 }}
          />
        ) : (
          <>
            {itemList?.slice(0, 3).map((item, idx) => (
              <img
                key={idx}
                src={getInfoAboutCoins(item.symbol)?.logo}
                alt=''
                style={{ width: 40, marginLeft: idx !== 0 ? -16 : 0 }}
              />
            ))}
          </>
        )}

        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <h5 style={{ fontSize: 13, marginLeft: 12 }}>{textContent}</h5>
          <Link
            to={link}
            style={{
              fontSize: 10,
              marginLeft: 13,
              color: '#8173E0',
              textDecoration: 'underline',
            }}
            onClick={() =>
              sendTrackEvent('notificationClicked', {
                count: itemList.length,
                type: type,
              })
            }
          >
            {/* Click to claim your withdrawal */}
            {linkContent}
          </Link>
        </div>
      </div>
      {/* <button style={toastBtnStyle} onClick={closeToast}>
          Close
        </button> */}
    </div>
  );
};

function useNotification(startFetchWithdrawalBalance = false) {
  // const {
  //   userLogin: { userInfo },
  //   userMsg: { state: userNotificationData },
  // } = useSelector((state) => state);
  const { state: userNotificationData } = useSelector((state) => state.userMsg);
  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();
  const { sendTrackEvent } = useSegment();
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const [withdrawalBalanceList, setWithdrawalBalanceList] = useState([]);
  const { getBalanceWithdraw } = useWalletMethods();
  const { getInfoAboutCoins } = useBalance();
  const { account: connectedAccount } = useWeb3React();

  const getNWithdrawalCoinList = () => {
    return coinsDetails?.convertedArray?.map((item) => item.symbol);
  };

  const getWithdrawalBalances = async (withdrawalCoinList) => {
    try {
      if (withdrawalCoinList?.length) {
        const withdrawableBalanceList = [];
        for (let i = 0; i < withdrawalCoinList?.length; i++) {
          const item = await getInfoAboutCoins(withdrawalCoinList[i]);
          if (withdrawalCoinList[i] == 'eth') {
            let withdrawableBalance;
            withdrawableBalance = await getBalanceWithdraw(
              connectedAccount,
              item?.stark_asset_id
            );
            let ethValue = withdrawableBalance
              ? ethers.utils.formatEther(withdrawableBalance)
              : 0;
            withdrawableBalanceList.push({
              symbol: withdrawalCoinList[i],
              balance: ethValue,
            });
          } else {
            const item = getInfoAboutCoins(withdrawalCoinList[i]);
            let withdrawableBalance;

            withdrawableBalance = await getBalanceWithdraw(
              connectedAccount,
              item?.stark_asset_id
            );

            const blockChainBalance =
              withdrawableBalance / Math.pow(10, item?.blockchain_decimal);
            withdrawableBalanceList.push({
              symbol: withdrawalCoinList[i],
              balance: blockChainBalance,
            });
          }
        }

        // console.log({ withdrawableBalanceList });
        setWithdrawalBalanceList(withdrawableBalanceList);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const generateNotificationForWithdrawal = (withdrawalBalanceList) => {
    if (withdrawalBalanceList.length && userInfo?.token) {
      let balance = withdrawalBalanceList.filter((item) => {
        return item.balance > 0;
      });
      let res = sessionStorage.getItem('clear_withdrawal_toast')
        ? sessionStorage.getItem('clear_withdrawal_toast')
        : null;
      let objIndex = userNotificationData?.findIndex((obj) => {
        return obj.id == 'withdrawal_balance';
      });
      if (balance.length) {
        if (objIndex === -1) {
          let data = userNotificationData;
          let dataObj = {
            id: 'withdrawal_balance',
            logo: 'https://brine-mainnet-fe-assets.s3.ap-southeast-1.amazonaws.com/img/img/money-bag-fill.svg',
            msg: `You have ${balance && balance.length} withdrawal pending`,
            time: moment().format('llll'),
            type: 'withdrawal',
            count: balance?.length,
          };
          data.push(dataObj);
          const uniqData = uniqBy(data, 'id');
          dispatch(storeMsg(uniqData));
          dispatch(changeNotificatedState(false));
        }
        if (!res && coinsDetails?.convertedArray) {
          sendTrackEvent('notificationDisplayed', {
            withdrawalCount: balance.length,
          });
          //   toast.dark(<WithdrawalBalance balance={balance} />, {
          toast.dark(
            <NotificationToastContainer
              itemList={balance}
              type='withdrawal'
              textContent={`You have ${
                balance && balance.length
              } withdrawal pending \n`}
              link={'/history?tab=withdrawals&hightlight=withdrawal'}
              linkContent={`Click to claim your withdrawal`}
            />,
            {
              autoClose: false,
              draggable: false,
              className: 'withdrawal_toast_body',
              onClose: () =>
                sessionStorage.setItem('clear_withdrawal_toast', 'true'),
            }
          );
          sessionStorage.setItem('clear_withdrawal_toast', 'true');
        }
      }
    }
  };

  const handleWithdrawalCount = (withdrawalBalanceList) => {
    if (withdrawalBalanceList.length) {
      let balance = withdrawalBalanceList.filter((item) => {
        return item.balance > 0;
      });
      let objIndex = userNotificationData?.findIndex((obj) => {
        return obj.id == 'withdrawal_balance';
      });
      if (balance.length) {
        if (objIndex !== -1) {
          let data = userNotificationData;
          let count = data[objIndex]?.count;
          if (count == 1) {
            let filterItem = data?.filter((obj) => {
              return obj.id !== 'withdrawal_balance';
            });
            const uniqData = uniqBy(filterItem, 'id');
            dispatch(storeMsg(uniqData));
            // dispatch(changeNotificatedState(false));
          } else {
            let dataObj = {
              id: 'withdrawal_balance',
              logo: 'https://brine-assets-public.s3.ap-southeast-1.amazonaws.com/img/money-bag-fill.svg',
              msg: `You have ${count - 1} withdrawal pending`,
              time: moment().format('llll'),
              type: 'withdrawal',
              count: Number(count) - 1,
            };
            data[objIndex] = dataObj;
            const uniqData = uniqBy(data, 'id');
            dispatch(storeMsg(uniqData));
            // dispatch(changeNotificatedState(false));
          }
        }
      }
    }
  };

  const handleReferralCount = (notClaimedRewards) => {
    if (notClaimedRewards.length) {
      let balance = notClaimedRewards;
      let objIndex = userNotificationData?.findIndex((obj) => {
        return obj.id == 'not_claimed_rewards';
      });
      if (balance.length) {
        if (objIndex !== -1) {
          let data = userNotificationData;
          let count = data[objIndex]?.count;
          if (count == 1) {
            let filterItem = data?.filter((obj) => {
              return obj.id !== 'not_claimed_rewards';
            });
            const uniqData = uniqBy(filterItem, 'id');
            dispatch(storeMsg(uniqData));
            // dispatch(changeNotificatedState(false));
          } else {
            let dataObj = {
              id: 'not_claimed_rewards',
              logo: 'https://brine-mainnet-fe-assets.s3.ap-southeast-1.amazonaws.com/img/img/money-bag-fill.svg',
              msg: `You have ${Number(count) - 1} rewards pending`,
              time: moment().format('llll'),
              type: 'unclaimed_rewards',
              count: Number(count) - 1,
            };
            data[objIndex] = dataObj;
            const uniqData = uniqBy(data, 'id');
            dispatch(storeMsg(uniqData));
            // dispatch(changeNotificatedState(false));
          }
        }
      }
    }
  };

  const getWithdrawalCount = () => {
    let res = userNotificationData?.find(
      (item) => item?.id === 'withdrawal_balance'
    );
    return res?.count;
  };

  const getUnclaimRewardsCount = () => {
    let res = userNotificationData?.find(
      (item) => item?.id === 'not_claimed_rewards'
    );
    return res?.count;
  };

  useEffect(() => {
    if (coinsDetails?.convertedArray && startFetchWithdrawalBalance) {
      let coinList = getNWithdrawalCoinList();
      getWithdrawalBalances(coinList);
    }
    return () => setWithdrawalBalanceList([]);
  }, [coinsDetails?.convertedArray]);

  return {
    generateNotificationForWithdrawal,
    withdrawalBalanceList,
    handleWithdrawalCount,
    handleReferralCount,
    getWithdrawalCount,
    getUnclaimRewardsCount,
  };
}

export default useNotification;
