import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { useBalance } from '../../../utils/hooks';

function DetailsChart({
  chartData,
  color,
  isExchangePage,
  setLastPriceInChart,
}) {
  const { formatCurrency } = useBalance();

  let data = {
    series: [
      {
        data: chartData.payload,
      },
    ],
    xaxis: {
      type: 'numeric',
    },
    options: {
      chart: {
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        type: 'line',
        height: 160,
        width: '100%',
        sparkline: {
          enabled: true,
        },
        events: {
          mouseMove: function (event, chartContext, config) {
            setLastPriceInChart(
              config.globals.stackedSeriesTotals[config.dataPointIndex]
            );
          },
          mouseLeave: function (event, chartContext, config) {
            setLastPriceInChart(null);
          },
        },
      },
      stroke: {
        show: true,
        width: 3,
        curve: 'smooth',
        // curve: 'straight',
      },
      fill: { opacity: 0, type: 'solid' },
      colors: [color],
      tooltip: {
        x: {
          show: true,
          formatter: (data) => {
            //console.log(data);
            return moment.unix(data).format('llll');
          },
        },
        y: {
          formatter: (data) => formatCurrency(data),
          title: {
            formatter: (seriesName) => 'Price : ',
          },
        },
        marker: {
          show: false,
        },
      },
    },
  };
  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type='area'
      width={'100%'}
      height={isExchangePage ? 150 : 132}
    />
  );
}

export default DetailsChart;
