import * as Sentry from '@sentry/react';
import { ENVIRONMENT } from './constants';

export class WebSocketTimeoutHelper {
  constructor(timeoutDuration, wsname) {
    this.timeout = null;
    this.timeoutDuration = timeoutDuration;
    this.wsname = wsname;
  }

  startTimeout() {
    this.timeout = setTimeout(() => {
      Sentry.captureMessage(
        `[WS:${this.wsname}] - No expected data received within the specified timeout. - ${this.timeoutDuration}ms - ENV:[${ENVIRONMENT}]`
      );
      //   console.error(
      //     `[WS:${this.wsname}] - No expected data received within the specified timeout. - ${this.timeoutDuration}ms`
      //   );
    }, this.timeoutDuration);
    return this.timeout;
  }

  clearTimeoutAndResolve() {
    clearTimeout(this.timeout);
  }
}
