import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toggleSetting } from '../../actions/settingAction';
import { userDetails } from '../../actions/userAction';
import {
  CustomSelect,
  HelmetContainer,
  Toast,
  HelpAndFeedBack,
  ProfileTab,
} from '../../components';
import { SETTING_CLEANUP } from '../../constants/settingConstant';
import { getQueryParams } from '../../utils/query-helper';
import { useAnalytics, useSegment } from '../../utils/hooks';

function Settings({ setOpenFeedBackPopup }) {
  // const {
  //   setting: { open: settingOpen, screenState },
  //   userDetails: { userData },
  // } = useSelector((state) => state);

  const { sendTrackEvent, analytics } = useSegment();
  const { open: settingOpen, screenState } = useSelector(
    (state) => state.setting
  );
  const { isdark } = useSelector((state) => state.theme);
  const { userData } = useSelector((state) => state.userDetails);
  const [tabData, setTabData] = useState([
    { label: 'Profile', value: 'Profile' },
    {
      label: 'Support and Community',
      value: 'Help and Feedback',
    },
  ]);

  // useEffect(() => {
  //   const item = tabData.find((item) => item.label === '2FA');

  //   //console.log(item);
  //   if (!response?.payload?.otp && !item) {
  //     setTabData([...tabData, { label: '2FA', value: '2FA' }]);
  //   }
  //   if (response?.payload?.otp && item) {
  //     tabData.splice(tabData.indexOf(item), 0);
  //     setTabData(tabData);
  //   }

  //   if (!userInfo['is_oauth']) {
  //     setTabData((prev) => [...prev, { label: 'Password', value: 'Password' }]);
  //   }
  // }, [response, loading, error, userInfo, uLoading]);

  const [sectionState, setSectionState] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (screenState == 'KYC') {
      setSectionState({ label: 'KYC', value: 'KYC' });
    } else {
      setSectionState(tabData[0]);
    }
  }, []);

  const renderTab = () => {
    if (sectionState) {
      if (sectionState.value === 'Profile') {
        return <ProfileTab />;
      } else if (sectionState.value === 'Help and Feedback') {
        return <HelpAndFeedBack setOpenFeedBackPopup={setOpenFeedBackPopup} />;
      }
    }
  };

  useEffect(() => {
    if (analytics) {
      sendTrackEvent('tabSelected', { tabName: sectionState.label });
    }
  }, [analytics, sectionState]);

  // useEffect(() => {
  //   analytics?.track('Clicked settings tab', {
  //     from: 'Settings Tab',
  //     uid: userInfo?.payload?.uid,
  //     keyword: sectionState?.label,
  //     QueryParams: { ...getQueryParams() },
  //     ip: ip,
  //   });
  // }, [analytics, sectionState]);

  useEffect(() => {
    return () =>
      dispatch({
        type: SETTING_CLEANUP,
      });
  }, []);

  useEffect(() => {
    if (!userData) {
      dispatch(userDetails());
    }
  }, [userData]);

  const location = useLocation();
  const history = useHistory();

  const handleRemoveRedirect = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('redirect_to')) {
      queryParams.delete('redirect_to');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  return (
    <>
      <HelmetContainer title='Settings' />
      <div className='mfp-bg mfp-zoom-in mfp-ready'></div>
      <Toast />
      <div
        className='mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-zoom-in mfp-ready'
        style={{ overflow: 'hidden auto' }}
      >
        <div className='mfp-container mfp-s-ready mfp-inline-holder'>
          <div className='mfp-content'>
            <div
              className='popup popup_settings static__height'
              id='popup-settings'
            >
              <div className='popup__title h6'>Account Settings</div>
              <div className='popup__tabs js-tabs'>
                <div className={`${isdark ? 'dark' : ''}`}>
                  <div className='popup__select mobile-show setting_select'>
                    <CustomSelect
                      defaultValue={
                        screenState === 'KYC'
                          ? { label: 'KYC', value: 'KYC' }
                          : sectionState
                          ? sectionState
                          : tabData[0]
                      }
                      options={tabData}
                      onChange={(data) => setSectionState(data)}
                    />
                  </div>
                </div>
                <div className='popup__nav mobile-hide'>
                  {tabData.map((item, idx) => (
                    <button
                      className={`popup__link js-tabs-link ${
                        item.label === sectionState?.label ? 'active' : ''
                      } `}
                      onClick={() => setSectionState(item)}
                      key={idx}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                <div className='popup__select mobile-show'></div>
                <div className='popup__container'>{renderTab()}</div>
              </div>
              <button
                title='Close (Esc)'
                type='button'
                className='mfp-close'
                onClick={() => {
                  dispatch(toggleSetting());
                  setSectionState(tabData[0]);
                  handleRemoveRedirect();
                }}
              >
                ×
              </button>
            </div>
          </div>
          <div className='mfp-preloader'>Loading...</div>
        </div>
      </div>
    </>
  );
}

export default Settings;
