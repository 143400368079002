import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { HelmetProvider } from 'react-helmet-async';
import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { ErrorBoundary } from 'react-error-boundary';
import CrashFallback from './components/common/crash-fallback/crash-fallback';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from '@web3-react/core';
import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from './utils/connectors-v8/coinbaseWallet';
import {
  hooks as metaMaskHooks,
  metaMask,
} from './utils/connectors-v8/metaMask';
import {
  hooks as walletConnectHooks,
  walletConnect,
} from './utils/connectors-v8/walletConnect';
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from './utils/connectors-v8/walletConnectV2';
import { StarknetConfig } from '@starknet-react/core';
import { connectors as starkConnectors } from './utils/starknetConfig';

// function getLibrary(provider) {
//   const library = new ethers.providers.Web3Provider(provider);
//   library.pollingInterval = 12000;
//   return library;
// }

let DSN =
  'https://a82759ba67ae46489ef04ace0cdfe178@o4505078945742848.ingest.sentry.io/4505078970253312'; // todo: move this to .env

Sentry.init({
  dsn: DSN,
  integrations: [new BrowserTracing()],
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'testnet',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  sampleRate: 0.25,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
  ],
});

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={CrashFallback}>
      <StarknetConfig
        connectors={starkConnectors}
        // defaultProvider={...}
        autoConnect={true}
      >
        <Web3ReactProvider connectors={connectors}>
          <Provider store={store}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Provider>
        </Web3ReactProvider>
      </StarknetConfig>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
