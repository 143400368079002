import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNonce,
  storeKey,
  storeUserViaMethod,
  walletLogin,
} from '../../../actions/userAction';
import { toast } from 'react-toastify';
import { useMeta, useSegment, useWalletMethods } from '../../../utils/hooks';
import { ethers } from 'ethers';
import { USER_LOGIN_RESET } from '../../../constants/userConstant';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import { USER_ADD_KEY_CLEANUP } from '../../../constants/userConstant';
import { useWeb3React } from '@web3-react/core';
import { useEagerConnect, useInactiveListener } from '../../../utils/hooks';
import { walletconnect } from '../../../utils/connectors/connectors';
import { Image, WalletLoginInfo, WalletLoginInfoV2 } from '../../../components';
import {
  ALLOWED_CHAIN_ID,
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  staticMsg,
} from '../../../utils/constants';
import { walletConnectV2 } from '../../../utils/connectors-v8/walletConnectV2';
import { URI_AVAILABLE } from '@web3-react/walletconnect-v2';
import { images } from '../../../utils/image/img';

function WalletConnectLoginV2({
  history,
  setSelectedOption,
  loginState,
  setLoginState,
}) {
  const [openInstructionPopUp, setOpenInstructionPopUp] = useState(false);
  const dispatch = useDispatch();
  const { connectWallet, connectedAccount } = useMeta();
  const [stateLoading, setStateLoading] = useState(false);
  const [clickedVerfiy, setClickedVerfiy] = useState(false);
  const [signedKey, setSignedKey] = useState(null);
  const [connectedSignature, setConnectSignature] = useState(null);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [openNetworkPrompt, setOpenNetworkPrompt] = useState(false);
  const {
    signMessageL1,
    METAMASK_POSSIBLE_ERRORS,
    provider: providerMeta,
    get0X0to0X,
  } = useMeta();
  // const {
  //   userKey: { state },
  // } = useSelector((state) => state);
  const { state } = useSelector((state) => state.userKey);
  const { walletDeactivate } = useWalletMethods();
  const context = useWeb3React();
  const {
    connector,
    provider: library,
    chainId,
    account,
    activate,
    deactivate,
    connectorName,
    active,
    error: contextError,
  } = context;
  const [activatingConnector, setActivatingConnector] = useState();
  const { analytics, sendTrackEvent } = useSegment();

  useEffect(() => {
    if (
      activatingConnector &&
      activatingConnector === connector &&
      library?.connection?.url !== 'metamask'
    ) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo: userLoginInfo } = userLogin;

  const handleSubmit = (signature) => {
    networkCheck();
    sendTrackEvent('finalLoginRequestInitiated', {
      walletType: 'wallet_connect_v2',
      address: account,
    });
    dispatch(walletLogin(account, signature, get0X0to0X(signedKey), state));
  };

  const handleLoginWithReferral = () => {
    sendTrackEvent('finalLoginRequestInitiated', {
      walletType: 'wallet_connect_v2',
      address: account,
    });
    dispatch(
      walletLogin(
        account,
        connectedSignature,
        get0X0to0X(signedKey),
        state,
        referralCode
      )
    );
  };

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const getNonceAPIcall = async (account) => {
    sendTrackEvent('loginNonceRequestInitiated', {
      walletType: 'wallet_connect_v2',
      address: account,
    });
    try {
      const data = await getNonce(account);
      sendTrackEvent('loginNonceRequestSuccess', {
        walletType: 'wallet_connect_v2',
        address: account,
      });
      return data;
    } catch (error) {
      sendTrackEvent('loginNonceRequestFailure', {
        walletType: 'wallet_connect_v2',
        address: account,
        error: error,
      });
      toast.error(error ? error : 'Something went wrong');
      setStateLoading(false);
    }
  };

  const handleGetNonce = async () => {
    if (!clickedVerfiy) {
      return toast.error('Click the toggle button to verify you’re human.');
    }

    dispatch({
      type: USER_LOGIN_RESET,
    });

    try {
      setStateLoading(true);
      const data = await getNonceAPIcall(account);

      sendTrackEvent('secondMessageSignRequestInitiated', {
        walletType: 'wallet_connect_v2',
        address: account,
      });

      const sig = await signMsg(data.payload);
      if (sig) {
        sendTrackEvent('secondMessageSignRequestSuccess', {
          walletType: 'wallet_connect_v2',
          address: account,
        });
        handleSubmit(sig);
        setConnectSignature(sig);
        // console.log(sig);
      }
      setStateLoading(false);
    } catch (error) {
      sendTrackEvent('secondMessageSignRequestFailure', {
        walletType: 'wallet_connect_v2',
        address: account,
        error: error,
      });
      toast.error(error ? error : 'Something went wrong');
      setStateLoading(false);
    }
  };

  const signMsg = (msg) => {
    if (!!(library && account)) {
      return library.getSigner(account).signMessage(msg);
    }
  };

  useEffect(() => {
    walletConnectV2.connectEagerly(ALLOWED_NETWORK_ID).catch((error) => {
      console.debug('Failed to connect eagerly to walletconnect', error);
    });
  }, []);

  useEffect(() => {
    walletConnectV2.events.on(URI_AVAILABLE, (uri) => {
      console.log(`uri: ${uri}`);
    });
  }, []);

  const connectWalletConnect = async () => {
    console.log('disconnect');
    // localStorage.clear();
    try {
      if (library?.connection?.url !== 'metamask') {
        sendTrackEvent('brineWalletConnectionInitiated', {
          walletType: 'wallet_connect_v2',
        });
        console.log('connecting wallet');
        // setActivatingConnector(walletconnect);
        walletConnectV2
          .activate()
          .then(() => {
            sendTrackEvent('brineWalletConnectionSuccess', {
              walletType: 'wallet_connect_v2',
            });
          })
          .catch((error) => {
            sendTrackEvent('brineWalletConnectionFailure', {
              error: error,
              walletType: 'wallet_connect_v2',
            });
            // if (
            //   error.name === 'UnsupportedChainIdError' ||
            //   error.message?.search(/Unsupported chain/i) !== -1
            // ) {
            //   setOpenNetworkPrompt(true);
            // }
            // setActivatingConnector(undefined);
            // if (error.message === 'The user rejected the request.') {
            //   setTimeout(() => {
            //     window.location.reload();
            //   }, 1000);
            // }
          });
        // activate(walletConnectV2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (clickedVerfiy) {
      dispatch({
        type: USER_ADD_KEY_CLEANUP,
      });
      if (!(library && account)) {
        connectWalletConnect();
        setClickedVerfiy(false);
      } else {
        networkCheck();
        sendTrackEvent('brineWalletConnectionSuccess', {
          walletType: 'wallet_connect_v2',
        });
        sendTrackEvent('walletFirstSignRequestInitiated', {
          walletType: 'wallet_connect_v2',
          address: account,
        });
        signMsg(staticMsg)
          .then((data) => {
            toast.dismiss('meta_first_open');
            dispatch(storeKey(data));
            let stark_private_key = getKeyPairFromSignature(data).getPrivate();
            let stark_public_key = getKeyPairFromSignature(data)
              .getPublic()
              .getX();
            // console.log(`Private Key: ${stark_private_key}`);
            // console.log(`Public Key: ${stark_public_key}`);
            const finalKey = ethers.BigNumber.from(
              stark_public_key.toString()
            ).toHexString();

            setSignedKey(finalKey);
            sendTrackEvent('walletFirstSignRequestSuccess', {
              walletType: 'wallet_connect_v2',
              address: account,
            });
          })
          .catch((e) => {
            toast.dismiss('meta_first_open');
            toast.error(METAMASK_POSSIBLE_ERRORS[e.code]?.message);
            setClickedVerfiy(false);
            sendTrackEvent('brineWalletConnectionFailure', {
              walletType: 'wallet_connect_v2',
              error: e,
              address: account,
            });
            sendTrackEvent('walletFirstSignRequestFailure', {
              walletType: 'wallet_connect_v2',
              address: account,
              error: e,
            });
          });
      }
    }
  }, [clickedVerfiy]);

  useEffect(() => {
    if (error && error !== 'Please enter referral code') {
      toast.error(error);
      setStateLoading(false);
      sendTrackEvent('finalLoginRequestFailure', {
        walletType: 'wallet_connect_v2',
        address: account,
        error: error,
      });
    }
    if (error === 'Please enter referral code') {
      setOpenLoginPopup(true);
    }
    if (userLoginInfo && userLoginInfo.token) {
      sendTrackEvent('finalLoginRequestSuccess', {
        walletType: 'wallet_connect_v2',
        address: account,
      });
      history.push('/');
      dispatch(
        storeUserViaMethod({
          via: 'wallet_connect_v2',
          address: account,
        })
      );
      setStateLoading(false);
    }
  }, [userLoginInfo, history, error]);

  useEffect(() => {
    if (library?.connection?.url === 'metamask') {
      // disconnect();
      // providerMeta.disconnect();
      walletDeactivate();
    }
  }, [library]);

  useEffect(() => {
    if (signedKey && clickedVerfiy) {
      handleGetNonce();
    }
  }, [signedKey, clickedVerfiy]);

  const networkCheck = () => {
    if (chainId && chainId !== ALLOWED_NETWORK_ID && !openNetworkPrompt) {
      // Handle unsupported chainId
      // For example, display an error message to the user
      setClickedVerfiy(false);
      setSignedKey(null);
      setOpenNetworkPrompt(true);
      return;
      // Alternatively, you can automatically disconnect the user's wallet by calling:
      // deactivate();
    } else {
      setOpenNetworkPrompt(false);
    }
  };

  useEffect(() => {
    networkCheck();
  }, [chainId]);

  return (
    <WalletLoginInfoV2
      setLoginState={setLoginState}
      setSelectedOption={setSelectedOption}
      deactivate={deactivate}
      dispatch={dispatch}
      WalletImg={images.walletConnectV2}
      WalletImageComponent={
        <Image
          src={images.walletConnectV2}
          alt=''
          style={{ margin: '24px 0px' }}
        />
      }
      account={account}
      clickedVerfiy={clickedVerfiy}
      setClickedVerfiy={setClickedVerfiy}
      setOpenNetworkPrompt={setOpenNetworkPrompt}
      openNetworkPrompt={openNetworkPrompt}
      loading={loading}
      stateLoading={stateLoading}
      library={library}
      handleGetNonce={handleGetNonce}
      connectWalletLink={connectWalletConnect}
      setOpenInstructionPopUp={setOpenInstructionPopUp}
      openInstructionPopUp={openInstructionPopUp}
      openLoginPopup={openLoginPopup}
      setOpenLoginPopup={setOpenLoginPopup}
      referralCode={referralCode}
      setReferralCode={setReferralCode}
      handleLoginWithReferral={handleLoginWithReferral}
      signedKey={signedKey}
    />
  );
}

export default withRouter(WalletConnectLoginV2);
