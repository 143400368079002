import Popup from '../popup/popup';
import Image from '../img/image';
import { images } from '../../../utils/image/img';

function LockedBalance({ state, setState }) {
  return (
    <div className='share__popup network__error lock__balance'>
      <Popup state={state} setState={setState}>
        <div className='h6'>What is locked balance?</div>
        <Image src={images.lockBalance} />
        <p>
          These amount of tokens that are currently unavailable for creating
          orders or withdrawals, as they are currently in use for limit orders
          that haven’t executed yet.{' '}
        </p>
      </Popup>
    </div>
  );
}

export default LockedBalance;
