import ExchangeBanner from '../exchange-banner/exchange-banner';
import ExchangeCell from '../exchange-table';
import {
  ExchangeAction,
  ExchangeMarket,
  ExchangeFooter,
  OrderBook,
  Image,
  SkeletonContainer,
} from '../../../components';
import { useWebSocketData } from '../../../utils/hooks';

export default function BeginnerMode({
  coinsLoading,
  loading,
  assetsData,
  selectedtab,
  setSelectedCell,
  selectedCell,
  selectedOption,
  roundUp,
  ip,
  setSelectedOption,
  symbolOfCoins,
  tradeMode,
  setTradeMode,
  fetchOrderTable,
  setFetchOrderTable,
  // analytics,
  userInfo,
  getQueryParams,
  investedData,
  investedAmount,
  currentUsdtAmount,
  InvestedIcon,
  formatCurrency,
  parseFloat,
  percentage,
  CurrentIcon,
  setSelectedTab,
  orderBookPrice,
  setOrderBookPrice,
}) {
  const { getSocketFunctionalityInfo } = useWebSocketData();
  return (
    <div className='page__container page__container_exchange'>
      <div className='exchange'>
        {coinsLoading ||
        loading ||
        (getSocketFunctionalityInfo().full &&
          getSocketFunctionalityInfo().partial) ? (
          <SkeletonContainer
            width='100%'
            height='120px'
            className='box__shadow exchange__top'
            style={{
              marginBottom: 35,
              borderRadius: 20,
            }}
          />
        ) : (
          <ExchangeCell
            selectedtab={selectedtab}
            setSelectedCell={setSelectedCell}
            selectedCell={selectedCell}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
        <div className='exchange__row'>
          <div className='exchange__container'>
            <ExchangeMarket
              params={symbolOfCoins}
              selectedtab={selectedtab}
              selectedCell={selectedCell}
              tradeMode={tradeMode}
              setTradeMode={setTradeMode}
              fetchOrderTable={fetchOrderTable}
              setFetchOrderTable={setFetchOrderTable}
            />
          </div>
          <div className='exchange__sidebar'>
            {selectedCell?.symbol && tradeMode && (
              <OrderBook
                selectedtab={selectedtab}
                selectedCell={selectedCell}
                orderBookPrice={orderBookPrice}
                setOrderBookPrice={setOrderBookPrice}
              />
            )}
            {coinsLoading || (loading && !assetsData) ? (
              <SkeletonContainer
                width='100%'
                height='320px'
                className='box__shadow'
                style={{
                  marginBottom: 35,
                  borderRadius: 20,
                }}
              />
            ) : (
              <ExchangeAction
                selectedtab={selectedtab}
                setSelectedCell={setSelectedCell}
                selectedCell={selectedCell}
                tradeMode={tradeMode}
                setTradeMode={setTradeMode}
                fetchOrderTable={fetchOrderTable}
                setFetchOrderTable={setFetchOrderTable}
                orderBookPrice={orderBookPrice}
                setOrderBookPrice={setOrderBookPrice}
              />
            )}
            {!tradeMode ? (
              <ExchangeBanner
                title='Trading & Asset Updates'
                date='November 2020'
                btnValue='Explore Now'
                link={'/pricedetails/eth'}
                // onClick={() => {
                //   analytics?.track('Explore Now button clicked', {
                //     coinsymbol: symbolOfCoins,
                //     uid: userInfo?.payload?.uid,
                //     from: 'Exchange page',
                //     QueryParams: { ...getQueryParams() },
                //     ip: ip,
                //   });
                // }}
              />
            ) : (
              <>
                {(coinsLoading || loading) && !assetsData ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SkeletonContainer
                      width={'150px'}
                      height={'150px'}
                      style={{
                        borderRadius: 40,
                        marginBottom: 20,
                      }}
                      className='details__container'
                    />
                    <SkeletonContainer
                      width={'150px'}
                      height={'150px'}
                      style={{
                        borderRadius: 40,
                        marginBottom: 20,
                        marginLeft: 20,
                      }}
                      className='details__container'
                    />
                  </div>
                ) : (
                  <div className='pro_mode_portfolio_container'>
                    {selectedtab == 'market__tabs' && (
                      <>
                        {userInfo?.token ? (
                          <div className='card'>
                            <div className='card__head'>
                              <div className='card__title h6'>Portfolio</div>
                            </div>
                            {investedData &&
                              !isNaN(investedAmount) &&
                              !isNaN(currentUsdtAmount) && (
                                <div className='pro_mode_invested_container'>
                                  <div className='pro_mode_invested_item'>
                                    <div className='pro_mode_invested_image'>
                                      <Image src={InvestedIcon} alt='' />
                                    </div>
                                    <h3>Invested</h3>
                                    <h4>
                                      {formatCurrency(
                                        Number(investedAmount)?.toFixed(3)
                                      )}
                                    </h4>
                                    <h5
                                      className={
                                        parseFloat(currentUsdtAmount) <
                                        parseFloat(investedAmount)
                                          ? 'negative'
                                          : 'positive'
                                      }
                                    >
                                      Return{' '}
                                      {investedData.balance == 0.0 ||
                                      currentUsdtAmount === '0'
                                        ? `0%`
                                        : percentage(
                                            investedAmount,
                                            currentUsdtAmount
                                          )}
                                    </h5>
                                  </div>
                                  <div
                                    className='pro_mode_invested_item'
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div className='pro_mode_invested_image'>
                                      <Image src={CurrentIcon} />
                                    </div>
                                    <h3>Current</h3>
                                    <h4>
                                      {formatCurrency(
                                        Number(currentUsdtAmount)?.toFixed(3)
                                      )}
                                    </h4>
                                    <h5
                                      className={
                                        parseFloat(currentUsdtAmount) <
                                        parseFloat(investedAmount)
                                          ? 'negative'
                                          : 'positive'
                                      }
                                    >
                                      Total{' '}
                                      {roundUp(
                                        currentUsdtAmount < investedAmount
                                      )
                                        ? 'Loss'
                                        : 'Profit'}{' '}
                                      {currentUsdtAmount == 0 ||
                                      investedAmount == 0 ||
                                      isNaN(investedAmount) ||
                                      isNaN(currentUsdtAmount)
                                        ? '0'
                                        : formatCurrency(
                                            Number(
                                              roundUp(
                                                currentUsdtAmount -
                                                  investedAmount
                                              )
                                                ?.toString()
                                                ?.replace('-', '')
                                            )
                                          )}
                                    </h5>
                                  </div>
                                  {/* <DetailsBox
                    img={InvestedIcon}
                    hasBalance={true}
                    title="Invested"
                    subTitle={`Return ${
                    investedData.balance == 0.0 ||
                    currentUsdtAmount === '0'
                    ? `0%`
                    : percentage(investedAmount, currentUsdtAmount)
                    }`}
                    description="Total investment made in this asset (In USDT) "
                    subTitleStatus={`${
                    parseFloat(currentUsdtAmount) <
                    parseFloat(investedAmount)
                    ? 'negative'
                    : 'positive'
                    }`}
                    value={formatCurrency(investedAmount?.toFixed(3))}
                    />
                    <DetailsBox
                    img={CurrentIcon}
                    title="Current"
                    description="Present value of this asset held in your wallet (In USDT)"
                    hasBalance={true}
                    subTitle={` Total Return ${
                    roundUp(currentUsdtAmount < investedAmount)
                    ? ' '
                    : ' +'
                    }${roundUp(currentUsdtAmount - investedAmount)}`}
                    subTitleStatus={`${
                    parseFloat(currentUsdtAmount) <
                    parseFloat(investedAmount)
                    ? 'negative'
                    : 'positive'
                    }`}
                    value={formatCurrency(
                    Number(currentUsdtAmount)?.toFixed(3)
                    )}
                    /> */}
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className='card'>
                            <div className='card__head'>
                              <div className='card__title h6'>Portfolio</div>
                            </div>
                            <div className='pro_mode_invested_container'>
                              <div className='pro_mode_invested_item'>
                                <div className='pro_mode_invested_image'>
                                  <Image src={InvestedIcon} alt='' />
                                </div>
                                <h3>Invested</h3>
                                <h4>---</h4>
                                <h5 className={'positive'}>Return ---</h5>
                              </div>
                              <div
                                className='pro_mode_invested_item'
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                <div className='pro_mode_invested_image'>
                                  <Image src={CurrentIcon} />
                                </div>
                                <h3>Current</h3>
                                <h4>---</h4>
                                <h5 className={'positive'}>Total ---</h5>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <ExchangeFooter
          selectedtab={selectedtab}
          setSelectedTab={setSelectedTab}
          tradeMode={tradeMode}
          setTradeMode={setTradeMode}
        />
      </div>
    </div>
  );
}
