import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useBalance,
  useWebSocketData,
  useViewport as useViewPort,
  useSegment,
} from '../../../../utils/hooks';
import { SkeletonContainer } from '../../../../components';
import { getTenPendingOrderData } from '../../../../actions/historyAction';
import ReconnectingWebSocket from 'reconnecting-websocket';
import ReactTooltip from 'react-tooltip';
import { webSocketUrl } from '../../../../utils/urls';

const FlexEndWrapper = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

function OrderTable({
  selectedCell,
  tradeMode,
  orderBookPrice,
  setOrderBookPrice,
  selectedtab,
}) {
  const { symbol } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [orderbids, setOrderBids] = useState([]);
  const [orderasks, setOrderAsks] = useState([]);
  const { width, height } = useViewPort();
  const { lastPrice, getStatus } = useWebSocketData();
  const { formatCurrency } = useBalance();
  const [pendingOrderData, setPendingOrderData] = useState([]);
  const sellOrderContainerRef = React.useRef();
  const buyOrderContainerRef = React.useRef();
  const [idxofOrderAsks, setIdxofOrderAsks] = useState('');
  const [toolTipAskData, setToolTipAskData] = useState(null);
  const [idxofOrderBids, setIdxofOrderBids] = useState('');
  const [toolTipBidData, setToolTipBidData] = useState(null);
  // const [isSellOrderBookScrollable, setIsSellOrderBookScrollable] =
  //   useState('not-started');
  // const {
  // getOrderBook: { loading, error, orderBookData, reRender },
  // userLogin: { userInfo },
  // notificationDetails: { notificationData },
  // } = useSelector((state) => state);
  const { analytics, sendTrackEvent } = useSegment();
  const { isdark } = useSelector((state) => state.theme);
  const { loading, error, orderBookData, reRender } = useSelector(
    (state) => state.getOrderBook
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const { notificationData } = useSelector(
    (state) => state.notificationDetails
  );

  const [isUserInteract, setIsUserInteract] = useState(false);

  useEffect(() => {
    if (selectedCell.symbol && orderBookData) {
      setOrderData(orderBookData[selectedCell?.symbol + symbol?.split('-')[1]]);
    }
  }, [selectedCell.symbol, orderBookData, reRender, symbol]);

  useEffect(() => {
    getHighestBidsValue();
    getHighestAsksValue();
    // ReactTooltip.rebuild();
  }, [selectedCell.symbol, orderData, reRender, symbol]);

  useEffect(() => {
    if (sellOrderContainerRef?.current || buyOrderContainerRef?.current) {
      ReactTooltip.rebuild();
    }
  }, [
    sellOrderContainerRef?.current,
    buyOrderContainerRef?.current,
    symbol,
    orderData,
    idxofOrderAsks,
    idxofOrderBids,
    reRender,
  ]);

  function percentage(partialValue, totalValue) {
    const value = (100 * partialValue) / totalValue;
    return value?.toFixed(2);
  }

  function removeItemFromList(arr, value) {
    arr.splice(
      arr.findIndex(function (i) {
        return i.id === value;
      }),
      1
    );
    return arr;
  }

  // red - Lowest top ()
  // green - highest top (bids)

  function getHighestBidsValue() {
    // green list
    // const newArr = orderData?.bids?.splice(0, 5);
    const newArr = orderData;
    let data;
    // i) {
    data = newArr?.bids?.sort((a, b) => b[0] - a[0]);
    // } else {
    //   data = newArr?.bids?.sort((a, b) => b[0] - a[0])?.slice(0, 5);
    // }
    let cumulativeData = [];
    let totalAmount = 0;

    for (let index = 0; index < data?.length; index++) {
      const amount = data[index][1];
      const price = data[index][0];
      totalAmount += Number(amount);
      cumulativeData.push([amount, price, totalAmount]);
    }

    // const orderWithPer = data?.map((item) => {
    //   let lastElement = data[data?.length - 1];

    //   return {
    //     data1: item[0],
    //     data2: item[1],
    //     per: percentage(item[0] - data[0][0], lastElement[0] - data[0][0]),
    //     per: percentage(item[0] - data[0][0], lastElement[0] - data[0][0]),
    //   };
    // }

    const orderWithPer = cumulativeData?.map((item) => {
      return {
        data1: item[1],
        data2: item[0],
        per: percentage(item[2], totalAmount),
      };
    });

    setOrderBids(orderWithPer);
  }

  function getHighestAsksValue() {
    // red list
    const newArr = orderData;
    let data;
    // i) {
    data = newArr?.asks?.sort((a, b) => b[0] - a[0]);
    // } else {
    //   data = newArr?.asks?.sort((a, b) => b[0] - a[0])?.slice(0, 5);
    // }
    let cumulativeData = [];
    let totalAmount = 0;

    for (let index = data?.length - 1; index >= 0; index--) {
      const amount = data[index][1];
      const price = data[index][0];
      totalAmount += Number(amount);
      cumulativeData.push([amount, price, totalAmount]);
    }

    const orderWithPer = cumulativeData?.map((item) => {
      return {
        data1: item[1],
        data2: item[0],
        per: percentage(item[2], totalAmount),
      };
    });

    setOrderAsks(orderWithPer);
  }

  const getPendingOrders = async () => {
    try {
      const data = await getTenPendingOrderData(
        userInfo.token.access,
        1,
        symbol.replace('-', ''),
        true
      );
      setPendingOrderData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const estConnnectionWs = () => {
  //   let ws = new ReconnectingWebSocket(
  //     `${webSocketUrl}/private?auth_header=${userInfo?.token?.access}`
  //   );
  //   setWs(ws);
  // };

  // const connect = () => {
  //   if (ws) {
  //     ws.onopen = function () {
  //       //Subscribe to the channel
  //       ws.send(
  //         JSON.stringify({ event: 'subscribe', streams: ['trade', 'order'] })
  //       );
  //     };

  //     ws.onmessage = function (msg) {
  //       let res = JSON.parse(msg.data);
  //       if (res?.order?.market === symbol?.replace('-', '')) {
  //         if (pendingOrderData?.length === 0) {
  //           getPendingOrders();
  //         }
  //         if (res?.order?.state === 'wait') {
  //           let tempData = pendingOrderData;
  //           tempData?.unshift(res?.order);
  //           const uniqData = _.uniqBy(tempData, 'id');
  //           setPendingOrderData(uniqData);
  //         } else if (res?.order?.state === 'cancel') {
  //           let arr = removeItemFromList(pendingOrderData, res?.order?.id);
  //           setPendingOrderData(arr);
  //         }
  //       }

  //       ws.onclose = () => {
  //         setTimeout(() => {
  //           connect();
  //         }, 3000);
  //       };

  //       ws.onerror = (err) => {
  //         console.error(
  //           'Socket encountered error: ',
  //           err.message,
  //           'Closing socket'
  //         );
  //         setTimeout(() => {
  //           connect();
  //         }, 3000);
  //       };

  //       ws.onerror = function (err) {
  //         console.log(err);
  //       };
  //     };
  //   }
  // };

  const updateTradeOrderData = () => {
    let res = notificationData;
    if (res?.order?.market === symbol?.replace('-', '')) {
      if (pendingOrderData?.length === 0) {
        getPendingOrders();
      }
      if (res?.order?.state === 'wait') {
        let tempData = pendingOrderData;
        tempData?.unshift(res?.order);
        const uniqData = _.uniqBy(tempData, 'id');
        setPendingOrderData(uniqData);
      } else if (res?.order?.state === 'cancel') {
        let arr = removeItemFromList(pendingOrderData, res?.order?.id);
        setPendingOrderData(arr);
      }
    }
  };

  useEffect(
    () => {
      if (userInfo?.token) {
        getPendingOrders();
      }
      // estConnnectionWs();
    },
    [symbol],
    userInfo?.token
  );

  useEffect(() => {
    if (notificationData) {
      updateTradeOrderData();
    }
  }, [notificationData]);

  // useEffect(() => {
  //   connect();
  // }, [userInfo, pendingOrderData, ws]);

  const checkMine = (side, price, amount) => {
    if (pendingOrderData) {
      let status = false;
      pendingOrderData?.forEach((data) => {
        if (
          price == data.price &&
          data.side == side &&
          data.market == symbol.replace('-', '')
        ) {
          status = true;
        }
      });
      return status;
    }
  };

  useEffect(() => {
    if (sellOrderContainerRef.current && !isUserInteract) {
      sellOrderContainerRef.current.scrollTop =
        sellOrderContainerRef.current.scrollHeight;
    }
  }, [sellOrderContainerRef, orderasks, selectedtab, isUserInteract]);

  useEffect(() => {
    if (sellOrderContainerRef && sellOrderContainerRef?.current) {
      let lastScrollTop = 0;
      sellOrderContainerRef.current.onscroll = (e) => {
        if (sellOrderContainerRef.current?.scrollTop < lastScrollTop) {
          setIsUserInteract(true);
          return;
        }
        lastScrollTop =
          sellOrderContainerRef.current?.scrollTop <= 0
            ? 0
            : sellOrderContainerRef.current?.scrollTop;
        if (
          sellOrderContainerRef.current?.scrollTop +
            sellOrderContainerRef.current?.offsetHeight >=
          sellOrderContainerRef.current?.scrollHeight
        ) {
          setIsUserInteract(false);
        }
      };
    }
  }, [sellOrderContainerRef, orderasks, orderData]);

  useEffect(() => {
    if (analytics && orderasks?.length && orderbids?.length) {
      // asks - buy
      // bids - sell
      // sendTrackEvent('buySellSpreadUpdated', {});
      let lowestAskPrice = orderasks[0]?.data1;
      let highestBidValue = orderbids[0]?.data1;
      let spread = (lowestAskPrice - highestBidValue) / lowestAskPrice;

      const limitedAskData = orderasks.slice(0, 20);
      const limitedBidData = orderbids.slice(0, 20);

      // let totalBuyAmount = 0;
      // let totalSellAmount = 0;

      // limitedAskData.forEach((item) => {
      //   const amount = parseFloat(item.data1);
      //   const quantity = parseFloat(item.data2);
      //   totalBuyAmount += amount * quantity;
      // });

      let totalBuyAmount = limitedAskData.reduce(
        (a, c) => a + c.data1 * c.data2,
        0
      );

      let totalSellAmount = limitedBidData.reduce(
        (a, c) => a + c.data1 * c.data2,
        0
      );

      // limitedBidData.forEach((item) => {
      //   const amount = parseFloat(item.data1);
      //   const quantity = parseFloat(item.data2);
      //   totalSellAmount += amount * quantity;
      // });

      sendTrackEvent('buySellSpreadUpdated', {
        highestBidValue,
        lowestAskPrice,
        spread,
        totalBuyAmount,
        totalSellAmount,
        market: symbol,
        lastPrice: lastPrice(symbol?.replace('-', '')),
      });
    }
  }, [analytics, orderbids, orderasks]);

  useEffect(() => {
    setIsUserInteract(false);
  }, [symbol]);

  function calculateAverge(array) {
    let total = 0;
    let divider = 0;
    // Amount1 x price1 + Amount2 x price2 / Amount1 + Amount2
    if (array.length) {
      array.forEach(function (item, _) {
        total = Number(item?.data1) * Number(item?.data2) + total;
        divider = Number(item?.data2) + divider;
      });
      return (total / divider).toFixed(3);
    }
  }

  const getAvergeAsks = (index) => {
    let asksData = orderasks?.slice(0, Number(index) + 1);
    let price = 0;
    let amount = 0;

    asksData.forEach((item) => {
      price = Number(item?.data1) + price;
      amount = Number(item?.data2) + amount;
    });

    return {
      sumPrice: Number(calculateAverge(asksData) * amount).toFixed(3),
      sumAmount: amount.toFixed(3),
      avgPrice: calculateAverge(asksData),
    };
  };

  const getAvergeBids = (index) => {
    let bidsData = orderbids?.slice(0, Number(index) + 1);
    let price = 0;
    let amount = 0;

    bidsData.forEach((item) => {
      price = Number(item?.data1) + price;
      amount = Number(item?.data2) + amount;
    });

    return {
      sumPrice: Number(calculateAverge(bidsData) * amount).toFixed(3),
      sumAmount: amount.toFixed(3),
      avgPrice: calculateAverge(bidsData),
    };
  };

  useEffect(() => {
    if (
      idxofOrderAsks !== '' &&
      idxofOrderAsks !== null &&
      idxofOrderAsks !== undefined
    ) {
      let d = getAvergeAsks(idxofOrderAsks);
      setToolTipAskData(d);
    }
  }, [idxofOrderAsks, orderasks, symbol]);

  useEffect(() => {
    if (
      idxofOrderBids !== '' &&
      idxofOrderBids !== null &&
      idxofOrderBids !== undefined
    ) {
      let d = getAvergeBids(idxofOrderBids);
      setToolTipBidData(d);
    }
  }, [idxofOrderBids, orderbids, symbol]);

  useEffect(() => {
    return () => {
      setIdxofOrderBids('');
      setIdxofOrderAsks('');
      setToolTipBidData(null);
      setToolTipAskData(null);
    };
  }, [symbol]);

  // useEffect(() => {
  //   ReactTooltip.hide();
  //   ReactTooltip.rebuild();
  // }, [orderData]);

  // const isScrollable = function (ele) {
  //   if (ele) {
  //     const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
  //     const overflowYStyle = window.getComputedStyle(ele).overflowY;
  //     const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  //     return hasScrollableContent && !isOverflowHidden;
  //   }
  // };

  // useEffect(() => {
  //   if (sellOrderContainerRef?.current) {
  //     setIsSellOrderBookScrollable('not-started');
  //     setTimeout(() => {
  //       setIsSellOrderBookScrollable(
  //         isScrollable(sellOrderContainerRef?.current)
  //       );
  //     }, 0);
  //   }
  // }, [width, sellOrderContainerRef?.current, height, symbol]);

  // console.log({ idxofOrderAsks, idxofOrderBids });

  return (
    <>
      {loading || !orderData || !orderBookData || !orderbids ? (
        <SkeletonContainer
          height={width > 750 ? height : 400}
          width={'100%'}
          style={{ borderRadius: '10px' }}
        />
      ) : (
        <div className='orders__table'>
          <div className='orders__row orders__row_head order__asks'>
            <div className='orders__cell'>AMOUNT ({selectedCell.symbol})</div>
            <div className='orders__cell'>
              PRICE ({symbol?.split('-')[1].toUpperCase()})
            </div>
            {/* <div className="orders__cell">MINE</div> */}
          </div>
          <div
            className='table_height_control order_table order_table_asks_container'
            ref={sellOrderContainerRef}
            onMouseOver={() => console.log('')}
            onMouseOut={() => {
              setIdxofOrderBids('');
              setIdxofOrderAsks('');
              // setToolTipBidData(null);
              // setToolTipAskData(null);
              // alert('eee');
            }}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <ReactTooltip
              place='right'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles order_tooltip'
              id={'avg_price_ask'}
              effect='solid'
              getContent={(dataTip) => {
                // sumPrice: price.toFixed(2),
                // sumAmount: amount.toFixed(2),
                // avgPrice: calculateAverge(asksData),
                // console.log({ dataTip });
                // if (dataTip) {
                //   setIdxofOrderAsks(dataTip);
                // }
                return (
                  <div>
                    <ul>
                      <li>
                        Avg.Price ≈{' '}
                        {toolTipAskData === null
                          ? '---'
                          : toolTipAskData?.avgPrice}
                      </li>
                      <li>
                        Total in {symbol?.split('-')[1]?.toUpperCase()} ={' '}
                        {toolTipAskData === null
                          ? '---'
                          : toolTipAskData?.sumPrice}
                      </li>
                      <li>
                        Sum Amount {symbol?.split('-')[0]?.toUpperCase()} ={' '}
                        {toolTipAskData === null
                          ? '---'
                          : toolTipAskData?.sumAmount}
                      </li>
                    </ul>
                  </div>
                );
              }}
            />
            {/* {isSellOrderBookScrollable ? (
              <>
                {!loading &&
                  orderData &&
                  orderasks &&
                  orderasks.map((data, idx) => (
                    <div
                      className={`orders__row order__asks ${
                        idxofOrderAsks === '' || Number(idxofOrderAsks) > idx
                          ? ''
                          : 'active'
                      }`}
                      id='order__asks'
                      key={idx}
                      onClick={() => setOrderBookPrice(data?.data1)}
                      data-tip={idx}
                      data-for={'avg_price_ask'}
                    >
                      <div
                        className='orders__cell orders__up'
                        style={{
                          paddingLeft: checkMine('sell', data.data1, data.data2)
                            ? '0px'
                            : '11px',
                        }}
                      >
                        {checkMine('sell', data.data1, data.data2) ? '👉' : ''}{' '}
                        {data.data2}
                        <div
                          className='orders__bg'
                          style={{
                            width: `${data.per}%`,
                          }}
                        ></div>
                      </div>
                      <div
                        className='orders__cell'
                        onClick={() => setOrderBookPrice(data?.data1)}
                        style={{ zIndex: width > 700 ? 100 : 0 }}
                      >
                        {' '}
                        {data.data1}
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <FlexEndWrapper>
                {!loading &&
                  orderData &&
                  orderasks &&
                  orderasks.map((data, idx) => (
                    <div
                      className={`orders__row order__asks ${
                        idxofOrderAsks === '' || Number(idxofOrderAsks) > idx
                          ? ''
                          : 'active'
                      }`}
                      id='order__asks'
                      key={idx}
                      onClick={() => setOrderBookPrice(data?.data1)}
                      data-tip={idx}
                      data-for={'avg_price_ask'}
                    >
                      <div
                        className='orders__cell orders__up'
                        style={{
                          paddingLeft: checkMine('sell', data.data1, data.data2)
                            ? '0px'
                            : '11px',
                        }}
                      >
                        {checkMine('sell', data.data1, data.data2) ? '👉' : ''}{' '}
                        {data.data2}
                        <div
                          className='orders__bg'
                          style={{
                            width: `${data.per}%`,
                          }}
                        ></div>
                      </div>
                      <div
                        className='orders__cell'
                        onClick={() => setOrderBookPrice(data?.data1)}
                        style={{ zIndex: width > 700 ? 100 : 0 }}
                      >
                        {' '}
                        {data.data1}
                      </div>
                    </div>
                  ))}
              </FlexEndWrapper>
            )} */}
            <>
              {!loading &&
                orderData &&
                orderasks &&
                orderasks.map((data, idx) => {
                  return (
                    <div
                      style={{
                        position: 'relative',
                      }}
                      key={idx}
                      onMouseEnter={() => setIdxofOrderAsks(idx)}
                      onMouseLeave={() => setIdxofOrderAsks(null)}
                    >
                      <div
                        className='orders__bg orders__bg_red'
                        style={{
                          width: `${Number(data?.per)}%`,
                          position: 'absolute',
                        }}
                      ></div>
                      <div
                        className={`orders__row order__asks ${
                          idxofOrderAsks === '' || Number(idxofOrderAsks) < idx
                            ? ''
                            : 'active'
                        }`}
                        id='order__asks'
                        onClick={() => setOrderBookPrice(data?.data1)}
                        data-tip={idx}
                        data-for={'avg_price_ask'}
                      >
                        <div
                          className='orders__cell orders__up'
                          style={{
                            paddingLeft: checkMine(
                              'sell',
                              data.data1,
                              data.data2
                            )
                              ? '0px'
                              : '11px',
                          }}
                        >
                          {checkMine('sell', data.data1, data.data2)
                            ? '👉'
                            : ''}{' '}
                          {data.data2}
                          {/* <div
                          className='orders__bg'
                          style={{
                            width: `${data.per}%`,
                          }}
                        ></div> */}
                        </div>
                        <div
                          className='orders__cell'
                          onClick={() => setOrderBookPrice(data?.data1)}
                          style={{ zIndex: width > 700 ? 100 : 0 }}
                        >
                          {' '}
                          {data.data1}
                        </div>
                        {/* <div className="orders__cell">
                    {checkMine('sell', data.data1, data.data2) ? '👈' : '-'}
                  </div> */}
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
          <div
            className={`orders__price ${getStatus(
              `${selectedCell.symbol}${symbol?.split('-')[1]}`
            )} `}
          >
            {formatCurrency(
              lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
            )}{' '}
            {symbol?.split('-')[1].toUpperCase()}
          </div>
          <div
            className='table_height_control order_table order_table_bids_container'
            onMouseOver={() => console.log('')}
            onMouseOut={() => {
              setIdxofOrderBids('');
              setIdxofOrderAsks('');
              // setToolTipBidData(null);
              // setToolTipAskData(null);
              // alert('eee');
            }}
            ref={buyOrderContainerRef}
          >
            <ReactTooltip
              place='right'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles order_tooltip'
              id={'avg_price_bid'}
              effect='solid'
              getContent={(dataTip) => {
                // if (dataTip) {
                //   setIdxofOrderBids(dataTip);
                // }
                return (
                  <div>
                    <ul>
                      <li>
                        Avg.Price ≈{' '}
                        {toolTipBidData == null
                          ? '---'
                          : toolTipBidData?.avgPrice}
                      </li>
                      <li>
                        Total in {symbol?.split('-')[1]?.toUpperCase()} ={' '}
                        {toolTipBidData == null
                          ? '---'
                          : toolTipBidData?.sumPrice}
                      </li>
                      <li>
                        Sum Amount{' '}
                        {toolTipBidData == null
                          ? '---'
                          : symbol?.split('-')[0]?.toUpperCase()}{' '}
                        = {toolTipBidData?.sumAmount}
                      </li>
                    </ul>
                  </div>
                );
              }}
            />
            {!loading &&
              orderData &&
              orderbids &&
              orderbids.map((data, idx) => {
                return (
                  <div
                    style={{
                      position: 'relative',
                    }}
                    key={idx}
                    onMouseEnter={() => setIdxofOrderBids(idx)}
                    onMouseLeave={() => setIdxofOrderBids(null)}
                    // if (dataTip) {
                    //   setIdxofOrderBids(dataTip);
                    // }
                  >
                    <div
                      className='orders__bg orders__bg_green'
                      style={{
                        width: `${Number(data?.per)}%`,
                        position: 'absolute',
                      }}
                    ></div>
                    <div
                      onClick={() => setOrderBookPrice(data?.data1)}
                      data-tip={idx}
                      data-for={'avg_price_bid'}
                      className={`orders__row  sell__row order__bids ${
                        idxofOrderBids === '' || Number(idxofOrderBids) < idx
                          ? ''
                          : 'active'
                      }`}
                      style={{ position: 'relative' }}
                    >
                      <div
                        className='orders__cell orders__down'
                        style={{
                          paddingLeft: checkMine('buy', data.data1, data.data2)
                            ? '0px'
                            : '11px',
                        }}
                      >
                        {checkMine('buy', data.data1, data.data2) ? '👉' : ''}{' '}
                        {data.data2}
                      </div>
                      <div
                        className='orders__cell'
                        onClick={() => setOrderBookPrice(data?.data1)}
                        style={{ zIndex: width > 700 ? 100 : 0 }}
                      >
                        {data.data1}
                      </div>
                      {/* <div className="orders__cell">
                    {checkMine('buy', data.data1, data.data2) ? '👈' : '-'}
                  </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default OrderTable;
