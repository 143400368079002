import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import CustomButton from '../../common/custom-button/custom-button';
import { useSegment } from '../../../utils/hooks';
import saltAnimationData from '../../../assests/json/salt-lottie.json';
import Lottie from 'react-lottie';
import DepositLinkText from '../../common/deposit-popup/deposit-link-text';
import LoadingBtn from '../../common/loading-btn/loading-btn';

const ConnectStepper = ({ stage = 'stage-2', activeAll = false }) => {
  return (
    <div
      className={`register-stepper salt-stepper ${
        activeAll ? 'active_all' : ''
      } `}
    >
      <div
        className={`step ${stage === 'stage-2' ? 'editing' : ''} ${
          stage === 'stage-3' ? 'done' : ''
        }  ${activeAll ? 'editing' : stage === 'stage-4' ? 'done' : ''}`}
      >
        <div className='step-circle'>
          <span>1</span>
        </div>
        {/* <div className='step-title h6'>
          Initiate your Withdrawal request in the{' '}
          <Link
            to={{
              pathname: '/wallet',
              search: '?tab=withdrawal',
            }}
          >
            <span style={{ color: '#8173E0', textDecoration: 'underline' }}>
              Wallet tab
            </span>
          </Link>{' '}
          .
        </div> */}
        <div className='step-bar-left'></div>
        <div className='step-bar-right'></div>
      </div>
      <div className={`step ${stage === 'stage-3' ? 'editing' : 'incomplete'}`}>
        <div className='step-circle'>
          <span>2</span>
        </div>
        {/* <div className='step-title h6'>
          WAIT for upto <span style={{ color: '#8173E0' }}>24 hours</span>.
        </div> */}
        <div className='step-bar-left'></div>
        <div className='step-bar-right'></div>
      </div>
    </div>
  );
};

function SaltScoreConnect({
  isUserLoggedIn,
  isRankGenerated,
  handleGetSaltScore,
  saltScoreFlagLoading,
  isSaltScoreCalLoading,
  stage,
  showScore,
}) {
  const location = useLocation();
  const history = useHistory();
  const { sendTrackEvent } = useSegment();

  const SaltAnimationData = {
    loop: false,
    autoplay: true,
    animationData: saltAnimationData,
  };

  const saltHeading = {
    stage1: '',
    stage2: 'We’re calculating your $SALT ⌛️',
    stage3: 'We’re calculating your $SALT ⌛️',
  };

  const saltDescription = {
    stage1: (
      <div
        className='card__description salt__description'
        style={{ color: '#808191', fontSize: 13 }}
      >
        Connect your wallet to extract your SALT score.
      </div>
    ),
    stage2: (
      <div
        className='card__description salt__description'
        style={{ color: '#808191', fontSize: 13 }}
      >
        {/* We have a surprise for you 🥳 */}
        Kindly wait for it till your <span>$SALT</span> is calculated.
      </div>
    ),
    stage3: (
      // <div
      //   className='card__description salt_description'
      //   style={{ color: '#808191', fontSize: 13 }}
      // >
      //   We have a surprise for you 🥳 Your <span>$SALT</span> score is now ready
      //   for claiming.
      // </div>
      <div
        className='card__description salt__description'
        style={{ color: '#808191', fontSize: 13 }}
      >
        {/* We have a surprise for you 🥳 */}
        Kindly wait for it till your <span>$SALT</span> is calculated.
      </div>
    ),
  };

  const saltButtonTitle = {
    stage1: 'Calculate SALT score',
    stage2: 'Calculating your $SALT...',
    stage3: 'Calculating your $SALT...',
  };

  return (
    <div className='card card_widget text-center'>
      <div className='card__inner'>
        {/* <ConnectStepper stage={isUserLoggedIn ? 'stage-3' : 'stage-2'} /> */}
        <div className='card__title salt__title h6'>{saltHeading[stage]}</div>
        <Lottie
          options={SaltAnimationData}
          height={200}
          width={200}
          style={{
            marginTop: '-27px',
          }}
        />
        <div
          className='card__description'
          style={{ color: '#808191', fontSize: 14 }}
        >
          {saltDescription[stage]}
        </div>
        <div style={{ marginTop: 12, display: 'block' }}>
          {!isUserLoggedIn ? (
            <CustomButton
              title='Connect Wallet'
              style={{
                minWidth: '100%',
                marginTop: 10,
              }}
              onClick={() => {
                history.push({
                  pathname: `/signin`,
                  search: location.search,
                });
                sendTrackEvent('buttonClicked', {
                  title: 'Connect Wallet',
                  action: 'Routing to Login Page',
                });
              }}
            />
          ) : (
            <>
              {/* {!isRankGenerated && ( */}
              <>
                {isSaltScoreCalLoading ||
                stage === 'stage2' ||
                stage === 'stage3' ? (
                  <div style={{ marginTop: 30 }}>
                    <LoadingBtn
                      size='full'
                      text={'Calculating your $SALT...'}
                    />
                  </div>
                ) : (
                  <CustomButton
                    title={saltButtonTitle[stage]}
                    style={{
                      minWidth: '100%',
                      marginTop: 10,
                    }}
                    onClick={
                      stage === 'stage3'
                        ? () => showScore()
                        : () => handleGetSaltScore(true)
                    }
                  />
                )}
              </>
              {/* )} */}
            </>
          )}
        </div>
      </div>
      <DepositLinkText
        text='How to increase my SALT Score?'
        link='https://www.brine.fi/salt-score'
      />
    </div>
  );
}

export default SaltScoreConnect;
