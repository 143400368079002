import React from 'react';

export function LeaderBoardTags({
  setSelectedFilter,
  selectedFilter,
  options,
  addTagclassName,
  addLinksclassName,
  ...otherProps
}) {
  return (
    <div className={addTagclassName}>
      {options?.map((data, idx) => (
        <button
          className={`${addLinksclassName} ${
            selectedFilter.value === data.value ? 'active' : ''
          }`}
          {...otherProps}
          key={idx}
          onClick={() => setSelectedFilter(data)}
        >
          {data.label}
          <span
            className={data?.isActive ? 'active__tags active' : 'active__tags'}
          >
            {data?.isActive ? 'LIVE' : 'ENDED'}
          </span>
        </button>
      ))}
    </div>
  );
}
