/* global BigInt */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../../common/popup/popup';
import {
  useAnalytics,
  useBalance,
  useMeta,
  useWalletMethods,
  useWebSocketData,
  useViewport,
  useSegment,
} from '../../../utils/hooks';
import { useWeb3React } from '@web3-react/core';
import { useLocation, useHistory } from 'react-router-dom';
import {
  baseCurrency,
  MAX_LENGTH_ALLOWED_IN_NUM_INPUT,
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  NORMAL_WITHDRAWAL_CONFIRMATION_HOURS,
  WITHDRAWAL_FEE_DECIMAL_POINTS,
} from '../../../utils/constants';
import { walletTextAndLogo } from '../../header/header';
import Image from '../img/image';
import { getQueryParams } from '../../../utils/query-helper';
import {
  cryptoDepositStart,
  getFastVaultId,
  getVaultId,
  getWithdrawalVaultId,
  startFastWithdrawalDex,
  startWithdrawalDex,
  validateFastWithdrawalDex,
  validateWithdrawalDex,
} from '../../../actions/walletAction';
import animationData from '../../../assests/json/done.json';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import {
  logout,
  getBalance as getUserBalance,
  getBalance,
} from '../../../actions/userAction';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import {
  getDEXDepositsData,
  getDEXFastWithdrawData,
  getWithdrawsData,
} from '../../../actions/historyAction';
import Icon from '../icons/icons';
import { InsufficientBalanceBtn } from '../../exchange/exchange-action/exchange-action';
import CustomButton from '../custom-button/custom-button';
import WarnBox from '../warn-box/warn-box';
import Loader from '../loader/loader';
import SucccessLottie from '../success-lottie/success-lottie';
import { DepositLoader } from '../../wallets/wallet-deposit/wallet-deposit';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  InstructionForWithdrawal,
  StepperItem,
} from '../../wallets/wallet-withdraw/wallet-withdraw';
import { sanitizeProvider } from '../../../utils/sanitize-provider';
import {
  PurpleCard,
  DepositDropdown as DepositDropDown,
  DepositLinkText,
  DepositInput as DepositFormInput,
  LoadingBtn,
  IconBtn,
} from '../../../components';
import { getTransferMsgHash, sign } from '../../../utils/contracts/signature';
import processingAnimationData from '../../../assests/json/processing.json';
import { images } from '../../../utils/image/img';
import Lottie from 'react-lottie';
import Toast from '../../common/toast/toast';
import { numberInputOnWheelPreventChange } from '../../../utils/numberPrevent';
import { networkName } from '../../../utils/urls';
import { BigNumber } from 'ethers';
import ReactTooltip from 'react-tooltip';
import useFeature, { FEATURE_FLAG } from '../../../utils/hooks/useFeature';
import { networkOptions } from '../../../utils/hooks/useWallets';
import Decimal from 'decimal.js';
import { getDecimalJsInstance } from '../../../utils/decimal';
import StarknetConnectPopup from '../starknet-connect-popup/starknet-connect-popup';
import { useAccount, useConnectors, useProvider } from '@starknet-react/core';
import { truncate } from '../../../utils/string-methods';

function removeHexPrefix(str, removeOx0 = false) {
  // Use a regular expression to remove "0x0" and "0x" from the beginning of the string
  if (removeOx0) {
    return str.replace(/^(0x0|0x)/, '');
  } else {
    return str.replace(/^0x/, '');
  }
}

function WithdrawalPopup({
  state,
  setState,
  selectedSymbol,
  dropDownOptions,
  setSelectedDropDownOption,
  priority,
  isWalletPage,
}) {
  const [selectedData, setSelectData] = useState(null);
  const [populatedList, setPopulatedList] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const context = useWeb3React();
  const [withdrawalLoading, setWithdrawalLoading] = useState(false);
  const [disableBackBtn, setDisableBackBtn] = useState(false);
  const { connector } = context;
  const [ethereumCoinData, setEthereumCoinData] = useState([]);
  const [polygonCoinData, setPolygonCoinData] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const [filteredCoinByNetwork, setFilterCoinByNetwork] = useState([]);
  const { provider } = useProvider();
  // const options = [
  //   {
  //     label: 'Fast 🚀 (1-30 mins)',
  //     value: 'fast',
  //   },
  //   {
  //     label: 'Normal 🎉 (7-8 hours)',
  //     value: 'normal',
  //   },
  // ];
  const options = [
    {
      label: 'Fast',
      value: 'fast',
    },
    {
      label: 'Normal',
      value: 'normal',
    },
  ];
  const dispatch = useDispatch();
  const { width } = useViewport();
  const { lastPrice } = useWebSocketData();
  const {
    getSingleCoinsBalance,
    roundUpWithSymbol,
    isDecimal,
    getInfoAboutCoins,
    preventScientific,
    getFastWithdrawalFee: getStaticFastWithdrawalFee,
  } = useBalance();
  // const inputRef = document.getElementById('amount-input');
  const withdrawOptions = [
    {
      value: 'normal',
      currency: 'Normal',
      label: (
        <div className='deposit_dropdown'>
          <img src={images.normalWithdrawal} className='dropdown-item' />
          Normal
          <span>{NORMAL_WITHDRAWAL_CONFIRMATION_HOURS} hours</span>
        </div>
      ),
    },
    {
      value: 'fast',
      currency: 'Fast',
      label: (
        <div className='deposit_dropdown'>
          <img src={images.fastWithdrawal} className='dropdown-item' />
          Fast
          {selectedData?.network === 'starknet' ? (
            <span>25 mins</span>
          ) : (
            <span>5 mins</span>
          )}
        </div>
      ),
    },
  ];

  // const [coinsInfoStore, setCoinInfoStore] = useState({});
  const [amountToWithdraw, setAmountToWithdraw] = useState('');
  const { connectedAccount } = useMeta(selectedData?.currency);
  const [selectedOption, setSelectedOptions] = useState(withdrawOptions[0]);
  const { deactivate } = useWeb3React();
  const { analytics, sendTrackEvent } = useSegment();
  const [ethereum, setEthereum] = useState(null);
  const [fastWithdrawalGasFee, setFastWithdrawalGasFee] = useState(null);
  const { getStatusByFeatureKey } = useFeature();
  const [isUserFromBackController, setIsUserFromBackController] =
    useState(false);
  const [starknetCoinData, setStarknetCoinData] = useState([]);
  const [openStarknetPopup, setOpenStarknetPopup] = useState(false);
  const [optimismCoinData, setOptimismCoinData] = useState([]);

  // const {
  // userLogin: { userInfo },
  // userKey: { state: userKey },
  // userVia: { state: userVia },
  // withdrawalIds: { state: withdrawalIds },
  // userIpAddress: { ip },
  // theme: { isdark },
  // } = useSelector((state) => state);
  // const {
  // getCoins: { loading: coinsLoading, error: coinsError, coinsDetails },
  // depositDetails: { details },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { state: userVia } = useSelector((state) => state.userVia);
  const { state: userKey } = useSelector((state) => state.userKey);
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const { details } = useSelector((state) => state.depositDetails);

  const [processLoading, setProcessLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const { account } = useWeb3React();
  const [isManualStarknetConnect, setisManualStarknetConnect] = useState(false);
  const { connect, connectors, disconnect } = useConnectors();
  const { toFixedTrunc, truncateToDecimalPlaces } = useBalance();
  const [enableForceWithdrawal, setEnableForceWithdrawal] = useState(true);

  const {
    account: starknetAccount,
    address: starknetAddress,
    status,
  } = useAccount();
  // Popup
  const [openWithdrawalStartPopup, setOpenWithdrawalStartPopup] =
    useState(false);
  const [openInstructionForWithdrawal, setOpenInstructionForWithdrawal] =
    useState(false);
  const [openVaultIdPopup, setOpenVaultIdPopup] = useState(false);

  const [activatingConnector, setActivatingConnector] = useState();
  const {
    getFastWithdrawalGasFee,
    getPopulatedData,
    networkConfig,
    getNetworkOptionByName,
    getAllowedWithdrawalCoinListByNetwork,
  } = useWalletMethods();

  const handleFilteredCoinByNetwork = () => {
    let filterCoins = populatedList?.filter(
      (item) => item?.network == selectedNetwork?.network
    );
    setFilterCoinByNetwork(filterCoins);
  };

  useEffect(() => {
    if (populatedList?.length) {
      handleFilteredCoinByNetwork();
    }
  }, [populatedList?.length, selectedNetwork?.network]);

  // const handlePreventDecimal = (e) => {
  //   let invalidChars = ['-', '+', 'e', '.'];
  //   if (invalidChars.includes(e.key)) {
  //     return e.preventDefault();
  //   }
  // };

  // useEffect(() => {
  //   const coinInfo = coinsDetails?.convertedArray?.find((item) => {
  //     return (
  //       item.symbol == selectedData?.symbol ||
  //       item.symbol == selectedData?.currency
  //     );
  //   });
  //   setCoinInfoStore(coinInfo);
  // }, [coinsDetails, selectedData?.symbol, selectedData?.currency]);

  // useEffect(() => {
  //   if (inputRef) {
  //     if (
  //       coinsInfoStore?.symbol == 'shib' ||
  //       coinsInfoStore?.decimal == 0 ||
  //       coinsInfoStore?.symbol == 'inr'
  //     ) {
  //       inputRef.addEventListener('keydown', handlePreventDecimal);
  //       return () =>
  //         inputRef.removeEventListener('keydown', handlePreventDecimal);
  //     }
  //   }
  // }, [
  //   inputRef,
  //   coinsInfoStore?.symbol,
  //   coinsInfoStore?.currency,
  //   coinsInfoStore?.decimal,
  // ]);

  const maxLengthCheck = (object) => {
    let decimalPoint = getInfoAboutCoins(selectedData?.currency)?.quanitization;
    if (isDecimal(object.target.value)) {
      if (countDecimals(object.target.value) >= Number(decimalPoint) + 1) {
        object.target.value = amountToWithdraw;
      }
    } else {
      let maxLength = 9;
      const { value } = object.target;
      if (String(value).length >= maxLength) {
        object.target.value = amountToWithdraw;
      }
    }
  };

  const generateHash = async (nonce, msg_hash, isFastWithdrawal = false) => {
    let stark_public_key = getKeyPairFromSignature(userKey);
    let msgHex = BigNumber.from(msg_hash).toHexString();
    const msg = sign(stark_public_key, removeHexPrefix(msgHex));

    return !isFastWithdrawal
      ? {
          msg_hash: removeHexPrefix(msgHex, true),
          signature: {
            r: `0x${msg.r.toString('hex')}`,
            s: `0x${msg.s.toString('hex')}`,
            recoveryParam: msg.recoveryParam,
          },
          nonce: nonce,
        }
      : {
          msg_hash: msg_hash,
          signature: {
            r: `0x${msg.r.toString('hex')}`,
            s: `0x${msg.s.toString('hex')}`,
            recoveryParam: msg.recoveryParam,
          },
          fastwithdrawal_withdrawal_id: nonce,
        };
  };

  const generateFastWithdrawHash = async (
    nonce,
    quanitizatedAmount,
    exp_timestamp,
    recieverVaultId,
    receiverPublicKey,
    senderVaultId
  ) => {
    let token = getInfoAboutCoins(selectedData?.currency)?.stark_asset_id;

    let stark_public_key = getKeyPairFromSignature(userKey);

    const msg_hash = await getTransferMsgHash(
      quanitizatedAmount,
      nonce,
      senderVaultId,
      token,
      recieverVaultId,
      receiverPublicKey,
      exp_timestamp
    );

    const msg = sign(stark_public_key, msg_hash);

    return {
      msg_hash,
      signature: {
        r: `0x${msg.r.toString('hex')}`,
        s: `0x${msg.s.toString('hex')}`,
        recoveryParam: msg.recoveryParam,
      },
      nonce: nonce,
    };
  };

  // const handleGetVaultId = async () => {
  //   try {
  //     const data = await getVaultId(
  //       userInfo?.token?.access,
  //       selectedData?.currency
  //     );
  //     return data;
  //   } catch (error) {
  //     setOpenVaultIdPopup(false);
  //     toast.error(error);
  //   }
  // };

  const handleWithdrawalValidation = async (hashObj) => {
    sendTrackEvent('withdrawalValidationInitiated', {
      network: selectedData?.network,
    });
    try {
      const validateRes = await validateWithdrawalDex(
        userInfo?.token?.access,
        hashObj
      );
      toast.success(validateRes.message);
      setOpenWithdrawalStartPopup(true);
      dispatch(getWithdrawsData(1));
      setAmountToWithdraw('');
      setProcessLoading(false);
      sendTrackEvent('withdrawalValidationSuccess', {
        network: selectedData?.network,
        amount: String(amountToWithdraw),
      });
    } catch (error) {
      toast.error(error);
      sendTrackEvent('withdrawalValidationFailure', {
        error,
        network: selectedData?.network,
      });
    }
  };

  const handleWithdrawalFastValidation = async (hashObj) => {
    sendTrackEvent('fastWithdrawalValidationInitiated', {
      network: selectedData?.network,
    });

    try {
      const validateRes = await validateFastWithdrawalDex(
        userInfo?.token?.access,
        hashObj
      );
      toast.success(validateRes.message);
      setOpenWithdrawalStartPopup(true);
      dispatch(getDEXFastWithdrawData(1));
      setAmountToWithdraw('');
      sendTrackEvent('fastWithdrawalValidationSuccess', {
        network: selectedData?.network,
        amount: String(amountToWithdraw),
      });
      setProcessLoading(false);
    } catch (error) {
      toast.error(error);
      sendTrackEvent('fastWithdrawalValidationFailure', {
        error,
        network: selectedData?.network,
      });
    }
  };

  const refreshBalance = () => {
    setTimeout(() => {
      dispatch(getBalance());
    }, 10000);
  };

  const handleDexWithdrawal = async () => {
    let feature = getStatusByFeatureKey(FEATURE_FLAG['withdrawal']);

    if (!feature.status) {
      return toast.info(feature?.reason);
    }

    // analytics?.track('Withdrawal request start', {
    //   from: 'Wallet Page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   symbol: selectedData?.currency,
    //   amount: amountToWithdraw,
    //   ip: ip,
    // });
    setProcessLoading(true);
    setBtnDisable(true);
    // if (userVia?.via == 'metamask') {
    //   if (
    //     !ethereum.isConnected() ||
    //     !ethereum._state.initialized ||
    //     !connectedAccount
    //   ) {
    //     toast.error('Your session has expired; please log in again');
    //     setTimeout(() => {
    //       walletDeactivate();
    //       dispatch(logout(true));
    //     }, 1500);
    //     setBtnDisable(false);
    //     setOpenVaultIdPopup(false);
    //     setProcessLoading(false);
    //     return;
    //   }
    // }

    if (amountToWithdraw <= 0) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Amount should be greater than zero');
    }

    if (
      Number(getSingleCoinsBalance(selectedData?.currency)) < amountToWithdraw
    ) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Your Brine wallet has insufficient balance');
    }

    if (userVia?.via !== 'metamask' && userVia?.via !== 'web3_auth') {
      if (!account) {
        toast.error('Your session has expired; please log in again');
      }
    }

    try {
      // remove this
      // let coinsIdList = withdrawalIds.payload.filter(
      //   (item) => item.coin === selectedData?.currency
      // );
      // let senderVaultId = coinsIdList?.filter(
      //   (item) => item.vault_type === 'GEN'
      // )[0]?.id;
      // let recieverVaultId = coinsIdList?.filter(
      //   (item) => item.vault_type === 'WD'
      // )[0]?.id;
      // let receiverPublicKey = coinsIdList?.filter(
      //   (item) => item.vault_type === 'WD'
      // )[0]?.stark_public_address;

      // if (senderVaultId && recieverVaultId) {
      //   sendTrackEvent('withdrawalVaultIdRequestSuccess');
      // } else {
      //   sendTrackEvent('withdrawalVaultIdRequestFailure', {
      //     error: 'No vault ID found',
      //   });
      // }

      // if (!senderVaultId || !recieverVaultId) {
      //   handleGetVaultId();
      //   dispatch(getWithdrawalVaultId());
      //   setOpenVaultIdPopup(true);
      //   return;
      // }

      sendTrackEvent('withdrawalInitiationStarted', {
        network: selectedData?.network,
      });
      const { data } = await startWithdrawalDex(
        userInfo?.token?.access,
        amountToWithdraw,
        selectedData?.currency
      );
      sendTrackEvent('withdrawalInitiationSuccess', {
        network: selectedData?.network,
      });
      sendTrackEvent('withdrawalHashGenerationStarted', {
        network: selectedData?.network,
      });

      let msgHash = data?.payload?.msg_hash;
      let nonce = data?.payload?.nonce;

      const hashObj = await generateHash(nonce, msgHash);

      sendTrackEvent('withdrawalHashGenerationSuccess', {
        network: selectedData?.network,
      });
      if (!hashObj) {
        sendTrackEvent('withdrawalHashGenerationFailure', {
          error: 'something went wrong, while hash generation',
          network: selectedData?.network,
        });
        toast.error('Something went wrong; please try again later', {
          toastId: 'withdrawal_failed',
        });
        setBtnDisable(false);
        setProcessLoading(false);
        return;
      }
      handleWithdrawalValidation(hashObj);
      refreshBalance();
      setTimeout(() => {
        setBtnDisable(false);
      }, 5000);
      // analytics?.track('Withdrawal request success', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   amount: amountToWithdraw,
      //   ip: ip,
      // });
    } catch (e) {
      // analytics?.track('Withdrawal request failed', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   ip: ip,
      //   amount: amountToWithdraw,
      // });
      sendTrackEvent('withdrawalInitiationFailure', {
        error: e,
        network: selectedData?.network,
      });
      toast.error(e);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      setTimeout(() => {
        setBtnDisable(false);
      }, 3000);
    }
  };

  const handleDexFastWithdrawal = async () => {
    // analytics?.track('Fast withdrawal request start', {
    //   from: 'Wallet Page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   symbol: selectedData?.currency,
    //   amount: amountToWithdraw,
    //   ip: ip,
    // });
    let feature = getStatusByFeatureKey(FEATURE_FLAG['fastWithdrawal']);

    if (!feature.status) {
      return toast.info(feature?.reason);
    }

    setProcessLoading(true);
    setBtnDisable(true);
    // if (userVia?.via == 'metamask') {
    //   if (
    //     !ethereum.isConnected() ||
    //     !ethereum._state.initialized ||
    //     !connectedAccount
    //   ) {
    //     toast.error('Your session has expired; please log in again');
    //     setTimeout(() => {
    //       walletDeactivate();
    //       dispatch(logout(true));
    //     }, 1500);
    //     setBtnDisable(false);
    //     setProcessLoading(false);
    //     setOpenVaultIdPopup(false);
    //     return;
    //   }
    // }

    if (amountToWithdraw == 0) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Please enter an amount greater than zero');
    }

    if (
      Number(getSingleCoinsBalance(selectedData?.currency)) <
      Number(amountToWithdraw) + Number(getFastWithdrawalFee())
    ) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Your Brine wallet has insufficient balance');
    }

    if (userVia?.via !== 'metamask' && userVia?.via !== 'web3_auth') {
      if (!account) {
        toast.error('Your session has expired; please log in again');
      }
    }

    try {
      // let coinsIdList = withdrawalIds.payload.filter(
      //   (item) => item.coin === selectedData?.currency
      // );
      // let senderVaultId = coinsIdList?.filter(
      //   (item) => item.vault_type === 'GEN'
      // )[0]?.id;

      // const res = await getFastVaultId(
      //   userInfo?.token?.access,
      //   selectedData?.currency
      // );

      // let recieverVaultId = res?.payload?.id;
      // let receiverPublicKey = res?.payload?.stark_public_address;

      // if (senderVaultId && recieverVaultId) {
      //   sendTrackEvent('fastWithdrawalVaultIdRequestSuccess');
      // } else {
      //   sendTrackEvent('fastWithdrawalVaultIdRequestFailure', {
      //     error: 'No vault ID found',
      //   });
      // }

      // if (!senderVaultId || !recieverVaultId) {
      //   handleGetVaultId();
      //   dispatch(getWithdrawalVaultId());
      //   setOpenVaultIdPopup(true);
      //   return;
      // }
      // sendTrackEvent('fastWithdrawalInitiationStarted');

      let formattedFee = getFastWithdrawalFee(false);

      const { data } = await startFastWithdrawalDex(
        userInfo?.token?.access,
        amountToWithdraw,
        selectedData?.currency,
        toFixedTrunc(Number(formattedFee), 15) || null,
        enableForceWithdrawal,
        selectedData?.network?.toUpperCase(),
        selectedData?.network === 'starknet' ? starknetAddress : null
      );

      sendTrackEvent('fastWithdrawalInitiationSuccess', {
        network: selectedData?.network,
      });

      sendTrackEvent('fastWithdrawalHashGenerationStarted', {
        network: selectedData?.network,
      });

      // const hashObj = await generateFastWithdrawHash(
      //   data?.payload?.id,
      //   data?.payload?.extras?.quantised_amount,
      //   data?.payload?.extras?.exp_timestamp,
      //   recieverVaultId,
      //   receiverPublicKey,
      //   senderVaultId
      // );

      let msgHash = data?.payload?.msg_hash;
      let fastWithdrawalId = data?.payload?.fastwithdrawal_withdrawal_id;

      const hashObj = await generateHash(fastWithdrawalId, msgHash, true);

      sendTrackEvent('fastWithdrawalHashGenerationSuccess', {
        network: selectedData?.network,
      });
      if (!hashObj) {
        toast.error('Something went wrong; please try again later', {
          toastId: 'withdrawal_failed',
        });
        setBtnDisable(false);
        setProcessLoading(false);
        return;
      }
      handleWithdrawalFastValidation(hashObj);
      refreshBalance();
      setTimeout(() => {
        setBtnDisable(false);
      }, 5000);
      // analytics?.track('Fast withdrawal request success', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   amount: amountToWithdraw,
      //   ip: ip,
      // });
    } catch (e) {
      // analytics?.track('Fast withdrawal request failed', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   amount: amountToWithdraw,
      //   ip: ip,
      //   error: e,
      // });
      toast.error(e);
      sendTrackEvent('fastWithdrawalInitiationFailure', {
        error: e,
        network: selectedData?.network,
      });
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      setTimeout(() => {
        setBtnDisable(false);
      }, 3000);
    }
  };

  const noteStyle = {
    marginTop: -6,
    marginBottom: 16,
    fontFamily: 'Poppins',
    fontSize: 11,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  };

  const PopupStyles = {
    // paddingLeft: width > 750 ? 50 : 0,
    // paddingRight: width > 750 ? 50 : 0,
    marginTop: 20,
    textAlign: 'center',
    fontFamily: 'Poppins',
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const processAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: processingAnimationData,
  };

  useEffect(() => {
    return () => {
      setAmountToWithdraw('');
    };
  }, [selectedData]);

  const getFastWithdrawalFee = (showStaticFee = true) => {
    if (fastWithdrawalGasFee) {
      const selectedCurrencyPair = selectedData.symbol;
      const nativeCurrency = networkConfig[selectedData?.network]?.nativeSymbol;
      const selectedPairFee =
        getStaticFastWithdrawalFee(selectedCurrencyPair) *
        lastPrice(selectedCurrencyPair + baseCurrency);
      const ethFee =
        fastWithdrawalGasFee * lastPrice(nativeCurrency + baseCurrency);

      if (selectedPairFee <= ethFee) {
        return convertGasFeeEthToCurrency(fastWithdrawalGasFee);
      } else {
        return !showStaticFee
          ? null
          : getStaticFastWithdrawalFee(selectedCurrencyPair);
      }
    }
  };

  function handlePercentInWithdraw(percent) {
    const balance = getSingleCoinsBalance(selectedData?.currency);

    // Create Decimal objects for percent, balance, and fastWithdrawalFee
    const percentDecimal = getDecimalJsInstance(percent);
    const balanceDecimal = getDecimalJsInstance(balance);

    let finalAmountDecimal;
    if (selectedOption.value === 'fast') {
      const fastWithdrawalFeeDecimal = getDecimalJsInstance(
        getFastWithdrawalFee()
      );

      // Calculate finalAmount for fast withdrawal with precision
      finalAmountDecimal = percentDecimal
        .times(balanceDecimal)
        .dividedBy(100)
        .minus(fastWithdrawalFeeDecimal);
    } else {
      // Calculate finalAmount without fast withdrawal
      finalAmountDecimal = percentDecimal.times(balanceDecimal).dividedBy(100);
    }

    // Ensure the finalAmount is greater than or equal to 0
    if (finalAmountDecimal.lessThan(0)) {
      finalAmountDecimal = getDecimalJsInstance(0);
    }

    // Round up the finalAmount without removing extra zeros
    let amount = roundUpWithSymbol(
      finalAmountDecimal.toString(),
      selectedData?.currency
    );

    setAmountToWithdraw(amount);
  }

  const handleTryAgain = () => {
    setOpenVaultIdPopup(false);
    if (selectedOption.value === 'fast') {
      handleDexFastWithdrawal();
    } else {
      handleDexWithdrawal();
    }
  };

  useEffect(() => {
    sanitizeProvider(setEthereum);
  }, []);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObj = getQueryParams();
    const invalidParams = ['order_id', 'mid', 'status', 'message'];
    if (Object.keys(queryParamsObj).length != 0 && location.search != '') {
      for (const key in queryParamsObj) {
        const InvalidKey = invalidParams.find((item) => item == key);
        if (InvalidKey) {
          queryParams.delete(InvalidKey);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [location.search]);

  // useEffect(() => {
  //   if (coinsDetails && !coinsLoading) {
  //     let finalArr = [];
  //     coinsDetails?.convertedArray.forEach((item) => {
  //       finalArr.push({
  //         value: item?.symbol + ' ' + item?.name,
  //         currency: item?.symbol,
  //         label: (
  //           <div className='deposit_dropdown'>
  //             <img src={item.logo} className='dropdown-item' />
  //             {item?.name}
  //             <span>{item?.symbol?.toUpperCase()}</span>
  //           </div>
  //         ),
  //         ...item,
  //       });
  //     });
  //     setSelectData(finalArr[0]);
  //     setPopulatedList(finalArr);
  //   }
  // }, [coinsDetails]);

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  useEffect(() => {
    if (selectedSymbol !== 'eth' && populatedList?.length) {
      populatedList?.forEach((item) => {
        if (item?.symbol === selectedSymbol) {
          setSelectData(item);
        }
      });
    }
  }, [selectedSymbol, populatedList]);

  const resetSelectedItem = () => {
    if (selectedSymbol) {
      populatedList?.forEach((item) => {
        if (item?.symbol === selectedSymbol) {
          setSelectData(item);
        }
      });
    } else {
      setSelectData(populatedList[0]);
      setSelectedNetwork(networkOptions[0]);
    }
  };

  useEffect(() => {
    if (state) {
      let isShown = localStorage.getItem(
        'is-brine-withdrawal-instruction-shown'
      );
      if (!isShown) {
        setState(false);
        setOpenInstructionForWithdrawal(true);
        setDisableBackBtn(true);
        localStorage.setItem('is-brine-withdrawal-instruction-shown', true);
      }
    }
  }, [state]);

  const handleStarkwareConnect = () => {
    if (starknetAddress) {
      setSelectedNetwork(getNetworkOptionByName('starknet'));
      setSelectData(starknetCoinData[0]);
      // setRequestedNetwork(null);
    } else {
      setOpenStarknetPopup(true);
      setState(false);
      setisManualStarknetConnect(true);
    }
  };

  useEffect(() => {
    if (selectedOption?.value === 'normal' && state) {
      setSelectedDropDownOption(dropDownOptions[1]);
    } else if (selectedOption?.value === 'fast') {
      setSelectedDropDownOption(dropDownOptions[2]);
    }
  }, [selectedOption, state]);

  useEffect(() => {
    if (analytics && selectedData) {
      sendTrackEvent('coinChangeDetected', {
        coin: selectedData?.name,
        action: 'Withdrawal',
        network: selectedData?.network,
      });
    }
  }, [analytics, selectedData]);

  useEffect(() => {
    if (analytics && selectedData) {
      sendTrackEvent('withdrawalModeChanged', {
        coin: selectedOption?.value,
        action: 'Withdrawal',
        network: selectedData?.network,
      });
    }
  }, [analytics, selectedOption]);

  const handleGetFastWithdrawalGasFee = async () => {
    if (selectedData?.network !== 'starknet') {
      let res = await getFastWithdrawalGasFee(
        selectedData?.symbol,
        selectedData?.network
      );
      setFastWithdrawalGasFee(res);
    } else {
      setFastWithdrawalGasFee('1.00');
    }
  };

  useEffect(() => {
    if (selectedData?.value && state) {
      handleGetFastWithdrawalGasFee();
    }
  }, [selectedData?.value, state]);

  function convertGasFeeEthToCurrency(gasFeeInEth) {
    const selectedCurrencyPriceInUsdc = lastPrice(
      selectedData.currency + baseCurrency
    );
    const nativeCurrency = networkConfig[selectedData?.network]?.nativeSymbol;
    const ethPriceInUsdc = lastPrice(nativeCurrency + baseCurrency);
    const gasFeeInUsdc = gasFeeInEth * ethPriceInUsdc;
    const gasFeeInSelectedCurrency = gasFeeInUsdc / selectedCurrencyPriceInUsdc;

    return gasFeeInSelectedCurrency;
  }

  useEffect(() => {
    if (
      coinsDetails &&
      details &&
      !ethereumCoinData.length &&
      !polygonCoinData.length &&
      !selectedData
    ) {
      let { allData, ethData, polygonData, starknetData, optimismData } =
        getPopulatedData();
      setEthereumCoinData(ethData);
      setPolygonCoinData(polygonData);
      setPopulatedList(allData);
      setStarknetCoinData(starknetData);
      setOptimismCoinData(optimismData);
      setSelectData(allData[0]);
    }
  }, [coinsDetails, details?.payload]);

  const handleCoinAndNetworkChange = (coinData) => {
    if (selectedData?.value === coinData?.value) {
      return;
    }

    setFastWithdrawalGasFee(null);
    setSelectData(coinData);
    setSelectedNetwork(
      // coinData?.network === 'starknet'
      //   ? networkOptions[2]
      //   : coinData?.network === 'polygon'
      //   ? networkOptions[1]
      //   : networkOptions[0]
      getNetworkOptionByName(coinData?.network)
    );
  };

  const handleCoinByNetwork = (network) => {
    if (selectedNetwork?.network === network) {
      return;
    }
    let data = {
      ethereum: ethereumCoinData,
      polygon: polygonCoinData,
      optimism: optimismCoinData,
    };

    setFastWithdrawalGasFee(null);

    let allowedList = getAllowedWithdrawalCoinListByNetwork(network);
    let selectedSymbol = allowedList?.find((i) => i === selectedData?.symbol);

    if (selectedData?.symbol === selectedSymbol) {
      let results = populatedList?.filter(
        (data) => data?.symbol === selectedSymbol
      );
      let selectedCoinByNetwork = results?.find(
        (item) => item?.network === network
      );
      setSelectData(selectedCoinByNetwork);
      setSelectedNetwork(
        // network === 'polygon' ? networkOptions[1] : networkOptions[0]
        getNetworkOptionByName(network)
      );
      return;
    }

    setSelectData(data[network][0]);
    setSelectedNetwork(
      // network === 'polygon' ? networkOptions[1] : networkOptions[0]
      getNetworkOptionByName(network)
    );
  };

  useEffect(() => {
    if (priority?.symbol && populatedList?.length) {
      let results = populatedList?.find(
        (data) =>
          data?.symbol === priority?.symbol &&
          data?.network === priority?.network
      );
      setSelectData(results);
      setSelectedNetwork(
        // results?.network === 'polygon' ? networkOptions[1] : networkOptions[0]
        getNetworkOptionByName(results?.network)
      );
    }
  }, [priority, populatedList?.length, isWalletPage]);

  useEffect(() => {
    if (
      selectedData?.network === 'starknet' &&
      status === 'disconnected' &&
      state
    ) {
      toast.error('Please connect your starknet wallet to continue.', {
        toastId: 'switch__starknet_toast',
      });
      disconnect();
      setSelectedNetwork(networkOptions[0]);
      setSelectData(populatedList[0]);
    }
  }, [status, selectedData?.network, state]);

  const reset = () => {};

  // useEffect(() => {
  //   if (selectedData?.network) {
  //     handleCoinAndNetworkChange();
  //   }
  // }, [selectedData?.network, selectedNetwork?.network]);

  // console.log({ selectedNetwork });

  const getCoinStatus = () => {
    return selectedData?.network === 'starknet'
      ? details?.payload?.network_config?.STARKNET?.allowed_tokens_for_fast_wd?.includes(
          selectedData?.symbol
        )
      : selectedData?.network === 'ethereum'
      ? getInfoAboutCoins(selectedData?.currency)?.fast_withdrawals_enabled
      : details?.payload?.network_config?.POLYGON?.allowed_tokens_for_fast_wd?.includes(
          selectedData?.symbol
        );
  };

  useEffect(() => {
    if (details?.payload?.network_config && state) {
      if (!isUserFromBackController) {
        let allowedTokensForPolygon =
          details?.payload?.network_config?.POLYGON?.allowed_tokens_for_fast_wd;
        let isEthFastWithdrawalEnabled = getInfoAboutCoins(
          selectedData?.currency
        )?.fast_withdrawals_enabled;
        if (
          (allowedTokensForPolygon.includes(selectedData?.symbol) &&
            selectedData?.network === 'polygon') ||
          isEthFastWithdrawalEnabled
        ) {
          setSelectedOptions(withdrawOptions[1]);
        } else {
          setSelectedOptions(withdrawOptions[0]);
        }
      }
    }
  }, [
    details?.payload?.network_config,
    selectedData?.symbol,
    fastWithdrawalGasFee,
    state,
    isUserFromBackController,
  ]);

  const handleCallBackConnect = () => {
    if (isManualStarknetConnect) {
      setSelectedNetwork(getNetworkOptionByName('starknet'));
      setTimeout(() => {
        setisManualStarknetConnect(false);
        setOpenStarknetPopup(false);
        setState(true);
        setSelectData(starknetCoinData[0]);
      }, 0);
    }
  };

  const handleCoinChange = (val) => {
    // reset();
    // setSelectData(val);
    if (val?.network === 'starknet') {
      handleStarkwareConnect();
    } else {
      setIsUserFromBackController(false);
      handleCoinAndNetworkChange(val, val?.network);
    }
  };

  const handleNetworkChange = (val) => {
    if (val?.network === 'starknet') {
      handleStarkwareConnect();
    } else {
      setIsUserFromBackController(false);
      handleCoinByNetwork(val?.network);
    }
  };

  const showWithdrawalMode = () => {
    if (selectedNetwork?.network !== 'ethereum') {
      return false;
    }
    return selectedData?.fast_withdrawals_enabled || false;
  };

  return (
    <>
      <div className='deposit-popup'>
        {state && (
          <Popup
            state={state}
            setState={setState}
            clearState={() => {
              reset();
              resetSelectedItem();
            }}
          >
            {!populatedList || withdrawalLoading || !fastWithdrawalGasFee ? (
              <DepositLoader />
            ) : (
              <>
                <div className='deposit-popup-title h6'>Withdraw</div>
                <div
                  className='market__select deposit-dropdown-container'
                  style={{ width: '100%', marginTop: 30 }}
                >
                  <div className='deposit-popup-subtitle h6'>Network</div>
                  <DepositDropDown
                    options={networkOptions?.filter(
                      (option) => option.network !== 'scroll'
                    )}
                    value={selectedNetwork}
                    onChange={(val) => handleNetworkChange(val)}
                  />
                </div>
                <div
                  className='market__select deposit-dropdown-container'
                  style={{ width: '100%', marginTop: 30 }}
                >
                  <div className='deposit-popup-subtitle h6'>Asset</div>
                  {filteredCoinByNetwork.length && (
                    <DepositDropDown
                      options={filteredCoinByNetwork}
                      defaultValue={
                        selectedData ? selectedData : filteredCoinByNetwork[0]
                      }
                      value={selectedData}
                      onChange={(val) => handleCoinChange(val)}
                    />
                  )}
                </div>
                <div style={{ marginTop: 30 }}>
                  <div className='deposit-popup-subtitle h6'>Amount</div>
                  <DepositFormInput
                    active
                    value={amountToWithdraw}
                    onChange={(e) => setAmountToWithdraw(e.target.value)}
                    type='number'
                    onWheel={numberInputOnWheelPreventChange}
                    min='0'
                    placeholder='0'
                    onInput={maxLengthCheck}
                    subText={
                      selectedData?.symbol === baseCurrency ||
                      selectedData?.symbol === 'usdt' ||
                      selectedData?.symbol === 'usdc'
                        ? null
                        : `${(
                            Number(
                              lastPrice(
                                `${selectedData?.symbol}${baseCurrency}`
                              )
                            ) * Number(amountToWithdraw)
                          ).toFixed(2)} ${baseCurrency?.toUpperCase()}`
                    }
                    suffix={
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handlePercentInWithdraw(100)}
                      >
                        <h5 style={{ color: '#8173E0', fontSize: 13 }}>MAX</h5>
                      </div>
                    }
                    notesElement={
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <h5 style={{ color: '#808191' }}>
                              Available Balance to Withdraw
                            </h5>
                          </div>
                          <div>
                            <h5 style={{ color: '#808191' }}>
                              {getSingleCoinsBalance(selectedData?.currency)
                                ? getSingleCoinsBalance(
                                    selectedData?.currency
                                  ) +
                                  ' ' +
                                  selectedData?.symbol?.toUpperCase()
                                : '--'}
                            </h5>
                          </div>
                        </div>
                      </>
                    }
                  />
                  {/* {getSingleCoinsBalance(selectedData?.currency) != null &&
                    getSingleCoinsBalance(selectedData?.currency) != 0 && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontFamily: 'Poppins',
                          marginLeft: -23,
                          marginTop: -18,
                          marginBottom: 20,
                        }}
                      >
                        {perValue.map((value, idx) => (
                          <span
                            className='numbers_slider'
                            key={idx}
                            onClick={() => handlePercentInWithdraw(value)}
                          >
                            {value}%
                          </span>
                        ))}
                      </div>
                    )} */}
                  {/* <PurpleCard>
                    <div className='deposit__card_item'>
                      <div>
                        <div className='h6 subtitle' style={{ fontSize: 13 }}>
                          Available Balance to Withdraw
                        </div>
                      </div>
                      <div>
                        <Image
                          src={images.logoDark}
                          style={{ width: 62, height: 62 }}
                        />
                      </div>
                    </div>
                    <div className='deposit__card_item'>
                      <div>
                        <div className='h6 amount'>
                          {getSingleCoinsBalance(selectedData?.currency)
                            ? getSingleCoinsBalance(selectedData?.currency) +
                              ' ' +
                              selectedData?.symbol?.toUpperCase()
                            : '--'}
                        </div>
                      </div>
                      {selectedData?.symbol !== baseCurrency &&
                        selectedData?.symbol !== 'usdc' &&
                        selectedData?.symbol !== 'usdt' && (
                          <div className='usdc'>
                            {Number(
                              Number(
                                getSingleCoinsBalance(selectedData?.currency)
                              ) *
                                Number(
                                  lastPrice(
                                    `${selectedData?.symbol}${baseCurrency}`
                                  )
                                )
                            ).toFixed(2)}{' '}
                            {baseCurrency?.toUpperCase()}
                          </div>
                        )}
                    </div>
                  </PurpleCard> */}
                </div>
                {showWithdrawalMode() ? (
                  <div
                    className='market__select deposit-dropdown-container'
                    style={{ width: '100%', marginTop: 30 }}
                  >
                    <div className='deposit-popup-subtitle h6'>Choose Mode</div>
                    {populatedList.length && (
                      <DepositDropDown
                        isSearchable={false}
                        options={withdrawOptions}
                        value={selectedOption}
                        onChange={(val) => {
                          reset();
                          setSelectedOptions(val);
                        }}
                      />
                    )}
                  </div>
                ) : null}
                {selectedOption?.value !== 'fast' ? (
                  <div className='deposit-info-tiny' style={{ marginTop: 20 }}>
                    <p>
                      Normal Withdrawals require your attention{' '}
                      {NORMAL_WITHDRAWAL_CONFIRMATION_HOURS} hours after
                      initiation for final approval.
                    </p>
                    {/* <p>
                      Withdrawals require your attention 7 to 8 hours after
                      initiation for final approval.
                    </p> */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          color: '#8173E0',
                          cursor: 'pointer',
                          marginTop: 2,
                          width: 'fit-content',
                        }}
                        onClick={() => {
                          setState(false);
                          setOpenInstructionForWithdrawal(true);
                        }}
                      >
                        <span>
                          <Icon
                            icon='info-circle'
                            className='icon'
                            size={'12px'}
                            color='#8173E0'
                          />
                        </span>
                        Learn more
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className='estimated__gas'
                      style={{
                        marginTop: 23,
                      }}
                    >
                      <div className='h6'>Transaction fee</div>
                      <div
                        style={{
                          flexDirection: 'column',
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div className='h6 gas_amount'>
                          ~
                          {`${truncateToDecimalPlaces(
                            preventScientific(getFastWithdrawalFee()),
                            WITHDRAWAL_FEE_DECIMAL_POINTS
                          )} ${selectedData?.currency?.toUpperCase()}`}
                        </div>
                        <h3 style={{ fontSize: 10 }}>
                          ~
                          {`${truncateToDecimalPlaces(
                            lastPrice(selectedData?.currency + baseCurrency) *
                              getFastWithdrawalFee(),
                            WITHDRAWAL_FEE_DECIMAL_POINTS
                          )} ${baseCurrency?.toUpperCase()}`}
                          {/* {(
                        Number(
                          lastPrice(`${selectedData?.symbol}${baseCurrency}`)
                        ) * Number(feeAmount)
                      ).toFixed(2)}{' '} */}
                          {/* {baseCurrency?.toUpperCase()} */}
                        </h3>
                      </div>
                    </div>
                    {/* <label
                      className='checkbox exchange__item enable__force_withdrawal'
                      style={{
                        padding: 0,
                        marginLeft: 5,
                        marginBottom: 30,
                        width: 'fit-content',
                      }}
                    >
                      <input
                        className='checkbox__input'
                        name={'item'}
                        type='checkbox'
                        onChange={(e) =>
                          setEnableForceWithdrawal(e.target.checked)
                        }
                        // checked={false}
                        value={enableForceWithdrawal}
                      />
                      <span className='checkbox__in'>
                        <span className='checkbox__tick'></span>
                        <span
                          className='checkbox__text exchange__currency'
                          style={{
                            fontSize: 13,
                            color: '#808191',
                            fontFamily: 'Poppins',
                            marginTop: -2,
                          }}
                        >
                          Enable Force Withdrawal{' '}
                          <span
                            data-tip={
                              "Accelerate withdrawals with 'Enable Force Withdrawal' Agree to any gas fee for a swift transaction"
                            }
                            data-for={'enable_force_withdrawal'}
                          >
                            <Icon
                              icon='info-circle'
                              className='icon'
                              size={20}
                            />
                            <ReactTooltip
                              place='top'
                              type={isdark ? 'light' : 'dark'}
                              className='tooltip_styles price_details'
                              id={'enable_force_withdrawal'}
                            />
                          </span>
                        </span>
                      </span>
                    </label> */}
                    <div className='estimated__gas'>
                      <div className='h6'>Total</div>
                      <div
                        style={{
                          flexDirection: 'column',
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div className='h6 gas_amount'>
                          {`${truncateToDecimalPlaces(
                            Number(getFastWithdrawalFee()) +
                              Number(amountToWithdraw),
                            WITHDRAWAL_FEE_DECIMAL_POINTS
                          )} ${selectedData?.currency?.toUpperCase()}`}
                        </div>
                        <h3 style={{ fontSize: 10 }}>
                          ~
                          {`(${truncateToDecimalPlaces(
                            lastPrice(selectedData?.currency + baseCurrency) *
                              (Number(getFastWithdrawalFee()) +
                                Number(amountToWithdraw)),
                            WITHDRAWAL_FEE_DECIMAL_POINTS
                          )} ${baseCurrency.toUpperCase()})`}
                        </h3>
                      </div>
                    </div>
                  </>
                )}

                {status === 'connected' &&
                starknetAddress &&
                selectedData?.network === 'starknet' ? (
                  <>
                    <button
                      className='card__btn btn btn_border starknet__address_connect'
                      onClick={() => {
                        setState(false);
                        setOpenStarknetPopup(true);
                      }}
                      style={{ marginTop: 5 }}
                    >
                      <IconBtn
                        className='icon'
                        iconName='withdraw'
                        text={`Connected Starknet address - ${truncate(
                          starknetAddress,
                          12
                        )}`}
                      />
                    </button>
                  </>
                ) : null}

                {!showWithdrawalMode() && selectedOption?.value === 'fast' && (
                  <div className='deposit-info-tiny' style={{ marginTop: 20 }}>
                    <p>
                      Fast withdrawal is supported for this asset, enabling you
                      to access your funds in under 5 minutes.
                    </p>
                  </div>
                )}

                <div
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    marginTop: 20,
                  }}
                >
                  {processLoading ? (
                    <LoadingBtn style={{ display: 'block', width: '100%' }} />
                  ) : (
                    <>
                      {selectedOption.value == 'fast' ? (
                        <>
                          {getCoinStatus() ? (
                            <>
                              {Number(amountToWithdraw) <
                              Number(
                                getInfoAboutCoins(selectedData?.currency)
                                  ?.min_fast_withdrawal
                              ) ? (
                                <InsufficientBalanceBtn
                                  text={`Amount should be at least  ${
                                    selectedData.min_fast_withdrawal
                                  } ${selectedData?.currency?.toUpperCase()}`}
                                />
                              ) : (
                                <>
                                  {Number(
                                    getSingleCoinsBalance(
                                      selectedData?.currency
                                    )
                                  ) <
                                  Number(amountToWithdraw) +
                                    Number(getFastWithdrawalFee()) ? (
                                    <InsufficientBalanceBtn />
                                  ) : (
                                    <CustomButton
                                      onClick={handleDexFastWithdrawal}
                                      title={`Withdraw ${selectedData?.currency?.toUpperCase()}`}
                                      disabled={btnDisable}
                                      style={{
                                        display: 'block',
                                        width: '100%',
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <InsufficientBalanceBtn text='Currently Unavailable' />
                          )}
                        </>
                      ) : (
                        <>
                          {Number(
                            getSingleCoinsBalance(selectedData?.currency)
                          ) < amountToWithdraw ? (
                            <InsufficientBalanceBtn />
                          ) : (
                            <>
                              {selectedData?.network !== 'ethereum' ? (
                                <InsufficientBalanceBtn text='Currently Unavailable' />
                              ) : (
                                <CustomButton
                                  onClick={handleDexWithdrawal}
                                  title={`Withdraw ${selectedData?.currency?.toUpperCase()}`}
                                  disabled={btnDisable}
                                  style={{ display: 'block', width: '100%' }}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </Popup>
        )}
      </div>
      <div className='share__popup network__error'>
        <Popup
          state={openWithdrawalStartPopup}
          setState={setOpenWithdrawalStartPopup}
          closeonAnyPopupState={() => {
            setOpenVaultIdPopup(false);
            setState(false);
            setOpenInstructionForWithdrawal(false);
          }}
        >
          <div>
            <Lottie options={defaultOptions} height={200} width={200} />
            <p style={{ ...PopupStyles, marginBottom: 10, fontSize: 13 }}>
              {selectedOption.value === 'fast'
                ? `Your withdrawal is now processing and can be tracked in the history section. (ETA : ${
                    selectedData?.network === 'starknet'
                      ? '<25 mins'
                      : '<5 mins'
                  } )`
                : `Your withdrawal is now processing, please wait upto ${NORMAL_WITHDRAWAL_CONFIRMATION_HOURS} hours to redeem the withdrawal to your wallet 🙏.`}{' '}
            </p>
          </div>
        </Popup>
      </div>

      <div className='share__popup network__error'>
        <Popup
          state={openVaultIdPopup}
          setState={setOpenVaultIdPopup}
          closeonAnyPopupState={() => {
            setOpenWithdrawalStartPopup(false);
            setState(false);
            setOpenInstructionForWithdrawal(false);
          }}
        >
          <div>
            <Lottie
              options={processAnimationOptions}
              height={200}
              width={200}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  ...PopupStyles,
                }}
              >
                One time setup for withdrawal is in progress please try in a
                minute
              </p>
              <CustomButton
                title='Try Again'
                // style={{ marginTop: 20 }}
                onClick={handleTryAgain}
              />
            </div>
          </div>
        </Popup>
      </div>
      <div className='share__popup '>
        <Popup
          state={openInstructionForWithdrawal}
          setState={setOpenInstructionForWithdrawal}
          closeonAnyPopupState={() => {
            setOpenVaultIdPopup(false);
            setOpenWithdrawalStartPopup(false);
          }}
        >
          <InstructionForWithdrawal
            setState={setOpenInstructionForWithdrawal}
            disableBackBtn={disableBackBtn}
            backController={() => {
              setOpenInstructionForWithdrawal(false);
              setState(true);
              setDisableBackBtn(false);
              setIsUserFromBackController(true);
            }}
          />
        </Popup>
      </div>

      <div className='share__popup network__error'>
        <Popup
          state={openStarknetPopup}
          setState={setOpenStarknetPopup}
          clearState={() => setState(true)}
        >
          <StarknetConnectPopup
            handleCallBackConnect={handleCallBackConnect}
            setRequestedNetwork={(data) => console.log(data)}
          />
        </Popup>
      </div>
    </>
  );
}

export default WithdrawalPopup;
