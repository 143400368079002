import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomButton, Icon, Image } from '../../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { useSegment } from '../../../utils/hooks';

function NavSearch() {
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { sendTrackEvent } = useSegment();

  useEffect(() => {
    if (searchText.length) {
      const data = coinsDetails?.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setFilteredData(data);
    }
  }, [searchText]);

  return (
    <>
      <div
        className={`details__company ${
          filteredData.length !== 0 && searchText.length ? 'active' : ''
        } `}
        style={{
          width: '80%',
          position: 'absolute',
          bottom: filteredData.length >= 2 ? 250 : 220,
        }}
      >
        <div className='details__body' style={{ maxHeight: 120 }}>
          {filteredData.map((item, idx) => {
            return (
              <a
                key={idx}
                className='details__item'
                href={`/pricedetails/${item.symbol}`}
              >
                <div className='details__logo'>
                  <Image src={item.logo} alt='' />
                </div>
                <div className='details__desc'>
                  <div className='details__info'>{item.name}</div>
                  <div
                    className='details__currency'
                    style={{ textTransform: 'uppercase' }}
                  >
                    {item.symbol}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>

      <div className='sidebar__search' style={{ position: 'relative' }}>
        {userInfo?.token ? (
          <>
            {!searchText.length && (
              <button className='sidebar__start'>
                <Icon icon='search' className='icon' />
              </button>
            )}
            <input
              className='sidebar__input'
              type='search'
              style={{
                padding: !searchText.length ? '0 56px 0 24px' : '0 10px 0 24px',
              }}
              placeholder='Search'
              onChange={(e) => setSearchText(e.target.value)}
            />
          </>
        ) : (
          <div className='mobile-show'>
            <CustomButton
              title='Connect Wallet'
              style={{
                minWidth: '100%',
                marginBottom: 20,
              }}
              onClick={() => {
                history.push({
                  pathname: `/signin`,
                  search: location.search,
                });
                sendTrackEvent('buttonClicked', {
                  title: 'Connect Wallet',
                  action: 'Routing to Login Page',
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default NavSearch;
