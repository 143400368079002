import { useEffect, useState } from 'react';
import WalletTotalBalanceCard from '../wallet-total-balance-card/wallet-total-balance-card';
import {
  useBalance,
  useWebSocketData,
  useAnalytics,
  useSegment,
  useWalletMethods,
} from '../../../utils/hooks';
import {
  Icon,
  IconBtn,
  Image,
  Collapsible,
  Popup,
  CustomButton,
  LatestActivity,
  LockedBalance,
} from '../../../components';
import { useSelector } from 'react-redux';
import { getQueryParams } from '../../../utils/query-helper';
import { useHistory, useLocation } from 'react-router-dom';
import { EarningSvgIcon, HandsSvgIcon, images } from '../../../utils/image/img';
import { baseCurrency, baseDispayCurrency } from '../../../utils/constants';
import Decimal from 'decimal.js';
import { getDecimalJsInstance } from '../../../utils/decimal';

let faitData = [
  {
    name: 'inr',
    image: images.getFaitImg('inr'),
  },
  {
    name: 'usd',
    image: images.getFaitImg('usd'),
  },
  {
    name: 'aud',
    image: images.getFaitImg('aud'),
  },
  {
    name: 'CAD',
    image: images.getFaitImg('cad'),
  },
  {
    name: 'AED',
    image: images.getFaitImg('aed'),
  },
  {
    name: 'GBP',
    image: images.getFaitImg('gbp'),
  },
];

const options = [
  {
    label: 'Deposit',
    value: 'deposit',
  },
  {
    label: 'Fast',
    value: 'fast',
  },
  {
    label: 'Normal',
    value: 'normal',
  },
];

function WalletsTotalBalance({
  visibleSideBar,
  setVisibleSideBar,
  selectedData,
  setOpenBankDetailsPopup,
  openBankDetailsPopUp,
  setOpenDepositModal,
  setOpenWithdrawModal,
  dropDownOptions,
  setSelectedOptions,
  selectedOption,
  handleDepositOpen,
}) {
  const {
    getInfoAboutCoins,
    getInvestedSingleCoin,
    roundUp,
    formatCurrency,
    roundUpWithSymbol,
    getAvgPriceBalance,
    toFixedTrunc,
  } = useBalance();
  const { lastPrice } = useWebSocketData();
  const { analytics, sendTrackEvent } = useSegment();
  const {
    userDetails: { userData },
    userIpAddress: { ip },
    userLogin: { userInfo },
    getCoins: { loading: coinsLoading, error: coinsError, coinsDetails },
    depositDetails: { details },
  } = useSelector((state) => state);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);
  // const [faitDataState, setFaitDataState] = useState(faitData[0]);
  const [openLockBalance, setOpenLockBalance] = useState(false);

  const {
    // error,
    loading: assestLoading,
    assetsData,
  } = useSelector((state) => state.getAssests);

  const history = useHistory();
  const location = useLocation();

  const getCryptotoUSDC = () => {
    if (selectedData) {
      // Create Decimal objects for price and balance
      const priceDecimal = getDecimalJsInstance(
        lastPrice(`${selectedData.currency}${baseCurrency}`)
      );
      const balanceDecimal = getDecimalJsInstance(selectedData.balance);

      // Calculate the value using Decimal.js
      const valueDecimal = priceDecimal.times(balanceDecimal);

      let res;

      if (selectedData.currency === baseCurrency) {
        // Handle case where currency is the same as baseCurrency
        res = roundUpWithSymbol(selectedData.balance, baseCurrency);
      } else {
        // Convert valueDecimal to a string with desired precision
        res = roundUpWithSymbol(valueDecimal.toString(), baseCurrency);
      }

      setCurrentAmount(res);
    }
  };

  function percentage(partialValue, totalValue) {
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  useEffect(() => {
    let mounted = true;
    if (selectedData && mounted) {
      setInvestedAmount(
        getInvestedSingleCoin(selectedData.currency, selectedData.balance)
      );
      getCryptotoUSDC();
    }
    return () => {
      setInvestedAmount(0);
      mounted = false;
    };
  }, [visibleSideBar, selectedData]);

  // useEffect(() => {
  //   let id = setInterval(() => {
  //     let item = faitData[Math.floor(Math.random() * faitData.length)];
  //     setFaitDataState(item);
  //   }, 1700);

  //   return () => {
  //     clearInterval(id);
  //   };
  // }, []);

  return (
    <>
      {selectedData && (
        <div className='wallets__body'>
          {selectedData.locked && (
            <div
              className='wallets__code'
              style={{ fontSize: 13, marginBottom: 8 }}
            >
              🔒 Locked balance - {selectedData?.locked}{' '}
              {selectedData?.currency?.toUpperCase()}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenLockBalance((prev) => !prev)}
              >
                <Icon
                  icon='info-circle'
                  className='icon'
                  size={'12px'}
                  color='#6C5DD3'
                />
              </span>
            </div>
          )}
          <div className='wallets__line'>
            <div className={'wallets__money'}>
              {roundUpWithSymbol(selectedData.balance, selectedData.currency)}
            </div>

            <div
              className='wallets__currency'
              style={{
                textTransform: 'uppercase',
                // color: '#808191',
                // marginLeft: 5,
                // fontFamily: 'Poppins',
                // fontSize: 18,
              }}
            >
              {selectedData.currency}
            </div>
          </div>

          {selectedData.currency !== `usdc` &&
            selectedData.currency !== 'usdt' &&
            assetsData && (
              <div className='wallets__code'>
                {formatCurrency(toFixedTrunc(currentAmount, 3))}{' '}
                {baseCurrency?.toUpperCase()}
              </div>
            )}

          <div className='card__btns card__btns_mt20'>
            <button
              className='card__btn btn btn_orange'
              onClick={() => {
                handleDepositOpen();
                setSelectedOptions(dropDownOptions[0]);
                sendTrackEvent('buttonClicked', {
                  tittle: 'Deposit',
                  action: 'Opening deposit popup',
                });
              }}
            >
              <IconBtn className='icon' iconName='withdraw' text={'Deposit'} />
            </button>
            <button
              className='card__btn btn btn_border'
              onClick={() => {
                setOpenWithdrawModal(true);
                setSelectedOptions(dropDownOptions[1]);
                // analytics?.track('Clicked withdraw section', {
                //   from: 'Wallet Page',
                //   symbol: selectedData.currency,
                //   uid: userInfo?.payload?.uid,
                //   QueryParams: { ...getQueryParams() },
                //   ip: ip,
                // });
                sendTrackEvent('buttonClicked', {
                  tittle: 'Withdraw',
                  action: 'Opening withdraw popup',
                });
              }}
            >
              <IconBtn className='icon' iconName='deposit' text={'Withdraw'} />
            </button>
          </div>

          {/* <div
            className={`select_wallet_item`}
            onClick={() => {
              history.push('/simplex');
            }}
          >
            <div className='select_wallet_img'>
              <img
                src={faitDataState.image}
                // className={`${title == 'Social Login' ? 'blink-image' : ''}`}
                style={{ width: '90px' }}
              />
            </div>
            <span>Buy with Cash ({faitDataState.name?.toUpperCase()})</span>
          </div> */}

          <LatestActivity
            selectedOption={selectedOption}
            setSelectedOptions={setSelectedOptions}
          />

          {selectedData?.currency !== baseCurrency &&
            selectedData?.currency !== 'usdt' &&
            selectedData?.currency !== 'usdc' && (
              <Collapsible
                cb={() => {
                  sendTrackEvent('buttonClicked', {
                    tittle: 'See More',
                    action:
                      'Expand and collapse the user’s portfolio and avg price',
                  });
                }}
                closeLabel={
                  <CollapseTitle
                    text={'See more'}
                    icon={
                      <i
                        className='fas fa-chevron-down'
                        style={{
                          color: '#8173E0',
                        }}
                      ></i>
                    }
                  />
                }
                openLabel={
                  <CollapseTitle
                    text={'See less'}
                    icon={
                      <i
                        className='fas fa-chevron-up'
                        style={{
                          color: '#8173E0',
                        }}
                      ></i>
                    }
                  />
                }
              >
                {/* <div className='wallet-analytics'>
                  <div className='wallet-analytics-item'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <div>
                        <Image
                          src={images.investedColored}
                          style={{ width: 20, height: 20 }}
                        />
                      </div>
                      <div
                        className='wallets__category'
                        style={{
                          fontFamily: 'Poppins',
                          marginLeft: 5,
                          fontSize: 13,
                        }}
                      >
                        Invested
                      </div>
                    </div>

                    <div className='wallet-money'>
                      {baseDispayCurrency?.symbol?.toUpperCase()}
                      {investedAmount != 0
                        ? formatCurrency(investedAmount?.toFixed(3))
                        : 0}{' '}
                    </div>
                  </div>
                  <div className='wallet-analytics-item'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <div>
                        <Image
                          src={images.returnsColored}
                          style={{ width: 20, height: 20 }}
                        />
                      </div>
                      <div
                        className='wallets__category'
                        style={{
                          fontFamily: 'Poppins',
                          marginLeft: 5,
                          fontSize: 13,
                        }}
                      >
                        Returns
                      </div>
                    </div>
                    <div
                      className={`wallet-money   ${
                        parseFloat(currentAmount) < parseFloat(investedAmount)
                          ? 'status negative'
                          : 'status positive'
                      }`}
                    >
                      {selectedData.balance == 0.0 ||
                      currentAmount === '0' ||
                      Number(currentAmount).toFixed(3) -
                        Number(investedAmount).toFixed(3) ==
                        0
                        ? `0%`
                        : percentage(investedAmount, currentAmount) === '-0.00%'
                        ? '0.00%'
                        : percentage(investedAmount, currentAmount)}
                    </div>
                  </div>
                  <div className='wallet-analytics-item'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <div>
                        <Image
                          src={images.currenctColored}
                          style={{ width: 20, height: 20 }}
                        />
                      </div>
                      <div
                        className='wallets__category'
                        style={{
                          fontFamily: 'Poppins',
                          marginLeft: 5,
                          fontSize: 13,
                        }}
                      >
                        Current
                      </div>
                    </div>
                    <div className='wallet-money'>
                      {baseDispayCurrency?.symbol?.toUpperCase()}
                      {selectedData.balance == 0.0 || currentAmount == '0'
                        ? ' 0'
                        : formatCurrency(
                            Number(currentAmount)?.toFixed(3)
                          )}{' '}
                    </div>
                  </div>
                  {getAvgPriceBalance(selectedData?.currency) && (
                    <div className='wallet-analytics-item'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <Image
                            src={images.avgPriceColored}
                            style={{ width: 20, height: 20 }}
                          />
                        </div>
                        <div
                          className='wallets__category'
                          style={{
                            fontFamily: 'Poppins',
                            marginLeft: 5,
                            fontSize: 13,
                          }}
                        >
                          Avg Buying Price
                        </div>
                      </div>
                      <div className='wallet-money'>
                        {baseDispayCurrency?.symbol?.toUpperCase()}
                        {formatCurrency(
                          Number(
                            getAvgPriceBalance(selectedData?.currency)
                          ).toFixed(2),
                          selectedData?.currency
                        )}
                      </div>
                    </div>
                  )}
                </div> */}
                {selectedData.currency !== `usdc` &&
                  selectedData.currency !== 'usdt' &&
                  assetsData && (
                    <div className='wallets__parameters'>
                      <div className='wallets__parameter'>
                        <div className='wallets__preview'>
                          {selectedData && (
                            <img
                              src={
                                getInfoAboutCoins(selectedData?.currency)?.logo
                              }
                              alt=''
                            />
                          )}
                        </div>
                        <div className='wallets__box'>
                          <div
                            className='wallets__category'
                            style={{ fontFamily: 'Poppins' }}
                          >
                            Invested
                          </div>
                          <div className={`wallets__number  h6`}>
                            {' '}
                            {investedAmount != 0
                              ? formatCurrency(toFixedTrunc(investedAmount, 3))
                              : 0}{' '}
                            {baseCurrency?.toUpperCase()}
                          </div>
                          <div
                            className={`wallets__price small ${
                              parseFloat(currentAmount) <
                              parseFloat(investedAmount)
                                ? 'negative'
                                : ''
                            } `}
                          >
                            {' '}
                            Return{' '}
                            {selectedData.balance == 0.0 ||
                            currentAmount === '0' ||
                            Number(currentAmount).toFixed(3) -
                              Number(investedAmount).toFixed(3) ==
                              0
                              ? `0%`
                              : percentage(
                                  Number(investedAmount),
                                  Number(currentAmount)
                                ) === '-0.00%'
                              ? '0.00%'
                              : percentage(
                                  Number(investedAmount),
                                  Number(currentAmount)
                                )}
                          </div>
                        </div>
                      </div>
                      <div className='wallets__parameter'>
                        <div className='wallets__preview'>
                          <HandsSvgIcon />
                        </div>
                        <div className='wallets__box'>
                          <div
                            className='wallets__category'
                            style={{ fontFamily: 'Poppins' }}
                          >
                            Current
                          </div>
                          <div className={`wallets__number h6`}>
                            {selectedData.balance == 0.0 || currentAmount == '0'
                              ? ' 0'
                              : formatCurrency(
                                  toFixedTrunc(currentAmount, 3)
                                )}{' '}
                            {baseCurrency?.toUpperCase()}
                          </div>
                          <div
                            className={`wallets__price small  ${
                              parseFloat(currentAmount) <
                              parseFloat(investedAmount)
                                ? 'negative'
                                : ''
                            }`}
                          >
                            Total{' '}
                            {parseFloat(currentAmount) <
                            parseFloat(investedAmount)
                              ? 'Loss'
                              : 'Profit'}
                            {/* {parseFloat(currentAmount) < parseFloat(investedAmount)
                        ? ' '
                        : ' +'} */}
                            {currentAmount == 0 ||
                            investedAmount == 0 ||
                            isNaN(investedAmount) ||
                            isNaN(currentAmount) ||
                            Number(currentAmount).toFixed(3) -
                              Number(investedAmount).toFixed(3) ==
                              0
                              ? ' 0'
                              : ' ' +
                                formatCurrency(
                                  Number(
                                    currentAmount -
                                      investedAmount.toString().replace('-', '')
                                  ).toFixed(4)
                                )}{' '}
                          </div>
                        </div>
                      </div>
                      {getAvgPriceBalance(selectedData?.currency) && (
                        <div className='wallets__parameter'>
                          <div className='wallets__preview'>
                            <EarningSvgIcon />
                          </div>
                          <div className='wallets__box'>
                            <div
                              className='wallets__category'
                              style={{ fontFamily: 'Poppins' }}
                            >
                              Avg. Buy Price
                            </div>
                            <div className={`wallets__number h6`}>
                              {formatCurrency(
                                Number(
                                  getAvgPriceBalance(selectedData?.currency)
                                ).toFixed(2),
                                selectedData?.currency
                              )}{' '}
                              {baseCurrency?.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                <WalletTotalBalanceCard
                  setOpenBankDetailsPopup={setOpenBankDetailsPopup}
                  openBankDetailsPopUp={openBankDetailsPopUp}
                  visibleSideBar={visibleSideBar}
                  setVisibleSideBar={setVisibleSideBar}
                  selectedData={selectedData}
                />
              </Collapsible>
            )}
          <LockedBalance
            state={openLockBalance}
            setState={setOpenLockBalance}
          />
        </div>
      )}
    </>
  );
}

export default WalletsTotalBalance;

function CollapseTitle({ text, icon }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20,
        cursor: 'pointer',
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <div
        className='h6'
        style={{
          color: '#808191',
          fontSize: 13,
        }}
      >
        {text}
      </div>
      <div
        style={{
          marginLeft: 8,
        }}
      >
        <span>{icon}</span>
      </div>
    </div>
  );
}
