import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { USER_CAN_REFERRAL_COUNT } from '../../../../utils/constants';
import { getQueryParams } from '../../../../utils/query-helper';
import { Icon, Image } from '../../../../components';
import { images } from '../../../../utils/image/img';
import { Link } from 'react-router-dom';
import { useSegment } from '../../../../utils/hooks';

function InviteCard({ analytics }) {
  // const {
  // getReferralCode: { loading, response, error },
  // userLogin: { userInfo },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, response, error } = useSelector(
    (state) => state.getReferralCode
  );

  const { sendTrackEvent } = useSegment();
  const referralCodeInputRef = useRef();

  const handleCopy = () => {
    sendTrackEvent('buttonClicked', {
      title: 'Referral Code Copy icon',
    });
    if (referralCodeInputRef?.current) {
      referralCodeInputRef?.current?.select();
    }
    if (navigator.clipboard) {
      toast.success('Copied to clipboard, You can share the link');
      navigator.clipboard.writeText(
        `${window.location.host}/?referral_code=${response?.payload?.referral_code}`
      );
    } else {
      //console.log('In http connection ~ 🎯');
    }
  };

  return (
    <div className='popup__invite' style={{ position: 'relative' }}>
      <div className='popup__info h6'>
        Invite your friends to Brine; for every trade they make, you get 40% of
        their trading fee and they get 10%!
        {/* <br /> */}
        {/* {!referralCountError && (
          <div className='referral_count_remaining'>
            {!referralCountLoading && (
              <span>
                🚨{' '}
                {totalReferrals?.payload?.total_invited >=
                USER_CAN_REFERRAL_COUNT
                  ? 0
                  : USER_CAN_REFERRAL_COUNT -
                    totalReferrals?.payload?.total_invited}{' '}
              </span>
            )}
            {USER_CAN_REFERRAL_COUNT - totalReferrals?.payload?.total_invited ==
            1
              ? 'invite'
              : 'invites'}{' '}
            remaining
          </div>
        )} */}
      </div>
      {userInfo?.token && (
        <div className='popup__label'>Your Referral Code</div>
      )}
      <div className='popup__wrap'>
        {!userInfo?.token ? (
          <Link
            to={(location) => ({ ...location, pathname: '/signin' })}
            className='slider__btn btn  referral_code_connect'
          >
            Connect wallet to start inviting
          </Link>
        ) : (
          <>
            <input
              className='popup__input'
              defaultValue={
                userInfo?.token ? response?.payload?.referral_code : '--------'
              }
              type='text'
              id='referral-input'
              readOnly
              ref={referralCodeInputRef}
            />
            <button className='popup__copy' onClick={handleCopy}>
              <Icon icon='copy' className='icon' color='#6c5dd3' />
            </button>
          </>
        )}
      </div>
      <Image
        src={images.referralCodeBanner}
        style={{
          width: !userInfo?.token ? 70 : 100,
        }}
      />
    </div>
  );
}

export default InviteCard;
