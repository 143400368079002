import { useSegment } from '../../../../../utils/hooks';

function TableCell({
  data,
  isaccountDetails,
  name,
  value,
  redirectFunc,
  disableLink,
}) {
  const { sendTrackEvent } = useSegment();
  return (
    <>
      {!isaccountDetails ? (
        <tr
          onClick={() => {
            redirectFunc(data.link);
            sendTrackEvent('buttonClicked', {
              title: data?.name,
              action: 'Opening link',
              link: data.link,
            });
          }}
        >
          <td>
            <a
              href={disableLink ? '#' : data.link}
              target={disableLink ? '_parent' : '_blank'}
              style={{ color: '#808191', fontSize: 13 }}
            >
              {data.name}
            </a>
          </td>
          <td>
            <a
              href={disableLink ? '#' : data.link}
              target={disableLink ? '_parent' : '_blank'}
              className='why__kyc'
            >
              <i className='fas fa-arrow-right icon'></i>
            </a>
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            <p style={{ color: '#808191', fontSize: 13 }}>{name}</p>
          </td>
          <td style={{ textAlign: 'right' }}>
            <p className='why__kyc'>{value}</p>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableCell;
