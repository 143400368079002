import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Settings from '../../pages/settings/settings';
import Header from '../header/header';
import Navbar from '../navbar/navbar';
import { useStopwatch } from 'react-timer-hook';
import { changeCurrentNetworkState, logout } from '../../actions/userAction';
import {
  useEagerConnect,
  useInactiveListener,
  useWalletMethods,
} from '../../utils/hooks';
import { useWeb3React } from '@web3-react/core';
import { walletconnect } from '../../utils/connectors/connectors';
import { Feedback, MotionContainer, Tawk } from '../../components';
import { toast } from 'react-toastify';
import { changeTheme } from '../../actions/themeAction';
import { sanitizeProvider } from '../../utils/sanitize-provider';
import { walletConnect } from '../../utils/connectors-v8/walletConnect';
import { walletConnectV2 } from '../../utils/connectors-v8/walletConnectV2';
import {
  ALLOWED_NETWORK_ID,
  ALLOWED_OPTIMISM_NETWORK_ID,
  ALLOWED_POLYGON_NETWORK_ID,
  ALLOWED_SCROLL_NETWORK_ID,
} from '../../utils/constants';
import { networkMap } from '../../utils/hooks/useWallets';
import LeaderboardBannerPopup from '../common/leaderboard-banner-popup/leaderboard-banner-popup';
import { getLeaderBoardCampaign } from '../../actions/leaderboard';
import { prefix } from '../../store';

function Layout({
  children,
  isExchangePage,
  isNotificationPage,
  isWalletPage,
  showborder,
  isHomePage,
  tradeMode,
  setTradeMode,
  reduceZIndex,
}) {
  const {
    active,
    error: errorContext,
    account: accountWL,
    provider: library,
    connector,
    chainId,
    isActive,
    isActivating,
  } = useWeb3React();
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);
  const { state: currentNetworkData } = useSelector((s) => s.ethNetworkDetails);

  // const {
  // navbar: { open },
  // setting: { open: settingOpen },
  // userVia: { state: userVia },
  // theme: { isdark },
  // userLogin: { userInfo },
  // campaignsList: {
  //   response: campaignsResponse,
  //   loading: campaignsLoading,
  //   error: campaignsError,
  // },
  // } = useSelector((state) => state);

  const {
    response: campaignsResponse,
    loading: campaignsLoading,
    error: campaignsError,
  } = useSelector((state) => state.campaignsList);
  const { isdark } = useSelector((state) => state.theme);
  const { state: userVia } = useSelector((state) => state.userVia);
  const { open: settingOpen } = useSelector((state) => state.setting);
  const { open } = useSelector((state) => state.navbar);

  const { provider, walletDeactivate } = useWalletMethods();
  const location = useLocation();
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const [ethereum, setEthereum] = useState(null);
  const [openFeedBackPopup, setOpenFeedBackPopup] = useState(false);
  const [openLeaderboardPopup, setOpenLeaderboardPopup] = useState(false);

  const { minutes, start, reset, isRunning, pause } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        if (!isRunning) {
          start();
        }
      } else {
        reset();
        pause();
      }
    });
    if (!document.hidden && minutes >= 10) {
      window.location.reload();
    }
  }, [document.hidden, minutes]);

  useEffect(() => {
    if (userVia) {
      if (userVia?.via == 'metamask') {
        if (ethereum) {
          ethereum.on('connect', (connectInfo) => {});
          ethereum.on('accountsChanged', (accounts) => {
            walletDeactivate();
            dispatch(logout(true));
          });
          ethereum.on('chainChanged', (chainId) => {
            if (
              chainId &&
              Number(chainId) !== ALLOWED_NETWORK_ID &&
              Number(chainId) !== ALLOWED_POLYGON_NETWORK_ID &&
              Number(chainId) !== ALLOWED_SCROLL_NETWORK_ID &&
              Number(chainId) !== ALLOWED_OPTIMISM_NETWORK_ID
            ) {
              walletDeactivate();
              dispatch(logout(true));
            }
          });
        }
      }
    }
  }, [ethereum, userVia, location, active]);

  useEffect(() => {
    sanitizeProvider(setEthereum);
  }, []);

  useEffect(() => {
    if (walletConnect?.provider) {
      walletConnect.provider.on('disconnect', () => {
        // toast.error('Your wallet was disconnected; please log in again');
        walletDeactivate();
        dispatch(logout(true));
      });
    }
  }, [walletConnect?.provider]);

  // useEffect(() => {
  //   if (walletConnect?.provider) {
  //     walletConnect?.provider.on('chainChanged', (accounts) => {
  //       if (userVia?.address && accounts?.account !== userVia?.address) {
  //         walletDeactivate();
  //         dispatch(logout(true));
  //       }
  //     });

  //     walletConnect?.provider.on('accountsChanged', () => {
  //       walletDeactivate();
  //       dispatch(logout(true));
  //     });

  //     walletConnect?.provider.on('networkChanged', () => {
  //       walletDeactivate();
  //       dispatch(logout(true));
  //     });
  //   }
  // }, [walletConnect?.provider]);

  useEffect(() => {
    if (walletConnectV2?.provider) {
      walletConnectV2.provider.on('disconnect', () => {
        // toast.error('Your wallet was disconnected; please log in again');
        walletDeactivate();
        dispatch(logout(true));
      });
    }
  }, [walletConnect?.provider]);

  useEffect(() => {
    if (walletConnectV2?.provider) {
      walletConnectV2?.provider.on('Web3ReactUpdate', (accounts) => {
        if (userVia?.address && accounts?.account !== userVia?.address) {
          walletDeactivate();
          dispatch(logout(true));
        }
      });

      walletConnectV2?.provider.on('Web3ReactDeactivate', () => {
        walletDeactivate();
        dispatch(logout(true));
      });

      walletConnectV2?.provider.on('chainChanged', (accounts) => {
        if (userVia?.address && accounts?.account !== userVia?.address) {
          walletDeactivate();
          dispatch(logout(true));
        }
      });

      walletConnectV2?.provider.on('accountsChanged', () => {
        walletDeactivate();
        dispatch(logout(true));
      });

      walletConnectV2?.provider.on('networkChanged', () => {
        walletDeactivate();
        dispatch(logout(true));
      });

      walletConnectV2?.provider.on('disconnect', () => {
        walletDeactivate();
        dispatch(logout(true));
      });

      walletConnectV2?.provider?.enable().catch((error) => {
        // Handle error while enabling provider
      });
    }
  }, [walletConnectV2?.provider]);

  useEffect(() => {
    let simplexThemeChange =
      localStorage.getItem('simplex-theme-change') || null;
    if (simplexThemeChange === 'true') {
      dispatch(changeTheme(isdark));
      localStorage.removeItem('simplex-theme-change');
    }
  }, []);

  const handleUserScroll = () => {
    let allInputElement = document.querySelectorAll('input');
    allInputElement?.forEach((item) => {
      if (item.type === 'number') {
        item.blur();
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleUserScroll);
    return () => {
      window.removeEventListener('scroll', handleUserScroll);
    };
  }, []);

  useEffect(() => {
    if (chainId) {
      dispatch(
        changeCurrentNetworkState({
          queuedCoin: null,
          network: networkMap[Number(chainId)],
          loading: false,
          chainId: Number(chainId),
        })
      );
    }
  }, [chainId, dispatch]);

  useEffect(() => {
    if (!campaignsResponse) {
      dispatch(getLeaderBoardCampaign());
    }
  }, [campaignsResponse]);

  useEffect(() => {
    if (campaignsResponse?.payload?.length && userVia?.address) {
      let key = `${campaignsResponse?.payload[0]?.campaign_uid}-${userVia?.address}`;
      let res = localStorage.getItem(key) || null;
      if (!res) {
        setOpenLeaderboardPopup(true);
      }
    }
  }, [campaignsResponse, userVia?.address]);

  // useEffect(() => {
  //   if (
  //     userInfo?.token?.access &&
  //     userVia?.via === 'metamask' &&
  //     !isActive &&
  //     isActivating &&
  //     !account &&
  //     !chainId
  //   ) {
  //     toast.error('Session expired, Please login again!');
  //     setTimeout(() => {
  //       dispatch(logout(true));
  //     }, 0);
  //   }
  // }, [userInfo?.token?.access, isActive, isActivating, account, chainId]);

  // useEffect(() => {
  //   function checkConnection() {
  //     ethereum
  //       .request({ method: 'eth_accounts' })
  //       .then((acc) => {
  //         console.log({ checkConnection: acc });
  //         if (!acc?.length) {
  //           toast.error('Session expired, Please login again!');
  //           setTimeout(() => {
  //             dispatch(logout(true));
  //           }, 0);
  //         }
  //       })
  //       .catch(console.error);
  //   }
  //   if (ethereum && userInfo?.token?.access && userVia?.via === 'metamask') {
  //     checkConnection();
  //   }
  // }, [ethereum, userInfo, userVia]);

  return (
    <MotionContainer>
      {
        <div
          className={`page ${isExchangePage ? 'full' : ''} ${
            !open ? 'wide' : ''
          }`}
        >
          <div className='page__content'>
            <Navbar
              isExchangePage={isExchangePage}
              reduceZIndex={reduceZIndex}
            />
            <Header
              isWalletPage={isWalletPage}
              isHomePage={isHomePage}
              isExchangePage={isExchangePage}
              isNotificationPage={isNotificationPage}
              showborder={showborder}
              tradeMode={tradeMode}
              setTradeMode={setTradeMode}
            />
            {children}
            {settingOpen ? (
              <Settings setOpenFeedBackPopup={setOpenFeedBackPopup} />
            ) : null}
            <Feedback
              openFeedBackPopup={openFeedBackPopup}
              setOpenFeedBackPopup={setOpenFeedBackPopup}
            />
          </div>

          {/* <Tawk showTawk={(isExchangePage && !tradeMode) || !isExchangePage} /> */}
        </div>
      }
      <LeaderboardBannerPopup
        openLeaderboardPrompt={openLeaderboardPopup}
        setOpenLeaderboardPrompt={setOpenLeaderboardPopup}
      />
    </MotionContainer>
  );
}

export default Layout;
