import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function Tooltip({ position }) {
  const { isdark } = useSelector((state) => state.theme);
  return (
    <ReactTooltip
      place={position ? position : 'right'}
      type={isdark ? 'light' : 'dark'}
      effect='solid'
      className='tooltip_styles'
    />
  );
}

export default Tooltip;
