import React from 'react';
import { useSegment } from '../../../utils/hooks';

const DepositLinkText = ({ text, link, type = 'link', ...otherProps }) => {
  const { sendTrackEvent } = useSegment();
  return (
    <div
      style={{
        marginTop: 20,
        textAlign: 'center',
      }}
      {...otherProps}
    >
      <a
        href={link}
        target='_blank'
        referrerPolicy='no-referrer'
        className='deposit_video_tutorial'
        onClick={() =>
          sendTrackEvent('buttonClicked', {
            title: text,
            action: 'Opening link',
          })
        }
      >
        {text}
        {'   '}
        {type == 'link' ? (
          <i className='fas fa-external-link-alt'></i>
        ) : (
          <i className='fab fa-youtube'></i>
        )}
      </a>
    </div>
  );
};

export default DepositLinkText;
