import React from 'react';
import { useWebSocketData } from '../../../utils/hooks/index';

function DotRenderer({ children, ...otherProps }) {
  const { getSocketFunctionalityInfo } = useWebSocketData();

  return (
    <>
      {getSocketFunctionalityInfo().full ? (
        children
      ) : (
        <div
          className='dot-flashing small'
          style={{ marginTop: 8, marginLeft: 19 }}
          {...otherProps}
        ></div>
      )}
    </>
  );
}

export default DotRenderer;
