import axios from 'axios';
import * as exchangeConstants from '../constants/exchangeConstant';
import { baseUrl, networkName } from '../utils/urls';
// import ReconnectingWebSocket from 'reconnecting-websocket';

// export let ws = new ReconnectingWebSocket(`${webSocketUrl}/liveorderbookwire`);

// export const getOrderBook = () => async (dispatch) => {
//   ws.onopen = () => {
//     // on connecting, do nothing but log it to the console
//     dispatch({
//       type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST,
//     });
//     //console.log('connected');
//   };

//   ws.onmessage = (evt) => {
//     // listen to data sent from the websocket server
//     if (evt.data !== 'Connection established with Brine') {
//       const message = JSON.parse(evt.data);
//       dispatch({
//         type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_SUCCESS,
//         payload: message,
//       });
//     }
//   };

//   ws.onclose = () => {
//     //console.log('disconnected');
//     dispatch({
//       type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_FAIL,
//     });
//     setTimeout(() => {
//       ws.onopen = () => {
//         // on connecting, do nothing but log it to the console
//         dispatch({
//           type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST,
//         });
//         //console.log('connected');
//       };
//     }, 500);
//   };

//   ws.onerror = (err) => {
//     console.error('Socket encountered error: ', err.message, 'Closing socket');
//     dispatch({
//       type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_FAIL,
//       // payload: 'Something went wrong',
//     });

//     setTimeout(() => {
//       ws.onopen = () => {
//         // on connecting, do nothing but log it to the console
//         dispatch({
//           type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST,
//         });
//         //console.log('connected');
//       };
//     }, 500);
//   };
// };

export const createOrder = (obj) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: exchangeConstants.CREATE_ORDER_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/user/stark/createorder/`,
      obj,
      config
    );

    dispatch({
      type: exchangeConstants.CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: exchangeConstants.CREATE_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const cancelOrder = (id) => async (dispatch, getState) => {
  //console.log(id);
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: exchangeConstants.CANCEL_ORDER_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/user/cancelorder/`,
      { order_id: id },
      config
    );

    dispatch({
      type: exchangeConstants.CANCEL_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: exchangeConstants.CANCEL_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOrderNonce = async (token, reqObj) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/user/stark/getordernonce/`,
      reqObj,
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const getAllTrades = async (token, symbol) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    let baseTradeUrl =
      networkName === 'mainnet'
        ? `${baseUrl}/market/trades/?market=${symbol}`
        : `${baseUrl}/market/public-trades/?market=${symbol}`;

    const { data } = await axios.get(baseTradeUrl, config);
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
