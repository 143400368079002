import { toast } from 'react-toastify';

export const sanitizeProvider = async (setState) => {
  if (typeof window.ethereum !== 'undefined') {
    // edge case if MM and CBW are both installed
    if (window.ethereum?.providers?.length) {
      window.ethereum.providers.forEach((p) => {
        if (p?.isMetaMask) {
          setState(p);
        }
      });
    } else {
      if (window.ethereum?.isMetaMask) {
        setState(window?.ethereum);
      }
    }
  }
};

export const checkUserHaveCoinBaseExtension = () => {
  let eth = window.ethereum;
  if (typeof eth !== 'undefined') {
    // check if user using coinbase extension
    if (eth?.providers && eth?.providers?.length) {
      eth.providers.forEach((p) => {
        if (p?.isCoinbaseWallet) {
          toast.error(
            'It seems that coinbase extension is not working; please try to remove the extension and use the coinbase mobile app.'
          );
        }
      });
    } else {
      if (eth.isCoinbaseWallet) {
        toast.error(
          'It seems that coinbase extension is not working; please try to remove the extension and use the coinbase mobile app.'
        );
      }
    }
  }
};
