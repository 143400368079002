import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import notFoundedAnimation from '../../../assests/json/not-found.json';
import { images } from '../../../utils/image/img';
import Image from '../img/image';

function NotFoundedAnimation({ text, size = 'lg', textStyle }) {
  const NotFoundedAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: notFoundedAnimation,
  };

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        padding: 10,
        marginTop: 60,
      }}
    >
      {/* <Lottie
        options={NotFoundedAnimationOptions}
        height={size == 'sm' ? 300 : size == 'x-sm' ? 100 : 400}
        width={size == 'sm' ? 300 : size == 'x-sm' ? 100 : 400}
      /> */}
      <Image
        src={size == 'x-sm' ? images.magnifyingSmImage : images.notFounded}
        alt='not found image'
        style={{
          // height: size == 'sm' ? 220 : size == 'x-sm' ? 80 : 200,
          maxWidth: size == 'sm' ? 300 : size == 'x-sm' ? 80 : 400,
          objectFit: 'cover',
        }}
      />
      {text && (
        <div
          style={{
            // marginTop: size == 'x-sm' ? -10 : -42,
            marginTop: 30,
            fontSize: 16,
            color: '#808191',
            ...textStyle,
          }}
          className='h5'
        >
          {text}
        </div>
      )}
    </div>
  );
}

export default NotFoundedAnimation;
