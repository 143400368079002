import { useEffect, useState, useRef } from 'react';
import Icon from '../icons/icons';
import { numberInputOnWheelPreventChange } from '../../../utils/numberPrevent';

function DepositInput({
  label,
  iconName,
  subTitle,
  disabledProperty,
  active,
  suffix,
  subText,
  notesElement = null,
  ...otherInputProps
}) {
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    if (document.hasFocus() && ref.current.contains(document.activeElement)) {
      setFocus(true);
    }
  }, []);

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Prevent entry of 'e' character
    if (keyValue.toLowerCase() === 'e') {
      event.preventDefault();
    }
  };

  const handleBeforeInput = (e) => {
    // prevent firefox issue
    const enteredCharacter = e.data;
    const sanitizedValue = enteredCharacter;
    const isValid = /^\d*\.?\d*$/.test(sanitizedValue);
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`operations__field field js-field ${
        hasFocus || disabledProperty || active ? 'active' : ''
      }`}
    >
      {/* <div className='field__label'>
        {label}
        {iconName && <Icon icon={iconName} className='icon icon-label' />}
        {subTitle && <span className='field__max'>Max Amount</span>}
      </div> */}
      <div className='popup__field'>
        {/* <input className='field__input js-field-input' /> */}
        <div className='popup__label'>{label}</div>
        <div className='popup__wrap deposit__input_wrap'>
          <input
            className={
              notesElement
                ? 'popup__input deposit__input notes__element'
                : 'popup__input deposit__input'
            }
            ref={ref}
            step='any'
            // onKeyDown={handleKeyPress}
            onBeforeInput={handleBeforeInput}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            {...otherInputProps}
            style={{ borderRadius: notesElement ? '8px 8px 0px 0px' : '8px' }}
          />
          {subText && <div className='deposit__input_sub_text'>{subText}</div>}
          {suffix && <div className='deposit__input_suffix'>{suffix}</div>}
        </div>

        {notesElement && <div className='operations__note'>{notesElement}</div>}
      </div>
    </div>
  );
}

export default DepositInput;
