import React, { useCallback, useEffect, useState } from 'react';
import { getQueryParams } from '../../utils/query-helper';
import { ENABLE_SEGMENT, ENVIRONMENT } from '../constants';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { analyticsKey } from '../urls';
import { useSelector } from 'react-redux';

function useSegment() {
  const [analytics, setAnalytics] = useState(null);
  const { state: userVia } = useSelector((state) => state.userVia);
  const { ip } = useSelector((state) => state.userIpAddress);

  useEffect(() => {
    if (ENVIRONMENT === 'mainnet' && ENABLE_SEGMENT) {
      const loadAnalytics = async () => {
        let [response] = await AnalyticsBrowser.load({
          writeKey: analyticsKey,
        });
        setAnalytics(response);
      };
      loadAnalytics();
    }
  }, []);

  const sendTrackEvent = useCallback(
    (eventName, body = {}) => {
      if (ENABLE_SEGMENT && ENVIRONMENT === 'mainnet' && analytics) {
        let queryParams = getQueryParams();
        if (Object.keys(queryParams).includes('')) {
          queryParams = null;
        }
        body['route'] = window?.location?.pathname;
        if (queryParams) {
          body['queryParams'] = queryParams;
        }
        if (userVia?.address && !body['address']) {
          body['address'] = userVia?.address;
        }
        if (userVia?.via && !body['walletType']) {
          body['walletType'] = userVia?.via;
        }
        if (ip) {
          body['ip'] = ip;
        }
        analytics?.track(eventName, { ...body });
      }
    },
    [analytics]
  );

  const sendPageEvent = useCallback(
    (eventName, body = {}) => {
      if (ENABLE_SEGMENT && ENVIRONMENT === 'mainnet' && analytics) {
        let queryParams = getQueryParams();
        if (Object.keys(queryParams).includes('')) {
          queryParams = null;
        }
        body['route'] = window?.location?.pathname;
        if (queryParams) {
          body['queryParams'] = queryParams;
        }
        if (userVia?.address && !body['address']) {
          body['address'] = userVia?.address;
        }
        if (ip) {
          body['ip'] = ip;
        }
        if (userVia?.via && !body['walletType']) {
          body['walletType'] = userVia?.via;
        }
        analytics?.page(eventName, { ...body });
      }
    },
    [analytics]
  );

  return { sendPageEvent, sendTrackEvent, analytics };
}

export default useSegment;
