// /* global BigInt */
// import { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
// import { cryptoDepositStart, getVaultId } from '../../../actions/walletAction';
// import {
//   useBalance,
//   useWebSocketData,
//   useMeta,
//   useWeb3Auth,
//   useWalletMethods,
//   useAnalytics,
// } from '../../../utils/hooks';
import {
  CustomButton,
  Popup,
  SucccessLottie,
  SkeletonContainer,
  Image,
  Loader,
  WarnBox,
} from '../../../components';
// import WalletWithdrawFormInput from '../wallet-withdraw/wallet-withdraw-input/wallet-withdraw-input';
// import DespositOperationItem from './desposit-operation-item/desposit-operation-item';
// import { logout } from '../../../actions/userAction';
// import { getQueryParams } from '../../../utils/query-helper';
// import { useHistory, useLocation } from 'react-router-dom';
// import { ethers } from 'ethers';
// import { getKeyPairFromSignature } from '../../../utils/contracts/app';
// import animationData from '../../../assests/json/done.json';
// import LatestActivity from '../../home/latest-activities/latest-activities';
// import { getDEXDepositsData } from '../../../actions/historyAction';
// import { useWeb3React } from '@web3-react/core';
// import {
//   ALLOWED_NETWORK_ID,
//   ALLOWED_NETWORK_NAME,
//   baseCurrency,
//   MAX_LENGTH_ALLOWED_IN_NUM_INPUT,
// } from '../../../utils/constants';
// import { InsufficientBalanceBtn } from '../../exchange/exchange-action/exchange-action';
// import { images } from '../../../utils/image/img';
// import { sanitizeProvider } from '../../../utils/sanitize-provider';
// import { numberInputOnWheelPreventChange } from '../../../utils/numberPrevent';

export function DepositLoader() {
  return (
    <>
      <SkeletonContainer
        style={{
          width: '100%',
          height: 70,
          borderRadius: 20,
          marginTop: 40,
        }}
      />
      <SkeletonContainer
        style={{
          width: '100%',
          height: 70,
          borderRadius: 20,
          marginTop: 40,
        }}
      />

      <SkeletonContainer
        style={{
          width: '100%',
          height: 170,
          borderRadius: 20,
          marginTop: 40,
        }}
      />
      {/* <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {[1, 2, 3, 4, 5].map((item) => (
          <SkeletonContainer
            key={item}
            style={{
              width: 50,
              height: 50,
              marginRight: 10,
              borderRadius: '50%',
            }}
          />
        ))}
      </div> */}
      <SkeletonContainer
        style={{
          width: '100%',
          height: 60,
          borderRadius: 20,
          marginTop: 20,
        }}
      />
      {/* <SkeletonContainer
        style={{
          width: '100%',
          height: 60,
          borderRadius: 20,
          marginTop: 20,
        }}
      /> */}
      <SkeletonContainer
        style={{
          width: '100%',
          height: 60,
          borderRadius: 20,
          marginTop: 20,
        }}
      />
      {/* <SkeletonContainer
        style={{ width: 120, height: 25, borderRadius: 1, marginTop: 35 }}
      /> */}
      {/* <div>
        {[1, 2, 3, 4, 5, 6, 7].map((item) => (
          <SkeletonContainer
            style={{
              width: '100%',
              height: 60,
              borderRadius: 10,
              marginTop: 15,
            }}
            key={item}
          />
        ))}
      </div> */}
    </>
  );
}

// const BlockChainBalance = ({ metaBalance, selectedData }) => {
//   const { lastPrice } = useWebSocketData();
//   return (
//     <div
//       className='actions__menu toggle_mode'
//       style={{
//         padding: '8px 9px 8px 11px',
//         margin: '15px 0px 0px 0px',
//         borderRadius: 5,
//         fontFamily: 'Poppins',
//       }}
//     >
//       <div className='exchange_toggle_mode'>
//         <h5 style={{ maxWidth: '133px', fontSize: 13 }}>
//           💰 Available to Deposit
//         </h5>
//       </div>
//       <div>
//         <p style={{ fontSize: '12px', textAlign: 'center' }}>
//           {metaBalance
//             ? metaBalance + ' ' + selectedData?.currency?.toUpperCase()
//             : 0}
//           <br />
//           {selectedData?.currency !== 'usdc' &&
//             selectedData?.currency !== 'usdt' && (
//               <span style={{ fontSize: '10px' }}>
//                 (
//                 {Number(
//                   Number(metaBalance) *
//                     Number(
//                       lastPrice(`${selectedData?.currency}${baseCurrency}`)
//                     )
//                 ).toFixed(2)}{' '}
//                 {selectedData?.currency === 'usdt' ? 'USDT' : 'USDC'} )
//               </span>
//             )}
//         </p>
//       </div>
//     </div>
//   );
// };

// function WalletDeposit({
//   visibleSideBar,
//   selectedData,
//   setStartFetch,
//   startFetch,
//   confirmationData,
//   depositIntervalId,
//   setTxBlockNumber,
//   txBlockNumber,
// }) {
//   const { getBalanceToUSDC, roundUpWithSymbol, getInfoAboutCoins, isDecimal } =
//     useBalance();
//   const location = useLocation();
//   const history = useHistory();
//   const [cryptoAmount, setCryptoAmount] = useState('');
//   const [queryParams, setQueryParams] = useState({});
//   const [openAllowancePopup, setOpenAllowancePopup] = useState(false);
//   const [showGasFeeWarning, setShowGasFeeWarning] = useState(false);
//   const [alreadyAskedAllowance, setAlreadyAskedAllowance] = useState(false);
//   const [confirmDepositPopup, setConfirmDepositPopup] = useState(false);
//   const [depositSuccess, setDepositSuccess] = useState(false);
//   const [metaBalance, setMetaBalance] = useState(null);
//   const [depositRes, setDepositRes] = useState(null);
//   const context = useWeb3React();
//   const { lastPrice } = useWebSocketData();
//   const [openselfDeposit, setOpenSelfDeposit] = useState(null);
//   const [accountWeb3, setAccountWeb3] = useState(null);
//   const [depositLoading, setDepositLoading] = useState(true);
//   // const { analytics } = useAnalytics();
//   const [feeAmount, setFeeAmount] = useState(0);
//   const [feeAmountError, setFeeAmountError] = useState(null);
//   const [userAllowance, setUserAllowance] = useState(null);
//   const [disableAllowanceBtn, setDisableAllowanceBtn] = useState(false);
//   const [showProcessAllowancePopup, setProcessAllowancePopup] = useState(false);
//   const {
//     connector,
//     provider: library,
//     chainId,
//     account,
//     activate,
//     deactivate,
//     active,
//     error: contextError,
//   } = context;

//   const {
//     userLogin: { userInfo },
//     userDetails: { userData, loading, error },
//     userKey: { state },
//     vaultList: { state: vaultListState },
//     userVia: { state: userVia },
//     getCoins: { loading: coinsLoading, error: coinsError, coinsDetails },
//     userGetBalance,
//     userIpAddress: { ip },
//     theme: { isdark },
//     web3AuthProvider: { provider: providerWeb3AuthGlobalState },
//   } = useSelector((state) => state);
//   const [ethereum, setEthereum] = useState(null);

//   const dispatch = useDispatch();

//   const {
//     getBalance: getBalanceWeb3Auth,
//     provider: providerWeb3Auth,
//     startDeposit: startDepositWeb3Auth,
//     approveUnlimitedAllowance: approveUnlimitedAllowanceWeb3Auth,
//     getAllowance: getAllowanceWeb3Auth,
//     getAccounts: getAccountsWeb3Auth,
//     getGasFeeV2: getGasFeeWeb3Auth,
//   } = useWeb3Auth();

//   const perValue = ['25', '50', '75', '100'];

//   const { get0X0to0X, METAMASK_POSSIBLE_ERRORS, connectedAccount } = useMeta(
//     selectedData?.currency
//   );

//   const {
//     startDeposit,
//     getAllowance: getAllowance,
//     approveUnlimitedAllowance,
//     getBalance,
//     getGasFeev2,
//     capitalize,
//     walletDeactivate,
//   } = useWalletMethods(selectedData?.currency);

//   const [activatingConnector, setActivatingConnector] = useState();

//   useEffect(() => {
//     if (activatingConnector && activatingConnector === connector) {
//       setActivatingConnector(undefined);
//     }
//   }, [activatingConnector, connector]);

//   useEffect(() => {
//     const queryParams = getQueryParams();
//     const invalidParams = ['order_id', 'mid', 'status', 'message'];
//     const validParams = {};
//     if (Object.keys(queryParams).length != 0 && location.search != '') {
//       for (const key in queryParams) {
//         const InvalidKey = invalidParams.find((item) => item == key);
//         if (InvalidKey) {
//           delete queryParams[InvalidKey];
//         } else {
//           validParams[key] = queryParams[key];
//         }
//       }
//       setQueryParams(validParams);
//     }
//   }, [location.search]);

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const queryParamsObj = getQueryParams();
//     const invalidParams = ['order_id', 'mid', 'status', 'message'];
//     if (Object.keys(queryParamsObj).length != 0 && location.search != '') {
//       for (const key in queryParamsObj) {
//         const InvalidKey = invalidParams.find((item) => item == key);
//         if (InvalidKey) {
//           queryParams.delete(InvalidKey);
//           history.replace({
//             search: queryParams.toString(),
//           });
//         }
//       }
//     }
//   }, [location.search]);

//   const handleGetVaultId = async (symbol) => {
//     try {
//       const data = await getVaultId(userInfo?.token?.access, symbol);
//       return data;
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const countDecimals = (value) => {
//     if (Math.floor(value) === value) return 0;
//     return value.toString().split('.')[1]?.length || 0;
//   };

//   const handleCryptoDeposit = async () => {
//     // analytics?.track('Deposit request start', {
//     //   from: 'Wallet Page',
//     //   uid: userInfo?.payload?.uid,
//     //   QueryParams: { ...getQueryParams() },
//     //   symbol: selectedData?.currency,
//     //   amount: cryptoAmount,
//     //   ip: ip,
//     // });
//     if (cryptoAmount <= 0) {
//       return toast.error('Amount should be greater than zero');
//     }

//     if (
//       countDecimals(cryptoAmount) >
//       Number(getInfoAboutCoins(selectedData?.currency)?.quanitization)
//     ) {
//       toast.error(
//         `Max decimal places allowed is only "${
//           getInfoAboutCoins(selectedData?.currency)?.quanitization
//         }"`
//       );
//       return;
//     }

//     let maximumAmount =
//       Math.pow(
//         10,
//         Number(getInfoAboutCoins(selectedData?.currency)?.quanitization) + 1
//       ) - 1;

//     if (maximumAmount <= cryptoAmount) {
//       toast.error('Please enter lesser amount ');
//       return;
//     }

//     const vaultRes = await handleGetVaultId(selectedData?.currency);
//     const vaultId = vaultRes?.payload?.id;
//     if (!vaultId) {
//       toast.error('Something went wrong; please try again later');
//       return;
//     }
//     // if (userVia?.via == 'metamask') {
//     //   if (
//     //     !ethereum.isConnected() ||
//     //     !ethereum._state.initialized ||
//     //     !connectedAccount
//     //   ) {
//     //     toast.error('Your session has expired; please log in again');
//     //     setTimeout(() => {
//     //       deactivate();
//     //       dispatch(logout(true));
//     //     }, 1500);

//     //     return;
//     //   }
//     // }
//     // if (!vaultId) {
//     //   return toast.error('Something went wrong, Please try again later');
//     // }

//     if (!account && userVia?.via !== 'web3_auth') {
//       toast.error('Your session has expired; please log in again');
//       dispatch(logout(true));
//     }

//     if (chainId !== ALLOWED_NETWORK_ID && userVia?.via !== 'web3_auth') {
//       toast.error(
//         `Kindly change network to ${ALLOWED_NETWORK_NAME} in your wallet`
//       );
//       setTimeout(() => {
//         walletDeactivate();
//         dispatch(logout(true));
//       }, 1500);
//     }

//     let allowanceAmount;

//     if (selectedData?.currency !== 'eth') {
//       if (userVia?.via !== 'web3_auth') {
//         allowanceAmount = await getAllowance();
//       } else {
//         allowanceAmount = await getAllowanceWeb3Auth(selectedData?.currency);
//       }
//     }

//     if (selectedData?.currency === 'eth' && metaBalance == cryptoAmount) {
//       return toast.error('Your blockchain wallet has insufficient balance');
//     }

//     let stark_public_key = getKeyPairFromSignature(state).getPublic().getX();
//     const finalKey = ethers.BigNumber.from(
//       stark_public_key.toString()
//     ).toHexString();

//     if (
//       parseFloat(allowanceAmount) < parseFloat(cryptoAmount) &&
//       selectedData?.currency !== 'eth'
//     ) {
//       setOpenAllowancePopup(true);
//     } else {
//       if (Number(metaBalance) < cryptoAmount) {
//         return toast.error('Your blockchain wallet has insufficient balance');
//       }
//       setConfirmDepositPopup(true);
//       setTimeout(async () => {
//         try {
//           let res;
//           if (userVia?.via !== 'web3_auth') {
//             res = await startDeposit(
//               finalKey,
//               vaultId,
//               cryptoAmount.toString()
//             );
//           } else {
//             res = await startDepositWeb3Auth(
//               selectedData?.currency,
//               finalKey,
//               vaultId,
//               cryptoAmount.toString()
//             );
//           }
//           setDepositRes(res);
//           try {
//             const data = await cryptoDepositStart(
//               userInfo.token.access,
//               res.amount.toString(),
//               get0X0to0X(res.token_id.toString()),
//               get0X0to0X(res.stark_key.toString()),
//               res.deposit_blockchain_hash.toString(),
//               res.deposit_blockchain_nonce.toString(),
//               res.vault_id.toString()
//             );
//             toast.success('Successfully submitted');
//             // analytics?.track('Deposit request success', {
//             //   from: 'Wallet Page',
//             //   uid: userInfo?.payload?.uid,
//             //   QueryParams: { ...getQueryParams() },
//             //   symbol: selectedData?.currency,
//             //   amount: cryptoAmount,
//             //   ip: ip,
//             // });
//             setDepositSuccess(true);
//             dispatch(getDEXDepositsData(1));
//             setConfirmDepositPopup(false);
//             setCryptoAmount('');
//             setTimeout(() => {
//               getWalletBalance();
//             }, 5000);
//             setTxBlockNumber(null);
//             toast.dismiss('deposit_loading');
//           } catch (error) {
//             setConfirmDepositPopup(false);
//             toast.error(error?.message);
//             // toast.error(error?.code);
//           }
//         } catch (e) {
//           // analytics?.track('Deposit request failed', {
//           //   from: 'Wallet Page',
//           //   uid: userInfo?.payload?.uid,
//           //   QueryParams: { ...getQueryParams() },
//           //   symbol: selectedData?.currency,
//           //   amount: cryptoAmount,
//           //   ip: ip,
//           //   error: e,
//           // });
//           console.log(e);
//           setConfirmDepositPopup(false);
//           if (userVia?.via !== 'web3_auth') {
//             toast.error(
//               e?.reason ? capitalize(e?.reason) : capitalize(e.message)
//             );
//           } else {
//             toast.error(e.message);
//           }
//         }
//       }, 1000);
//     }
//   };

//   const handleStoreKey = (key, val) => {
//     sessionStorage.setItem(key, val);
//   };

//   useEffect(() => {
//     sanitizeProvider(setEthereum);
//   }, []);

//   useEffect(() => {
//     const isThere = sessionStorage.getItem(
//       `pending_allowance_${selectedData?.currency}`
//     )
//       ? sessionStorage.getItem(`pending_allowance_${selectedData?.currency}`)
//       : null;

//     if (isThere) {
//       setAlreadyAskedAllowance(true);
//     }
//     return () => {
//       setAlreadyAskedAllowance(false);
//     };
//   }, [openAllowancePopup, selectedData, disableAllowanceBtn]);

//   const maxLengthCheck = (object) => {
//     if (isDecimal(object.target.value)) {
//       if (
//         countDecimals(object.target.value) >=
//         Number(getInfoAboutCoins(selectedData?.currency)?.quanitization) + 1
//       ) {
//         object.target.value = cryptoAmount;
//       }
//     } else {
//       let maxLength = MAX_LENGTH_ALLOWED_IN_NUM_INPUT + 1;
//       const { value } = object.target;
//       if (String(value).length >= maxLength) {
//         object.target.value = cryptoAmount;
//       }
//     }
//   };

//   const toastBtnStyle = {
//     padding: '5px 10px',
//     background: '#6c5dd3',
//     color: '#fff',
//     borderRadius: 5,
//     fontSize: '12px',
//     fontFamily: 'Poppins',
//   };

//   const handleClose = () => {
//     sessionStorage.removeItem('allowance_loading');
//     sessionStorage.removeItem('allowance_loading_amount');
//     setStartFetch(false);
//   };

//   const Msg1 = ({ closeToast }) => (
//     <div>
//       Allowance request in progress for {selectedData?.currency?.toUpperCase()}
//       <br />
//       <button
//         style={toastBtnStyle}
//         onClick={() => {
//           closeToast();
//           handleClose();
//         }}
//       >
//         Close
//       </button>
//     </div>
//   );

//   const monitorAllowance = () => {
//     toast.loading(<Msg1 />, {
//       toastId: 'allowance_loading',
//       closeOnClick: false,
//       draggable: false,
//     });
//     sessionStorage.setItem('allowance_loading', selectedData?.currency);
//     sessionStorage.setItem('allowance_loading_amount', cryptoAmount);
//   };

//   useEffect(() => {
//     return () => {
//       setCryptoAmount('');
//     };
//   }, [selectedData]);

//   const handleAllowancePermission = async (direct_call = false) => {
//     if (direct_call && alreadyAskedAllowance) {
//       return setOpenAllowancePopup(true);
//     }
//     setDisableAllowanceBtn(true);
//     setProcessAllowancePopup(true);
//     // analytics?.track('Allowance request start', {
//     //   from: 'Wallet Page',
//     //   uid: userInfo?.payload?.uid,
//     //   QueryParams: { ...getQueryParams() },
//     //   symbol: selectedData?.currency,
//     //   amount: cryptoAmount,
//     //   ip: ip,
//     // });

//     try {
//       let data;
//       if (userVia?.via !== 'web3_auth') {
//         let ethBalance = await getBalance('eth');
//         if (ethBalance == 0) {
//           setOpenAllowancePopup(false);
//           return toast.error('Your Ethereum balance is insufficient');
//         } else {
//           data = await approveUnlimitedAllowance();
//         }
//       } else {
//         let ethData = getInfoAboutCoins('eth');
//         let ethBalance = await getBalanceWeb3Auth('eth', ethData);
//         if (ethBalance == 0) {
//           setOpenAllowancePopup(false);
//           return toast.error('Your Ethereum balance is insufficient');
//         } else {
//           data = await approveUnlimitedAllowanceWeb3Auth(
//             selectedData?.currency
//           );
//         }
//       }
//       handleStoreKey(`pending_allowance_${selectedData?.currency}`, data?.hash);
//       setOpenAllowancePopup(false);
//       setProcessAllowancePopup(false);
//       monitorAllowance();
//       setStartFetch(true);
//       // analytics?.track('Allowance request success', {
//       //   from: 'Wallet Page',
//       //   uid: userInfo?.payload?.uid,
//       //   QueryParams: { ...getQueryParams() },
//       //   symbol: selectedData?.currency,
//       //   amount: cryptoAmount,
//       //   ip: ip,
//       // });
//       setDisableAllowanceBtn(false);
//     } catch (e) {
//       // analytics?.track('Allowance request failed', {
//       //   from: 'Wallet Page',
//       //   uid: userInfo?.payload?.uid,
//       //   QueryParams: { ...getQueryParams() },
//       //   symbol: selectedData?.currency,
//       //   error: e,
//       //   amount: cryptoAmount,
//       // });
//       setOpenAllowancePopup(false);
//       if (userVia?.via !== 'web3_auth') {
//         toast.error(e?.reason ? capitalize(e?.reason) : capitalize(e.message));
//       } else {
//         toast.error(e.message);
//       }
//       setProcessAllowancePopup(false);
//       setDisableAllowanceBtn(false);
//     }
//   };

//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//   };

//   useEffect(() => {
//     return () => setDepositSuccess(false);
//   }, []);

//   const getWalletBalance = async () => {
//     if (selectedData?.currency) {
//       if (userVia?.via !== 'web3_auth') {
//         if (!!account && !!library) {
//           await getBalance(selectedData?.currency)
//             .then((data) => {
//               setMetaBalance(Number(data).toFixed(5));
//             })
//             .catch((e) => console.log(e));
//         }
//       } else {
//         if (providerWeb3AuthGlobalState) {
//           let tokenContractAddress = getInfoAboutCoins(selectedData?.currency);
//           getBalanceWeb3Auth(selectedData?.currency, tokenContractAddress)
//             .then((data) => setMetaBalance(Number(data)?.toFixed(5)))
//             .catch((e) => {
//               console.log({ e });
//             });
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     getWalletBalance();
//   }, [
//     selectedData,
//     account,
//     library,
//     getBalanceWeb3Auth,
//     getBalance,
//     userVia,
//     providerWeb3AuthGlobalState,
//   ]);

//   useEffect(() => {
//     return () => {
//       setMetaBalance(null);
//     };
//   }, [selectedData]);

//   const handlePercentInDeposit = (percent, isEth = false) => {
//     if (metaBalance) {
//       const temp1 = (Number(percent) * Number(metaBalance)) / 100;
//       const perValue = (temp1 / 100) * 100;
//       let amount = isEth
//         ? (Number(perValue) - Number(feeAmount)).toFixed(5) < 0
//           ? 0
//           : (Number(perValue) - Number(feeAmount)).toFixed(5)
//         : perValue?.toFixed(5);
//       setCryptoAmount(amount);
//     } else {
//       setCryptoAmount(0);
//     }
//   };

//   const handleGetUserAddress = async () => {
//     if (providerWeb3AuthGlobalState && userVia?.via == 'web3_auth') {
//       try {
//         const ethAddress = await getAccountsWeb3Auth();
//         setAccountWeb3(ethAddress[0]);
//       } catch (error) {
//         console.log(error, 'error');
//       }
//     }
//   };

//   useEffect(() => {
//     handleGetUserAddress();
//   }, [providerWeb3AuthGlobalState]);

//   const handleGetFeeAmount = async () => {
//     setDepositLoading(true);
//     try {
//       let fee;
//       let stark_public_key = getKeyPairFromSignature(state).getPublic().getX();
//       let vaultRes = await handleGetVaultId(selectedData?.currency);
//       let vaultId = vaultRes?.payload?.id;
//       const finalKey = ethers.BigNumber.from(
//         stark_public_key.toString()
//       ).toHexString();
//       if (userVia?.via === 'web3_auth') {
//         fee = await getGasFeeWeb3Auth(
//           selectedData?.currency,
//           finalKey,
//           vaultId,
//           '0.000001'
//         );
//       } else {
//         fee = await getGasFeev2(finalKey, vaultId, '0.00001');
//       }
//       setFeeAmount(fee);
//       setDepositLoading(false);
//     } catch (errorRes) {
//       setFeeAmountError(errorRes?.error?.code);
//       setDepositLoading(false);
//     }
//   };

//   const getAllowanceOfCoin = async () => {
//     let allowanceAmount;

//     if (selectedData?.currency !== 'eth') {
//       if (userVia?.via !== 'web3_auth') {
//         allowanceAmount = await getAllowance();
//       } else {
//         allowanceAmount = await getAllowanceWeb3Auth(selectedData?.currency);
//       }
//     }
//     setDepositLoading(false);
//     return allowanceAmount;
//   };

//   useEffect(async () => {
//     if (
//       selectedData &&
//       metaBalance !== null &&
//       metaBalance !== 0 &&
//       visibleSideBar === 'deposit'
//     ) {
//       handleGetFeeAmount();
//     }
//   }, [
//     selectedData,
//     userVia,
//     metaBalance,
//     visibleSideBar,
//     account,
//     library,
//     providerWeb3AuthGlobalState,
//   ]);

//   useEffect(() => {
//     setFeeAmount(null);
//     setFeeAmountError(null);
//     setDepositLoading(true);
//     setUserAllowance(null);
//     setDisableAllowanceBtn(false);
//   }, [selectedData]);

//   useEffect(async () => {
//     if (
//       selectedData &&
//       selectedData?.currency !== 'eth' &&
//       // metaBalance !== null &&
//       // metaBalance !== 0 &&
//       visibleSideBar === 'deposit'
//     ) {
//       const data = await getAllowanceOfCoin();
//       setUserAllowance(data);
//     }
//     if (selectedData?.currency === 'eth') {
//       setDepositLoading(false);
//     }
//   }, [
//     selectedData,
//     userVia,
//     visibleSideBar,
//     account,
//     library,
//     providerWeb3AuthGlobalState,
//   ]);

//   useEffect(() => {
//     if (txBlockNumber) {
//       setDepositLoading(false);
//     }
//   }, [txBlockNumber]);

//   const handleShowGasWarning = async () => {
//     if (userVia?.via !== 'web3_auth') {
//       let ethBalance = await getBalance('eth');
//       if (selectedData?.currency !== 'eth') {
//         if (Number(ethBalance) < Number(feeAmount)) {
//           setShowGasFeeWarning(true);
//         } else {
//           setShowGasFeeWarning(false);
//         }
//       } else {
//         if (Number(ethBalance) < Number(feeAmount) + Number(cryptoAmount)) {
//           setShowGasFeeWarning(true);
//         } else {
//           setShowGasFeeWarning(false);
//         }
//       }
//     } else {
//       let ethBalance = await getBalanceWeb3Auth('eth');
//       if (selectedData?.currency !== 'eth') {
//         if (Number(ethBalance) < Number(feeAmount)) {
//           setShowGasFeeWarning(true);
//         } else {
//           setShowGasFeeWarning(false);
//         }
//       } else {
//         if (Number(ethBalance) < Number(feeAmount) + Number(cryptoAmount)) {
//           setShowGasFeeWarning(true);
//         } else {
//           setShowGasFeeWarning(false);
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     if (feeAmount) {
//       handleShowGasWarning();
//     }
//   }, [feeAmount, selectedData?.currency, cryptoAmount]);

//   return (
//     <>
//       <div
//         className='operations__wrap'
//         style={{
//           display: visibleSideBar === 'deposit' ? 'block' : 'none',
//         }}
//       >
//         {depositLoading ? (
//           <DepositLoader />
//         ) : (
//           <>
//             {selectedData && (
//               <>
//                 <div className='operations__item'>
//                   <div className='operations__flex'>
//                     <div className='operations__title'>Total Balance</div>
//                     <div className='operations__box'>
//                       <div className='operations__counter'>
//                         {selectedData?.currency == 'usdt'
//                           ? parseFloat(selectedData.balance).toFixed(3) +
//                             ' ' +
//                             selectedData.currency.toUpperCase()
//                           : roundUpWithSymbol(
//                               parseFloat(selectedData.balance),
//                               selectedData.currency
//                             ) +
//                             ' ' +
//                             selectedData.currency.toUpperCase()}
//                       </div>
//                       {selectedData.currency !== 'usdt' &&
//                         selectedData.currency !== 'usdc' && (
//                           <div className='operations__price'>
//                             {getBalanceToUSDC(selectedData)?.toFixed(3)}
//                           </div>
//                         )}
//                     </div>
//                   </div>
//                   {metaBalance !== null && (
//                     <>
//                       <BlockChainBalance
//                         metaBalance={metaBalance}
//                         selectedData={selectedData}
//                       />
//                     </>
//                   )}
//                 </div>
//                 {userVia?.via === 'web3_auth' && (
//                   <div
//                     style={{
//                       fontFamily: 'Poppins',
//                       textAlign: 'center',
//                       padding: '0px 10px',
//                     }}
//                   >
//                     <p>
//                       📝 Add Balance to your blockchain wallet{' '}
//                       <span
//                         style={{
//                           color: '#8173E0',
//                           cursor: 'pointer',
//                           textDecoration: 'underline',
//                         }}
//                         onClick={() => setOpenSelfDeposit((prev) => !prev)}
//                       >
//                         {openselfDeposit ? '' : 'Click here to add'}
//                       </span>{' '}
//                       <div style={{ marginTop: 20 }}>
//                         {openselfDeposit && (
//                           <DespositOperationItem
//                             title='Your address'
//                             description='Send cryptocurrency with QR code or address'
//                             QRCodeValue={accountWeb3}
//                             closeState={setOpenSelfDeposit}
//                           />
//                         )}
//                       </div>
//                     </p>
//                   </div>
//                 )}
//                 {selectedData?.currency !== 'inr' && (
//                   <div style={{ marginTop: 30 }}>
//                     <WalletWithdrawFormInput
//                       active
//                       label='Enter the Amount to Deposit'
//                       value={cryptoAmount}
//                       type='number'
//                       onWheel={numberInputOnWheelPreventChange}
//                       min='0'
//                       placeholder='0'
//                       onInput={maxLengthCheck}
//                       onChange={(e) => setCryptoAmount(e.target.value)}
//                     />
//                     {metaBalance != null && (
//                       <div
//                         style={{
//                           display: 'flex',
//                           alignItems: 'center',
//                           justifyContent: 'center',
//                           fontFamily: 'Poppins',
//                           marginLeft: -23,
//                           marginBottom: 25,
//                           marginTop: 25,
//                         }}
//                       >
//                         {perValue.map((value, idx) => (
//                           <div key={idx}>
//                             {selectedData?.currency === 'eth' &&
//                             value == 100 ? (
//                               <span
//                                 className='numbers_slider'
//                                 onClick={() =>
//                                   handlePercentInDeposit('100', true)
//                                 }
//                               >
//                                 {'100'}%
//                               </span>
//                             ) : (
//                               <span
//                                 className='numbers_slider'
//                                 onClick={() => handlePercentInDeposit(value)}
//                               >
//                                 {value}%
//                               </span>
//                             )}
//                           </div>
//                         ))}
//                       </div>
//                     )}

//                     {Number(feeAmount) != 0 &&
//                       !isNaN(feeAmount) &&
//                       !feeAmountError && (
//                         <div className='estimated__gas'>
//                           <p>
//                             Estimated gas fee - {Number(feeAmount).toFixed(8)}{' '}
//                             ETH (
//                             {(
//                               Number(
//                                 lastPrice(
//                                   `${selectedData?.currency}${baseCurrency}`
//                                 )
//                               ) * Number(feeAmount)
//                             ).toFixed(6)}{' '}
//                             {baseCurrency?.toUpperCase()})
//                           </p>
//                         </div>
//                       )}
//                     <div
//                       style={{
//                         display: !showGasFeeWarning ? 'none' : 'block',
//                       }}
//                     >
//                       <WarnBox>
//                         <div style={{ display: 'flex', flexDirection: 'row' }}>
//                           <h1>
//                             <i className='fas fa-exclamation-circle'></i>
//                           </h1>
//                           <h1>Insufficient funds.</h1>
//                         </div>
//                         <p>
//                           Your ethereum balance is insufficient to cover the gas
//                           fee, the transaction may fail.
//                         </p>
//                         {Number(metaBalance) > cryptoAmount && (
//                           <span
//                             className='link'
//                             onClick={() =>
//                               setShowGasFeeWarning((prev) => !prev)
//                             }
//                           >
//                             I want to proceed anyway
//                           </span>
//                         )}
//                       </WarnBox>
//                     </div>
//                     {userAllowance !== null && userAllowance === 0 && (
//                       <>
//                         {/* <CustomButton
//                           title={`Allow the Brine to use your ${selectedData?.currency?.toUpperCase()}`}
//                           onClick={handleAllowancePermission}
//                           disabled={disableDepositbtn || startFetch}
//                           style={{
//                             display: 'block',
//                             width: '100%',
//                             marginBottom: 20,
//                           }}
//                         /> */}
//                         <button
//                           className='card__btn btn btn_border'
//                           style={{
//                             display: 'block',
//                             width: '100%',
//                             // background: 'rgb(108, 93, 211)',
//                             // background: `linear-gradient(
//                             //   180deg,
//                             //   rgba(108, 93, 211, 1) 30%,
//                             //   rgba(65, 134, 210, 1) 85%
//                             // )`,
//                             fontFamily: 'Poppins',
//                             marginBottom: '20px',
//                             fontSize: 12,
//                             // border: 'none',
//                           }}
//                           disabled={disableAllowanceBtn || startFetch}
//                           onClick={() => handleAllowancePermission(true)}
//                         >
//                           <span className='btn__text'>
//                             <img
//                               src={
//                                 getInfoAboutCoins(selectedData?.currency)?.logo
//                               }
//                               alt=''
//                               style={{ width: 20 }}
//                             />
//                             {`Allow Brine to use your ${selectedData?.currency?.toUpperCase()}`}
//                             <i
//                               className='fas fa-arrow-right'
//                               style={{
//                                 marginLeft: 5,
//                                 animation:
//                                   'shake-animation 4.72s ease infinite',
//                               }}
//                             ></i>
//                           </span>
//                         </button>
//                       </>
//                     )}
//                     <div style={{ display: 'grid', placeItems: 'center' }}>
//                       {Number(metaBalance) < cryptoAmount ? (
//                         <InsufficientBalanceBtn />
//                       ) : (
//                         <>
//                           <CustomButton
//                             title={`${selectedData?.currency?.toUpperCase()} Deposit`}
//                             onClick={handleCryptoDeposit}
//                             disabled={
//                               startFetch ||
//                               userAllowance === 0 ||
//                               depositLoading ||
//                               showGasFeeWarning
//                             }
//                             style={{ display: 'block', width: '100%' }}
//                           />
//                         </>
//                       )}
//                     </div>
//                     <LatestActivity
//                       isdeposit={true}
//                       confirmationData={confirmationData}
//                     />
//                   </div>
//                 )}
//               </>
//             )}
//           </>
//         )}
//       </div>
//       <div className='share__popup network__error'>
//         <Popup
//           state={showProcessAllowancePopup}
//           setState={setProcessAllowancePopup}
//         >
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               flexDirection: 'column',
//               width: '100%',
//             }}
//           >
//             <div className='h6'>Waiting for Approval.</div>
//             {/* <Image src={images.networkError} /> */}
//             <div
//               style={{
//                 marginTop: 20,
//                 marginBottom: 20,
//               }}
//             >
//               <Loader />
//             </div>
//             <p>
//               Please <span style={{ color: '#8173E0' }}>confirm</span> the
//               allowance request on{' '}
//               {userVia?.via == 'metamask'
//                 ? 'Metamask'
//                 : userVia?.via == 'wallet_link'
//                 ? 'Coin base wallet app'
//                 : 'WalletConnect app'}{' '}
//               🚀
//             </p>
//             <CustomButton
//               title='Close'
//               addClassName='disconnect_btn'
//               style={{
//                 width: '100%',
//               }}
//               onClick={() => {
//                 setConfirmDepositPopup((prev) => !prev);
//               }}
//             />
//           </div>
//         </Popup>
//       </div>
//       <div className='share__popup network__error'>
//         <Popup state={openAllowancePopup} setState={setOpenAllowancePopup}>
//           <div style={{ fontFamily: 'Poppins' }}>
//             {alreadyAskedAllowance ? (
//               <>
//                 <p
//                   style={{
//                     textAlign: 'center',
//                     marginTop: 5,
//                     marginBottom: 10,
//                   }}
//                 >
//                   ⚠️ Your previous approval is still pending for confirmation.
//                   Would you like to still continue raising an approval ?
//                 </p>
//                 <div
//                   style={{
//                     marginTop: 22,
//                     display: 'flex',
//                     flexDirection: 'row',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <CustomButton
//                     title='Cancel'
//                     onClick={() => setOpenAllowancePopup(false)}
//                   />

//                   <button
//                     className='card__btn btn btn_border'
//                     style={{ marginLeft: 12 }}
//                     onClick={() => setAlreadyAskedAllowance(false)}
//                   >
//                     <span className='btn__text'>Continue</span>
//                   </button>
//                 </div>
//               </>
//             ) : (
//               <>
//                 <p>
//                   <span style={{ fontSize: 20, color: '#8173E0' }}>
//                     We need your approval to proceed. 🙏
//                   </span>
//                   <br />
//                   <br />
//                   <span style={{ fontWeight: 'bold' }}>
//                     Why do we need it ?
//                   </span>{' '}
//                   <br />
//                   <br />
//                   Brine has a two-step approval process for extra security. This
//                   is to ensure that no one can transfer your funds into Brine
//                   without your knowledge or permission.
//                   {/* <br />
//                 <br /> Similarly In Brine we have 2 step approval process for
//                 extra security. Without your approval no one can transfer your
//                 funds into Brine. */}
//                 </p>
//                 <div
//                   style={{
//                     marginTop: 22,
//                     display: 'grid',
//                     placeItem: 'center',
//                   }}
//                 >
//                   <CustomButton
//                     style={{
//                       width: '100%',
//                     }}
//                     title='Confirm Approval'
//                     onClick={handleAllowancePermission}
//                   />
//                 </div>
//               </>
//             )}
//           </div>
//         </Popup>
//       </div>
//       <div className='share__popup network__error'>
//         <Popup setState={setConfirmDepositPopup} state={confirmDepositPopup}>
//           <div style={{ fontFamily: 'Poppins' }}>
//             {userVia?.via !== 'web3_auth' ? (
//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   flexDirection: 'column',
//                   width: '100%',
//                 }}
//               >
//                 <div className='h6'>Waiting for Approval.</div>
//                 {/* <Image src={images.networkError} /> */}
//                 <div
//                   style={{
//                     marginTop: 20,
//                     marginBottom: 20,
//                   }}
//                 >
//                   <Loader />
//                 </div>
//                 <p>
//                   Please confirm the deposit request on{' '}
//                   {userVia?.via == 'metamask'
//                     ? 'Metamask'
//                     : userVia?.via == 'wallet_link'
//                     ? 'Coin base wallet app'
//                     : 'WalletConnect app'}{' '}
//                   🚀
//                 </p>
//                 <CustomButton
//                   title='Close'
//                   addClassName='disconnect_btn'
//                   style={{
//                     width: '100%',
//                   }}
//                   onClick={() => {
//                     setConfirmDepositPopup((prev) => !prev);
//                   }}
//                 />
//               </div>
//             ) : (
//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   flexDirection: 'column',
//                   width: '100%',
//                 }}
//               >
//                 <div className='h6'>Transaction Processing</div>
//                 {/* <Image src={images.networkError} /> */}
//                 <div
//                   style={{
//                     marginTop: 20,
//                     marginBottom: 20,
//                   }}
//                 >
//                   <Loader />
//                 </div>
//                 <p>Deposit request is now processing</p>
//                 <CustomButton
//                   title='Close'
//                   addClassName='disconnect_btn'
//                   style={{
//                     width: '100%',
//                   }}
//                   onClick={() => {
//                     setConfirmDepositPopup((prev) => !prev);
//                   }}
//                 />
//               </div>
//             )}
//           </div>
//         </Popup>
//       </div>
//       <div className='share__popup network__error'>
//         <Popup
//           state={depositSuccess}
//           setState={() => {
//             setDepositSuccess((prev) => !prev);
//             setDepositRes(null);
//           }}
//           confetti={true}
//         >
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               flexDirection: 'column',
//               width: '100%',
//             }}
//           >
//             <div className='h6'>Deposit Successful</div>
//             {/* <Image src={images.networkError} /> */}
//             <div
//               style={{
//                 marginTop: 20,
//                 marginBottom: 20,
//               }}
//             >
//               <SucccessLottie />
//             </div>
//             <p>Your deposit is now processing (Waiting time {'<'} 2)</p>
//             {depositRes?.deposit_blockchain_hash && (
//               <CustomButton
//                 title='Track Deposit'
//                 addClassName='disconnect_btn'
//                 style={{
//                   width: '100%',
//                 }}
//                 onClick={() =>
//                   history.push(
//                     `/history?tab=deposits&value=${depositRes.deposit_blockchain_hash}`
//                   )
//                 }
//               />
//             )}
//           </div>
//         </Popup>
//       </div>
//     </>
//   );
// }

// export default WalletDeposit;
