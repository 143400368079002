import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { images } from '../../../utils/image/img';
import { Image } from '../../../components';

function HeaderIndicator({ value, tradeMode, setTradeMode, handle }) {
  const { isdark } = useSelector((state) => state.theme);

  return (
    <div
      className='header__indicator'
      data-tip={`Click here to change trading 🚀 Mode`}
      data-for={'toggle_trade_mode'}
      onClick={() => {
        localStorage.setItem('brine_mode', !tradeMode);
        setTradeMode((prev) => !prev);
        ReactTooltip.hide();
      }}
    >
      <div
        className='header__img'
        style={{ display: !tradeMode ? 'flex' : 'none' }}
      >
        <Image src={images.pulse} alt='pulse' />
      </div>
      <span style={{ marginLeft: tradeMode ? 5 : 0 }}>
        {!tradeMode ? 'Beginner' : 'Pro'}
      </span>
      <div
        className='header__img'
        style={{
          marginLeft: 5,
          marginRight: 0,
          display: tradeMode ? 'flex' : 'none',
        }}
      >
        <Image src={images.pulse} alt='pulse' />
      </div>
      <ReactTooltip
        place='bottom'
        type={isdark ? 'light' : 'dark'}
        className='tooltip_styles'
        id={'toggle_trade_mode'}
      />
    </div>
  );
}

export default HeaderIndicator;
