import { useState, useEffect, useMemo } from 'react';
import notificationAudio from '../../assests/audio/notification_sound.mp3';

export const useAudio = () => {
  const audio = useMemo(
    () => new Audio(notificationAudio),
    [notificationAudio]
  );
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};
