import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../actions/userAction';
import { parseJwt } from '../../utils/jwt';
import { useWalletMethods } from '../../utils/hooks';

function PrivateRoute({ children, ...otherProps }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const { userData, error: userError } = useSelector(
    (state) => state.userDetails
  );
  const { deactivate } = useWeb3React();
  const { walletDeactivate } = useWalletMethods();

  useEffect(() => {
    if (userInfo) {
      if (parseJwt(userInfo.token.access)) {
        const decodedJwt = parseJwt(userInfo.token.access);
        if (decodedJwt.exp * 1000 < Date.now()) {
          walletDeactivate();
          dispatch(logout(true));
        }
      }
    }
  });

  // useEffect(() => {
  //   if (
  //     (userData &&
  //       (!userData.payload.name || !userData.payload.name === '') &&
  //       response.payload.level === 1) ||
  //     userError?.toLowerCase() == 'user not found'
  //   ) {
  //     history.push('/profile');
  //   }
  // }, []);

  return (
    // <Route
    //   {...otherProps}
    //   render={({ location }) =>
    //     //   userInfo?.token && response.payload.level === 0 ? (
    //     //     <Redirect
    //     //       to={{
    //     //         pathname: '/verify',
    //     //         state: { from: location },
    //     //       }}
    //     //     />
    //     //   ) : (
    //     //     <Redirect
    //     //       to={{
    //     //         pathname: '/signin',
    //     //         state: { from: location },
    //     //       }}
    //     //     />
    //     //   )
    //     // }
    //     userInfo?.token ? (
    //       userData && userData?.payload?.name ? (
    //         children
    //       ) : (
    //         ((userData && !userData?.payload?.name) ||
    //           userError === 'User Not found') && (
    //           <Redirect
    //             to={{
    //               pathname: '/profile',
    //               search: location.search,
    //               state: { from: location },
    //             }}
    //           />
    //         )
    //       )
    //     ) : userInfo?.token ? (
    //       <Redirect
    //         to={{
    //           pathname: '/verify',
    //           search: location.search,
    //           state: { from: location },
    //         }}
    //       />
    //     ) : (
    //       !userInfo && (
    //         <Redirect
    //           to={{
    //             pathname: '/signin',
    //             search: location.search,
    //             state: { from: location },
    //           }}
    //         />
    //       )
    //     )
    //   }
    // />
    // Enable public access to web app
    <Route
      {...otherProps}
      render={({ location }) =>
        !userInfo ||
        (userInfo?.token && userData && userData?.payload?.name !== '')
          ? children
          : ((userData && userData?.payload?.name === '') ||
              userError === 'User Not found') && (
              <Redirect
                to={{
                  pathname: '/profile',
                  search: location.search,
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
}

export default PrivateRoute;
