import React from 'react';
import Lottie from 'react-lottie';
import { CustomButton } from '../../../components';
import crashAnimationData from '../../../assests/json/crash.json';

function CrashFallback({ error }) {
  const CrashAnimationData = {
    loop: false,
    autoplay: true,
    animationData: crashAnimationData,
  };
  return (
    // <div className='not__found'>
    //   <Lottie options={CrashAnimationData} height={300} width={300} />
    //   <div className='h1' style={{ fontSize: 30, marginBottom: 20 }}>
    //     An error occurred
    //   </div>
    //   <div
    //     className='p'
    //     style={{
    //       color: '#8173E0',
    //       fontFamily: 'Poppins',
    //       fontSize: 17,
    //       maxWidth: 450,
    //       textAlign: 'center',
    //     }}
    //   >
    //     {/* {error?.message} */}
    //     Something went wrong
    //   </div>
    //   <CustomButton
    //     title='Try Again'
    //     onClick={() => window.location.reload()}
    //     style={{ marginTop: 40, marginBottom: 20 }}
    //   />
    // </div>
    <>
      <div className='crash_fallback_wrapper'>
        <Lottie options={CrashAnimationData} height={300} width={300} />
        <h1 className='oops'>Oops, Someting went wrong</h1>
        {/* <p className='info'>Someting went wrong</p> */}
        <br />
        <button className='button' onClick={() => window.location.reload()}>
          <i className='fas fa-redo'></i>Try Again
        </button>
      </div>
    </>
  );
}

export default CrashFallback;
