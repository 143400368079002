import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../../utils/query-helper';
import {
  useAnalytics,
  useBalance,
  useSegment,
  useWebSocketData,
} from '../../../../utils/hooks';
import { Image } from '../../../../components';
import { baseCurrency } from '../../../../utils/constants';

function WidgetsCard({ data }) {
  const {
    lastPrice,
    priceChangePercent,
    getStatus,
    getSocketFunctionalityInfo,
    formatMarketPair,
  } = useWebSocketData();
  const { formatCurrency, roundUpWithSymbol } = useBalance();
  const history = useHistory();
  const location = useLocation();
  const { sendTrackEvent } = useSegment();

  return (
    <>
      <div
        onClick={() => {
          history.push({
            pathname: `/pricedetails/${data.symbol}`,
            search: location.search,
          });
          sendTrackEvent('coinItemClick', { name: data?.symbol });
          // analytics?.track('Clicked the coin!', {
          //   symbol: data.symbol,
          //   uid: userInfo?.payload?.uid,
          //   from: 'Homepage',
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
        }}
        className='widgets__item'
        style={{ overflow: 'hidden', cursor: 'pointer' }}
      >
        <div className='widgets__head'>
          <div className='widgets__company'>
            <div className='widgets__logo'>
              <Image src={data.logo} alt='' />
            </div>
            <div className='widgets__details'>
              <div className='widgets__category'>{data.name}</div>
              <div
                className='widgets__info'
                style={{ textTransform: 'uppercase' }}
              >
                {data.symbol}
              </div>
            </div>
          </div>
          {/* <div className="widgets__time">{formatTime(data.symbol)}</div> */}
          {getSocketFunctionalityInfo().full ? (
            <div className='widgets__time'>24h</div>
          ) : (
            <div className='widgets__time'>
              <div
                className='dot-flashing small'
                style={{ marginTop: 17, marginRight: 20 }}
              ></div>
            </div>
          )}
        </div>
        <div className='widgets__body' style={{ marginBottom: 20 }}>
          <div className='widgets__line'>
            <div className='widgets__price' style={{ maxWidth: '170px' }}>
              {formatCurrency(lastPrice(formatMarketPair(data.symbol)))}{' '}
              {data.symbol == 'usdt'
                ? 'USDT'
                : `${baseCurrency?.toUpperCase()}`}
            </div>
            {getSocketFunctionalityInfo().full && (
              <>
                <div
                  className={`${
                    data.symbol != 'usdc' ? 'status' : ''
                  } ${getStatus(formatMarketPair(data.symbol))}`}
                  style={{ fontFamily: 'Poppins', fontWeight: '600' }}
                >
                  {priceChangePercent(formatMarketPair(data.symbol))}{' '}
                </div>
              </>
            )}
          </div>
          {/* <div className="widgets__chart">
          <div id="spark-4">
            <WidgetLineChart
              color={data.status === 'positive' ? '#4fbf67' : '#fc2c03'}
            />
          </div>
        </div> */}
        </div>
        <Link
          className='widgets__btn btn btn_orange btn_wide'
          to={(location) => ({
            ...location,
            pathname: `/pricedetails/${data.symbol}`,
          })}
          // onClick={() => {
          //   analytics?.track('Clicked the coin!', {
          //     symbol: data.symbol,
          //     from: 'Homepage',
          //     uid: userInfo?.payload?.uid,
          //     QueryParams: { ...getQueryParams() },
          //     ip: ip,
          //   });
          // }}
        >
          View Asset
        </Link>
      </div>
    </>
  );
}

export default WidgetsCard;
