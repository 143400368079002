import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavHeader from './nav-header/nav-header';
import NavContainer from './nav-container/nav-container';
import NavBottom from './nav-bottom/nav-bottom';
// import { routes, publicRoutes } from '../../utils/data';
import { images } from '../../utils/image/img';
import ReactTooltip from 'react-tooltip';
import { toggleNavbar } from '../../actions/navbarAction';
import { useViewport } from '../../utils/hooks';
import HomeSvg from '../../assests/img/home.svg';
import HistorySvg from '../../assests/img/history.svg';

function NetworkStatus({ showNetworkStatusText, networkStatus, isdark }) {
  const { open } = useSelector((state) => state.navbar);

  return (
    <>
      <div
        className='network__status mobile-hide'
        style={{
          alignItems: showNetworkStatusText ? 'baseline' : 'center',
          width: showNetworkStatusText ? 'fit-content' : '100%',
        }}
      >
        <img
          src={images[networkStatus]}
          data-tip={`${humanize(networkStatus)}`}
          data-for='network__status'
          style={{
            cursor: !showNetworkStatusText ? 'pointer' : 'auto',
          }}
        />
        {showNetworkStatusText && (
          <div className='h6'>{humanize(networkStatus)}</div>
        )}
        {!showNetworkStatusText && (
          <ReactTooltip
            place='bottom'
            id='network__status'
            type={isdark ? 'light' : 'dark'}
            className='tooltip_styles'
          />
        )}
      </div>
      <div className='mobile-show'>
        <div className='network__status'>
          <img src={images[networkStatus]} />
          {!open && <div className='h6'>{humanize(networkStatus)}</div>}
        </div>
      </div>
    </>
  );
}

function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}

function Navbar({ isExchangePage, reduceZIndex }) {
  const {
    navbar: { open },
    theme: { isdark },
    userLogin: { userInfo },
    getBanner: { bannerDetails, loading: bannerLoading },
  } = useSelector((state) => state);
  const [networkStatus, setNetworkStatus] = useState('connecting');
  const [showNetworkStatusText, setShowNetworkStatusText] = useState(false);
  const dispatch = useDispatch();

  const lastTradedMarket = () => {
    let lastVistedMarket = localStorage.getItem('last_traded_market') || null;
    const inList = bannerDetails?.payload?.markets_with_slash.find(
      (item) => item == lastVistedMarket?.replace('-', '/')
    );
    return inList ? lastVistedMarket : 'btc-usdc';
  };

  const routes = [
    {
      path: '/',
      name: 'Home',
      active: true,
      image: HomeSvg,
      cacheKey: null,
    },
    {
      path: `/exchange/${lastTradedMarket()}`,
      name: 'Trade',
      active: false,
      icon: 'chart',
      cacheKey: null,
    },
    // {
    //   path: '/prices',
    //   name: 'Prices',
    //   active: false,
    //   icon: 'document',
    // },
    {
      path: '/wallet',
      name: 'Wallets',
      active: false,
      icon: 'wallet',
      cacheKey: null,
    },
    // {
    //   path: '/promotion',
    //   name: 'Promotions',
    //   active: false,
    //   icon: 'discount',
    // },
    {
      path: '/history',
      name: 'History',
      active: false,
      image: HistorySvg,
      cacheKey: 'show_withdrawal_badge.brine',
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      active: false,
      image: images.leaderboard,
    },
    {
      path: '/salt',
      name: 'SALT score',
      active: false,
      image: images.saltScore,
      showAnimation: true,
      cacheKey: null,
    },
    {
      path: '/referral',
      name: 'Referrals',
      active: false,
      image: images.rewards,
      cacheKey: 'show_rewards_badge.brine',
    },
    // {
    //   path: '/',
    //   name: 'Settings',
    //   active: false,
    //   icon: 'fas fa-cog',
    // },
  ];

  const publicRoutes = [
    {
      path: '/',
      name: 'Home',
      active: true,
      image: HomeSvg,
      cacheKey: null,
    },
    {
      path: `/exchange/${lastTradedMarket()}`,
      name: 'Trade',
      active: false,
      icon: 'chart',
      cacheKey: null,
    },
    {
      path: '/wallet',
      name: 'Wallets',
      active: false,
      icon: 'wallet',
      cacheKey: null,
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      active: false,
      image: images.leaderboard,
    },
    {
      path: '/salt',
      name: 'SALT score',
      active: false,
      image: images.saltScore,
      showAnimation: true,
      cacheKey: null,
    },
    {
      path: '/referral',
      name: 'Referrals',
      active: false,
      image: images.rewards,
      cacheKey: null,
    },
    // {
    //   path: '/',
    //   name: 'Settings',
    //   active: false,
    //   icon: 'fas fa-cog',
    // },
  ];

  /*
    1.connecting
    2.stable_connection
    3.unstable_connection
    4.not_connected
  */

  useEffect(() => {
    if (isExchangePage) {
      setShowNetworkStatusText(!open);
    } else {
      setShowNetworkStatusText(open);
    }
  }, [isExchangePage, open]);

  useEffect(() => {
    setNetworkStatus('stable_connection');
    try {
      navigator.connection.onchange = function (e) {
        //do what you need to do ,on speed change event
        if (e.target?.effectiveType === '2g') {
          setNetworkStatus('unstable_connection');
        } else if (e.target?.effectiveType === '3g') {
          setNetworkStatus('stable_connection');
        }
      };
    } catch (error) {
      setNetworkStatus('stable_connection');
    }

    window.addEventListener('offline', (event) => {
      setNetworkStatus('not_connected');
    });

    window.addEventListener('online', (event) => {
      if (navigator?.connection?.effectiveType === '2g') {
        setNetworkStatus('unstable_connection');
      } else {
        setNetworkStatus('stable_connection');
      }
    });
  }, []);

  useEffect(() => {
    if (isExchangePage && !open) {
      dispatch(toggleNavbar());
    }
  }, [isExchangePage]);

  return (
    <div>
      <div
        className={`sidebar ${reduceZIndex ? 'zIndex' : ''} ${
          isExchangePage ? 'small' : ''
        } ${!open ? 'active' : ''}`}
      >
        <NavHeader />
        <NavContainer
          routes={userInfo?.token ? routes : publicRoutes}
          isExchangePage={isExchangePage}
        />
        <NetworkStatus
          showNetworkStatusText={showNetworkStatusText}
          humanize={humanize}
          networkStatus={networkStatus}
          isdark={isdark}
        />
        <NavBottom isExchangePage={isExchangePage} />
      </div>
    </div>
  );
}

export default Navbar;
