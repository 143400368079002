import React from 'react';
import { tempStaticTime } from '../../../utils/constants';

export const getTimeDiff = (endTime) => {
  const givenTimestamp = new Date(endTime);
  const currentTimestamp = new Date();
  const timeDifference = givenTimestamp - currentTimestamp;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  // console.log(`Difference: ${days} days, ${hours} hours, ${minutes} minutes`);

  return {
    days,
    hours,
    minutes,
  };
};

export const getTimeDiffWithExtra24Hours = (endTime) => {
  // Parse the input timestamp
  const givenTimestamp = new Date(tempStaticTime);

  // Calculate the time difference
  const currentTimestamp = new Date();
  const timeDifference = givenTimestamp - currentTimestamp;

  // Calculate days, hours, and minutes
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

  return {
    days,
    hours,
    minutes,
  };
};

export function CampaignTimeLine({ selectedFilter }) {
  // if (!selectedFilter?.isActive) {
  //   return null;
  // }

  let timeFunc = {
    active: getTimeDiff,
    inactive: getTimeDiffWithExtra24Hours,
  };

  return (
    <>
      {selectedFilter?.label && (
        <div className='event_timeline'>
          {selectedFilter?.isActive ? (
            <h1 className='text-mask'>{selectedFilter?.label} ENDS IN</h1>
          ) : (
            <h1 className='text-mask'>NEW EVENT STARTS IN</h1>
          )}
          <div className='campaign__timer'>
            <div>
              <div>
                <h1 className='text-mask'>
                  {timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                    selectedFilter?.end_time
                  ).days > 0
                    ? timeFunc[
                        selectedFilter?.isActive ? 'active' : 'inactive'
                      ](selectedFilter?.end_time).days
                    : 0}
                </h1>
              </div>
              <div>
                <p>days</p>
              </div>
            </div>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <div>
                <h1 className='text-mask'>
                  {timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                    selectedFilter?.end_time
                  ).hours > 0
                    ? timeFunc[
                        selectedFilter?.isActive ? 'active' : 'inactive'
                      ](selectedFilter?.end_time).hours
                    : 0}
                </h1>
              </div>
              <div>
                <p>hours</p>
              </div>
            </div>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <div>
                <h1 className='text-mask'>
                  {timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                    selectedFilter?.end_time
                  ).minutes > 0
                    ? timeFunc[
                        selectedFilter?.isActive ? 'active' : 'inactive'
                      ](selectedFilter?.end_time).minutes
                    : 0}
                </h1>
              </div>
              <div>
                <p>mins</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
