import { Popup, CustomButton, Image } from '../../../components';
import { images } from '../../../utils/image/img';

function MetaPopup({ openInstructionPopUp, setOpenInstructionPopUp }) {
  return (
    <div className='meta_instruction'>
      <Popup state={openInstructionPopUp} setState={setOpenInstructionPopUp}>
        <div className='meta_instruction__bg'>
          <p>It just takes a few seconds to setup ✅</p>
          <Image src={images.logoWhite} style={{ width: 42 }} alt='' />
        </div>
        <div style={{ marginBottom: 100, marginTop: 20 }}>
          <div className='meta_instruction_flex_container'>
            <div className='meta_instruction_item'>
              <ul>
                <li>
                  Step 1.Go to the Metamask website{'  '}{' '}
                  <CustomButton
                    title='Click here'
                    onClick={() =>
                      window.open('https://metamask.io/download/', '_blank')
                    }
                  />
                </li>
                <li>
                  Step 2.Click on <span>Install Now</span>
                </li>
                <li>
                  Step 3.Click on <span>Add to Browser</span>{' '}
                </li>
                <li>Step 4.Once Added, Click on Get Started </li>
              </ul>
            </div>
            <div className='meta_instruction_item'>
              <Image src={images.metamask} alt='' />
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default MetaPopup;
