import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AuthButton from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNonce,
  logout,
  storeKey,
  storeUserViaMethod,
  walletLogin,
} from '../../../actions/userAction';
import { toast } from 'react-toastify';
import { useMeta, useSegment } from '../../../utils/hooks';
import { ethers } from 'ethers';
import { USER_LOGIN_RESET } from '../../../constants/userConstant';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import { USER_ADD_KEY_CLEANUP } from '../../../constants/userConstant';
import { useWeb3React } from '@web3-react/core';
import {
  useEagerConnect,
  useInactiveListener,
  useWalletMethods,
} from '../../../utils/hooks';

import { WalletLoginInfo, WalletLoginInfoV2 } from '../../../components';
import { scroller } from 'react-scroll';
import {
  ALLOWED_CHAIN_ID,
  metamaskDeepLinkUrl,
  staticMsg,
} from '../../../utils/constants';
import { images } from '../../../utils/image/img';
import { sanitizeProvider } from '../../../utils/sanitize-provider';
import { isMobileBrowser } from '../../../utils/browser-checker';
import { metaMask } from '../../../utils/connectors-v8/metaMask';

function MetaLogin({
  history,
  setSelectedOption,
  loginState,
  setLoginState,
  location,
}) {
  const [openInstructionPopUp, setOpenInstructionPopUp] = useState(false);
  const dispatch = useDispatch();
  const { connectWallet, connectedAccount } = useMeta();
  const [stateLoading, setStateLoading] = useState(false);
  const [clickedVerfiy, setClickedVerfiy] = useState(false);
  const [signedKey, setSignedKey] = useState(null);
  const [connectedSignature, setConnectSignature] = useState(null);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [metaProvider, setMetaProvider] = useState(null);
  const {
    signMessageL1,
    METAMASK_POSSIBLE_ERRORS,
    provider: providerMeta,
    get0X0to0X,
  } = useMeta();
  const context = useWeb3React();
  const {
    connector,
    provider,
    chainId,
    account,
    // activate,
    deactivate,
    error: contextError,
  } = context;
  const { activate } = connector;
  const [activatingConnector, setActivatingConnector] = useState();
  const { analytics, sendTrackEvent } = useSegment();
  // const {
  //   userKey: { state },
  // } = useSelector((state) => state);
  const { state } = useSelector((state) => state.userKey);
  const [openNetworkPrompt, setOpenNetworkPrompt] = useState(false);
  useEffect(() => {
    if (
      activatingConnector &&
      activatingConnector === connector &&
      provider?.connection?.url !== 'metamask'
    ) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo: userLoginInfo } = userLogin;

  const handleSubmit = (signature) => {
    sendTrackEvent('finalLoginRequestInitiated', {
      walletType: 'metamask',
      address: account,
    });
    dispatch(walletLogin(account, signature, get0X0to0X(signedKey), state));
  };

  const getChainId = async () => {
    if (metaProvider) {
      let chainId = await metaProvider.request({ method: 'eth_chainId' });
      return chainId;
    }
  };

  const handleLoginWithReferral = async () => {
    sendTrackEvent('finalLoginRequestInitiated', {
      walletType: 'metamask',
      address: account,
    });
    const chainId = await getChainId();
    if (chainId == ALLOWED_CHAIN_ID) {
      dispatch(
        walletLogin(
          account,
          connectedSignature,
          get0X0to0X(signedKey),
          state,
          referralCode
        )
      );
    } else {
      // toast.loading(<SuccessMsg />, {
      //   toastId: 'wrong_network',
      // });
      setOpenNetworkPrompt(true);
      scroller.scrollTo('network_error_container', {
        duration: 400,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const getNonceAPIcall = async (account) => {
    sendTrackEvent('loginNonceRequestInitiated', {
      walletType: 'metamask',
      address: account,
    });
    try {
      const data = await getNonce(account);
      sendTrackEvent('loginNonceRequestSuccess', {
        walletType: 'metamask',
        address: account,
      });
      return data;
    } catch (error) {
      sendTrackEvent('loginNonceRequestFailure', {
        walletType: 'metamask',
        address: account,
        error: error,
      });
      toast.error(error ? error : 'Something went wrong');
      setStateLoading(false);
    }
  };

  const handleGetNonce = async () => {
    if (!clickedVerfiy) {
      return toast.error('Click the toggle button to verify you’re human.');
    }

    dispatch({
      type: USER_LOGIN_RESET,
    });

    const chainId = await getChainId();
    if (chainId == ALLOWED_CHAIN_ID) {
      try {
        setStateLoading(true);
        const data = await getNonceAPIcall(account);
        sendTrackEvent('secondMessageSignRequestInitiated', {
          walletType: 'metamask',
          address: account,
        });
        const sig = await signMsg(data.payload);
        if (sig) {
          sendTrackEvent('secondMessageSignRequestSuccess', {
            walletType: 'metamask',
            address: account,
          });
          handleSubmit(sig);
          setConnectSignature(sig);
          // console.log(sig);
        }
        setStateLoading(false);
      } catch (error) {
        if (error?.code === 'ACTION_REJECTED' || error?.code === 4001) {
          sendTrackEvent('secondMessageSignRequestUserAbort', {
            walletType: 'metamask',
            msg: error,
            address: account,
          });
        } else {
          sendTrackEvent('secondMessageSignRequestFailure', {
            walletType: 'metamask',
            address: account,
            error: error,
          });
        }
        toast.error(error ? error : 'Something went wrong');
        setStateLoading(false);
      }
    } else {
      // toast.loading(<SuccessMsg />, {
      //   toastId: 'wrong_network',
      // });
      setOpenNetworkPrompt(true);
      scroller.scrollTo('network_error_container', {
        duration: 400,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  const signMsg = (msg) => {
    if (!!(provider && account)) {
      return provider.getSigner(account).signMessage(msg);
    }
  };

  const connectWalletLink = () => {
    // if (!metaProvider?.isConnected() || !metaProvider?._state?.initialized) {
    //   toast.error('Something went wrong; please try again later.');
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    // }
    // if (provider?.connection?.url !== 'metamask') {

    if (isMobileBrowser() && !metaProvider) {
      window.open(metamaskDeepLinkUrl, '_blank');
    }

    if (!metaProvider && !isMobileBrowser()) {
      setClickedVerfiy(false);
      return toast.error('Install Metamask to continue.');
    }

    sendTrackEvent('brineWalletConnectionInitiated', {
      walletType: 'metamask',
    });
    // setActivatingConnector(injected);
    // activate(injected, (error) => {
    //   if (error) {
    //     console.log({ error });
    //     setActivatingConnector(undefined);
    //     sendTrackEvent('brineWalletConnectionFailure', {
    //       error: error,
    //       walletType: 'metamask',
    //     });
    //   } else {
    //     sendTrackEvent('brineWalletConnectionSuccess', {
    //       walletType: 'metamask',
    //     });
    //   }
    // });
    metaMask
      .activate()
      .then((res) => {
        sendTrackEvent('brineWalletConnectionSuccess', {
          walletType: 'metamask',
        });
      })
      .catch((error) => {
        setActivatingConnector(undefined);
        if (error?.code === 'ACTION_REJECTED' || error?.code === 4001) {
          sendTrackEvent('brineWalletConnectionUserAbort', {
            walletType: 'metamask',
            msg: error,
            address: account,
          });
        } else {
          sendTrackEvent('brineWalletConnectionFailure', {
            error: error,
            walletType: 'metamask',
          });
        }
      });
  };
  // };

  const checkNetwork = async () => {
    const chainId = await getChainId();
    if (chainId !== ALLOWED_CHAIN_ID) {
      // toast.loading(<SuccessMsg />, {
      //   toastId: 'wrong_network',
      // });
      setOpenNetworkPrompt(true);
      scroller.scrollTo('network_error_container', {
        duration: 400,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  // const SuccessMsg = ({ closeToast, toastProps }) => {
  //   return (
  //     <div>
  //       <p className="font-bold">Oops, You seem to be on the wrong lane.</p>
  //       <p>
  //         Kindly change your network to Goerli by clicking on the link below 👇
  //       </p>
  //       <p
  //         style={{ textDecoration: 'underline' }}
  //         onClick={() => {
  //           changeNetwork();
  //           closeToast();
  //         }}
  //       >
  //         <i className="text-indigo-600 underline">Click here</i>
  //       </p>
  //     </div>
  //   );
  // };

  useEffect(() => {
    sanitizeProvider(setMetaProvider);
  }, []);

  useEffect(async () => {
    if (clickedVerfiy) {
      if (isMobileBrowser() && !metaProvider) {
        window.open(metamaskDeepLinkUrl, '_blank');
      }

      if (!metaProvider && !isMobileBrowser()) {
        setClickedVerfiy(false);
        return toast.error('Install Metamask to continue.');
      }
      // if (!metaProvider?.isConnected() || !metaProvider?._state?.initialized) {
      //   if (isMobileBrowser()) {
      //     toast.error(
      //       'Oops! Seems like Metamask isn’t supported on this browser. We suggest you use your Metamask Wallet via Walletconnect'
      //     );
      //     setClickedVerfiy(false);
      //   } else {
      //     toast.error('Something went wrong; please try again later.');
      //     setTimeout(() => {
      //       window.location.reload();
      //     }, 1000);
      //     setClickedVerfiy(false);
      //   }
      //   return;
      // }
      checkNetwork();
      dispatch({
        type: USER_ADD_KEY_CLEANUP,
      });

      if (!account) {
        connectWalletLink();
        setClickedVerfiy(false);
      } else {
        sendTrackEvent('brineWalletConnectionSuccess', {
          walletType: 'metamask',
        });
        const chainId = await metaProvider.request({
          method: 'eth_chainId',
        });
        if (chainId == ALLOWED_CHAIN_ID) {
          sendTrackEvent('walletFirstSignRequestInitiated', {
            walletType: 'metamask',
            address: account,
          });
          signMsg(staticMsg)
            .then((data) => {
              toast.dismiss('meta_first_open');
              dispatch(storeKey(data));
              let stark_private_key =
                getKeyPairFromSignature(data).getPrivate();
              let stark_public_key = getKeyPairFromSignature(data)
                .getPublic()
                .getX();
              // console.log(`Private Key: ${stark_private_key}`);
              // console.log(`Public Key: ${stark_public_key}`);
              const finalKey = ethers.BigNumber.from(
                stark_public_key.toString()
              ).toHexString();
              setSignedKey(finalKey);
              sendTrackEvent('walletFirstSignRequestSuccess', {
                walletType: 'metamask',
                address: account,
              });
            })
            .catch((e) => {
              toast.dismiss('meta_first_open');
              toast.error(METAMASK_POSSIBLE_ERRORS[e.code]?.message);
              setClickedVerfiy(false);
              if (e?.code === 'ACTION_REJECTED' || e?.code === 4001) {
                sendTrackEvent('brineWalletConnectionUserAbort', {
                  walletType: 'metamask',
                  msg: e,
                  address: account,
                });
                sendTrackEvent('walletFirstSignRequestUserAbort', {
                  walletType: 'metamask',
                  address: account,
                  error: e,
                });
              } else {
                sendTrackEvent('brineWalletConnectionFailure', {
                  walletType: 'metamask',
                  error: e,
                  address: account,
                });
                sendTrackEvent('walletFirstSignRequestFailure', {
                  walletType: 'metamask',
                  address: account,
                  error: e,
                });
              }
            });
        } else {
          setOpenNetworkPrompt(true);
          scroller.scrollTo('network_error_container', {
            duration: 400,
            delay: 0,
            smooth: 'easeInOutQuart',
          });
          setClickedVerfiy(false);
        }
      }
    }
  }, [clickedVerfiy, metaProvider]);

  useEffect(() => {
    if (error && error !== 'Please enter referral code') {
      sendTrackEvent('finalLoginRequestFailure', {
        walletType: 'metamask',
        address: account,
        error: error,
      });
      toast.error(error);
      setStateLoading(false);
    }
    if (error === 'Please enter referral code') {
      setOpenLoginPopup(true);
    }
    if (userLoginInfo && userLoginInfo.token) {
      sendTrackEvent('finalLoginRequestSuccess', {
        walletType: 'metamask',
        address: account,
      });
      history.push('/');
      dispatch(
        storeUserViaMethod({
          via: 'metamask',
          address: account,
        })
      );
      setStateLoading(false);
    }
  }, [userLoginInfo, history, error]);

  useEffect(() => {
    if (metaProvider) {
      metaProvider.on('accountsChanged', (accounts) => {
        if (location?.pathname === '/signin') {
          setClickedVerfiy(false);
          window.location.reload();
        }
      });
      metaProvider.on('chainChanged', (chainId) => {
        if (location?.pathname === '/signin') {
          setClickedVerfiy(false);
          // window.location.reload();
        }
      });
    }
  }, [metaProvider, location?.pathname]);

  useEffect(() => {
    if (signedKey && clickedVerfiy) {
      handleGetNonce();
    }
  }, [signedKey, clickedVerfiy]);

  return (
    <WalletLoginInfoV2
      setLoginState={setLoginState}
      setSelectedOption={setSelectedOption}
      deactivate={deactivate}
      dispatch={dispatch}
      WalletImg={images.metamask}
      showMetaMaskPopup={true}
      setOpenNetworkPrompt={setOpenNetworkPrompt}
      openNetworkPrompt={openNetworkPrompt}
      account={account}
      clickedVerfiy={clickedVerfiy}
      setClickedVerfiy={setClickedVerfiy}
      loading={loading}
      stateLoading={stateLoading}
      provider={provider}
      handleGetNonce={handleGetNonce}
      connectWalletLink={connectWalletLink}
      setOpenInstructionPopUp={setOpenInstructionPopUp}
      openInstructionPopUp={openInstructionPopUp}
      openLoginPopup={openLoginPopup}
      setOpenLoginPopup={setOpenLoginPopup}
      referralCode={referralCode}
      setReferralCode={setReferralCode}
      handleLoginWithReferral={handleLoginWithReferral}
      signedKey={signedKey}
    />
  );
}

export default withRouter(MetaLogin);
