import React from 'react';
import Image from '../../common/img/image';
import { images } from '../../../utils/image/img';
import { useSelector } from 'react-redux';

export function LeaderBoardCell({
  item,
  idx,
  randomImgs,
  userScore,
  selectedFilter,
}) {
  const { userInfo } = useSelector((s) => s.userLogin);
  return (
    <div
      className={`prices__row leaderBoard__row ${
        item.rank == userScore?.rank ? 'active' : ''
      }`}
      style={{ cursor: 'auto' }}
    >
      <div className='prices__cell'>
        <div className='prices__company'>
          <div className='prices__logo'>
            <Image
              src={
                userInfo?.payload?.uid === item?.user_uid
                  ? images.getIdenticonImg(4)
                  : randomImgs[idx]
              }
              alt=''
            />
          </div>
          <div
            className='prices__text'
            style={{
              fontSize: 15,
            }}
          >
            {item.name}
          </div>
        </div>
      </div>
      <div
        className='prices__cell'
        style={{
          textTransform: 'uppercase',
          fontSize: 15,
        }}
      >
        {item.email?.replace('@letskrypto.com', '')}
      </div>
      <div
        className='prices__cell'
        style={{
          textTransform: 'uppercase',
        }}
      >
        {Number(item?.traded_volume)?.toFixed()}
      </div>
      <div
        className='prices__cell'
        style={{
          fontSize: 20,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {!selectedFilter?.isActive &&
        (item.rank == 1 || item.rank == 2 || item.rank == 3) ? (
          <>
            <img
              src={
                item.rank == 1
                  ? images.LbWinnerImg1
                  : item.rank == 2
                  ? images.LbWinnerImg2
                  : images.LbWinnerImg3
              }
              style={{
                height: 32,
                // marginLeft: 3,
              }}
            />
          </>
        ) : (
          <div>#{item?.rank}</div>
        )}
      </div>
    </div>
  );
}
