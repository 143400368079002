import axios from 'axios';
import { networkName } from '../utils/urls';

export const sendFeedback = async (feedback) => {
  let baseUrl =
    networkName === 'mainnet'
      ? 'https://api.brine.fi/feedback/feedback/'
      : 'https://api-testnet.brine.fi/feedback/feedback/';
  try {
    const { data } = await axios.post(baseUrl, {
      feedback: feedback.trim(),
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};
