import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HelmetContainer,
  CustomSelect,
  SkeletonContainer,
  Image,
  CustomButton,
  NotFoundedAnimation,
} from '../../components';
import Layout from '../../components/layout/layout';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getLeaderBoardCampaign,
  getLeaderBoardList,
  getUserScoreLeaderBoaadScore,
  getArchivedLeaderBoardCampaign,
} from '../../actions/leaderboard';
import { toast } from 'react-toastify';
import { images } from '../../utils/image/img';
import { useSegment, useViewport } from '../../utils/hooks';
import { LeaderBoardTags } from '../../components/leaderboard/leaderboard-tags/leaderboard-tags';
import { LeaderBoardCell } from '../../components/leaderboard/leaderboard-cell/leaderboard-cell';
import { PrizeDetails } from '../../components/leaderboard/leaderboard-prize-details/leaderboard-prize-details';
import { WinnerDetails } from '../../components/leaderboard/leaderboard-winner-details/leaderboard-winner-details';
import { UserStats } from '../../components/leaderboard/leaderboard-userstats/leaderboard-userstats';
import { UserScore } from '../../components/leaderboard/leaderboard-userscore/leaderboard-userscore';
import Carousel from 'react-multi-carousel';
import { ProTip } from '../../components/leaderboard/leaderboard-protip/leaderboard-protip';
import LeaderboardIndividualScore from '../../components/leaderboard/leaderboard-individual-score/leaderboard-individual-score';

const randomImg = () => {
  const random = Math.floor(Math.random() * 4);
  return images.getIdenticonImg(Number(random) + 1);
};

export function sanitizePrizeData(prizeData) {
  if (prizeData && prizeData?.length) {
    const prizeImages = {
      firstPrize: images.firstPrize,
      secondPrize: images.thirdPrize,
      thirdPrize: images.secondPrize,
      otherPrize: images.secondPrize,
    };
    let totalPrizeAmount = 0;
    const sanitizedData = prizeData.map((prize) => {
      const positions = prize.position;
      const rewards = prize.rewards;

      let name;
      if (positions.length === 1) {
        const position = positions[0];
        if (position === '1') name = '1st';
        else if (position === '2') name = '2nd';
        else if (position === '3') name = '3rd';
        else name = position;
      } else {
        name = `${positions[0]}-${positions[positions.length - 1]}`;
      }

      const rewardCard = rewards.find(
        (reward) => reward.type === 'REWARD_CARD'
      );
      const nft = rewards.find((reward) => reward.type === 'NFT');

      const prizeArray = [];
      if (rewardCard) {
        let prizeAmount = rewardCard.meta.amount;
        totalPrizeAmount += prizeAmount * positions.length; // Accumulate for each position
        prizeArray.push(`💰 $${prizeAmount} USDC`);
      }
      if (nft) {
        prizeArray.push(`🚀 Brinerd NFT X 1`);
      }

      return {
        img:
          name === '1st'
            ? prizeImages.firstPrize
            : name === '2nd'
            ? prizeImages.secondPrize
            : name === '3rd'
            ? prizeImages.thirdPrize
            : prizeImages.otherPrize,
        name,
        prize: prizeArray,
      };
    });

    return { sanitizedData, totalPrizeAmount };
  }
}

function LeaderboardPage() {
  const { isdark } = useSelector((state) => state.theme);
  const [randomImgs, setRandomImgs] = useState([]);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [leaderBoardData, setLeaderBoardData] = useState(null);
  const [campaignMeta, setCampaignMeta] = useState(null);
  const [leaderBoardDataLoading, setLeaderBoardDataLoading] = useState(false);
  const [leaderBoardUserDataLoading, setLeaderBoardUserDataLoading] =
    useState(false);
  const [userScore, setUserScore] = useState(null);
  const [showMore, setShowMore] = useState(true);
  const [proTip, setProTip] = useState(null);
  const { width } = useViewport();
  const dispatch = useDispatch();
  // const {
  //   campaignsList: {
  //     response: campaignsResponse,
  //     loading: campaignsLoading,
  //     error: campaignsError,
  //   },
  //   archivedCampaignsList: {
  //     response: acampaignsResponse,
  //     loading: acampaignsLoading,
  //     error: acampaignsError,
  //   },
  //   userLogin: { userInfo },
  // } = useSelector((state) => state);

  const {
    response: campaignsResponse,
    loading: campaignsLoading,
    error: campaignsError,
  } = useSelector((state) => state.campaignsList);

  const {
    response: acampaignsResponse,
    loading: acampaignsLoading,
    error: acampaignsError,
  } = useSelector((state) => state.archivedCampaignsList);

  const { userInfo } = useSelector((state) => state.userLogin);

  const { sendPageEvent, analytics, sendTrackEvent } = useSegment();

  const leaderboardStyle = {
    yourScoreContainer: {
      padding: '20px 20px',
      marginBottom: 20,
      scrollMarginTop: 10,
      fontFamily: 'Poppins',
    },
    yourScore: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    yourScoreP1: {
      textAlign: 'right',
      color: '#808191',
      marginRight: 12,
    },
    yourScoreSpan1: {
      fontSize: 20,
      color: isdark ? '#fff' : '#000',
    },
    hrStyle: {
      borderRight: '0.5px solid #808191',
      height: '60px',
    },
    yourScoreP2: {
      textAlign: 'right',
      color: '#808191',
      marginLeft: 12,
    },
    yourScoreSpan2: {
      color: '#4fbf67',
      fontSize: 20,
    },
  };

  useEffect(() => {
    let imgs = [];
    for (let i = 0; i <= 100; i++) {
      imgs.push(randomImg());
    }
    setRandomImgs(imgs);
    dispatch(getLeaderBoardCampaign());
    dispatch(getArchivedLeaderBoardCampaign());
  }, [dispatch]);

  function sortObjectsByTimestamp(arrayOfObjects) {
    // Get the current date to compare against
    const currentDate = new Date();

    // Custom sorting function to sort by the 'timestamp' property
    arrayOfObjects.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      // Compare timestamps in descending order
      const timestampComparison = dateB - dateA;

      // If timestamps are the same, prioritize the newer object
      if (timestampComparison === 0) {
        return currentDate - dateB;
      }

      return timestampComparison;
    });

    return arrayOfObjects;
  }

  useEffect(() => {
    if (campaignsResponse?.payload && acampaignsResponse?.payload) {
      let campaignNames = [];

      campaignsResponse?.payload?.forEach((item) => {
        campaignNames.push({
          label: item?.name,
          id: item?.campaign_uid,
          value: item?.campaign_uid,
          isActive: true,
          ...item,
        });
      });

      if (acampaignsResponse?.payload?.length) {
        const sortedArray = sortObjectsByTimestamp(acampaignsResponse?.payload);
        sortedArray.slice(0, 1).forEach((item) => {
          campaignNames.push({
            label: item?.name,
            id: item?.campaign_uid,
            value: item?.campaign_uid,
            isActive: false,
            ...item,
          });
        });
      }

      setSortingOptions(campaignNames);
      setSelectedFilter(campaignNames[0]);
    }
    // if (campaignsError) {
    //   toast.error(campaignsError);
    // }
  }, [
    campaignsResponse,
    acampaignsResponse,
    // , campaignsError
  ]);

  useEffect(() => {
    if (analytics) {
      sendPageEvent('leaderboardPageVisited');
    }
  }, [analytics]);

  useEffect(() => {
    if (analytics && selectedFilter) {
      sendTrackEvent('tabSelected', {
        tab: selectedFilter?.label,
        market: selectedFilter?.market,
      });
    }
  }, [analytics, selectedFilter]);

  const handleFetchLeaderboard = async () => {
    if (selectedFilter?.value) {
      setLeaderBoardDataLoading(true);
      setLeaderBoardUserDataLoading(true);
      setShowMore(true);
      try {
        const data = await getLeaderBoardList(
          // 'aaee3e74-6aaf-45b1-b899-bd95b002916b'
          selectedFilter?.value,
          selectedFilter?.isActive,
          userInfo?.token?.access
        );
        if (
          userInfo?.payload?.uid &&
          userInfo?.token?.access &&
          selectedFilter?.isActive
        ) {
          handleGetUserStats(selectedFilter?.value);
          console.log({ data });
        } else {
          console.log({ user_data: data?.payload?.user_data });
          setUserScore({ user_data: data?.payload?.user_data });
          setLeaderBoardUserDataLoading(false);
        }
        setCampaignMeta(selectedFilter);
        console.log({ data });
        setLeaderBoardData(data?.payload?.leaderboard);
        // setUserScore(data?.payload?.user_data);
      } catch (error) {
        toast.error(error);
        console.log(error);
      }
    }
    setLeaderBoardDataLoading(false);
  };

  const handleGetUserStats = async (campaign_id) => {
    try {
      const data = await getUserScoreLeaderBoaadScore(
        userInfo?.token?.access,
        campaign_id
        // userInfo?.payload?.uid
      );
      setUserScore(data['payload']);
      setLeaderBoardUserDataLoading(false);
    } catch (error) {
      console.log({ error });
      setLeaderBoardUserDataLoading(false);
    }
  };

  useEffect(() => {
    handleFetchLeaderboard();

    return () => {
      setUserScore(null);
      setCampaignMeta(null);
    };
  }, [selectedFilter, userInfo?.payload?.uid, userInfo?.token?.access]);

  const prizeSliderResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1920 },
      items: 5,
    },
    xl: {
      breakpoint: { max: 1910, min: 1710 },
      items: 4,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 1709, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  console.log('re-rending....');

  return (
    <Layout>
      <HelmetContainer title='Leaderboard' />
      <div className='page__container'>
        {((!campaignsLoading &&
          !acampaignsLoading &&
          campaignsResponse?.payload?.length === 0 &&
          acampaignsResponse?.payload?.length === 0) ||
          campaignsError) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NotFoundedAnimation
              size={width > 750 ? 'lg' : 'sm'}
              text='No leaderboard events happening right now.'
            />
          </div>
        )}
        {(campaignsLoading ||
          acampaignsLoading ||
          campaignsResponse?.payload?.length > 0 ||
          acampaignsResponse?.payload?.length > 0) && (
          <>
            <div className='categories leaderboard_page'>
              <div className='leaderboard__title'>
                <div className='categories__title h5'>
                  Campaigns
                  <div className='activity__select notifications__select leaderboard__select tablet-show'>
                    {selectedFilter?.value && (
                      <CustomSelect
                        defaultValue={selectedFilter}
                        options={sortingOptions}
                        onChange={(data) => {
                          setSelectedFilter(data);
                        }}
                      />
                    )}
                  </div>
                  <LeaderBoardTags
                    setSelectedFilter={setSelectedFilter}
                    selectedFilter={selectedFilter}
                    options={sortingOptions}
                    addTagclassName='activity__tags tablet-hide'
                    addLinksclassName='activity__link leader_board_link'
                    style={{ marginLeft: 0, marginTop: 10, marginBottom: 15 }}
                  />
                </div>
                {/* {userScore && (
                  <div
                    className='categories__item your__score'
                    style={leaderboardStyle.yourScoreContainer}
                  >
                    <div style={leaderboardStyle.yourScore}>
                      <p style={leaderboardStyle.yourScoreP1}>
                        Your score <br />{' '}
                        <span style={leaderboardStyle.yourScoreSpan1}>
                          {Number(userScore?.traded_volume).toFixed(3)}
                        </span>
                      </p>
                      <hr style={leaderboardStyle.hrStyle} />
                      <p style={leaderboardStyle.yourScoreP2}>
                        Rank <br />{' '}
                        <span style={leaderboardStyle.yourScoreSpan2}>
                          {userScore?.rank == 'NA'
                            ? '>100'
                            : '#' + userScore?.rank}
                        </span>
                      </p>
                    </div>
                  </div>
                )} */}
              </div>
              <div
              // style={{
              //   marginBottom: 20,
              // }}
              >
                <UserStats
                  selectedFilter={selectedFilter}
                  userScore={userScore}
                  leaderBoardData={leaderBoardData}
                  leaderBoardUserDataLoading={leaderBoardUserDataLoading}
                  totalPrizeAmount={
                    sanitizePrizeData(selectedFilter?.prizes?.prizes)
                      ?.totalPrizeAmount
                  }
                  proTip={proTip}
                />
              </div>
              {leaderBoardUserDataLoading ||
              (userInfo?.token?.access && !userScore && !proTip) ? null : (
                <Carousel
                  responsive={prizeSliderResponsive}
                  sliderClass='user__stats_slider'
                  itemClass='user__stats_slider_item'
                >
                  <UserScore
                    userScore={userScore}
                    leaderBoardData={leaderBoardData}
                    selectedCampaign={selectedFilter}
                  />
                  <LeaderboardIndividualScore
                    userScore={userScore}
                    selectedCampaign={selectedFilter}
                  />
                  {userInfo?.token?.access &&
                    userScore?.user_data?.rank?.rank != 1 &&
                    userScore?.user_data?.rank?.rank !== -1 && (
                      <ProTip
                        leaderBoardData={leaderBoardData}
                        userScore={userScore}
                        proTip={proTip}
                        setProTip={setProTip}
                        selectedCampaign={selectedFilter}
                      />
                    )}
                </Carousel>
              )}
              {selectedFilter && selectedFilter?.isActive && (
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 18,
                  }}
                >
                  <PrizeDetails
                    campaignsLoading={
                      campaignsLoading || acampaignsLoading || !selectedFilter
                    }
                    prizeDetails={
                      sanitizePrizeData(selectedFilter?.prizes?.prizes)
                        .sanitizedData
                    }
                  />
                </div>
              )}
              {selectedFilter &&
                !selectedFilter?.isActive &&
                !leaderBoardUserDataLoading &&
                !acampaignsLoading && (
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 18,
                    }}
                  >
                    <WinnerDetails
                      campaignsLoading={
                        campaignsLoading || acampaignsLoading || !selectedFilter
                      }
                      winnerDetails={leaderBoardData?.slice(0, 2)}
                    />
                  </div>
                )}

              {campaignsLoading ||
              leaderBoardDataLoading ||
              acampaignsLoading ? (
                <div style={{ marginTop: 20 }}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                    <SkeletonContainer
                      key={item}
                      style={{
                        height: 60,
                        borderRadius: 10,
                        marginBottom: 15,
                      }}
                    />
                  ))}
                </div>
              ) : (
                <>
                  {leaderBoardData?.length == 0 ? (
                    <NotFoundedAnimation
                      size='sm'
                      text='No leaderboard data available for this campaign.'
                      textStyle={{ textAlign: 'center' }}
                    />
                  ) : (
                    <>
                      <div className='leaderboard__title'>
                        <div
                          className='categories__title h5'
                          style={{ fontSize: 21 }}
                        >
                          Leaderboard
                        </div>
                      </div>
                      <div className='prices__container'>
                        <div className='prices__table'>
                          <div className='prices__row prices__row_head leaderBoard__row_head'>
                            <div className='prices__cell'>Username</div>
                            <div className='prices__cell'>address</div>
                            <div className='prices__cell'>trading volume</div>
                            <div className='prices__cell'>Rank</div>
                          </div>
                          {leaderBoardData
                            ?.slice(0, showMore ? 50 : leaderBoardData.length)
                            .map((item, idx) => (
                              <LeaderBoardCell
                                key={idx}
                                item={item}
                                idx={idx}
                                randomImgs={randomImgs}
                                userScore={userScore}
                              />
                            ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {leaderBoardData?.length > 50 && (
                <div
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    marginTop: 32,
                  }}
                >
                  <CustomButton
                    title={!showMore ? 'Show less' : 'Load more'}
                    onClick={() => setShowMore((prev) => !prev)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default LeaderboardPage;
