function BrowserChecker() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  let str = M.join(' ');
  var browser = str.substring(0, str.indexOf(' '));
  var version = str.substring(str.indexOf(' '));

  return {
    browser,
    version,
  };
}

export const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator?.userAgent
  );
};

export const isLegacyBrowser = () => {
  if (isMobileBrowser()) {
    // true for mobile device
    return false;
  }
  var { browser, version } = BrowserChecker();

  let ver = version?.trim();
  ver = parseInt(ver);

  if (version) {
    if (
      (browser == 'Chrome' && version < 70) ||
      (browser == 'Firefox' && version < 53) ||
      (browser == 'Safari' && version < 5) ||
      (browser == 'IE' && version < 11) ||
      (browser == 'Opera' && version < 52)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
