import CustomButton from '../custom-button/custom-button';
import { images } from '../../../utils/image/img';
import Lottie from 'react-lottie';
import crashAnimationData from '../../../assests/json/crash.json';

export default function LegacyBrowser() {
  const CrashAnimationData = {
    loop: false,
    autoplay: true,
    animationData: crashAnimationData,
  };
  return (
    <div className='server__down' style={{ marginTop: 70 }}>
      {/* <div className='h2'>
        Oops, This browser is no longer supported<span>.</span>{' '}
      </div> */}
      <Lottie options={CrashAnimationData} height={400} width={400} />

      {/* <i class='fas fa-exclamation-circle' style={{ fontSize: 90 }}></i> */}
      <p
        style={{ fontSize: 18, marginTop: 20, marginBottom: 20, minWidth: 610 }}
        className='oops'
      >
        We are sorry, the browser version you are using is not compatible with
        Brine app. Please use the latest version of{' '}
        <a
          href='https://www.google.com/intl/en_in/chrome/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Chrome
        </a>
        ,{' '}
        <a
          href='https://www.opera.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Opera
        </a>
        ,{' '}
        <a
          href='https://www.opera.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Firefox
        </a>
        , or{' '}
        <a
          href='https://www.opera.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Safari
        </a>
      </p>
      {/* <CustomButton
        title='Try again'
        onClick={() => window.location.reload()}
      /> */}
    </div>
  );
}
