import * as leaderboardConstants from '../constants/leaderboardConstant';

export const getLeaderboardCampaignsReducer = (state = {}, action) => {
  switch (action.type) {
    case leaderboardConstants.GET_CAMPAIGNS_REQUEST:
      return { loading: true };
    case leaderboardConstants.GET_CAMPAIGNS_SUCCESS:
      return { loading: false, response: action.payload };
    case leaderboardConstants.GET_CAMPAIGNS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getArchivedLeaderboardCampaignsReducer = (state = {}, action) => {
  switch (action.type) {
    case leaderboardConstants.GET_ARCHIVED_CAMPAIGNS_REQUEST:
      return { loading: true };
    case leaderboardConstants.GET_ARCHIVED_CAMPAIGNS_SUCCESS:
      return { loading: false, response: action.payload };
    case leaderboardConstants.GET_ARCHIVED_CAMPAIGNS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
