import { useSelector } from 'react-redux';
import { getQueryParams } from '../../../../utils/query-helper';
import { useAnalytics } from '../../../../utils/hooks';

function NewsCard({ data }) {
  const { isdark } = useSelector((state) => state.theme);

  // const { analytics } = useAnalytics();

  const truncateText = (txt, length) => {
    if (txt.length <= length) {
      return txt;
    }
    return txt?.slice(0, length) + '....';
  };

  const linearGradient = isdark
    ? 'linear-gradient(#242731,rgba(0,0,0,0.6))'
    : 'linear-gradient(rgb(252, 252, 252), rgba(223, 216, 216, 0.7))';

  return (
    <div
      className='card__item news__card'
      style={{
        backgroundImage: `${linearGradient},url(${data?.urlToImage})`,
        backgroundPosition: 'center',
      }}
    >
      {/* <div className="new__image">
         <img
          src={data?.urlToImage}
          alt={data?.title}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 10,
            objectFit: 'center',
          }}
        /> 
      </div>*/}
      <div style={{ marginTop: '10px' }}>
        <a
          href={data?.url}
          target='_blank'
          rel='noreferrer'
          style={{ color: 'inherit' }}
          // onClick={() => {
          //   analytics?.track('News Clicked!', {
          //     title: data?.title,
          //     uid: userInfo?.payload?.uid,
          //     QueryParams: { ...getQueryParams() },
          //     ip: ip,
          //   });
          // }}
        >
          <div className='card__line'>
            <div className='card__category news_title'>{data?.title}</div>
          </div>
          <div className='card__status news_description'>
            {truncateText(data?.description, 142)}
          </div>
        </a>
      </div>
    </div>
  );
}

export default NewsCard;
