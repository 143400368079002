import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

function CustomSelect({
  defaultValue,
  isExchangePage = false,
  options,
  ...otherProps
}) {
  const { isdark } = useSelector((state) => state.theme);

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: state.isFocused && isdark ? 'transparent' : 'transparent',
      borderColor: state.isFocused ? '#6c5dd3' : 'transparent',
      cursor: 'pointer',
      borderRadius: 10,
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#8173E0' : isdark ? 'white' : 'black',
      // background: state.isSelected
      //   ? isdark
      //     ? 'rgba(128, 129, 145,0.1)'
      //     : '#efefef'
      //   : 'green',
      borderColor: 'transparent',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: 1,
      backgroundColor: state?.isDisabled
        ? undefined
        : state?.isSelected
        ? 'rgba(128, 129, 145,0.2)'
        : state?.isFocused
        ? isdark
          ? 'rgba(128, 129, 145,0.05)'
          : '#efefef'
        : undefined,
    }),
    container: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: !isdark ? 'rgba(128, 129, 145,0.1)' : '#191b20',
      borderRadius: 10,
      bordercolor: '#8173E0',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: isExchangePage ? '#808191' : !isdark ? '#000' : '#fff',
      fontSize: isExchangePage ? 10 : 13,
      paddingLeft: 10,
      fontWeight: 600,
      letterSpacing: isExchangePage ? 1 : 0,
      textTransform: isExchangePage ? 'uppercase' : 'revert',
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 125,
      background: isdark ? '#242731' : '#fff',
      fontSize: 12,
      borderRadius: 10,

      '::-webkit-scrollbar': {
        width: '2px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: isdark ? '#242731' : '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      // maxHeight: 125,
      background: isdark ? '#242731' : '#fff',
      // fontSize: 12,
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: 10,
        position: 'relative',
        height: 54,
        borderRadius: 10,
        backgroundColor: 'transparent',
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#808191',
        fontSize: 12,
      };
    },
  };

  return (
    <div style={{ fontFamily: 'Poppins' }}>
      <Select
        defaultValue={defaultValue}
        styles={customStyles}
        options={options}
        {...otherProps}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            primary: '#6c5dd3',
          },
        })}
      />
    </div>
  );
}

export default CustomSelect;
