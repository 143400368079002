import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast, LoadingBtn, IconBtn } from '../../../components';
import { addName, logout, userDetails } from '../../../actions/userAction';
import FormInput from '../form-input/forminput';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthButton from '../button/button';
import { country } from '../../../utils/country';
import { getAssests, getDepositDetails } from '../../../actions/assestsAction';
import {
  useAnalytics,
  useMeta,
  useSegment,
  useWalletMethods,
} from '../../../utils/hooks';
import { getQueryParams } from '../../../utils/query-helper';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

function AuthUsername({ history }) {
  const [userInfo, setUserInfo] = useState({
    name: '',
    // uniqueName: '',
    email: '',
    referralCode: '',
  });
  // const [nationality, setNationality] = useState(country[0].value);
  // const { analytics } = useAnalytics();
  // const [clickedVerfiy, setClickedVerfiy] = useState(false);
  // const [isCodeCopied, setIsCodeCopied] = useState(false);
  const { get0X0to0X } = useMeta();
  const { deactivate, account } = useWeb3React();
  const { walletDeactivate } = useWalletMethods();
  // const [privateKey, setPrivateKey] = useState(null);
  // const [stage, setStage] = useState(null);
  const { analytics, sendTrackEvent } = useSegment();
  // const {
  // userAddName,
  // userAddUniqueName,
  // userLogin,
  // userDetails: userDataState,
  // userKey: { state },
  // userIpAddress: { ip },
  // userVia: { state: userVia },
  // } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation();

  const { userInfo: userLoginInfo } = useSelector((state) => state.userLogin);
  const { userData } = useSelector((state) => state.userDetails);
  const { state } = useSelector((state) => state.userKey);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const {
    loading: userNameLoading,
    error: userNameError,
    response: userNameResponse,
  } = useSelector((state) => state.userAddName);

  const {
    loading: userUniqueNameLoading,
    error: userUniqueNameError,
    response: userUniqueNameResponse,
  } = useSelector((state) => state.userAddUniqueName);

  const removeReferralFromSearch = (searchLocation) => {
    const searchParams = new URLSearchParams(searchLocation);
    if (searchParams.has('referral_code')) {
      searchParams.delete('referral_code');
      return searchParams.toString();
    } else {
      return location.search;
    }
  };

  // const removeReferralCodeQuery = () => {
  //   console.log('removing');
  //   const queryParams = new URLSearchParams(location.search);
  //   if (queryParams.has('referral_code')) {
  //     queryParams.delete('referral_code');
  //     history.replace({
  //       search: queryParams.toString(),
  //     });
  //   }
  // };

  useEffect(() => {
    if (userNameError || userUniqueNameError) {
      // analytics?.track('User Profile Edit failed!', {
      //   from: 'profile page',
      //   uid: userInfo?.payload?.uid,
      //   reason: userNameError ? userNameError : userUniqueNameError,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      sendTrackEvent('profileCreationRequestFailure', {
        error: userNameError ? userNameError : userUniqueNameError,
      });
      return toast.error(userNameError || userUniqueNameError);
    }
    if (userNameResponse && userLoginInfo && userLoginInfo?.token) {
      // analytics?.track('User Profile Edit success!', {
      //   from: 'profile page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   userIpAddress: { ip },
      // });
      sendTrackEvent('profileCreationRequestSuccess', {
        error: userNameError ? userNameError : userUniqueNameError,
      });
      // removeReferralCodeQuery();
      dispatch(userDetails());
      history.push({
        pathname: '/',
        search: removeReferralFromSearch(location.search),
      });
    }
  }, [
    userLoginInfo,
    history,
    userUniqueNameError,
    userNameError,
    userNameResponse,
    userUniqueNameResponse,
  ]);

  const handleLogout = () => {
    // analytics?.track('User Login with other account clicked!', {
    //   from: 'profile page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   userIpAddress: { ip },
    // });

    sendTrackEvent('buttonClicked', {
      title: 'Login With Another Account ? Click Here',
      action: 'logout',
    });

    walletDeactivate();
    dispatch(logout(true));

    // analytics?.track('User Login with other account success!', {
    //   from: 'profile page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   userIpAddress: { ip },
    // });
    history.push({ pathname: '/signin', search: location.search });
  };

  const handleSubmit = () => {
    // analytics?.track('User Profile Edit button clicked!', {
    //   from: 'profile page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   userIpAddress: { ip },
    // });
    // const onlyAllowedUnderScoreRe = /^[a-zA-Z0-9_]+$/;
    // || userInfo.uniqueName === ''
    if (userInfo.name === '') {
      return toast.error('Please fill out ALL required fields.');
    }

    // if (!clickedVerfiy) {
    //   return toast.error(
    //     "Click the toggle button to verify you’re human."
    //   );
    // }

    // if (!onlyAllowedUnderScoreRe.test(userInfo.uniqueName.trim())) {
    //   return toast.error('Invalid characters in Unique Username field.');
    // }

    if (!userNameResponse || userNameError) {
      if (state) {
        // let stark_public_key = getKeyPairFromSignature(state)
        //   .getPublic()
        //   .getX();
        // const finalKey = ethers.BigNumber.from(
        //   stark_public_key.toString()
        // ).toHexString();
        sendTrackEvent('profileCreationRequestInitiated', {
          error: userNameError ? userNameError : userUniqueNameError,
        });

        dispatch(
          addName(
            userInfo.name,
            userInfo?.email != '' ? userInfo?.email : null,
            userInfo?.referralCode != '' ? userInfo?.referralCode : null
          )
        );
      } else {
        toast.error('Something went wrong; please try logging in again.');
        setTimeout(() => {
          walletDeactivate();
          dispatch(logout(true));
        }, 1000);
      }
    }
    // if (!userUniqueNameResponse || userUniqueNameError)
    //   dispatch(addUniqueUserName(userInfo.uniqueName));
  };

  useEffect(() => {
    if (userData?.payload?.name) {
      history.push({ pathname: '/', search: location.search });
    }
    dispatch(getAssests());
    dispatch(getDepositDetails());
  }, [userData]);

  useEffect(() => {
    if (!userData) {
      dispatch(userDetails());
    }
  }, []);

  // useEffect(() => {
  //   if (clickedVerfiy) {
  //     dispatch({
  //       type: USER_ADD_KEY_CLEANUP,
  //     });
  //     signMessageL1()
  //       .then((data) => {
  //         dispatch(storeKey(data));
  //         let stark_private_key = getKeyPairFromSignature(data).getPrivate();
  //         let stark_public_key = getKeyPairFromSignature(data).getPublic().getX();
  //         console.log(`Private Key: ${stark_private_key}`);
  //         console.log(`Public Key: ${stark_public_key}`);
  //         const finalKey = ethers.BigNumber.from(
  //           stark_public_key.toString()
  //         ).toHexString();
  //         setSignedKey(finalKey);
  //       })
  //       .catch((e) => {
  //         toast.error(e.message);
  //         setClickedVerfiy(false);
  //       });
  //   }
  // }, [clickedVerfiy]);

  // const handleCopyTheCode = () => {
  //   navigator.clipboard.writeText(privateKey);
  //   toast.success('Copied 👍🏻', { toastId: 'copy' });
  // };

  // useEffect(() => {
  //   if (userVia?.via === 'web3_auth') {
  //     setStage('show-seed');
  //     // let stark_private_key = getKeyPairFromSignature(state).getPrivate();
  //     let stark_public_key = getKeyPairFromSignature(state).getPublic().getX();
  //     // console.log(`Private Key: ${stark_private_key}`);
  //     // console.log(`Public Key: ${stark_public_key}`);
  //     setPrivateKey(stark_public_key);
  //   } else {
  //     setStage('hide-seed');
  //   }
  // }, [userVia, state]);

  // const handleHideSeedPhase = () => {
  //   if (!isCodeCopied) {
  //     // handleCopyTheCode();
  //     setIsCodeCopied(true);
  //     setStage('hide-seed');
  //   }
  // };

  useEffect(() => {
    if (userLoginInfo?.token) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('referral_code')) {
        // queryParams.delete('tab');
        // history.replace({
        //   search: queryParams.toString(),
        // });
        const referCode = queryParams.get('referral_code');
        setUserInfo({ ...userInfo, referralCode: referCode });
      }
    }
  }, [userLoginInfo?.token, location?.search]);

  return (
    <div className='login__col'>
      <Toast />
      <div className='login__form'>
        {/* {stage === 'show-seed' && privateKey ? (
          <>
            <div
              // className='operations__item'
              style={{
                position: 'relative',
                marginTop: 30,
                background: 'transparent',
                boxShadow: 'none',
              }}
            >
              <div
                className='login__stage h4'
                style={{
                  // fontSize: '22px',
                  marginBottom: 20,
                }}
              >
                Secret Key
              </div>
              <div
                className='operations__text'
                style={{
                  marginBottom: '15px',
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                }}
              >
                📝 Your secret recovery phrase enables access to your account in
                case of password loss. It will only be shown to you ONCE, so
                note it down, store it in a safe location and maintain a backup
                if needed. Your recovery phrase is the only way you can recover
                your account, so make sure you do not lose it.
                <br />
                <br />
                ⚠️ This secret key is for your eyes only, do not share or
                disclose this secret key with anyone ever. Anyone who has this
                key can access your entire wallet.
              </div>
              <div
                className='operations__code'
                style={{ fontFamily: 'Poppins', marginBottom: '15px' }}
              >
                <input
                  className='operations__input'
                  type='text'
                  disabled
                  defaultValue={privateKey}
                  style={{ fontFamily: 'Poppins' }}
                />
                <button
                  className='operations__copy'
                  onClick={handleCopyTheCode}
                >
                  <IconBtn className='icon' iconName='copy' />
                </button>
              </div>
            </div>
            <AuthButton
              title={'I have copied the secret key'}
              onClick={handleHideSeedPhase}
              style={{ marginTop: 20 }}
            />
          </>
        ) : ( */}
        {/* <> */}
        <div className='login__stage h4'>Pick your profile</div>
        <div>
          <FormInput
            label='Name'
            type='text'
            name='name'
            value={userInfo.name}
            onChange={(e) => handleChange(e)}
          />
          {/* <FormInput
            label='Unique userName'
            type='text'
            name='uniqueName'
            value={userInfo.uniqueName}
            onChange={(e) => handleChange(e)}
          /> */}
          <FormInput
            label='Email (optional)'
            type='email'
            showInformativeIcon={true}
            name='email'
            value={userInfo.email}
            onChange={(e) => handleChange(e)}
          />
          <FormInput
            label='Invite Code (optional)'
            type='text'
            // showInformativeIcon={true}
            name='referralCode'
            value={userInfo.referralCode}
            onChange={(e) => handleChange(e)}
          />
          {/* <div className="custom_checkbox">
            <input
              type="checkbox"
              checked={clickedVerfiy}
              onClick={(e) => setClickedVerfiy(e.target.checked)}
              style={{ pointerEvents: clickedVerfiy ? 'none' : 'all' }}
            />
            <p>Click the toggle button to verfiy you're a human</p>
          </div> */}
        </div>
        <div style={{ marginTop: 40 }}>
          {userNameLoading || userUniqueNameLoading ? (
            <LoadingBtn size='full' />
          ) : (
            <AuthButton title='Submit Details' onClick={handleSubmit} />
          )}
        </div>
        <div className='login__flex'>
          <div className='login__text'>Login With Another Account ? </div>{' '}
          <div
            className='login__link'
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
          >
            Click Here
          </div>
        </div>
        {/* </> */}
        {/* )} */}
      </div>
    </div>
  );
}

export default withRouter(AuthUsername);
