import { useWeb3React } from '@web3-react/core';
import {
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  ALLOWED_POLYGON_NETWORK_ID,
  ENVIRONMENT,
  ethNetworkName,
} from '../../../utils/constants';
import { useWalletMethods } from '../../../utils/hooks';
import Popup from '../popup/popup';
import Image from '../img/image';
import { images } from '../../../utils/image/img';
import CustomButton from '../custom-button/custom-button';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentNetworkState } from '../../../actions/userAction';

export default function NetworkChangePopup({
  openNetworkPrompt,
  setOpenNetworkPrompt,
  onSuccess,
  onDisconnect,
  isPolygon = false,
  symbol,
}) {
  const { connector } = useWeb3React();
  const { walletDeactivate, switchChain } = useWalletMethods();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state: currentNetworkData } = useSelector((s) => s.ethNetworkDetails);
  // console.log({ currentNetworkData });

  const handleSwitchNetwork = async (network) => {
    try {
      await switchChain(network);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  let description = {
    '/wallet': `Please change your network to ${
      ethNetworkName['ethereum'][ENVIRONMENT]
    } to deposit ${symbol?.toUpperCase()} as ${symbol?.toUpperCase()} deposits on ${
      ethNetworkName[currentNetworkData?.network][ENVIRONMENT]
    } are currently not supported.`,
    '/history': `Please switch your network to ${ethNetworkName['ethereum'][ENVIRONMENT]} to withdraw.`,
    // '/wallet': `You’ll need to switch your network to
    // ${
    //   ALLOWED_NETWORK_NAME === 'mainnet'
    //     ? isPolygon
    //       ? 'Polygon Mainnet'
    //       : ' Ethereum Mainnet'
    //     : isPolygon
    //     ? 'Polygon Mumbai'
    //     : ' Goerli Network'
    // }
    // to continue deposit ${symbol?.toUpperCase()}`,
  };

  return (
    <div className='share__popup network__error'>
      <Popup state={openNetworkPrompt} setState={setOpenNetworkPrompt}>
        <div className='h6' style={{ marginBottom: 38 }}>
          Yikes! You’re in the wrong lane.
        </div>
        <Image src={images.networkError} />

        {description[location.pathname] ? (
          <p style={{ fontSize: 12 }}>{description[location.pathname]}</p>
        ) : (
          <p style={{ fontSize: 12 }}>
            You’ll need to switch your network to
            {ALLOWED_NETWORK_NAME === 'mainnet'
              ? isPolygon
                ? 'Polygon Mainnet'
                : ' Ethereum Mainnet'
              : isPolygon
              ? 'Polygon Mumbai'
              : ' Goerli Network'}{' '}
            to continue using Brine{' '}
            {ALLOWED_NETWORK_NAME !== 'mainnet' ? 'Testnet' : ''}
          </p>
        )}

        <CustomButton
          title={`Switch to ${
            ALLOWED_NETWORK_NAME === 'mainnet'
              ? isPolygon
                ? 'Polygon Mainnet'
                : ' Ethereum Mainnet'
              : isPolygon
              ? 'Polygon Mumbai'
              : ' Goerli Network'
          } `}
          onClick={() => {
            handleSwitchNetwork(
              isPolygon ? ALLOWED_POLYGON_NETWORK_ID : ALLOWED_NETWORK_ID
            );
            setOpenNetworkPrompt((prev) => !prev);
          }}
        />
        {connector &&
          location.pathname !== '/history' &&
          location.pathname !== '/wallet' && (
            <CustomButton
              title='Disconnect Wallet'
              addClassName='disconnect_btn'
              onClick={() => {
                if (connector) {
                  walletDeactivate();
                }
                onDisconnect();
                walletDeactivate();
                setOpenNetworkPrompt(false);
              }}
            />
          )}
      </Popup>
    </div>
  );
}
