import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  useBalance,
  useViewport,
  useWebSocketData,
  useAnalytics,
  getMonthTimeStamp,
  getOneDayTimeStamp,
  getOneHourTimeStamp,
  getYearTimeStamp,
  useSegment,
} from '../../../utils/hooks';
import {
  Icon,
  SkeletonContainer,
  DetailsChart,
  ExchangeMarketTransactions,
  ExchangePriceChart,
  Popup,
  FormInput,
  CustomButton,
  TVChartContainer,
} from '../../../components';
import {
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { toast } from 'react-toastify';
import { getQueryParams } from '../../../utils/query-helper';
import { getChartData } from '../../../actions/assestsAction';
import ReactTooltip from 'react-tooltip';
import { images } from '../../../utils/image/img';
import { baseCurrency } from '../../../utils/constants';
import { dummyChartData } from '../../../utils/data';
import { isMobileBrowser } from '../../../utils/browser-checker';

function DetailsBox({
  img,
  title,
  subTitle,
  subTitleStatus,
  value,
  color,
  hasBalance,
  description,
}) {
  // const {
  //   theme: { isdark },
  // } = useSelector((state) => state);

  const { isdark } = useSelector((state) => state.theme);

  return (
    <div className='details__box' style={{ flex: 'revert' }}>
      <div className='details__icon '>
        <img
          src={img}
          alt=''
          className={`max-width-50 ${
            title === 'Current' ? 'scale-minus-1' : ''
          }`}
        />
      </div>
      <div className='details__inner'>
        <div className='details__category'>
          {title}
          <span data-tip={description} data-for={title}>
            <Icon icon='info-circle' className='icon' />
            <ReactTooltip
              place='bottom'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles price_details'
              id={title}
            />
          </span>
        </div>
        <div className={'details__price' + color && color}>{value}</div>
        {hasBalance && (
          <div className={subTitleStatus}>{subTitle ? subTitle : 0}</div>
        )}
      </div>
    </div>
  );
}

const NormalGraph = ({ selectedCell }) => {
  // const {
  //   navbar: { open },
  //   userIpAddress: { ip },
  //   userLogin: { userInfo },
  // } = useSelector((state) => state);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { symbol } = useParams();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [currentUsdtAmount, setCurrentUSDTAmount] = useState(0);
  const [investedData, setInvestedData] = useState(null);
  const { formatCurrency, getInvestedSingleCoin, roundUpWithSymbol, roundUp } =
    useBalance();
  const { getHigh, getLow, lastPrice, priceChangePercent, getStatus } =
    useWebSocketData();
  const dispatch = useDispatch();
  const [selectedTimeStamp, setSelectedTimestamp] = useState('1H');
  const [lastPriceInChart, setLastPriceInChart] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { sendTrackEvent } = useSegment();
  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);
  const { width, height } = useViewport();
  const exchangeInputRef = useRef();

  // const { analytics } = useAnalytics();
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const findUserHaveThisCoin = () => {
    //console.log('called');
    const isContains = balance?.payload?.find((item) => {
      //console.log(item.currency, symbol, 'vv');
      return item.currency == selectedCell?.symbol;
    });
    setInvestedData(isContains);
  };

  useEffect(() => {
    findUserHaveThisCoin();
  }, [selectedCell?.symbol, balance]);

  const { assetsData, loading } = useSelector((state) => state.getAssests);

  function percentage(partialValue, totalValue) {
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    //console.log(partialValue, totalValue, 'new');
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  const getCryptotoUSDC = () => {
    if (investedData) {
      const value =
        lastPrice(`${investedData.currency}${baseCurrency}`) *
        parseFloat(investedData.balance);
      return roundUpWithSymbol(value, baseCurrency);
    }
  };

  const {
    loading: chartLoading,
    error: chartError,
    chartData,
  } = useSelector((state) => state.getChartData);

  const { width: screenwidth } = useViewport();
  const title = `Buy, sell, trade and pay with cryptocurrency to anyone, anywhere, anytime. Brine is all the gear you'd need for your crypto-hunt. \n`;
  const url = window.location.href;
  const hashtags = '#Brine #cryptocurrency';
  const via = 'Brine';

  useEffect(() => {
    if (selectedCell?.symbol) {
      if (selectedTimeStamp == '1H') {
        dispatch(
          getChartData(
            symbol?.replace('-', ''),
            30,
            getOneHourTimeStamp().beforeDay,
            getOneHourTimeStamp().presentDay
          )
        );
      } else if (selectedTimeStamp == '1D') {
        dispatch(
          getChartData(
            symbol?.replace('-', ''),
            30,
            getOneDayTimeStamp().beforeDay,
            getOneDayTimeStamp().presentDay
          )
        );
      } else if (selectedTimeStamp == '1M') {
        dispatch(
          getChartData(
            symbol?.replace('-', ''),
            60,
            getMonthTimeStamp().beforeDay,
            getMonthTimeStamp().presentDay
          )
        );
      } else if (selectedTimeStamp == '1Y') {
        dispatch(
          getChartData(
            symbol?.replace('-', ''),
            60,
            getYearTimeStamp().beforeDay,
            getYearTimeStamp().presentDay
          )
        );
      }
    }
  }, [selectedTimeStamp, symbol, selectedCell]);

  useEffect(() => {
    if (setSelectedTimestamp != '1H') {
      setSelectedTimestamp('1H');
    }
  }, [symbol]);

  const shareCoins = () => {
    if (isMobileBrowser()) {
      if (navigator.share) {
        navigator
          .share({
            title: title,
          })
          .then(() => {
            // analytics?.track('Clicked share button', {
            //   from: 'Price details',
            //   uid: userInfo?.payload?.uid,
            //   symbol: !selectedCell ? selectedCell?.name : selectedCell?.name,
            //   QueryParams: { ...getQueryParams() },
            //   ip: ip,
            // });
            toast.success('Thanks for sharing 👍🏻');
          })
          .catch(console.error);
      } else {
        // fallback
        // analytics?.track('Clicked share button', {
        //   from: 'Price details',
        //   uid: userInfo?.payload?.uid,
        //   symbol: !selectedCell ? selectedCell?.symbol : selectedCell?.symbol,
        //   QueryParams: { ...getQueryParams() },
        //   ip: ip,
        // });
        setOpenPopUp(true);
      }
    } else {
      setOpenPopUp(true);
    }
  };

  const handleCopy = () => {
    if (exchangeInputRef?.current) {
      exchangeInputRef?.current?.select();
    }
    if (navigator.clipboard) {
      toast.success('Copied to clipboard, You can share the link');
      navigator.clipboard.writeText(window.location.href);
    } else {
      //console.log('In http connection ~ 🎯');
    }
  };

  useEffect(() => {
    if (investedData) {
      setInvestedAmount(
        getInvestedSingleCoin(investedData.currency, investedData.balance)
      );
      setCurrentUSDTAmount(getCryptotoUSDC());
    }
  }, [balance, assetsData, coinsDetails, selectedCell?.symbol, investedData]);

  const chartWidth = () => {
    if (screenwidth < 620) {
      return '100%';
    } else {
      return '95%';
    }
  };

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', function () {
  //     if (document.hidden) {
  //       const input = document.getElementById('copy-box');
  //       input?.blur();
  //       window.getSelection().removeAllRanges();
  //     }
  //   });
  // }, []);

  return (
    <div
      className='details__container exchange_chart_beginner'
      style={{ borderRadius: '32px 32px 0px 0px', padding: '32px 32px 0px' }}
    >
      <div className='details__top'>
        <div
          className={`details__company`}
          style={{ zIndex: '0', cursor: 'revert' }}
        >
          <div className='details__head'>
            <div className='details__desc'>
              <div className='details__info'>
                {!selectedCell ? selectedCell?.name : selectedCell?.name}
                <span style={{ color: '#808191', fontSize: 17 }}>
                  {' '}
                  ({selectedCell?.symbol?.toUpperCase()})
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='details__btns'>
          <button className='details__btn btn btn_border' onClick={shareCoins}>
            <Icon icon='share' className='details_icon icon' />
            <span className='btn__text'>Share</span>
          </button>
        </div>

        <div className='share__popup' style={{ position: 'relative' }}>
          <Popup setState={setOpenPopUp} state={openPopUp}>
            <div className='share__bg'></div>
            <div className='share__container'>
              <div className='share__coins'>
                <img
                  src={!selectedCell ? selectedCell?.logo : selectedCell?.logo}
                />
                <div className='h5'>Share it with your friends</div>
                <div className='h6'>
                  The price of <span>{selectedCell?.name}</span> is{' '}
                  {formatCurrency(
                    lastPrice(`${selectedCell?.symbol}${baseCurrency}`)
                  )}
                </div>
              </div>
              <div className='share__social_icon referral_share'>
                <TelegramShareButton
                  url={url}
                  title={title}
                  hashtag={hashtags}
                  windowHeight={height}
                  windowWidth={width}
                >
                  <TelegramIcon size={50} round />
                </TelegramShareButton>
                <WhatsappShareButton
                  url={url}
                  title={title}
                  windowHeight={height}
                  windowWidth={width}
                >
                  <WhatsappIcon size={50} round />
                </WhatsappShareButton>
                <TwitterShareButton
                  url={url}
                  title={title}
                  via='Brine'
                  windowHeight={height}
                  windowWidth={width}
                >
                  <TwitterIcon size={50} round />
                </TwitterShareButton>
              </div>
              <div className='h6 share__copy_link'>Or copy link</div>
              {/* <FormInput
                value={window.location.href}
                label='Click here to Copy'
                onFocus={handleCopy}
                id='copy-box'
                readOnly
              /> */}

              <div style={{ marginBottom: 10, position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    background: 'transparent',
                    zIndex: 1000,
                    borderRadius: 20,
                  }}
                  onClick={() => handleCopy(`${window.location.href}`)}
                ></div>
                {/* <FormInput
                value={`${window.location.href}/?referral_code=${userReferralCode}`}
                label='Click here to Copy'
                id='copy-box'
                readOnly
              /> */}
                <div class='field__wrap' style={{ position: 'relative' }}>
                  <div class='field__label' style={{ top: 22 }}>
                    Click here to Copy
                  </div>
                  <input
                    className='field__input js-field-input'
                    // id='copy-box'
                    readOnly={true}
                    value={`${window.location.href}`}
                    ref={exchangeInputRef}
                    // value='localhost:3000/?referral_code=OGDQPF'
                  />
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
      {selectedCell?.name !== 'Rupee' && (
        <div className='details__flex'>
          <div className='details__money'>
            {formatCurrency(lastPrice(`${selectedCell?.symbol}${baseCurrency}`))
              ? lastPriceInChart
                ? formatCurrency(Number(lastPriceInChart).toFixed(3))
                : formatCurrency(lastPrice(`${symbol?.replace('-', '')}`))
              : '--'}{' '}
            <span style={{ fontSize: 35 }}>
              {symbol.split('-')[1] == 'usdc' ? 'USDC' : 'USDT'}
            </span>
          </div>
          {/* <div
            className={`details__status ${
              getStatus(`${selectedCell?.symbol}usdt`) === 'positive'
                ? 'color-green'
                : 'color-red'
            } `}
          >
            {priceChangePercent(`${selectedCell?.symbol}usdt`)}
          </div>{' '} */}
        </div>
      )}

      <>
        {userInfo?.token ? (
          <>
            {(coinsLoading || loading) && !assetsData ? (
              <SkeletonContainer
                width={'100%'}
                height={'250px'}
                style={{ borderRadius: 40, marginBottom: 20 }}
                className='details__container'
              />
            ) : (
              <>
                {!chartLoading &&
                  !chartError &&
                  chartData &&
                  chartData.payload.length !== 0 &&
                  selectedCell?.symbol && (
                    <>
                      <div
                        style={{
                          width: chartWidth(),
                          padding: '20px',
                          marginBottom: 10,
                        }}
                        className='beginner_chart'
                      >
                        <DetailsChart
                          chartData={chartData}
                          color={
                            getStatus(`${symbol?.replace('-', '')}`) ===
                            'positive'
                              ? '#4fbf67'
                              : '#ff6628'
                          }
                          setLastPriceInChart={setLastPriceInChart}
                          isExchangePage={true}
                        />
                      </div>
                    </>
                  )}
              </>
            )}
          </>
        ) : (
          <div
            style={{
              height: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className='connect__wallet_chart'>
              <p>Connect your wallet to deposit and start trading.</p>
              <CustomButton
                title={'Connect Wallet'}
                onClick={() => {
                  history.push({
                    pathname: `/signin`,
                    search: location.search,
                  });
                  sendTrackEvent('buttonClicked', {
                    title: 'Connect Wallet',
                    action: 'Routing to Login Page',
                  });
                }}
              />
            </div>
          </div>
        )}
      </>

      <div
        style={{
          display: userInfo?.token ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 15,
          marginTop: 25,
        }}
      >
        {['1H', '1D', '1M', '1Y'].map((item, idx) => (
          <div
            className={`chart_options ${
              selectedTimeStamp == item ? 'active' : ''
            } `}
            onClick={() => setSelectedTimestamp(item)}
            key={idx}
          >
            <p>{item}</p>
          </div>
        ))}
      </div>
      {selectedCell?.symbol !== 'usdt' && (
        <div
          className='details__list exchange_list'
          style={{ margin: '0 -32px 0px', border: 'none' }}
        >
          {investedData &&
            !isNaN(investedAmount) &&
            !isNaN(currentUsdtAmount) && (
              <>
                <DetailsBox
                  img={images.invested}
                  hasBalance={true}
                  title='Invested'
                  subTitle={`Return ${
                    investedData.balance == 0.0 || currentUsdtAmount === '0'
                      ? `0%`
                      : percentage(investedAmount, currentUsdtAmount)
                  }`}
                  description={`Total investment made in this asset (In ${baseCurrency?.toUpperCase()})`}
                  subTitleStatus={`${
                    parseFloat(currentUsdtAmount) < parseFloat(investedAmount)
                      ? 'negative'
                      : 'positive'
                  }`}
                  value={formatCurrency(Number(investedAmount)?.toFixed(3))}
                />
                <DetailsBox
                  img={images.current}
                  title='Current'
                  description={`Present value of this asset held in your wallet (In ${baseCurrency?.toUpperCase()})`}
                  hasBalance={true}
                  subTitle={` Total Return ${
                    roundUp(currentUsdtAmount < investedAmount) ? ' ' : ' +'
                  }${roundUp(currentUsdtAmount - investedAmount)}`}
                  subTitleStatus={`${
                    parseFloat(currentUsdtAmount) < parseFloat(investedAmount)
                      ? 'negative'
                      : 'positive'
                  }`}
                  value={formatCurrency(Number(currentUsdtAmount)?.toFixed(3))}
                />
              </>
            )}
        </div>
      )}
    </div>
  );
};

function ExchangeMarket({
  selectedtab,
  selectedCell,
  params,
  tradeMode,
  setTradeMode,
  fetchOrderTable,
  setFetchOrderTable,
}) {
  const [selectedGraphType, setGraphType] = useState('Price Chart');
  const { width } = useViewport();
  const { isdark } = useSelector((state) => state.theme);
  const { symbol } = useParams();
  const breakpoint = 766;
  const history = useHistory();
  const location = useLocation();
  const { sendTrackEvent } = useSegment();
  // const graphtype = [
  //   {
  //     name: 'Price Chart',
  //   },
  //   {
  //     name: 'Deep Chart',
  //   },
  // ];

  const renderGraph = () => {
    // if (selectedGraphType === 'Price Chart') {
    return (
      <div className='market__item js-tabs-item' style={{ display: 'block' }}>
        <div className='market__legend'>
          {/* <div className="market__variant">
            {selectedCell?.symbol?.toUpperCase()}/
            {symbol?.split('-')[1].toUpperCase()}
          </div> */}
          {/* {['O', 'H', 'L', 'C'].map((item, idx) => (
              <div className="market__variant" key={idx}>
                {item} <span className="negative">18432.3204898</span>
              </div>
            ))} */}
        </div>
        {/* {userInfo?.token ? ( */}
        <div
          className='market__chart market__chart_price'
          style={{ display: 'block' }}
        >
          {selectedCell?.symbol ? (
            // <ExchangePriceChart
            //   // symbol={`${selectedCell?.symbol?.toUpperCase()}BUSD`}
            //   symbol={`${symbol?.toLowerCase()?.replace('-', '')}`}
            //   themeColor={isdark ? 'dark' : 'light'}
            //   width={width}
            //   params={params}
            // />
            <TVChartContainer
              symbol={`${symbol?.toLowerCase()?.replace('-', '')}`}
              themeColor={isdark ? 'dark' : 'light'}
            />
          ) : (
            <SkeletonContainer className='trade_view__chart' />
          )}
        </div>
        {/* ) : (
          <div style={{ position: 'relative', opacity: 0.99 }}>
            <div className='connect__wallet_chart absolute-center'>
              <p>Connect your wallet to deposit and start trading.</p>
              <CustomButton
                title={'Connect Wallet'}
                onClick={() => {
                  history.push({
                    pathname: `/signin`,
                    search: location.search,
                  });
                  sendTrackEvent('buttonClicked', {
                    title: 'Connect Wallet',
                    action: 'Routing to Login Page',
                  });
                }}
              />
            </div>
            <div
              className='market__chart market__chart_price chart_bg'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            ></div>
          </div>
        )} */}
      </div>
    );
    //   } else {
    //     return (
    //       <div className="market__item js-tabs-item" style={{ display: 'block' }}>
    //         <div className="market__legend">
    //           <div className="market__variant">BTC/USDT</div>
    //           {['O', 'H', 'L', 'C'].map((item, idx) => (
    //             <div className="market__variant" key={idx}>
    //               {item} <span className="positive">18432.3204898</span>
    //             </div>
    //           ))}
    //         </div>
    //         <div
    //           className="market__chart market__chart_deep"
    //           style={{ display: 'block' }}
    //         >
    //           <ExchangeDeepChart />
    //         </div>
    //       </div>
    //     );
    //   }
  };
  return (
    <div className='market'>
      <div
        className={`market__tabs  js-tabs`}
        style={{
          display:
            selectedtab === 'market__tabs' && width < breakpoint
              ? 'block'
              : 'none',
        }}
      >
        {/*<div className="market__head">
          <div className="market__menu">
            {graphtype.map((item, idx) => (
              <button
                key={idx}
                className={`market__link js-tabs-link ${
                  selectedGraphType === item.name ? 'active' : ''
                } `}
                onClick={() => setGraphType(item.name)}
              >
                {item.name}
              </button>
            ))}
          </div> */}
        {/* <div className="market__group">
            <div className="market__actions">
              <a className="market__action" href="/">
                <Icon icon="time-clock" className="icon" />
                <span>30m</span>
              </a>
              <a className="market__action" href="/">
                <Icon icon="ranking" className="icon" />
                <span>Indicator</span>
              </a>
              <a className="market__action" href="/">
                <Icon icon="arrow-up-right-square" className="icon" />
              </a>
            </div>
          </div> 
        </div>*/}
        <div className='market__body'>
          <div className='market__container'>
            {tradeMode ? (
              renderGraph()
            ) : (
              <NormalGraph selectedCell={selectedCell} />
            )}
          </div>
        </div>
      </div>
      <ExchangeMarketTransactions
        selectedtab={selectedtab}
        selectedCell={selectedCell}
        tradeMode={tradeMode}
        setTradeMode={setTradeMode}
        fetchOrderTable={fetchOrderTable}
        setFetchOrderTable={setFetchOrderTable}
      />
    </div>
  );
}

export default ExchangeMarket;
