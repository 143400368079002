import { useHistory, useLocation } from 'react-router';
import { images } from '../../../utils/image/img';

function ExchangeBanner({ date, btnValue, title, link, ...otherbtnProps }) {
  const history = useHistory();
  const location = useLocation();
  return (
    <div
      className='exchange__banner'
      style={{ backgroundImage: `url(${images.banner})` }}
    >
      <div className='exchange__date'></div>
      <div className='exchange__title h4'>{title}</div>
      <button
        className='exchange__btn btn btn_white btn_sm'
        {...otherbtnProps}
        onClick={() =>
          history.push({
            pathname: link,
            search: location.search,
          })
        }
      >
        {btnValue}
      </button>
    </div>
  );
}

export default ExchangeBanner;
