import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import sendAnimationData from '../../../assests/json/send.json';
import CustomButton from '../custom-button/custom-button';
import Popup from '../popup/popup';
import doneAnimationData from '../../../assests/json/done.json';
import { toast } from 'react-toastify';
import { sendFeedback } from '../../../actions/commonAction';
import { useSegment, useViewport } from '../../../utils/hooks';
import Icon from '../icons/icons';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

function InputContainer({ setSentSuccessFully, text, setText }) {
  const [email, setEmail] = useState('');
  const { sendTrackEvent } = useSegment();
  const { isdark } = useSelector((s) => s.theme);

  const handleSubmit = async () => {
    if (text.length == 0) {
      return toast.error('Share your feedback here!');
    }

    if (
      email.length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      return toast.error('Please enter a valid email.');
    }

    const feedback = email !== '' ? text + ' - ' + email : text;
    try {
      sendTrackEvent('feedbackRequestInitiated');
      const data = await sendFeedback(feedback);
      setSentSuccessFully(true);
      sendTrackEvent('feedbackRequestSuccess');
    } catch (error) {
      toast.error('Please try again in a while!');
      sendTrackEvent('feedbackRequestFailure', { error });
    }
  };
  return (
    <div className='feedback_popup_container'>
      <h2>Send Us Feedback</h2>
      <div style={{ marginTop: 25 }}>
        <label style={{ color: '#808191' }}>
          Email (optional)
          <span
            className='feedback__email_icon'
            data-tip={
              'This data is being collected purely for communication regarding product feedback; it will not be stored or used for other purposes.'
            }
            data-for={'feedback__email_icon'}
          >
            <Icon icon='info-circle' className='icon' />
            <ReactTooltip
              place='bottom'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles price_details feedback__email_details'
              id={'feedback__email_icon'}
            />
          </span>
        </label>
        <input
          type='email'
          name='email'
          placeholder='user@gmail.com'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <label style={{ color: '#808191' }}>Tell us how we can improve!</label>
        <textarea
          label='Tell us how we can improve!'
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div
          style={{
            display: 'block',
            display: 'grid',
            placeItems: 'center',
            marginTop: 10,
          }}
        >
          <CustomButton
            title='Send'
            onClick={handleSubmit}
            style={{ width: '90%' }}
          />
        </div>
      </div>
    </div>
  );
}

function SuccessAnimation({ doneAnimationOptions }) {
  return (
    <div>
      <Lottie options={doneAnimationOptions} height={200} width={200} />
      <p style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
        Sent successfully!
        <br />
        Thank you! 🙏 Your feedback is appreciated.
      </p>
    </div>
  );
}

function Feedback({ openFeedBackPopup, setOpenFeedBackPopup }) {
  const [sentSuccessFully, setSentSuccessFully] = useState(false);
  const [feedBackText, setFeedBackText] = useState('');
  const [collapseFeedBack, setCollapseFeedBack] = useState(false);
  const { sendTrackEvent } = useSegment();
  const clearState = () => {
    setSentSuccessFully(false);
    setFeedBackText('');
  };
  const { width } = useViewport();
  const smallSendIconAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: sendAnimationData,
  };
  const doneAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: doneAnimationData,
  };

  useEffect(() => {
    const collapse_icons = localStorage.getItem('collapse_icons') || null;
    if (collapse_icons == 'true') {
      setCollapseFeedBack(true);
    }
  }, []);

  return (
    <>
      <div className='feedback-container'>
        <div
          className='feedback_collaspe_icon'
          style={{ position: 'absolute', top: -5, left: -3 }}
          onClick={() => {
            localStorage.setItem('collapse_icons', !collapseFeedBack);
            setCollapseFeedBack((prev) => !prev);
          }}
        >
          {width > 750 && (
            <>
              {collapseFeedBack ? (
                <i className='fa fa-chevron-circle-left' aria-hidden='true'></i>
              ) : (
                <i
                  className='fa fa-chevron-circle-right'
                  aria-hidden='true'
                ></i>
              )}
            </>
          )}
        </div>
        <div onClick={() => setOpenFeedBackPopup((prev) => !prev)}>
          <Lottie
            options={smallSendIconAnimationOptions}
            height={35}
            width={35}
          />
        </div>
        <div
          className={`send-us-feedback ${
            collapseFeedBack || width < 750 ? 'close' : ''
          } `}
          onClick={() => {
            sendTrackEvent('buttonClicked', {
              title: ' Send us feedback',
              action: 'Opening feedback instruction popup',
            });
            setOpenFeedBackPopup((prev) => !prev);
          }}
        >
          Send us feedback
        </div>
      </div>
      <div className='share__popup feedback__popup'>
        <Popup
          state={openFeedBackPopup}
          setState={setOpenFeedBackPopup}
          clearState={clearState}
        >
          {!sentSuccessFully ? (
            <InputContainer
              setSentSuccessFully={setSentSuccessFully}
              setText={setFeedBackText}
              text={feedBackText}
            />
          ) : (
            <SuccessAnimation
              doneAnimationOptions={doneAnimationOptions}
              setSentSuccessFully={setSentSuccessFully}
              sentSuccessFully={sentSuccessFully}
            />
          )}
        </Popup>
      </div>
    </>
  );
}

export default Feedback;
