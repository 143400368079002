import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNews } from '../../../actions/assestsAction';
import { SkeletonContainer, NewsCard } from '../../../components';

function PriceNews({ term }) {
  const {
    news: newsPayload,
    loading,
    error,
  } = useSelector((state) => state.news);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNews(term));
  }, [term]);

  return (
    <div className='card'>
      <div className='card__head'>
        <div className='card__title h6'>News</div>
      </div>
      <div className='card__list'>
        {/* {error && <div className='h4 error-message'>Something went wrong</div>} */}
        {loading
          ? [1, 2, 3, 4, 5].map((item, idx) => (
              <SkeletonContainer
                key={idx}
                className='card__item news__card'
                height='70px'
                style={{ marginBottom: '20px', borderRadius: '10px' }}
              />
            ))
          : newsPayload &&
            newsPayload?.payload
              ?.slice(0, 5)
              .map((item, idx) => <NewsCard key={idx} data={item} />)}
      </div>
    </div>
  );
}

export default PriceNews;
