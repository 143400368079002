import React from 'react';
import { useSelector } from 'react-redux';
import { useNotification } from '../../../utils/hooks';

function ActivitiesTags({
  setSelectedFilter,
  selectedFilter,
  options,
  addTagclassName,
  addLinksclassName,
  ...otherProps
}) {
  const { getWithdrawalCount } = useNotification();

  return (
    <div className={addTagclassName}>
      {options.map((data, idx) => (
        <>
          <button
            className={`${addLinksclassName} ${
              selectedFilter.label === data.label ? 'active' : ''
            }`}
            {...otherProps}
            key={idx}
            onClick={() => setSelectedFilter(data)}
          >
            {data.label}
            {data?.value === 'Withdraws' && getWithdrawalCount() && (
              <span
                style={{
                  background: '#6c5dd3',
                  padding: '3px 7px',
                  borderRadius: '20px',
                  fontSize: 10,
                  color: '#fff',
                  marginLeft: 10,
                }}
              >
                {getWithdrawalCount()}
              </span>
            )}
          </button>{' '}
        </>
      ))}
    </div>
  );
}

export default ActivitiesTags;
