import React from 'react';
import { CustomButton, Popup } from '../../../components';
import { useLocation } from 'react-router-dom';

export function CancelConfirmationPopup({
  openCancelPopup,
  setOpenCancelPopup,
  setCancelId,
  cancelId,
  handleCancelOrder,
}) {
  const { pathname } = useLocation();
  return (
    <Popup
      state={openCancelPopup}
      setState={setOpenCancelPopup}
      clearState={() => setCancelId(null)}
    >
      <div
        style={{
          marginTop: 20,
          fontFamily: 'Poppins',
          paddingLeft: 10,
        }}
      >
        <>
          <p
            style={{
              textAlign: 'center',
              // marginTop: 10,
              marginBottom: 10,
            }}
          >
            Are you sure to cancel this order ?
          </p>
          <div
            style={{
              marginTop: 22,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CustomButton
              addClassName={
                pathname.includes('history') ? null : 'btn_red mute_hover'
              }
              title='Cancel'
              onClick={() => {
                if (cancelId) {
                  handleCancelOrder(cancelId);
                  setOpenCancelPopup(false);
                }
              }}
            />
            <button
              className='card__btn btn btn_border'
              style={{
                marginLeft: 12,
              }}
              onClick={() => setOpenCancelPopup((pre) => !pre)}
            >
              <span className='btn__text'>Close</span>
            </button>
          </div>
        </>
      </div>
    </Popup>
  );
}

export default CancelConfirmationPopup;
