import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getQueryParams } from '../../../utils/query-helper';
import { useAnalytics } from '../../../utils/hooks';
import { Icon } from '../../../components';

function ExchangeFooter({
  selectedtab,
  setSelectedTab,
  tradeMode,
  setTradeMode,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  // const { analytics } = useAnalytics();
  const footerMenu = [
    {
      tab: 'market__tabs',
      icon: 'chart',
      name: 'Buy & Sell',
    },
    {
      tab: 'market__transactions',
      icon: 'document-plus',
      name: 'Orders',
    },
    {
      tab: 'orders',
      icon: 'swap',
      name: 'Order book',
    },
  ];

  // useEffect(() => {
  //   analytics?.page(
  //     `User clicked ${selectedtab} in bottom navigation in mobile view`,
  //     {
  //       uid: userInfo?.payload?.uid,
  //       from: 'Exchange Page',
  //       QueryParams: { ...getQueryParams() },
  //       ip: ip,
  //     }
  //   );
  // }, [analytics, selectedtab]);

  return (
    <div className='exchange__foot'>
      <div className='exchange__menu'>
        {footerMenu.map((item, idx) => {
          if (!tradeMode && item.name == 'Order book') return;
          if (!userInfo?.token && item.name == 'Orders') return;

          return (
            <button
              key={idx}
              className={`exchange__link ${
                selectedtab === item.tab ? 'note' : ''
              } ${item.tab === selectedtab ? 'active' : ''} `}
              onClick={() => setSelectedTab(item.tab)}
            >
              <Icon
                icon={item.icon}
                className='icon'
                color={
                  item.tab === selectedtab ? '#6c5dd3' : 'rgb(128, 129, 145)'
                }
              />
              <p
                style={{
                  fontSize: 10,
                  padding: 5,
                  color: item.tab === selectedtab ? '#6c5dd3' : '#808191',
                }}
              >
                {item.name}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default ExchangeFooter;
