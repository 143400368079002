import { useRef, useState } from 'react';
import {
  Icon,
  CustomFilterDropDown,
  NotFoundedAnimation,
  LatestActivitiesList,
} from '../../../components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useBalance,
  listenForOutsideClicks,
  useSegment,
} from '../../../utils/hooks';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { images } from '../../../utils/image/img';

const dropDownOptions = [
  { name: 'Deposit' },
  { name: 'Normal Withdrawal' },
  { name: 'Fast Withdrawal' },
];

function LatestActivity({ selectedOption }) {
  const [depositData, setDepositData] = useState(null);
  const [withdrawsData, setWithdrawsData] = useState(null);
  const [fastWithdrawsData, setFastWithdrawsData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('Deposit');
  const [opendropDown, setOpenDropDown] = useState(false);
  const [listening, setListening] = useState(false);
  const withdrawDropdownRef = useRef(null);
  const { preventScientific } = useBalance();
  const { analytics, sendTrackEvent } = useSegment();
  const {
    dexDepositData,
    withDrawData,
    fastWithDrawData,
    userVia: { state: userVia },
  } = useSelector((state) => state);
  const { getInfoAboutCoinsByTokenId, getInfoAboutCoins, roundUpWithSymbol } =
    useBalance();

  const handleClose = () => {
    setOpenDropDown(false);
  };

  useEffect(
    listenForOutsideClicks(
      listening,
      setListening,
      withdrawDropdownRef,
      handleClose
    )
  );

  const {
    loading: depositLoading,
    error: depositError,
    response: depositResponse,
  } = dexDepositData;

  const {
    loading: withDrawLoading,
    error: withDrawError,
    response: withDrawResponse,
  } = withDrawData;

  const {
    loading: fastWithDrawLoading,
    error: fastWithDrawError,
    response: fastWithDrawResponse,
  } = fastWithDrawData;

  let dataLimit = 2;

  useEffect(() => {
    if (depositResponse) {
      const data = depositResponse?.payload?.results?.slice(0, dataLimit);
      setDepositData(data);
    }
  }, [depositResponse]);

  useEffect(() => {
    if (withDrawResponse) {
      const data = withDrawResponse?.payload?.results?.slice(0, dataLimit);
      setWithdrawsData(data);
    }
  }, [withDrawResponse]);

  useEffect(() => {
    if (fastWithDrawResponse) {
      const data = fastWithDrawResponse?.payload?.results?.slice(0, dataLimit);
      setFastWithdrawsData(data);
    }
  }, [fastWithDrawResponse]);

  const getCountDown = (time) => {
    let date1 = new Date(time);
    let date2 = new Date();
    let hoursDiff = Math.abs(date1 - date2) / 36e5;
    return { hoursDiff: Math.trunc(hoursDiff), date1, date2 };
  };

  useEffect(() => {
    if (selectedOption?.name) {
      setSelectedFilter(selectedOption?.name);
      sendTrackEvent('latestActivityDropdownSelected', {
        selected: selectedOption?.name,
      });
    }
  }, [selectedOption?.name]);

  return (
    <>
      {selectedFilter === 'Deposit' ? (
        <>
          <div className='card'>
            <div className='card__head' style={{ marginBottom: 11 }}>
              <div className='card__title h6'>Latest Deposits</div>
              <div ref={withdrawDropdownRef}>
                <CustomFilterDropDown
                  selectedOption={selectedFilter}
                  setSelectedOptions={setSelectedFilter}
                  openState={opendropDown}
                  setOpenState={setOpenDropDown}
                  options={dropDownOptions}
                />
              </div>
            </div>
            {depositResponse?.payload?.results.length === 0 || depositError ? (
              <NotFoundedAnimation text='No activity found' size='x-sm' />
            ) : (
              <>
                <div className='card__list'>
                  {depositData?.map((data, idx) => {
                    const propData = {
                      bg: images.money,
                      image: getInfoAboutCoinsByTokenId(data?.token_id)?.logo,
                      category: `Deposited ${getInfoAboutCoinsByTokenId(
                        data?.token_id
                      )?.symbol?.toUpperCase()}`,
                      date: moment(data?.created_at).format('LL'),
                      status:
                        data?.blockchain_deposit_status == 'Failed' ||
                        data?.brine_deposit_status == 'Failed' ||
                        data?.stark_deposit_status == 'Failed'
                          ? 'Failed'
                          : data?.blockchain_deposit_status == 'Pending' ||
                            data?.brine_deposit_status == 'Pending' ||
                            data?.stark_deposit_status == 'Pending'
                          ? 'Pending'
                          : 'Success',
                      price:
                        preventScientific(
                          data?.amount /
                            Math.pow(
                              10,
                              getInfoAboutCoinsByTokenId(data?.token_id)
                                ?.quanitization
                            )
                        ) +
                        ' ' +
                        getInfoAboutCoinsByTokenId(
                          data?.token_id
                        )?.symbol?.toUpperCase(),
                      hash: data?.deposit_blockchain_hash,
                    };
                    return (
                      <LatestActivitiesList
                        isdeposit={true}
                        data={propData}
                        key={idx}
                      />
                    );
                  })}
                </div>
                <Link
                  className='card__view'
                  to={{
                    pathname: '/history',
                    search: '?tab=deposits',
                  }}
                >
                  <div className='card__arrow'>
                    <Icon icon='arrow-right' className='icon' />
                  </div>
                  View all activity
                </Link>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='card'>
            <div className='card__head' style={{ marginBottom: 11 }}>
              <div className='card__title h6'>
                Latest {selectedFilter}s
                {/* <div style={{ fontSize: 12, color: '#808191' }}>
                      ({selectedFilter})
                    </div> */}
              </div>
              <div ref={withdrawDropdownRef}>
                <CustomFilterDropDown
                  selectedOption={selectedFilter}
                  setSelectedOptions={setSelectedFilter}
                  openState={opendropDown}
                  setOpenState={setOpenDropDown}
                  options={dropDownOptions}
                />
              </div>
            </div>
            {selectedFilter === 'Normal Withdrawal' ? (
              <>
                {withdrawsData?.length == 0 ? (
                  <NotFoundedAnimation text='No activity found' size='x-sm' />
                ) : (
                  <>
                    {withDrawResponse?.payload?.results.length !== 0 &&
                      !withDrawError && (
                        <>
                          <div className='card__list'>
                            {withdrawsData &&
                              withdrawsData?.map((data, idx) => {
                                const propData = {
                                  bg: images.money,
                                  image: getInfoAboutCoins(data?.token_id)
                                    ?.logo,
                                  category: `Withdrawn ${getInfoAboutCoins(
                                    data?.token_id
                                  )?.symbol?.toUpperCase()}`,
                                  date: moment(data?.created_at).format('LL'),
                                  status:
                                    getCountDown(data?.created_at).hoursDiff >=
                                      6 &&
                                    data.transaction_status === 'CONFIRMING'
                                      ? 'READY'
                                      : data.transaction_status?.toUpperCase(),
                                  price:
                                    preventScientific(
                                      roundUpWithSymbol(
                                        data?.amount,
                                        data?.token_id
                                      )
                                    ) +
                                    ' ' +
                                    getInfoAboutCoins(
                                      data?.token_id
                                    )?.symbol?.toUpperCase(),
                                  hash: data?.deposit_blockchain_hash,
                                  id: data.id,
                                };
                                return (
                                  <LatestActivitiesList
                                    isdeposit={false}
                                    data={propData}
                                    key={idx}
                                  />
                                );
                              })}
                          </div>
                          <Link
                            className='card__view'
                            to={(location) => ({
                              pathname: '/history',
                              search: '?tab=withdrawals',
                            })}
                          >
                            <div className='card__arrow'>
                              <Icon icon='arrow-right' className='icon' />
                            </div>
                            View all activity
                          </Link>
                        </>
                      )}
                  </>
                )}
              </>
            ) : (
              <>
                {fastWithdrawsData?.length == 0 ? (
                  <NotFoundedAnimation text='No activity found' size='x-sm' />
                ) : (
                  <>
                    {fastWithDrawResponse?.payload?.results.length !== 0 &&
                      !fastWithDrawError && (
                        <>
                          <div className='card__list'>
                            {fastWithdrawsData &&
                              fastWithdrawsData?.map((data, idx) => {
                                const propData = {
                                  bg: images.money,
                                  image: getInfoAboutCoins(data?.token_id)
                                    ?.logo,
                                  category: `Withdrawn ${getInfoAboutCoins(
                                    data?.token_id
                                  )?.symbol?.toUpperCase()}`,
                                  date: moment(data?.created_at).format('LL'),
                                  status:
                                    data.blockchain_status === 'SUCCESS' &&
                                    data.transaction_status ===
                                      'TRANSFER_INITIATED'
                                      ? 'SUCCESS'
                                      : data.transaction_status?.toUpperCase(),
                                  price:
                                    roundUpWithSymbol(
                                      data?.amount,
                                      data?.token_id
                                    ) +
                                    ' ' +
                                    getInfoAboutCoins(
                                      data?.token_id
                                    )?.symbol?.toUpperCase(),
                                  hash: data?.deposit_blockchain_hash,
                                  id: data.id,
                                };
                                return (
                                  <LatestActivitiesList
                                    isdeposit={false}
                                    isfastWithdrawal={true}
                                    data={propData}
                                    key={idx}
                                  />
                                );
                              })}
                          </div>
                          <Link
                            className='card__view'
                            to={(location) => ({
                              pathname: '/history',
                              search: '?tab=fast-withdrawals',
                            })}
                          >
                            <div className='card__arrow'>
                              <Icon icon='arrow-right' className='icon' />
                            </div>
                            View all activity
                          </Link>
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default LatestActivity;
