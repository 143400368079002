import { withRouter } from 'react-router';
import {
  MetaLogin,
  WalletConnectLogin,
  WalletLinkLogin,
  // Web3AuthLogin,
  WalletConnectLoginV2,
} from '../../../components';

function SignIn({
  history,
  setSelectedOption,
  loginState,
  setLoginState,
  selectedOption,
}) {
  const commonProps = {
    history,
    setSelectedOption,
    loginState,
    setLoginState,
    selectedOption,
  };

  return (
    <>
      {selectedOption === 'Metamask' && <MetaLogin {...commonProps} />}
      {selectedOption === 'Coinbase Wallet' && (
        <WalletLinkLogin {...commonProps} />
      )}
      {selectedOption === 'WalletConnect' && (
        <WalletConnectLogin {...commonProps} />
      )}
      {selectedOption === 'WalletConnect V2' && (
        <WalletConnectLoginV2 {...commonProps} />
      )}
      {/* {selectedOption === 'Social Login' && <Web3AuthLogin {...commonProps} />} */}
    </>
  );
}

export default withRouter(SignIn);
