import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useViewport,
  useWebSocketData,
  useBalance,
  useAnalytics,
  getMonthTimeStamp,
  getOneDayTimeStamp,
  getOneHourTimeStamp,
  getYearTimeStamp,
  useSegment,
} from '../../../utils/hooks';
import {
  SkeletonContainer,
  DetailsButton,
  DetailsAbout,
  DetailsBox,
  DetailsChart,
  DetailsTop,
} from '../../../components';
import { useHistory, useLocation } from 'react-router';
import { getQueryParams } from '../../../utils/query-helper';
import { useDispatch } from 'react-redux';
import { getChartData } from '../../../actions/assestsAction';
import { images } from '../../../utils/image/img';
import { baseCurrency } from '../../../utils/constants';

function DetailsContainer({
  coinDetails,
  selectedCell,
  setSelectedCell,
  symbol,
}) {
  const { width: screenwidth } = useViewport();
  const { getHigh, getLow, lastPrice, priceChangePercent, getStatus } =
    useWebSocketData();
  const [investedData, setInvestedData] = useState(null);
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [investedAmount, setInvestedAmount] = useState(0);
  const [currentUsdtAmount, setCurrentUSDTAmount] = useState(0);
  const [selectedTimeStamp, setSelectedTimestamp] = useState('1H');
  const history = useHistory();
  const dispatch = useDispatch();
  const [lastPriceInChart, setLastPriceInChart] = useState(null);
  const { getSocketFunctionalityInfo, formatMarketPair } = useWebSocketData();
  const { sendPageEvent, analytics, sendTrackEvent } = useSegment();
  const { open } = useSelector((state) => state.navbar);

  const {
    loading: chartLoading,
    error: chartError,
    chartData,
  } = useSelector((state) => state.getChartData);
  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);

  const { formatCurrency, getInvestedSingleCoin, roundUpWithSymbol, roundUp } =
    useBalance();

  // const { analytics } = useAnalytics();
  const location = useLocation();

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { assetsData } = useSelector((state) => state.getAssests);

  const chartWidth = () => {
    if (screenwidth < 620) {
      return '100%';
    } else {
      if (!open) {
        return '720px';
      } else {
        return '550px';
      }
    }
  };

  useEffect(() => {
    if (analytics) {
      sendPageEvent('priceDetailsPageVisited');
    }
  }, [analytics]);

  useEffect(() => {
    if (analytics) {
      sendTrackEvent('timestampChanged', { period: selectedTimeStamp });
    }
  }, [analytics, selectedTimeStamp]);

  useEffect(() => {
    if (analytics) {
      sendTrackEvent('coinChanged', { coin: selectedCell?.name });
    }
  }, [analytics, selectedCell]);

  useEffect(() => {
    if (selectedCell) {
      if (selectedTimeStamp == '1H') {
        dispatch(
          getChartData(
            `${symbol}${baseCurrency}`,
            30,
            getOneHourTimeStamp().beforeDay,
            getOneHourTimeStamp().presentDay
          )
        );
      } else if (selectedTimeStamp == '1D') {
        dispatch(
          getChartData(
            `${symbol}${baseCurrency}`,
            30,
            getOneDayTimeStamp().beforeDay,
            getOneDayTimeStamp().presentDay
          )
        );
      } else if (selectedTimeStamp == '1M') {
        dispatch(
          getChartData(
            `${symbol}${baseCurrency}`,
            60,
            getMonthTimeStamp().beforeDay,
            getMonthTimeStamp().presentDay
          )
        );
      } else if (selectedTimeStamp == '1Y') {
        dispatch(
          getChartData(
            `${symbol}${baseCurrency}`,
            60,
            getYearTimeStamp().beforeDay,
            getYearTimeStamp().presentDay
          )
        );
      }
    }
  }, [selectedTimeStamp, selectedCell]);

  const findUserHaveThisCoin = () => {
    //console.log('called');
    const isContains = balance?.payload?.find((item) => {
      //console.log(item.currency, symbol, 'vv');
      return item.currency == symbol;
    });
    setInvestedData(isContains);
  };

  useEffect(() => {
    findUserHaveThisCoin();
  }, [symbol, balance]);

  const getCryptotoUSDT = () => {
    if (investedData) {
      const value =
        lastPrice(`${investedData.currency}${baseCurrency}`) *
        parseFloat(investedData.balance);
      return roundUpWithSymbol(value, `${baseCurrency}`);
    }
  };

  function percentage(partialValue, totalValue) {
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    //console.log(partialValue, totalValue, 'new');
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  useEffect(() => {
    if (searchText.length === 0) {
      setResult(coinsDetails?.convertedArray);
    } else {
      const data = coinsDetails.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setResult(data);
    }
  }, [searchText, coinsDetails?.convertedArray]);

  useEffect(() => {
    if (investedData) {
      setInvestedAmount(
        getInvestedSingleCoin(investedData.currency, investedData.balance)
      );
      setCurrentUSDTAmount(getCryptotoUSDT());
    }
  }, [balance, assetsData, coinsDetails, symbol, investedData]);

  useEffect(() => {
    if (setSelectedTimestamp != '1H') {
      setSelectedTimestamp('1H');
    }
  }, [selectedCell, symbol]);

  return (
    <div className='details'>
      <div className='details__container'>
        {!coinsLoading &&
          coinsDetails?.convertedArray &&
          (getSocketFunctionalityInfo().full ||
            getSocketFunctionalityInfo().partial) && (
            <>
              <DetailsTop
                coinDetails={coinDetails}
                selectedCell={selectedCell}
                setSelectedCell={setSelectedCell}
                symbol={symbol}
                result={result}
                setSearchText={setSearchText}
                searchText={searchText}
              />
              <div className='details__flex'>
                <div className='details__money'>
                  {lastPriceInChart
                    ? formatCurrency(Number(lastPriceInChart).toFixed(3))
                    : formatCurrency(
                        lastPrice(formatMarketPair(coinDetails?.symbol))
                      )}{' '}
                  <span style={{ fontSize: 35 }}>
                    {' '}
                    {coinDetails?.symbol == 'usdt'
                      ? 'USDT'
                      : `${baseCurrency?.toUpperCase()}`}
                  </span>
                </div>
                {/* <div
                className={`details__status ${
                  getStatus(`${coinDetails?.symbol}${baseCurrency}`) === 'positive'
                    ? 'color-green'
                    : 'color-red'
                } `}
              >
                {priceChangePercent(`${coinDetails?.symbol}${baseCurrency}`)}
              </div> */}
              </div>

              {chartLoading && (
                <SkeletonContainer
                  width={'100%'}
                  height='200px'
                  style={{ marginBottom: 5 }}
                />
              )}
              {coinDetails?.symbol != 'usdt' &&
                coinDetails?.symbol != 'usdc' &&
                !chartLoading &&
                !chartError &&
                chartData &&
                chartData.payload.length !== 0 && (
                  <div
                    style={{
                      width: chartWidth(),
                      marginRight: 10,
                      padding: '20px',
                    }}
                  >
                    <DetailsChart
                      chartData={chartData}
                      color={
                        getStatus(`${coinDetails?.symbol}${baseCurrency}`) ===
                        'positive'
                          ? '#4fbf67'
                          : '#ff6628'
                      }
                      setLastPriceInChart={setLastPriceInChart}
                    />
                  </div>
                )}
              {selectedCell?.symbol !== 'usdt' &&
                coinDetails?.symbol != 'usdc' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 15,
                      marginTop: 25,
                    }}
                  >
                    {['1H', '1D', '1M', '1Y'].map((item, idx) => (
                      <div
                        className={`chart_options ${
                          selectedTimeStamp == item ? 'active' : ''
                        } `}
                        onClick={() => setSelectedTimestamp(item)}
                        key={idx}
                      >
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                )}
              {coinDetails?.symbol !== 'usdt' &&
                coinDetails?.symbol != 'usdc' && (
                  <div className='details__list'>
                    {investedData && (
                      <>
                        <DetailsBox
                          img={images.invested}
                          hasBalance={true}
                          title='Invested'
                          subTitle={`Return ${
                            investedData.balance == 0.0 ||
                            currentUsdtAmount === '0' ||
                            isNaN(currentUsdtAmount) ||
                            isNaN(investedAmount) ||
                            Number(currentUsdtAmount).toFixed(3) -
                              Number(investedAmount).toFixed(3) ==
                              0
                              ? `0%`
                              : percentage(investedAmount, currentUsdtAmount)
                          }`}
                          subTitleStatus={`${
                            parseFloat(currentUsdtAmount) <
                            parseFloat(investedAmount)
                              ? 'negative'
                              : 'positive'
                          }`}
                          description={`Total investment made in this asset (In ${baseCurrency?.toUpperCase()})`}
                          value={formatCurrency(
                            Number(investedAmount)?.toFixed(3)
                          )}
                        />
                        <DetailsBox
                          img={images.current}
                          title='Current'
                          hasBalance={true}
                          subTitle={` Total ${
                            currentUsdtAmount < investedAmount
                              ? 'Loss'
                              : 'Profit'
                          }${'  '}${
                            isNaN(currentUsdtAmount) ||
                            isNaN(investedAmount) ||
                            currentUsdtAmount == 0 ||
                            investedAmount == 0 ||
                            Number(currentUsdtAmount).toFixed(3) -
                              Number(investedAmount).toFixed(3) ==
                              0
                              ? '0'
                              : formatCurrency(
                                  Number(
                                    currentUsdtAmount - investedAmount
                                  ).toFixed(4)
                                ).replace('-', '')
                          }`}
                          subTitleStatus={`${
                            parseFloat(currentUsdtAmount) <
                            parseFloat(investedAmount)
                              ? 'negative'
                              : 'positive'
                          }`}
                          description={`Present value of this asset held in your wallet (In ${baseCurrency?.toUpperCase()})`}
                          value={formatCurrency(
                            Number(currentUsdtAmount)?.toFixed(3)
                          )}
                        />
                      </>
                    )}
                    <DetailsBox
                      img={images.marketBlue}
                      title='24Hr Change'
                      value={priceChangePercent(
                        `${coinDetails?.symbol}${baseCurrency}`
                      )}
                      description={`Difference between the current price and the price 24 hours ago (In ${baseCurrency?.toUpperCase()})`}
                      color={getStatus(`${coinDetails?.symbol}${baseCurrency}`)}
                    />
                    <DetailsBox
                      img={images.highPrice}
                      title='24Hr High'
                      value={formatCurrency(
                        getHigh(`${coinDetails?.symbol}${baseCurrency}`)
                      )}
                      description={`Highest price the coin has reached in the past 24 hours (In ${baseCurrency?.toUpperCase()})`}
                    />
                    <DetailsBox
                      img={images.buyPrice}
                      title='Current Buying Price'
                      value={formatCurrency(
                        lastPrice(`${coinDetails?.symbol}${baseCurrency}`)
                      )}
                      description={`The present buying price of the coin (In ${baseCurrency?.toUpperCase()})`}
                    />
                    <DetailsBox
                      img={images.lowPrice}
                      title='24Hr Low'
                      value={formatCurrency(
                        getLow(`${coinDetails?.symbol}${baseCurrency}`)
                      )}
                      description={`Lowest price the coin has reached in the past 24 hours (In ${baseCurrency?.toUpperCase()})`}
                    />
                  </div>
                )}
              <DetailsAbout coinDetails={coinDetails} />
              {coinDetails?.symbol != 'usdt' &&
                coinDetails?.symbol != 'usdc' && (
                  <DetailsButton
                    title='Buy & Sell'
                    onClick={() => {
                      history.push({
                        pathname: `/exchange/${
                          coinDetails?.symbol == 'usdt'
                            ? 'eth'
                            : coinDetails?.symbol
                        }-${baseCurrency}`,
                        search: location.search,
                      });
                      // analytics?.track('Clicked buy & sell button', {
                      //   from: 'Price details',
                      //   uid: userInfo?.payload?.uid,
                      //   symbol: selectedCell?.symbol,
                      //   QueryParams: { ...getQueryParams() },
                      //   ip: ip,
                      // });
                      sendTrackEvent('buttonClicked', {
                        title: 'Buy & Sell',
                        symbol: selectedCell?.symbol,
                        action: 'Routing to exchange',
                      });
                    }}
                  />
                )}
            </>
          )}
      </div>
    </div>
  );
}

export default DetailsContainer;
