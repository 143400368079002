import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Icon, Image } from '../../../components';
import { useWebSocketData } from '../../../utils/hooks';

function DetailsBox({
  img,
  title,
  subTitle,
  subTitleStatus,
  value,
  color,
  hasBalance,
  description,
}) {
  const { isdark } = useSelector((state) => state.theme);
  const { getSocketFunctionalityInfo } = useWebSocketData();
  return (
    <div className='details__box'>
      <div className='details__icon'>
        <Image
          src={img}
          alt=''
          className={`max-width-50 ${
            title === 'Current' ? 'scale-minus-1' : ''
          }`}
        />
      </div>
      <div className='details__inner'>
        <div className='details__category'>
          {title}
          <span data-tip={description} data-for={title}>
            <Icon icon='info-circle' className='icon' />
            <ReactTooltip
              place='bottom'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles price_details'
              id={title}
            />
          </span>
        </div>
        {!getSocketFunctionalityInfo().full && !value ? (
          <div
            className='dot-flashing small'
            style={{ marginTop: 8, marginLeft: 20 }}
          ></div>
        ) : (
          <div className={'details__price' + color && color}>{value}</div>
        )}
        {hasBalance && (
          <div className={subTitleStatus}>{subTitle ? subTitle : 0}</div>
        )}
      </div>
    </div>
  );
}

export default DetailsBox;
