export const getQueryParams = (query = null) =>
  (query || window.location.search.replace('?', ''))
    .split('&')
    .map((e) => e.split('=').map(decodeURIComponent))
    .reduce((r, [k, v]) => ((r[k] = v), r), {});

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const objToParams = (obj) => {
  let refinedObj = removeEmpty(obj);
  let res = new URLSearchParams(refinedObj).toString();
  return res;
};

export const convertedTimeStamp = (timeStamp) => {
  // let lastThreeDigits = timeStamp.toString().substr(-3);
  // if (lastThreeDigits === '000') {
  //   return Number(timeStamp) / 1000;
  // } else {
  //   return Number(timeStamp);
  // }
  return timeStamp;
};
