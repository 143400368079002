import stark_test_abi from '../utils/contracts/starkex_abi_test.json';
import stark_main_abi from '../utils/contracts/starkex_abi_main.json';
import polygon_test_abi from '../utils/contracts/polygon_deposit.json';
import stark_erc20_abi from '../utils/contracts/stark_erc20_abi.json';
import fast_withdrawal_abi from '../utils/contracts/fast_withdrawal_smart_contract_abi.json';

export const appConfig = {
  ALLOWED_NETWORK_NAME:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 'mainnet' : 'Goerli',
  ALLOWED_POLYGON_NETWORK_NAME:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 'mainnet' : 'Goerli',
  ALLOWED_NETWORK_ID: process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 1 : 5,
  ALLOWED_POLYGON_NETWORK_ID:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 137 : 80001,
  ALLOWED_SCROLL_NETWORK_ID:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 534352 : 534351,
  ALLOWED_OPTIMISM_NETWORK_ID:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? 10 : 420,
  // ALLOWED_STARKNET_NETWORK_ID: '0x534e5f4d41494e',
  ALLOWED_STARKNET_NETWORK_ID:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet'
      ? '0x534e5f4d41494e'
      : '0x534e5f474f45524c49',
  ALLOWED_CHAIN_ID:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? '0x1' : '0x5',
  ALLOWED_POLYGON_CHAIN_ID:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet' ? '0x137' : '0x80001',
  STARK_CONTRACT:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet'
      ? '0x1390f521A79BaBE99b69B37154D63D431da27A07'
      : '0x87eB0b1B5958c7fD034966925Ea026ad8Bf3d6dD',
  abi_file:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet'
      ? stark_main_abi
      : stark_test_abi,
  polygon_abi_file:
    process.env.REACT_APP_ENVIRONMENT === 'mainnet'
      ? polygon_test_abi
      : polygon_test_abi,
  environment: process.env.REACT_APP_ENVIRONMENT,
  stark_erc20_abi: stark_erc20_abi,
  testingStarkwareToken:
    '0x053c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8',
};

export const MAX_LENGTH_ALLOWED_IN_NUM_INPUT = 8;
export const USER_CAN_REFERRAL_COUNT = 25;
export const TICKER_REFETCH_TIMEOUT_IN_MINS = 2;
export const WITHDRAWAL_FEE_DECIMAL_POINTS = 5;
export const ALLOWED_POLYGON_NETWORK_NAME =
  appConfig.ALLOWED_POLYGON_NETWORK_NAME;
export const ALLOWED_NETWORK_NAME = appConfig.ALLOWED_NETWORK_NAME;
export const ALLOWED_NETWORK_ID = appConfig.ALLOWED_NETWORK_ID;
export const ALLOWED_POLYGON_NETWORK_ID = appConfig.ALLOWED_POLYGON_NETWORK_ID;
export const ALLOWED_STARKNET_NETWORK_ID =
  appConfig.ALLOWED_STARKNET_NETWORK_ID;
export const ALLOWED_CHAIN_ID = appConfig.ALLOWED_CHAIN_ID;
export const ALLOWED_POLYGON_CHAIN_ID = appConfig.ALLOWED_POLYGON_CHAIN_ID;
export const ALLOWED_SCROLL_NETWORK_ID = appConfig.ALLOWED_SCROLL_NETWORK_ID;
export const ALLOWED_OPTIMISM_NETWORK_ID =
  appConfig.ALLOWED_OPTIMISM_NETWORK_ID;
export const STARK_CONTRACT = appConfig.STARK_CONTRACT;
export const ENVIRONMENT = appConfig.environment;
export const ENABLE_SEGMENT = true;
// export const ENABLE_RANGO = true;
export const ENABLE_WALLET_CONNECT_V2 = true;
// export const INTRACT_KEY = process.env.REACT_APP_INTRACT_KEY;
export const MAX_INT_ALLOWANCE =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const stark_abi = appConfig.abi_file;
export const polygon_abi = appConfig.polygon_abi_file;
export const NORMAL_WITHDRAWAL_CONFIRMATION_HOURS = 24;
export const baseCurrency = 'usdc';
export const baseDispayCurrency = { currency: 'usdc', symbol: '$' };
export const fastWithdrawalABI = fast_withdrawal_abi;
export const testingStarkwareToken = appConfig.testingStarkwareToken;
export const fastWithdrawalOwnerAddress = {
  ethereum: {
    testnet: '0xE4A3c711DF83a29E151df5B87f77472f36FBa2cC',
    mainnet: '0x53786E4CD6bC36Cf20D55da59BcfF60CAf69c6B0',
  },
  polygon: {
    testnet: '0x685dB6521452DaD42CeE00fBCB0b10b0788fA9ba',
    mainnet: '0xd14B2Bf2fc651309B423b1061E17706dA94Ce086',
  },
  optimism: {
    testnet: '0x685db6521452dad42cee00fbcb0b10b0788fa9ba',
    mainnet: '',
  },
};
export const tempStaticTime = '2023-09-24T00:00:00.000000Z';
export const fastWithdrawalContractAddress = {
  ethereum: {
    testnet: '0x52859bcd452e8eb7757b83623eed3fbb86ed3e1b',
    mainnet: '0xe17F8e501bF5e968e39D8702B30c3A8b955d8f52',
  },
  polygon: {
    testnet: '0x4112d384aab075404a9772086425febe45a58f10',
    mainnet: '0x2714C5958e2b1417B3f2b7609202FFAD359a5965',
  },
  optimism: {
    testnet: '0x29F53FFC222CD75805712c2dF76fA683c4b1e967',
    mainnet: '',
  },
};
export const starkErc20Abi = appConfig.stark_erc20_abi;
export const metamaskDeepLinkUrl =
  ENVIRONMENT === 'testnet'
    ? 'https://metamask.app.link/dapp/testnet.brine.finance/signin'
    : 'https://metamask.app.link/dapp/trade.brine.fi/signin';

export const ethNetworkName = {
  ethereum: {
    testnet: 'Goerli Network',
    mainnet: 'Ethereum Mainnet',
  },
  polygon: {
    testnet: 'Polygon Mumbai',
    mainnet: 'Polygon Mainnet',
  },
  scroll: {
    testnet: 'Scroll sepolia',
    mainnet: 'Scroll Mainnet',
  },
  optimism: {
    testnet: 'Optimism Goerli',
    mainnet: 'Optimism Mainnet',
  },
};

export const staticMsg =
  ENVIRONMENT === 'mainnet'
    ? 'Get started with Brine. Make sure the origin is https://trade.brine.fi'
    : "Click sign to verify you're a human - Brine.finance";
export const rpcURL = {
  ethereum: {
    testnet: 'https://goerli.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6',
    mainnet: 'https://mainnet.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6',
  },
  polygon: {
    testnet:
      'https://polygon-mumbai.g.alchemy.com/v2/R5-9Oy4phjw1P2jdxZ_g9P1xlOr6F1RE',
    mainnet:
      'https://polygon-mainnet.g.alchemy.com/v2/R5-9Oy4phjw1P2jdxZ_g9P1xlOr6F1RE',
  },
  optimism: {
    testnet:
      'https://opt-goerli.g.alchemy.com/v2/gJR89pdjomrloaSX-jy41lq9OJboQvfn',
    mainnet:
      'https://opt-goerli.g.alchemy.com/v2/gJR89pdjomrloaSX-jy41lq9OJboQvfn',
  },
};
