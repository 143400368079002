import React from 'react';
import { Link } from 'react-router-dom';
import {
  BannerStaticImage1,
  BannerStaticImage2,
  BannerStaticImage3,
  BannerStaticImage4,
  images,
} from '../../../utils/image/img';
import { Image } from '../../../components';
import { useSelector } from 'react-redux';

const staticBannerImage = [
  {
    title: 'Self-custody',
    subtitle: 'Hold your assets the Trustless way with us.',
    svg: <BannerStaticImage1 />,
  },
  {
    title: 'Industry’s lowest trading fee',
    subtitle: 'Trade at a fee of 0.04% or lower.',
    svg: <BannerStaticImage2 />,
  },
  {
    title: 'Instant trade execution',
    subtitle: 'Experience order execution at light speed.',
    svg: <BannerStaticImage3 />,
  },
  {
    title: 'Exciting rebates',
    subtitle: 'Get up to 50% of your referrals’ trading fees.',
    svg: <BannerStaticImage4 />,
  },
];

function AuthPreviewImage({
  title,
  subtitle,
  showImage = true,
  showStaticText = false,
}) {
  const { isdark } = useSelector((s) => s.theme);

  return (
    <div className='login__col'>
      <Link
        className='login__logo'
        to={(location) => ({
          ...location,
          pathname: '/',
        })}
      >
        <Image
          src={isdark ? images.logoDark : images.logoWhite}
          alt='Brine logo'
          style={{ width: 52 }}
        />
      </Link>
      <h1 className='login__title h1' style={{ fontSize: '42px' }}>
        {title}
      </h1>
      <div className='login__info h6'>{subtitle}</div>
      {showImage ? (
        <div className='login__preview'>
          <Image src={images.previewImage} alt='previewImage' />
        </div>
      ) : null}
      {showStaticText ? (
        <div className='static__banner_container'>
          {staticBannerImage.map((item, idx) => (
            <div className='static__banner_item' key={idx}>
              <div className='svg'>{item.svg}</div>
              <div>
                <div className='h5'>{item.title}</div>
                <div className='p'>{item.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default AuthPreviewImage;
