import { useEffect, useState } from 'react';
import HeaderSearch from './header-search/header-search';
import HeaderLogo from './header-logo/header-logo';
import HeaderGroup from './header-group/header-group';
import HeaderDropDown from './header-dropdown/header-dropdown';
import { toggleNavbar } from '../../actions/navbarAction';
import { useDispatch } from 'react-redux';
import HeaderProfile from './header-profile/header-profile';
import CollapseIcon from '../navbar/nav-svgs/collapse';
import { useSelector } from 'react-redux';
import {
  useAudio,
  useBalance,
  useFeature,
  useSegment,
  useViewport,
  useWebSocketData,
} from '../../utils/hooks';
import {
  CaretDownIconSvg,
  CopyIcon,
  DepositWithFaitSvg,
  DiscordIconSvg,
  DocumentIconSvg,
  EtherScanIcon,
  HelpIconSvg,
  images,
  LogoutIcon,
  MoreIconSvg,
  SettingSvg,
  TermsIconSvg,
  TestTubeSvg,
  WorldIconSvg,
} from '../../utils/image/img';
import {
  getNotificationDetails,
  getRangoOrderBook,
  getTickerDetails,
  getUserFeatureFlagDetails,
  storeMsg,
} from '../../actions/assestsAction';
import moment from 'moment';
import {
  getDepositsData,
  getDEXDepositsData,
  getDEXFastWithdrawData,
  getOrderData,
} from '../../actions/historyAction';
import _ from 'lodash';
import {
  changeNotificatedState,
  getBalance,
  getUserPermissionDetails,
  logout,
} from '../../actions/userAction';
import { getScratchCards } from '../../actions/rewardsAction';
import { useLocation, useHistory } from 'react-router-dom';
import Image from '../common/img/image';
import { ENVIRONMENT } from '../../utils/constants';
import { truncate } from '../../utils/string-methods';
import { toggleSetting } from '../../actions/settingAction';
import { toast } from 'react-toastify';
import { etherscanDomain } from '../../utils/urls';
import { getOrderBook } from '../../actions/exchangeAction';
import Popup from '../common/popup/popup';
import CustomButton from '../common/custom-button/custom-button';
import Loader from '../common/loader/loader';
import { sendFeedback } from '../../actions/commonAction';
import * as Sentry from '@sentry/react';
import { networkName } from '../../utils/urls';
import { FEATURE_FLAG } from '../../utils/hooks/useFeature';
import { getSaltLeaderBoard } from '../../actions/saltAction';

export const walletTextAndLogo = {
  web3_auth: {
    text: 'Web3Auth Wallet',
    logo: images.google,
  },
  metamask: {
    text: 'Metamask Wallet',
    logo: images.metamask,
  },
  wallet_connect: {
    text: 'WalletConnect Wallet',
    logo: images.walletConnect,
  },
  wallet_link: {
    text: 'Wallet Link Wallet',
    logo: images.walletLink,
  },
  wallet_connect_v2: {
    text: 'WalletConnect Wallet',
    logo: images.walletConnect,
  },
};

const userWalletOptions = [
  {
    name: 'Settings',
    svg: SettingSvg,
  },
  // {
  //   name: 'Deposit with Fiat',
  //   svg: DepositWithFaitSvg,
  // },
  {
    name: 'Copy Address',
    svg: CopyIcon,
  },
  {
    name: 'Open in Etherscan',
    svg: EtherScanIcon,
  },
  {
    name: 'Logout',
    svg: LogoutIcon,
  },
];

const moreData = [
  {
    image: images.fileCode,
    name: 'Docs',
    link: 'https://docs.brine.fi/api-documentation',
    svg: DocumentIconSvg,
  },
  {
    image: images.discord,
    name: 'Community',
    link: 'https://discord.com/invite/wuPHBr9e9y',
    svg: DiscordIconSvg,
  },
  {
    image: images.documentIcon,
    name: 'Terms of use',
    link: 'https://www.brine.fi/legal',
    svg: TermsIconSvg,
  },
  {
    image: images.documentIcon,
    name: 'Privacy Policy',
    link: 'https://www.brine.fi/legal',
    svg: TermsIconSvg,
  },
  {
    image: images.helpIcon,
    name: 'Help',
    link: '/help',
    svg: HelpIconSvg,
  },
];

const Header = ({
  isExchangePage,
  isNotificationPage,
  showborder,
  isHomePage,
  isWalletPage,
  tradeMode,
  setTradeMode,
}) => {
  const dispatch = useDispatch();
  const { getInfoAboutCoinsByTokenId, getInfoAboutCoins, preventScientific } =
    useBalance();
  const { sendTrackEvent } = useSegment();
  // const {
  // userDetails: { userData, loading: uLoading },
  // theme: { isdark },
  // userLogin: { userInfo },
  // notificationDetails: { notificationData },
  // userMsg: { state: userNotificationData },
  // userVia: { state: userWalletInfo },
  // getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
  // showErrorMsg: { state: wsErrorState },
  // userFeatureFlag: {
  //   response: userFeatureFlagResponse,
  //   error: userFeatureFlagError,
  //   loading: userFeatureFlagLoading,
  // },
  // saltLeaderBoard: {
  //   response: saltLeaderboardResponse,
  //   loading: saltLeaderboardLoading,
  //   error: saltLeaderboardError,
  // },
  // } = useSelector((state) => state);
  const {
    response: saltLeaderboardResponse,
    loading: saltLeaderboardLoading,
    error: saltLeaderboardError,
  } = useSelector((state) => state.saltLeaderBoard);
  const {
    response: userFeatureFlagResponse,
    error: userFeatureFlagError,
    loading: userFeatureFlagLoading,
  } = useSelector((state) => state.userFeatureFlag);
  const {
    response: userPermissionFlagResponse,
    error: userPermissionFlagError,
    loading: userPermissionFlagLoading,
  } = useSelector((state) => state.userPermission);
  const { state: wsErrorState } = useSelector((state) => state.showErrorMsg);
  const [playing, toggle] = useAudio();
  const { width } = useViewport();
  const location = useLocation();
  const history = useHistory();
  const { getSocketFunctionalityInfo } = useWebSocketData();
  const [openWebsocketError, setOpenWebsocketError] = useState(false);
  const [disableFeedBackBtn, setDisableFeedBackBtn] = useState(false);
  const { getStatusByFeatureKey } = useFeature();
  const { loading, error, assetsData } = useSelector(
    (state) => state.getAssests
  );
  const {
    loading: orderLoading,
    error: orderError,
    orderBookData,
  } = useSelector((state) => state.getOrderBook);
  const { state: userWalletInfo } = useSelector((state) => state.userVia);
  const { state: userNotificationData } = useSelector((state) => state.userMsg);
  const { notificationData } = useSelector(
    (state) => state.notificationDetails
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const { isdark } = useSelector((state) => state.theme);

  console.log({ userPermissionFlagResponse });

  useEffect(() => {
    if (notificationData?.deposit) {
      let objIndex = userNotificationData?.findIndex((obj) => {
        return (
          obj.id == notificationData?.deposit?.deposit_blockchain_hash &&
          obj.type === 'deposit'
        );
      });
      if (
        notificationData?.deposit?.blockchain_deposit_status === 'Success' &&
        notificationData?.deposit?.brine_deposit_status === 'Success' &&
        objIndex === -1
      ) {
        let data = userNotificationData;
        let dataObj = {
          id: notificationData?.deposit?.deposit_blockchain_hash,
          logo: getInfoAboutCoinsByTokenId(notificationData?.deposit?.token_id)
            ?.logo,
          msg: `Your ${preventScientific(
            Number(notificationData?.deposit?.amount) /
              Math.pow(
                10,
                getInfoAboutCoinsByTokenId(notificationData?.deposit?.token_id)
                  ?.quanitization
              )
          )} ${
            getInfoAboutCoinsByTokenId(notificationData?.deposit?.token_id)
              ?.name
          } have been successfully deposited.`,
          time: moment(notificationData?.deposit?.updated_at).format('llll'),
          network: notificationData?.deposit?.network,
          type: 'deposit',
        };
        data.push(dataObj);
        const uniqData = _.uniqBy(data, 'id');
        dispatch(storeMsg(uniqData));
        if (objIndex === -1) {
          dispatch(getDEXDepositsData());
          dispatch(getBalance());
        }
        if (!playing) {
          dispatch(changeNotificatedState(false));
          toggle();
          if (location?.pathname === '/history') {
            history.push(`/history?tab=deposits&clear=true`);
          }
        }
      }
    } else if (notificationData?.fast_withdrawal) {
      let objIndex = userNotificationData?.findIndex((obj) => {
        return (
          obj.id ==
            notificationData?.fast_withdrawal?.l1_withdrawal_blockchain_hash &&
          obj.type === 'fast_withdrawal'
        );
      });
      if (
        notificationData?.fast_withdrawal?.blockchain_status === 'SUCCESS' &&
        notificationData?.fast_withdrawal?.brine_withdrawal_status ===
          'Success' &&
        objIndex === -1
      ) {
        let data = userNotificationData;
        let dataObj = {
          id: notificationData?.fast_withdrawal?.l1_withdrawal_blockchain_hash,
          logo: getInfoAboutCoins(notificationData?.fast_withdrawal?.token_id)
            ?.logo,
          msg: `Your ${Number(notificationData?.fast_withdrawal?.amount)} ${
            getInfoAboutCoins(notificationData?.fast_withdrawal?.token_id)?.name
          } has been sent successfully to your wallet.`,
          time: moment(notificationData?.fast_withdrawal?.updated_at).format(
            'llll'
          ),
          type: 'fast_withdrawal',
        };
        data.push(dataObj);
        const uniqData = _.uniqBy(data, 'id');
        dispatch(storeMsg(uniqData));
        if (objIndex === -1) {
          dispatch(getDEXFastWithdrawData());
        }
        if (!playing && objIndex === -1) {
          dispatch(changeNotificatedState(false));
          toggle();
          if (location?.pathname === '/history') {
            history.push(`/history?tab=fast-withdrawals&clear=true`);
          }
        }
      }
    } else if (notificationData?.referral_reward) {
      let objIndex = userNotificationData?.findIndex((obj) => {
        return (
          obj.id == notificationData?.referral_reward?.id &&
          obj.type === 'referral_reward'
        );
      });
      let data = userNotificationData;
      let dataObj = {
        id: notificationData?.referral_reward?.id,
        logo: getInfoAboutCoins(notificationData?.referral_reward?.currency)
          ?.logo,
        msg:
          notificationData?.referral_reward?.status === 'Not_Claimed'
            ? `You received ${
                notificationData?.referral_reward?.trading_fee_reward
              } ${notificationData?.referral_reward?.currency?.toUpperCase()} as trading fee reward..`
            : `Your reward of ${
                notificationData?.referral_reward?.trading_fee_reward
              } ${notificationData?.referral_reward?.currency?.toUpperCase()} has been successfully redeemed.`,
        time: moment(notificationData?.referral_reward?.updated_at).format(
          'llll'
        ),
        type: 'referral_reward',
      };
      data.push(dataObj);
      const uniqData = _.uniqBy(data, 'id');
      dispatch(storeMsg(uniqData));
      if (objIndex === -1) {
        dispatch(getScratchCards('Not_Claimed'));
      }
      if (!playing && objIndex === -1) {
        dispatch(changeNotificatedState(false));
        toggle();
      }
    }
    // else if (
    //   notificationData?.order &&
    //   notificationData?.order?.state === 'done'
    // ) {
    //   let objIndex = userNotificationData?.findIndex((obj) => {
    //     return obj.id == notificationData?.order?.id && obj.type === 'order';
    //   });
    //   let data = userNotificationData;
    //   let dataObj = {
    //     id: notificationData?.order?.id,
    //     logo: images.trade,
    //     msg: `Your order ${notificationData?.order?.market?.toUpperCase()} - ${notificationData?.order?.side?.toUpperCase()} is successfully completed`,
    //     time: moment.unix(notificationData?.order?.at).format('llll'),
    //     type: 'order',
    //   };
    //   data.push(dataObj);
    //   const uniqData = _.uniqBy(data, 'id');
    //   dispatch(storeMsg(uniqData));
    //   if (objIndex === -1) {
    //     dispatch(getOrderData());
    //   }
    //   if (!playing && objIndex === -1) {
    //     toggle();
    //   }
    // }
  }, [
    notificationData?.deposit,
    notificationData?.referral_reward,
    notificationData?.fast_withdrawal,
    // notificationData?.order,
  ]);

  useEffect(() => {
    if (!saltLeaderboardResponse) {
      dispatch(getSaltLeaderBoard());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!notificationData && userInfo?.token) {
      dispatch(getNotificationDetails());
    }
    if (!orderBookData) {
      dispatch(getRangoOrderBook());
    }
    if (!userFeatureFlagResponse) {
      dispatch(getUserFeatureFlagDetails());
    }
    if (!userPermissionFlagResponse && userInfo?.token) {
      dispatch(getUserPermissionDetails());
    }
  }, [dispatch, userInfo]);

  const handleCopyTheCode = (address) => {
    navigator.clipboard.writeText(address);
    toast.success('Address copied 👍🏻', { toastId: 'copy' });
  };

  const handleUserOptions = (name) => {
    sendTrackEvent('buttonClicked', { title: name });
    switch (name) {
      case userWalletOptions[0].name:
        dispatch(toggleSetting());
        break;
      case userWalletOptions[1].name:
        handleCopyTheCode(userWalletInfo?.address);
        break;
      case userWalletOptions[2].name:
        window.open(`${etherscanDomain}address/${userWalletInfo?.address}`);
        break;
      case userWalletOptions[3].name:
        dispatch(logout(true));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!assetsData) {
      dispatch(getTickerDetails());
    }
  }, []);

  useEffect(() => {
    if (wsErrorState === true && !loading && !orderLoading && error) {
      setOpenWebsocketError(true);
      // if (networkName !== 'testnet') {
      //   // Sentry.captureMessage(
      //   //   `Urgent!, Global ticker and orderbooks are broken or have an empty array. ENV - ${networkName}`
      //   // );
      // }
    } else {
      setOpenWebsocketError(false);
    }
  }, [wsErrorState, loading, orderLoading, error]);

  const handleAlertDeveloper = async () => {
    try {
      const data = await sendFeedback(
        `Livewire and orderbooks are broken or have an empty array. ENV - ${networkName}`
      );
      setDisableFeedBackBtn(true);
      toast.success('Successfully submitted');
    } catch (error) {
      toast.error('Please try again in a while!');
    }
  };

  return (
    <>
      {(!tradeMode || width < 700) && (
        <div className={`header ${showborder ? 'header_border' : ''} `}>
          <HeaderLogo logo={isdark ? images.logoDark : images.logoWhite} />
          {isNotificationPage ? (
            ''
          ) : (
            // <HeaderSearch
            //   styles={{ visibility: isWalletPage ? 'hidden' : 'visible' }}
            // />
            <div className='header__search'>
              <HeaderDropDown
                bodyStyle={{
                  padding: '10px 5px',
                  marginLeft: '-13px',
                  border: `1px solid ${
                    isdark ? 'rgba(228, 228, 228, 0.1)' : '#e4e4e4'
                  } `,
                  boxShadow: 'rgba(228, 228, 228, 0.1)',
                  borderRadius: 10,
                }}
                heading={
                  <div className='header__heading'>
                    {ENVIRONMENT === 'testnet' ? (
                      <TestTubeSvg />
                    ) : (
                      <WorldIconSvg />
                    )}
                    <p>{ENVIRONMENT === 'testnet' ? 'Testnet' : 'Mainnet'}</p>
                    <CaretDownIconSvg className='arrow' />
                  </div>
                }
              >
                <div className='header__more_options'>
                  {networkName === 'mainnet' ? (
                    <div
                      className={`header__more_item`}
                      onClick={() => {
                        if (ENVIRONMENT === 'testnet') return;
                        sendTrackEvent('buttonClicked', {
                          title: 'Testnet',
                          action: 'Opening brine testnet',
                        });
                        window.open('https://testnet.brine.finance/', '_blank');
                      }}
                    >
                      <TestTubeSvg />
                      <p>Testnet</p>
                    </div>
                  ) : (
                    <div
                      className='header__more_item'
                      onClick={() => {
                        if (ENVIRONMENT === 'mainnet') return;
                        window.open('https://trade.brine.fi/', '_blank');
                        sendTrackEvent('buttonClicked', {
                          title: 'Testnet',
                          action: 'Opening brine mainnet',
                        });
                      }}
                    >
                      <WorldIconSvg />
                      <p>Mainnet</p>
                    </div>
                  )}
                </div>
              </HeaderDropDown>
              <HeaderDropDown
                bodyStyle={{
                  padding: '10px 5px',
                  marginLeft: '-13px',
                  border: `1px solid ${
                    isdark ? 'rgba(228, 228, 228, 0.1)' : '#e4e4e4'
                  } `,
                  boxShadow: 'rgba(228, 228, 228, 0.1)',
                  borderRadius: 10,
                }}
                styles={{
                  marginLeft: 30,
                }}
                heading={
                  <div className='header__heading'>
                    <MoreIconSvg />
                    <p>More</p>
                    <CaretDownIconSvg className='arrow' />
                  </div>
                }
              >
                <div
                  className='header__more_options'
                  style={{
                    width: 120,
                  }}
                >
                  {moreData.map((item) => (
                    <div
                      className='header__more_item'
                      key={item.name}
                      onClick={() => {
                        sendTrackEvent('buttonClicked', {
                          title: item?.name,
                          action: 'Opening the link',
                        });
                        item?.name !== 'Help'
                          ? window.open(item.link, '_blank')
                          : history.push({
                              pathname: '/help',
                              search: location.search,
                            });
                      }}
                    >
                      {/* <Image
                        src={item.image}
                        style={{
                          tintColor: 'white',
                        }}
                      /> */}
                      {item.svg()}
                      <p>{item.name}</p>
                    </div>
                  ))}
                </div>
              </HeaderDropDown>
            </div>
          )}
          <div
            className='header'
            style={{
              padding: 0,
            }}
          >
            {/* {isExchangePage && width >= breakpoint && (
          <TradeModeToggler
            tradeMode={tradeMode}
            setTradeMode={setTradeMode}
            styles={{ marginBottom: 0, marginRight: 30 }}
          />
        )} */}
            <HeaderGroup
              isExchangePage={isExchangePage}
              tradeMode={tradeMode}
              setTradeMode={setTradeMode}
              notificationData={userNotificationData}
            />
            {/* {userInfo?.token && <HeaderProfile />} */}
            {userInfo?.token && (
              <HeaderDropDown
                bodyStyle={{
                  padding: '10px 5px',
                  marginLeft: '-7px',
                  border: `1px solid ${
                    isdark ? 'rgba(228, 228, 228, 0.1)' : '#e4e4e4'
                  } `,
                  boxShadow: 'rgba(228, 228, 228, 0.1)',
                  borderRadius: 10,
                  marginTop: 50,
                }}
                styles={{
                  // justifyContent: 'center',
                  display: 'flex',
                }}
                heading={
                  <div className='header__heading header__wallet_info'>
                    <Image
                      src={walletTextAndLogo[userWalletInfo?.via].logo}
                      style={{ width: 30, height: 30 }}
                    />
                    <p>{truncate(userWalletInfo?.address, 10)}</p>
                    <CaretDownIconSvg className='arrow' />
                  </div>
                }
              >
                <div
                  className='header__more_options'
                  style={{
                    width: 150,
                  }}
                >
                  {userWalletOptions.map((item) => (
                    <div
                      className='header__more_item'
                      key={item.name}
                      onClick={() => handleUserOptions(item.name)}
                    >
                      {item.svg()}
                      <p>{item.name}</p>
                    </div>
                  ))}
                </div>
              </HeaderDropDown>
            )}
            <button
              className='header__toggle'
              onClick={() => dispatch(toggleNavbar())}
            >
              <CollapseIcon />
            </button>
          </div>
        </div>
      )}
      <div className='share__popup network__error'>
        <Popup
          state={openWebsocketError}
          setState={setOpenWebsocketError}
          disabledClosebtn={true}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div className='h6' style={{ fontSize: 22 }}>
              Oops, something went wrong.
            </div>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {/* <Loader /> */}
              <Image
                src={images.networkError}
                style={{ width: 100, height: 112 }}
              />
            </div>
            <p style={{ maxWidth: '450px', fontSize: 13 }}>
              We're working on fixing the issue as soon as possible. Please try
              again later or contact our{' '}
              <a
                href='mailto:support@brine.fi'
                style={{ textDecoration: 'underline', color: '#8173E0' }}
              >
                customer support team
              </a>{' '}
              for assistance. Thank you for your patience
            </p>
            <CustomButton
              title='Send feedback'
              style={{
                width: '100%',
              }}
              disabled={disableFeedBackBtn}
              onClick={handleAlertDeveloper}
            />
          </div>
        </Popup>
      </div>
    </>
  );
};

export default Header;
