import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';

import { MAINNET_CHAINS, URLS } from '../web3-react-utils/chains';

import { TESTNET_CHAINS } from '../web3-react-utils/chains';
import { ALLOWED_NETWORK_ID, ENVIRONMENT } from '../constants';

const [mainnet, ...optionalMainnetChains] =
  Object.keys(MAINNET_CHAINS).map(Number);

const [testnet, ...optionalTestnetChains] =
  Object.keys(TESTNET_CHAINS).map(Number);

const projectId =
  ENVIRONMENT !== 'testnet'
    ? '9b45d4c2894576345c88745da6eae231'
    : 'e04c17b2d33cf2ea644898e9b2d67923';

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      // defaultChainId: ALLOWED_NETWORK_ID,
      options: {
        projectId: projectId,
        chains: [ENVIRONMENT === 'mainnet' ? mainnet : testnet],
        showQrModal: true,
        optionalChains:
          ENVIRONMENT === 'mainnet'
            ? optionalMainnetChains
            : optionalTestnetChains,
        rpcMap: URLS,
        rpc: URLS,
      },
    })
);
