import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toggleNavbar } from '../../../actions/navbarAction';
import { useNotification, useViewport } from '../../../utils/hooks';
import { Icon, Tooltip, Image, RippleEffect } from '../../../components';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function NavItem({ route, active, isExchangePage }) {
  const { width } = useViewport();
  const breakpoint = 1000;
  const dispatch = useDispatch();
  const { getUnclaimRewardsCount, getWithdrawalCount } = useNotification();

  const { isdark } = useSelector((state) => state.theme);
  const { open } = useSelector((state) => state.navbar);

  // const showSaltRippleAnimaion = localStorage.getItem(
  //   'show_salt_ripple_animation'
  // );

  const showBadges = (cacheKey) => {
    if (cacheKey) {
      let res = sessionStorage.getItem(cacheKey) || null;
      return res ? true : false;
    } else {
      return false;
    }
  };

  let badges = {
    Referrals: getUnclaimRewardsCount(),
    History: getWithdrawalCount(),
  };

  return (
    <Link
      className={'sidebar__item ' + (active ? 'active' : '')}
      // to={route.path}
      to={(location) => ({ ...location, pathname: route.path })}
      onClick={() => (width <= breakpoint ? dispatch(toggleNavbar()) : null)}
      data-tip={route.name}
      style={{ position: 'relative' }}
    >
      <div className='sidebar__icon'>
        {route?.icon ? (
          <Icon icon={route.icon} className='icon' />
        ) : (
          <Image
            src={route.image}
            alt=''
            style={{ width: route.path === '/salt' ? 28 : 19 }}
          />
        )}
      </div>
      {(!open || isExchangePage) && (
        <ReactTooltip
          place={'right'}
          type={isdark ? 'light' : 'dark'}
          effect='solid'
          className='tooltip_styles navbar_tooltip'
        />
      )}
      <div className='sidebar__text'>{route.name}</div>
      {/* {route.showAnimation && showSaltRippleAnimaion !== 'false' ? ( */}
      {/* {location.pathname !== route.path && ( */}
      <>
        {!showBadges(route?.cacheKey) && badges[route.name] ? (
          <div className='navbar__ripple_wrapper'>
            {/* <RippleEffect /> */}
            <p
              className='badges__navbar'
              style={{
                color: '#fff',
                background: '#6c5dd3',
                padding: '1px 7px',
                borderRadius: '20px',
                fontSize: 10,
                marginRight: !open || isExchangePage ? 0 : 20,
              }}
            >
              {badges[route.name]}
            </p>
          </div>
        ) : null}
      </>
      {/* )} */}

      {/* ) : null} */}
    </Link>
  );
}

export default NavItem;
