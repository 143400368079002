import { useDispatch, useSelector } from 'react-redux';
import { toggleSetting } from '../../../actions/settingAction';
import { changeTheme } from '../../../actions/themeAction';
import { getQueryParams } from '../../../utils/query-helper';
import { useAnalytics } from '../../../utils/hooks';
import { Icon } from '../../../components';
import { images } from '../../../utils/image/img';

function NavBottom() {
  // const {
  // theme: { isdark },
  // userDetails: { userData, loading, error },
  // } = useSelector((state) => state);

  const { userData, loading, error } = useSelector(
    (state) => state.userDetails
  );
  const { isdark } = useSelector((state) => state.theme);

  // const { analytics } = useAnalytics();

  const dispatch = useDispatch();

  return (
    <div className='sidebar__bottom'>
      <label className='switch switch_theme js-switch-theme'>
        <input
          className='switch__input'
          type='checkbox'
          onChange={() => {
            dispatch(changeTheme(isdark));
            // analytics?.track('User changed theme!', {
            //   value: isdark ? 'dark' : 'white',
            //   uid: userInfo?.payload?.uid,
            //   QueryParams: { ...getQueryParams() },
            //   ip: ip,
            // });
          }}
          checked={isdark ? true : false}
        />
        <span className='switch__in'>
          <span className='switch__box'></span>
          <span className='switch__icon'>
            <Icon
              icon={`theme-${isdark ? 'light' : 'dark'}`}
              className='icon'
            />
          </span>
        </span>
      </label>
      <div className='sidebar__user'>
        {userData && (
          <img
            src={
              userData.payload.img?.file
                ? userData.payload.img.file
                : images.avator
            }
            alt=''
            onClick={() => dispatch(toggleSetting())}
          />
        )}
      </div>
    </div>
  );
}

export default NavBottom;
