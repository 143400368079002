export const GET_ORDERDATA_REQUEST = 'GET_ORDERDATA_REQUEST';
export const GET_ORDERDATA_SUCCESS = 'GET_ORDERDATA_SUCCESS';
export const GET_ORDERDATA_FAIL = 'GET_ORDERDATA_FAIL';
export const HISTORY_PAGE_RESET = 'HISTORY_PAGE_RESET';

export const GET_PENDING_ORDERDATA_REQUEST = 'GET_PENDING_ORDERDATA_REQUEST';
export const GET_PENDING_ORDERDATA_SUCCESS = 'GET_PENDING_ORDERDATA_SUCCESS';
export const GET_PENDING_ORDERDATA_FAIL = 'GET_PENDING_ORDERDATA_FAIL';

export const GET_TRADEDATA_REQUEST = 'GET_TRADEDATA_REQUEST';
export const GET_TRADEDATA_SUCCESS = 'GET_TRADEDATA_SUCCESS';
export const GET_TRADEDATA_FAIL = 'GET_TRADEDATA_FAIL';

export const GET_WITHDRAWDATA_REQUEST = 'GET_WITHDRAWDATA_REQUEST';
export const GET_WITHDRAWDATA_SUCCESS = 'GET_WITHDRAWDATA_SUCCESS';
export const GET_WITHDRAWDATA_FAIL = 'GET_WITHDRAWDATA_FAIL';

export const GET_DEPOSITSDATA_REQUEST = 'GET_DEPOSITSDATA_REQUEST';
export const GET_DEPOSITSDATA_SUCCESS = 'GET_DEPOSITSDATA_SUCCESS';
export const GET_DEPOSITSDATA_FAIL = 'GET_DEPOSITSDATA_FAIL';

export const GET_DEX_DEPOSIT_DATA_REQUEST = 'GET_DEX_DEPOSIT_DATA_REQUEST';
export const GET_DEX_DEPOSIT_DATA_SUCCESS = 'GET_DEX_DEPOSIT_DATA_SUCCESS';
export const GET_DEX_DEPOSIT_DATA_FAIL = 'GET_DEX_DEPOSIT_DATA_FAIL';
export const GET_DEX_DEPOSIT_DATA_CLEANUP = 'GET_DEX_DEPOSIT_DATA_CLEANUP';

export const GET_DEX_FAST_WITHDRAW_DATA_REQUEST =
  'GET_DEX_FAST_WITHDRAW_DATA_REQUEST';
export const GET_DEX_FAST_WITHDRAW_DATA_SUCCESS =
  'GET_DEX_FAST_WITHDRAW_DATA_SUCCESS';
export const GET_DEX_FAST_WITHDRAW_DATA_FAIL =
  'GET_DEX_FAST_WITHDRAW_DATA_FAIL';
export const GET_DEX_FAST_WITHDRAW_DATA_CLEANUP =
  'GET_DEX_FAST_WITHDRAW_DATA_CLEANUP';
