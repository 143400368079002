import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useBalance } from '../../../utils/hooks';

function WalletColumn({ chartData }) {
  const [chartdata, setChartData] = useState([]);
  const [chartlabeldata, setChartLabelData] = useState([]);
  const { getCurrencyBalances } = useBalance();

  const getChartData = () => {
    if (chartData?.length > 0) {
      setChartData(
        chartData?.slice(0, 3).map((item) => {
          const value = (item?.balance * 100) / getCurrencyBalances();
          return value < 1 ? Math.ceil(value) : Math.floor(value);
        })
      );
    } else {
      setChartData([87, 75, 62]);
    }
  };

  const getLabelData = () => {
    if (chartData?.length >= 1) {
      setChartLabelData(
        chartData?.slice(0, 3).map((item) => item?.currency.toUpperCase())
      );
    } else {
      setChartLabelData(['USD', 'EUR', 'RUB']);
    }
  };

  useEffect(() => {
    getChartData();
    getLabelData();
  }, [chartData]);

  const data = {
    series: [
      {
        name: 'balance',
        data: chartdata,
      },
    ],
    options: {
      chart: {
        height: 150,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#3498db'],
        },
      },

      xaxis: {
        categories: chartlabeldata,
        position: 'top',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          // style: {
          //   fontSize: '12px',
          //   colors: ['#3498db'],
          // },
          colors: ['#fff'],
          formatter: function (val) {
            return val + '%';
          },
        },
      },
    },
  };
  return (
    <>
      {chartdata.length > 0 &&
        chartData.length > 0 &&
        chartlabeldata.length > 0 &&
        chartlabeldata[0] !== undefined &&
        !isNaN(chartdata[0]) && (
          <ReactApexChart
            options={data.options}
            series={data.series}
            type='bar'
            height={170}
            width={'100%'}
          />
        )}
    </>
  );
}

export default WalletColumn;
