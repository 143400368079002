import React from 'react';
import { Helmet } from 'react-helmet-async';
function HelmetContainer({ title, description, content }) {
  return (
    <Helmet>
      <title>{title} | Brine</title>
      <meta name={description} content={content} />
    </Helmet>
  );
}
HelmetContainer.defaultProps = {
  title: 'Brine',
  description: 'Brine',
  content: 'Brine web application',
};
export default HelmetContainer;
