import axios from 'axios';
import * as rewardsConstants from '../constants/rewardsConstant';
import { baseUrl } from '../utils/urls';

export const getReferralCode = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: rewardsConstants.GET_REFERRAL_CODE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.get(`${baseUrl}/referral/getCode/`, config);

    dispatch({
      type: rewardsConstants.GET_REFERRAL_CODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: rewardsConstants.GET_REFERRAL_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getScratchCardEarning = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: rewardsConstants.GET_REFERRAL_CODE_EARNING_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.get(
      `${baseUrl}/scratchCard/myscratch_card_earnings/`,
      config
    );

    dispatch({
      type: rewardsConstants.GET_REFERRAL_CODE_EARNING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: rewardsConstants.GET_REFERRAL_CODE_EARNING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const redeemScratchCards = async (token, code) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/referral/createReferral/`,
      { code: code },
      config
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getScratchCards = (type, page) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: rewardsConstants.GET_SCRATCH_CARD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl}/referral/list_referral_trading_fee_rewards/?status=${type}`,
      {},
      config
    );

    dispatch({
      type: rewardsConstants.GET_SCRATCH_CARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: rewardsConstants.GET_SCRATCH_CARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getNotClaimedCards = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/referral/list_referral_trading_fee_rewards/?status=Not_Claimed`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const redeemScratchCardsFromLists = async (token, code) => {
  const AuthStr = 'JWT '.concat(token);
  try {
    const { data } = await axios.get(`${baseUrl}/scratchCard/redeem/${code}/`, {
      headers: { Authorization: AuthStr },
    });
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const claimRewards = async (token, symbol, created_by) => {
  const AuthStr = 'JWT '.concat(token);
  try {
    const { data } = await axios.post(
      `${baseUrl}/referral/claim_rewards/`,
      { currency: symbol, created_by },
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const claimExternalCampaignsRewards = async (token, id) => {
  const AuthStr = 'JWT '.concat(token);
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/referral/external-rewards/claim/`,
      { reward_id: id },
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const getTotalReferrals = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: rewardsConstants.GET_TOTAL_REFERRAL_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.get(
      `${baseUrl}/referral/totalReferrals/`,
      config
    );

    dispatch({
      type: rewardsConstants.GET_TOTAL_REFERRAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: rewardsConstants.GET_TOTAL_REFERRAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getFeeInfoForClaim = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: rewardsConstants.GET_CLAIM_FEE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/referral/get_claim_trading_fee_reward_fee/`,
      {},
      config
    );

    dispatch({
      type: rewardsConstants.GET_CLAIM_FEE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: rewardsConstants.GET_CLAIM_FEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getExternalCampaignList = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: rewardsConstants.GET_EXTERNAL_CAMPAIGNS_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.get(
      `${baseUrl}/v1/referral/external-rewards/`,
      config
    );

    dispatch({
      type: rewardsConstants.GET_EXTERNAL_CAMPAIGNS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: rewardsConstants.GET_EXTERNAL_CAMPAIGNS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
