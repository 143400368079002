import { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFoundPage from '../pages/not-found/not-found';
import PrivateRoute from './privateRoutes/privateRoutes';
import { userDetails } from '../actions/userAction';
import { networkCheck } from '../actions/kycAction';
import { healthCheck } from '../actions/assestsAction';
import { AnimatePresence } from 'framer-motion';
import HelperPage from '../pages/helper-page/helper-page';
import { prefix } from '../store';
import { changeTheme } from '../actions/themeAction';
import {
  ServerDown,
  InitialScratchCard,
  FallbackLoader,
  LegacyBrowser,
  CustomButton,
  USIssue,
} from '../components';
import { isLegacyBrowser } from '../utils/browser-checker';
import { lazyRetry } from '../utils/lazy-retry';

const LazyHomePage = lazy(() =>
  lazyRetry('homeLazyComponent', () =>
    import(
      /* webpackChunkName: "homeLazyComponent" */ '../pages/homepage/homepage'
    )
  )
);

const LazyExchangePage = lazy(() =>
  lazyRetry('exchangeLazyComponent', () =>
    import(
      /* webpackChunkName: "exchangeLazyComponent" */ '../pages/exchange-page/exchange-page'
    )
  )
);

const LazyPriceDetails = lazy(() =>
  lazyRetry('priceDetailsLazyComponent', () =>
    import(
      /* webpackChunkName: "priceDetailsLazyComponent" */ '../pages/price-details/price-details'
    )
  )
);

const LazyReferralPage = lazy(() =>
  lazyRetry('referralLazyComponent', () =>
    import(
      /* webpackChunkName: "referralLazyComponent" */ '../pages/referrals-page/referrals-page'
    )
  )
);

const LazyWalletPage = lazy(() =>
  lazyRetry('walletLazyComponent', () =>
    import(
      /* webpackChunkName: "walletLazyComponent" */ '../pages/wallets-page/wallets-page'
    )
  )
);

const LazyHistoryPage = lazy(() =>
  lazyRetry('activitiesLazyComponent', () =>
    import(
      /* webpackChunkName: "activitiesLazyComponent" */ '../pages/activities-page/activities-page'
    )
  )
);

const LazyVerifyPage = lazy(() =>
  lazyRetry('verifyLazyComponent', () =>
    import(
      /* webpackChunkName: "verifyLazyComponent" */ '../pages/verify-email-page/verify-email-page'
    )
  )
);

const LazySaltPage = lazy(() =>
  lazyRetry('saltLazyComponent', () =>
    import(
      /* webpackChunkName: "saltLazyComponent" */ '../pages/salt-page/salt-page'
    )
  )
);

const LazyInformationPage = lazy(() =>
  lazyRetry('informationLazyComponent', () =>
    import(
      /* webpackChunkName: "informationLazyComponent" */ '../pages/information-page/information-page'
    )
  )
);

const LazySignInPage = lazy(() =>
  lazyRetry('signInLazyComponent', () =>
    import(
      /* webpackChunkName: "signInLazyComponent" */ '../pages/signin-page/signin-page'
    )
  )
);

const LazyLeaderBoardPage = lazy(() =>
  lazyRetry('leaderboardLazyComponent', () =>
    import(
      /* webpackChunkName: "leaderboardLazyComponent" */ '../pages/leaderboard-v2-page/leaderboard-v2-page'
    )
  )
);

function Index() {
  const { isdark } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userInfo: userRegisterInfo } = useSelector(
    (state) => state.userRegister
  );

  const [isServerDown, setIsServerDown] = useState(false);
  const [isLegacyClient, setIsLegacyClient] = useState(false);
  const [showCustomMsg, setShowCustomMsg] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isdark === true) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isdark]);

  useEffect(() => {
    if (userInfo && userInfo?.token?.access) {
      dispatch(userDetails());
      dispatch(networkCheck());
    }
    if (userRegisterInfo) {
      dispatch(userDetails());
      dispatch(networkCheck());
    }
  }, [userInfo, userRegisterInfo]);

  useEffect(() => {
    async function checkServerStatus() {
      try {
        const data = await healthCheck();
        if (data.status === 'success') {
          setIsServerDown(false);
        }
      } catch (e) {
        if (e == 451) {
          setShowCustomMsg(true);
        } else {
          setShowCustomMsg(false);
        }
        setIsServerDown(true);
      }
    }
    checkServerStatus();
  }, []);

  useEffect(() => {
    let theme = localStorage.getItem(prefix + '.theme')
      ? localStorage.getItem(prefix + '.theme')
      : null;
    if (theme == null) {
      localStorage.setItem(prefix + '.theme', 'true');
      dispatch(changeTheme());
    }
  }, []);

  useEffect(() => {
    setIsLegacyClient(isLegacyBrowser());
  }, []);

  // return (
  //   <div className='server__down'>
  //     <div className='h2'>
  //       We’re under maintenance
  //       <span>.</span>{' '}
  //     </div>
  //     <img src={images.serverDown} />
  //     {/* <p>We'll be backup on Wed 11 Jan</p> */}
  //     {/* <ul>
  //       <li>Bug fixes 🪲</li>
  //       <li>Adding rate limiter for API trading 📶.</li>
  //     </ul> */}
  //     <CustomButton
  //       title='Try again'
  //       onClick={() => window.location.reload()}
  //     />
  //   </div>
  // );

  return (
    <div className={`${isdark ? 'dark' : ''}`}>
      <>
        {!isLegacyClient ? (
          <>
            {isServerDown ? (
              // <>{showCustomMsg ? <USIssue /> : <ServerDown />}</>
              <ServerDown />
            ) : (
              <>
                <Router>
                  <Suspense fallback={<FallbackLoader />}>
                    <AnimatePresence>
                      <Switch>
                        <PrivateRoute exact path='/'>
                          <LazyHomePage />
                        </PrivateRoute>
                        {/* <PrivateRoute path='/prices'>
                    <LazyPricePage />
                  </PrivateRoute> */}
                        <PrivateRoute path='/pricedetails/:symbol'>
                          <LazyPriceDetails />
                        </PrivateRoute>
                        <PrivateRoute path='/exchange/:symbol'>
                          <LazyExchangePage />
                        </PrivateRoute>
                        <PrivateRoute path='/wallet'>
                          <LazyWalletPage />
                        </PrivateRoute>
                        {/* <PrivateRoute path="/promotion">
           <PromotionsPage />
         </PrivateRoute> */}
                        {/* <PrivateRoute path="/promotiondetails/:id">
           <PromotionsDetails />
         </PrivateRoute> */}
                        <PrivateRoute path='/History'>
                          <LazyHistoryPage />
                        </PrivateRoute>
                        {/* <PrivateRoute path="/notification">
               <NotificationPage />
             </PrivateRoute> */}
                        <PrivateRoute path='/referral'>
                          <LazyReferralPage />
                        </PrivateRoute>
                        {/* <Route path="/signup" component={Signup} /> */}
                        <Route path='/signin' component={LazySignInPage} />
                        <Route path='/verify' component={LazyVerifyPage} />
                        <Route path='/salt' component={LazySaltPage} />
                        <Route
                          path='/profile'
                          component={LazyInformationPage}
                        />
                        {/* <Route path='/simplex' component={SimplexFormPage} /> */}
                        {/* <PrivateRoute path="/payment-status">
             <PaymentStatusPage />
           </PrivateRoute> */}
                        <Route path='/help' component={HelperPage} />
                        {/* <PrivateRoute path='/leaderboard'>
                          <LazyLeaderBoardPage />
                        </PrivateRoute> */}
                        <Route
                          path='/leaderboard'
                          component={LazyLeaderBoardPage}
                        />
                        <Route component={NotFoundPage} />
                      </Switch>
                    </AnimatePresence>
                  </Suspense>
                  {/* <InitialScratchCard /> */}
                </Router>
                {/* )} */}
              </>
            )}
          </>
        ) : (
          <LegacyBrowser />
        )}
      </>
    </div>
  );
}

export default Index;
