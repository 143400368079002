// import { useState } from 'react';
import { useSelector } from 'react-redux';
// import SkeletonContainer from '../../common/skeleton/skeleton';
// import { ProTip } from '../leaderboard-protip/leaderboard-protip';
// import { UserScore } from '../leaderboard-userscore/leaderboard-userscore';
// import LeaderboardIndividualScore from '../leaderboard-individual-score/leaderboard-individual-score';
// import {
//   CampaignTimeLine,
//   getTimeDiff,
// } from '../leaderboard-campaign-time/leaderboard-campaign-time';
import { useViewport } from '../../../utils/hooks';
// import moment from 'moment';
// import { compareTimestamp } from '../../../pages/salt-page/salt-page';
// import { tempStaticTime } from '../../../utils/constants';
import { TradeInfo } from '../leaderboard-trade-details/leaderboard-trade-details';

export function UserStats({
  selectedFilter,
  userScore,
  totalPrizeAmount,
  leaderBoardUserDataLoading,
  proTip,
}) {
  return (
    <>
      <div className='your_stats_event_container'>
        <div className='trade__details_container_wrappper'>
          <TradeInfo
            selectedFilter={selectedFilter}
            totalPrizeAmount={totalPrizeAmount}
          />
        </div>
        {/* <div>
          <div className='leaderboard__title'>
            <div
              className='categories__title h5'
              style={{
                fontSize: 21,
              }}
            >
              Your stats
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div>
              {leaderBoardUserDataLoading ||
              (userInfo?.token?.access && !userScore && !proTip) ? (
                <div className='flex-container'>
                  {Array.from(Array(2).keys()).map((item, idx) => (
                    <div className='flex-items' key={idx}>
                      <SkeletonContainer
                        style={{
                          width: width > 700 ? 200 : 'calc(44vw)',
                          height: 200,
                          borderRadius: 20,
                          marginLeft: item !== 0 ? 20 : 0,
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
