import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ethers, utils } from 'ethers';
import { useSelector } from 'react-redux';
import { sanitizeProvider } from '../sanitize-provider';

export const METAMASK_POSSIBLE_ERRORS = {
  '-32700': {
    standard: 'JSON RPC 2.0',
    message:
      'Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.',
  },
  '-32600': {
    standard: 'JSON RPC 2.0',
    message: 'The JSON sent is not a valid Request object.',
  },
  '-32601': {
    standard: 'JSON RPC 2.0',
    message: 'Unfortunately, the method seems to be unavailable',
  },
  '-32602': {
    standard: 'JSON RPC 2.0',
    message: 'Invalid method parameter(s).',
  },
  '-32603': {
    standard: 'JSON RPC 2.0',
    message: 'Something went wrong; please try again',
  },
  '-32000': {
    standard: 'EIP-1474',
    message: 'Request sent was rejected. Please try again',
  },
  '-32001': {
    standard: 'EIP-1474',
    message: 'Requested resource is unavailable.',
  },
  '-32002': {
    standard: 'EIP-1474',
    message: 'Resource unavailable.',
  },
  '-32003': {
    standard: 'EIP-1474',
    message: 'Transaction has been rejected; please try again..',
  },
  '-32004': {
    standard: 'EIP-1474',
    message: 'Method not supported.',
  },
  '-32005': {
    standard: 'EIP-1474',
    message: 'Request limit exceeded',
  },
  4001: {
    standard: 'EIP-1193',
    message: 'User rejected the request.',
  },
  ACTION_REJECTED: {
    standard: 'EIP-1193',
    message: 'User rejected the request.',
  },
  4100: {
    standard: 'EIP-1193',
    message:
      'The requested account and/or method has not been authorized by the user.',
  },
  4200: {
    standard: 'EIP-1193',
    message: 'The requested method is not supported by this Ethereum provider.',
  },
  4900: {
    standard: 'EIP-1193',
    message: 'The provider is disconnected from all chains.',
  },
  4901: {
    standard: 'EIP-1193',
    message: 'The provider is disconnected from the specified chain.',
  },
};

function useMeta(coin) {
  const [connectedAccount, setConnectedAccount] = useState(null);
  const { state: userVia } = useSelector((state) => state.userVia);
  const [ethereum, setEthereum] = useState(null);

  const provider = ethereum
    ? new ethers.providers.Web3Provider(ethereum)
    : null;

  // const { ethereum } = window;

  useEffect(() => {
    sanitizeProvider(setEthereum);
  }, []);

  const checkWalletIsConnected = async () => {
    try {
      if (!ethereum) {
        return;
      }
      const account = await ethereum.send('eth_accounts');
      if (account?.result?.length > 0) {
        setConnectedAccount(utils.getAddress(account.result[0]));
      }
    } catch (e) {
      console.log(e);
      // toast.error(e.message);
    }
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) return toast.error('Please install MetaMesk');
      await ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      setConnectedAccount(address);
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  const get0X0to0X = (address) => {
    if (
      address?.substring(0, 3) === '0x0' ||
      address?.substring(0, 3) === '0X0'
    ) {
      return address.replace('0x0', '0x');
    } else {
      return address;
    }
  };

  // const getAllowance = async () => {
  //   let address = await signer.getAddress();
  //   let allowance = await contract_final.allowance(address, stark_contract);
  //   return parseInt(allowance._hex);
  // };

  const isMetaMaskConnected = async () => {
    if (ethereum) {
      let provider = new ethers.providers.Web3Provider(ethereum);
      const accounts = await provider.listAccounts();
      return accounts.length > 0;
    }
  };

  useEffect(() => {
    if (userVia && userVia?.via === 'metamask') {
      checkWalletIsConnected();
    }
  }, [userVia, ethereum]);

  return {
    connectedAccount,
    connectWallet,
    get0X0to0X,
    METAMASK_POSSIBLE_ERRORS,
    isMetaMaskConnected,
    provider,
  };
}

export default useMeta;
