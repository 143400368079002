import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance } from '../../../actions/userAction';
import {
  useBalance,
  useAnalytics,
  useWebSocketData,
  useSegment,
} from '../../../utils/hooks';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../utils/query-helper';
import {
  CustomButton,
  SkeletonContainer,
  RadialBarChart,
  Icon,
} from '../../../components';
import { baseCurrency } from '../../../utils/constants';
import { images } from '../../../utils/image/img';

function TotalBalance({ setOpenDepositModal }) {
  const [chartData, setChartData] = useState([]);
  const dispatch = useDispatch();
  const { sendTrackEvent } = useSegment();
  const location = useLocation();
  const history = useHistory();

  // const {
  // getAssests,
  // userGetBalance,
  // getCoins: { coinsDetails, coinDetailsLoading },
  // userLogin: { userInfo },
  // getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
  // } = useSelector((state) => state);
  const { getSocketFunctionalityInfo } = useWebSocketData();
  // const { loading, error, balance } = userGetBalance;

  const {
    loading: orderLoading,
    error: orderError,
    orderBookData,
  } = useSelector((state) => state.getOrderBook);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { coinsDetails, coinDetailsLoading } = useSelector(
    (state) => state.getCoins
  );
  const { loading, error, balance } = useSelector(
    (state) => state.userGetBalance
  );
  const {
    loading: assetLoading,
    error: assetError,
    assetsData,
  } = useSelector((state) => state.getAssests);

  const { getCurrencyBalances, roundUp, getChartData, formatCurrency } =
    useBalance();

  useEffect(() => {
    if (!balance) {
      dispatch(getBalance());
    }
  }, []);

  useEffect(() => {
    const data = getChartData();
    setChartData(data);
  }, [balance, loading, assetsData, orderBookData]);

  return (
    <>
      {userInfo?.token && (
        <>
          {((!getSocketFunctionalityInfo().full &&
            !getSocketFunctionalityInfo().partial) ||
            coinDetailsLoading ||
            loading) && (
            <SkeletonContainer
              width={'100%'}
              height={'400px'}
              className='box__shadow'
              style={{ borderRadius: 20, marginBottom: 30 }}
            />
          )}
          {!error &&
            balance &&
            (getSocketFunctionalityInfo().full ||
              getSocketFunctionalityInfo().partial) && (
              <div className='card card_widget text-center'>
                <button
                  className='card__next'
                  onClick={() =>
                    history.push({
                      pathname: `wallet`,
                      search: location.search,
                    })
                  }
                >
                  <Icon icon='arrow-up-right' className='icon' />
                </button>
                {balance.payload.length > 0 &&
                parseFloat(formatCurrency(getCurrencyBalances(true))) != 0.0 &&
                getCurrencyBalances(true) !== 0 ? (
                  <>
                    <div className='card__chart card__chart_total-balance'>
                      <div id='chart-total-balance'>
                        {!loading &&
                          chartData &&
                          (getSocketFunctionalityInfo().full ||
                            getSocketFunctionalityInfo().partial) && (
                            <RadialBarChart
                              chartData={chartData}
                              totalBalance={getCurrencyBalances}
                              loading={loading}
                              assetLoading={assetLoading}
                            />
                          )}
                      </div>
                    </div>
                    <div className='card__inner'>
                      <div className='card__title h6'>Total Balance</div>
                      <>
                        <div className='card__currency'>
                          {baseCurrency?.toUpperCase()}
                        </div>
                        <div
                          className={`card__number 
                        h5
                      `}
                          style={{ marginBottom: 10 }}
                        >
                          {formatCurrency(getCurrencyBalances(true))
                            ? formatCurrency(getCurrencyBalances(true))
                            : 0}{' '}
                        </div>
                        <CustomButton
                          title='Deposit'
                          onClick={() => {
                            // history.push({
                            //   pathname: `wallet`,
                            //   search: location.search,
                            // });
                            // analytics?.track('Deposit button clicked!', {
                            //   uid: userInfo?.payload?.uid,
                            //   from: 'Homepage',
                            //   QueryParams: { ...getQueryParams() },
                            //   ip: ip,
                            // });
                            sendTrackEvent('buttonClicked', {
                              title: 'Deposit',
                              action: 'Opening Deposit popup',
                            });
                            setOpenDepositModal(true);
                          }}
                        />
                      </>
                    </div>
                  </>
                ) : (
                  <div className='card__inner'>
                    <div className='card__title h6'>Total Balance</div>
                    <div className='card__number h4'>0</div>
                    <div style={{ marginTop: 12 }}>
                      <CustomButton
                        title='Deposit'
                        onClick={() =>
                          // history.push({
                          //   pathname: `wallet`,
                          //   search: location.search,
                          // })
                          setOpenDepositModal(true)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
        </>
      )}
    </>
  );
}

export default TotalBalance;
