import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { SkeletonContainer } from '../../../components';
import { getAllTrades } from '../../../actions/exchangeAction';
import { webSocketUrl } from '../../../utils/urls';

function AllTrades({ selectedCell }) {
  const { symbol } = useParams();
  const [allTradeData, setAllTradeData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [ws, setWs] = useState(null);

  // const {
  //   userLogin: { userInfo },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);

  const handleGetAllTrades = async () => {
    setLoading(true);
    try {
      const data = await getAllTrades(
        userInfo?.token?.access,
        symbol?.replace('-', '')
      );
      setAllTradeData(data?.payload);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (symbol) {
      handleGetAllTrades();
    }
  }, [symbol]);

  const establishWsConnection = () => {
    let ws = new ReconnectingWebSocket(`${webSocketUrl}/public`);
    setWs(ws);
  };

  const getAllTradeWS = () => {
    ws.onopen = function () {
      //Subscribe to the channel
      ws.send(
        JSON.stringify({
          event: 'subscribe',
          streams: [`${symbol?.replace('-', '')}.trades`],
        })
      );
    };

    ws.onmessage = (msg) => {
      let res = JSON.parse(msg.data);
      let state = allTradeData;
      if (res[`${symbol?.replace('-', '')}.trades`]) {
        const response = res[`${symbol?.replace('-', '')}.trades`];
        let objIndex = state?.findIndex((obj) => {
          if (obj.tid) {
            return obj.tid == response.trades[0]?.tid;
          } else {
            return obj.id == response.trades[0]?.id;
          }
        });
        if (objIndex != -1) {
          state[objIndex] = response.trades[0];
          setAllTradeData(state);
        } else {
          state?.unshift(response.trades[0]);
          setAllTradeData(state);
        }
      }

      ws.onclose = () => {
        setTimeout(() => {
          getAllTradeWS();
        }, 3000);
      };

      ws.onerror = (err) => {
        console.error(
          'Socket encountered error: ',
          err.message,
          'Closing socket'
        );
        setTimeout(() => {
          getAllTradeWS();
        }, 3000);
      };

      ws.onerror = function (err) {
        console.log(err);
      };
    };
  };

  useEffect(() => {
    if (!loading) {
      establishWsConnection();
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [symbol, loading]);

  useEffect(() => {
    if (symbol && ws && !loading) {
      getAllTradeWS();
    }
  }, [symbol, ws, allTradeData, loading]);

  const dateConversion = (unixTime) => {
    const date = new Date(unixTime * 1000);
    return date.toLocaleTimeString('en-US').replace('PM', '').replace('AM', '');
  };

  return (
    <>
      {loading ? (
        <SkeletonContainer
          height={'300px'}
          width={'100%'}
          style={{ borderRadius: '10px' }}
        />
      ) : (
        <div className='orders__table'>
          <div className='orders__row orders__row_head'>
            <div className='orders__cell'>AMOUNT ({selectedCell.symbol})</div>
            <div className='orders__cell'>
              PRICE ({symbol?.split('-')[1].toUpperCase()})
            </div>
            <div className='orders__cell'>Time</div>
            {/* <div className="orders__cell">MINE</div> */}
          </div>
          <div className='table_height_control order_table all_trade'>
            {allTradeData?.map((data, idx) => {
              return (
                <div className='orders__row' key={idx}>
                  <div
                    className={`orders__cell ${
                      data?.taker_type !== 'sell'
                        ? 'orders__down'
                        : 'orders__up'
                    } `}
                  >
                    {data.amount}
                    <div
                      className='orders__bg'
                      style={{ width: `1000%` }}
                    ></div>
                  </div>
                  <div className='orders__cell'>{data.price}</div>
                  <div className='orders__cell'>
                    {dateConversion(
                      data.created_at ? data.created_at : data.date
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default AllTrades;
