import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { images } from '../../../utils/image/img';
import {
  Image,
  MetaPopup,
  Toast,
  Icon,
  CustomButton,
  Popup,
} from '../../../components';
import { useSegment } from '../../../utils/hooks';
import {
  ENABLE_WALLET_CONNECT_V2,
  ENVIRONMENT,
} from '../../../utils/constants';

const WalletOptions = ({
  title,
  value,
  image,
  selectedOption,
  setSelectedOption,
  setLoginState,
  setOpenWalletConnectV2Prompt,
  openWalletConnectV2Prompt,
}) => {
  const [socialImg, setSocialImg] = useState(null);
  const { analytics, sendTrackEvent } = useSegment();

  const socialMediaImg = [
    images.google,
    images.facebook,
    images.twitch,
    images.reddit,
    images.discord,
    images.twitter,
  ];

  useEffect(() => {
    const randomImgGen = setInterval(() => {
      const random = Math.floor(Math.random() * socialMediaImg.length);
      setSocialImg(socialMediaImg[random]);
    }, 2500);
    return () => {
      clearInterval(randomImgGen);
    };
  }, []);

  return (
    <div
      className={`select_wallet_item ${
        selectedOption == title ? 'active' : ''
      }`}
      onClick={() => {
        if (title !== 'WalletConnect') {
          setSelectedOption(title);
          sendTrackEvent('walletOptionSelected', { selectedWallet: title });
          localStorage.setItem('user_via', title);
          setLoginState('2');
        } else {
          setSelectedOption('WalletConnect V2');
          sendTrackEvent('walletOptionSelected', {
            selectedWallet: 'WalletConnect V2',
          });
          localStorage.setItem('user_via', 'WalletConnect V2');
          setLoginState('2');
        }
      }}
    >
      {/* {title !== 'WalletConnect' ? null : (
        <span
          style={{
            position: 'absolute',
            right: 10,
            top: 7,
            padding: 10,
            zIndex: 100,
          }}
          onClick={() => setOpenWalletConnectV2Prompt(true)}
        >
          <i className='fas fa-ellipsis-h wallet__connect_v2_icon'></i>
        </span>
      )} */}
      <div
        className='select_wallet_img'
        onClick={() => {
          if (title === 'WalletConnect') {
            // setSelectedOption(title);
            // sendTrackEvent('walletOptionSelected', { selectedWallet: title });
            // localStorage.setItem('user_via', title);
            // setLoginState('2');

            setSelectedOption('WalletConnect V2');
            sendTrackEvent('walletOptionSelected', {
              selectedWallet: 'WalletConnect V2',
            });
            localStorage.setItem('user_via', 'WalletConnect V2');
            setLoginState('2');
          }
        }}
      >
        <Image
          src={
            title == 'Social Login'
              ? socialImg
                ? socialImg
                : images.google
              : image
          }
          alt={title}
          // className={`${title == 'Social Login' ? 'blink-image' : ''}`}
          style={{
            width: title == 'Social Login' ? 30 : 20,
            height: title == 'Social Login' ? 30 : 20,
          }}
        />
      </div>
      <span
      // onClick={() => {
      //   if (title === 'WalletConnect') {
      //     setSelectedOption('WalletConnect V2');
      //     sendTrackEvent('walletOptionSelected', {
      //       selectedWallet: 'WalletConnect V2',
      //     });
      //     localStorage.setItem('user_via', 'WalletConnect V2');
      //     setLoginState('2');
      //   }
      // }}
      >
        {value}
      </span>
    </div>
  );
};

// const SelectLabel = ({ image, name }) => {
//   return (
//     <div>
//       <Image
//         src={image}
//         alt={name}
//         style={{ width: 30, height: 30, marginRight: 10 }}
//       />
//       {name}
//     </div>
//   );
// };

function SelectWallet({ setSelectedOption, selectedOption, setLoginState }) {
  const [openInstructionPopUp, setOpenInstructionPopUp] = useState(false);
  const [openWalletConnectV2Prompt, setOpenWalletConnectV2Prompt] =
    useState(false);
  const { sendTrackEvent } = useSegment();

  const history = useHistory();
  const location = useLocation();

  const walletOptions = [
    {
      title: 'Metamask',
      value: 'Metamask',
      image: images.metamaskSm,
    },
    {
      title: 'Coinbase Wallet',
      value: 'Coinbase Wallet',
      image: images.walletLink,
    },
    {
      title: 'WalletConnect',
      value: 'WalletConnect',
      image: images.walletConnect,
    },
    // {
    //   title: 'Social Login',
    //   image:
    //     'https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png',
    // },
  ];

  // if (ENABLE_WALLET_CONNECT_V2) {
  //   walletOptions.push({
  //     title: 'WalletConnect V2',
  //     value: 'WalletConnect V2',
  //     image: images.walletConnect,
  //   });
  // }

  return (
    <div className='login__col'>
      <div className='login__form'>
        <div
          className='login__stage h4'
          style={{ textAlign: 'center', marginBottom: '16px' }}
        >
          Sign in to Brine Fi
          <span
            className='help__icon_signin'
            onClick={() =>
              history.push({ pathname: '/help', search: location.search })
            }
          >
            <Icon icon='info-circle' className='icon' />
          </span>
        </div>
        <div className='login__stage h6' style={{ textAlign: 'center' }}>
          Select your wallet to continue
        </div>
        <Toast />
        <div className='select_wallet__container'>
          {walletOptions.map((item) => {
            return (
              <WalletOptions
                key={item.title}
                title={item.title}
                image={item.image}
                value={item.value}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setLoginState={setLoginState}
                setOpenWalletConnectV2Prompt={setOpenWalletConnectV2Prompt}
                openWalletConnectV2Prompt={openWalletConnectV2Prompt}
              />
            );
          })}
        </div>
        {/* <div
          className="categories__select mobile-show"
          style={{ marginBottom: 40, marginTop: '10%' }}
        >
          <CustomSelect
            options={selectWalletOptions}
            dynamicHeight={true}
            onChange={(data) => {
              setSelectedOption(data.value);
              localStorage.setItem('user_via', data.value);
            }}
          />
        </div> */}
        {/* <AuthButton
          title="Click here to continue &#8594;"
          disabled={!selectedOption}
          onClick={() => setLoginState('2')}
        /> */}
        <div style={{ display: 'grid', placeContent: 'center' }}>
          <div className='mpc__wallet_container'>
            <div style={{ marginRight: 0, marginTop: 3 }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                fill='none'
                viewBox='0 0 24 24'
              >
                <path
                  fill='#8173E0'
                  d='M13 16a1 1 0 11-2 0 1 1 0 012 0z'
                ></path>
                <path
                  stroke='#8173E0'
                  strokeLinecap='round'
                  strokeWidth='1.5'
                  d='M12 7v6m10-1c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'
                ></path>
              </svg>
            </div>
            <div>
              <p>
                Currently, Brine doesn’t support NON deterministic and MPC
                wallets.
              </p>
            </div>
          </div>
        </div>

        <div className='login__flex'>
          <div className='login__text'>
            Don't have a wallet ?{' '}
            <Link
              className='login__link'
              onClick={() => setOpenInstructionPopUp((prev) => !prev)}
              to={(location) => ({ ...location, pathname: '/signin' })}
            >
              {'  '} Click Here
            </Link>
          </div>
        </div>

        {/* <WarnBox style={{ marginTop: 15 }}> */}
        {/* </WarnBox> */}
        <div
          className='login__note'
          style={{ marginTop: 20, fontFamily: 'Poppins', textAlign: 'center' }}
        >
          By logging in with any of the options above, you acknowledge that you
          have read, understood, and agree to the{' '}
          <a href='https://www.brine.fi/legal' rel='noreferrer' target='_blank'>
            Terms and Privacy policy
          </a>
        </div>
      </div>

      <MetaPopup
        openInstructionPopUp={openInstructionPopUp}
        setOpenInstructionPopUp={setOpenInstructionPopUp}
      />
      <div className='share__popup network__error'>
        <Popup
          state={openWalletConnectV2Prompt}
          setState={setOpenWalletConnectV2Prompt}
        >
          <div className='h6' style={{ marginBottom: 40 }}>
            Connect with V1 instead
          </div>
          <Image src={images.walletConnectV2} />
          <p
            className='wallet__connect_warning_text'
            style={{
              marginTop: '22px',
              maxWidth: 312,
            }}
          >
            {/* Please note that WalletConnect V2 is <span>under development</span>{' '}
            and is <span>unsupported</span> by many wallets. */}
            Support for v1 will be discontinued <span>June 28</span>.
            <br />{' '}
            <a
              href='https://medium.com/walletconnect/walletconnect-v2-0-protocol-whats-new-3243fa80d312'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more
            </a>
          </p>
          <CustomButton
            title='Use WalletConnect V1 anyway'
            addClassName='disconnect_btn'
            onClick={() => {
              // setSelectedOption('WalletConnect V2');
              // sendTrackEvent('walletOptionSelected', {
              //   selectedWallet: 'WalletConnect V2',
              // });
              // localStorage.setItem('user_via', 'WalletConnect V2');
              // setLoginState('2');
              setSelectedOption('WalletConnect');
              sendTrackEvent('walletOptionSelected', {
                selectedWallet: 'WalletConnect',
              });
              localStorage.setItem('user_via', 'WalletConnect');
              setLoginState('2');
            }}
          />
        </Popup>
      </div>
    </div>
  );
}

export default SelectWallet;
