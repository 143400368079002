import { OPEN_SETTING } from '../constants/settingConstant';
import * as settingConstant from '../constants/settingConstant';
import axios from 'axios';
import { baseUrl } from '../utils/urls';

export const toggleSetting = (state) => (dispatch) => {
  dispatch({
    type: OPEN_SETTING,
    payload: state,
  });
};
