import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SkeletonContainer from '../../common/skeleton/skeleton';
import { ProTip } from '../leaderboard-protip/leaderboard-protip';
import { UserScore } from '../leaderboard-userscore/leaderboard-userscore';
import LeaderboardIndividualScore from '../leaderboard-individual-score/leaderboard-individual-score';
import {
  CampaignTimeLine,
  getTimeDiff,
  getTimeDiffWithExtra24Hours,
} from '../leaderboard-campaign-time/leaderboard-campaign-time';
import { useViewport } from '../../../utils/hooks';
import moment from 'moment';
import { compareTimestamp } from '../../../pages/salt-page/salt-page';
import { tempStaticTime } from '../../../utils/constants';
import Icon from '../../common/icons/icons';
import ReactTooltip from 'react-tooltip';

let timeFunc = {
  active: getTimeDiff,
  inactive: getTimeDiffWithExtra24Hours,
};

export const TradeInfo = ({ selectedFilter, totalPrizeAmount }) => {
  const { isdark } = useSelector((state) => state.theme);

  if (selectedFilter?.isActive == null) {
    return (
      <div className={'trade__details_container'}>
        <SkeletonContainer style={{ height: 150 }} />
      </div>
    );
  }

  const timeDiff =
    getTimeDiff(tempStaticTime).hours + getTimeDiff(tempStaticTime).days * 24;

  return (
    <>
      {selectedFilter?.isActive ? (
        <div className={'trade__details_container'}>
          {/* <div className='trade__details_container__wrappper_inner'>
            <div className='h5'>Trading Competition is live 🚀</div>
            <div className='event_timeline trade__details_container__timeline'>
              Ends on {moment(selectedFilter?.end_time).format('MMM Do')}
            </div>
          </div> */}
          {/* <ul>
            <li>
              📈 Trade on{' '}
              <span>
                {selectedFilter?.markets?.join(', ')?.toUpperCase() + '.'}
              </span>{' '}
            </li>
            <li>🦾 Higher the volume, higher the chances of winning</li>
            <li>
              🏆 Win from prize pool of{' '}
              <span>${totalPrizeAmount || '---'} USDC</span>
            </li>
          </ul> */}

          <div className='trade__details_inner_container'>
            <div className='trade__details_text_container trade__details_title'>
              <div className='trade__details_title'>
                {selectedFilter?.label}
              </div>
              <div className='trade__details_description'>
                <span style={{ color: '#808191', fontSize: 14 }}>Ends in</span>{' '}
                {timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                  selectedFilter?.end_time
                ).days > 0
                  ? timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                      selectedFilter?.end_time
                    ).days
                  : 0}
                <span className='timeline'>d</span>{' '}
                {timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                  selectedFilter?.end_time
                ).hours > 0
                  ? timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                      selectedFilter?.end_time
                    ).hours
                  : 0}
                <span className='timeline'>h</span>{' '}
                {timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                  selectedFilter?.end_time
                ).minutes > 0
                  ? timeFunc[selectedFilter?.isActive ? 'active' : 'inactive'](
                      selectedFilter?.end_time
                    ).minutes
                  : 0}
                <span className='timeline'>m</span>
              </div>
            </div>
            <div className='trade__details_text_container'>
              <div className='trade__details_title'>Markets</div>
              <div className='trade__details_description'>
                {/* {selectedFilter?.markets?.join(', ')?.toUpperCase() + '.'}
                 */}
                {selectedFilter?.markets?.map((pair, index) => (
                  <React.Fragment key={pair}>
                    {pair?.toUpperCase()}{' '}
                    {index < selectedFilter?.markets.length - 1 && (
                      <span style={{ color: '#808191' }}>|</span>
                    )}{' '}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className='trade__details_text_container'>
              <div className='trade__details_title'>Prize Pool</div>
              <div className='trade__details_description'>
                {totalPrizeAmount || '---'} USDC
              </div>
            </div>
            <div className='trade__details_text_container'>
              <div className='trade__details_title'>
                Minimum trading amount
                <span
                  data-tip={`Trade a minimum of ${
                    selectedFilter?.extras?.required_campaign_score || '---'
                  } USDC to enter the competition`}
                  data-for={'leaderboard__minimum_amount'}
                >
                  <Icon
                    icon='info-circle'
                    className='icon'
                    // color={isdark ? '#fff' : '#000'}
                  />
                  <ReactTooltip
                    place='bottom'
                    type={isdark ? 'light' : 'dark'}
                    className='tooltip_styles price_details'
                    id={'leaderboard__minimum_amount'}
                  />
                </span>
              </div>
              <div className='trade__details_description'>
                {selectedFilter?.extras?.required_campaign_score || '---'} USDC
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={'trade__details_container'}>
          {/* <div className='trade__details_container__wrappper_inner'>
            {compareTimestamp(tempStaticTime) ? (
              <div className='h5'>
                <span className='color-red'>ENDED</span> | New event starts in ~{' '}
                {timeDiff} {timeDiff === 1 ? 'hour' : 'hours'}
              </div>
            ) : (
              <div className='h5'>
                <span className='color-red'>INACTIVE</span> | Event has ended!
              </div>
            )}

            <div className='event_timeline trade__details_container__timeline'>
              Ended on {moment(selectedFilter?.end_time).format('MMM Do')}
            </div>
          </div> */}
          {/* <ul>
            <li>🦾 Higher the volume, higher the chances of winning</li>
            <li>
              🏆 New sprint with 2x prize pool <span>$2000 USDC</span>{' '}
            </li>
            <li>
              ⏱ Duration: <span>12 days</span>
            </li>
          </ul> */}
          <div className='trade__details_inner_container'>
            <div className='trade__details_text_container trade__details_title'>
              <div className='trade__details_title'>
                {selectedFilter?.label}
              </div>
              <span className='color-red'>ENDED</span>
            </div>
            <div className='trade__details_text_container'>
              <div className='trade__details_title'>Markets</div>
              <div className='trade__details_description'>
                {/* {selectedFilter?.markets?.join(', ')?.toUpperCase() + '.'}
                 */}
                {selectedFilter?.markets?.map((pair, index) => (
                  <React.Fragment key={pair}>
                    {pair?.toUpperCase()}{' '}
                    {index < selectedFilter?.markets.length - 1 && (
                      <span style={{ color: '#808191' }}>|</span>
                    )}{' '}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className='trade__details_text_container'>
              <div className='trade__details_title'>Prize Pool</div>
              <div className='trade__details_description'>
                {totalPrizeAmount || '---'} USDC
              </div>
            </div>
          </div>
        </div>
      )}{' '}
    </>
  );
};
