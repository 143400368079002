/* global BigInt */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../../common/popup/popup';
import DepositFormInput from './deposit-input';
import DepositDropDown from './deposit-dropdown';
import {
  useAnalytics,
  useBalance,
  useMeta,
  useSegment,
  useStarknet,
  useWalletMethods,
  useWebSocketData,
} from '../../../utils/hooks';
import { useWeb3React } from '@web3-react/core';
import { useLocation, useHistory } from 'react-router-dom';
import {
  baseCurrency,
  MAX_LENGTH_ALLOWED_IN_NUM_INPUT,
  ALLOWED_NETWORK_ID,
  ALLOWED_NETWORK_NAME,
  ENVIRONMENT,
  ALLOWED_POLYGON_NETWORK_ID,
  ALLOWED_STARKNET_NETWORK_ID,
  ALLOWED_SCROLL_NETWORK_ID,
  WITHDRAWAL_FEE_DECIMAL_POINTS,
  staticMsg,
} from '../../../utils/constants';
import { walletTextAndLogo } from '../../header/header';
import Image from '../img/image';
import { getQueryParams } from '../../../utils/query-helper';
import {
  crossChainDepositStart,
  cryptoDepositStart,
  getVaultId,
  handleInitaiteLayerSwapSave,
  handleInitaiteLayerSwapTransaction,
} from '../../../actions/walletAction';
import animationData from '../../../assests/json/done.json';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import {
  logout,
  getBalance as getUserBalance,
  changeCurrentNetworkState,
} from '../../../actions/userAction';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import { getDEXDepositsData } from '../../../actions/historyAction';
import Icon from '../icons/icons';
import { InsufficientBalanceBtn } from '../../exchange/exchange-action/exchange-action';
import CustomButton from '../custom-button/custom-button';
import WarnBox from '../warn-box/warn-box';
import Loader from '../loader/loader';
import SucccessLottie from '../success-lottie/success-lottie';
import { DepositLoader } from '../../wallets/wallet-deposit/wallet-deposit';
import ReconnectingWebSocket from 'reconnecting-websocket';
import DepositLinkText from './deposit-link-text';
import { StepperItem } from '../../wallets/wallet-withdraw/wallet-withdraw';
import { sanitizeProvider } from '../../../utils/sanitize-provider';
import PurpleCard from './purple-card';
import { etherscanDomain } from '../../../utils/urls';
import { numberInputOnWheelPreventChange } from '../../../utils/numberPrevent';
import { images } from '../../../utils/image/img';
import { networkMap, networkOptions } from '../../../utils/hooks/useWallets';
import LoadingBtn from '../loading-btn/loading-btn';
import StarknetConnectPopup from '../starknet-connect-popup/starknet-connect-popup';
import { useAccount, useConnectors, useNetwork } from '@starknet-react/core';
import { getLayerSwapQuote } from '../../../actions/walletAction';
import { truncate } from '../../../utils/string-methods';
import IconBtn from '../icon-text/icon-text';
import { getDecimalJsInstance } from '../../../utils/decimal';
import { getBannerDetails } from '../../../actions/assestsAction';

const SuccessEtherscanMsg = ({ closeToast, symbol, link }) => {
  return (
    <div style={{ fontFamily: 'Poppins' }}>
      {/* <p className='font-bold'>Tx: {res?.deposit_blockchain_hash?.slice(0, 20)}...</p> */}
      <p>Deposit has been succesfuly processed.</p>
      <a href={link} target='_blank' style={{ color: '#6c5dd3' }}>
        <i className='text-indigo-600 underline'>See Tx Details</i>
      </a>
    </div>
  );
};

function DepositPopup({
  state,
  setState,
  selectedSymbol,
  priority,
  setPriority,
  isWalletPage,
}) {
  const [selectedData, setSelectData] = useState(null);
  const [populatedList, setPopulatedList] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const {
    getInfoAboutCoins,
    isDecimal,
    removeTrailingZeros,
    toFixedTrunc,
    truncateToDecimalPlaces,
  } = useBalance();
  const { state: currentNetworkData } = useSelector((s) => s.ethNetworkDetails);
  const location = useLocation();
  const history = useHistory();
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const [askedAllowance, setAskedAllownace] = useState(0);
  const [openAllowancePopup, setOpenAllowancePopup] = useState(false);
  const [showGasFeeWarning, setShowGasFeeWarning] = useState(false);
  const [confirmDepositPopup, setConfirmDepositPopup] = useState(false);
  const [depositSuccess, setDepositSuccess] = useState(false);
  const [metaBalance, setMetaBalance] = useState(null);
  const [depositRes, setDepositRes] = useState(null);
  const context = useWeb3React();
  const { lastPrice } = useWebSocketData();
  const { preventScientific } = useBalance();
  const [depositLoading, setDepositLoading] = useState(true);
  const [feeAmount, setFeeAmount] = useState(0);
  const [layerFeeAmount, setLayerSwapFeeAmount] = useState('---');
  const [layerFeeAmountError, setLayerSwapFeeAmountError] = useState(false);
  const [layerSwapFeeObj, setLayerSwapFeeObj] = useState(null);
  const [starkBalance, setStarkBalance] = useState(0);
  const [isLayerSwapDepositLoading, setIsLayerSwapDepositLoading] =
    useState(false);
  const [feeAmountError, setFeeAmountError] = useState(null);
  const [userAllowance, setUserAllowance] = useState(null);
  const [disableAllowanceBtn, setDisableAllowanceBtn] = useState(false);
  const [showProcessAllowancePopup, setProcessAllowancePopup] = useState(false);
  const [txBlockNumber, setTxBlockNumber] = useState();
  const [startFetch, setStartFetch] = useState(false);
  const [allowanceLoading, setAllowanceLoading] = useState(false);
  const [ethereumCoinData, setEthereumCoinData] = useState([]);
  const [polygonCoinData, setPolygonCoinData] = useState([]);
  const [scrollCoinData, setScrollCoinData] = useState([]);
  const [optimismCoinData, setOptimismCoinData] = useState([]);
  const [nativeBalanceOfCoin, setNativeBalanceOfCoin] = useState(null);
  const [starknetCoinData, setStarknetCoinData] = useState([]);
  const { analytics, sendTrackEvent } = useSegment();
  const [initialChainId, setInitalChainId] = useState(null);
  const [tempNetworkDetails, setTempNetworkData] = useState(null);
  const [requestedNetwork, setRequestedNetwork] = useState(null);
  // loading states
  const [isFetchAllowanceLoading, setIsFetchAllowanceLoading] = useState(true);
  const [isFetchNetworkFeeLoading, setIsFetchNetworkFeeLoading] =
    useState(true);
  const [openStarknetPopup, setOpenStarknetPopup] = useState(false);
  const [isManualStarknetConnect, setisManualStarknetConnect] = useState(false);
  const [isFetchBalanceLoading, setisFetchBalanceLoading] = useState(true);
  const { chain } = useNetwork();
  const { getBalance: getStarknetBalance, executeTransaction } = useStarknet();
  const { connect, connectors, disconnect } = useConnectors();
  const [filteredCoinByNetwork, setFilterCoinByNetwork] = useState([]);
  const {
    connector,
    provider: library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error: contextError,
    isActive,
    isActivating,
  } = context;

  // const {
  // userLogin: { userInfo },
  // userKey: { state: userKey },
  // vaultList: { state: vaultListState },
  // userVia: { state: userVia },
  // userGetBalance,
  // userIpAddress: { ip },
  // theme: { isdark },
  // web3AuthProvider: { provider: providerWeb3AuthGlobalState },
  // getCoins: { loading: coinsLoading, error: coinsError, coinsDetails },
  // dexDepositData,
  // depositDetails: { details },
  // userDepositState: {
  //   state: depositLevelState,
  //   loading: depositStateLoading,
  //   error: depositStateError,
  // },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { state: userKey } = useSelector((state) => state.userKey);
  const { state: userVia } = useSelector((state) => state.userVia);
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const { response: depositResponse } = useSelector(
    (state) => state.dexDepositData
  );
  const { details } = useSelector((state) => state.depositDetails);
  const { state: depositLevelState } = useSelector(
    (state) => state.userDepositState
  );

  const [isUserHasVerified, setIsUserHasVerified] = useState(false);
  const [isUserHasVerifiedLoading, setIsUserHasVerifiedLoading] =
    useState(false);
  const {
    account: starknetAccount,
    address: starknetAddress,
    status,
  } = useAccount();

  // const { response: depositResponse } = dexDepositData;
  const dispatch = useDispatch();
  const [activatingConnector, setActivatingConnector] = useState();
  const { get0X0to0X, METAMASK_POSSIBLE_ERRORS, connectedAccount } = useMeta(
    selectedData?.symbol
  );
  const {
    response: userPermissionFlagResponse,
    error: userPermissionFlagError,
    loading: userPermissionFlagLoading,
  } = useSelector((state) => state.userPermission);

  const {
    startDeposit,
    getAllowance: getAllowance,
    approveUnlimitedAllowance,
    getBalance,
    getGasFeev2,
    getBrineCrossChainGasFeeV2,
    capitalize,
    getSingleCoinAllowance,
    waitAddon,
    switchChain,
    addNetwork,
    startBrineCrossChainDeposit,
    networkConfig,
    getPopulatedData,
    getBalanceV2,
    getNetworkOptionByName,
    getCoinListByNetworkName,
    getAllowedDepositCoinListByNetwork,
    signEVMTransaction,
    dequantize,
  } = useWalletMethods(selectedData?.symbol);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    const queryParams = getQueryParams();
    const invalidParams = ['order_id', 'mid', 'status', 'message'];
    const validParams = {};
    if (Object.keys(queryParams).length != 0 && location.search != '') {
      for (const key in queryParams) {
        const InvalidKey = invalidParams.find((item) => item == key);
        if (InvalidKey) {
          delete queryParams[InvalidKey];
        } else {
          validParams[key] = queryParams[key];
        }
      }
      setQueryParams(validParams);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObj = getQueryParams();
    const invalidParams = ['order_id', 'mid', 'status', 'message'];
    if (Object.keys(queryParamsObj).length != 0 && location.search != '') {
      for (const key in queryParamsObj) {
        const InvalidKey = invalidParams.find((item) => item == key);
        if (InvalidKey) {
          queryParams.delete(InvalidKey);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [location.search]);

  const handleGetVaultId = async (symbol) => {
    sendTrackEvent('vaultIdRequestInitiated');
    try {
      const data = await getVaultId(userInfo?.token?.access, symbol);
      sendTrackEvent('vaultIdRequestSuccess', {
        coin: selectedData?.name,
        network: selectedData?.network,
      });
      return data;
    } catch (error) {
      sendTrackEvent('vaultIdRequestFailure', {
        coin: selectedData?.name,
        network: selectedData?.network,
        error,
      });
      console.log(error);
    }
  };

  const handleChangeNetworkInternally = (queuedCoin, network, chainId) => {
    dispatch(
      changeCurrentNetworkState({
        queuedCoin,
        network,
        loading: false,
        chainId,
      })
    );
  };

  const handleCryptoDeposit = async () => {
    if (!userPermissionFlagResponse?.payload?.brine_permissions?.can_deposit) {
      toast.error('The requested feature is currently unavailable.');
      return;
    }

    if (!account) {
      toast.error('Your session has expired; please log in again', {
        toastId: 'session_logout',
      });
      setTimeout(() => {
        dispatch(logout(true));
      }, 2000);
      return;
    }

    if (cryptoAmount <= 0) {
      return toast.error('Amount should be greater than zero');
    }

    if (selectedData?.network !== selectedNetwork?.network) {
      return toast.error(
        `${selectedData?.name} is not supported on the ${selectedNetwork?.value} network. Please switch to the appropriate network.`
      );
    }

    if (
      countDecimals(cryptoAmount) >
      Number(getInfoAboutCoins(selectedData?.symbol)?.quanitization)
    ) {
      toast.error(
        `Max decimal places allowed is only "${
          getInfoAboutCoins(selectedData?.symbol)?.quanitization
        }"`
      );
      return;
    }

    let maximumAmount =
      Math.pow(
        10,
        Number(getInfoAboutCoins(selectedData?.symbol)?.quanitization) + 1
      ) - 1;

    if (maximumAmount <= cryptoAmount) {
      toast.error('Please enter lesser amount ');
      return;
    }

    const vaultRes = await handleGetVaultId(selectedData?.symbol);
    const vaultId = vaultRes?.payload?.id;
    if (!vaultId) {
      toast.error('Something went wrong; please try again later');
      return;
    }

    if (!isActive && !isActivating && userVia?.via !== 'web3_auth') {
      toast.error('Your session has expired; please log in again');
      dispatch(logout(true));
    }

    let allowanceAmount;

    if (selectedData?.symbol !== 'eth') {
      allowanceAmount = await getAllowance(
        selectedData?.symbol,
        currentNetworkData?.network
      );
    }

    if (selectedData?.symbol === 'eth' && metaBalance == cryptoAmount) {
      return toast.error('Your blockchain wallet has insufficient balance');
    }

    let stark_public_key = getKeyPairFromSignature(userKey).getPublic().getX();
    const finalKey = ethers.BigNumber.from(
      stark_public_key.toString()
    ).toHexString();

    if (
      parseFloat(allowanceAmount) < parseFloat(cryptoAmount) &&
      selectedData?.symbol !== 'eth'
    ) {
      setAskedAllownace(allowanceAmount);
      setOpenAllowancePopup(true);
    } else {
      if (Number(metaBalance) < cryptoAmount) {
        return toast.error('Your blockchain wallet has insufficient balance');
      }
      setState(false);
      setConfirmDepositPopup(true);
      setTimeout(async () => {
        try {
          sendTrackEvent('depositProcessingStarted', {
            coin: selectedData?.name,
            network: selectedData?.network,
          });
          let res;
          if (currentNetworkData?.network !== 'ethereum') {
            res = await startBrineCrossChainDeposit(
              selectedData,
              cryptoAmount.toString()
            );
          } else {
            res = await startDeposit(
              finalKey,
              vaultId,
              cryptoAmount.toString()
            );
          }

          sendTrackEvent('depositProcessingCompleted', {
            coin: selectedData?.name,
            network: selectedData?.network,
          });

          if (
            currentNetworkData?.network === 'polygon' ||
            currentNetworkData?.network === 'optimism'
          ) {
            res['deposit_blockchain_hash'] = res?.hash;
          }

          setDepositRes(res);

          try {
            sendTrackEvent('depositSaveInitiated', {
              coin: selectedData?.name,
              network: selectedData?.network,
            });
            if (currentNetworkData?.network === 'ethereum') {
              const _ = await cryptoDepositStart(
                userInfo.token.access,
                res.amount.toString(),
                get0X0to0X(res.token_id.toString()),
                get0X0to0X(res.stark_key.toString()),
                res.deposit_blockchain_hash.toString(),
                res.deposit_blockchain_nonce.toString(),
                res.vault_id.toString()
              );
            } else {
              const _ = await crossChainDepositStart(
                userInfo.token.access,
                cryptoAmount?.toString(),
                selectedData?.symbol,
                res.hash.toString(),
                res.nonce.toString(),
                selectedData?.network
              );
            }

            toast.success(
              <SuccessEtherscanMsg
                link={`
              ${
                networkConfig[currentNetworkData?.network].blockExplorerUrl
              }tx/${res?.deposit_blockchain_hash?.toString()}
              `}
              />,
              {}
            );

            setDepositSuccess(true);
            dispatch(getDEXDepositsData(1));
            setConfirmDepositPopup(false);
            setCryptoAmount('');
            setTimeout(() => {
              getWalletBalance();
            }, 5000);
            setTxBlockNumber(null);
            toast.dismiss('deposit_loading');
            sendTrackEvent('depositSaveSuccess', {
              coin: selectedData?.name,
              network: selectedData?.network,
              amount: String(cryptoAmount),
            });
          } catch (error) {
            setConfirmDepositPopup(false);
            toast.error(error?.message);
            sendTrackEvent('depositSaveFailure', {
              coin: selectedData?.name,
              error: error,
              network: selectedData?.network,
            });
          }
        } catch (e) {
          if (e?.code === 'ACTION_REJECTED' || e?.code === 4001) {
            sendTrackEvent('depositProcessingUserAbort', {
              coin: selectedData?.name,
              msg: e,
            });
          } else {
            sendTrackEvent('depositProcessingFailed', {
              coin: selectedData?.name,
              error: e,
              network: selectedData?.network,
            });
          }
          setConfirmDepositPopup(false);
          reset();
          if (userVia?.via !== 'web3_auth') {
            toast.error(
              e?.code
                ? METAMASK_POSSIBLE_ERRORS[e?.code]?.message
                : e?.reason
                ? capitalize(e?.reason)
                : capitalize(e.message)
            );
          } else {
            toast.error(e.message);
          }
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (currentNetworkData?.chainId) {
      setInitalChainId(currentNetworkData?.chainId);
    }
  }, [currentNetworkData?.chainId]);

  const findItembySymbol = (val) => {
    let res = populatedList?.find((item) => item.value === val);
    return res;
  };

  const handleInterSwitchCoin = () => {
    // let allowedPolygonList =
    //   details?.payload?.network_config?.POLYGON?.allowed_tokens_for_deposit;
    // let allowedScrollList =
    //   details?.payload?.network_config?.SCROLL?.allowed_tokens_for_deposit;
    // let allowedEthereumList = coinsDetails?.convertedArray.map(
    //   (item) => item.symbol
    // );
    let allowedList = getAllowedDepositCoinListByNetwork(
      tempNetworkDetails?.to?.network
    );

    // getAllowedCrossChainCoinList(
    //   tempNetworkDetails?.to?.network
    // );
    // tempNetworkDetails?.to?.network === 'scroll'
    //   ? allowedScrollList
    //   : allowedPolygonList;
    let selectedSymbol = allowedList?.find((i) => i === selectedData?.symbol);

    if (selectedData?.symbol === selectedSymbol) {
      let results = populatedList?.filter(
        (data) => data?.symbol === selectedSymbol
      );
      let selectedCoinByNetwork = results?.find(
        (item) => item?.network === currentNetworkData?.network
      );

      setSelectData(selectedCoinByNetwork);
      setSelectedNetwork(
        // currentNetworkData?.network === 'scroll'
        //   ? networkOptions[2]
        //   : currentNetworkData?.network === 'polygon'
        //   ? networkOptions[1]
        //   : networkOptions[0]
        getNetworkOptionByName(currentNetworkData?.network)
      );
      return true;
    } else {
      return false;
    }
  };

  const handleFetchNativeBalance = async () => {
    setNativeBalanceOfCoin(null);
    try {
      let nativeBal = await getBalance(
        networkConfig[currentNetworkData?.network]?.nativeSymbol,
        currentNetworkData?.network
      );
      setNativeBalanceOfCoin(nativeBal);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (
      selectedData?.network !== 'starknet' &&
      selectedData?.network !== 'scroll'
    ) {
      handleFetchNativeBalance();
    }
  }, [selectedData?.network]);

  const handleCoinAndNetworkChange = () => {
    if (selectedData) {
      let data = {
        ethereum: ethereumCoinData,
        polygon: polygonCoinData,
        scroll: scrollCoinData,
        optimism: optimismCoinData,
      };
      if (tempNetworkDetails?.to?.network) {
        let res = handleInterSwitchCoin();
        if (res) {
          return;
        }
      }
      let chainId = selectedData?.chainId;

      if (initialChainId && initialChainId !== chainId) {
        if (currentNetworkData?.queuedCoin) {
          let filteredData = findItembySymbol(
            currentNetworkData?.queuedCoin?.value
          );
          setSelectData(filteredData);
        } else if (data[networkMap[initialChainId]][0]) {
          setSelectData(data[networkMap[initialChainId]][0]);
        }
        setSelectedNetwork(networkConfig[networkMap[initialChainId]].uiOption);
        // setRequestedNetwork(null);
      }
    }
  };

  const signMsg = (msg) => {
    if (!!(library && account)) {
      return library.getSigner(account).signMessage(msg);
    }
  };

  useEffect(() => {
    if (
      // selectedNetwork?.network !== 'starknet' &&
      // selectedData?.network !== 'starknet'
      requestedNetwork !== 'starknet'
    ) {
      if (selectedData?.chainId && initialChainId) {
        handleCoinAndNetworkChange();
      }
    }
  }, [
    selectedData?.chainId,
    initialChainId,
    currentNetworkData,
    selectedNetwork?.network,
    chainId,
    state,
  ]);

  const toastBtnStyle = {
    padding: '5px 10px',
    background: '#6c5dd3',
    color: '#fff',
    borderRadius: 5,
    fontSize: '12px',
    fontFamily: 'Poppins',
    marginTop: 5,
  };

  const handleClose = () => {
    sessionStorage.removeItem('allowance_loading');
    sessionStorage.removeItem('allowance_loading_amount');
    setStartFetch(false);
  };

  const Msg1 = ({ closeToast, symbol }) => (
    <div>
      Allowance request in progress for{' '}
      {symbol ? symbol?.toUpperCase() : selectedData?.symbol?.toUpperCase()}
      <br />
      <button
        style={toastBtnStyle}
        onClick={() => {
          closeToast();
          handleClose();
        }}
      >
        Close
      </button>
    </div>
  );

  const monitorAllowance = (hash) => {
    toast.loading(<Msg1 />, {
      toastId: 'allowance_loading',
      closeOnClick: false,
      draggable: false,
    });
    sessionStorage.setItem('allowance_loading', selectedData?.symbol);
    sessionStorage.setItem(
      'allowance_loading_amount',
      cryptoAmount.length == 0 ? 0 : cryptoAmount
    );
    sessionStorage.setItem('allowance_loading_hash', hash);
  };

  const handleAllowancePermission = async (isRawFlow = false) => {
    if (!userPermissionFlagResponse?.payload?.brine_permissions?.can_deposit) {
      toast.error('The requested feature is currently unavailable.');
      return;
    }

    if (!account) {
      toast.error('Your session has expired; please log in again', {
        toastId: 'session_logout',
      });
      setTimeout(() => {
        dispatch(logout(true));
      }, 2000);
      return;
    }
    setDisableAllowanceBtn(true);
    setProcessAllowancePopup(true);
    // setState(false);

    if (selectedData?.network !== selectedNetwork?.network) {
      return toast.error(
        `${selectedData?.name} is not supported on the ${selectedNetwork?.value} network. Please switch to the appropriate network.`
      );
    }

    sendTrackEvent('allowanceFlowInitialized', {
      coin: selectedData?.name,
      network: selectedData?.network,
    });

    try {
      let data;
      let ethBalance = await getBalance(
        networkConfig[currentNetworkData?.network]?.nativeSymbol,
        currentNetworkData?.network
      );
      if (ethBalance == 0) {
        setOpenAllowancePopup(false);
        setDisableAllowanceBtn(false);
        setProcessAllowancePopup(false);
        return toast.error(
          `Your ${
            networkConfig[currentNetworkData?.network]?.nativeCurrency
          } balance is insufficient to process the allowance request`
        );
      } else {
        data = await approveUnlimitedAllowance(
          selectedData?.symbol,
          currentNetworkData?.network
        );
      }
      sendTrackEvent('allowanceFlowApproved', {
        coin: selectedData?.name,
        network: selectedData?.network,
      });
      setOpenAllowancePopup(false);
      setProcessAllowancePopup(false);
      monitorAllowance(data?.hash);
      if (isRawFlow) {
        setState(true);
        setAllowanceLoading(true);
      }
      setStartFetch(true);
      setDisableAllowanceBtn(false);
      reset();
    } catch (e) {
      if (e?.code === 'ACTION_REJECTED' || e?.code === 4001) {
        sendTrackEvent('allowanceFlowUserAbort', {
          coin: selectedData?.name,
          network: selectedData?.network,
          msg: e,
        });
      } else {
        sendTrackEvent('allowanceFlowFailed', {
          coin: selectedData?.name,
          network: selectedData?.network,
          error: e,
        });
      }
      setOpenAllowancePopup(false);
      if (userVia?.via !== 'web3_auth') {
        toast.error(
          e?.code
            ? METAMASK_POSSIBLE_ERRORS[e?.code]?.message
            : e?.reason
            ? capitalize(e?.reason)
            : capitalize(e.message)
        );
      } else {
        toast.error(e.message);
      }
      setProcessAllowancePopup(false);
      setDisableAllowanceBtn(false);
      reset();
    }
  };

  useEffect(() => {
    return () => setDepositSuccess(false);
  }, []);

  const getWalletBalance = async () => {
    setisFetchBalanceLoading(true);
    try {
      let data = await getBalanceV2(
        selectedData?.symbol,
        selectedData?.network
      );
      if (
        data !== undefined &&
        data !== null &&
        data?.balance !== undefined &&
        selectedData?.symbol === data.symbol &&
        selectedNetwork?.network === data.network
      ) {
        setMetaBalance(
          toFixedTrunc(data?.balance?.toString(), selectedData?.quanitization)
        );
        setDepositLoading(false);
        setisFetchBalanceLoading(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getWalletStarknetBalance = async () => {
    setisFetchBalanceLoading(true);
    setStarkBalance(null);
    try {
      let res = await getStarknetBalance(
        selectedData?.token_contract,
        selectedData?.blockchain_decimal
      );
      setStarkBalance(toFixedTrunc(res, selectedData?.quanitization));
      setDepositLoading(false);
      setisFetchBalanceLoading(false);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (
      selectedData &&
      selectedNetwork?.network === 'starknet' &&
      selectedData?.network === 'starknet'
    ) {
      // Implement the allowance for starknet
      // setIsFetchAllowanceLoading(true);
      getWalletStarknetBalance();
    }
  }, [selectedData, state, selectedNetwork?.network, starknetAccount?.address]);

  useEffect(() => {
    if (
      selectedData &&
      selectedNetwork?.network !== 'starknet' &&
      selectedData?.network !== 'starknet'
    ) {
      getWalletBalance();
    }
  }, [selectedData, state, selectedNetwork?.network]);

  const StartNetworkSwitchLoadingMsg = ({ network }) => (
    <div className='network__switch_toast'>
      <h2>
        You have selected
        {' ' + network + ' '} network
      </h2>
      <p>
        You’ll need to switch your network to
        {' ' + capitalize(network) + ' ' + capitalize(ENVIRONMENT) + ' '}
        to with the continue deposit
      </p>
    </div>
  );

  const startNetworkSwitchLoading = (network) => {
    toast.loading(<StartNetworkSwitchLoadingMsg network={network} />, {
      toastId: 'switch_network_loading',
      closeOnClick: false,
      draggable: false,
      position: 'bottom-right',
      theme: 'dark',
      className: 'withdrawal_toast_body',
    });
  };

  const handleAddNetwork = async (
    previousNetwork,
    coinData = null,
    networkData = null
  ) => {
    try {
      sendTrackEvent('addNetworkInitialized', {
        requestingNetwork: selectedNetwork?.network,
      });
      await addNetwork(networkData?.network);
      toast.dismiss('switch_network_loading');
      sendTrackEvent('addNetworkApproved', {
        requestingNetwork: selectedNetwork?.network,
      });
    } catch (error) {
      if (error?.code === 'ACTION_REJECTED' || error?.code === 4001) {
        sendTrackEvent('addNetworkUserAbort', {
          requestingNetwork: selectedNetwork?.network,
          msg: error,
        });
      } else {
        sendTrackEvent('addNetworkFailed', {
          requestingNetwork: selectedNetwork?.network,
          error: error,
        });
      }
      setSelectedNetwork(previousNetwork);
      toast.dismiss('switch_network_loading');
      setTempNetworkData(null);
      const isWalletError = error?.message?.includes('wallet_addEthereumChain');
      toast.error(
        isWalletError
          ? 'It seems that the wallet does not support the Polygon network. Please try to add the Polygon network manually.'
          : error.message
      );
    }
  };

  const handleSwitchNetwork = async (val, coinData = null) => {
    if (userVia?.via === 'wallet_connect_v2' && val?.network === 'polygon') {
      return toast.error(
        'Currently, we are facing some issues with Wallet Connect. Please try again later or use an alternative login gateway to make a deposit.',
        { toastId: 'wallet_connect_v2_deposit' }
      );
    }

    if (selectedData?.value === coinData?.value) {
      return;
    }

    if (val?.value === selectedNetwork?.value && coinData === null) {
      return;
    }

    let previousNetwork = selectedNetwork;

    if (chainId !== val?.chainId) {
      try {
        sendTrackEvent('networkSwitchInitialized', {
          requestingNetwork: val?.network,
        });
        startNetworkSwitchLoading(val?.network);
        await switchChain(val?.chainId);
        sendTrackEvent('networkSwitchApproved', {
          requestingNetwork: val?.network,
        });
        toast.dismiss('switch_network_loading');
        setSelectedNetwork(val);
        handleChangeNetworkInternally(
          coinData ? coinData : null,
          val?.value?.toLowerCase(),
          networkConfig[val?.network].chainId
        );
      } catch (error) {
        const tryPolygon = error?.message?.includes(
          'as the wallet is not connected to it'
        );
        if (tryPolygon || error?.code == 4902) {
          handleAddNetwork(previousNetwork, coinData, val);
        } else {
          toast.dismiss('switch_network_loading');
          setTempNetworkData(null);
          toast.error(error.message);
          if (error?.code === 'ACTION_REJECTED' || error?.code === 4001) {
            sendTrackEvent('networkSwitchUserAbort', {
              requestingNetwork: val?.network,
              msg: error,
            });
          } else {
            sendTrackEvent('networkSwitchFailed', {
              requestingNetwork: val?.network,
              error: error,
            });
          }
        }
      }
    } else {
      if (selectedData?.network !== 'starknet') {
        reset();
      }
      if (selectedData?.network === 'starknet') {
        reset();
        setSelectedNetwork(val);
        if (coinData === null) {
          let coinData = getCoinListByNetworkName(val?.network, true);
          setSelectData(
            coinData[0]
            // val?.chainId === ALLOWED_SCROLL_NETWORK_ID
            //   ? scrollCoinData[0]
            //   : val?.chainId === ALLOWED_POLYGON_NETWORK_ID
            //   ? polygonCoinData[0]
            //   : ethereumCoinData[0]
          );
        }
      }
      // setSelectedNetwork(val);
      if (selectedData?.network !== 'starknet') {
        setSelectData(coinData);
      }
      handleChangeNetworkInternally(
        coinData,
        val?.value?.toLowerCase(),
        networkConfig[val?.network].chainId
      );
    }
  };

  const handleStarkwareConnect = () => {
    if (starknetAddress) {
      let coinData = getCoinListByNetworkName('starknet');
      setSelectedNetwork(getNetworkOptionByName('starknet'));
      setSelectData(coinData[0]);
      // setRequestedNetwork(null);
    } else {
      setOpenStarknetPopup(true);
      setState(false);
      setisManualStarknetConnect(true);
    }
  };

  const handleCallBackConnect = () => {
    if (isManualStarknetConnect) {
      let coinData = getCoinListByNetworkName('starknet');
      setSelectedNetwork(getNetworkOptionByName('starknet'));

      setTimeout(() => {
        setisManualStarknetConnect(false);
        setOpenStarknetPopup(false);
        setState(true);
        setSelectData(coinData[0]);
      }, 0);
    }
  };

  // useEffect(() => {
  //   if (selectedNetwork?.network === 'starknet') {
  //     setSelectData(starknetCoinData[0]);
  //   }
  //   if (selectedData?.network === 'starknet') {
  //     setSelectedNetwork(networkOptions[2]);
  //   }
  // }, [selectedNetwork?.network, selectedData?.network]);

  useEffect(() => {
    if (chain?.id && selectedNetwork?.network === 'starknet' && state) {
      if (chain?.id !== ALLOWED_STARKNET_NETWORK_ID) {
        toast.error(
          `Please change your starknet wallet network to ${ENVIRONMENT} to continue.`,
          {
            toastId: 'switch__starknet_toast',
          }
        );
        setRequestedNetwork('ethereum');
        disconnect();
        setSelectedNetwork(getNetworkOptionByName('ethereum'));
      }
    }
  }, [chain?.id, selectedNetwork?.network, state]);

  useEffect(() => {
    if (
      selectedData?.network === 'starknet' &&
      status === 'disconnected' &&
      state
    ) {
      toast.error('Please connect your starknet wallet to continue.', {
        toastId: 'switch__starknet_toast',
      });
      setRequestedNetwork('ethereum');
      disconnect();
      setSelectedNetwork(getNetworkOptionByName('ethereum'));
    }
  }, [status, selectedData?.network, state]);

  const handlePercentInDeposit = (percent, isEth = false) => {
    sendTrackEvent('depositMaxButtonClicked', {
      fee: feeAmount,
      coin: selectedData?.name,
      network: selectedData?.network,
    });
    let blockChainBalance =
      selectedData?.network === 'starknet' ? starkBalance : metaBalance;
    let blockChainFee =
      selectedData?.network === 'starknet' || selectedData?.network === 'scroll'
        ? layerFeeAmount
        : feeAmount;
    if (blockChainBalance) {
      const temp1 = (Number(percent) * Number(blockChainBalance)) / 100;
      const perValue = (temp1 / 100) * 100;
      let amount =
        isEth ||
        selectedData?.network === 'starknet' ||
        selectedData?.network === 'scroll'
          ? (Number(perValue) - Number(blockChainFee)).toFixed(
              selectedData?.quanitization || 5
            ) < 0
            ? 0
            : (Number(perValue) - Number(blockChainFee)).toFixed(
                selectedData?.quanitization || 5
              )
          : perValue?.toFixed(selectedData?.quanitization || 5);
      setCryptoAmount(Number(amount));
    } else {
      setCryptoAmount(0);
    }
  };

  // const handlePercentInDeposit = (percent, isEth = false) => {
  //   sendTrackEvent('depositMaxButtonClicked', {
  //     fee: feeAmount,
  //     coin: selectedData?.name,
  //     network: selectedData?.network,
  //   });

  //   let blockChainBalance =
  //     selectedData?.network === 'starknet'
  //       ? getDecimalJsInstance(starkBalance)
  //       : getDecimalJsInstance(metaBalance);
  //   let blockChainFee =
  //     selectedData?.network === 'starknet'
  //       ? getDecimalJsInstance(layerFeeAmount)
  //       : getDecimalJsInstance(feeAmount);

  //   if (blockChainBalance.greaterThan(0)) {
  //     const temp1 = blockChainBalance
  //       .mul(getDecimalJsInstance(percent))
  //       .div(100);
  //     const perValue = temp1.div(100);
  //     let amount;
  //     if (isEth || selectedData?.network === 'starknet') {
  //       amount = perValue.sub(blockChainFee).greaterThanOrEqualTo(0)
  //         ? perValue
  //             .sub(blockChainFee)
  //             .toDecimalPlaces(selectedData?.quanitization || 5)
  //         : getDecimalJsInstance(0);
  //     } else {
  //       amount = perValue.toDecimalPlaces(selectedData?.quanitization || 5);
  //     }
  //     console.log({
  //       blockChainFee: blockChainFee?.toString(),
  //       selectedData,
  //       perValue: perValue?.toString(),
  //     });

  //     setCryptoAmount(amount.toNumber());
  //   } else {
  //     setCryptoAmount(0);
  //   }
  // };

  const handleGetFeeAmount = async () => {
    setDepositLoading(true);
    setIsFetchNetworkFeeLoading(true);
    try {
      let fee;
      let stark_public_key = getKeyPairFromSignature(userKey)
        .getPublic()
        .getX();

      const finalKey = ethers.BigNumber.from(
        String(stark_public_key)
      ).toHexString();
      const vaultRes = await handleGetVaultId(selectedData?.symbol);
      const vaultId = vaultRes?.payload?.id;

      if (currentNetworkData?.network === 'ethereum') {
        fee = await getGasFeev2(selectedData, finalKey, vaultId, '0.0000001');
      } else {
        fee = await getBrineCrossChainGasFeeV2(selectedData);
      }
      setIsFetchNetworkFeeLoading(false);
      setFeeAmount(fee);
      setDepositLoading(false);
      sendTrackEvent('networkFee', {
        coin: selectedData?.name,
        fee: fee,
        action: 'Deposit',
        network: selectedData?.network,
      });
    } catch (errorRes) {
      setFeeAmountError(errorRes?.error?.code);
      setDepositLoading(false);
      setIsFetchNetworkFeeLoading(false);
    }
  };

  const getAllowanceOfCoin = async () => {
    try {
      let allowanceAmount;

      if (selectedData?.symbol !== 'eth') {
        allowanceAmount = await getSingleCoinAllowance(
          selectedData.symbol,
          selectedData?.network
        );
      }

      setDepositLoading(false);
      return allowanceAmount;
    } catch (error) {
      console.log({ error });
    }
  };

  const handleGetLayerSwapFeeAmount = async () => {
    setDepositLoading(true);
    setLayerSwapFeeAmountError(null);
    setIsFetchNetworkFeeLoading(true);
    setLayerSwapFeeObj(null);

    try {
      let data = await getLayerSwapQuote(
        userInfo?.token?.access,
        selectedData?.network?.toUpperCase(),
        selectedData?.symbol
      );
      setIsFetchNetworkFeeLoading(false);
      setLayerSwapFeeAmount(data?.payload?.fee_amount);
      setLayerSwapFeeObj(data?.payload);
      setDepositLoading(false);
    } catch (error) {
      setLayerSwapFeeAmountError(error);
    }
  };

  useEffect(() => {
    if (
      selectedNetwork?.network !== 'starknet' &&
      selectedData?.network !== 'starknet' &&
      selectedNetwork?.network !== 'scroll' &&
      selectedData?.network !== 'scroll'
    ) {
      if (selectedData?.value && details && coinsDetails && state) {
        setFeeAmount(null);
        handleGetFeeAmount();
      }
    } else {
      handleGetLayerSwapFeeAmount();
    }
  }, [selectedData?.value, state, selectedNetwork?.network]);

  const selectCoinByPriority = () => {
    if (priority && isWalletPage) {
      if (priority?.value) {
        let filteredData = findItembySymbol(priority?.value);
        setSelectData(filteredData);
        setSelectedNetwork(
          // networkConfig[networkMap[currentNetworkData?.chainId]].uiOption
          getNetworkOptionByName(currentNetworkData?.network)
        );
        setPriority(null);
      }
    }
  };

  useEffect(() => {
    selectCoinByPriority();
  }, [priority, currentNetworkData?.queuedCoin?.value, isWalletPage]);

  const getUserCurrenctAllowance = async () => {
    if (selectedData && selectedData?.symbol !== 'eth') {
      setIsFetchAllowanceLoading(true);
      const data = await getAllowanceOfCoin();
      setUserAllowance(data);
      setIsFetchAllowanceLoading(false);
    } else {
      setDepositLoading(false);
      setIsFetchAllowanceLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedNetwork?.network !== 'starknet' &&
      selectedData?.network !== 'starknet' &&
      selectedNetwork?.network !== 'scroll' &&
      selectedData?.network !== 'scroll'
    ) {
      if (selectedData?.symbol === 'eth') {
        setIsFetchAllowanceLoading(false);
      }
      if (selectedData?.value && state) {
        if (
          !selectedData?.value?.includes('undefined') &&
          !selectedData?.value?.includes('null')
        ) {
          getUserCurrenctAllowance();
        }
      }
    } else {
      // Implement the allowance for starknet
      setUserAllowance(Infinity);
      setIsFetchAllowanceLoading(false);
    }
  }, [selectedData?.value, state]);

  useEffect(() => {
    if (txBlockNumber) {
      setDepositLoading(false);
    }
  }, [txBlockNumber]);

  const handleShowGasWarning = async () => {
    if (selectedData?.network !== 'starknet') {
      try {
        let nativeCurrencyBalance = await getBalance(
          networkConfig[currentNetworkData?.network]?.nativeSymbol,
          currentNetworkData?.network
        );
        if (selectedData?.symbol !== 'eth') {
          if (Number(nativeCurrencyBalance) < Number(feeAmount)) {
            setShowGasFeeWarning(true);
          } else {
            setShowGasFeeWarning(false);
          }
        } else {
          if (
            Number(nativeCurrencyBalance) <
            Number(feeAmount) + Number(cryptoAmount)
          ) {
            setShowGasFeeWarning(true);
          } else {
            setShowGasFeeWarning(false);
          }
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };

  useEffect(() => {
    if (feeAmount) {
      handleShowGasWarning();
    }
  }, [feeAmount, selectedData?.symbol, cryptoAmount, chainId, account]);

  useEffect(() => {
    if (
      coinsDetails &&
      details &&
      !ethereumCoinData.length &&
      !polygonCoinData.length
    ) {
      let {
        allData,
        ethData,
        polygonData,
        starknetData,
        scrollData,
        optimismData,
      } = getPopulatedData(true);

      setEthereumCoinData(ethData);
      setPolygonCoinData(polygonData);
      setStarknetCoinData(starknetData);
      setScrollCoinData(scrollData);
      setOptimismCoinData(optimismData);
      setPopulatedList(allData);
      setSelectData(allData[0]);
    }
  }, [coinsDetails, details?.payload, initialChainId]);

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const maxLengthCheck = (object) => {
    if (isDecimal(object.target.value)) {
      if (
        countDecimals(object.target.value) >=
        Number(getInfoAboutCoins(selectedData?.symbol)?.quanitization) + 1
      ) {
        object.target.value = cryptoAmount;
      }
    } else {
      let maxLength = MAX_LENGTH_ALLOWED_IN_NUM_INPUT + 1;
      const { value } = object.target;
      if (String(value).length >= maxLength) {
        object.target.value = cryptoAmount;
      }
    }
  };

  const reset = () => {
    setCryptoAmount('');
    setFeeAmount(null);
    setFeeAmountError(null);
    setDepositLoading(true);
    setUserAllowance(null);
    setDisableAllowanceBtn(false);
    setMetaBalance(null);
  };

  // allowance notification
  useEffect(() => {
    let symbol = sessionStorage.getItem('allowance_loading');
    let amount = sessionStorage.getItem('allowance_loading_amount');
    let allowanceHash = sessionStorage.getItem('allowance_loading_hash');
    let hash = sessionStorage.getItem('deposit_hash_loading') || null;
    let tokenId = sessionStorage.getItem('deposit_token_id_loading') || null;
    let data = depositResponse?.payload?.results[0];
    if (symbol && allowanceHash) {
      setStartFetch(true);
    }
  }, [userVia, depositResponse?.payload?.results]);

  const handleMonitorAllowance = async () => {
    if (coinsDetails) {
      let symbol = sessionStorage.getItem('allowance_loading');
      let amount = sessionStorage.getItem('allowance_loading_amount');
      let allowanceHash = sessionStorage.getItem('allowance_loading_hash');

      if (selectedData?.symbol === symbol) {
        setAllowanceLoading(true);
      }

      if (symbol && startFetch && allowanceHash) {
        toast.loading(<Msg1 symbol={symbol} />, {
          toastId: 'allowance_loading',
          closeOnClick: false,
          draggable: false,
        });

        waitAddon(allowanceHash, 1).then((data) => {
          if (data?.confirmations >= 1) {
            // clearInterval(allowanceInterval);
            toast.dismiss('allowance_loading');
            toast.info(
              `Your allowance request has now been processed for ${symbol?.toUpperCase()}. Please deposit again 🎉`,
              {
                toastId: 'allowance_loading_stop',
                // onClose: () => window.location.reload(),
                autoClose: 3000,
              }
            );
            getUserCurrenctAllowance();
            sessionStorage.removeItem('allowance_loading');
            sessionStorage.removeItem('allowance_loading_amount');
            setStartFetch(false);
            setAllowanceLoading(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    handleMonitorAllowance();
  }, [coinsDetails, startFetch, userVia, selectedData]);

  useEffect(() => {
    if (analytics && selectedData) {
      sendTrackEvent('coinChangeDetected', {
        coin: selectedData?.name,
        action: 'Deposit',
        network: selectedData?.network,
      });
    }
  }, [analytics, selectedData]);

  const resetSelectedItem = () => {
    selectCoinByPriority();
  };

  const handleInsufficientBalanceBtn = () => {
    if (selectedData?.network !== 'starknet') {
      if (selectedData?.network === 'scroll') {
        return (
          Number(metaBalance) - Number(layerFeeAmount) < cryptoAmount ||
          Number(metaBalance) === 0
        );
      }
      if (selectedData?.symbol === 'eth') {
        return (
          Number(metaBalance) - Number(feeAmount) < cryptoAmount ||
          Number(metaBalance) === 0
        );
      } else {
        return Number(metaBalance) < cryptoAmount || Number(metaBalance) === 0;
      }
    } else {
      return (
        Number(starkBalance) - Number(layerFeeAmount) < cryptoAmount ||
        Number(starkBalance) === 0
      );
    }
  };

  const startLayerSwapDeposit = async () => {
    try {
      sendTrackEvent('vaultIdRequestInitiated', {
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      let data = await handleInitaiteLayerSwapTransaction(
        userInfo?.token?.access,
        cryptoAmount,
        selectedData?.symbol,
        selectedData?.network === 'scroll' ? userVia?.address : starknetAddress,
        layerSwapFeeObj,
        selectedData?.network?.toUpperCase()
      );
      sendTrackEvent('vaultIdRequestSuccess', {
        coin: selectedData?.name,
        network: selectedData?.network,
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      return {
        to: data?.payload?.ls_data?.to_address,
        amount: data?.payload?.ls_data?.base_units,
        ref_id: data?.payload?.ref_id,
        data: data?.payload?.ls_data?.data,
        tokenAddress: selectedData?.token_contract,
      };
    } catch (error) {
      sendTrackEvent('vaultIdRequestFailure', {
        coin: selectedData?.name,
        network: selectedData?.network,
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
        error,
      });
      throw error;
    }
  };

  const handleLayerSwapSend = async (formatedData) => {
    try {
      sendTrackEvent('depositProcessingStarted', {
        coin: selectedData?.name,
        network: selectedData?.network,
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      let hash;
      if (selectedData?.network === 'starknet') {
        hash = await executeTransaction(formatedData?.data);
      } else {
        formatedData['amount'] = cryptoAmount;
        formatedData['qAmount'] = dequantize(
          cryptoAmount,
          selectedData?.blockchain_decimal
        );
        formatedData['tokenAddress'] = selectedData?.token_contract;
        hash = await signEVMTransaction(formatedData);
      }
      sendTrackEvent('depositProcessingCompleted', {
        coin: selectedData?.name,
        network: selectedData?.network,
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      return hash;
    } catch (error) {
      if (error?.code === 'ACTION_REJECTED' || error?.code === 4001) {
        sendTrackEvent('depositProcessingUserAbort', {
          coin: selectedData?.name,
          error: error,
          network: selectedData?.network,
          ccAddress:
            selectedData?.network === 'starknet'
              ? starknetAddress
              : userVia?.address,
        });
      } else {
        sendTrackEvent('depositProcessingFailed', {
          coin: selectedData?.name,
          error: error,
          network: selectedData?.network,
          ccAddress:
            selectedData?.network === 'starknet'
              ? starknetAddress
              : userVia?.address,
        });
      }
      handleLayerSwapSaveTx(formatedData?.ref_id, null);
      throw error?.code
        ? METAMASK_POSSIBLE_ERRORS[error?.code]?.message
        : error?.reason
        ? capitalize(error?.reason)
        : capitalize(error.message);
    }
  };

  const handleLayerSwapSaveTx = async (ref_id, tx_hash) => {
    try {
      sendTrackEvent('depositSaveInitiated', {
        coin: selectedData?.name,
        network: selectedData?.network,
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      let res = await handleInitaiteLayerSwapSave(
        userInfo?.token?.access,
        ref_id,
        tx_hash
      );
      sendTrackEvent('depositSaveSuccess', {
        coin: selectedData?.name,
        network: selectedData?.network,
        amount: String(cryptoAmount),
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      return res;
    } catch (error) {
      sendTrackEvent('depositSaveFailure', {
        coin: selectedData?.name,
        error: error,
        network: selectedData?.network,
        ccAddress:
          selectedData?.network === 'starknet'
            ? starknetAddress
            : userVia?.address,
      });
      throw error;
    }
  };

  const handleLayerSwapDeposit = async () => {
    if (!userPermissionFlagResponse?.payload?.brine_permissions?.can_deposit) {
      toast.error('The requested feature is currently unavailable.');
      return;
    }

    if (!account) {
      toast.error('Your session has expired; please log in again', {
        toastId: 'session_logout',
      });
      setTimeout(() => {
        dispatch(logout(true));
      }, 2000);
      return;
    }

    if (selectedData?.network === 'scroll') {
      if (
        Number(metaBalance) - Number(layerFeeAmount) < cryptoAmount ||
        Number(metaBalance) === 0
      ) {
        return toast.error('Your blockchain wallet has insufficient balance');
      }
    } else {
      if (
        Number(starkBalance) - Number(layerFeeAmount) < cryptoAmount ||
        Number(starkBalance) === 0
      ) {
        return toast.error('Your blockchain wallet has insufficient balance');
      }
    }

    if (Number(layerSwapFeeObj?.max_amount) < cryptoAmount) {
      return toast.error(
        `Amount cannot exceed  ${
          layerSwapFeeObj.max_amount
        } ${selectedData?.symbol?.toUpperCase()}`
      );
    } else if (cryptoAmount < Number(layerSwapFeeObj?.min_amount)) {
      return toast.error(
        `Amount should be at least  ${
          layerSwapFeeObj.min_amount
        } ${selectedData?.symbol?.toUpperCase()}`
      );
    }

    try {
      setIsLayerSwapDepositLoading(true);
      let formatedData = await startLayerSwapDeposit();
      // console.log({ formatedData });
      setConfirmDepositPopup(true);
      let res = await handleLayerSwapSend(formatedData);
      let saveRes = await handleLayerSwapSaveTx(
        formatedData?.ref_id,
        res?.transaction_hash
      );
      // console.log({ saveRes });
      setDepositSuccess(true);
      setConfirmDepositPopup(false);
      setIsLayerSwapDepositLoading(false);
      toast.success(
        <SuccessEtherscanMsg
          link={`
        ${
          networkConfig[selectedData?.network].blockExplorerUrl
        }tx/${res?.transaction_hash?.toString()}
        `}
        />,
        {}
      );
      setDepositRes({ deposit_blockchain_hash: res?.transaction_hash });
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error?.message);
      setConfirmDepositPopup(false);
      setIsLayerSwapDepositLoading(false);
    }
  };

  // console.log({ isFetchAllowanceLoading, isFetchBalanceLoading });

  const handleCoinChange = (val) => {
    setTempNetworkData(null);
    setRequestedNetwork(val?.network);
    if (val?.network === 'starknet') {
      handleStarkwareConnect();
    } else {
      // setSelectData(val);
      handleSwitchNetwork(
        // val?.network === 'scroll'
        //   ? networkOptions[2]
        //   : val?.network === 'polygon'
        //   ? networkOptions[1]
        //   : networkOptions[0],
        getNetworkOptionByName(val?.network),
        val
      );
    }
  };

  const handleNetworkChange = (val) => {
    setTempNetworkData({
      from: selectedNetwork,
      to: val,
    });
    setRequestedNetwork(val?.network);
    if (val?.network === 'starknet') {
      handleStarkwareConnect();
    } else {
      handleSwitchNetwork(val);
    }
  };

  const handleFilteredCoinByNetwork = () => {
    let filterCoins = populatedList?.filter(
      (item) => item?.network == selectedNetwork?.network
    );
    setFilterCoinByNetwork(filterCoins);
  };

  useEffect(() => {
    if (populatedList?.length) {
      handleFilteredCoinByNetwork();
    }
  }, [
    populatedList?.length,
    selectedNetwork?.network,
    currentNetworkData?.network,
  ]);

  useEffect(() => {
    if (selectedNetwork?.network && selectedData?.network) {
      if (selectedNetwork?.network !== selectedData?.network) {
        let coinData = getCoinListByNetworkName(selectedNetwork?.network);
        setSelectedNetwork(getNetworkOptionByName(selectedNetwork?.network));
        if (coinData?.length) {
          setSelectData(coinData[0]);
        }
      }
    }
  }, [selectedNetwork?.network, selectedData?.network]);

  const checkUserUsingMPCWallet = async () => {
    if (!account) {
      toast.error('Your session has expired; please log in again', {
        toastId: 'session_logout',
      });
      setTimeout(() => {
        dispatch(logout(true));
      }, 2000);
      return;
    }
    let isAllowanceRequest =
      userAllowance !== 'null' &&
      userAllowance === 0 &&
      selectedData?.symbol !== 'eth';

    let isLayerSwapDeposit =
      selectedData?.network === 'starknet' ||
      selectedData?.network === 'scroll';

    if (!depositLevelState?.payload?.has_deposited) {
      setIsUserHasVerifiedLoading(true);
      try {
        let res = await signMsg(staticMsg);
        if (!res) {
          setIsUserHasVerifiedLoading(false);
          return toast.error('Please verify before depositing funds');
        }
        if (res === userKey) {
          setIsUserHasVerified(true);
          if (isAllowanceRequest) {
            handleAllowancePermission();
          } else {
            if (isLayerSwapDeposit) {
              handleLayerSwapDeposit();
            } else {
              handleCryptoDeposit();
            }
          }
          setIsUserHasVerifiedLoading(false);
        } else {
          setIsUserHasVerifiedLoading(false);
          toast.error(
            'Looks like you’re using an MPC wallet which is currently not supported on Brine. Please use a non-MPC wallet (e.g., Metamask or Coinbase).'
          );
        }
      } catch (error) {
        setIsUserHasVerifiedLoading(false);
        toast.error('Please verify before depositing funds');
      }
    }
  };

  useEffect(() => {
    setIsUserHasVerified(depositLevelState?.payload?.has_deposited);
  }, [depositLevelState?.payload?.has_deposited]);

  return (
    <div className='deposit-popup'>
      {state && (
        <Popup
          state={state}
          setState={setState}
          clearState={() => {
            reset();
            resetSelectedItem();
          }}
        >
          {isFetchAllowanceLoading || isFetchBalanceLoading ? (
            <DepositLoader />
          ) : (
            <>
              <div className='deposit-popup-title h6'>Deposit</div>
              <div
                className='market__select deposit-dropdown-container'
                style={{ width: '100%', marginTop: 30 }}
              >
                <div className='deposit-popup-subtitle h6'>Network</div>
                <DepositDropDown
                  options={networkOptions.filter(
                    (i) => i.network !== 'starknet'
                  )}
                  // options={networkOptions}
                  defaultValue={
                    selectedNetwork ? selectedNetwork : networkOptions[0]
                  }
                  value={selectedNetwork}
                  onChange={(val) => {
                    handleNetworkChange(val);
                  }}
                />
              </div>
              <div
                className='market__select deposit-dropdown-container'
                style={{ width: '100%', marginTop: 30 }}
              >
                <div className='deposit-popup-subtitle h6'>Asset</div>
                {filteredCoinByNetwork.length && (
                  <DepositDropDown
                    options={filteredCoinByNetwork.filter(
                      (i) => i.network !== 'starknet'
                    )}
                    // options={filteredCoinByNetwork}
                    // defaultValue={selectedData ? selectedData : populatedList[0]}
                    value={selectedData}
                    onChange={(val) => {
                      handleCoinChange(val);
                    }}
                  />
                )}
              </div>
              {userAllowance !== 'null' &&
              userAllowance === 0 &&
              selectedData?.symbol !== 'eth' ? null : (
                <div style={{ marginTop: 30 }}>
                  <div className='deposit-popup-subtitle h6'>Amount</div>
                  <DepositFormInput
                    active
                    value={cryptoAmount}
                    type='number'
                    min='0'
                    placeholder='0'
                    onInput={maxLengthCheck}
                    onWheel={numberInputOnWheelPreventChange}
                    onChange={(e) => setCryptoAmount(e.target.value)}
                    suffix={
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handlePercentInDeposit(
                            '100',
                            selectedData?.symbol === 'eth' ? true : false
                          )
                        }
                      >
                        <h5 style={{ color: '#8173E0', fontSize: 13 }}>MAX</h5>
                      </div>
                    }
                    subText={
                      selectedData?.symbol === baseCurrency ||
                      selectedData?.symbol === 'usdt' ||
                      selectedData?.symbol === 'usdc'
                        ? null
                        : `${(
                            Number(
                              lastPrice(
                                `${selectedData?.symbol}${baseCurrency}`
                              )
                            ) * Number(cryptoAmount)
                          ).toFixed(2)} ${baseCurrency?.toUpperCase()}`
                    }
                    notesElement={
                      <>
                        {(metaBalance != null || starkBalance !== null) && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              color: '#808191',
                            }}
                          >
                            <div>
                              <h5>
                                Available in{' '}
                                {selectedData?.network === 'starknet'
                                  ? 'Starknet Wallet'
                                  : walletTextAndLogo[userVia?.via].text}{' '}
                              </h5>
                            </div>
                            <div>
                              {selectedData?.network === 'starknet' ? (
                                <h5>
                                  {starkBalance !== null &&
                                  starkBalance !== undefined
                                    ? starkBalance +
                                      ' ' +
                                      selectedData?.symbol?.toUpperCase()
                                    : '--'}
                                </h5>
                              ) : (
                                <h5>
                                  {metaBalance !== null &&
                                  metaBalance !== undefined
                                    ? metaBalance +
                                      ' ' +
                                      selectedData?.symbol?.toUpperCase()
                                    : '--'}
                                </h5>
                              )}
                              {/* <h5>
                                {metaBalance
                                  ? metaBalance +
                                    ' ' +
                                    selectedData?.symbol?.toUpperCase()
                                  : '--'}
                              </h5> */}
                            </div>
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
              )}
              {userAllowance !== 'null' &&
              userAllowance === 0 &&
              selectedData?.symbol !== 'eth' ? (
                <div className='allowance__deposit_container'>
                  <div className='step-track'>
                    <StepperItem
                      htmlElement={
                        <p>
                          <span>Allow Brine</span> to deposit your{' '}
                          <span>{selectedData?.symbol?.toUpperCase()}</span>.
                        </p>
                      }
                    />
                    <StepperItem
                      htmlElement={
                        <p>
                          {' '}
                          <span>
                            Deposit {selectedData?.symbol?.toUpperCase()}
                          </span>{' '}
                          from your wallet.
                        </p>
                      }
                    />
                  </div>
                  <PurpleCard
                    style={{
                      marginTop: -10,
                    }}
                  >
                    <div className='deposit__card_item'>
                      <div>
                        <div className='h6 title'>
                          Allow {selectedData?.symbol?.toUpperCase()} deposits
                          from your {walletTextAndLogo[userVia?.via].text}
                        </div>
                      </div>
                      <div>
                        <Image src={walletTextAndLogo[userVia?.via].logo} />
                      </div>
                    </div>
                    <div
                      className='deposit__card_item'
                      style={{ marginTop: 3 }}
                    >
                      <div>
                        <div className='h6 subtitle'>
                          For security reasons, Brine requires your permission
                          when you deposit any token for the first time.
                        </div>
                      </div>
                      {selectedData?.symbol !== baseCurrency &&
                        selectedData?.symbol !== 'usdc' &&
                        selectedData?.symbol !== 'usdt' && (
                          <div className='usdc'>
                            {Number(
                              Number(metaBalance) *
                                Number(
                                  lastPrice(
                                    `${selectedData?.symbol}${baseCurrency}`
                                  )
                                )
                            ).toFixed(2)}{' '}
                            {baseCurrency?.toUpperCase()}
                          </div>
                        )}
                    </div>
                  </PurpleCard>
                </div>
              ) : null}

              <>
                {selectedData?.network === 'starknet' ||
                selectedNetwork?.network === 'scroll' ? (
                  <>
                    {!isNaN(layerFeeAmount) && !layerFeeAmountError ? (
                      <div className='estimated__gas'>
                        <div className='h6'>Network fee</div>
                        <div
                          style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div className='h6 gas_amount'>
                            {Number(layerFeeAmount)?.toFixed(3)}{' '}
                            {selectedData?.symbol?.toUpperCase()}
                          </div>
                          {/* <h3 style={{ fontSize: 10 }}>
                            ~
                            {(
                              Number(
                                lastPrice(
                                  `${networkConfig[
                                    currentNetworkData?.network
                                  ]?.nativeSymbol?.toLowerCase()}${baseCurrency}`
                                )
                              ) * Number(feeAmount)
                            ).toFixed(2)}{' '}
                            {baseCurrency?.toUpperCase()}
                          </h3> */}
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {(!isNaN(feeAmount) && !feeAmountError && userAllowance) ||
                    (!userAllowance && selectedData?.symbol === 'eth') ? (
                      <div className='estimated__gas'>
                        <div className='h6'>Network fee</div>
                        <div
                          style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div className='h6 gas_amount'>
                            {Number(feeAmount)?.toFixed(
                              WITHDRAWAL_FEE_DECIMAL_POINTS
                            )}{' '}
                            {networkConfig[
                              currentNetworkData?.network
                            ]?.nativeSymbol?.toUpperCase()}
                          </div>
                          <h3 style={{ fontSize: 10 }}>
                            ~
                            {(
                              Number(
                                lastPrice(
                                  `${networkConfig[
                                    currentNetworkData?.network
                                  ]?.nativeSymbol?.toLowerCase()}${baseCurrency}`
                                )
                              ) * Number(feeAmount)
                            ).toFixed(2)}{' '}
                            {baseCurrency?.toUpperCase()}
                          </h3>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </>
              {status === 'connected' &&
              starknetAddress &&
              selectedData?.network === 'starknet' ? (
                <>
                  {/* <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: 13,
                      color: '#8173E0',
                    }}
                  >
                    Connected address - {truncate(starknetAddress, 12)}
                    <i
                      className='fa-solid fa-circle-arrow-right'
                      style={{ marginLeft: 4 }}
                    ></i>
                  </p> */}

                  <button
                    className='card__btn btn btn_border starknet__address_connect'
                    onClick={() => {
                      setState(false);
                      setOpenStarknetPopup(true);
                    }}
                  >
                    <IconBtn
                      className='icon'
                      iconName='withdraw'
                      text={`Connected Starknet address - ${truncate(
                        starknetAddress,
                        12
                      )}`}
                    />
                  </button>
                </>
              ) : null}

              {isUserHasVerified ? (
                <>
                  {userAllowance !== 'null' &&
                  userAllowance === 0 &&
                  selectedData?.symbol !== 'eth' ? (
                    <AllowAllowanceBtn
                      disableAllowanceBtn={disableAllowanceBtn}
                      startFetch={startFetch}
                      handleAllowancePermission={() =>
                        handleAllowancePermission(true)
                      }
                      getInfoAboutCoins={getInfoAboutCoins}
                      selectedData={selectedData}
                      loading={allowanceLoading}
                    />
                  ) : (
                    <>
                      {selectedData?.network !== 'starknet' && (
                        <>
                          {(!showGasFeeWarning ||
                            Number(metaBalance) <= cryptoAmount ||
                            Number(metaBalance) === 0) && (
                            <div className='deposit-info-tiny'>
                              <p>
                                <span>
                                  {' '}
                                  <Icon
                                    icon='info-circle'
                                    className='icon'
                                    size={'12px'}
                                  />
                                </span>{' '}
                                Please note that Network Fee is only for
                                deposits and withdrawals and not for trades as
                                they are Gasless.{' '}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      <>
                        {selectedData?.network !== 'starknet' &&
                          selectedData?.network !== 'scroll' && (
                            <>
                              {Number(feeAmount) >= nativeBalanceOfCoin && (
                                <div
                                  style={{
                                    display: !showGasFeeWarning
                                      ? 'none'
                                      : 'block',
                                    marginTop: 20,
                                  }}
                                >
                                  <WarnBox>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <h1>
                                        <i className='fas fa-exclamation-circle'></i>
                                      </h1>
                                      <h1>Insufficient funds.</h1>
                                    </div>
                                    {Number(feeAmount) > nativeBalanceOfCoin ? (
                                      <p>
                                        Your ethereum balance is insufficient to
                                        cover the gas fee, the transaction may
                                        fail.
                                      </p>
                                    ) : (
                                      <p>
                                        Your ethereum balance is insufficient.
                                      </p>
                                    )}
                                    {Number(feeAmount) >
                                      nativeBalanceOfCoin && (
                                      <span
                                        className='link'
                                        onClick={() => {
                                          setShowGasFeeWarning((prev) => !prev);
                                          sendTrackEvent(
                                            'insufficientGasAcceptanceClicked',
                                            {
                                              coin: selectedData?.name,
                                              network: selectedData?.network,
                                            }
                                          );
                                        }}
                                      >
                                        I want to proceed anyway
                                      </span>
                                    )}
                                  </WarnBox>
                                </div>
                              )}
                            </>
                          )}
                      </>
                      <div
                        style={{
                          display: 'grid',
                          placeItems: 'center',
                          marginTop: 20,
                        }}
                      >
                        {handleInsufficientBalanceBtn() ? (
                          <InsufficientBalanceBtn />
                        ) : (
                          <>
                            {selectedData?.network === 'starknet' ||
                            selectedData?.network === 'scroll' ? (
                              <>
                                {isLayerSwapDepositLoading ? (
                                  <LoadingBtn size='full' />
                                ) : (
                                  <>
                                    <CustomButton
                                      title={`Confirm ${selectedData?.symbol?.toUpperCase()} Deposit`}
                                      onClick={() => handleLayerSwapDeposit()}
                                      disabled={
                                        depositLoading ||
                                        isFetchNetworkFeeLoading ||
                                        isLayerSwapDepositLoading
                                      }
                                      style={{
                                        display: 'block',
                                        width: '100%',
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {!showGasFeeWarning && (
                                  <>
                                    <>
                                      <CustomButton
                                        title={`Confirm ${selectedData?.symbol?.toUpperCase()} Deposit`}
                                        onClick={() => handleCryptoDeposit()}
                                        disabled={
                                          startFetch ||
                                          depositLoading ||
                                          showGasFeeWarning ||
                                          isFetchNetworkFeeLoading ||
                                          isLayerSwapDepositLoading
                                        }
                                        style={{
                                          display: 'block',
                                          width: '100%',
                                        }}
                                      />
                                    </>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    marginTop: 20,
                  }}
                >
                  {userAllowance !== 'null' &&
                  userAllowance === 0 &&
                  selectedData?.symbol !== 'eth' ? null : (
                    <div
                      className='deposit-info-tiny'
                      style={{ marginBottom: 15 }}
                    >
                      <p>
                        <span>
                          {' '}
                          <Icon
                            icon='info-circle'
                            className='icon'
                            size={'12px'}
                          />
                        </span>{' '}
                        Please note that Network Fee is only for deposits and
                        withdrawals and not for trades as they are Gasless.{' '}
                      </p>
                    </div>
                  )}
                  {isUserHasVerifiedLoading ? (
                    <LoadingBtn size='full' />
                  ) : (
                    <CustomButton
                      title={`Verfiy yourself`}
                      onClick={() => checkUserUsingMPCWallet()}
                      style={{
                        display: 'block',
                        width: '100%',
                      }}
                    />
                  )}
                </div>
              )}
              <DepositLinkText
                text='Need help with Deposits? Have a look here'
                link='https://www.youtube.com/watch?v=1bDDD5TewPU'
                style={{
                  marginTop:
                    showGasFeeWarning && Number(metaBalance) > cryptoAmount
                      ? 10
                      : 20,
                  textAlign: 'center',
                }}
              />
            </>
          )}
        </Popup>
      )}
      <div className='share__popup network__error'>
        <Popup
          state={showProcessAllowancePopup}
          setState={setProcessAllowancePopup}
          closeonAnyPopupState={() => {
            setConfirmDepositPopup(false);
            setDepositSuccess(false);
            setDepositRes(null);
            setState(false);
            setOpenAllowancePopup(false);
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div className='h6'>Waiting for Approval.</div>
            {/* <Image src={images.networkError} /> */}
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Loader />
            </div>
            <p>
              Please <span style={{ color: '#8173E0' }}>confirm</span> the
              allowance request on{' '}
              {selectedData?.network === 'starknet'
                ? 'Starknet'
                : userVia?.via == 'metamask'
                ? 'Metamask'
                : userVia?.via == 'wallet_link'
                ? 'Coin base wallet app'
                : 'WalletConnect app'}{' '}
              🚀
            </p>
            <CustomButton
              title='Close'
              addClassName='disconnect_btn'
              style={{
                width: '100%',
              }}
              onClick={() => {
                setConfirmDepositPopup((prev) => !prev);
              }}
            />
          </div>
        </Popup>
      </div>
      <div className='share__popup'>
        <Popup
          state={openAllowancePopup}
          setState={setOpenAllowancePopup}
          closeonAnyPopupState={() => {
            setConfirmDepositPopup(false);
            setDepositSuccess(false);
            setDepositRes(null);
            setState(false);
            setProcessAllowancePopup(false);
          }}
        >
          <div style={{ fontFamily: 'Poppins' }}>
            {/* {alreadyAskedAllowance ? (
              <
                <p
                  style={{
                    textAlign: 'center',
                    marginTop: 5,
                    marginBottom: 10,
                  }}
                >
                  ⚠️ Your previous approval is still pending for confirmation.
                  Would you like to still continue raising an approval ?
                </p>
                <div
                  style={{
                    marginTop: 22,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CustomButton
                    title='Cancel'
                    onClick={() => setOpenAllowancePopup(false)}
                  />

                  <button
                    className='card__btn btn btn_border'
                    style={{ marginLeft: 12 }}
                    onClick={() => handleAllowancePermission()}
                  >
                    <span className='btn__text'>Continue</span>
                  </button>
                </div>
              </>
            ) : ( */}
            <div
              // style={{
              //   display: 'grid',
              //   placeItems: 'center',
              // }}
              style={{ marginTop: 50 }}
            >
              <div className='h6' style={{ textAlign: 'center' }}>
                We need your approval to proceed
              </div>
              <PurpleCard>
                <div className='deposit__card_item'>
                  <div>
                    <div className='h6 title' style={{ fontSize: 14 }}>
                      Increase your deposit limit
                    </div>
                  </div>
                  <div>
                    <Image src={walletTextAndLogo[userVia?.via].logo} />
                  </div>
                </div>
                <div className='deposit__card_item' style={{ marginTop: 3 }}>
                  <div>
                    <div className='h6 subtitle' style={{ fontSize: 12 }}>
                      It seems you've approved a low limit of{' '}
                      {preventScientific(askedAllowance)} {selectedData?.symbol}
                      . We recommend you to increase your limit by approving
                      allowance.
                    </div>
                  </div>
                </div>
              </PurpleCard>
              <div className='p allowance__msg'>
                If you do not want to see this again, we recommend enabling
                <span> Use Default </span>
                as allowance cap on {walletTextAndLogo[userVia?.via].text}
              </div>
            </div>
            <AllowAllowanceBtn
              disableAllowanceBtn={disableAllowanceBtn}
              startFetch={startFetch}
              handleAllowancePermission={() => handleAllowancePermission(false)}
              getInfoAboutCoins={getInfoAboutCoins}
              selectedData={selectedData}
              text={`Change deposit limit for ${selectedData?.symbol?.toUpperCase()}`}
            />
            {/* )} */}
          </div>
        </Popup>
      </div>
      <div className='share__popup network__error'>
        <Popup
          setState={setConfirmDepositPopup}
          state={confirmDepositPopup}
          closeonAnyPopupState={() => {
            // setDepositSuccess(false);
            // setDepositRes(false);
            setState(false);
            setOpenAllowancePopup(false);
            setProcessAllowancePopup(false);
          }}
        >
          <div style={{ fontFamily: 'Poppins' }}>
            {userVia?.via !== 'web3_auth' ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div className='h6'>Waiting for Approval.</div>
                {/* <Image src={images.networkError} /> */}
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Loader />
                </div>
                <p style={{ fontSize: 12 }}>
                  We are waiting for you to complete the request on{' '}
                  {selectedData?.network === 'starknet'
                    ? 'Starknet'
                    : userVia?.via == 'metamask'
                    ? ' Metamask'
                    : userVia?.via == 'wallet_link'
                    ? ' Coin base wallet app'
                    : ' WalletConnect app'}
                  . Please note that you can check your the status of your
                  deposit in the History tab.
                </p>
                <CustomButton
                  title='Close'
                  addClassName='disconnect_btn'
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {
                    setConfirmDepositPopup((prev) => !prev);
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div className='h6'>Transaction Processing</div>
                {/* <Image src={images.networkError} /> */}
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Loader />
                </div>
                <p style={{ fontSize: 12 }}>
                  Your deposit request was successful. Please note that you can
                  check your the status of your deposit in the History tab.
                </p>
                <CustomButton
                  title='Close'
                  addClassName='disconnect_btn'
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {
                    setConfirmDepositPopup((prev) => !prev);
                  }}
                />
              </div>
            )}
          </div>
        </Popup>
      </div>
      <div className='share__popup network__error'>
        <Popup
          state={depositSuccess}
          setState={() => {
            setDepositSuccess((prev) => !prev);
            setDepositRes(null);
          }}
          closeonAnyPopupState={() => {
            setConfirmDepositPopup(false);
            setState(false);
            setOpenAllowancePopup(false);
            setProcessAllowancePopup(false);
          }}
          confetti={true}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div className='h6'>Deposit Successful</div>
            {/* <Image src={images.networkError} /> */}
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <SucccessLottie />
            </div>
            <p style={{ fontSize: 12 }}>
              Your deposit request was successful. Please note that you can
              check your the status of your deposit in the History tab.
            </p>
            {depositRes?.deposit_blockchain_hash && (
              <CustomButton
                title='Track Deposit'
                addClassName='disconnect_btn'
                style={{
                  width: '100%',
                }}
                onClick={() =>
                  history.push(
                    `/history?tab=deposits&value=${depositRes.deposit_blockchain_hash}`
                  )
                }
              />
            )}
          </div>
        </Popup>
        {/* <NetworkChangePopup /> */}
      </div>
      <div className='share__popup network__error'>
        <Popup
          state={openStarknetPopup}
          setState={setOpenStarknetPopup}
          clearState={() => setState(true)}
        >
          <StarknetConnectPopup
            handleCallBackConnect={handleCallBackConnect}
            setRequestedNetwork={setRequestedNetwork}
          />
        </Popup>
      </div>
    </div>
  );
}

export default DepositPopup;

function AllowAllowanceBtn({
  disableAllowanceBtn,
  startFetch,
  handleAllowancePermission,
  getInfoAboutCoins,
  selectedData,
  loading,
  text = '',
}) {
  const [countdown, setCountdown] = useState(30);
  const [countText, setCountText] = useState('30 seconds remaining');
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let interval;

    if (isCounting && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
        setCountText(`${countdown - 1} seconds remaining`);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(interval);
      setCountText('Loading...');
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [countdown, isCounting]);

  const handleStartCountdown = () => {
    setCountdown(30);
    setIsCounting(true);
    setCountText('30 seconds remaining');
  };

  const btnTitle = text.length
    ? text
    : `Allow Brine to use your ${selectedData?.symbol?.toUpperCase()}`;

  useEffect(() => {
    if (loading) {
      handleStartCountdown();
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <div className='allowance__loader'>
          <LoadingBtn
            text={`Processing allowance. ${countText}.`}
            size={'full'}
            disabled={true}
          />
        </div>
      ) : (
        <button
          className='card__btn btn btn__orange'
          style={{
            display: 'block',
            width: '100%',
            fontFamily: 'Poppins',
            marginBottom: '20px',
            marginTop: '30px',
            fontSize: 12,
            background: `rgba(108, 93, 211, 1)`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          disabled={disableAllowanceBtn || startFetch}
          onClick={handleAllowancePermission}
        >
          <div>
            <img
              src={getInfoAboutCoins(selectedData?.symbol)?.logo}
              alt=''
              style={{
                width: 20,
              }}
            />
          </div>
          <div>
            <span
              className='btn__text'
              style={{
                color: '#fff',
                fontFamily: 'Inter',
                fontSize: 14,
              }}
            >
              {btnTitle}
              <i
                className='fas fa-arrow-right'
                style={{
                  marginLeft: 5,
                  animation: 'shake-animation 4.72s ease infinite',
                }}
              ></i>
            </span>
          </div>
        </button>
      )}
    </>
  );
}
