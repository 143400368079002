import React from 'react';
import Image from '../../common/img/image';
import { images } from '../../../utils/image/img';

export function SaltLeaderBoardCell({ item, idx, randomImgs, userScore }) {
  return (
    <div
      className={`prices__row leaderBoard__row ${
        item.rank == userScore?.rank ? 'active' : ''
      }`}
      style={{ cursor: 'auto' }}
    >
      <div className='prices__cell'>
        <div className='prices__company'>
          <div className='prices__logo'>
            <Image src={randomImgs[idx]} alt='' />
          </div>
          <div
            className='prices__text salt__username'
            style={{
              fontSize: 15,
            }}
          >
            {item.name}
          </div>
        </div>
      </div>
      <div
        className='prices__cell'
        style={{
          textTransform: 'uppercase',
          fontSize: 15,
        }}
      >
        {item.address}
      </div>
      <div
        className='prices__cell'
        style={{
          textTransform: 'uppercase',
        }}
      >
        {item?.score}
      </div>
      <div
        className='prices__cell'
        style={{
          fontSize: 20,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div className={`positive`}>#{item?.rank}</div>
        {(item.rank == 1 || item.rank == 2 || item.rank == 3) && (
          <img
            src={
              item.rank == 1
                ? images.goldMedal
                : item.rank == 2
                ? images.silverMedal
                : images.bronzeMedal
            }
            style={{
              height: 22,
              marginLeft: 3,
            }}
          />
        )}
      </div>
    </div>
  );
}
