import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useBalance,
  useWebSocketData,
  useMeta,
  useViewport,
  useAnalytics,
  useSegment,
} from '../../../utils/hooks';
import {
  CustomButton,
  Popup,
  WarnBox,
  Icon,
  LoadingBtn,
  DepositLinkText,
  Image,
} from '../../../components';
import WalletWithdrawFormInput from './wallet-withdraw-input/wallet-withdraw-input';
import {
  getFastVaultId,
  getVaultId,
  getWithdrawalVaultId,
  startFastWithdrawalDex,
  startWithdrawalDex,
  validateFastWithdrawalDex,
  validateWithdrawalDex,
} from '../../../actions/walletAction';
import { toast } from 'react-toastify';
import { getBalance, logout } from '../../../actions/userAction';
import LatestActivity from '../../home/latest-activities/latest-activities';
import {
  getDEXFastWithdrawData,
  getWithdrawsData,
} from '../../../actions/historyAction';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import Lottie from 'react-lottie';
import animationData from '../../../assests/json/waiting.json';
import processingAnimationData from '../../../assests/json/processing.json';
import { getTransferMsgHash, sign } from '../../../utils/contracts/signature';
import { useWeb3React } from '@web3-react/core';
import { getQueryParams } from '../../../utils/query-helper';
import ReactTooltip from 'react-tooltip';
import { InsufficientBalanceBtn } from '../../exchange/exchange-action/exchange-action';
import {
  NORMAL_WITHDRAWAL_CONFIRMATION_HOURS,
  baseCurrency,
} from '../../../utils/constants';
import { sanitizeProvider } from '../../../utils/sanitize-provider';
import { images } from '../../../utils/image/img';
import { numberInputOnWheelPreventChange } from '../../../utils/numberPrevent';

export const InstructionForWithdrawal = ({
  setState,
  disableBackBtn,
  backController,
  disableBtn,
}) => {
  const { analytics, sendTrackEvent } = useSegment();
  return (
    <div className='notes__instant withdraw__instruction'>
      {!disableBackBtn && (
        <div
          style={{ cursor: 'pointer', position: 'absolute' }}
          onClick={backController}
        >
          <p style={{ fontFamily: 'Poppins', color: '#8173E0' }}>
            <span style={{ marginRight: 3 }}>
              <i className='fas fa-chevron-left'></i>
            </span>
            Back
          </p>
        </div>
      )}
      <div className='h6'>Withdrawal Guide</div>
      {!disableBtn && (
        <div style={{ display: 'grid', placeItems: 'center', marginTop: 40 }}>
          <Image
            src={images.informationIcon}
            style={{ width: 110, height: 110 }}
          />
        </div>
      )}
      <Stepper />
      {!disableBtn && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomButton
            title='Understood, I want to proceed'
            style={{ minWidth: '100%' }}
            onClick={() => {
              backController();
              sendTrackEvent('buttonClicked', {
                title: 'Understood, I want to proceed',
                action: 'Closing the withdrawal instruction popup',
              });
            }}
          />
        </div>
      )}
      <DepositLinkText
        text={'Watch a video to learn more'}
        link='https://youtu.be/5YoTLW1oox4'
        type='video'
      />
    </div>
  );
};

function WalletWithdraw({
  visibleSideBar,
  setVisibleSideBar,
  setSelectedData,
  selectedData,
}) {
  // const options = [
  //   {
  //     label: 'Fast 🚀 (1-30 mins)',
  //     value: 'fast',
  //   },
  //   {
  //     label: 'Normal 🎉 (7-8 hours)',
  //     value: 'normal',
  //   },
  // ];
  const options = [
    {
      label: 'Fast',
      value: 'fast',
    },
    {
      label: 'Normal',
      value: 'normal',
    },
  ];
  const dispatch = useDispatch();
  const { width } = useViewport();
  const { lastPrice } = useWebSocketData();
  const { formatCurrency, roundUpWithSymbol, isDecimal, getInfoAboutCoins } =
    useBalance();
  // const inputRef = document.getElementById('amount-input');
  const [coinsInfoStore, setCoinInfoStore] = useState({});
  const [amountToWithdraw, setAmountToWithdraw] = useState('');
  const { connectedAccount } = useMeta(selectedData?.currency);
  const [openWithdrawalStartPopup, setOpenWithdrawalStartPopup] =
    useState(false);
  const [openInstructionForWithdrawal, setOpenInstructionForWithdrawal] =
    useState(false);
  const [openVaultIdPopup, setOpenVaultIdPopup] = useState(false);
  const [selectedOption, setSelectedOptions] = useState(options[1]);
  const { deactivate } = useWeb3React();
  // const { analytics } = useAnalytics();
  const [ethereum, setEthereum] = useState(null);
  // const {
  // getCoins: { coinsDetails },
  // userLogin: { userInfo },
  // userKey: { state },
  // userVia: { state: userVia },
  // withdrawalIds: { state: withdrawalIds },
  // userIpAddress: { ip },
  // theme: { isdark },
  // } = useSelector((state) => state);

  const { isdark } = useSelector((state) => state.theme);
  const { ip } = useSelector((state) => state.userIpAddress);
  const { state: userVia } = useSelector((state) => state.userVia);
  const { state } = useSelector((state) => state.userKey);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { state: withdrawalIds } = useSelector((state) => state.withdrawalIds);
  const { coinsDetails } = useSelector((state) => state.getCoins);

  const [processLoading, setProcessLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const { account } = useWeb3React();
  const perValue = ['25', '50', '75', '100'];
  const { getFastWithdrawalFee, toFixedTrunc } = useBalance();

  const getCryptotoUSDT = () => {
    if (selectedData) {
      const value =
        lastPrice(`${selectedData.currency}${baseCurrency}`) *
        parseFloat(selectedData.balance);
      return selectedData?.currency == `${baseCurrency}`
        ? roundUpWithSymbol(selectedData.balance, `${baseCurrency}`)
        : roundUpWithSymbol(value, `${baseCurrency}`);
    }
  };

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const handlePreventDecimal = (e) => {
    let invalidChars = ['-', '+', 'e', '.'];
    if (invalidChars.includes(e.key)) {
      return e.preventDefault();
    }
  };

  useEffect(() => {
    const coinInfo = coinsDetails?.convertedArray?.find((item) => {
      return (
        item.symbol == selectedData?.symbol ||
        item.symbol == selectedData?.currency
      );
    });
    setCoinInfoStore(coinInfo);
  }, [coinsDetails, selectedData?.symbol, selectedData?.currency]);

  // useEffect(() => {
  //   if (inputRef) {
  //     if (
  //       coinsInfoStore?.symbol == 'shib' ||
  //       coinsInfoStore?.decimal == 0 ||
  //       coinsInfoStore?.symbol == 'inr'
  //     ) {
  //       inputRef.addEventListener('keydown', handlePreventDecimal);
  //       return () =>
  //         inputRef.removeEventListener('keydown', handlePreventDecimal);
  //     }
  //   }
  // }, [
  //   inputRef,
  //   coinsInfoStore?.symbol,
  //   coinsInfoStore?.currency,
  //   coinsInfoStore?.decimal,
  // ]);

  const maxLengthCheck = (object) => {
    let decimalPoint =
      selectedData?.currency == 'usdc' || selectedData?.currency == 'usdt'
        ? 5
        : getInfoAboutCoins(selectedData?.currency)?.quanitization;
    if (isDecimal(object.target.value)) {
      if (countDecimals(object.target.value) >= Number(decimalPoint) + 1) {
        object.target.value = amountToWithdraw;
      }
    } else {
      let maxLength = 9;
      const { value } = object.target;
      if (String(value).length >= maxLength) {
        object.target.value = amountToWithdraw;
      }
    }
  };

  const generateHash = async (
    nonce,
    quanitizatedAmount,
    exp_timestamp,
    senderVaultId,
    recieverVaultId,
    receiverPublicKey
  ) => {
    let token = getInfoAboutCoins(selectedData?.currency)?.stark_asset_id;

    let stark_public_key = getKeyPairFromSignature(state);

    const msg_hash = await getTransferMsgHash(
      quanitizatedAmount,
      nonce,
      senderVaultId,
      token,
      recieverVaultId,
      receiverPublicKey,
      exp_timestamp
    );

    const msg = sign(stark_public_key, msg_hash);

    return {
      msg_hash,
      signature: {
        r: `0x${msg.r.toString('hex')}`,
        s: `0x${msg.s.toString('hex')}`,
        recoveryParam: msg.recoveryParam,
      },
      nonce: nonce,
    };
  };

  const generateFastWithdrawHash = async (
    nonce,
    quanitizatedAmount,
    exp_timestamp,
    recieverVaultId,
    receiverPublicKey,
    senderVaultId
  ) => {
    let token = getInfoAboutCoins(selectedData?.currency)?.stark_asset_id;

    let stark_public_key = getKeyPairFromSignature(state);

    const msg_hash = await getTransferMsgHash(
      quanitizatedAmount,
      nonce,
      senderVaultId,
      token,
      recieverVaultId,
      receiverPublicKey,
      exp_timestamp
    );

    const msg = sign(stark_public_key, msg_hash);

    return {
      msg_hash,
      signature: {
        r: `0x${msg.r.toString('hex')}`,
        s: `0x${msg.s.toString('hex')}`,
        recoveryParam: msg.recoveryParam,
      },
      nonce: nonce,
    };
  };

  const handleGetVaultId = async () => {
    try {
      const data = await getVaultId(
        userInfo?.token?.access,
        selectedData?.currency
      );
      return data;
    } catch (error) {
      setOpenVaultIdPopup(false);
      toast.error(error);
    }
  };

  const handleWithdrawalValidation = async (hashObj) => {
    try {
      const validateRes = await validateWithdrawalDex(
        userInfo?.token?.access,
        hashObj
      );
      toast.success(validateRes.message);
      setOpenWithdrawalStartPopup(true);
      dispatch(getWithdrawsData(1));
      setAmountToWithdraw('');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleWithdrawalFastValidation = async (hashObj) => {
    try {
      const validateRes = await validateFastWithdrawalDex(
        userInfo?.token?.access,
        hashObj
      );
      toast.success(validateRes.message);
      setOpenWithdrawalStartPopup(true);
      dispatch(getDEXFastWithdrawData(1));
      setAmountToWithdraw('');
    } catch (error) {
      toast.error(error);
    }
  };

  const refreshBalance = () => {
    setTimeout(() => {
      dispatch(getBalance());
      setVisibleSideBar(false);
      setSelectedData(null);
    }, 10000);
  };

  const handleDexWithdrawal = async () => {
    // analytics?.track('Withdrawal request start', {
    //   from: 'Wallet Page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   symbol: selectedData?.currency,
    //   amount: amountToWithdraw,
    //   ip: ip,
    // });
    setProcessLoading(true);
    setBtnDisable(true);
    // if (userVia?.via == 'metamask') {
    //   if (
    //     !ethereum.isConnected() ||
    //     !ethereum._state.initialized ||
    //     !connectedAccount
    //   ) {
    //     toast.error('Your session has expired; please log in again');
    //     setTimeout(() => {
    //       deactivate();
    //       dispatch(logout(true));
    //     }, 1500);
    //     setBtnDisable(false);
    //     setOpenVaultIdPopup(false);
    //     setProcessLoading(false);
    //     return;
    //   }
    // }

    if (amountToWithdraw <= 0) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Amount should be greater than zero');
    }

    if (Number(selectedData.balance) < amountToWithdraw) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Your Brine wallet has insufficient balance');
    }

    if (userVia?.via !== 'metamask' && userVia?.via !== 'web3_auth') {
      if (!account) {
        toast.error('Your session has expired; please log in again');
      }
    }

    try {
      let coinsIdList = withdrawalIds.payload.filter(
        (item) => item.coin === selectedData?.currency
      );
      let senderVaultId = coinsIdList?.filter(
        (item) => item.vault_type === 'GEN'
      )[0]?.id;
      let recieverVaultId = coinsIdList?.filter(
        (item) => item.vault_type === 'WD'
      )[0]?.id;
      let receiverPublicKey = coinsIdList?.filter(
        (item) => item.vault_type === 'WD'
      )[0]?.stark_public_address;

      if (!senderVaultId || !recieverVaultId) {
        handleGetVaultId();
        dispatch(getWithdrawalVaultId());
        setOpenVaultIdPopup(true);
        return;
      }
      const { data } = await startWithdrawalDex(
        userInfo?.token?.access,
        amountToWithdraw,
        selectedData?.currency
      );
      const hashObj = await generateHash(
        data?.payload?.id,
        data?.payload?.extras?.quantised_amount,
        data?.payload?.extras?.exp_timestamp,
        senderVaultId,
        recieverVaultId,
        receiverPublicKey
      );
      if (!hashObj) {
        toast.error('Something went wrong; please try again later', {
          toastId: 'withdrawal_failed',
        });
        setBtnDisable(false);
        setProcessLoading(false);
        return;
      }
      handleWithdrawalValidation(hashObj);
      setProcessLoading(false);
      refreshBalance();
      setTimeout(() => {
        setBtnDisable(false);
      }, 15000);
      // analytics?.track('Withdrawal request success', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   amount: amountToWithdraw,
      //   ip: ip,
      // });
    } catch (e) {
      // analytics?.track('Withdrawal request failed', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   ip: ip,
      //   amount: amountToWithdraw,
      // });
      toast.error(e);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      setTimeout(() => {
        setBtnDisable(false);
      }, 3000);
    }
  };

  const handleDexFastWithdrawal = async () => {
    // analytics?.track('Fast withdrawal request start', {
    //   from: 'Wallet Page',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   symbol: selectedData?.currency,
    //   amount: amountToWithdraw,
    //   ip: ip,
    // });
    setProcessLoading(true);
    setBtnDisable(true);
    // if (userVia?.via == 'metamask') {
    //   if (
    //     !ethereum.isConnected() ||
    //     !ethereum._state.initialized ||
    //     !connectedAccount
    //   ) {
    //     toast.error('Your session has expired; please log in again');
    //     setTimeout(() => {
    //       deactivate();
    //       dispatch(logout(true));
    //     }, 1500);
    //     setBtnDisable(false);
    //     setProcessLoading(false);
    //     setOpenVaultIdPopup(false);
    //     return;
    //   }
    // }

    if (amountToWithdraw == 0) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Please enter an amount greater than zero');
    }

    if (
      Number(selectedData.balance) <
      Number(amountToWithdraw) +
        Number(getFastWithdrawalFee(selectedData?.currency))
    ) {
      setBtnDisable(false);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      return toast.error('Your Brine wallet has insufficient balance');
    }

    if (userVia?.via !== 'metamask' && userVia?.via !== 'web3_auth') {
      if (!account) {
        toast.error('Your session has expired; please log in again');
      }
    }

    try {
      let coinsIdList = withdrawalIds.payload.filter(
        (item) => item.coin === selectedData?.currency
      );
      let senderVaultId = coinsIdList?.filter(
        (item) => item.vault_type === 'GEN'
      )[0]?.id;

      const res = await getFastVaultId(
        userInfo?.token?.access,
        selectedData?.currency
      );

      let recieverVaultId = res?.payload?.id;
      let receiverPublicKey = res?.payload?.stark_public_address;

      if (!senderVaultId || !recieverVaultId) {
        handleGetVaultId();
        dispatch(getWithdrawalVaultId());
        setOpenVaultIdPopup(true);
        return;
      }

      const { data } = await startFastWithdrawalDex(
        userInfo?.token?.access,
        amountToWithdraw,
        selectedData?.currency
      );

      const hashObj = await generateFastWithdrawHash(
        data?.payload?.id,
        data?.payload?.extras?.quantised_amount,
        data?.payload?.extras?.exp_timestamp,
        recieverVaultId,
        receiverPublicKey,
        senderVaultId
      );
      if (!hashObj) {
        toast.error('Something went wrong; please try again later', {
          toastId: 'withdrawal_failed',
        });
        setBtnDisable(false);
        setProcessLoading(false);
        return;
      }
      handleWithdrawalFastValidation(hashObj);
      setProcessLoading(false);
      refreshBalance();
      setTimeout(() => {
        setBtnDisable(false);
      }, 15000);
      // analytics?.track('Fast withdrawal request success', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   amount: amountToWithdraw,
      //   ip: ip,
      // });
    } catch (e) {
      // analytics?.track('Fast withdrawal request failed', {
      //   from: 'Wallet Page',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   symbol: selectedData?.currency,
      //   amount: amountToWithdraw,
      //   ip: ip,
      //   error: e,
      // });
      toast.error(e);
      setProcessLoading(false);
      setOpenVaultIdPopup(false);
      setTimeout(() => {
        setBtnDisable(false);
      }, 3000);
    }
  };

  const noteStyle = {
    marginTop: -6,
    marginBottom: 16,
    fontFamily: 'Poppins',
    fontSize: 11,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  };

  const PopupStyles = {
    // paddingLeft: width > 750 ? 50 : 0,
    // paddingRight: width > 750 ? 50 : 0,
    marginTop: 20,
    textAlign: 'center',
    fontFamily: 'Poppins',
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const processAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: processingAnimationData,
  };

  useEffect(() => {
    return () => {
      setAmountToWithdraw('');
    };
  }, [selectedData]);

  const handlePercentInWithdraw = (percent) => {
    const temp1 = (Number(percent) * Number(selectedData?.balance)) / 100;
    const perValue = (temp1 / 100) * 100;

    let amount =
      selectedData?.currency == 'usdc' || selectedData?.currency == 'usdt'
        ? toFixedTrunc(perValue, 5)
        : roundUpWithSymbol(perValue, selectedData?.currency);
    setAmountToWithdraw(amount);
  };

  const handleTryAgain = () => {
    setOpenVaultIdPopup(false);
    if (selectedOption.value === 'fast') {
      handleDexFastWithdrawal();
    } else {
      handleDexWithdrawal();
    }
  };

  useEffect(() => {
    sanitizeProvider(setEthereum);
  }, []);

  return (
    <div
      className='operations__form'
      style={{
        display: visibleSideBar === 'withdraw' ? 'block' : 'none',
      }}
    >
      {selectedData && (
        <>
          {selectedOption.value !== 'fast' && (
            <p style={noteStyle}>
              📝 Withdrawals can take upto 7-8 hours to confirm.{' '}
              <span
                style={{ marginLeft: 8, cursor: 'pointer' }}
                onClick={() => setOpenInstructionForWithdrawal((pre) => !pre)}
              >
                <Icon icon='info-circle' className='icon' />
              </span>
            </p>
          )}
          <div className='operations__balance'>
            <div className='operations__label'>AVAILABLE BALANCE</div>
            <div className='operations__counter'>
              {roundUpWithSymbol(selectedData.balance, selectedData.currency) +
                ' ' +
                selectedData.currency.toUpperCase()}
            </div>
            {selectedData.currency !== 'usdt' &&
              selectedData.currency !== baseCurrency && (
                <div className='operations__price'>
                  {formatCurrency(Number(getCryptotoUSDT())?.toFixed(3))}{' '}
                  {baseCurrency?.toUpperCase()}
                </div>
              )}
          </div>
          <div className='actions__field order_book_custom_select'>
            {/* <CustomSelect
              defaultValue={selectedOption}
              options={options}
              onChange={(item) => setSelectedOptions(item)}
            /> */}
            <div className='actions__menu'>
              <ReactTooltip
                place='bottom'
                type={isdark ? 'light' : 'dark'}
                className='tooltip_styles fast_withdrawal'
                id={'fast-withdrawal'}
              />
              {options.reverse().map((option) => (
                <button
                  className={`actions__link ${
                    selectedOption.label === option.label ? 'active' : ''
                  }`}
                  onClick={() => setSelectedOptions(option)}
                  key={option.label}
                >
                  {option.value === 'fast' ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      data-tip={
                        'Processing may time take up to 5 minutes, you will be charged a variable fee by the Brine platform, but there will be no gas fees.'
                      }
                      data-for={'fast-withdrawal'}
                    >
                      <span>{option.label}</span>
                      <span>
                        <svg
                          // className='icon'
                          // width='100%'
                          // height='100%'
                          viewBox='0 0 1024 1024'
                          xmlns='http://www.w3.org/2000/svg'
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            width: 12,
                            marginLeft: 4,
                            marginTop: -3,
                          }}
                        >
                          <path
                            d='M0 512c0-281.6 230.4-512 512-512s512 230.4 512 512c0 281.6-230.4 512-512 512s-512-230.4-512-512zM128 512c0 211.2 172.8 384 384 384s384-172.8 384-384c0-211.2-172.8-384-384-384s-384 172.8-384 384z'
                            fill='rgb(128, 129, 145)'
                          ></path>
                          <path
                            d='M448 448h128v320h-128v-320z'
                            fill='rgb(128, 129, 145)'
                          ></path>
                          <path
                            d='M576 320c0 35.346-28.654 64-64 64s-64-28.654-64-64c0-35.346 28.654-64 64-64s64 28.654 64 64z'
                            fill='rgb(128, 129, 145)'
                          ></path>
                        </svg>
                      </span>
                    </div>
                  ) : (
                    <span>{option.label}</span>
                  )}
                </button>
              ))}
            </div>
          </div>
          <WalletWithdrawFormInput
            label='AMOUNT TO WITHDRAW'
            value={amountToWithdraw}
            onChange={(e) => setAmountToWithdraw(e.target.value)}
            min='0'
            placeholder='0'
            type='number'
            onInput={maxLengthCheck}
            onWheel={numberInputOnWheelPreventChange}
            active={true}
          />
          {selectedOption.value == 'fast' && (
            <>
              <WalletWithdrawFormInput
                label='TRANSACTION FEE'
                // iconName='info-square'
                value={`${getFastWithdrawalFee(
                  selectedData?.currency
                )} ${selectedData?.currency?.toUpperCase()} (${Number(
                  (
                    lastPrice(selectedData?.currency + baseCurrency) *
                    getFastWithdrawalFee(selectedData?.currency)
                  ).toFixed(5)
                )} ${baseCurrency?.toUpperCase()})`}
                disabled
                disabledProperty
              />
              <WalletWithdrawFormInput
                label={'total'.toUpperCase()}
                // iconName='info-square'
                value={`${Number(
                  (
                    Number(getFastWithdrawalFee(selectedData?.currency)) +
                    Number(amountToWithdraw)
                  ).toFixed(8)
                )} ${selectedData?.currency?.toUpperCase()} (${Number(
                  (
                    lastPrice(selectedData?.currency + baseCurrency) *
                    (Number(getFastWithdrawalFee(selectedData?.currency)) +
                      Number(amountToWithdraw))
                  ).toFixed(5)
                )} ${baseCurrency.toUpperCase()})`}
                disabled
                disabledProperty
              />
            </>
          )}
          {selectedData.balance != null && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                marginLeft: -23,
                marginTop: -18,
                marginBottom: 20,
              }}
            >
              {perValue.map((value, idx) => (
                <span
                  className='numbers_slider'
                  key={idx}
                  onClick={() => handlePercentInWithdraw(value)}
                >
                  {value}%
                </span>
              ))}
            </div>
          )}
          <div style={{ display: 'grid', placeItems: 'center' }}>
            {processLoading ? (
              <LoadingBtn style={{ display: 'block', width: '100%' }} />
            ) : (
              <>
                {selectedOption.value == 'fast' ? (
                  <>
                    {Number(selectedData.balance) <
                    Number(amountToWithdraw) +
                      Number(getFastWithdrawalFee(selectedData?.currency)) ? (
                      <InsufficientBalanceBtn />
                    ) : (
                      <CustomButton
                        onClick={handleDexFastWithdrawal}
                        title={`Withdraw ${selectedData?.currency?.toUpperCase()}`}
                        disabled={btnDisable}
                        style={{ display: 'block', width: '100%' }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {Number(selectedData.balance) < amountToWithdraw ? (
                      <InsufficientBalanceBtn />
                    ) : (
                      <CustomButton
                        onClick={handleDexWithdrawal}
                        title={`Withdraw ${selectedData?.currency?.toUpperCase()}`}
                        disabled={btnDisable}
                        style={{ display: 'block', width: '100%' }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {selectedOption.value === 'normal' && (
            <div style={{ marginTop: 25 }}>
              <WarnBox>
                <h1>Note:</h1>
                <p>
                  Normal withdrawals are a 2-step process. Once you initiate
                  your withdrawal, it will take up to 24 hours to process. Once
                  it has been processed, you can send the funds to your wallet.
                  You need to come back to brine.fi to complete your withdrawal.
                </p>
              </WarnBox>
            </div>
          )}
          <LatestActivity
            selectedOption={selectedOption}
            setSelectedOptions={setSelectedOptions}
          />
        </>
      )}
      <div className='share__popup network__error'>
        <Popup
          state={openWithdrawalStartPopup}
          setState={setOpenWithdrawalStartPopup}
        >
          <div>
            <Lottie options={defaultOptions} height={200} width={200} />
            <p style={{ ...PopupStyles, marginBottom: 10 }}>
              {selectedOption.value === 'fast'
                ? 'Your withdrawal is now processing and can be tracked in the history section. (ETA : <5 mins)'
                : 'Your withdrawal is now processing, please wait upto 12 hours to redeem the withdrawal to your wallet 🙏.'}{' '}
            </p>
          </div>
        </Popup>
      </div>
      <Popup
        state={openInstructionForWithdrawal}
        setState={setOpenInstructionForWithdrawal}
      >
        <InstructionForWithdrawal setState={setOpenInstructionForWithdrawal} />
      </Popup>
      <div className='share__popup network__error'>
        <Popup state={openVaultIdPopup} setState={setOpenVaultIdPopup}>
          <div>
            <Lottie
              options={processAnimationOptions}
              height={200}
              width={200}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  ...PopupStyles,
                }}
              >
                One time setup for withdrawal is in progress please try in a
                minute
              </p>
              <CustomButton
                title='Try Again'
                // style={{ marginTop: 20 }}
                onClick={handleTryAgain}
              />
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default WalletWithdraw;

function Stepper() {
  return (
    <div className='step-track'>
      <StepperItem
        htmlElement={<p>Initiate your Withdrawal request on Brine.</p>}
      />
      <StepperItem
        htmlElement={
          <p>
            Wait for upto <span style={{ color: '#8173E0' }}>24 hours.</span>
          </p>
        }
        describeTimeline={true}
      />
      <StepperItem
        htmlElement={
          <p>
            The status for your Withdrawal request is now{' '}
            <span style={{ color: '#4fbf67' }}>READY</span> in the{' '}
            <span style={{ color: '#8173E0' }}>History Tab</span>.
          </p>
        }
      />
      <StepperItem
        htmlElement={
          <p>
            Click on <span style={{ color: '#8173E0' }}>Withdraw</span> below to
            complete the Withdrawal transaction.
          </p>
        }
      />
    </div>
  );
}

export function StepperItem({
  describeTimeline,
  title,
  htmlElement,
  describeHTMLElement,
  lineStyles,
  isDone,
  ...otherStepperStyle
}) {
  return (
    <div
      className='step-track-step'
      style={{
        height: '5rem',
      }}
      {...otherStepperStyle}
    >
      <div
        className='step-track-status'
        style={{
          position: 'relative',
        }}
      >
        <span
          className={
            isDone ? 'step-track-status-dot completed' : 'step-track-status-dot'
          }
        ></span>
        {/* {describeTimeline ? (
          <span className='step-track-status-dot stopwatch'></span>
        ) : (
          <span className='step-track-status-dot'></span>
        )} */}
        <span className='step-track-status-line' style={lineStyles}></span>
        {describeTimeline && (
          <span
            className='step-track-text-sub'
            style={{
              height: '4rem',
              color: 'rgb(108, 93, 211)',
              fontFamily: 'Poppins',
              fontSize: '12px',
              position: 'absolute',
              left: '22px',
              width: '200px',
              top: '45px',
            }}
          >
            <i className='fas fa-stopwatch'></i>{' '}
            {NORMAL_WITHDRAWAL_CONFIRMATION_HOURS} hours
          </span>
        )}

        {describeHTMLElement && (
          <span
            className='step-track-text-sub'
            style={{
              height: '4rem',
              color: 'rgb(108, 93, 211)',
              fontFamily: 'Poppins',
              fontSize: '12px',
              position: 'absolute',
              left: '30px',
              width: '200px',
              top: '45px',
            }}
          >
            {describeHTMLElement}
          </span>
        )}
      </div>

      <div className='step-track-text'>
        {htmlElement ? (
          <div className='step-track-text-stat'>{htmlElement}</div>
        ) : (
          <p className='step-track-text-stat'>{title}</p>
        )}
      </div>
    </div>
  );
}
