import Lottie from 'react-lottie';
import { useViewport } from '../../../utils/hooks';
import animationData from '../../../assests/json/done.json';

function SucccessLottie({ children }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  const { width } = useViewport();
  return (
    <div>
      <Lottie options={defaultOptions} height={100} width={100} />
      {children && (
        <p
          style={{
            // paddingLeft: width > 750 ? 50 : 0,
            // paddingRight: width > 750 ? 50 : 0,
            marginTop: 20,
            textAlign: 'center',
            fontFamily: 'Poppins',
            marginBottom: 10,
          }}
        >
          {children}
        </p>
      )}
    </div>
  );
}

export default SucccessLottie;
