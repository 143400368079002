import { webSocketUrl } from '../../urls';

export default class SocketClient {
  constructor(base_url, path) {
    this.baseUrl = `${webSocketUrl}/public`;
    this.tvIntervals = {
      1: '1m',
      3: '3m',
      5: '5m',
      15: '15m',
      30: '30m',
      60: '1h',
      120: '2h',
      240: '4h',
      360: '6h',
      480: '8h',
      720: '12h',
      D: '1d',
      '1D': '1d',
      '3D': '3d',
      W: '1w',
      '1W': '1w',
      M: '1M',
      '1M': '1M',
    };
    this.streams = {}; // e.g: {'BTCUSDT': { paramStr: '', data:{}, listener:  } }
    this.marketPair = '';
    this.symbol = '';
    this.interval = '';
    this._createSocket();
  }

  periodsMapNumber = {
    '1m': 1,
    '5m': 5,
    '15m': 15,
    '30m': 30,
    '1h': 60,
    '2h': 120,
    '4h': 240,
    '6h': 360,
    '12h': 720,
    '1d': 1440,
    '3d': 4320,
    '1w': 10080,
    '1M': 43800,
  };

  _createSocket() {
    this._ws = new WebSocket(this.baseUrl);
    this._ws.onopen = (e) => {
      console.info(`Brine WS Open`);
      localStorage.setItem('wsStatus', 1);
    };

    this._ws.onclose = () => {
      console.warn('Brine WS Closed');
      localStorage.setItem('wsStatus', 0);
    };

    this._ws.onerror = (err) => {
      console.warn('WS Error', err);
      localStorage.setItem('wsStatus', 0);
    };

    this._ws.onmessage = (msg) => {
      if (!msg?.data) return;
      let socketData = JSON.parse(msg.data);
      if (socketData['success']) return;
      try {
        if (socketData) {
          let symbol = Object.keys(socketData)[0];
          let webSocketData = socketData[symbol];
          // Update data
          let lastSocketData = {
            time: webSocketData[0] * 1000,
            close: webSocketData[4],
            open: webSocketData[1],
            high: webSocketData[2],
            low: webSocketData[3],
            volume: webSocketData[5],
            closeTime: webSocketData[0] * 1000,
            openTime:
              (webSocketData[0] -
                this.periodsMapNumber[this.interval] * 60 * 1000) *
              1000,
          };
          if (Object.keys(this.streams).length && this.symbol.length) {
            this.streams[this.symbol].data = lastSocketData;
            this.streams[this.symbol].listener(lastSocketData);
          }
        }
      } catch (e) {
        console.warn(e);
      }
    };
  }

  subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
    lastDailyBar
  ) {
    try {
      let paramStr = `${symbolInfo.name.toLowerCase()}.kline-${
        this.tvIntervals[resolution]
      }`;

      let subscribeWsText = JSON.stringify({
        event: 'subscribe',
        streams: [
          `${symbolInfo.name.toLowerCase()}.kline-${
            this.tvIntervals[resolution]
          }`,
        ],
      });
      if (this._ws.readyState === 1) {
        this._ws.send(subscribeWsText);
        this.marketPair = paramStr;
        this.symbol = symbolInfo.name;
        this.interval = this.tvIntervals[resolution];
        this.streams[symbolInfo.name] = {
          //register multiple streams in streams object
          paramStr,
          data: {},
          listener: onRealtimeCallback,
        };
      }
    } catch (e) {
      console.error(e);
    }
  }

  unsubscribeFromStream(subscriberUID) {
    try {
      if (this.marketPair) {
        const obj = { event: 'unsubscribe', streams: [this.marketPair] };
        this.marketPair = '';
        this.symbol = '';
        if (this._ws.readyState === 1) {
          this._ws.send(JSON.stringify(obj));
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}
