import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBalance, useWebSocketData } from '../../../../utils/hooks';
import { SkeletonContainer } from '../../../../components';

function ExchangeOrderTable({ selectedCell, setTrade, tradeMode }) {
  const [orderData, setOrderData] = useState(null);
  const [orderbids, setOrderBids] = useState([]);
  const [orderasks, setOrderAsks] = useState([]);

  const { symbol } = useParams();

  const { lastPrice, getStatus } = useWebSocketData();
  const { formatCurrency } = useBalance();

  // const {
  // getOrderBook: { loading, error, orderBookData, reRender },
  //   theme: { isdark },
  //   getAssests: { assetsData },
  // } = useSelector((state) => state);

  const { loading, error, orderBookData, reRender } = useSelector(
    (state) => state.getOrderBook
  );

  useEffect(() => {
    if (selectedCell.symbol !== 'inr' && !loading && orderBookData) {
      setOrderData(orderBookData[selectedCell?.symbol + symbol?.split('-')[1]]);
    }
  }, [selectedCell.symbol, loading, orderBookData, reRender]);

  useEffect(() => {
    getHighestBidsValue();
    getHighestAsksValue();
  }, [selectedCell.symbol, orderData]);

  // //console.log(orderData);

  function percentage(partialValue, totalValue) {
    const value = (100 * partialValue) / totalValue;
    return value?.toFixed();
  }

  // red - Lowest top ()
  // green - highest top (bids)

  function getHighestBidsValue() {
    // const newArr = orderData?.bids?.splice(0, 5);
    const newArr = orderData;
    const data = newArr?.bids?.sort((a, b) => b[0] - a[0])?.slice(0, 5);
    const orderWithPer = data?.map((item) => {
      return {
        data1: item[0],
        data2: item[1],
        per: percentage(item[1], data[0][1]),
      };
    });
    //console.log(orderWithPer, 'orderWithPer');
    setOrderBids(orderWithPer);
  }

  function getHighestAsksValue() {
    const newArr = orderData;
    const data = newArr?.asks?.sort((a, b) => a[0] - b[0])?.slice(0, 5);
    const orderWithPer = data?.map((item) => {
      //console.log(data[0][1], item[0], item[1]);
      return {
        data1: item[0],
        data2: item[1],
        per: percentage(item[1], data[0][1]),
      };
    });
    setOrderAsks(orderWithPer);
  }

  return (
    <>
      {/* {!loading && orderData && orderBookData && orderbids && assetsData && (
        <div
          className={`orders__price ${
            getStatus(`${selectedCell?.symbol}${symbol?.split('-')[1]}`) ===
            'positive'
              ? 'color-green'
              : 'color-red'
          } `}
          style={{
            border: 'none',
            backgroundColor: isdark ? '#242731' : '#ffff',
          }}
        >
          {' '}
          {formatCurrency(
            lastPrice(`${selectedCell?.symbol}${symbol?.split('-')[1]}`)
          )}{' '}
          {symbol?.split('-')[1]?.toUpperCase()}
        </div>
      )} */}
      {!tradeMode && (
        <>
          {loading || !orderData || !orderBookData || !orderbids ? (
            <SkeletonContainer
              height={'300px'}
              width={'100%'}
              style={{ borderRadius: '10px' }}
            />
          ) : (
            <div className='exchange__order-book'>
              <div className='orders__table exchange_chart_order'>
                <div className={`orders__price  color-red`}>
                  Sell Order Book
                </div>
                <div className='orders__row orders__row_head'>
                  <div className='orders__cell'>
                    AMOUNT ({selectedCell.symbol})
                  </div>
                  <div className='orders__cell'>
                    PRICE ({symbol?.split('-')[1]})
                  </div>
                </div>
                {!loading &&
                  orderData &&
                  orderasks &&
                  orderasks.map((data, idx) => (
                    <div className='orders__row' key={idx}>
                      <div className='orders__cell orders__up'>
                        {data.data2}
                        <div
                          className='orders__bg'
                          style={{
                            width: `${data.per}%`,
                          }}
                        ></div>
                      </div>
                      <div className='orders__cell'> {data.data1}</div>
                    </div>
                  ))}
              </div>
              <div className='orders__table exchange_chart_order'>
                <div className={`orders__price color-green`}>
                  Buy Order Book
                </div>
                <div className='orders__row orders__row_head'>
                  <div className='orders__cell'>
                    AMOUNT ({selectedCell.symbol})
                  </div>
                  <div className='orders__cell'>
                    PRICE ({symbol?.split('-')[1]})
                  </div>
                </div>
                {!loading &&
                  orderData &&
                  orderbids &&
                  orderbids.map((data, idx) => (
                    <div className='orders__row' key={idx}>
                      <div className='orders__cell orders__down'>
                        {data.data2}
                        <div
                          className='orders__bg'
                          style={{ width: `${data.per}%` }}
                        ></div>
                      </div>
                      <div className='orders__cell'> {data.data1}</div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ExchangeOrderTable;
