import { useSelector, useDispatch } from 'react-redux';
import { toggleNavbar } from '../../../actions/navbarAction';
import CollapseIcon from '../nav-svgs/collapse';
import { Link } from 'react-router-dom';
import { Icon, Image } from '../../../components';
import { images } from '../../../utils/image/img';

function NavHeader() {
  const { isdark } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  return (
    <div className='sidebar__head'>
      <Link
        className='sidebar__logo'
        to={(location) => ({ ...location, pathname: '/' })}
      >
        <Image
          className={
            'sidebar__pic' +
            (isdark ? 'sidebar__pic_dark' : 'sidebar__pic_light')
          }
          src={isdark ? images.logoBigWhite : images.logoBigDark}
          style={{ width: '130px' }}
          alt='App logo'
        />
      </Link>
      <button
        className='sidebar__toggle'
        onClick={() => dispatch(toggleNavbar())}
      >
        <CollapseIcon />
      </button>
      <button
        className='sidebar__close'
        onClick={() => dispatch(toggleNavbar())}
      >
        <Icon icon='close' className='icon' />
      </button>
    </div>
  );
}

export default NavHeader;
