import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useBalance, useSegment } from '../../../utils/hooks';
import { truncate } from '../../../utils/string-methods';
import CustomButton from '../../common/custom-button/custom-button';

export const formatPair = (pair) => {
  if (pair) {
    const symbol = pair.match(/usdt|usdc/)[0];
    const formattedPair = pair.replace(symbol, `-${symbol}`);
    return formattedPair;
  }
};

export function UserScore({ userScore, leaderBoardData, selectedCampaign }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { state: userVia } = useSelector((state) => state.userVia);

  const location = useLocation();
  const history = useHistory();
  const { sendTrackEvent } = useSegment();
  const { toFixedTrunc } = useBalance();

  return (
    <div
      // className={
      //   userInfo?.token?.access &&
      //   userScore?.user_data?.rank?.rank != 1 &&
      //   leaderBoardData?.length !== 0 &&
      //   userScore?.user_data?.rank?.rank !== -1
      //     ? 'your_stats__wrapper'
      //     : 'your_stats__wrapper your__score__without_jwt'
      // }
      style={
        {
          // marginTop: 6,
          // width: !userInfo?.token?.access ? '100%' : 'inherit',
        }
      }
    >
      <div className='your_stats__container'>
        <div
          className={
            // userInfo?.token?.access &&
            // userScore?.user_data?.rank?.rank != 1 &&
            // leaderBoardData?.length !== 0 &&
            // userScore?.user_data?.rank?.rank !== -1
            //   ? 'your_stats'
            //   : 'your_stats_without_jwt'
            'your_stats'
          }
        >
          <h1
            className='text-mask'
            style={{
              fontSize: 35,
            }}
          >
            {userInfo?.token?.access &&
            userScore?.user_data?.rank?.rank &&
            userScore?.user_data?.rank?.rank !== -1
              ? `#${userScore?.user_data?.rank?.rank}`
              : '---'}
          </h1>
          <p>Your rank</p>
          <div className='user__score'>
            <div>
              <h6 // className='text-mask'
                style={{
                  fontSize: 15,
                  color: '#8173E0',
                }}
              >
                {userInfo?.token?.access &&
                userScore?.user_data?.rank?.traded_volume
                  ? toFixedTrunc(userScore?.user_data?.rank?.traded_volume, 3)
                  : '---'}
              </h6>
              <p
                style={{
                  marginTop: -6,
                }}
              >
                Your score
              </p>
            </div>
            <div>
              <h6 // className='text-mask'
                style={{
                  fontSize: 15,
                  color: '#8173E0',
                }}
              >
                {userInfo?.token?.access && userVia?.address
                  ? truncate(userVia?.address, 10)
                  : '---'}
              </h6>
              <p
                style={{
                  marginTop: -6,
                }}
              >
                Eth Address
              </p>
            </div>
          </div>
          {!userInfo?.token?.access && (
            // <h6 style={{ textAlign: 'center', marginTop: 16, fontSize: 12 }}>
            //   Connect wallet to view your score
            // </h6>
            <div style={{ marginTop: 10, textAlign: 'center' }}>
              {/* <Link
                  to={(location) => ({
                    ...location,
                    pathname: `/signin/`,
                  })}
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: '#fff',
                    color: '#fff',
                    fontSize: 12,
                  }}
                >
                  Connect wallet to view your score
                </Link> */}
              <div
                className={`header__item  header__item_download meta_instruction_item`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 0,
                  marginTop: 20,
                }}
              >
                <CustomButton
                  title='View Score'
                  onClick={() => {
                    sendTrackEvent('buttonClicked', {
                      title: 'Connect Wallet',
                      action: 'Routing to Login Page',
                    });
                    history.push({
                      pathname: '/signin',
                      search: location.search,
                      state: { from: location },
                    });
                  }}
                  style={{
                    minWidth: '100%',
                  }}
                />
              </div>
            </div>
          )}
          {selectedCampaign?.isActive ? (
            <>
              {userInfo?.token?.access &&
                userScore &&
                userScore?.user_data?.rank?.rank === -1 && (
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <div
                      className={`header__item  header__item_download meta_instruction_item`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 0,
                        marginTop: 20,
                      }}
                    >
                      <CustomButton
                        title='Trade to get started'
                        onClick={() => {
                          sendTrackEvent('buttonClicked', {
                            title: 'Trade to get started',
                            action: 'Trade to get started the Campaign',
                          });
                          history.push({
                            pathname: `/exchange/${formatPair(
                              selectedCampaign?.market
                            )}`,
                            search: location.search,
                            state: { from: location },
                          });
                        }}
                        style={{
                          minWidth: '100%',
                        }}
                      />
                    </div>
                  </div>
                )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
