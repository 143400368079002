import moment from 'moment';
import { scroller } from 'react-scroll';
import { useBalance, useWalletMethods } from '../../../../utils/hooks';
import { images } from '../../../../utils/image/img';
import { Image } from '../../../../components';
import { NORMAL_WITHDRAWAL_CONFIRMATION_HOURS } from '../../../../utils/constants';

export function transformString(inputString) {
  // Replace underscores with spaces
  const transformedString = inputString?.replace('_', ' ');
  return transformedString;
}

const classNameByWithdrawStatus = {
  RECEIVED: 'color-orange',
  VERIFIED: 'color-orange',
  INITIATED: 'color-orange',
  PROCESSING: 'color-orange',
  PROCESSED: 'color-orange',
  IN_PROGRESS: 'color-orange',
  SUCCESS: 'color-green',
  FAILED: 'color-red',
  INVALID: 'color-red',
  RESOLVED: 'color-orange',
  CONFIRMING: 'color-orange',
  TRANSFER_INITIATED: 'color-orange',
};

function ActivitiesCell({
  data,
  sideBardata,
  handleSideBarToggle,
  selectedFilterValue,
}) {
  const { getInfoAboutCoinsByTokenId, getInfoAboutCoins } = useBalance();
  const { formatCurrency, roundUpWithSymbol, preventScientific } = useBalance();
  const { networkConfig } = useWalletMethods();

  const scrollToSection = () => {
    scroller.scrollTo('header', {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  const getCountDown = (time) => {
    let date1 = new Date(time);
    let date2 = new Date();
    let hoursDiff = Math.abs(date1 - date2) / 36e5;
    return { hoursDiff: Math.trunc(hoursDiff), date1, date2 };
  };

  let isElementActive =
    selectedFilterValue !== 'Deposits'
      ? sideBardata?.id == data?.id
      : sideBardata?.deposit_blockchain_hash == data?.deposit_blockchain_hash;

  return (
    <div
      className={`activity__row ${isElementActive ? 'active' : ''}`}
      onClick={() => {
        handleSideBarToggle(data);
        // scrollToSection();
      }}
    >
      <div className='activity__cell'>
        <div className='activity__size'>
          {/* <Image
            src={sideBardata.id === data.id ? images.max : images.min}
            alt=''
          /> */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='15'
            fill='none'
            viewBox='0 0 288 288'
            style={{
              transform: isElementActive ? 'rotate(180deg)' : 'revert',
            }}
          >
            <path
              fill='#8173E0'
              d='M252 24h-72a11.998 11.998 0 00-12 12 11.998 11.998 0 0012 12h43.032l-99.516 99.516a12.003 12.003 0 00-.149 17.117 11.998 11.998 0 0017.117-.149L240 64.968V108c0 3.183 1.264 6.235 3.515 8.485a11.996 11.996 0 0016.97 0A11.996 11.996 0 00264 108V36a11.998 11.998 0 00-12-12z'
            ></path>
            <path
              fill='#8173E0'
              d='M228 127.056a11.996 11.996 0 00-8.485 3.515 11.996 11.996 0 00-3.515 8.485v85.956A15 15 0 01201.012 240H62.988A15 15 0 0148 225.012V86.988A15 15 0 0162.988 72h74.664a11.998 11.998 0 0012-12 11.998 11.998 0 00-12-12H62.988A39.035 39.035 0 0024 86.988v138A39.034 39.034 0 0062.988 264h138A39.036 39.036 0 00240 225.012v-85.956c0-3.183-1.264-6.235-3.515-8.485a11.996 11.996 0 00-8.485-3.515z'
            ></path>
          </svg>
        </div>
      </div>
      <div className='activity__cell'>
        <div className='activity__company'>
          {selectedFilterValue !== 'Orders' &&
            selectedFilterValue !== 'Deposits' &&
            selectedFilterValue !== 'Withdraws' &&
            selectedFilterValue !== 'fast-withdraws' && (
              <div className='activity__logo'>
                {selectedFilterValue !== 'Trades' ? (
                  <Image src={getInfoAboutCoins(data?.currency)?.logo} alt='' />
                ) : (
                  <Image
                    src={getInfoAboutCoins(data?.fee_currency)?.logo}
                    alt=''
                  />
                )}
              </div>
            )}
          {selectedFilterValue === 'Deposits' && (
            <div className='activity__logo' style={{ position: 'relative' }}>
              <Image
                src={getInfoAboutCoinsByTokenId(data?.token_id)?.logo}
                alt=''
              />
              <Image
                src={networkConfig[data?.network?.toLowerCase()]?.image}
                alt=''
                className='network__logo'
              />
            </div>
          )}
          {selectedFilterValue === 'fast-withdraws' && (
            <div className='activity__logo' style={{ position: 'relative' }}>
              <Image src={getInfoAboutCoins(data?.token_id)?.logo} alt='' />
              <Image
                src={networkConfig[data?.network?.toLowerCase()]?.image}
                alt=''
                className='network__logo'
              />
            </div>
          )}
          {selectedFilterValue === 'Withdraws' && (
            <div className='activity__logo'>
              <Image src={getInfoAboutCoins(data?.token_id)?.logo} alt='' />
            </div>
          )}
          <div
            className='activity__name'
            style={{
              color: data?.side
                ? data?.side == 'buy'
                  ? '#4fbf67'
                  : '#ff5353'
                : 'inherit',
            }}
          >
            {(selectedFilterValue === 'Withdraws' ||
              selectedFilterValue === 'fast-withdraws') &&
              `Withdrawn ${data?.token_id?.toUpperCase()}`}
            {selectedFilterValue === 'Deposits' &&
              `Deposited ${getInfoAboutCoinsByTokenId(
                data?.token_id
              )?.symbol?.toUpperCase()}`}
            {selectedFilterValue === 'Trades' &&
              `${data?.side?.toUpperCase()} ${
                data?.amount
              } - ${data?.market?.toUpperCase()}`}
            {selectedFilterValue === 'Orders' &&
              `${data?.side?.toUpperCase()} - ${data?.market?.toUpperCase()}`}
          </div>
        </div>
      </div>
      <div className='activity__cell'>
        <div className='activity__date'>
          {moment(data?.created_at).format('llll')}
        </div>
      </div>
      <div className='activity__cell'>
        <div className='activity__price'>
          {selectedFilterValue === 'Trades'
            ? formatCurrency(Number(data?.price))
            : selectedFilterValue === 'Orders'
            ? data?.price
              ? formatCurrency(Number(data?.price))
              : Number(data?.avg_price) === 0
              ? '-----'
              : formatCurrency(Number(data?.avg_price))
            : selectedFilterValue === 'Deposits'
            ? preventScientific(
                Number(data?.amount) /
                  Math.pow(
                    10,
                    getInfoAboutCoinsByTokenId(data?.token_id)?.quanitization
                  )
              ) +
              ' ' +
              getInfoAboutCoinsByTokenId(data?.token_id)?.symbol?.toUpperCase()
            : selectedFilterValue === 'Withdraws' ||
              selectedFilterValue === 'fast-withdraws'
            ? roundUpWithSymbol(data?.amount, data?.token_id) +
              ' ' +
              data?.token_id?.toUpperCase()
            : data?.amount + ' ' + data?.currency?.toUpperCase()}
        </div>
      </div>
      <div className='activity__cell activity__word_wrap'>
        {selectedFilterValue === 'Withdraws' && (
          <>
            <div
              className={`activity__address ${
                data.transaction_status === 'CONFIRMING' &&
                getCountDown(data?.created_at).hoursDiff >=
                  NORMAL_WITHDRAWAL_CONFIRMATION_HOURS
                  ? 'color-green'
                  : classNameByWithdrawStatus[data.transaction_status]
              }`}
            >
              {getCountDown(data?.created_at).hoursDiff >=
                NORMAL_WITHDRAWAL_CONFIRMATION_HOURS &&
              data.transaction_status === 'CONFIRMING'
                ? 'READY'
                : transformString(data.transaction_status?.toUpperCase())}
            </div>
          </>
        )}
        {selectedFilterValue === 'fast-withdraws' && (
          <>
            <div
              className={`activity__address ${
                data.blockchain_status === 'SUCCESS' &&
                data.transaction_status === 'TRANSFER_INITIATED'
                  ? 'color-green'
                  : classNameByWithdrawStatus[data.transaction_status]
              }`}
            >
              {data.blockchain_status === 'SUCCESS' &&
              data.transaction_status === 'TRANSFER_INITIATED'
                ? 'SUCCESS'
                : transformString(data.transaction_status?.toUpperCase())}
            </div>
          </>
        )}
        {selectedFilterValue === 'Deposits' && (
          <>
            {data?.blockchain_deposit_status === 'Failed' ||
            data?.brine_deposit_status === 'Failed' ? (
              <div className={`activity__address color-red`}>Failed</div>
            ) : (
              <div
                className={`activity__address ${
                  data?.blockchain_deposit_status !== 'Success' ||
                  data?.brine_deposit_status !== 'Success'
                    ? 'color-orange'
                    : 'color-green'
                } `}
              >
                {data?.blockchain_deposit_status === 'Success' &&
                data?.brine_deposit_status === 'Success'
                  ? 2
                  : data?.blockchain_deposit_status === 'Success' &&
                    data?.brine_deposit_status !== 'Success'
                  ? 1
                  : 0}{' '}
                of 2{' '}
                {data?.blockchain_deposit_status !== 'Success' ||
                data?.brine_deposit_status !== 'Success'
                  ? 'Pending'
                  : 'Success'}
              </div>
            )}
          </>
        )}
        {selectedFilterValue === 'Trades' && (
          <div className='activity__address'>
            {parseFloat(data?.total)?.toFixed(2)}
          </div>
        )}
        {selectedFilterValue === 'Orders' && (
          <div className='activity__address'>{data?.origin_volume}</div>
        )}
      </div>
      {selectedFilterValue === 'Orders' && (
        <div className='activity__cell activity__address'>
          {data?.executed_volume}
        </div>
      )}
    </div>
  );
}

export default ActivitiesCell;
