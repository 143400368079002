function DetailsAbout({ coinDetails }) {
  return (
    <>
      <div className='details__title h6'>About {coinDetails?.name}</div>
      <div className='details__row'>
        <div className='details__col'>
          <div className='details__text'>{coinDetails?.description}</div>
        </div>
        {/* <div className="details__col">
          <div className="details__links">
            <a className="details__link" href="/">
              <Icon icon="link" className="icon" />
              Official Website
            </a>
            <a className="details__link" href="/">
              <Icon icon="link" className="icon" />
              Whitepaper
            </a>
            <a className="details__link" href="/">
              <Icon icon="link" className="icon" />
              Source Code
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default DetailsAbout;
