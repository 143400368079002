import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssests,
  getCoinsDetails,
  getDepositDetails,
} from '../../../actions/assestsAction';
import { partialStateTabs, statusData } from '../../../utils/data';
import {
  CustomButton,
  CustomSelect,
  Tags,
  WidgetCard,
  MarketWidgetsCard,
  WidgetSkeleton,
  Icon,
  NotFoundedAnimation,
  SkeletonContainer,
} from '../../../components';
import _, { unionBy } from 'lodash';
import {
  useWebSocketData,
  useAnalytics,
  useSegment,
} from '../../../utils/hooks';
import { getQueryParams } from '../../../utils/query-helper';

// const filterData = [
//   {
//     label: 'Market Cap',
//     value: 'Market Cap',
//   },
//   {
//     label: 'A - Z',
//     value: 'A - Z',
//   },
//   {
//     label: 'Volumes',
//     value: 'Volumes',
//   },
//   {
//     label: 'Last 24h',
//     value: 'Last 24h',
//   },
//   {
//     label: 'Price',
//     value: 'Price',
//   },
// ];

// function TabSkeleton() {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//         width: '90%',
//         justifyContent: 'space-between',
//         marginBottom: 20,
//       }}
//     >
//       {[1, 2, 3, 4, 5].map((item) => (
//         <div style={{ marginRight: 20 }} key={item}>
//           <SkeletonContainer
//             style={{ width: 95, height: 40, borderRadius: 10 }}
//           />
//         </div>
//       ))}
//       <div style={{ marginRight: 20 }}>
//         <SkeletonContainer
//           style={{ width: 155, height: 40, borderRadius: 10 }}
//         />
//       </div>
//     </div>
//   );
// }

function Widgets() {
  const [selectedFilter, setSelectedFilter] = useState(statusData[0]);
  const [filterByTabData, setFilterByTabData] = useState(null);
  const [showMore, setShowMore] = useState(true);
  const [watchlistData, setWatchlistData] = useState([]);
  const [tabs, setTabs] = useState(statusData);
  const { sendTrackEvent } = useSegment();
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const showCard = 10;
  const dispatch = useDispatch();
  const {
    error,
    loading: assestsLoading,
    assetsData,
  } = useSelector((state) => state.getAssests);
  const { userInfo } = useSelector((state) => state.userLogin);
  // const {
  // getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
  // depositDetails: { details },
  // } = useSelector((state) => state);

  const {
    loading: orderLoading,
    error: orderError,
    orderBookData,
  } = useSelector((state) => state.getOrderBook);

  const { details } = useSelector((state) => state.depositDetails);

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { response: watchListData, loading } = useSelector(
    (state) => state.userWatchList
  );

  const { bannerDetails } = useSelector((state) => state.getBanner);

  const { getTopGainer, getTopLooser, getSocketFunctionalityInfo } =
    useWebSocketData();

  const handleLoadMore = () => {
    // analytics?.track('Clicked the coins pagination!', {
    //   uid: userInfo?.payload?.uid,
    //   from: 'Homepage',
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });
    setShowMore((prev) => !prev);
  };

  useEffect(() => {
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!details) {
      dispatch(getDepositDetails());
    }
    // //console.log(assetsData);
    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }
  }, []);

  const getWatchListCoins = () => {
    const items = [];
    const filteredItem = watchListData?.payload?.find(
      (item) => item?.name === 'watch-list-1'
    );
    //console.log('called', watchListData?.payload?.coins?.coins);
    filteredItem?.coins?.coins?.forEach((watchList) => {
      coinsDetails?.convertedArray?.forEach((item) => {
        //console.log(watchList == item.symbol, watchList, item.symbol);
        if (watchList == item.symbol) {
          items.push(item);
        }
      });
    });
    setWatchlistData(items);
  };

  const filterByTab = () => {
    if (coinsDetails?.convertedArray) {
      if (selectedFilter.value === 'top_losers') {
        const leastValue = getTopLooser();
        setFilterByTabData(leastValue);
      } else if (selectedFilter.value === 'top_gainers') {
        const topGainerValue = getTopGainer();
        setFilterByTabData(topGainerValue);
      } else if (selectedFilter.value === 'core_assets') {
        setFilterByTabData(coinsDetails.convertedArray);
      } else if (selectedFilter.value === 'watchlist') {
        setFilterByTabData(watchlistData);
      } else if (selectedFilter.value === 'all_markets') {
        setFilterByTabData(bannerDetails?.payload?.markets_with_slash);
      }
    }
  };

  useEffect(() => {
    filterByTab();
    setShowMore(true);
  }, [selectedFilter, bannerDetails, coinsDetails]);

  useEffect(() => {
    if (bannerDetails) {
      setFilterByTabData(bannerDetails?.payload?.markets_with_slash);
    }
  }, [coinsLoading, bannerDetails]);

  useEffect(() => {
    getWatchListCoins();
  }, [loading, coinsLoading, coinsDetails]);

  // const handleWatchList = () => {
  //   if (watchListData?.payload) {
  //     const filteredItem = watchListData?.payload?.find(
  //       (item) => item?.name === 'watch-list-1'
  //     );
  //     // let isThere = tabs?.find((item) => item.value === 'watchlist');
  //     // if (filteredItem?.coins?.coins?.length > 0 && !isThere) {
  //     //   setTabs([
  //     //     ...tabs,
  //     //     {
  //     //       label: 'WatchList',
  //     //       value: 'watchlist',
  //     //     },
  //     //   ]);
  //     // }
  //     if (filteredItem?.coins?.coins?.length) {
  //       setWatchlistData(filteredItem);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   handleWatchList();
  //   return () => setTabs(statusData);
  // }, [loading]);

  // useEffect(() => {
  //   analytics?.track('Filtering coins!', {
  //     keyword: selectedFilter.label,
  //     uid: userInfo?.payload?.uid,
  //     from: 'Homepage',
  //     QueryParams: { ...getQueryParams() },
  //     ip: ip,
  //   });
  // }, [analytics, selectedFilter]);

  useEffect(() => {
    if (searchText.length === 0) {
      setResult(filterByTabData);
    } else {
      if (selectedFilter.value == 'all_markets') {
        const data = bannerDetails?.payload?.markets_with_slash.filter((data) =>
          data.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase())
        );
        setResult(data);
        sendTrackEvent('searchInputChanged', {
          query: searchText,
          resultFound: data,
        });
      } else {
        const data = filterByTabData.filter(
          (data) =>
            data.name
              ?.toLocaleLowerCase()
              ?.includes(searchText?.toLocaleLowerCase()) ||
            data.symbol
              ?.toLocaleLowerCase()
              ?.includes(searchText?.toLocaleLowerCase())
        );
        setResult(data);
        sendTrackEvent('searchInputChanged', {
          query: searchText,
          resultFound: data,
        });
      }
    }
  }, [
    searchText,
    filterByTabData,
    bannerDetails,
    coinsDetails?.convertedArray,
    selectedFilter,
  ]);

  const noData = {
    top_gainers: 'No gainers today!',
    top_losers: 'No losers today!',
  };

  useEffect(() => {
    let { full, partial } = getSocketFunctionalityInfo();
    if (full) {
      setTabs(statusData);
    } else if (partial) {
      setTabs(partialStateTabs);
    } else {
      setTabs(statusData);
    }
  }, [assetsData, orderBookData]);

  useEffect(() => {
    if (selectedFilter) {
      sendTrackEvent('tabSelected', { tabName: selectedFilter?.label });
    }
  }, [selectedFilter]);

  return (
    <div>
      <div className='widgets'>
        {!userInfo?.token && (
          <div className='promotions__stage h5' style={{ marginBottom: 20 }}>
            Explore Assets
          </div>
        )}
        <div className='widgets__sorting' style={{ marginBottom: '11px' }}>
          <div className='widgets__select mobile-show'>
            {!getSocketFunctionalityInfo().full &&
            !getSocketFunctionalityInfo().partial ? (
              <>
                <CustomSelect
                  defaultValue={tabs[0]}
                  options={partialStateTabs}
                  onChange={(data) => setSelectedFilter(data)}
                />
              </>
            ) : (
              <>
                {watchlistData?.length > 0 ? (
                  <CustomSelect
                    defaultValue={tabs[0]}
                    options={[
                      ...tabs,
                      {
                        label: 'WatchList',
                        value: 'watchlist',
                      },
                    ]}
                    onChange={(data) => setSelectedFilter(data)}
                  />
                ) : (
                  <CustomSelect
                    defaultValue={tabs[0]}
                    options={tabs}
                    onChange={(data) => setSelectedFilter(data)}
                  />
                )}
              </>
            )}
          </div>
          {!getSocketFunctionalityInfo().full &&
          !getSocketFunctionalityInfo().partial ? (
            <Tags
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              options={partialStateTabs}
              addLinksclassName='widgets__link'
              addTagclassName='widgets__variants'
            />
          ) : (
            <>
              {watchlistData?.length > 0 ? (
                <Tags
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  options={[
                    ...tabs,
                    {
                      label: 'WatchList',
                      value: 'watchlist',
                    },
                  ]}
                  addLinksclassName='widgets__link'
                  addTagclassName='widgets__variants'
                />
              ) : (
                <Tags
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  options={tabs}
                  addLinksclassName='widgets__link'
                  addTagclassName='widgets__variants'
                />
              )}
            </>
          )}

          {/* <div className="widgets__select" style={{ width: '150px' }}>
          <CustomSelect defaultValue={filterData[1]} options={filterData} />
        </div> */}
          <div className='widget_search'>
            <div className='widget_search__container'>
              <input
                className='actions__input exchange_search__input widget_filter__input '
                type='search'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {!searchText.length && (
              <div className='widget_searchicon__container'>
                <Icon
                  icon='search'
                  className='icon'
                  size={15}
                  color='#808191'
                />
              </div>
            )}
          </div>
        </div>
        {/* {(error || coinsError) && (
        <div className='h4 error-message'>Something went wrong</div>
      )} */}

        {coinsLoading ||
        (!getSocketFunctionalityInfo().full &&
          !getSocketFunctionalityInfo().partial) ||
        !filterByTabData ||
        loading ? (
          <div className='widget__skeleton_container'>
            {[1, 2, 3, 4, 5, 6, 7].map((data, idx) => (
              <WidgetSkeleton key={idx} />
            ))}
          </div>
        ) : (
          <>
            {selectedFilter.value !== 'all_markets' ? (
              <>
                {!coinsLoading &&
                  coinsDetails?.convertedArray &&
                  (getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial) &&
                  !loading &&
                  result &&
                  filterByTabData && (
                    <>
                      <div>
                        {result.length > 0 ? (
                          <div className='widgets__list'>
                            {result
                              ?.slice(
                                0,
                                showMore ? showCard : filterByTabData.length
                              )
                              .map((data, idx) => (
                                <WidgetCard data={data} key={idx} />
                              ))}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'grid',
                              placeItems: 'center',
                              marginTop: -12,
                            }}
                          >
                            <NotFoundedAnimation
                              size='sm'
                              text={
                                noData[selectedFilter.value]
                                  ? noData[selectedFilter.value]
                                  : 'No result found'
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className='widgets__btns'>
                        {result.length > 9 && (
                          <CustomButton
                            title={
                              !showMore ? 'Show less' : 'Discover more assets'
                            }
                            onClick={handleLoadMore}
                          />
                        )}
                      </div>
                    </>
                  )}
              </>
            ) : (
              <>
                {bannerDetails &&
                  result &&
                  filterByTabData &&
                  (getSocketFunctionalityInfo().full ||
                    getSocketFunctionalityInfo().partial) && (
                    <>
                      {result?.length > 0 ? (
                        <div className='widgets__list'>
                          {result
                            ?.slice(
                              0,
                              showMore ? showCard : filterByTabData.length
                            )
                            .map((data, idx) => (
                              <MarketWidgetsCard data={data} key={idx} />
                            ))}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'grid',
                            placeItems: 'center',
                            marginTop: -12,
                          }}
                        >
                          <NotFoundedAnimation text='No coins founded' />
                        </div>
                      )}
                      <div className='widgets__btns'>
                        {result.length > 9 && (
                          <CustomButton
                            title={
                              !showMore ? 'Show less' : 'Discover more assets'
                            }
                            onClick={handleLoadMore}
                          />
                        )}
                      </div>
                    </>
                  )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default Widgets;
