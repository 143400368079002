import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  googleLogin,
  Register,
  userDetails,
} from '../../../actions/userAction';
import {
  LoadingBtn,
  Toast,
  AuthButton,
  FormInput,
  Popup,
  CustomButton,
} from '../../../components';
// import ReCAPTCHA from 'react-google-recaptcha';
import { url } from '../../../utils/urls';
// import GoogleLogin from 'react-google-login';
import {
  GOOGLE_LOGIN_RESET,
  USER_REGISTER_RESET,
} from '../../../constants/userConstant';
import { useAnalytics } from '../../../utils/hooks';
import { getQueryParams } from '../../../utils/query-helper';
import { getAssests, getDepositDetails } from '../../../actions/assestsAction';

function SignUp({ history }) {
  // const { analytics } = useAnalytics();
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [displayingComponent, setDisplayingComponent] = useState('stage-1');
  const captchaSignupRef = useRef();
  const dispatch = useDispatch();
  const [captchaToken, setCaptchaToken] = useState(null);
  const {
    loading,
    error,
    userInfo: userRegisterInfo,
  } = useSelector((state) => state.userRegister);
  const { userInfo: userLoginInfo } = useSelector((state) => state.userLogin);
  const [togglePassword, setTogglePassword] = useState(true);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(true);
  const [openGoogleAuthPopUp, setOpenGoogleAuthPop] = useState(false);
  const [googleToken, setGoogleToken] = useState('');
  const [googleOTP, setGoogleOTP] = useState('');
  const googleLoginState = useSelector((state) => state.googleAuth);
  const location = useLocation();

  const {
    loading: googleLoading,
    error: googleError,
    userInfo: googleLoginInfo,
  } = googleLoginState;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const onChangeCaptcha = (value) => {
    setCaptchaToken(value);
  };

  const responseOTPGoogle = () => {
    dispatch({
      type: USER_REGISTER_RESET,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });
    if (googleOTP) {
      dispatch(googleLogin(googleToken, googleOTP));
    }
  };

  const clearState = () => {
    dispatch({
      type: USER_REGISTER_RESET,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });
  };

  useEffect(() => {
    if (error) {
      setCaptchaToken(null);
      captchaSignupRef.current.reset();
      toast.error(error);
      // analytics?.track('User Sign up failed!', {
      //   from: 'Sign Up Page',
      //   reason: error,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
    }
    if (userRegisterInfo?.token) {
      setCaptchaToken(null);
      // setStepperState('stage-1');
      dispatch(userDetails());
      // analytics?.track('User Sign up success!', {
      //   from: 'Sign Up Page',
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      history.push({ pathname: '/verify', search: location.search });
    }
    if (googleError === 'Please enter the OTP from authenticator app') {
      setOpenGoogleAuthPop(true);
    }
    if (
      googleError &&
      googleError !== 'Please enter the OTP from authenticator app'
    ) {
      toast.error(googleError);
      // analytics?.track('User Google Sign up failed!', {
      //   from: 'Sign Up Page',
      //   reason: googleError,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
    }
    // if (userLoginInfo && userLoginInfo.token) {
    //   history.push('/');
    // }
  }, [userRegisterInfo, userLoginInfo, history, error, googleError]);

  const handleSubmit = () => {
    // analytics?.track('User Sign up button clicked!', {
    //   from: 'Sign Up Page',
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });
    if (
      userInfo.email === '' ||
      userInfo.password === '' ||
      userInfo.confirmPassword === ''
    ) {
      return toast.error('Please fill out ALL required fields.');
    }
    if (!captchaToken && url !== 'dev') {
      return toast.error('Check the recaptcha');
    }
    if (userInfo.password === userInfo.confirmPassword) {
      dispatch({
        type: USER_REGISTER_RESET,
      });

      dispatch({
        type: GOOGLE_LOGIN_RESET,
      });

      dispatch(Register(userInfo.email, userInfo.password, captchaToken));
    } else {
      toast.error('Your password and confirmation password do not match');
    }
  };

  const responseGoogle = (response) => {
    // analytics?.track('User Google Sign up  button clicked!', {
    //   from: 'Sign Up Page',
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });
    if (response['tokenId']) {
      setGoogleToken(response['tokenId']);
      dispatch({
        type: USER_REGISTER_RESET,
      });

      dispatch({
        type: GOOGLE_LOGIN_RESET,
      });
      dispatch(googleLogin(response['tokenId'], false));
    }
  };

  useEffect(() => {
    return () => captchaSignupRef?.current?.reset();
  }, []);

  useEffect(() => {
    dispatch(getAssests());
    dispatch(getDepositDetails());
  }, [dispatch]);

  return (
    <div className='login__col'>
      {/* <Stepper stage={stepperState} /> */}
      <div className='login__form'>
        {displayingComponent === 'stage-1' && (
          <div>
            <div className='login__stage h4'>Sign Up</div>
            <Toast />
            <div>
              <FormInput
                label='Email address'
                type='email'
                name='email'
                value={userInfo.email}
                onChange={(e) => handleChange(e)}
              />
              <div style={{ position: 'relative' }}>
                <FormInput
                  label='password'
                  type={togglePassword ? 'password' : 'text'}
                  name='password'
                  value={userInfo.password}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '40%',
                    right: 32,
                    color: '#808191',
                    cursor: 'pointer',
                  }}
                  onClick={() => setTogglePassword((pre) => !pre)}
                >
                  <i
                    className={`fas fa${
                      togglePassword ? '-eye-slash' : '-eye'
                    }`}
                  ></i>
                </div>
              </div>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <FormInput
                  label='Confirm Password'
                  type={toggleConfirmPassword ? 'password' : 'text'}
                  name='confirmPassword'
                  value={userInfo.confirmPassword}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '40%',
                    right: 32,
                    color: '#808191',
                    cursor: 'pointer',
                  }}
                  onClick={() => setToggleConfirmPassword((pre) => !pre)}
                >
                  <i
                    className={`fas fa${
                      toggleConfirmPassword ? '-eye-slash' : '-eye'
                    }`}
                  ></i>
                </div>
              </div>
              {/* <Link className="login__verify success" href="/">
            Click to verify
            <Icon icon="verify" className="icon" />
            <div className="login__status"></div>
          </Link> */}
              <div className='login__note'>
                By signing up I agree that I am 18 years of age or older, to the{' '}
                <a href='https://kryptoex.s3.ap-south-1.amazonaws.com/static/KryptoUserAgreement.pdf'>
                  User Agreement
                </a>
                ,{' '}
                <a href='https://kryptoex.s3.ap-south-1.amazonaws.com/static/KryptoPrivacyPolicy.pdf'>
                  Privacy Policy
                </a>
                ,
                <a href='https://kryptoex.s3.ap-south-1.amazonaws.com/static/KryptoAML.pdf'>
                  AML Policy
                </a>
                , and {/* <a href="/">E-Sign Consent</a>. */}
              </div>
            </div>
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                marginBottom: 12,
                marginTop: 12,
              }}
            >
              {/* <ReCAPTCHA
                sitekey={captchaKey}
                ref={captchaSignupRef}
                onChange={onChangeCaptcha}
              /> */}
            </div>
            {loading ? (
              <LoadingBtn size='full' />
            ) : (
              <AuthButton title='Sign up now' onClick={handleSubmit} />
            )}
            <div
              className='h4'
              style={{
                color: '#808191',
                margin: 10,
                fontSize: 12,
                textAlign: 'center',
              }}
            >
              or
            </div>
            {/* <div
              style={{
                display: 'grid',
                placeItems: 'center',
                marginBottom: 12,
                marginTop: 12,
              }}
              className='google__btn'
            >
              <GoogleLogin
                clientId='706918766220-70gvp0mgn3p56aammc3ipctoii4drn83.apps.googleusercontent.com'
                buttonText='Sign up with Google'
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                theme='dark'
                cookiePolicy={'single_host_origin'}
              />
            </div> */}
            <div className='login__flex'>
              <div className='login__text'>Already a member?</div>
              <Link
                className='login__link'
                to={(location) => ({ ...location, pathname: '/signin' })}
                onClick={clearState}
              >
                Sign in now
              </Link>
            </div>
          </div>
        )}
      </div>
      <Popup state={openGoogleAuthPopUp} setState={setOpenGoogleAuthPop}>
        <div
          className='forgot_password__innercontainer'
          style={{ marginTop: 30 }}
        >
          <FormInput
            value={googleOTP}
            label='Please enter the OTP from authenticator app'
            onChange={(e) => setGoogleOTP(e.target.value)}
          />
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <CustomButton title='Login' onClick={responseOTPGoogle} />
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default withRouter(SignUp);
