import { useEffect, useState } from 'react';
import {
  CustomSelect,
  Tags,
  InviteCard,
  Popup,
  SkeletonContainer,
  CustomButton,
  NotFoundedAnimation,
  Image,
  Icon,
  Loader,
} from '../../../components';
import { useSelector, useDispatch } from 'react-redux';
import {
  claimExternalCampaignsRewards,
  claimRewards,
  getExternalCampaignList,
  getFeeInfoForClaim,
  getNotClaimedCards,
  getScratchCards,
  getTotalReferrals,
} from '../../../actions/rewardsAction';
import { toast } from 'react-toastify';
import {
  useAnalytics,
  useBalance,
  useNotification,
  useSegment,
  useViewport,
} from '../../../utils/hooks';
import {
  getAssests,
  getBannerDetails,
  getCoinsDetails,
  getDepositDetails,
  storeMsg,
} from '../../../actions/assestsAction';
import { images } from '../../../utils/image/img';
import { baseCurrency } from '../../../utils/constants';
import ReactTooltip from 'react-tooltip';
import {
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import useFeature, { FEATURE_FLAG } from '../../../utils/hooks/useFeature';
import _ from 'lodash';
import moment from 'moment';

const UnClaimedCard = ({
  item,
  setSelectedData,
  setOpenPopup,
  setConfirmPopup,
  isExternalCampaign = false,
}) => {
  const { getInfoAboutCoins, roundUpWithSymbol, preventScientific } =
    useBalance();

  return (
    <div
      className='promotions__item unredeemed'
      onClick={() => {
        item['isExternalCampaign'] = isExternalCampaign ? true : false;
        setSelectedData(item);
        setOpenPopup(true);
      }}
    >
      <Image
        src={getInfoAboutCoins(item.currency)?.logo}
        style={{ width: 40 }}
      />
      {isExternalCampaign ? (
        <div className='p'>
          {roundUpWithSymbol(item.quantity, item.currency)}{' '}
          {item.currency.toUpperCase()}
        </div>
      ) : (
        <div className='p'>
          {preventScientific(roundUpWithSymbol(item.total, item.currency))}{' '}
          {item.currency.toUpperCase()}
        </div>
      )}
      <CustomButton title='Claim' addClassName='btn-small' />
    </div>
  );
};

const ClaimedCard = ({ item, isExternalCampaign = false }) => {
  const { getInfoAboutCoins, roundUpWithSymbol, preventScientific } =
    useBalance();

  return (
    <div className='promotions__item unredeemed' style={{ height: 210 }}>
      <div
        className='h6'
        style={{
          fontSize: 12,
          position: 'absolute',
          // right: 10,
          bottom: 20,
          color: '#808191',
        }}
      >
        {isExternalCampaign ? 'Campaigns' : 'Trading'}
      </div>
      <div className='h6'>You Got</div>
      <Image
        src={getInfoAboutCoins(item.currency)?.logo}
        style={{ width: 50 }}
      />
      <div className='p' style={{ textAlign: 'center' }}>
        {preventScientific(
          roundUpWithSymbol(
            isExternalCampaign ? item?.quantity : item.total,
            item.currency
          )
        )}{' '}
        {item.currency?.toUpperCase()?.toUpperCase()}
      </div>
    </div>
  );
};

function ReferralsTab({
  earning,
  selectFilter,
  setSelectFilter,
  filter,
  totalReferralsEarning,
}) {
  // const {
  // getReferralCode: { loading, response, error },
  // claimfee: { response: feeResponse },
  // userMsg: { state: userNotificationData },
  // } = useSelector((state) => state);
  const { response: feeResponse } = useSelector((state) => state.claimfee);
  const { loading, response, error } = useSelector(
    (state) => state.getReferralCode
  );

  const { getInfoAboutCoins, roundUpWithSymbol } = useBalance();
  const [claimloading, setClaimloading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [redeemCardPageCount, setRedeemCardPageCount] = useState(1);
  const dispatch = useDispatch();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const { sendTrackEvent } = useSegment();
  const { width, height } = useViewport();
  const { preventScientific } = useBalance();
  const [notClaimedRewards, setNotClaimedRewards] = useState([]);
  const { handleReferralCount } = useNotification();
  // const {
  // theme: { isdark },
  // getScratchCard: {
  //   loading: scratchCardLoading,
  //   error: scratchCardError,
  //   response: scratchCardResponse,
  // },
  // userLogin: { userInfo },
  // getTotalReferrals: { response: totalReferrals },
  // getBanner: { bannerDetails },
  // userIpAddress: { ip },
  // externalCampaignList: {
  //   response: externalCampaignListRes,
  //   loading: externalCampaignListLoading,
  //   error: externalcampaignListError,
  // },
  // depositDetails: { details },
  // } = useSelector((state) => state);

  const {
    loading: scratchCardLoading,
    error: scratchCardError,
    response: scratchCardResponse,
  } = useSelector((state) => state.getScratchCard);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { getStatusByFeatureKey } = useFeature();
  const { assetsData } = useSelector((state) => state.getAssests);
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const { response: totalReferrals } = useSelector(
    (state) => state.getTotalReferrals
  );
  const { bannerDetails } = useSelector((state) => state.getBanner);
  const {
    response: externalCampaignListRes,
    loading: externalCampaignListLoading,
    error: externalcampaignListError,
  } = useSelector((state) => state.externalCampaignList);
  const { details } = useSelector((state) => state.depositDetails);

  useEffect(() => {
    if (userInfo?.token) {
      getScratchCardList();
    }
  }, [selectFilter, userInfo]);

  const getScratchCardList = () => {
    if (selectFilter.value == 'unredeemed') {
      dispatch(getScratchCards('Not_Claimed', 1));
    } else {
      dispatch(getScratchCards('Claimed', 1));
    }
  };

  // const handleReferralCount = (notClaimedRewards) => {
  //   if (notClaimedRewards.length) {
  //     let balance = notClaimedRewards;
  //     let objIndex = userNotificationData?.findIndex((obj) => {
  //       return obj.id == 'not_claimed_rewards';
  //     });
  //     if (balance.length) {
  //       if (objIndex !== -1) {
  //         let data = userNotificationData;
  //         let count = data[objIndex]?.count;
  //         if (count == 1) {
  //           let filterItem = data?.filter((obj) => {
  //             return obj.id !== 'not_claimed_rewards';
  //           });
  //           const uniqData = _.uniqBy(filterItem, 'id');
  //           dispatch(storeMsg(uniqData));
  //           // dispatch(changeNotificatedState(false));
  //         } else {
  //           let dataObj = {
  //             id: 'not_claimed_rewards',
  //             logo: 'https://brine-mainnet-fe-assets.s3.ap-southeast-1.amazonaws.com/img/img/money-bag-fill.svg',
  //             msg: `You have ${Number(count) - 1} rewards pending`,
  //             time: moment().format('llll'),
  //             type: 'unclaimed_rewards',
  //             count: Number(count) - 1,
  //           };
  //           data[objIndex] = dataObj;
  //           const uniqData = _.uniqBy(data, 'id');
  //           dispatch(storeMsg(uniqData));
  //           // dispatch(changeNotificatedState(false));
  //         }
  //       }
  //     }
  //   }
  // };

  const handleGetClaimCard = async () => {
    try {
      let res = await getNotClaimedCards(userInfo?.token?.access);
      // console.log({ res: res?.payload });
      setNotClaimedRewards(res?.payload);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (userInfo?.token?.access) {
      handleGetClaimCard();
    }
  }, [userInfo?.token?.access]);

  const handleClaimCard = async (item) => {
    let feature = getStatusByFeatureKey(FEATURE_FLAG['referral']);

    if (!feature.status) {
      return toast.info(feature?.reason);
    }

    if (
      selectedData?.total <=
      Number(feeResponse?.payload[selectedData?.currency])
    ) {
      return;
    }
    sendTrackEvent('claimProcessInitiated');
    try {
      setClaimloading(true);
      const data = await claimRewards(
        userInfo.token.access,
        selectedData.currency,
        selectedData?.created_by
      );
      handleGetClaimCard();
      handleReferralCount(notClaimedRewards);
      setClaimloading(false);
      getScratchCardList();
      setConfirmPopup(true);
      sendTrackEvent('claimProcessSuccess');
      // analytics?.track('Redeemed ScratchCard success!', {
      //   keyword: selectFilter?.value,
      //   from: 'Referral Page',
      //   uid: userInfo?.payload?.uid,
      //   ip: ip,
      // });
      // dispatch(getScratchCardEarning());
    } catch (e) {
      sendTrackEvent('claimProcessFailure', { error: e });
      // analytics?.track('Redeemed ScratchCard failed!', {
      //   keyword: selectFilter?.value,
      //   from: 'Referral Page',
      //   uid: userInfo?.payload?.uid,
      //   ip: ip,
      // });
      toast.error(e);
      setClaimloading(false);
    }
  };

  const handleExternalCampaignsClaimCard = async () => {
    sendTrackEvent('externalCampaignsClaimProcessInitiated');
    try {
      setClaimloading(true);
      const data = await claimExternalCampaignsRewards(
        userInfo.token.access,
        selectedData.id
      );
      setClaimloading(false);
      dispatch(getExternalCampaignList());
      setConfirmPopup(true);
      sendTrackEvent('externalCampaignsClaimProcessSuccess');
      // analytics?.track('Redeemed ScratchCard success!', {
      //   keyword: selectFilter?.value,
      //   from: 'Referral Page',
      //   uid: userInfo?.payload?.uid,
      //   ip: ip,
      // });
      // dispatch(getScratchCardEarning());
    } catch (e) {
      sendTrackEvent('externalCampaignsClaimFailure', { error: e });
      // analytics?.track('Redeemed ScratchCard failed!', {
      //   keyword: selectFilter?.value,
      //   from: 'Referral Page',
      //   uid: userInfo?.payload?.uid,
      //   ip: ip,
      // });
      toast.error(e);
      setClaimloading(false);
    }
  };

  useEffect(() => {
    setRedeemCardPageCount(1);
  }, [selectFilter]);

  // useEffect(() => {
  //   analytics?.track('Clicked filter tab', {
  //     keyword: selectFilter?.value,
  //     from: 'Referral Page',
  //     uid: userInfo?.payload?.uid,
  //     ip: ip,
  //   });
  // }, [analytics, selectFilter]);

  useEffect(() => {
    dispatch(getTotalReferrals());
    dispatch(getFeeInfoForClaim());

    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }

    if (!bannerDetails) {
      dispatch(getBannerDetails());
    }

    if (!assetsData) {
      dispatch(getAssests());
    }

    if (!details) {
      dispatch(getDepositDetails());
    }
  }, [dispatch]);

  const clearState = () => {
    setConfirmPopup(false);
    setSelectedData(null);
  };

  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);

  const shareTextReferral = `
I’m so excited to be one of the winners of the ${
    selectedData?.campaign?.details
  } powered by @BrineFinance 🚀
  
Thanks to the team for acknowledging my early support towards the project and awarding me with  ${roundUpWithSymbol(
    selectedData?.isExternalCampaign
      ? selectedData?.quantity
      : selectedData?.total,
    selectedData?.currency
  )} ${selectedData?.currency?.toUpperCase()} on trade.brine.fi 🎊

I will continue using #brinefi as it’s the best solution for my trading needs! 🦾`;

  const shareTextTrading = `
Just claimed my ${roundUpWithSymbol(
    selectedData?.isExternalCampaign
      ? selectedData?.quantity
      : selectedData?.total,
    selectedData?.currency
  )} ${selectedData?.currency?.toUpperCase()} on @BrineFinance as referral rebate 💪
  
Brine is the first DEX to bring referral rebates to life where you earn upto 50% of your referees’ trading fees! 🚀`;

  const url = 'https://brine.fi/';
  const hashtag = '#Brine, #cryptocurrency';

  return (
    <div className='page__col referral_col'>
      <InviteCard
      // analytics={analytics}
      />
      <div className='popup__category'>Total Referrals Rewards</div>
      <div className='popup__flex'>
        {totalReferralsEarning ? (
          <>
            <div className='popup__money h3'>
              {totalReferralsEarning?.toFixed(3)}
            </div>
            <div className='popup__currency'>{baseCurrency?.toUpperCase()}</div>
          </>
        ) : (
          <>
            <div className='popup__money h3'>0.00</div>
            <div className='popup__currency'>{baseCurrency?.toUpperCase()}</div>
          </>
        )}
      </div>
      <div className='popup__parameters'>
        <div className='popup__parameter'>
          <div className='popup__head'>
            <div className='popup__text'>Total Invited</div>
            {userInfo?.token ? (
              <div className='popup__text'>
                {totalReferrals?.payload?.total_invited
                  ? totalReferrals?.payload?.total_invited
                  : 0}
              </div>
            ) : (
              <div className='popup__text'>---</div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: 40 }}>
        <div
          className='widgets__select mobile-show'
          style={{ marginBottom: 30 }}
        >
          <CustomSelect
            defaultValue={filter[0]}
            options={filter}
            onChange={(data) => setSelectFilter(data)}
          />
        </div>
        <Tags
          selectedFilter={selectFilter}
          setSelectedFilter={setSelectFilter}
          options={filter}
          addTagclassName='notifications__tags  mobile-hide'
          addLinksclassName='notifications__link'
        />
        <div className='referral__card promotions__list'>
          {selectFilter.value === 'unredeemed' ? (
            <>
              {scratchCardLoading ? (
                [1, 2, 3, 4, 5].map((item) => (
                  <div key={item} className='promotions__item bg-transparent'>
                    <SkeletonContainer
                      style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: 10,
                      }}
                    />
                  </div>
                ))
              ) : (
                <>
                  {scratchCardResponse?.payload?.map((item, idx) => (
                    <UnClaimedCard
                      item={item}
                      key={idx}
                      setOpenPopup={setOpenPopup}
                      setSelectedData={setSelectedData}
                    />
                  ))}
                  {!externalCampaignListLoading &&
                    !externalcampaignListError &&
                    externalCampaignListRes?.payload?.map((item, idx) => {
                      if (item?.claimed === false) {
                        return (
                          <UnClaimedCard
                            item={item}
                            key={idx}
                            isExternalCampaign={true}
                            setOpenPopup={setOpenPopup}
                            setSelectedData={setSelectedData}
                          />
                        );
                      }
                    })}
                </>
              )}
            </>
          ) : (
            <>
              {scratchCardLoading ? (
                [1, 2, 3, 4, 5].map((item) => (
                  <div key={item} className='promotions__item bg-transparent'>
                    <SkeletonContainer
                      style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: 10,
                      }}
                    />
                  </div>
                ))
              ) : (
                <>
                  <>
                    {scratchCardResponse?.payload?.map((item, idx) => (
                      <ClaimedCard item={item} key={idx} />
                    ))}
                    {!externalCampaignListLoading &&
                      !externalcampaignListError &&
                      externalCampaignListRes?.payload?.map((item, idx) => {
                        if (item?.claimed === true) {
                          return (
                            <ClaimedCard
                              item={item}
                              key={idx}
                              isExternalCampaign={true}
                            />
                          );
                        }
                      })}
                  </>
                </>
              )}
            </>
          )}
        </div>
        {!userInfo?.token && (
          <NotFoundedAnimation
            text={
              selectFilter?.value === 'unredeemed'
                ? 'Oops, maybe try referring Brine to more of your peers!'
                : 'No Rewards found'
            }
            size='sm'
          />
        )}
        {scratchCardResponse?.payload?.length === 0 &&
          externalCampaignListRes?.payload?.length === 0 && (
            <>
              {!scratchCardLoading &&
                ((scratchCardResponse?.payload?.length === 0 &&
                  selectFilter.value === 'redeemed') ||
                  (scratchCardResponse?.payload?.length === 0 &&
                    selectFilter.value === 'unredeemed')) && (
                  <NotFoundedAnimation
                    text={
                      selectFilter?.value === 'unredeemed'
                        ? 'Oops, maybe try referring Brine to more of your peers!'
                        : 'No Rewards found'
                    }
                    size='sm'
                  />
                )}
            </>
          )}
      </div>
      {/* <div className='referral__popup'> */}
      <div className='share__popup network__error'>
        <Popup
          state={openPopup}
          setState={setOpenPopup}
          clearState={clearState}
        >
          <>
            {claimloading ? (
              <div className='referral_loader'>
                <Loader />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className='h6'>Reward Summary</div>
                <Image
                  src={images.referralImage}
                  style={{ marginTop: 10, marginBottom: 25, width: 120 }}
                />
                <div className='referral__quotes_container'>
                  <div className='referral__quotes_container_inner'>
                    <h4>Reward</h4>
                    <h6>
                      {preventScientific(
                        roundUpWithSymbol(
                          selectedData?.isExternalCampaign
                            ? selectedData?.quantity
                            : selectedData?.total,
                          selectedData?.currency
                        )
                      )}{' '}
                      {selectedData?.currency?.toUpperCase()}
                    </h6>
                  </div>
                  {/* {!selectedData?.isExternalCampaign && (
                    <div className='referral__quotes_container_inner'>
                      <h4>
                        <ReactTooltip
                          place='bottom'
                          type={isdark ? 'light' : 'dark'}
                          className='tooltip_styles price_details'
                          id={'claimable_fee'}
                        />
                        Claim Fee
                        <span
                          data-tip={`We are charging a minimum fee to claim rewards in order to prevent spam.`}
                          data-for={'claimable_fee'}
                        >
                          <Icon icon='info-circle' className='icon' />
                        </span>
                      </h4>
                      <h6>
                        {roundUpWithSymbol(
                          feeResponse?.payload[selectedData?.currency],
                          selectedData?.currency
                        )}{' '}
                        {selectedData?.currency?.toUpperCase()}
                      </h6>
                    </div>
                  )} */}
                  {/* {!selectedData?.isExternalCampaign && (
                    <div className='referral__quotes_container_inner'>
                      <h4>
                        <ReactTooltip
                          place='bottom'
                          type={isdark ? 'light' : 'dark'}
                          className='tooltip_styles price_details'
                          id={'claimable_fee_info'}
                        />
                        Claimable Balance
                        <span
                          data-tip={`Claimable Balance = Referral Reward -  Claim Fee`}
                          data-for={'claimable_fee_info'}
                        >
                          <Icon icon='info-circle' className='icon' />
                        </span>
                      </h4>
                      <h6>
                        {selectedData?.total <=
                        Number(feeResponse?.payload[selectedData?.currency])
                          ? 0
                          : roundUpWithSymbol(
                              selectedData?.total -
                                Number(
                                  feeResponse?.payload[selectedData?.currency]
                                ),
                              selectedData?.currency
                            )}{' '}
                        {selectedData?.currency?.toUpperCase()}
                      </h6>
                    </div>
                  )} */}
                  <div className='referral__quotes_container_inner'>
                    <h4>Reward Type</h4>
                    <h6>
                      {!selectedData?.isExternalCampaign
                        ? 'Trading'
                        : 'Campaigns'}
                    </h6>
                  </div>
                </div>
                {selectedData?.isExternalCampaign && (
                  <div className='deposit-info-tiny'>
                    <h6 style={{ fontSize: 13, fontWeight: 100 }}>
                      {selectedData?.campaign?.details}
                    </h6>
                  </div>
                )}

                {!selectedData?.isExternalCampaign ? (
                  <>
                    <CustomButton
                      title={`Claim`}
                      onClick={handleClaimCard}
                      // disabled={
                      //   selectedData?.total <=
                      //   Number(feeResponse?.payload[selectedData?.currency])
                      // }
                      style={{
                        display: 'block',
                        width: '100%',
                        marginTop: 15,
                        // opacity:
                        //   selectedData?.total <=
                        //   Number(feeResponse?.payload[selectedData?.currency])
                        //     ? 0.5
                        //     : 1,
                        // cursor:
                        //   selectedData?.total <=
                        //   Number(feeResponse?.payload[selectedData?.currency])
                        //     ? 'not-allowed'
                        //     : 'auto',
                      }}
                      // data-for={'no_currency_title'}
                      // data-tip={
                      //   'Cummulative of rewards value of this currency is less than claim fee'
                      // }
                    />
                    {/* {selectedData?.total <=
                      Number(feeResponse?.payload[selectedData?.currency]) && (
                      <h4>
                        <ReactTooltip
                          place='top'
                          type={isdark ? 'light' : 'dark'}
                          className='tooltip_styles price_details'
                          id={'no_currency_title'}
                        />
                      </h4>
                    )} */}
                  </>
                ) : (
                  <CustomButton
                    title={`Claim`}
                    onClick={handleExternalCampaignsClaimCard}
                    // disabled={
                    //   selectedData?.total <=
                    //   Number(feeResponse?.payload[selectedData?.currency])
                    // }
                    style={{
                      display: 'block',
                      width: '100%',
                      marginTop: 15,
                    }}
                  />
                )}
              </div>
            )}
          </>
        </Popup>
      </div>
      <div className='share_campaign_popup referral__share_popup'>
        <Popup
          setState={setConfirmPopup}
          state={confirmPopup}
          closeonAnyPopupState={() => setOpenPopup(false)}
        >
          <div
            className='share__bg'
            style={{
              height: '160px',
            }}
          ></div>
          <div className='share__container'>
            <div className='share__coins'>
              <img
                src={images.shareSocial}
                alt=''
                style={{
                  height: 200,
                }}
              />
              {selectedData?.isExternalCampaign ? (
                <div className='h5' style={{ fontSize: 18 }}>
                  {/* {' '}
                  Yayy! You just claimed a{' '}
                  {roundUpWithSymbol(
                    selectedData?.isExternalCampaign
                      ? selectedData?.quantity
                      : selectedData?.total,
                    selectedData?.currency
                  )}{' '}
                  {selectedData?.currency?.toUpperCase()} reward on Brine's */}
                  {selectedData?.user_remarks}
                </div>
              ) : (
                <div className='h5' style={{ fontSize: 18 }}>
                  Yayy! You just claimed a{' '}
                  {roundUpWithSymbol(
                    selectedData?.isExternalCampaign
                      ? selectedData?.quantity
                      : selectedData?.total,
                    selectedData?.currency
                  )}{' '}
                  {selectedData?.currency?.toUpperCase()}{' '}
                  <span
                    className='campaigns__details_mask'
                    style={{ color: '#ba77f7' }}
                  >
                    REBATE{' '}
                  </span>{' '}
                  for referring your trader frens.
                </div>
              )}
              {selectedData?.isExternalCampaign && (
                <div
                  className='campaigns__details_mask'
                  style={{ fontSize: 13 }}
                >
                  {/* {selectedData?.campaign?.details} */}
                  Yayy! You just claimed a{' '}
                  {roundUpWithSymbol(
                    selectedData?.isExternalCampaign
                      ? selectedData?.quantity
                      : selectedData?.total,
                    selectedData?.currency
                  )}{' '}
                  {selectedData?.currency?.toUpperCase()} reward
                </div>
              )}
              <div className='h6' style={{ fontSize: 14 }}>
                We appreciate your support immensely! #BeyondTheMoon #Winning
              </div>
            </div>
            <div className='share__social_icon referral_share'>
              <TelegramShareButton
                url={url}
                title={
                  selectedData?.isExternalCampaign
                    ? shareTextReferral
                    : shareTextTrading
                }
                hashtag={hashtag}
                windowHeight={height}
                windowWidth={width}
              >
                <TelegramIcon size={50} round />
              </TelegramShareButton>
              <WhatsappShareButton
                url={url}
                title={
                  selectedData?.isExternalCampaign
                    ? shareTextReferral
                    : shareTextTrading
                }
                windowHeight={height}
                windowWidth={width}
              >
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>
              <TwitterShareButton
                url={url}
                title={
                  selectedData?.isExternalCampaign
                    ? shareTextReferral
                    : shareTextTrading
                }
                via='Brine'
                windowHeight={height}
                windowWidth={width}
              >
                <TwitterIcon size={50} round />
              </TwitterShareButton>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default ReferralsTab;
