import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';
import { images } from '../../../utils/image/img';
import SkeletonContainer from '../../common/skeleton/skeleton';
import Image from '../../common/img/image';
import { useViewport } from '../../../utils/hooks';

// const prizeDetails = [
//   {
//     img: images.firstPrize,
//     name: '1st',
//     prize: ['💰  1000$', 'somthing'],
//   },
//   {
//     img: images.thirdPrize,
//     name: '2nd',
//     prize: ['💰  500$', 'somthing'],
//   },
//   {
//     img: images.secondPrize,
//     name: '3rd',
//     prize: ['💰  250$', 'somthing'],
//   },
// ];

const sliderResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

export function PrizeDetails({ campaignsLoading, prizeDetails }) {
  const { width } = useViewport();

  let winnerImgs = {
    0: images.LbWinnerImg1,
    1: images.LbWinnerImg2,
    2: images.LbWinnerImg3,
  };

  return (
    <div className='leaderboard__prize__wrapper'>
      <div
        className='categories__title h5'
        style={{
          fontSize: 21,
          marginBottom: 16,
          marginLeft: 10,
        }}
      >
        Prizes
        <Image
          src={images.thirdPrize}
          style={{ width: 40, height: 40, marginLeft: 10 }}
        />
      </div>
      {campaignsLoading || !prizeDetails ? (
        <div className='flex-container'>
          {Array.from(Array(width > 700 ? 3 : 2).keys()).map((item, idx) => (
            <div className='flex-items' key={idx}>
              <SkeletonContainer
                style={{
                  width: width > 700 ? 200 : 'calc(44vw)',
                  height: 200,
                  borderRadius: 20,
                  marginLeft: item !== 0 ? 20 : 0,
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {/* <div className='mobile-show'> */}
          <Carousel
            responsive={sliderResponsive}
            itemClass='prize__item leaderboard__prize_item'
            partialVisible={false}
            slidesToSlide={width >= 750 ? 2 : 1}
            containerClass='prize__carousel_container'
          >
            {prizeDetails?.map((prize, idx) => {
              return (
                <div
                  className={`prize leaderboard__prize ${
                    idx % 2 === 0 ? 'leaderboard__prize_second_border' : ''
                  }
                  `}
                  key={prize.name}
                >
                  <div>
                    <Image
                      src={
                        winnerImgs[idx] ? winnerImgs[idx] : images?.LbWinnerImg4
                      }
                      alt=''
                      style={{ width: winnerImgs[idx] ? 112 : 122, height: 90 }}
                    />
                  </div>
                  <div>
                    <h1>
                      #{prize.name}{' '}
                      {/* <span
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {'wins'}
                  </span> */}
                    </h1>
                    <ul>
                      {prize.prize.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </Carousel>
          {/* </div> */}
          {/* <div className='prize__event_container'>
            <div
              className='prize__wrapper mobile-hide'
              style={{
                marginTop: 6,
                marginBottom: 20,
              }}
            >
              <div className='prize__container'>
                {prizeDetails?.map((prize) => (
                  <div className='prize' key={prize.name}>
                    <Image src={prize.img} alt='' />
                    <h1 className='text-mask'>
                      #{prize.name}{' '}
                      <span
                        style={{
                          fontSize: 18,
                        }}
                      >
                        {'wins'}
                      </span>
                    </h1>
                    <ul>
                      {prize.prize.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
