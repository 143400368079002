import axios from 'axios';
import * as leaderboardConstants from '../constants/leaderboardConstant';
import { baseUrl } from '../utils/urls';
import { objToParams } from '../utils/query-helper';

export const getLeaderBoardCampaign = () => async (dispatch, getState) => {
  const config = {
    headers: {},
  };

  try {
    dispatch({
      type: leaderboardConstants.GET_CAMPAIGNS_REQUEST,
    });

    const { data } = await axios.get(
      `${baseUrl}/external-services/v2/campaign/active/`,
      config
    );

    dispatch({
      type: leaderboardConstants.GET_CAMPAIGNS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: leaderboardConstants.GET_CAMPAIGNS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getArchivedLeaderBoardCampaign =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: leaderboardConstants.GET_ARCHIVED_CAMPAIGNS_REQUEST,
      });

      const { data } = await axios.get(
        `${baseUrl}/external-services/v2/campaign/archived/`
      );

      dispatch({
        type: leaderboardConstants.GET_ARCHIVED_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: leaderboardConstants.GET_ARCHIVED_CAMPAIGNS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getLeaderBoardList = async (id, isActive = true, token) => {
  try {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
    }
    const { data } = await axios.get(
      `${baseUrl}/external-services/v2/leaderboard/${
        isActive ? 'active' : 'archived'
      }-campaign/?campaign_uid=${id}`,
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const getUserScoreLeaderBoaadScore = async (
  token,
  campaign_id
  // user_id
) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    let body = {
      campaign_uid: campaign_id,
      // user_uid: user_id,
      rank_above: true,
    };
    let queryParams = objToParams(body);
    const { data } = await axios.get(
      `${baseUrl}/external-services/v2/leaderboard/user-active-campaign/?${queryParams}`,
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
