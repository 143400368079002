export function listenForOutsideClicks(
  listening,
  setListening,
  DOMRef,
  handleClose
) {
  return () => {
    if (listening) return;
    if (!DOMRef.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        if (DOMRef && DOMRef.current?.contains(evt.target)) return;
        handleClose();
      });
    });
  };
}
