import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CustomButton,
  Feedback,
  HelmetContainer,
  Icon,
  Image,
} from '../../components';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import {
  MoonIcon,
  SunIcon,
} from '../../components/common/theme-toggler/theme-toggler';
import { changeTheme } from '../../actions/themeAction';
import { useSegment, useViewport } from '../../utils/hooks';
import { images } from '../../utils/image/img';
import { useLocation, useHistory } from 'react-router-dom';
import Pagination from '../../components/helper/pagination';
import { scroller } from 'react-scroll';
import { networkName } from '../../utils/urls';
import { getBannerDetails, getCoinsDetails } from '../../actions/assestsAction';
import {
  ENVIRONMENT,
  NORMAL_WITHDRAWAL_CONFIRMATION_HOURS,
} from '../../utils/constants';

function removeSuffix(token) {
  return token.replace(/(ing|s)$/, '');
}

const videosData = [
  {
    title: 'Sign in/ Sign Up',
    video: 'https://youtu.be/vHFNXHHUA9M',
    icon: <i className='fas fa-sign-in-alt'></i>,
  },
  // {
  //   title: 'How to Sign in on Brine using Social Login?',
  //   video: 'https://youtu.be/9RTD2Oynyr8',
  //   icon: <i className='fas fa-sign-in-alt'></i>,
  // },
  {
    title: 'How to Deposit',
    video: 'https://youtu.be/1bDDD5TewPU',
    icon: <i className='fas fa-piggy-bank'></i>,
  },
  {
    title: 'How to Withdraw',
    video: 'https://youtu.be/5YoTLW1oox4',
    icon: <i className='fas fa-money-check-alt'></i>,
  },
  {
    title: 'How to Trade',
    video: 'https://youtu.be/_84g5efuRxY',
    icon: <i className='fas fa-chart-line'></i>,
  },
];

const faqData = [
  {
    title: 'Overview',
  },
  {
    title: 'Transactions',
  },
  {
    title: 'Withdrawal',
  },
  {
    title: 'Deposit',
  },
  {
    title: 'Referrals',
  },
  {
    title: 'Trading Competition',
  },
];

const styles = {
  common: {
    fontFamily: 'Poppins',
  },
  navStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoStyle: {
    width: 130,
  },
  headingStyle: {
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 20,
    fontSize: 20,
    fontFamily: 'Poppins',
  },
  btnStyle: {
    minWidth: '104px',
    height: '38px',
    padding: '0 8px',
    borderRadius: ' 7px',
  },
};

function Breadcrumb({}) {
  return (
    <div>
      <ul className='breadcrumb breadcrumb--classic breadcrumb--classic-chevron'>
        <li className='active'>
          <a href='/help'>Back</a>
        </li>
        <li>
          <p>Search results</p>
        </li>
      </ul>
    </div>
  );
}

function KeywordSearch({ handleSubmit, text, setText }) {
  return (
    <div className='helper_search'>
      <div className='header__input_conatiner'>
        <form onSubmit={handleSubmit} role='search'>
          <input
            className='header__input'
            type='search'
            placeholder='Search keyword'
            value={text}
            autoComplete='off'
            aria-label='Search'
            onChange={(e) => setText(e.target.value)}
          />
        </form>
        {!text.length && (
          <button className='header__start'>
            <div className='icon icon-search'>
              <Icon icon='search' className='icon' />
            </div>
          </button>
        )}
      </div>
    </div>
  );
}

function Topic({ title, icon }) {
  return (
    <div className='h6 scroll_result'>
      {title}
      <span> {icon}</span>
    </div>
  );
}

function SearchLayout({
  handleSubmit,
  text,
  setText,
  query,
  setSelectedVideo,
  currentPosts,
  postsPerPage,
  searchFAQData,
  paginate,
  currentPage,
  searchVideoData,
}) {
  return (
    <div className='help-container'>
      <div
        style={{
          marginTop: 40,
        }}
      >
        <div className='helper_page_search_container'>
          <Breadcrumb />
          <KeywordSearch
            handleSubmit={handleSubmit}
            text={text}
            setText={setText}
          />
        </div>
        <div className='search-content'>
          <div className='h4'>Search results</div>
          <p className='result-content'>
            {Number(searchVideoData.length) + Number(searchFAQData.length)}{' '}
            results for "{query}"
          </p>
          <div>
            <Topic
              title='Video tutorials'
              icon={<i className='fas fa-play-circle'></i>}
            />
            {searchVideoData.length ? (
              <>
                {searchVideoData.map((item, idx) => (
                  <div
                    className='search__result'
                    style={{
                      padding: '20px 0px',
                      paddingTop: idx === 0 ? 0 : 20,
                    }}
                    key={idx}
                  >
                    <div
                      className='search__result_title h5'
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedVideo(item.video)}
                    >
                      {item.title}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className='result-content'>No results for "{query}"</p>
            )}
          </div>
          {ENVIRONMENT !== 'testnet' && (
            <div>
              <Topic
                title='FAQ'
                icon={<i className='fas fa-question-circle'></i>}
              />
              {currentPosts.length ? (
                <>
                  {currentPosts?.map((item, idx) => (
                    <div
                      className='search__result'
                      style={{
                        padding: '20px 0px',
                        paddingTop: idx === 0 ? 0 : 20,
                      }}
                      key={idx}
                    >
                      <div className='search__result_title h5'>
                        {item.title}
                      </div>
                      <div className='search__result_content p'>
                        {item?.htmlContent ? item?.htmlContent : item.content}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className='result-content'>No results for "{query}"</p>
              )}
            </div>
          )}
        </div>
      </div>
      {ENVIRONMENT !== 'testnet' && (
        <>
          {searchFAQData.length >= 10 && (
            <div
              style={{
                marginBottom: 30,
              }}
            >
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={searchFAQData?.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function HelperHomeLayout({
  text,
  setText,
  handleSubmit,
  width,
  selectedVideo,
  setSelectedVideo,
  selectedFAQ,
  setSelectedFAQ,
  accordionData,
  FAQ,
}) {
  return (
    <div>
      <HelpSearchInput
        text={text}
        setText={setText}
        handleSubmit={handleSubmit}
      />
      <div>
        <h3
          style={{
            textAlign: 'center',
            marginBottom: 20,
            marginTop: 20,
            fontSize: width > 700 ? 40 : 30,
            fontFamily: 'Poppins',
          }}
        >
          Video tutorials
        </h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className='help_video_container'>
            {videosData.map((item, idx) => (
              <CategoriesCard
                title={item.title}
                key={idx}
                video={item.video}
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
              >
                <span
                  style={{
                    color: '#808191',
                  }}
                >
                  {item.icon}
                </span>
              </CategoriesCard>
            ))}
          </div>
        </div>
        {ENVIRONMENT !== 'testnet' && (
          <>
            {width <= 700 ? (
              <>
                <div>
                  <h1
                    style={{
                      ...styles.headingStyle,
                      fontSize: width > 700 ? 40 : 30,
                    }}
                  >
                    Frequently Asked Questions
                  </h1>
                </div>
                <div>
                  <h1 style={styles.headingStyle}>{faqData[0].title}</h1>
                  <div className='accordion__container'>
                    <div className='accordion'>
                      {FAQ[faqData[0].title].map(
                        ({ title, content, htmlContent }) => (
                          <Accordion
                            title={title}
                            content={content}
                            key={title}
                            htmlContent={htmlContent}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={styles.headingStyle}>{faqData[1].title}</h1>
                  <div className='accordion__container'>
                    <div className='accordion'>
                      {FAQ[faqData[1].title].map(
                        ({ title, content, htmlContent }) => (
                          <Accordion
                            title={title}
                            content={content}
                            key={title}
                            htmlContent={htmlContent}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <h1 style={styles.headingStyle}>{faqData[2].title}</h1>
                  <div className='accordion__container'>
                    <div className='accordion'>
                      {FAQ[faqData[2].title].map(
                        ({ title, content, htmlContent }) => (
                          <Accordion
                            title={title}
                            content={content}
                            key={title}
                            htmlContent={htmlContent}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <h1 style={styles.headingStyle}>{faqData[3].title}</h1>
                  <div className='accordion__container'>
                    <div className='accordion'>
                      {FAQ[faqData[3].title].map(
                        ({ title, content, htmlContent }) => (
                          <Accordion
                            title={title}
                            content={content}
                            key={title}
                            htmlContent={htmlContent}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={styles.headingStyle}>{faqData[4].title}</h1>
                  <div className='accordion__container'>
                    <div className='accordion'>
                      {FAQ[faqData[4].title].map(
                        ({ title, content, htmlContent }) => (
                          <Accordion
                            title={title}
                            content={content}
                            key={title}
                            htmlContent={htmlContent}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <h1 style={styles.headingStyle}>{faqData[5].title}</h1>
                  <div className='accordion__container'>
                    <div className='accordion'>
                      {FAQ[faqData[5].title].map(
                        ({ title, content, htmlContent }) => (
                          <Accordion
                            title={title}
                            content={content}
                            key={title}
                            htmlContent={htmlContent}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <h1
                  style={{
                    ...styles.headingStyle,
                    fontSize: width > 700 ? 40 : 30,
                  }}
                >
                  Frequently Asked Questions
                </h1>
                <div className='faq_container'>
                  <div className='faq_titles'>
                    {faqData.map((item, idx) => (
                      <h3
                        key={idx}
                        className={selectedFAQ === item.title ? 'active' : ''}
                        onClick={() => setSelectedFAQ(item.title)}
                      >
                        {item.title}{' '}
                        {selectedFAQ === item.title && (
                          <i className='fas fa-angle-right'></i>
                        )}
                      </h3>
                    ))}
                  </div>
                  <div className='faq_info'>
                    {accordionData?.map(({ title, content, htmlContent }) => (
                      <Accordion
                        title={title}
                        content={content}
                        htmlContent={htmlContent}
                        key={title}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const NavBar = ({ isdark }) => {
  const dispatch = useDispatch();
  const { sendTrackEvent } = useSegment();

  return (
    <div
      className='helper__nav'
      style={{ ...styles.common, ...styles.navStyle }}
    >
      <div>
        <Link to={(location) => ({ ...location, pathname: '/' })}>
          <Image
            src={isdark ? images.logoBigWhite : images.logoBigDark}
            style={styles.logoStyle}
          />
        </Link>
      </div>
      <div>
        <CustomButton
          title='Contact us'
          style={styles.btnStyle}
          onClick={() => {
            window.open('mailto:support@brine.fi', '_blank');
            sendTrackEvent('buttonClicked', {
              title: 'Contact Us',
            });
          }}
        />
        <span
          className='theme_changer'
          onClick={() => dispatch(changeTheme(isdark))}
        >
          {isdark ? <SunIcon /> : <MoonIcon />}
        </span>
      </div>
    </div>
  );
};

const Accordion = ({ title, content, htmlContent }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className='accordion-item'>
      <div
        className={`accordion-title ${!isActive ? 'line' : ''} `}
        onClick={() => setIsActive(!isActive)}
      >
        <div>{title}</div>
        <div>
          {isActive ? (
            <i className='fas fa-angle-up'></i>
          ) : (
            <i className='fas fa-angle-down'></i>
          )}
        </div>
      </div>
      {isActive && (
        <div className={`accordion-content ${isActive ? 'line' : ''} `}>
          {htmlContent ? htmlContent : content}
        </div>
      )}
    </div>
  );
};

function HelpSearchInput({ handleSubmit, text, setText }) {
  return (
    <div className='helper-bg'>
      <div className='hero-inner'>
        <h2 className='hero-title'>Hi! How can we help?</h2>
        <form
          role='search'
          className='search search-full'
          onSubmit={handleSubmit}
        >
          <input
            type='search'
            name='query'
            value={text}
            placeholder='Search'
            onChange={(e) => setText(e.target.value)}
            autoComplete='off'
            aria-label='Search'
          />
        </form>
      </div>
    </div>
  );
}

function Modals({ selectedVideo, setSelected }) {
  const handleClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      setSelected(null);
    }
  };

  return (
    <>
      {selectedVideo && (
        <motion.div
          className='backdrop'
          onClick={handleClick}
          style={{ zIndex: 1 }}
        >
          <ReactPlayer
            url={selectedVideo}
            className='center'
            controls={true}
            height={
              window.screen.width > 350 && window.screen.height > 700
                ? '90%'
                : '30%'
            }
            width={
              window.screen.width > 350 && window.screen.height > 700
                ? '80%'
                : '90%'
            }
          />
        </motion.div>
      )}
    </>
  );
}

const CategoriesCard = ({ title, video, children, setSelectedVideo }) => (
  <div className='category_card' onClick={() => setSelectedVideo(video)}>
    {children}
    <h3>{title}</h3>
  </div>
);

function useQuery() {
  const { search } = useLocation();
  let params = useMemo(() => new URLSearchParams(search), [search]);
  return params.get('query');
}

function HelperPage() {
  // const {
  //   // theme: { isdark },
  //   // getBanner: { bannerDetails, loading: bannerLoading },
  //   // getCoins: { coinsDetails, loading: coinsLoading },
  // } = useSelector((state) => state);

  const { coinsDetails, loading: coinsLoading } = useSelector(
    (state) => state.getCoins
  );
  const { bannerDetails, loading: bannerLoading } = useSelector(
    (state) => state.getBanner
  );
  const { isdark } = useSelector((state) => state.theme);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [text, setText] = useState('');
  const { width } = useViewport();
  const [selectedFAQ, setSelectedFAQ] = useState('Overview');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFAQData, setSearchFAQData] = useState([]);
  const [searchVideoData, setSearchVideoData] = useState([]);
  const { sendPageEvent, analytics, sendTrackEvent } = useSegment();
  const dispatch = useDispatch();

  const postsPerPage = 10;
  const history = useHistory();
  const query = useQuery();

  const getSupportedPair = () => {
    if (bannerDetails?.payload) {
      const supportedPairs = bannerDetails?.payload?.markets_with_slash.map(
        (pair) => {
          const [base, quote] = pair.split('/');
          return `${base.toUpperCase()}/${quote.toUpperCase()}`;
        }
      );
      const formattedPairs = supportedPairs.join(' , ');
      // Find the last comma and replace it with ' &'
      const lastCommaIndex = formattedPairs.lastIndexOf(',');
      const formattedPairsWithAnd = `${formattedPairs.substring(
        0,
        lastCommaIndex
      )} &${formattedPairs.substring(lastCommaIndex + 1)}`;

      return `Supported token pairings are ${formattedPairsWithAnd}.`;
    } else {
      return `Supported token pairings are ---`;
    }
  };

  const getSupportedCoins = () => {
    if (coinsDetails?.convertedArray) {
      const supportedCoins = coinsDetails?.convertedArray.map(
        (coin) => `${coin.name} (${coin.symbol.toUpperCase()})`
      );
      const lastCoin = supportedCoins.pop(); // Remove the last coin from the array

      let result = `Brine supports ${
        supportedCoins.length + 1
      } coins: ${supportedCoins.join(', ')}`;
      if (supportedCoins.length > 0) {
        result += ' and';
      }
      result += ` ${lastCoin}.`;
      return result;
    } else {
      return `Brine supports '---'`;
    }
  };

  const FAQ = {
    Overview: [
      {
        title: 'What is Brine Fi?',
        content:
          'Brine Fi is a crosschain orderbook DEX that aims to improve the trading experience for users by providing an instant, gasless, trustless and secure platform for crypto trading.',
      },
      {
        title: 'Which coins does Brine support?',
        content: getSupportedCoins(),
      },
      {
        title: 'Does Brine charge gas fees on deposits and withdrawals?',
        content:
          'The Brine platform itself does not charge any gas fees on deposit or withdrawal, but there can be a variable fee depending upon the selected network.',
      },
      {
        title: 'Which wallets does the Brine platform support?',
        content: 'Brine supports all wallets.',
      },
      {
        title: 'How to connect a wallet to Brine Fi?',
        content:
          'Connect your wallet to Brine in no time with our easy to follow video tutorial!',
        htmlContent: (
          <p>
            Connect your wallet to Brine in no time with our easy to follow{' '}
            <a
              href='https://youtu.be/vHFNXHHUA9M'
              target='_blank'
              rel='noopener noreferrer'
            >
              video tutorial!
            </a>
          </p>
        ),
      },
      {
        title: 'Can users provide Liquidity in Liquidity Pool?',
        content: 'No, currently users cannot provide liquidity.',
      },
      {
        title: 'Does Brine have a token?',
        content:
          'Brine does not have a token at the moment, but do follow us on Twitter to receive instant updates about our token launch.',
      },
      {
        title: 'How can I participate in airdrops?',
        content:
          'We value our preliminary users and will incentivize accordingly; follow us on Twitter to get updates.',
      },
      {
        title: 'Are Brine smart contracts audited?',
        content: 'Yes, our smart contracts are audited by PeckShield.',
      },
      {
        title: 'Does Brine have a governance token?',
        content: 'No, we currently do not have a governance token.',
      },
      {
        title: 'What are the risks of using Brine?',
        content:
          "Without a user's signature being verified, Brine does not have access to any of the funds linked to the user as it works on a self-custody first principle.",
      },
      {
        title: 'How do I file a bug report?',
        content: 'Kindly drop us a mail at support@brine.fi',
      },
      {
        title: 'What are the supported token pairings?',
        content: getSupportedPair(),
      },
      {
        title: 'What gives Brine an edge over other existing AMMs and CEXs?',
        content:
          "Brine offers an orderbook based DEX that provides the security and privacy of any AMM DEX, with the ease of trading that CEXs offer. Orderbook based DEXs have professional market makers which can provide deep liquidity to the orderbooks and also help reduce slippage for your orders. It also doesn't have any LP fee or impermanent loss like AMM DEXs do.",
      },
    ],
    Transactions: [
      {
        title: 'What are the transactional charges?',
        content:
          'Brine charges 0.1% of the overall transaction volume and there is no gas fee involved. (Maker Fee - 0.1% & Taker Fee - 0.1%)',
      },
      {
        title: 'Is the trading fee charged on the Base or Quote pair?',
        content:
          'Brine has a trading fee of 0.1% which is charged only on the base coin. For example, if you buy Bitcoin using USDT, trading fee is deducted/charged on USDT.',
      },
    ],
    Withdrawal: [
      {
        title:
          'What are the different types of withdrawals available on the platform and what are the fees charged?',
        content:
          'There are two types of withdrawal on Brine: Normal mode and Fast mode. In Normal mode, there are no transaction fees, but gas fees will be charged by the respective blockchain. In Fast mode, you will be charged a variable fee by the Brine platform, but there will be no gas fees.',
      },
      {
        title: 'What is the estimated time for withdrawal to complete?',
        content: `In Normal mode, withdrawal will take up to ${NORMAL_WITHDRAWAL_CONFIRMATION_HOURS} hours and in Fast mode, withdrawal will take up to 5 minutes.`,
      },
      {
        title:
          'Why do normal withdrawals require more time to be processed than I expected?',
        content:
          'Brine is able to provide gasless transactions because of the robust usage of ZK (Zero-knowledge) based batching technology which updates the Ethereum blockchain at specified intervals. However, as the number of users increases, the updating interval decreases, thus reducing the timeline for normal withdrawals. You can always use fast withdrawal mode to get funds within a few minutes.',
      },
      {
        title: 'What is locked balance?',
        content:
          'When an order is placed but not yet executed, the balance is held back until the order is completed. This is referred to as locked balance',
      },
      {
        title: 'How is fast withdrawal mode different from normal withdrawal?',
        content:
          'Normal withdrawals take up to 24 hours and fast withdrawals take 5 minutes. For normal withdrawal, the user needs to pay the gas fee to the wallet and for fast withdrawal, the gas fee will be covered under the transfer fee that we charge.',
      },
      {
        title: 'What is fast withdrawal and how is the charge calculated?',
        content:
          'Fast withdrawal is an option through which you can withdraw your assets in less than 10 minutes. Fast withdrawal charge is a standard amount and it is calculated based on factors like greed, fear, etc.',
      },
      {
        title: 'Which coins can I withdraw using fast withdrawal?',
        content: `Currently, you can only withdraw ${
          ENVIRONMENT === 'testnet' ? 'BTC' : 'USDC'
        } in Ethereum and Polygon networks.`,
      },
      {
        title:
          'Is there any limit on how much I can withdraw through fast withdrawal?',
        content: `Currently, user level limit is 10000 ${
          ENVIRONMENT === 'testnet' ? 'BTC' : 'USDC'
        } and platform level limit is 20,000 ${
          ENVIRONMENT === 'testnet' ? 'BTC' : 'USDC'
        }.`,
      },
      {
        title: 'Which networks can I use to withdraw?',
        content: `You can withdraw both on Ethereum and Polygon networks; however, on Polygon, you can only make fast withdrawals (only ${
          ENVIRONMENT === 'testnet' ? 'BTC' : 'USDC'
        })`,
      },
      {
        title: 'What do I do if my fast withdrawal fails?',
        content:
          'The transaction could have failed for multiple reasons; you can reach out to us on support@brine.fi to resolve the issue. Kindly share your wallet address, the network used for withdrawal and the error message received.',
      },
      {
        title: 'How do I validate a transaction?',
        content: `You can validate a transaction by copying your wallet address and checking on Etherscan
         (${
           ENVIRONMENT === 'testnet'
             ? 'https://goerli.etherscan.io/'
             : 'https://etherscan.io/'
         }) or Polygonscan (${
          ENVIRONMENT === 'testnet'
            ? 'https://mumbai.polygonscan.com/'
            : 'https://polygonscan.com/'
        }) depending on which network was used for the transaction. You can look through the latest transactions and see if they were successful. If you still face issues, you can reach us at support@brine.fi`,
        htmlContent: (
          <p>
            You can validate a transaction by copying your wallet address and
            checking on{' '}
            <a
              href='https://etherscan.io/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Etherscan
            </a>{' '}
            or{' '}
            <a
              href='https://polygonscan.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Polygonscan
            </a>{' '}
            depending on which network was used for the transaction. You can
            look through the latest transactions and see if they were
            successful. If you still face issues, you can reach us at
            support@brine.fi.
          </p>
        ),
      },
    ],
    Deposit: [
      {
        title: 'What is the depositing fee?',
        content:
          'Brine does not charge a fee for deposits; however, there will be gas fees, which are charged by the respective blockchain.',
      },
      {
        title: 'How much time does it take for a deposit to be completed?',
        content: 'The deposit process will be completed within 20 minutes',
      },
    ],
    Referrals: [
      {
        title: 'How many people can I refer?',
        content:
          'You can refer as many friends as you like; we have no upper limit on referrals.',
      },
      {
        title: 'How much can I earn from referrals?',
        content:
          "The referrer earns up to 40% of the trading fee of their referees' transactions, and the referee earns 10% too.",
      },
      {
        title: 'Where do I find my referral code?',
        content:
          'You can find your unique referral code under the Rewards section.',
      },
    ],
    'Trading Competition': [
      {
        title: 'Where can I find the leaderboard?',
        content:
          'After signing in to the Brine trading platform, please select "Leaderboard" from the panel on the left side',
      },
      {
        title:
          'I am trading but my trading volume is not increasing in the leaderboard. Why?',
        content:
          'Each competition has a specific trading pair(s) which is counted towards trading volume; you can view this on the competition page. Trading on other pairs will not be counted in your leaderboard progress.',
      },
      {
        title: 'When will I get the leaderboard rewards?',
        content:
          'After the trading competition ends, the account with the highest trading volume will be selected and the reward will be credited within 4 working days after an official announcement. You can claim your rewards from the rewards section.',
      },
      {
        title: 'How can I claim my trading competition rewards?',
        content:
          'You can find them in the reward section, and claim it from there.',
      },
      {
        title:
          'I am tied with another user in trading volume, so how would the winner be selected?',
        content:
          'The trading volumes would be calculated to the decimal power, then we would make a decision.',
      },
      {
        title: 'How do I know about the next trading competition?',
        content:
          'You can follow us on social media to get instant updates about trading sprints, giveaways and much more.',
      },
    ],
  };

  const allData = [
    ...FAQ['Withdrawal'],
    ...FAQ['Referrals'],
    ...FAQ['Overview'],
    ...FAQ['Deposit'],
    ...FAQ['Transactions'],
    ...FAQ['Trading Competition'],
  ];

  const [accordionData, setAccordionData] = useState(FAQ['Overview']);

  // useEffect(() => {
  //   if (text.length >= 2) {
  //     const data = videosData.filter((data) =>
  //       data.title.toLocaleLowerCase().includes(text.toLocaleLowerCase())
  //     );
  //     setFilteredData(data);
  //   } else {
  //     setFilteredData(videosData);
  //   }
  // }, [text]);

  useEffect(() => {
    setAccordionData(FAQ[selectedFAQ]);
  }, [selectedFAQ]);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = searchFAQData?.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (query) {
      setText(query);
    }
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/help?query=${text}`);
  };

  useEffect(() => {
    if (query) {
      const faqFilteredData = allData.filter(
        (data) =>
          data.title.toLowerCase().includes(removeSuffix(text.toLowerCase())) ||
          data.content.toLowerCase().includes(removeSuffix(text.toLowerCase()))
      );
      setSearchFAQData(faqFilteredData);
      const videoFilteredData = videosData.filter((data) =>
        data.title.toLowerCase().includes(removeSuffix(text.toLowerCase()))
      );
      setCurrentPage(1);
      setSearchVideoData(videoFilteredData);

      sendTrackEvent('searchInputChanged', {
        query,
        resultFound: { FAQ: faqFilteredData, videos: videoFilteredData },
      });
    }
  }, [query, allData.length, videosData.length, text]);

  useEffect(() => {
    if (searchVideoData?.length || searchFAQData?.length) {
      scroller.scrollTo('scroll_result', {
        duration: 400,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [searchVideoData?.length, searchFAQData?.length]);

  useEffect(() => {
    if (analytics) {
      sendPageEvent('helperPageVisited');
    }
  }, [analytics]);

  useEffect(() => {
    if (selectedVideo) {
      sendTrackEvent('videoClicked', { videoLink: selectedVideo });
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (bannerDetails?.payload && coinsDetails?.convertedArray) {
      setAccordionData(FAQ[selectedFAQ]);
    }
  }, [bannerDetails?.payload, coinsDetails?.convertedArray]);

  let commonProps = {
    width,
    selectedVideo,
    selectedFAQ,
    setSelectedFAQ,
    accordionData,
    handleSubmit,
    text,
    setText,
    query,
    setSelectedVideo,
    currentPosts,
    postsPerPage,
    searchFAQData,
    paginate,
    currentPage,
    searchVideoData,
    FAQ,
  };

  useEffect(() => {
    if (!bannerDetails || !coinsDetails) {
      dispatch(getBannerDetails());
      dispatch(getCoinsDetails());
    }
  }, [dispatch]);

  return (
    <div>
      <HelmetContainer title='Help' />
      <NavBar isdark={isdark} />
      {query?.length ? (
        <SearchLayout {...commonProps} />
      ) : (
        <HelperHomeLayout {...commonProps} />
      )}
      <Modals selectedVideo={selectedVideo} setSelected={setSelectedVideo} />
      <Feedback />
    </div>
  );
}

export default HelperPage;
