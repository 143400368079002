import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useViewport,
  useWebSocketData,
  useBalance,
  useAnalytics,
  useMeta,
  useSegment,
} from '../../../utils/hooks';
import { getOrderNonce, createOrder } from '../../../actions/exchangeAction';
import { toast } from 'react-toastify';
import { CREATE_ORDER_CLEANUP } from '../../../constants/exchangeConstant';
import { getBalance, getPortolio, logout } from '../../../actions/userAction';
import { getQueryParams } from '../../../utils/query-helper';
import { sign } from '../../../utils/contracts/signature';
import { getKeyPairFromSignature } from '../../../utils/contracts/app';
import { getVaultId } from '../../../actions/walletAction';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { MAX_LENGTH_ALLOWED_IN_NUM_INPUT } from '../../../utils/constants';
import { useWeb3React } from '@web3-react/core';
import { LoadingBtn, CustomSelect, CustomButton } from '../../../components';
import { sanitizeProvider } from '../../../utils/sanitize-provider';
import { numberInputOnWheelPreventChange } from '../../../utils/numberPrevent';
import useFeature, { FEATURE_FLAG } from '../../../utils/hooks/useFeature';
import { Decimal } from 'decimal.js';
import { getDecimalJsInstance } from '../../../utils/decimal';

const ExchangeActionInput = React.forwardRef((props, ref) => {
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // Prevent entry of 'e' character
    if (keyValue.toLowerCase() === 'e' && keyCode !== 101) {
      event.preventDefault();
    }
  };

  const handleBeforeInput = (e) => {
    // prevent firefox issue
    const enteredCharacter = e.data;
    const sanitizedValue = enteredCharacter;
    const isValid = /^\d*\.?\d*$/.test(sanitizedValue);
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <input
      placeholder='0'
      className='actions__input'
      type='number' // defaultValue={0}
      min={0}
      ref={ref}
      step='any'
      onBeforeInput={handleBeforeInput}
      // onKeyDown={handleKeyPress}
      onWheel={numberInputOnWheelPreventChange}
      {...props}
    />
  );
});

export function InsufficientBalanceBtn({ text = 'Insufficient balance' }) {
  return (
    <button
      className='actions__btn btn btn_orange btn_wide'
      style={{
        backgroundColor: '#808191',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: 0.8,
      }}
    >
      &#9888; {text}
    </button>
  );
}

export function TradeModeToggler({ tradeMode, setTradeMode, styles }) {
  return (
    <div className='actions__menu toggle_mode' style={styles}>
      <div className='exchange_toggle_mode'>
        <h5>Trading Mode</h5>
        <p>Change to "{tradeMode ? 'Beginner' : 'Pro'}" 🚀 Mode</p>
      </div>
      <div className='custom_checkbox'>
        <input
          placeholder='0'
          type='checkbox'
          checked={tradeMode}
          onChange={(e) => {
            setTradeMode(e.target.checked);
            if (e.target.checked) {
              localStorage.setItem('brine_mode', true);
            } else {
              localStorage.setItem('brine_mode', false);
            }
          }}
        />
      </div>
    </div>
  );
}

const SelectMarketOptions = ({
  selectedMarket,
  options,
  setSelectedMarket,
}) => {
  return (
    <CustomSelect
      isExchangePage={true}
      defaultValue={
        selectedMarket ? selectedMarket : [{ value: 'market', label: 'Market' }]
      }
      options={options}
      onChange={(item) => setSelectedMarket(item)}
    />
  );
};

const AvailableBalance = ({
  symbol,
  investedData,
  selectedCell,
  baseCoinData,
  fetchOrderTable,
  setFetchOrderTable,
  tradeMode,
}) => {
  const { toFixedTrunc, getUSDCinBalance, isDecimal } = useBalance();
  const [disableRefetch, setDisableRefetch] = useState(false);
  // const {
  // theme: { isdark },
  // userLogin: { userInfo },
  // } = useSelector((state) => state);

  const { isdark } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();

  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);

  const refetchBalance = () => {
    if (disableRefetch) return '';
    localStorage.setItem(
      'brine_balance_usdt',
      getUSDCinBalance(symbol?.split('-')[1])
    );
    dispatch(getBalance());
    dispatch(getPortolio());
    setDisableRefetch(true);
    setTimeout(() => {
      const balanceUsdt = localStorage.getItem('brine_balance_usdt') || null;
      setDisableRefetch(false);
      if (
        balanceUsdt &&
        Number(balanceUsdt) < getUSDCinBalance(symbol?.split('-')[1])
      ) {
        setFetchOrderTable(true);
      }
    }, 5000);
  };

  return (
    <div
      className='operations__balance'
      style={{ position: 'relative', marginTop: 20 }}
    >
      <div className='operations__label'>AVAILABLE BALANCE</div>
      <div className='operations__counter'>
        {investedData
          ? toFixedTrunc(investedData?.balance, selectedCell.trade_decimal)
          : 0}{' '}
        {selectedCell.symbol?.toUpperCase()}
      </div>
      <div className='operations__counter'>
        {' '}
        {toFixedTrunc(
          getUSDCinBalance(symbol?.split('-')[1]),
          baseCoinData?.trade_decimal
        )
          ? toFixedTrunc(
              getUSDCinBalance(symbol?.split('-')[1]),
              baseCoinData?.trade_decimal
            )
          : 0}{' '}
        {symbol?.split('-')[1]?.toUpperCase()}
      </div>
      {userInfo?.token && (
        <div
          className={`refresh__balance ${disableRefetch ? 'disable_btn' : ''}`}
          style={{ top: tradeMode ? '32px' : '42px', right: '28px' }}
        >
          <div onClick={refetchBalance}>
            <i
              className='fas fa-redo-alt'
              data-tip={'Refresh after 5s'}
              data-for='refresh'
            ></i>
          </div>
          {disableRefetch && (
            <ReactTooltip
              place='bottom'
              id='refresh'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles'
            />
          )}
        </div>
      )}
    </div>
  );
};

const SectionTitle = ({ text, img }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <h3
        style={{
          marginBottom: 15,
          fontSize: 12,
          fontFamily: 'Poppins',
          fontWeight: 'lighter',
          marginLeft: 10,
        }}
      >
        {text}
      </h3>
      {img && (
        <img
          src={img}
          alt=''
          style={{ height: 20, marginLeft: 7, marginTop: -15 }}
        />
      )}
    </div>
  );
};

const BeginnerMode = (props) => {
  const {
    investedData,
    selectedCell,
    baseCoinData,
    symbol,
    options,
    setSelectedMarket,
    getInfoAboutCoins,
    activetab,
    getAmount,
    getlimit,
    amountINUSDT,
    loading,
    pricePerCoinsInSell,
    pricePerCoinsInBuy,
    handlePlaceOrder,
    selectedMarket,
    btnDisable,
    lastPrice,
    onChangeAmountBaseCoin,
    setActiveTab,
    inputRef,
    handleGetLimit,
    onChangeAmountInCoins,
    fetchOrderTable,
    setFetchOrderTable,
    maxLengthCheck,
    tradeMode,
  } = props;
  const { formatCurrency } = useBalance();
  const { userInfo } = useSelector((state) => state.userLogin);
  const history = useHistory();
  const location = useLocation();
  const { sendTrackEvent } = useSegment();

  return (
    <div className='beginner_mode'>
      <div className='actions__form'>
        <div className='actions__fieldset'>
          <div className='actions__field order_book_custom_select'>
            <AvailableBalance
              symbol={symbol}
              investedData={investedData}
              selectedCell={selectedCell}
              baseCoinData={baseCoinData}
              fetchOrderTable={fetchOrderTable}
              tradeMode={tradeMode}
              setFetchOrderTable={setFetchOrderTable}
            />
            <SelectMarketOptions
              selectedMarket={selectedMarket}
              options={options?.slice(1)}
              setSelectedMarket={setSelectedMarket}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: ' center',
              justifyContent: 'space-between',
            }}
          >
            <SectionTitle
              text='You Pay'
              img={
                activetab == 'Buy'
                  ? getInfoAboutCoins(symbol?.split('-')[1])?.logo
                  : getInfoAboutCoins(selectedCell?.symbol)?.logo
              }
            />
          </div>
          {/* {selectedMarket?.value === 'limit' ? (
            <>
              {activetab == 'Buy' ? (
                <>
                  <div className="actions__field">
                    <div className="actions__label">
                      Price per {symbol?.split('-')[0]?.toUpperCase()} in{' '}
                      {symbol?.split('-')[1]?.toUpperCase()}
                    </div>
                    <input
                      placeholder="0"
                      className="actions__input"
                      type="number"
                      // defaultValue={0}
                      min={0}
                      onInput={(e) => maxLengthCheck(e, getlimit)}
                      value={getlimit}
                      onChange={(e) => handleGetLimit(e, e.target.value)}
                    />
                    <div className="actions__currency color-purple">
                      {symbol?.split('-')[1]?.toUpperCase()}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="actions__field">
                    <div className="actions__label">
                      Amount in {selectedCell?.symbol?.toUpperCase()}
                    </div>
                    <input
                      placeholder="0"
                      className="actions__input"
                      type="number"
                      value={getAmount}
                      min={0}
                      ref={inputRef}
                      onInput={(e) => maxLengthCheck(e, getAmount)}
                      onChange={(e) => {
                        onChangeAmountBaseCoin(e, e.target.value);
                      }}
                    />
                    <div
                      className="actions__currency color-green"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {selectedCell?.symbol}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : ( */}
          <>
            {activetab == 'Buy' ? (
              <>
                <div className='actions__field'>
                  <div className='actions__label'>
                    {/* Amount in {symbol?.split('-')[1]?.toUpperCase()} */}
                    Amount
                  </div>
                  <ExchangeActionInput
                    value={amountINUSDT}
                    onInput={(e) => maxLengthCheck(e, amountINUSDT)}
                    onChange={(e) => onChangeAmountInCoins(e, e.target.value)}
                  />
                  <div className='actions__currency color-purple'>
                    {symbol?.split('-')[1]?.toUpperCase()}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='actions__field'>
                  <div className='actions__label'>
                    {/* Amount in {selectedCell?.symbol?.toUpperCase()} */}
                    Amount
                  </div>
                  <ExchangeActionInput
                    value={getAmount}
                    min={0}
                    ref={inputRef}
                    onInput={(e) => maxLengthCheck(e, getAmount)}
                    onChange={(e) => {
                      onChangeAmountBaseCoin(e, e.target.value);
                    }}
                  />
                  <div
                    className='actions__currency color-green'
                    style={{ textTransform: 'uppercase' }}
                  >
                    {selectedCell?.symbol}
                  </div>
                </div>
              </>
            )}
          </>
          {/* )} */}
          <div style={{ textAlign: 'center' }} className='change_trade_mode'>
            <i
              className='fas fa-sync-alt'
              onClick={() =>
                setActiveTab((pre) => (pre === 'Buy' ? 'Sell' : 'Buy'))
              }
            ></i>
          </div>
          <SectionTitle
            text='You Receive'
            img={
              activetab !== 'Sell'
                ? getInfoAboutCoins(selectedCell?.symbol)?.logo
                : getInfoAboutCoins(symbol?.split('-')[1])?.logo
            }
          />
          {activetab !== 'Sell' ? (
            <div className='actions__field'>
              <div className='actions__label'>
                {/* Amount in {selectedCell?.symbol?.toUpperCase()} */}
                Amount
              </div>
              <ExchangeActionInput
                value={getAmount}
                min={0}
                ref={inputRef}
                onInput={(e) => maxLengthCheck(e, getAmount)}
                onChange={(e) => {
                  onChangeAmountBaseCoin(e, e.target.value);
                }}
              />
              <div
                className='actions__currency color-green'
                style={{ textTransform: 'uppercase' }}
              >
                {selectedCell?.symbol}
              </div>
            </div>
          ) : (
            <>
              {/* {selectedMarket.value === 'limit' ? (
                <>
                  <div className="actions__field">
                    <div className="actions__label">
                      Price per {symbol?.split('-')[0]?.toUpperCase()} in{' '}
                      {symbol?.split('-')[1]?.toUpperCase()}
                    </div>
                    <input
                      placeholder="0"
                      className="actions__input"
                      type="number"
                      // defaultValue={0}
                      min={0}
                      value={getlimit}
                      onInput={(e) => maxLengthCheck(e, getlimit)}
                      onChange={(e) => handleGetLimit(e, e.target.value)}
                    />
                    <div className="actions__currency color-purple">
                      {symbol?.split('-')[1]?.toUpperCase()}
                    </div>
                  </div>
                </>
              ) : ( */}
              <>
                <div className='actions__field'>
                  <div className='actions__label'>
                    {/* Amount in {symbol?.split('-')[1]?.toUpperCase()} */}
                    Amount
                  </div>
                  <ExchangeActionInput
                    min={0}
                    value={amountINUSDT}
                    onInput={(e) => maxLengthCheck(e, amountINUSDT)}
                    onChange={(e) => onChangeAmountInCoins(e, e.target.value)}
                  />
                  <div className='actions__currency color-purple'>
                    {symbol?.split('-')[1]?.toUpperCase()}
                  </div>
                </div>
              </>
              {/* )} */}
            </>
          )}
        </div>
        {/* {selectedMarket?.value === 'limit' ? (
          <div
            className="h6 total-exchange__action"
            style={{ marginBottom: tradeMode ? 13 : 30, textAlign: 'right' }}
          >
            Total ={' '}
            {(getAmount * getlimit).toFixed(baseCoinData?.trade_decimal)}
          </div>
        ) : ( */}
        <>
          {activetab == 'Buy' ? (
            <div
              className='h6 total-exchange__action'
              style={{
                marginBottom: tradeMode ? 13 : 30,
                textAlign: 'right',
              }}
            >
              Price per {selectedCell?.symbol?.toUpperCase()} ={' '}
              {pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
                ? formatCurrency(
                    parseFloat(
                      lastPrice(
                        `${selectedCell?.symbol}${symbol?.split('-')[1]}`
                      )
                    ).toFixed(baseCoinData?.trade_decimal)
                  ) +
                  ' ' +
                  symbol?.split('-')[1].toUpperCase()
                : formatCurrency(
                    Number(pricePerCoinsInBuy).toFixed(
                      baseCoinData?.trade_decimal
                    )
                  ) +
                  ' ' +
                  symbol?.split('-')[1].toUpperCase()}
            </div>
          ) : (
            <div
              className='h6 total-exchange__action'
              style={{
                marginBottom: tradeMode ? 13 : 30,
                textAlign: 'right',
              }}
            >
              Price per {selectedCell?.symbol?.toUpperCase()} ={' '}
              {pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
                ? formatCurrency(
                    parseFloat(
                      lastPrice(
                        `${selectedCell?.symbol}${symbol?.split('-')[1]}`
                      )
                    ).toFixed(baseCoinData?.trade_decimal)
                  ) +
                  ' ' +
                  symbol?.split('-')[1].toUpperCase()
                : formatCurrency(
                    Number(pricePerCoinsInSell).toFixed(
                      baseCoinData?.trade_decimal
                    )
                  ) +
                  ' ' +
                  symbol?.split('-')[1].toUpperCase()}
            </div>
          )}
        </>
        {/* )} */}
        {userInfo?.token ? (
          <>
            {loading ? (
              <LoadingBtn size='full' bgcolor='#6c5dd3' />
            ) : (
              <button
                className='actions__btn btn btn_orange btn_wide'
                onClick={(e) =>
                  handlePlaceOrder(activetab === 'Buy' ? 'buy' : 'sell')
                }
                disabled={btnDisable}
                style={{
                  cursor: btnDisable ? 'not-allowed' : 'pointer',
                  pointerEvents: btnDisable ? 'none' : 'all',
                }}
              >
                Create Order
              </button>
            )}
          </>
        ) : (
          <CustomButton
            title='Connect wallet to trade'
            style={{
              minWidth: '100%',
            }}
            onClick={() => {
              sendTrackEvent('buttonClicked', {
                title: 'Connect Wallet',
                action: 'Routing to Login Page',
              });
              history.push({
                pathname: `/signin`,
                search: location.search,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

//TODO : ⚠️ REMOVE THE USDT MATH.TRUNC IN PROD
function ExchangeAction({
  selectedtab,
  setSelectedCell,
  selectedCell,
  tradeMode,
  setTradeMode,
  fetchOrderTable,
  setFetchOrderTable,
  orderBookPrice,
  setOrderBookPrice,
}) {
  const [activetab, setActiveTab] = useState('Buy');
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [getAmount, setGetAmount] = useState('');
  const [getlimit, setGetLimit] = useState('');
  const [amountINUSDT, setAmountINUSDT] = useState('');
  const [btnDisable, setbtnDisable] = useState(false);
  const [investedData, setInvestedData] = useState(null);
  const { getInfoAboutCoins } = useBalance();
  const { width } = useViewport();
  const breakpoint = 766;
  const dispatch = useDispatch();
  const [pricePerCoinsInBuy, setPricePerCoinsInBuy] = useState(0);
  const [pricePerCoinsInSell, setPricePerCoinsInSell] = useState(0);
  const [baseCoinData, setBaseCoinData] = useState('');
  const [orderData, setOrderData] = useState(null);
  const { get0X0to0X, connectedAccount } = useMeta();
  const [hasBalance, setHasBalance] = useState(true);
  const { symbol } = useParams();
  const inputRef = useRef();
  const { sendTrackEvent, analytics } = useSegment();
  const [total, setTotal] = useState(0);
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const { getStatusByFeatureKey } = useFeature();
  const [options] = useState([
    { value: 'limit', label: 'Limit' },
    { value: 'market', label: 'Market' },
  ]);

  const perValue = ['25', '50', '75', '100'];

  const { loading, error, orderDetails } = useSelector(
    (state) => state.createOrder
  );
  const history = useHistory();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);
  const [metaProvider, setMetaProvider] = useState(null);
  const { lastPrice, getMarketPrecision } = useWebSocketData();
  const { deactivate } = useWeb3React();
  const { formatCurrency, toFixedTrunc, getUSDCinBalance, isDecimal } =
    useBalance();
  // const {
  //   getOrderBook: {
  //     loading: orderLoading,
  //     error: orderError,
  //     orderBookData,
  //     reRender,
  //   },
  //   userKey: { state },
  //   vaultList: { state: vaultListState },
  //   userIpAddress: { ip },
  // } = useSelector((state) => state);

  const { reRender, orderBookData } = useSelector(
    (state) => state.getOrderBook
  );
  const { state } = useSelector((state) => state.userKey);

  useEffect(() => {
    if (orderDetails) {
      toast.success(orderDetails?.message);
      setOrderBookPrice(null);
      sendTrackEvent('orderPlacementSuccess', {
        market: symbol,
        ...orderDetails?.payload,
        lastPrice: lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`),
      });
      // analytics?.track(`Order created successfully!`, {
      //   from: 'Exchange page',
      //   type: selectedMarket.value,
      //   market: `${selectedCell?.symbol}${symbol?.split('-')[1]}`,
      //   side: activetab,
      //   volume: Number(getAmount),
      //   ord_type: selectedMarket?.value,
      //   price:
      //     selectedMarket.value === 'market'
      //       ? Number(
      //           activetab == 'Buy'
      //             ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
      //               ? parseFloat(
      //                   lastPrice(
      //                     `${selectedCell?.symbol}${symbol?.split('-')[1]}`
      //                   )
      //                 )
      //               : pricePerCoinsInBuy
      //             : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
      //             ? parseFloat(
      //                 lastPrice(
      //                   `${selectedCell?.symbol}${symbol?.split('-')[1]}`
      //                 )
      //               )
      //             : pricePerCoinsInSell
      //         )
      //       : Number(getlimit),
      //   currency_buy:
      //     activetab === 'Buy' ? symbol?.split('-')[0] : symbol?.split('-')[1],
      //   currency_sell:
      //     activetab === 'Buy' ? symbol?.split('-')[1] : symbol?.split('-')[0],
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      setTimeout(() => {
        setbtnDisable(false);
      }, 5000);
      resetForm(true);
      setTimeout(() => {
        dispatch(getPortolio());
        dispatch(getBalance());
      }, 5000);
    }
    if (error) {
      sendTrackEvent('orderPlacementFailure', { market: symbol, error });
      // analytics?.track(`Order creation failed!`, {
      //   from: 'Exchange page',
      //   type: selectedMarket.value,
      //   market: `${selectedCell?.symbol}${symbol?.split('-')[1]}`,
      //   side: activetab,
      //   volume: Number(getAmount),
      //   ord_type: selectedMarket?.value,
      //   price:
      //     selectedMarket.value === 'market'
      //       ? Number(
      //           activetab == 'Buy'
      //             ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
      //               ? parseFloat(
      //                   lastPrice(
      //                     `${selectedCell?.symbol}${symbol?.split('-')[1]}`
      //                   )
      //                 )
      //               : pricePerCoinsInBuy
      //             : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
      //             ? parseFloat(
      //                 lastPrice(
      //                   `${selectedCell?.symbol}${symbol?.split('-')[1]}`
      //                 )
      //               )
      //             : pricePerCoinsInSell
      //         )
      //       : Number(getlimit),
      //   currency_buy:
      //     activetab === 'Buy' ? symbol?.split('-')[0] : symbol?.split('-')[1],
      //   currency_sell:
      //     activetab === 'Buy' ? symbol?.split('-')[1] : symbol?.split('-')[0],
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      //   error: error,
      // });
      setTimeout(() => {
        setbtnDisable(false);
      }, 5000);
      toast.error(error);
    }
  }, [loading, error, orderDetails]);

  useEffect(() => {
    if (!loading && orderBookData) {
      setOrderData(orderBookData[selectedCell?.symbol + symbol?.split('-')[1]]);
    }
    getAsksValue();
    getBidsValue();
    return () => {
      setPricePerCoinsInBuy(0);
      setPricePerCoinsInSell(0);
    };
  }, [
    selectedCell.symbol,
    loading,
    orderBookData,
    orderData,
    reRender,
    symbol,
  ]);

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value?.toString().split('.')[1]?.length || 0;
  };

  const getAsksValue = () => {
    if (orderData) {
      const newOrderData = orderData;
      const data = newOrderData?.asks
        ?.sort((a, b) => Number(a[0]) - Number(b[0]))
        ?.slice(0, 5);

      if (data?.length) {
        setPricePerCoinsInBuy(
          Number(data[0][0]).toFixed(selectedCell?.quanitization)
        );
      }
    } else {
      setPricePerCoinsInBuy(Number(0).toFixed(selectedCell?.quanitization));
    }
  };

  const getBidsValue = () => {
    if (orderData) {
      const newOrderData = orderData;
      const data = newOrderData?.bids
        ?.sort((a, b) => Number(b[0]) - Number(a[0]))
        ?.slice(0, 5);

      if (data?.length) {
        setPricePerCoinsInSell(
          Number(data[0][0]).toFixed(selectedCell?.quanitization)
        );
      }
    } else {
      setPricePerCoinsInSell(Number(0)?.toFixed(selectedCell?.quanitization));
    }
  };

  useEffect(() => {
    if (orderBookPrice !== null) {
      setGetLimit(orderBookPrice);
    }
    return () => setOrderBookPrice(null);
  }, [orderBookPrice]);

  const resetForm = (create_order = null) => {
    if (!selectedMarket || selectedMarket?.value === 'market') {
      setSelectedMarket(options[1]);
    } else {
      setSelectedMarket(options[0]);
    }

    if (!create_order) {
      setGetAmount('');
      setGetLimit('');
      setAmountINUSDT('');
      setTotal(0);
    }
  };

  useEffect(() => {
    resetForm();
  }, [selectedCell, tradeMode, symbol]);

  const handlePreventDecimal = (e) => {
    let invalidChars = ['-', '+', 'e', '.'];
    if (invalidChars.includes(e.key)) {
      return e.preventDefault();
    }
  };

  const onChangeAmountBaseCoin = (e, value) => {
    if (
      selectedCell.symbol == 'shib' ||
      getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision == 0
    ) {
      setGetAmount(value);
      value = value.replace(/[e\+\-\.]/gi, '');
      const lastPrices =
        activetab === 'Buy'
          ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
            ? parseFloat(
                lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
              )
            : Number(pricePerCoinsInBuy)
          : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
          ? parseFloat(
              lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
            )
          : Number(pricePerCoinsInSell);
      const price = value * lastPrices;
      setAmountINUSDT(
        price?.toFixed(
          getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision
        )
      );
    } else if (
      countDecimals(getAmount) + 1 <=
        getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision ||
      (e.nativeEvent.inputType?.includes('delete') &&
        selectedCell?.symbol !== 'shib') ||
      (e.nativeEvent.inputType?.includes('insertText') &&
        !isNaN(value) &&
        String(value)?.length === 1)
    ) {
      setGetAmount(value);
      const lastPrices =
        activetab === 'Buy'
          ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
            ? parseFloat(
                lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
              )
            : Number(pricePerCoinsInBuy)
          : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
          ? parseFloat(
              lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
            )
          : Number(pricePerCoinsInSell);
      const price = value * lastPrices;
      setAmountINUSDT(
        price?.toFixed(
          getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision
        )
      );
    }
  };

  const onChangeAmountInCoins = (e, value) => {
    if (
      countDecimals(amountINUSDT) + 1 <=
        getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision ||
      e.nativeEvent.inputType?.includes('delete') ||
      (e.nativeEvent.inputType?.includes('insertText') &&
        !isNaN(value) &&
        String(value)?.length === 1)
    ) {
      setAmountINUSDT(value);
      const lastPrices =
        activetab === 'Buy'
          ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
            ? parseFloat(
                lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
              )
            : Number(pricePerCoinsInBuy)
          : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
          ? parseFloat(
              lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
            )
          : Number(pricePerCoinsInSell);
      const price = Number(value) / lastPrices;
      setGetAmount(
        price?.toFixed(
          getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
        )
      );
    }
  };

  const findUserHaveThisCoin = () => {
    const isContains = balance?.payload?.find((item) => {
      return item.currency == selectedCell.symbol;
    });
    setInvestedData(isContains);
  };

  useEffect(() => {
    findUserHaveThisCoin();
    const data = getInfoAboutCoins(symbol?.split('-')[1]);
    setBaseCoinData(data);
    return () => {
      setBaseCoinData('');
    };
  }, [selectedCell, balance, symbol]);

  const handleGetLimit = (e, value) => {
    if (
      countDecimals(value) <=
        getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision ||
      e.nativeEvent.inputType?.includes('delete')
    ) {
      setGetLimit(value);
    }
  };

  useEffect(() => {
    if (inputRef) {
      if (selectedCell.symbol == 'shib' || selectedCell.trade_decimal == 0) {
        inputRef.current?.addEventListener('keydown', handlePreventDecimal);
        return () =>
          inputRef.current?.removeEventListener(
            'keydown',
            handlePreventDecimal
          );
      }
    }
  }, [inputRef, selectedCell.symbol, selectedCell.trade_decimal]);

  useEffect(() => {
    // analytics?.track(`User clicked ${activetab} section!`, {
    //   from: 'Exchange page',
    //   symbol: selectedCell.symbol,
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });
    setOrderBookPrice(null);
  }, [activetab]);

  const handleCustomLimitPriceByBalance = (side, percent) => {
    if (side === 'buy') {
      // Custom limit with user balance
      // Calculate how many BTC a user can buy
      // Formula: percentage * (balanceOfUserInUsdc / getlimit)
      // Example: 0.75 * (3092.45 / 20) = [result]

      let balanceOfCurrentCoin = getUSDCinBalance(symbol?.split('-')[1]);
      let perValue = calcuatePercentage(percent, balanceOfCurrentCoin);
      let btcAmount = perValue / getlimit;
      let total = btcAmount * getlimit;

      setGetAmount(
        toFixedTrunc(
          btcAmount,
          getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
        )
      );
      setTotal(total);
    }
  };

  const handlePercentInBuy = (percent) => {
    // const temp1 =
    //   (Number(percent) * Number(getUSDCinBalance(symbol?.split('-')[1]))) / 100;
    let balanceOfCurrentCoin = getUSDCinBalance(symbol?.split('-')[1]);
    // if (!balanceOfCurrentCoin || balanceOfCurrentCoin === 0) {
    //   setGetAmount(0);
    //   setAmountINUSDT(0);
    //   return;
    // }
    let perValue = calcuatePercentage(percent, balanceOfCurrentCoin);
    // const perValue = (temp1 / 100) * 100;
    if (selectedMarket?.value === 'market') {
      setAmountINUSDT(
        toFixedTrunc(
          perValue,
          getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision
        )
      );
      const lastPrices =
        pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
          ? parseFloat(
              lastPrice(`${selectedCell?.symbol}${symbol?.split('-')[1]}`)
            )
          : Number(pricePerCoinsInBuy);
      const price = Number(perValue) / lastPrices;
      setGetAmount(
        toFixedTrunc(
          price,
          getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
        )
      );
    } else {
      const lastPriceOfCoin =
        pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
          ? parseFloat(
              lastPrice(`${selectedCell?.symbol}${symbol?.split('-')[1]}`)
            )
          : Number(pricePerCoinsInBuy);

      if (getlimit == 0) {
        setGetLimit(
          toFixedTrunc(
            Number(lastPriceOfCoin),
            getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision
          )
        );
      }

      if (getlimit != 0) {
        handleCustomLimitPriceByBalance('buy', percent);
      } else {
        let price;
        if (lastPriceOfCoin !== 0) {
          price = Number(perValue) / lastPriceOfCoin;
        } else {
          price = 0;
        }

        setGetAmount(
          toFixedTrunc(
            price,
            getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
          )
        );
        setTotal(price * (getlimit ? getlimit : lastPriceOfCoin));
      }
    }
  };

  let calcuatePercentage = (per, value) => {
    if (per == null || value == null) {
      return 0;
    }
    // Convert percent and balanceOfCurrentCoin to Decimal objects
    const percentDecimal = getDecimalJsInstance(per);
    const balanceDecimal = getDecimalJsInstance(value);

    // Calculate temp1 as a decimal
    const temp1 = percentDecimal.mul(balanceDecimal).div(100);
    return temp1.toNumber();
  };

  const handlePercentInSell = (percent) => {
    const balanceOfCurrentCoin = investedData
      ? Number(
          toFixedTrunc(
            investedData?.balance,
            getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
          )
        )
      : 0;

    if (balanceOfCurrentCoin !== 0) {
      if (selectedMarket?.value === 'market') {
        // const x = (Number(percent) * Number(balanceOfCurrentCoin)) / 100;
        let perValue = calcuatePercentage(percent, balanceOfCurrentCoin);
        setGetAmount(
          Number(
            toFixedTrunc(
              perValue,
              getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
            )
          )
        );
        // console.log({ perValue, x: x.toString(), perValue });
        const price =
          perValue.toFixed(
            getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
          ) *
          (pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
            ? Number(
                lastPrice(`${selectedCell?.symbol}${symbol?.split('-')[1]}`)
              )
            : Number(pricePerCoinsInSell));
        setAmountINUSDT(
          Number(toFixedTrunc(price, baseCoinData?.trade_decimal))
        );
      } else {
        // const temp1 = (Number(percent) * Number(balanceOfCurrentCoin)) / 100;
        // const perValue = (temp1 / 100) * 100;

        let perValue = calcuatePercentage(percent, balanceOfCurrentCoin);
        const price =
          perValue.toFixed(
            getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
          ) * Number(pricePerCoinsInSell);
        if (getlimit == 0) {
          if (pricePerCoinsInSell == 0 || !pricePerCoinsInSell) {
            setGetLimit(
              toFixedTrunc(
                Number(
                  lastPrice(`${selectedCell?.symbol}${symbol?.split('-')[1]}`)
                ),
                2
              )
            );
          } else {
            setGetLimit(
              toFixedTrunc(
                Number(pricePerCoinsInSell),
                getMarketPrecision(symbol?.replace('-', ''))?.pricePrecision
              )
            );
          }
        }
        setGetAmount(
          Number(
            toFixedTrunc(
              perValue,
              getMarketPrecision(symbol?.replace('-', ''))?.amountPrecision
            )
          )
        );
      }
    } else {
      setGetAmount('');
      setAmountINUSDT('');
    }
  };

  useEffect(() => {
    setAmountINUSDT('');
    setGetLimit('');
    setGetAmount('');
    setTotal(0);
  }, [activetab, selectedMarket]);

  const getPercentage = (per, val) => {
    return ((per * val) / 100).toFixed();
  };

  // const getOrderHistoryData = () => {
  //   dispatch(getOrderData(1, false));
  //   dispatch(getOrderData(1, true));
  //   dispatch(getTradesData(1));
  // };

  useEffect(() => {
    if (activetab == 'Buy') {
      if (
        selectedMarket?.value == 'market' &&
        Number(getUSDCinBalance(symbol?.split('-')[1])) < Number(amountINUSDT)
      ) {
        setHasBalance(false);
      } else if (
        selectedMarket?.value == 'limit' &&
        Number(getUSDCinBalance(symbol?.split('-')[1])) <
          Number(getAmount * getlimit)
      ) {
        setHasBalance(false);
      }
    } else if (activetab == 'Sell') {
      if (Number(investedData?.balance) < Number(getAmount)) {
        setHasBalance(false);
      }
    }
    return () => {
      setHasBalance(true);
    };
  }, [
    activetab,
    investedData,
    getAmount,
    getAmount,
    getlimit,
    symbol,
    amountINUSDT,
    selectedMarket,
  ]);

  const handleCreateDexOrder = async (obj) => {
    dispatch({
      type: CREATE_ORDER_CLEANUP,
    });
    dispatch(createOrder(obj));
  };

  const handlePlaceOrder = async (side) => {
    let feature = getStatusByFeatureKey(FEATURE_FLAG['orders']);

    if (!feature.status) {
      return toast.info(feature?.reason);
    }

    // if (
    //   userVia?.via === 'metamask' &&
    //   (!metaProvider?.isConnected() ||
    //     !metaProvider?._state?.initialized ||
    //     !connectedAccount)
    // ) {
    //   toast.error('Your session has expired; please log in again');
    //   setTimeout(() => {
    //     deactivate();
    //     dispatch(logout(true));
    //   }, 1500);
    //   return;
    // }

    // analytics?.track(`Order button clicked!`, {
    //   from: 'Exchange page',
    //   symbol: selectedCell.symbol,
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });

    let currentLastPrice =
      activetab == 'Buy'
        ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
          ? lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
          : pricePerCoinsInBuy
        : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
        ? lastPrice(`${selectedCell.symbol}${symbol?.split('-')[1]}`)
        : pricePerCoinsInSell;

    if (
      selectedMarket.value === 'market' &&
      getAmount * Number(currentLastPrice) <= 0
    ) {
      return toast.error('Please enter an amount greater than zero');
    }

    if (selectedMarket.value === 'limit' && getAmount * getlimit <= 0) {
      return toast.error('Please enter an amount greater than zero');
    }

    if (activetab == 'Buy') {
      if (
        selectedMarket?.value == 'market' &&
        Number(getUSDCinBalance(symbol?.split('-')[1])) < Number(amountINUSDT)
      ) {
        return toast.error(
          'Insufficient balance (market prices are constantly fluctuating, so don’t convert all your balance)'
        );
      } else if (
        selectedMarket?.value == 'limit' &&
        Number(getUSDCinBalance(symbol?.split('-')[1])) <
          Number(getAmount * getlimit)
      ) {
        return toast.error(
          'Insufficient balance (market prices are constantly fluctuating, so don’t convert all your balance)'
        );
      }
    } else if (activetab == 'Sell') {
      if (Number(investedData?.balance) < Number(getAmount)) {
        return toast.error(
          'Insufficient balance (market prices are constantly fluctuating, so don’t convert all your balance)'
        );
      }
    }

    if (
      getAmount >= Number(selectedCell.minimum_order) &&
      Number(selectedCell.maximum_order) >= getAmount
    ) {
      let stark_public_key = getKeyPairFromSignature(state);

      let price =
        selectedMarket.value === 'market'
          ? Number(
              activetab == 'Buy'
                ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
                  ? parseFloat(
                      lastPrice(
                        `${selectedCell?.symbol}${symbol?.split('-')[1]}`
                      )
                    )
                  : pricePerCoinsInBuy
                : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
                ? parseFloat(
                    lastPrice(`${selectedCell?.symbol}${symbol?.split('-')[1]}`)
                  )
                : pricePerCoinsInSell
            )
          : Number(getlimit);

      try {
        setbtnDisable(true);
        const orderReqData = {
          market: `${selectedCell?.symbol}${symbol?.split('-')[1]}`,
          side: side,
          volume: Number(getAmount),
          ord_type: selectedMarket?.value,
          price: price,
          currency_buy:
            activetab === 'Buy' ? symbol?.split('-')[0] : symbol?.split('-')[1],
          currency_sell:
            activetab === 'Buy' ? symbol?.split('-')[1] : symbol?.split('-')[0],
        };
        sendTrackEvent('orderNonceRequestInitiated', { market: symbol });
        const data = await getOrderNonce(userInfo?.token?.access, orderReqData);
        sendTrackEvent('orderNonceRequestSuccess', { market: symbol });
        // let msg_hash = getLimitOrderMsgHashWithFee(
        //   data?.payload?.order_data?.vault_id_sell,
        //   data?.payload?.order_data?.vault_id_buy,
        //   data?.payload?.order_data?.amount_sell,
        //   data?.payload?.order_data?.amount_buy,
        //   data?.payload?.order_data?.token_sell,
        //   data?.payload?.order_data?.token_buy,
        //   data?.payload?.nonce,
        //   data?.payload?.order_data?.expiration_timestamp,
        //   data?.payload?.order_data?.token_sell,
        //   data?.payload?.order_data?.vault_id_sell,
        //   data?.payload?.order_data?.fee_info?.fee_limit
        // );
        let msg = sign(
          stark_public_key,
          data.payload.msg_hash?.replace('0x', '')
        );
        const orderData = {
          msg_hash: data?.payload?.msg_hash,
          signature: {
            r: `0x${msg.r.toString('hex')}`,
            s: `0x${msg.s.toString('hex')}`,
          },
          nonce: data?.payload?.nonce,
        };
        sendTrackEvent('orderPlacementInitiated', { market: symbol });
        await handleCreateDexOrder(orderData);
      } catch (error) {
        sendTrackEvent('orderNonceRequestFailure', { market: symbol, error });
        // analytics?.track(`Order creation failed!`, {
        //   from: 'Exchange page',
        //   type: selectedMarket.value,
        //   market: `${selectedCell?.symbol}${symbol?.split('-')[1]}`,
        //   side: activetab,
        //   volume: Number(getAmount),
        //   ord_type: selectedMarket?.value,
        //   price:
        //     selectedMarket.value === 'market'
        //       ? Number(
        //           activetab == 'Buy'
        //             ? pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
        //               ? parseFloat(
        //                   lastPrice(
        //                     `${selectedCell?.symbol}${symbol?.split('-')[1]}`
        //                   )
        //                 )
        //               : pricePerCoinsInBuy
        //             : pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
        //             ? parseFloat(
        //                 lastPrice(
        //                   `${selectedCell?.symbol}${symbol?.split('-')[1]}`
        //                 )
        //               )
        //             : pricePerCoinsInSell
        //         )
        //       : Number(getlimit),
        //   currency_buy:
        //     activetab === 'Buy' ? symbol?.split('-')[0] : symbol?.split('-')[1],
        //   currency_sell:
        //     activetab === 'Buy' ? symbol?.split('-')[1] : symbol?.split('-')[0],
        //   uid: userInfo?.payload?.uid,
        //   QueryParams: { ...getQueryParams() },
        //   ip: ip,
        //   error: error,
        // });
        setOrderBookPrice(null);
        setTimeout(() => {
          setbtnDisable(false);
        }, 5000);
        toast.error(error);
      }
    } else {
      if (Number(selectedCell.maximum_order) < getAmount) {
        toast.error(
          `Amount cannot exceed  ${selectedCell.maximum_order} ${selectedCell?.symbol}`
        );
      } else if (getAmount < Number(selectedCell.minimum_order)) {
        toast.error(
          `Amount should be at least  ${selectedCell.minimum_order} ${selectedCell?.symbol}`
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: CREATE_ORDER_CLEANUP,
        payload: null,
      });
    };
  }, []);

  const maxLengthCheck = (object, selectedValue) => {
    if (!isDecimal(object.target.value)) {
      let maxLength = MAX_LENGTH_ALLOWED_IN_NUM_INPUT + 1;
      const { value } = object.target;
      if (String(value).length >= maxLength) {
        object.target.value = selectedValue;
      }
    }
  };

  const commomProps = {
    investedData,
    toFixedTrunc,
    selectedCell,
    getUSDCinBalance,
    baseCoinData,
    symbol,
    options,
    setSelectedMarket,
    getInfoAboutCoins,
    activetab,
    getAmount,
    getlimit,
    amountINUSDT,
    formatCurrency,
    loading,
    pricePerCoinsInSell,
    pricePerCoinsInBuy,
    handlePlaceOrder,
    selectedMarket,
    btnDisable,
    lastPrice,
    onChangeAmountBaseCoin,
    setActiveTab,
    inputRef,
    fetchOrderTable,
    setFetchOrderTable,
    handleGetLimit,
    onChangeAmountInCoins,
    maxLengthCheck,
    tradeMode,
  };

  useEffect(() => {
    sanitizeProvider(setMetaProvider);
  }, []);

  function renderTab() {
    if (activetab === 'Buy') {
      return (
        <div className='actions__form'>
          <div className='actions__fieldset'>
            <div className='actions__field order_book_custom_select'>
              <AvailableBalance {...commomProps} />
              <SelectMarketOptions {...commomProps} />
            </div>
            {selectedMarket?.value === 'limit' && (
              <div className='actions__field'>
                <div className='actions__label'>
                  {/* Price per {symbol?.split('-')[0]?.toUpperCase()} in{' '}
                  {symbol?.split('-')[1]?.toUpperCase()} */}
                  Price
                </div>
                <ExchangeActionInput
                  // defaultValue={0}
                  min={0}
                  value={getlimit}
                  onInput={(e) => maxLengthCheck(e, getlimit)}
                  onChange={(e) => handleGetLimit(e, e.target.value)}
                />
                <div className='actions__currency color-purple'>
                  {symbol?.split('-')[1]?.toUpperCase()}
                </div>
              </div>
            )}
            {selectedMarket?.value !== 'limit' && (
              <>
                <div className='actions__field'>
                  <div className='actions__label'>
                    {/* Amount in {symbol?.split('-')[1]?.toUpperCase()} */}
                    Amount
                  </div>
                  <ExchangeActionInput
                    min={0}
                    value={amountINUSDT}
                    onInput={(e) => maxLengthCheck(e, amountINUSDT)}
                    onChange={(e) => onChangeAmountInCoins(e, e.target.value)}
                  />
                  <div className='actions__currency color-purple'>
                    {symbol?.split('-')[1]?.toUpperCase()}
                  </div>
                </div>
              </>
            )}
            <div className='actions__field'>
              <div className='actions__label'>
                {/* Amount in {selectedCell?.symbol?.toUpperCase()} */}
                Amount
              </div>
              <ExchangeActionInput
                value={getAmount}
                min={0}
                ref={inputRef}
                onInput={(e) => maxLengthCheck(e, getAmount)}
                onChange={(e) => {
                  onChangeAmountBaseCoin(e, e.target.value);
                }}
              />
              <div
                className='actions__currency color-green'
                style={{ textTransform: 'uppercase' }}
              >
                {selectedCell?.symbol}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                marginLeft: -23,
              }}
            >
              {perValue.map((value, idx) => (
                <span
                  className='numbers_slider'
                  key={idx}
                  onClick={() => handlePercentInBuy(value)}
                >
                  {value}%
                </span>
              ))}
            </div>
          </div>

          {selectedMarket?.value === 'limit' ? (
            <div
              className='h6 total-exchange__action'
              style={{ marginBottom: tradeMode ? 13 : 30, textAlign: 'right' }}
            >
              Total ={' '}
              {
                // activetab === 'Buy'
                //   ? toFixedTrunc(total, baseCoinData?.trade_decimal)
                //   :
                toFixedTrunc(
                  getAmount * getlimit,
                  baseCoinData?.trade_decimal
                ) || 0
              }{' '}
              {symbol?.split('-')[1]?.toUpperCase()}
            </div>
          ) : (
            <div
              className='h6 total-exchange__action'
              style={{ marginBottom: tradeMode ? 13 : 30, textAlign: 'right' }}
            >
              Price per {selectedCell?.symbol?.toUpperCase()} ={' '}
              {pricePerCoinsInBuy == 0 || isNaN(pricePerCoinsInBuy)
                ? formatCurrency(
                    parseFloat(
                      lastPrice(
                        `${selectedCell?.symbol}${symbol?.split('-')[1]}`
                      )
                    ).toFixed(baseCoinData?.trade_decimal)
                  ) +
                  ' ' +
                  symbol?.split('-')[1]?.toUpperCase()
                : formatCurrency(
                    Number(pricePerCoinsInBuy).toFixed(
                      baseCoinData?.trade_decimal
                    )
                  ) +
                  ' ' +
                  symbol?.split('-')[1]?.toUpperCase()}
            </div>
          )}
          {userInfo?.token ? (
            <>
              {loading ? (
                <LoadingBtn size='full' bgcolor='#53ce53' />
              ) : (
                <>
                  {hasBalance ? (
                    <button
                      className='actions__btn btn btn_orange btn_wide'
                      onClick={(e) => handlePlaceOrder('buy')}
                      disabled={btnDisable == true}
                      style={{
                        backgroundColor: '#53ce53',
                        cursor: btnDisable ? 'not-allowed' : 'pointer',
                        pointerEvents: btnDisable ? 'none' : 'all',
                      }}
                    >
                      Buy {selectedCell?.symbol?.toUpperCase()}
                    </button>
                  ) : (
                    <InsufficientBalanceBtn />
                  )}
                </>
              )}
            </>
          ) : (
            <CustomButton
              title='Connect wallet to trade '
              style={{
                minWidth: '100%',
              }}
              onClick={() => {
                history.push({
                  pathname: `/signin`,
                  search: location.search,
                });
                sendTrackEvent('buttonClicked', {
                  title: 'Connect Wallet',
                  action: 'Routing to Login Page',
                });
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className='actions__form'>
          <div className='actions__fieldset'>
            <div className='actions__field order_book_custom_select'>
              <AvailableBalance {...commomProps} />
              <SelectMarketOptions {...commomProps} />
            </div>
            {selectedMarket?.value === 'limit' && (
              <div className='actions__field'>
                <div className='actions__label'>
                  {/* Price per {symbol?.split('-')[0]?.toUpperCase()} in{' '}
                  {symbol?.split('-')[1]?.toUpperCase()} */}
                  Price
                </div>
                <ExchangeActionInput
                  defaultValue={0}
                  min={0}
                  value={getlimit}
                  onInput={(e) => maxLengthCheck(e, getlimit)}
                  onChange={(e) => handleGetLimit(e, e.target.value)}
                />
                <div className='actions__currency color-purple'>
                  {symbol?.split('-')[1]?.toUpperCase()}
                </div>
              </div>
            )}

            {selectedMarket?.value !== 'limit' && (
              <div className='actions__field'>
                <div className='actions__label'>
                  {/* Amount in {symbol?.split('-')[1]?.toUpperCase()} */}
                  Amount
                </div>
                <ExchangeActionInput
                  defaultValue={0}
                  min={0}
                  value={amountINUSDT}
                  onInput={(e) => maxLengthCheck(e, amountINUSDT)}
                  onChange={(e) => onChangeAmountInCoins(e, e.target.value)}
                />
                <div className='actions__currency color-purple'>
                  {symbol?.split('-')[1]?.toUpperCase()}
                </div>
              </div>
            )}
            <div className='actions__field'>
              <div className='actions__label'>
                {/* Amount in {selectedCell?.symbol?.toUpperCase()} */}
                Amount
              </div>
              <ExchangeActionInput
                value={getAmount}
                id={selectedCell.symbol === 'shib' ? '' : 'preventdecimal'}
                min={0}
                onInput={(e) => maxLengthCheck(e, getAmount)}
                ref={inputRef}
                onChange={(e) => {
                  onChangeAmountBaseCoin(e, e.target.value);
                }}
              />
              <div
                className='actions__currency color-red'
                style={{ textTransform: 'uppercase' }}
              >
                {selectedCell?.symbol}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                marginLeft: -23,
              }}
            >
              {perValue.map((value, idx) => (
                <span
                  className='numbers_slider'
                  key={idx}
                  onClick={() => handlePercentInSell(value)}
                >
                  {value}%
                </span>
              ))}
            </div>
          </div>
          {selectedMarket?.value === 'limit' ? (
            <div
              className='h6 total-exchange__action'
              style={{ marginBottom: tradeMode ? 13 : 30, textAlign: 'right' }}
            >
              Total ={' '}
              {
                // activetab === 'Buy'
                //   ? toFixedTrunc(total, baseCoinData?.trade_decimal)
                //   :
                toFixedTrunc(
                  getAmount * getlimit,
                  baseCoinData?.trade_decimal
                ) || 0
              }{' '}
              {symbol?.split('-')[1]?.toUpperCase()}
            </div>
          ) : (
            <div
              className='h6 total-exchange__action'
              style={{ marginBottom: tradeMode ? 13 : 30, textAlign: 'right' }}
            >
              Price per {selectedCell?.symbol?.toUpperCase()} ={' '}
              {pricePerCoinsInSell == 0 || isNaN(pricePerCoinsInSell)
                ? formatCurrency(
                    parseFloat(
                      lastPrice(
                        `${selectedCell?.symbol}${symbol?.split('-')[1]}`
                      )
                    ).toFixed(baseCoinData?.trade_decimal)
                  ) +
                  ' ' +
                  symbol?.split('-')[1]?.toUpperCase()
                : formatCurrency(
                    Number(pricePerCoinsInSell).toFixed(
                      baseCoinData?.trade_decimal
                    )
                  ) +
                  ' ' +
                  symbol?.split('-')[1]?.toUpperCase()}
            </div>
          )}
          {userInfo?.token ? (
            <>
              {loading ? (
                <LoadingBtn size='full' bgcolor='#ff5353' />
              ) : (
                <>
                  {hasBalance ? (
                    <button
                      className='actions__btn btn btn_orange btn_wide'
                      onClick={(e) => handlePlaceOrder('sell')}
                      disabled={btnDisable == true}
                      style={{
                        backgroundColor: '#ff5353',
                        cursor: btnDisable ? 'not-allowed' : 'pointer',
                        pointerEvents: btnDisable ? 'none' : 'all',
                      }}
                    >
                      Sell {selectedCell?.symbol?.toUpperCase()}
                    </button>
                  ) : (
                    <InsufficientBalanceBtn btnDisable={btnDisable} />
                  )}
                </>
              )}
            </>
          ) : (
            <CustomButton
              title='Connect wallet to trade '
              style={{
                minWidth: '100%',
              }}
              onClick={() => {
                history.push({
                  pathname: `/signin`,
                  search: location.search,
                });
                sendTrackEvent('buttonClicked', {
                  title: 'Connect Wallet',
                  action: 'Routing to Login Page',
                });
              }}
            />
          )}
        </div>
      );
    }
  }

  useEffect(() => {
    if (analytics) {
      sendTrackEvent('orderTypeChangeDetected', {
        lastPrice: lastPrice(symbol?.replace('-', '')),
        market: selectedMarket?.value,
        side: activetab,
      });
    }
  }, [analytics, selectedMarket, activetab]);

  return (
    <div
      className='actions'
      style={{
        display:
          (selectedtab === 'market__tabs' && width < breakpoint) ||
          (tradeMode && width > breakpoint)
            ? 'block'
            : 'none',
        marginTop:
          selectedtab === 'market__tabs' && width < breakpoint ? '25px' : 0,
      }}
    >
      {/* {tradeMode && (
        <div className="profit_container">
          <div>
            <h6>Open Position Price</h6>
            <p>12,3333</p>
          </div>
          <div>
            <h6>Current Price</h6>
            <p>12, 333</p>
          </div>
          <div className="profit">
            <h6>Est* profit</h6>
            <p>11.2 %</p>
          </div>
        </div>
      )}{' '} */}
      {width <= 1299 && (
        <TradeModeToggler tradeMode={tradeMode} setTradeMode={setTradeMode} />
      )}
      {tradeMode ? (
        <>
          <div className='actions__menu'>
            <button
              className={`actions__link ${activetab === 'Buy' ? 'active' : ''}`}
              onClick={() => setActiveTab('Buy')}
              style={{ color: 'rgb(83, 206, 83)' }}
            >
              Buy
            </button>
            <button
              className={`actions__link ${
                activetab === 'Sell' ? 'active' : ''
              }`}
              style={{ color: 'rgb(255, 83, 83)' }}
              onClick={() => setActiveTab('Sell')}
            >
              Sell
            </button>
          </div>
          <>{renderTab()}</>
        </>
      ) : (
        <BeginnerMode {...commomProps} />
      )}
    </div>
  );
}

export default ExchangeAction;
