import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import {
  addWatchList,
  removeWatchList,
  getWatchList,
} from '../../../actions/assestsAction';
import {
  useBalance,
  useViewport,
  useWebSocketData,
  useAnalytics,
  listenForOutsideClicks,
} from '../../../utils/hooks';
import { Icon, Popup, FormInput, Image } from '../../../components';
import {
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { getQueryParams } from '../../../utils/query-helper';
import { baseCurrency } from '../../../utils/constants';
import { images } from '../../../utils/image/img';
import { isMobileBrowser } from '../../../utils/browser-checker';

function DetailsTop({
  coinDetails,
  selectedCell,
  setSelectedCell,
  symbol,
  result,
  setSearchText,
  searchText,
}) {
  const [openselected, setOpenselectedCell] = useState(false);
  const { lastPrice, checkItInWatchList } = useWebSocketData();
  const { formatCurrency } = useBalance();
  const history = useHistory();
  const [openPopUp, setOpenPopUp] = useState(false);
  const { width, height } = useViewport();
  // const { analytics } = useAnalytics();
  const location = useLocation();
  const coinsDropdownRef = useRef(null);
  const [listening, setListening] = useState(false);
  const priceDetailsLinkRef = useRef();
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const dispatch = useDispatch();
  // const {
  // theme: { isdark },
  // userWatchList: { loading, response },
  // userLogin: { userInfo },
  // userIpAddress: { ip },
  // } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, response } = useSelector((state) => state.userWatchList);

  useEffect(() => {
    setSelectedCell(coinDetails);
  }, [selectedCell]);

  //console.log(coinDetails, 'coinDetails');

  const title = `Buy, sell, trade and pay with cryptocurrency to anyone, anywhere, anytime. Brine is all the gear you'd need for your crypto-hunt. \n`;
  const url = window.location.href;
  const hashtags = '#Brine #cryptocurrency';
  const via = 'Brine';

  const shareCoins = () => {
    if (isMobileBrowser()) {
      if (navigator.share) {
        navigator
          .share({
            title: title,
          })
          .then(() => {
            // analytics?.track('Clicked share button', {
            //   from: 'Price details',
            //   uid: userInfo?.payload?.uid,
            //   symbol: !selectedCell ? coinDetails?.name : selectedCell?.name,
            //   QueryParams: { ...getQueryParams() },
            //   ip: ip,
            // });
            toast.success('Thanks for sharing 👍🏻');
          })
          .catch(console.error);
      } else {
        // fallback
        // analytics?.track('Clicked share button', {
        //   from: 'Price details',
        //   uid: userInfo?.payload?.uid,
        //   symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
        //   QueryParams: { ...getQueryParams() },
        //   ip: ip,
        // });
        setOpenPopUp(true);
      }
    } else {
      setOpenPopUp(true);
    }
  };

  const handleWatchList = async (coin) => {
    if (response) {
      const filteredItem = response?.payload?.find(
        (item) => item?.name === 'watch-list-1'
      );

      const checkdata = filteredItem?.coins.coins?.filter((item) => {
        return item == coin.symbol;
      });
      //console.log(checkdata);
      if (checkdata.length > 0) {
        try {
          const data = await removeWatchList(
            userInfo.token.access,
            coin.symbol
          );
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          // analytics?.track('Remove watchlist success', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
        } catch (e) {
          //console.log(e);
          // analytics?.track('Remove watchlist failed', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
          //   reason: e,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
          toast.error(e);
        }
      } else {
        try {
          const data = await addWatchList(userInfo.token.access, coin.symbol);
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          // analytics?.track('Add watchlist success', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
          //   QueryParams: { ...getQueryParams() },
          //   ip: ip,
          // });
        } catch (e) {
          // analytics?.track('Add watchlist failed', {
          //   from: 'Price details',
          //   uid: userInfo?.payload?.uid,
          //   symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
          //   reason: e,
          //   ip: ip,
          //   QueryParams: { ...getQueryParams() },
          // });
          toast.error(e);
        }
      }
    }
  };

  const handleCopy = () => {
    if (priceDetailsLinkRef) {
      priceDetailsLinkRef?.current.select();
    }
    if (navigator.clipboard) {
      toast.success('Copied to clipboard, You can share the link');
      navigator.clipboard.writeText(window.location.href);
    } else {
      //console.log('In http connection ~ 🎯');
    }
  };

  const handleClose = () => {
    setSearchText('');
    setOpenselectedCell(false);
  };

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', function () {
  //     if (document.hidden) {
  //       const input = document.getElementById('copy-box');
  //       input?.blur();
  //       window.getSelection().removeAllRanges();
  //     }
  //   });
  // }, []);

  useEffect(
    listenForOutsideClicks(
      listening,
      setListening,
      coinsDropdownRef,
      handleClose
    )
  );

  return (
    <div className='details__top'>
      <div
        ref={coinsDropdownRef}
        className={`details__company ${openselected ? 'active' : ''}`}
      >
        <div
          className='details__head'
          onClick={() => setOpenselectedCell((pre) => !pre)}
        >
          <div className='details__logo'>
            <img
              src={!selectedCell ? coinDetails?.logo : selectedCell?.logo}
              alt=''
            />
          </div>
          <div className='details__desc'>
            <div className='details__info'>
              {!selectedCell ? coinDetails?.name : selectedCell?.name}
            </div>
            <div
              className='details__currency'
              style={{ textTransform: 'uppercase' }}
            >
              {selectedCell?.symbol}
            </div>
          </div>
          <button className='price__details_dropdown__icon_container'>
            <Icon
              icon='arrow-down'
              className='icon price__details_dropdown_icon'
            />
          </button>
        </div>
        <div className='details__body'>
          <div className='f-center'>
            <input
              className='actions__input exchange_search__input active'
              type='search'
              value={searchText}
              placeholder='Search'
              onChange={(e) => setSearchText(e.target.value)}
            />
            {!searchText?.length && (
              <div style={{ position: 'absolute', right: 23, top: 25 }}>
                <Icon
                  icon='search'
                  className='icon'
                  size={15}
                  color='#808191'
                />
              </div>
            )}
          </div>

          {result.map((data, idx) => {
            if (data.symbol !== 'inr') {
              return (
                <button
                  key={idx}
                  className='details__item'
                  // onClick={() => setSelectedCell(data)}
                  onClick={() => {
                    history.push({
                      pathname: `/pricedetails/${data.symbol}`,
                      search: location.search,
                    });
                    setOpenselectedCell((pre) => !pre);
                    setSearchText('');
                  }}
                >
                  <div className='details__logo'>
                    <Image src={data.logo} alt='' />
                  </div>
                  <div className='details__desc'>
                    <div className='details__info'>{data.name}</div>
                    <div
                      className='details__currency'
                      style={{ textTransform: 'uppercase' }}
                    >
                      {data.symbol}
                    </div>
                  </div>
                </button>
              );
            }
          })}
        </div>
      </div>
      <div className='details__btns'>
        <button className='details__btn btn btn_border' onClick={shareCoins}>
          <Icon icon='share' className='details_icon icon' />
          <span className='btn__text'>Share</span>
        </button>
        {userInfo?.token && (
          <>
            {checkItInWatchList(selectedCell) ? (
              <button
                className='details__btn btn btn_border'
                onClick={() => handleWatchList(selectedCell)}
              >
                <Icon icon='star-fill' className='details_icon icon' />
                <span className='btn__text'>Remove</span>
              </button>
            ) : (
              <button
                className='details__btn btn btn_border'
                onClick={() => handleWatchList(selectedCell)}
              >
                <Icon icon='star' className='details_icon icon' />
                <span className='btn__text'>Add</span>
              </button>
            )}
          </>
        )}
      </div>

      <div className='share__popup' style={{ position: 'relative' }}>
        <Popup setState={setOpenPopUp} state={openPopUp}>
          <div className='share__bg'></div>
          <div className='share__container'>
            <div className='share__coins'>
              <img
                src={!selectedCell ? coinDetails?.logo : selectedCell?.logo}
              />
              <div className='h5'>Share it with your friends</div>
              <div className='h6'>
                The price of <span>{coinDetails?.name}</span> is{' '}
                {formatCurrency(
                  lastPrice(`${coinDetails?.symbol}${baseCurrency}`)
                )}
              </div>
            </div>
            <div className='share__social_icon referral_share'>
              <TelegramShareButton
                url={url}
                title={title}
                hashtag={hashtags}
                windowHeight={height}
                windowWidth={width}
              >
                <TelegramIcon size={50} round />
              </TelegramShareButton>
              <WhatsappShareButton
                url={url}
                title={title}
                windowHeight={height}
                windowWidth={width}
              >
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>
              <TwitterShareButton
                url={url}
                title={title}
                via='Brine'
                windowHeight={height}
                windowWidth={width}
              >
                <TwitterIcon size={50} round />
              </TwitterShareButton>
            </div>
            <div className='h6 share__copy_link'>Or copy link</div>
            <div style={{ marginBottom: 10, position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  background: 'transparent',
                  zIndex: 1000,
                  borderRadius: 20,
                }}
                onClick={() => handleCopy(`${window.location.href}`)}
              ></div>
              {/* <FormInput
                value={`${window.location.href}/?referral_code=${userReferralCode}`}
                label='Click here to Copy'
                id='copy-box'
                readOnly
              /> */}
              <div class='field__wrap' style={{ position: 'relative' }}>
                <div class='field__label' style={{ top: 22 }}>
                  Click here to Copy
                </div>
                <input
                  className='field__input js-field-input'
                  // id='copy-box'
                  readOnly={true}
                  value={`${window.location.href}`}
                  ref={priceDetailsLinkRef}
                  // value='localhost:3000/?referral_code=OGDQPF'
                />
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default DetailsTop;
