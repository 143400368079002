import { useEffect, useState } from 'react';
import ProfileFormInput from './profile-form-input/profile-form-input';
import ProfileImage from './profile-image/profile-image';
import { WithDrawAssets, currenyOptions } from '../../../../utils/data';
import {
  CustomButton,
  SkeletonContainer,
  LoadingBtn,
} from '../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import {
  addName,
  addUniqueUserName,
  logout,
  userDetails,
} from '../../../../actions/userAction';
import { toast } from 'react-toastify';
import { PROFILE_CLEANUP } from '../../../../constants/userConstant';
import {
  useAnalytics,
  useSegment,
  useWalletMethods,
} from '../../../../utils/hooks';
import { getQueryParams } from '../../../../utils/query-helper';
import { useWeb3React } from '@web3-react/core';
import { images } from '../../../../utils/image/img';

function ProfileTab() {
  const [currencyOption, setCurrencyOptions] = useState(currenyOptions[0]);
  const [withDrawValue, setWithDrawValue] = useState(WithDrawAssets[0]);
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const { analytics, sendPageEvent, sendTrackEvent } = useSegment();
  const { deactivate } = useWeb3React();
  const { userData, loading, error } = useSelector(
    (state) => state.userDetails
  );
  const {
    loading: userNameLoading,
    error: userNameError,
    response: userNameResponse,
  } = useSelector((state) => state.userAddName);
  const {
    loading: userUniqueNameLoading,
    error: userUniqueNameError,
    response: userUniqueNameResponse,
  } = useSelector((state) => state.userAddUniqueName);

  const dispatch = useDispatch();
  // const { logout: logoutWeb3Auth } = useWeb3Auth();
  const { walletDeactivate } = useWalletMethods();

  const handleRefreshUserData = () => {
    setTimeout(() => {
      dispatch(userDetails());
    }, 2000);
  };

  useEffect(() => {
    if (
      userData &&
      userData?.payload?.name !== name &&
      userData?.payload?.username !== userName &&
      userNameResponse &&
      userUniqueNameResponse
    ) {
      handleRefreshUserData();
      // analytics?.track('Clicked update profile success', {
      //   from: 'Settings tab',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      sendTrackEvent('profileUpdateSuccess');
      return toast.success('Updated Successfully', {
        autoClose: 2000,
        toastId: 'sxndcddjbc',
      });
    }

    if (
      userData &&
      userData?.payload?.name !== name &&
      userData?.payload?.username === userName &&
      userNameResponse
    ) {
      handleRefreshUserData();
      // analytics?.track('Clicked update profile success', {
      //   from: 'Settings tab',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      sendTrackEvent('profileUpdateSuccess');
      return toast.success('Updated Successfully', {
        autoClose: 2000,
        toastId: 'sxndcddjbc',
      });
    }

    if (
      userData &&
      userData?.payload?.name === name &&
      userData?.payload?.username !== userName &&
      userUniqueNameResponse
    ) {
      handleRefreshUserData();
      // analytics?.track('Clicked update profile success', {
      //   from: 'Settings tab',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      sendTrackEvent('profileUpdateSuccess');
      return toast.success('Updated Successfully', {
        autoClose: 2000,
        toastId: 'sxndcddjbc',
      });
    }

    if (userUniqueNameError || userNameError) {
      // analytics?.track('Clicked update profile failed', {
      //   from: 'Settings tab',
      //   uid: userInfo?.payload?.uid,
      //   reason: userUniqueNameError || userNameError,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      sendTrackEvent('profileUpdateFailure', {
        error: userUniqueNameError || userNameError,
      });
      return toast.error(userUniqueNameError || userNameError, {
        autoClose: 2000,
        toastId: '678',
      });
    }
  }, [
    userNameError,
    userUniqueNameError,
    userUniqueNameResponse,
    userNameResponse,
    userData,
  ]);

  const handleUpdateProfile = () => {
    // analytics?.track('Clicked update profile button', {
    //   from: 'Settings tab',
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });

    sendTrackEvent('profileUpdateClicked');

    dispatch({
      type: PROFILE_CLEANUP,
    });

    if (userData && userData.payload.name !== name) {
      dispatch(addName(name));
    }
    if (userData && userData.payload.username !== userName) {
      dispatch(addUniqueUserName(userName));
    }
  };

  useEffect(() => {
    if (userData && userData.payload) {
      setName(userData.payload.name);
      setUserName(userData.payload.username);
    }
    return () =>
      dispatch({
        type: PROFILE_CLEANUP,
      });
  }, []);

  return (
    <>
      <div className='popup__item js-tabs-item' style={{ display: 'block' }}>
        {loading && !error && !userData ? (
          <>
            <SkeletonContainer count={10} width={'50%'} height='100px' />
          </>
        ) : (
          <>
            <ProfileImage
              profileImg={
                userData?.payload?.img ? userData?.payload?.img : images.avator
              }
            />
            <div className='popup__fieldset'>
              <div className='popup__row'>
                <ProfileFormInput
                  label='Name'
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <ProfileFormInput
                  label='Username'
                  type='text'
                  onChange={(e) => setUserName(e.target.value)}
                  value={userName}
                />
              </div>
            </div>
            <div
              style={{ display: 'grid', placeItems: 'center', marginTop: 32 }}
            >
              {userNameLoading || userUniqueNameLoading ? (
                <LoadingBtn addClassName='card__btn' />
              ) : (
                <CustomButton
                  disabled={
                    userData?.payload?.name === name &&
                    userData?.payload?.username === userName
                  }
                  title='Update Profile'
                  onClick={handleUpdateProfile}
                />
              )}
            </div>
          </>
        )}
        <div className='popup__user popup__logout'>
          <div className='popup__line'>
            <div className='popup__btns'>
              <div className='popup__loading'>
                <button
                  className='popup__upload popup__label_mt22'
                  onClick={() => {
                    // if (userVia?.via === 'web3_auth') {
                    //   logoutWeb3Auth();
                    // }
                    walletDeactivate();
                    dispatch(logout(true));
                    // analytics?.track('Clicked logout button', {
                    //   from: 'Settings tab',
                    //   uid: userInfo?.payload?.uid,
                    //   QueryParams: { ...getQueryParams() },
                    //   ip: ip,
                    // });
                    sendTrackEvent('buttonClicked', {
                      title: 'Sign Out',
                      action: 'logout',
                    });
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <button className='popup__default' style={{ cursor: 'default' }}>
            You will be missed
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileTab;
