import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrderData,
  getPendingOrderData,
  getTradesData,
} from '../../../../actions/historyAction';
import { useSegment, useViewport } from '../../../../utils/hooks';
import {
  CustomButton,
  CustomSelect,
  ExchangeMarketTable,
  ExchangeOrderTable,
  Image,
  LoadingBtn,
  NotFoundedAnimation,
  CancelAllConfirmationPopup,
  ExchangeFilter,
  SkeletonContainer,
} from '../../../../components';
import _ from 'lodash';
import { cancelOrder } from '../../../../actions/exchangeAction';
import { useAnalytics } from '../../../../utils/hooks';
import { getQueryParams } from '../../../../utils/query-helper';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { webSocketUrl } from '../../../../utils/urls';
import moment from 'moment';
import { images } from '../../../../utils/image/img';
import { cancelAll } from '../../../../actions/assestsAction';
import { toast } from 'react-toastify';

function ExchangeMarketTransactions({
  selectedtab,
  selectedCell,
  tradeMode,
  fetchOrderTable,
  setFetchOrderTable,
}) {
  const data = [
    {
      label: 'All Orders',
      value: 'all_orders',
    },
    {
      label: 'Pending Orders',
      value: 'pending_orders',
    },
    {
      label: 'Trades',
      value: 'trades',
    },
  ];
  const [orderPageCount, setOrderPageCount] = useState(1);
  const [orderPendingPageCount, setOrderPendingPageCount] = useState(1);
  const [tradePageCount, setTradePageCount] = useState(1);
  const [allOrders, setAllOrder] = useState([]);
  const [trade, setTrade] = useState([]);
  const [pendingOrder, setPendingOrder] = useState([]);
  const [tempId, setTempId] = useState([]);
  const [btnDisable, setBtnDisbale] = useState(false);
  const [disableRefetch, setDisableRefetch] = useState(false);
  const { symbol } = useParams();
  const dispatch = useDispatch();
  const [selectedlabel, setselectedLabel] = useState(data[0]);
  const { width } = useViewport();
  const breakpoint = 766;
  const { analytics, sendTrackEvent } = useSegment();
  const [openCancelAllPopup, setOpenCancelAllPopup] = useState(null);
  const [enableMarketFilter, setEnableMarketFilter] = useState(true);
  // const {
  // orderData: {
  //   loading: orderLoading,
  //   response: orderResponse,
  //   error: orderError,
  // },
  // theme: { isdark },
  // tradeData: {
  //   loading: tradeLoading,
  //   response: tradeResponse,
  //   error: tradeError,
  // },
  // pendingOrderData: {
  //   loading: pendingOrderLoading,
  //   response: pendingOrderResponse,
  //   error: pendingOrderError,
  // },
  // createOrder: { loading, error, orderDetails },
  // cancelOrder: {
  //   response: cancelOrderResponse,
  //   loading: cancelOrderLoading,
  //   error: cancelOrderError,
  // },
  // userLogin: { userInfo },
  // userIpAddress: { ip },
  // notificationDetails: { notificationData },
  // } = useSelector((state) => state);
  const [orderItem, setOrderItem] = useState(null);
  const { notificationData } = useSelector(
    (state) => state.notificationDetails
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    response: cancelOrderResponse,
    loading: cancelOrderLoading,
    error: cancelOrderError,
  } = useSelector((state) => state.cancelOrder);
  const { loading, error, orderDetails } = useSelector(
    (state) => state.createOrder
  );
  const {
    loading: pendingOrderLoading,
    response: pendingOrderResponse,
    error: pendingOrderError,
  } = useSelector((state) => state.pendingOrderData);
  const { isdark } = useSelector((state) => state.theme);
  const {
    loading: tradeLoading,
    response: tradeResponse,
    error: tradeError,
  } = useSelector((state) => state.tradeData);
  const {
    loading: orderLoading,
    response: orderResponse,
    error: orderError,
  } = useSelector((state) => state.orderData);

  useEffect(() => {
    if (selectedlabel.value === 'all_orders') {
      setOrderItem(allOrders);
    } else if (selectedlabel.value === 'pending_orders') {
      setOrderItem(pendingOrder);
    }
  }, [allOrders, pendingOrder, selectedlabel]);

  const getData = () => {
    let market = !enableMarketFilter
      ? null
      : symbol?.replace('-', '').toLowerCase();

    if (selectedlabel.value === 'all_orders') {
      dispatch(getOrderData(orderPageCount, false, market));
    } else if (selectedlabel.value === 'pending_orders') {
      dispatch(getPendingOrderData(orderPendingPageCount, true, market));
    } else if (selectedlabel.value === 'trades') {
      dispatch(getTradesData(tradePageCount, market));
    }
  };

  const getFilterData = (state) => {
    let market = !state ? null : symbol?.replace('-', '').toLowerCase();
    if (selectedlabel.value === 'all_orders') {
      dispatch(getOrderData(1, false, market));
    } else if (selectedlabel.value === 'pending_orders') {
      dispatch(getPendingOrderData(1, true, market));
    } else if (selectedlabel.value === 'trades') {
      dispatch(getTradesData(1, market));
    }
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function removeItemFromList(arr, value) {
    const index = arr.indexOf(value);
    if (index > -1) {
      // only splice arr when item is found
      let res = arr.splice(index, 1); // 2nd parameter means remove one item only
      setTempId(res);
    }
  }

  // useState(() => {
  //   dispatch(getOrderData(1, false));
  //   dispatch(getTradesData(1));
  // }, [symbol]);

  useEffect(() => {
    // setOrderPageCount(1);
    // setOrderPendingPageCount(1);
    // setTradePageCount(1);
    // setAllOrder([]);
    // setPendingOrder([]);
    // setTrade([]);
    // setBtnDisbale(false);
    getData();
  }, [selectedlabel.value, symbol]);

  useEffect(() => {
    if (orderDetails || fetchOrderTable) {
      setOrderPageCount(1);
      setOrderPendingPageCount(1);
      setTradePageCount(1);
    }
  }, [orderDetails, fetchOrderTable]);

  const refetchOrderData = () => {
    if (disableRefetch) return '';
    cleanUpState();
    getData();
    setDisableRefetch(true);
    sendTrackEvent('buttonClicked', {
      market: symbol,
      title: 'Refresh Data',
      action: 'Refresh orders data',
    });
    setTimeout(() => {
      setDisableRefetch(false);
    }, 5000);
  };

  const handleLoadMore = () => {
    let market = !enableMarketFilter
      ? null
      : symbol?.replace('-', '').toLowerCase();
    // analytics?.track(`Load More clicked!`, {
    //   from: 'Exchange page',
    //   symbol: selectedCell.symbol,
    //   uid: userInfo?.payload?.uid,
    //   keyword: selectedlabel.label,
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });
    if (selectedlabel.value === 'all_orders') {
      dispatch(getOrderData(orderPageCount + 1, false, market));
      if (orderResponse && orderResponse?.payload.length < 10) {
        setOrderPageCount(0);
        setBtnDisbale(true);
      } else {
        setOrderPageCount((pre) => pre + 1);
      }
    } else if (selectedlabel.value === 'pending_orders') {
      dispatch(getPendingOrderData(orderPendingPageCount + 1, true, market));
      if (pendingOrderResponse && pendingOrderResponse?.payload?.length < 10) {
        setOrderPendingPageCount(0);
        setBtnDisbale(true);
      } else {
        setOrderPendingPageCount((pre) => pre + 1);
      }
    } else if (selectedlabel.value === 'trades') {
      dispatch(getTradesData(tradePageCount + 1, market));
      if (tradeResponse && tradeResponse?.payload.length < 10) {
        setTradePageCount(0);
        setBtnDisbale(true);
      } else {
        setTradePageCount((pre) => pre + 1);
      }
    }
  };

  const handleMergeItemForLoadMore = () => {
    if (
      (orderResponse?.payload ||
        tradeResponse?.payload ||
        pendingOrderResponse?.payload) &&
      !tradeLoading &&
      !orderLoading &&
      !pendingOrderLoading
    ) {
      if (selectedlabel.value === 'all_orders' && orderResponse?.payload) {
        const data = [...allOrders, ...orderResponse?.payload];
        const uniqData = _.uniqBy(data, 'id');
        setAllOrder(uniqData);
      } else if (
        selectedlabel.value === 'pending_orders' &&
        pendingOrderResponse
      ) {
        const data = [...pendingOrder, ...pendingOrderResponse?.payload];
        const uniqData = _.uniqBy(data, 'id');
        setPendingOrder(uniqData);
      } else if (selectedlabel.value === 'trades' && tradeResponse?.payload) {
        const data = [...trade, ...tradeResponse?.payload];
        const uniqData = _.uniqBy(data, 'id');
        setTrade(uniqData);
      }
    }
  };

  const handleCancelOrder = (id) => {
    // if (window.confirm('Are you sure to cancel this order')) {
    // analytics?.track(`Order cancel button clicked!`, {
    //   from: 'Exchange page',
    //   symbol: selectedCell.symbol,
    //   uid: userInfo?.payload?.uid,
    //   QueryParams: { ...getQueryParams() },
    //   ip: ip,
    // });
    dispatch(cancelOrder(id));
    // }
  };

  const cleanUpState = () => {
    setOrderPageCount(1);
    setOrderPendingPageCount(1);
    setTradePageCount(1);

    setAllOrder([]);
    setPendingOrder([]);
    setTrade([]);
    setBtnDisbale(false);
  };

  const handleFilter = (state) => {
    cleanUpState();
    getFilterData(state);
  };

  const clearNotificationState = () => {
    dispatch({
      type: 'GET_NOTIFICATION_DETAILS_WS_CLEANUP',
    });
  };

  useEffect(() => {
    handleMergeItemForLoadMore();
  }, [
    dispatch,
    orderResponse,
    tradeResponse,
    pendingOrderResponse,
    pendingOrderLoading,
    tradeLoading,
    orderLoading,
    // cancelOrderResponse,
  ]);

  useEffect(() => {
    return () => {
      cleanUpState();
    };
  }, [selectedlabel.value, symbol]);

  useEffect(() => {
    if (cancelOrderResponse) {
      // const data = pendingOrder.filter(
      //   (x) => x.id !== cancelOrderResponse?.payload?.id
      // );
      // setPendingOrder(data);
      let ids = tempId;
      ids.push(cancelOrderResponse?.payload?.id);
      let unqiue = ids.filter(onlyUnique);
      setTempId(unqiue);

      // setPendingOrder((prevPendingOrder) =>
      //   prevPendingOrder.filter(
      //     (order) => order.id !== cancelOrderResponse?.payload?.id
      //   )
      // );

      // analytics?.track(`Order cancelled successfully!`, {
      //   from: 'Exchange page',
      //   symbol: selectedCell.symbol,
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      // setCancelOrderIds([...cancelOrderIds, cancelOrderResponse?.payload?.id]);
      // setPendingOrder(data);
      // setTimeout(() => {
      //   cleanUpState();
      //   getData();
      //   setTempId('');
      // }, 2000);
    }
  }, [cancelOrderResponse, symbol]);

  // useEffect(() => {
  //   analytics?.track(`Clicked filter section!`, {
  //     from: 'Exchange page',
  //     symbol: selectedCell.symbol,
  //     uid: userInfo?.payload?.uid,
  //     QueryParams: { ...getQueryParams() },
  //     keyword: selectedlabel.label,
  //     ip: ip,
  //   });
  // }, [selectedlabel.value]);

  // const establishWsConnection = () => {
  //   let ws = new WebSocket(
  //     `${webSocketUrl}/private?auth_header=${userInfo?.token?.access}`
  //   );
  //   setWs(ws);
  // };

  const sortData = (arr) => {
    let data = arr.sort((a, b) => {
      return (
        new Date(
          a?.is_ws_data
            ? moment.unix(a.updated_at).format()
            : moment(a.updated_at).format()
        ) -
        new Date(
          b?.is_ws_data
            ? moment.unix(b.updated_at).format()
            : moment(b.updated_at).format()
        )
      );
    });
    return data?.reverse();
  };

  useEffect(() => {
    if (analytics && selectedlabel) {
      sendTrackEvent('tabSelected', {
        tab: selectedlabel?.label,
        market: symbol,
      });
    }
  }, [analytics, selectedlabel]);

  const updateTradeOrderData = () => {
    // notificationData
    let res = notificationData;

    // if (
    //   res?.order?.market === symbol?.replace('-', '') ||
    //   res?.trade?.market === symbol?.replace('-', '')
    // ) {
    if (res?.order) {
      if (
        enableMarketFilter &&
        res?.order?.market !== symbol?.replace('-', '')
      ) {
        return;
      }
      if (
        res?.order?.state === 'done' &&
        selectedlabel.value === 'pending_orders'
      ) {
        let filteredExcutedOrder = pendingOrder?.filter(
          (order) => order?.id !== res?.order?.id
        );
        setPendingOrder(filteredExcutedOrder);
      }
      if (selectedlabel.value === 'all_orders') {
        let tempData = allOrders;
        res.order['is_ws_data'] = true;
        tempData?.unshift(res?.order);
        const uniqData = _.uniqBy(tempData, 'id');
        let sortedData = sortData(uniqData);
        setAllOrder(sortedData);
      } else if (
        selectedlabel.value === 'pending_orders' &&
        res?.order?.state !== 'done' &&
        res?.order?.state !== 'cancel' &&
        res?.order?.state === 'wait' &&
        res?.order?.ord_type === 'limit'
      ) {
        let tempData = pendingOrder;
        tempData?.unshift(res?.order);
        const uniqData = _.uniqBy(tempData, 'id');
        let sortedData = sortData(uniqData);
        setPendingOrder(sortedData);
        // getData();
      } else if (
        res?.order?.state === 'cancel' &&
        res?.order?.ord_type === 'limit' &&
        res?.order?.state !== 'wait' &&
        selectedlabel.value === 'pending_orders'
      ) {
        if (tempId.includes(res?.order?.id)) {
          removeItemFromList(tempId, res?.order?.id);
        }
        let remainingItem = pendingOrder.filter((i) => {
          return i.id !== res?.order?.id;
        });
        let sortedData = sortData(remainingItem);
        setPendingOrder(sortedData);
      }
      clearNotificationState();
    } else if (res?.trade) {
      if (
        enableMarketFilter &&
        res?.trade?.market !== symbol?.replace('-', '')
      ) {
        return;
      }
      if (selectedlabel.value === 'trades') {
        let tempData = trade;
        res.trade['is_ws_data'] = true;
        tempData?.unshift(res?.trade);
        const uniqData = _.uniqBy(tempData, 'id');
        let sortedData = sortData(uniqData);
        setTrade(sortedData);
      }
      clearNotificationState();
    }
  };

  // const connect = () => {
  //   if (ws) {
  //     ws.onopen = function () {
  //       //Subscribe to the channel
  //       ws.send(
  //         JSON.stringify({ event: 'subscribe', streams: ['trade', 'order'] })
  //       );
  //     };

  //     ws.onmessage = (msg) => {
  //       let res = JSON.parse(msg.data);
  //       if (
  //          res?.order?.market === symbol?.replace('-', '') ||
  //         res?.trade?.market === symbol?.replace('-', '')
  //       ) {
  //         if (res?.order) {
  //           if (selectedlabel.value === 'all_orders') {
  //             let tempData = allOrders;
  //             res.order['is_ws_data'] = true;
  //             tempData?.unshift(res?.order);
  //             const uniqData = _.uniqBy(tempData, 'id');
  //             setAllOrder(uniqData);
  //           } else if (
  //             selectedlabel.value === 'pending_orders' &&
  //             res?.order?.state !== 'done' &&
  //             res?.order?.state !== 'cancel' &&
  //             res?.order?.state === 'wait' &&
  //             res?.order?.ord_type === 'limit'
  //           ) {
  //             let tempData = pendingOrder;
  //             tempData?.unshift(res?.order);
  //             const uniqData = _.uniqBy(tempData, 'id');
  //             setPendingOrder(uniqData);
  //             // getData();
  //           } else if (
  //             res?.order?.state === 'cancel' &&
  //             res?.order?.ord_type === 'limit' &&
  //             res?.order?.state !== 'wait' &&
  //             selectedlabel.value === 'pending_orders'
  //           ) {
  //             if (tempId.includes(res?.order?.id)) {
  //               removeItemFromList(tempId, res?.order?.id);
  //             }
  //             let remainingItem = pendingOrder.filter((i) => {
  //               return i.id !== res?.order?.id;
  //             });
  //             setPendingOrder(remainingItem);
  //           }
  //         } else if (res?.trade) {
  //           if (selectedlabel.value === 'trades') {
  //             let tempData = trade;
  //             res.trade['is_ws_data'] = true;
  //             tempData?.unshift(res?.trade);
  //             const uniqData = _.uniqBy(tempData, 'id');
  //             setTrade(uniqData);
  //           }
  //         }
  //       }
  //     };

  //     ws.onerror = function (err) {
  //       console.log(err);
  //     };
  //   }
  // };

  // useEffect(() => {
  //   connect();
  // }, [pendingOrder, allOrders, trade, ws, selectedlabel, tempId]);

  // useEffect(() => {
  //   if (userInfo) {
  //     establishWsConnection();
  //   }
  //   return () => {
  //     if (ws) {
  //       ws.close();
  //     }
  //   };
  // }, [userInfo]);

  useEffect(() => {
    if (notificationData) {
      updateTradeOrderData();
    }
  }, [notificationData, selectedlabel]);

  const noData = {
    all_orders: 'No orders found',
    pending_orders: 'No pending orders found',
    trades: 'No trades found',
  };

  const handleCancelAllOrder = async (all = true) => {
    try {
      const res = await cancelAll(
        userInfo?.token?.access,
        all ? null : symbol?.replace('-', '')
      );
      toast.success(res.message);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {userInfo?.token ? (
        <>
          {width > 790 && tradeMode && (
            <ExchangeOrderTable
              selectedCell={selectedCell}
              setTrade={setTrade}
              tradeMode={tradeMode}
            />
          )}
          <div
            className='market__transactions'
            style={{
              display:
                selectedtab === 'market__transactions' && width < breakpoint
                  ? 'block'
                  : 'none',
            }}
          >
            <div className='market__tabs_wrapper'>
              <div
                className='market__top js-tabs'
                style={{ position: 'relative', width: '100%' }}
              >
                <div className='market__menu mobile-hide'>
                  {data.map((item, idx) => (
                    <button
                      className={`market__link js-tabs-link ${
                        selectedlabel.label === item.label ? 'active' : ''
                      } `}
                      key={idx}
                      onClick={() => setselectedLabel(item)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                <div className='market__select mobile-show'>
                  <CustomSelect
                    defaultValue={data[0]}
                    options={data}
                    onChange={(data) => setselectedLabel(data)}
                  />
                </div>
              </div>
              <div
                className={`refresh__balance ${
                  disableRefetch ? 'disable_btn' : ''
                } market_tab__refresh`}
              >
                <div className='market__refresh_wrapper'>
                  {/* {selectedlabel.value === 'pending_orders' &&
                  pendingOrder.length ? (
                    <CustomButton
                      addClassName='btn_red'
                      title={'Cancel all'}
                      onClick={() => setOpenCancelAllPopup((prev) => !prev)}
                      style={{ marginRight: 20 }}
                    />
                  ) : null} */}
                  {symbol && (
                    <ExchangeFilter
                      enableMarketFilter={enableMarketFilter}
                      setEnableMarketFilter={setEnableMarketFilter}
                      market={symbol?.replace('-', '')?.toUpperCase()}
                      handleCB={handleFilter}
                    />
                  )}
                  <div onClick={refetchOrderData}>
                    <i
                      className='fas fa-redo-alt'
                      data-tip={'Refresh after 5s'}
                      data-for='refresh-table'
                    ></i>
                  </div>
                  {disableRefetch && (
                    <ReactTooltip
                      place='bottom'
                      id='refresh-table'
                      type={isdark ? 'light' : 'dark'}
                      className='tooltip_styles'
                    />
                  )}
                </div>
              </div>
            </div>

            {/* {(orderLoading || tradeLoading || pendingOrderLoading) &&
            orderItem?.length === 0 ? ( */}
            {((orderLoading || pendingOrderLoading) &&
              orderItem?.length === 0) ||
            (tradeLoading && trade?.length === 0) ? (
              [1, 2, 3, 4, 5, 6].map((item, idx) => (
                <SkeletonContainer
                  className='history__pro_trade_table'
                  key={idx}
                  width={'100%'}
                  height='40px'
                  style={{ marginBottom: 1 }}
                  containerClassName='history__pro_trade_table'
                />
              ))
            ) : (
              <>
                <ExchangeMarketTable
                  allOrders={allOrders}
                  trade={trade}
                  pendingOrder={pendingOrder}
                  handleCancelOrder={handleCancelOrder}
                  selectedlabel={selectedlabel}
                  orderPendingPageCount={orderPendingPageCount}
                  tradeMode={tradeMode}
                  btnDisable={btnDisable}
                  tempId={tempId}
                  handleLoadMore={handleLoadMore}
                  orderItem={orderItem}
                />
                <div
                  style={{ display: 'grid', placeItems: 'center', padding: 20 }}
                >
                  {!orderLoading &&
                    !tradeLoading &&
                    ((selectedlabel.value === 'pending_orders' &&
                      pendingOrder?.length === 0) ||
                      (selectedlabel.value === 'all_orders' &&
                        allOrders?.length === 0) ||
                      (selectedlabel.value === 'trades' &&
                        trade?.length === 0)) && (
                      <>
                        {tradeMode ? (
                          <p
                            style={{
                              fontFamily: 'Poppins',
                              margin: 10,
                            }}
                          >
                            {noData[selectedlabel?.value]}
                          </p>
                        ) : (
                          <NotFoundedAnimation
                            text={`${noData[selectedlabel?.value]}`}
                            size='sm'
                          />
                        )}
                      </>
                    )}
                  {!tradeMode && (
                    <>
                      {tradeLoading || orderLoading ? (
                        <>
                          <LoadingBtn />
                        </>
                      ) : (
                        <>
                          {!orderLoading &&
                            !tradeLoading &&
                            ((selectedlabel.value === 'pending_orders' &&
                              pendingOrder?.length >= 10) ||
                              (selectedlabel.value === 'all_orders' &&
                                allOrders?.length >= 10) ||
                              (selectedlabel.value === 'trades' &&
                                trade?.length >= 10)) && (
                              <>
                                {!btnDisable && (
                                  <CustomButton
                                    title='Load More'
                                    onClick={handleLoadMore}
                                  />
                                )}
                              </>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            <div className='share__popup network__error'>
              <CancelAllConfirmationPopup
                symbol={symbol?.replace('-', '/')}
                openCancelAllPopup={openCancelAllPopup}
                setOpenCancelAllPopup={setOpenCancelAllPopup}
                handleCancelAllOrder={() => handleCancelAllOrder(true)}
                handleCancelSpecficOrder={() => handleCancelAllOrder(false)}
              />
            </div>
          </div>
        </>
      ) : (
        <div className='market__transactions'>
          <StaticContentAbout tradeMode={tradeMode} />
        </div>
      )}
    </>
  );
}

export default ExchangeMarketTransactions;

function StaticContentAbout({ tradeMode }) {
  const data = [
    {
      img: images.staticImg1,
      title: 'Gasless',
      description:
        'You need to pay no gas fee for your trades, but only a transaction fee of 0.04%',
    },
    {
      img: images.staticImg2,
      title: 'Instant',
      description:
        'Brine’s matching engine can scale upto 600,000 TPS, promising instant execution of orders.',
    },
    {
      img: images.staticImg3,
      title: 'Self-Custody',
      description:
        'Hold your assets the Trustless way on Brine and unleash the true power of DeFi.',
    },
    {
      img: images.staticImg4,
      title: 'Rebates',
      description:
        "You earn up to 40% of your referees' trading fees and they get 10% too!",
    },
  ];
  return (
    <div className='static__content_container'>
      {data.map((item) => (
        <div
          className={`static__content_item ${
            tradeMode ? 'border-content' : ''
          }`}
          key={item.title}
        >
          <div className='title-container'>
            <Image src={item.img} />
            <div className='h6'>{item.title}</div>
          </div>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  );
}
