import './styles/App.css';
import Routes from './routes';
import React from 'react';
import { Buffer } from 'buffer';

window.Buffer = window.Buffer || require('buffer').Buffer;

function App() {
  return <Routes />;
}

export default App;
