import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadUserProfileImage } from '../../../../../actions/userAction';
import { userDetails } from '../../../../../actions/userAction';
import { Image } from '../../../../../components';
import { truncate } from '../../../../../utils/string-methods';
import { PurpleCopyIcon } from '../../../../../utils/image/img';

function ProfileImage({ profileImg }) {
  const [userImage, setUserImage] = useState(null);
  // const {
  //   userLogin: { userInfo },
  //   userDetails: { userData, loading, error },
  //   // userVia: { state: userViaState },
  // } = useSelector((state) => state);

  const { state: userViaState } = useSelector((state) => state.userVia);
  const { userData, loading, error } = useSelector(
    (state) => state.userDetails
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();

  const handleChangeProfilePicture = async (e) => {
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    const filePath = e.target.value;

    if (!allowedExtensions.exec(filePath)) {
      return toast.error(
        'Invalid format; please upload jpg, jpeg or png files only'
      );
    }

    if (e?.target?.files[0].size > 1000000) {
      return toast.error('Please upload images smaller than 1 MB');
    }

    try {
      toast.info('uploading', { toastId: 2000 });
      const { files } = e.target;
      setUserImage(URL.createObjectURL(e.target.files[0]));
      if (files.length) {
        const data = await uploadUserProfileImage(
          userInfo.token.access,
          files[0]
        );
        toast.success(`Successfully uploaded!`);
        dispatch(userDetails());
        //console.log(data);
      }
    } catch (error) {
      toast.error('Something went wrong; please try again later');
    }
  };

  const handleCopy = () => {
    if (navigator.clipboard) {
      // analytics?.track('clicked copy', {
      //   from: 'Referral Page',
      //   purpose: 'Referral Code',
      //   uid: userInfo?.payload?.uid,
      //   QueryParams: { ...getQueryParams() },
      //   ip: ip,
      // });
      toast.success('Address copied 👍🏻', { toastId: 'copy' });
      navigator.clipboard.writeText(userViaState?.address);
    } else {
      //console.log('In http connection ~ 🎯');
    }
  };

  return (
    <div className='popup__user'>
      <div className='popup__label popup__label_mb32'>Your Avatar</div>
      <div className='popup__line' style={{ alignItems: 'center' }}>
        <div className='popup__ava'>
          {userData?.payload?.img ? (
            <Image
              className='popup__pic'
              src={userData?.payload?.img.file}
              alt=''
            />
          ) : (
            <>
              {userImage ? (
                <Image className='popup__pic' src={userImage} alt='' />
              ) : (
                <Image className='popup__pic' src={profileImg} alt='' />
              )}
            </>
          )}
        </div>
        <div className='popup__details'>
          <div className='popup__btns' style={{ marginBottom: 5 }}>
            {/* <div className='popup__loading'>
              <input
                className='popup__file'
                type='file'
                onChange={(e) => handleChangeProfilePicture(e)}
              />
              <button
                className='popup__upload'
                disabled
                style={{ cursor: 'not-allowed' }}
              >
                Upload New
              </button>
            </div> */}
            <div>
              <div className='h6' style={{ fontSize: 16 }}>
                {truncate(userViaState?.address, 20)}
              </div>
            </div>
            {/* <button className="popup__btn btn btn_gray">Delete Avatar</button> */}
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleCopy}
          >
            <div style={{ marginRight: 5 }}>
              <PurpleCopyIcon />
            </div>
            <div className='popup__default' style={{ marginTop: 0 }}>
              Copy Address
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileImage;
