import React from 'react';
import { useSelector } from 'react-redux';
import { images } from '../../../utils/image/img';
import Image from '../img/image';

function FallbackLoader() {
  const { isdark } = useSelector((state) => state.theme);

  return (
    <div className='fallback_loader__container'>
      <div className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <Image src={isdark ? images.logoDark : images.logoWhite} />
      </div>
    </div>
  );
}

export default FallbackLoader;
