import React, { useEffect, useState } from 'react';

const Collapsible = ({
  children,
  openLabel,
  closeLabel,
  subState,
  subSetState,
  cb = null,
  initalState = false,
}) => {
  const [open, setOpen] = useState(initalState);

  const toggle = () => {
    setOpen(!open);
    if (subSetState) {
      subSetState((prev) => !prev);
    }
    if (cb) {
      cb();
    }
  };

  useEffect(() => {
    if (subState) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [subState]);

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={toggle}>
        {open ? openLabel : closeLabel ? closeLabel : null}
      </div>
      <div
        style={{
          opacity: !open ? '0' : '1',
          transition: 'all .2s',
          visibility: !open ? 'hidden' : 'visible',
          height: !open ? 0 : 'fit-content',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
