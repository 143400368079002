import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  CustomSelect,
  CustomButton,
  Tags,
  ActivitiesTags,
} from '../../../components';
import {
  useViewport,
  listenForOutsideClicks,
  useSegment,
} from './../../../utils/hooks';

function FilterIndication({}) {
  return (
    <div
      style={{
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#6c5dd3',
        position: 'absolute',
        top: -3,
        right: 0,
      }}
    ></div>
  );
}

function FilterDropdown({
  setDepositOpen,
  depositOpen,
  depositDropdownRef,
  checkedValue,
  setCheckedValue,
  marketPair,
  setMarketPair,
  pendingOrderFilter,
  setPendingOrderFilter,
  orderTypeFilter,
  setOrderTypeFilter,
  initialState,
  resetFilter,
  setCurrentMarketPair,
  showResult,
  selectedFilter,
  currentMarketPair,
  styles,
  refetchData,
  depositNetworkFilter,
  setDepositNetworkFilter,
}) {
  const { width } = useViewport();
  const historyFilterDropDown = useRef();

  // const {
  //   getBanner: { bannerDetails },
  //   theme: { isdark },
  // } = useSelector((state) => state);

  const { bannerDetails } = useSelector((state) => state.getBanner);
  const { isdark } = useSelector((state) => state.theme);

  const handleOnChange = (e) => {
    if (!e.target.checked) {
      setCurrentMarketPair(null);
    } else {
      setCurrentMarketPair(e.target.name?.replace('/', ''));
    }
    setCheckedValue({ ...initialState, [e.target.name]: e.target.checked });
  };

  const handleDepositFilterChange = (e) => {
    if (!e.target.checked) {
      setDepositNetworkFilter(null);
    } else {
      setDepositNetworkFilter(e.target.name?.replace('/', ''));
    }
    setCheckedValue({ ...initialState, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setMarketPair(bannerDetails?.payload?.markets_with_slash);
    }
    return () => {
      mounted = false;
    };
  }, [bannerDetails]);

  useEffect(() => {
    if (historyFilterDropDown.current) {
      historyFilterDropDown.current.scrollTop =
        historyFilterDropDown.current.scrollHeight;
    }
  }, [checkedValue]);

  const network =
    selectedFilter?.label === 'Deposits'
      ? ['Polygon', 'Ethereum', 'Starknet', 'Scroll', 'Optimism']
      : ['Polygon', 'Ethereum', 'Starknet'];

  return (
    <div
      ref={depositDropdownRef}
      style={{
        top: '0px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={styles.dropDownContainer}>
        <div
          className='wallet_deposit_container'
          style={{ justifyContent: 'center' }}
        >
          <button
            className='card__btn btn btn_border'
            onClick={() => setDepositOpen((prev) => !prev)}
            style={styles.depositBtn}
          >
            <span className='btn__text' style={styles.depositInnerContainer}>
              <span style={{ position: 'relative' }}>
                {(currentMarketPair ||
                  orderTypeFilter ||
                  pendingOrderFilter ||
                  depositNetworkFilter) && <FilterIndication />}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                  style={{
                    width: 17,
                    height: 17,
                    transform: 'rotate(90deg)',
                    marginRight: 4,
                  }}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4'
                  />
                </svg>
              </span>
              Filter
            </span>
          </button>
        </div>
      </div>
      <div>
        <div
          className={` exchange__company ${
            depositOpen ? 'active' : ''
          } wallet_dropdown`}
          style={{ position: 'relative' }}
        >
          {selectedFilter?.label === 'Deposits' ||
          selectedFilter?.value === 'fast-withdraws' ? (
            <div
              className='exchange__body wallet_body wallet_dropdown'
              style={{ maxHeight: '300px' }}
              ref={historyFilterDropDown}
            >
              <h4
                className='actions__input'
                style={{
                  padding: '10px 10px 0px 16px',
                  height: 'fit-content',
                  fontFamily: 'Poppins',
                  background: 'transparent',
                }}
              >
                Networks
              </h4>
              <div className='wallet_coin__dropdown history_filter_dropdown'>
                {network?.map((item, idx) => (
                  <label
                    className='checkbox exchange__item history_filter_item'
                    key={idx}
                  >
                    <input
                      className='checkbox__input'
                      name={item}
                      type='checkbox'
                      onChange={(e) => handleDepositFilterChange(e)}
                      checked={
                        checkedValue && Object.entries(checkedValue).length
                          ? checkedValue[item]
                            ? true
                            : false
                          : false
                      }
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <span
                        className='checkbox__text exchange__currency'
                        style={{ fontSize: 11, color: '#808191' }}
                      >
                        {item?.toUpperCase()}
                      </span>
                    </span>
                  </label>
                ))}
              </div>
              {selectedFilter?.label === 'Orders' && (
                <>
                  <h4
                    className='actions__input'
                    style={{
                      padding: '10px 10px 0px 16px',
                      height: 'fit-content',
                      fontFamily: 'Poppins',
                      background: 'transparent',
                    }}
                  >
                    Order Type
                  </h4>
                  <label
                    className='checkbox exchange__item history_filter_item'
                    style={{ marginTop: 5 }}
                  >
                    <input
                      className='checkbox__input'
                      type='checkbox'
                      onChange={(e) => {
                        orderTypeFilter == 'market'
                          ? setOrderTypeFilter(null)
                          : setOrderTypeFilter('market');
                      }}
                      checked={orderTypeFilter == 'market' ? true : false}
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <span
                        className='checkbox__text exchange__currency'
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: 11,
                          color: '#808191',
                        }}
                      >
                        Market
                      </span>
                    </span>
                  </label>
                  <label className='checkbox exchange__item history_filter_item'>
                    <input
                      className='checkbox__input'
                      name='Pending Orders'
                      type='checkbox'
                      onChange={(e) => {
                        orderTypeFilter == 'limit'
                          ? setOrderTypeFilter(null)
                          : setOrderTypeFilter('limit');
                      }}
                      checked={orderTypeFilter == 'limit' ? true : false}
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <span
                        className='checkbox__text exchange__currency'
                        style={{
                          fontFamily: 'Poppins',
                          marginTop: 5,
                          fontSize: 11,
                          color: '#808191',
                        }}
                      >
                        Limit
                      </span>
                    </span>
                  </label>
                  <label
                    className='checkbox exchange__item history_filter_item'
                    style={{ marginTop: 5 }}
                  >
                    <input
                      className='checkbox__input'
                      name='Pending Orders'
                      type='checkbox'
                      onChange={(e) => setPendingOrderFilter(e.target.checked)}
                      checked={pendingOrderFilter ? true : false}
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <h6
                        className='checkbox__text exchange__currency'
                        style={{ fontFamily: 'Poppins' }}
                      >
                        Pending Orders
                      </h6>
                    </span>
                  </label>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 12,
                  flexWrap: 'wrap',
                  // marginBottom: 5,
                  // position: 'sticky',
                  // bottom: 0,
                  padding: '5px 0px',
                  backgroundColor: isdark ? '#191b20' : '#ffffff',
                }}
              >
                <CustomButton
                  title='Apply filter'
                  addClassName='btn-sm'
                  onClick={() => {
                    showResult();
                    setDepositOpen(false);
                  }}
                  style={{ height: '37px', minWidth: 44, borderRadius: 7 }}
                />
                <button
                  className='card__btn btn btn_border'
                  style={{
                    height: '37px',
                    minWidth: width > 700 ? 44 : '78%',
                    marginLeft: width > 700 ? 12 : 0,
                    marginTop: width < 700 ? 10 : 0,
                    borderRadius: 7,
                  }}
                  onClick={() => {
                    resetFilter();
                    refetchData();
                    setDepositOpen(false);
                  }}
                >
                  <span className='btn__text'>Reset</span>
                </button>
              </div>
            </div>
          ) : (
            <div
              className='exchange__body wallet_body wallet_dropdown'
              style={{ maxHeight: '300px' }}
              ref={historyFilterDropDown}
            >
              <h4
                className='actions__input'
                style={{
                  padding: '10px 10px 0px 16px',
                  height: 'fit-content',
                  fontFamily: 'Poppins',
                  background: 'transparent',
                }}
              >
                Market Pair
              </h4>
              <div className='wallet_coin__dropdown history_filter_dropdown'>
                {marketPair?.map((item, idx) => (
                  <label
                    className='checkbox exchange__item history_filter_item'
                    key={idx}
                  >
                    <input
                      className='checkbox__input'
                      name={item}
                      type='checkbox'
                      onChange={(e) => handleOnChange(e)}
                      checked={
                        checkedValue && Object.entries(checkedValue).length
                          ? checkedValue[item]
                            ? true
                            : false
                          : false
                      }
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <span
                        className='checkbox__text exchange__currency'
                        style={{ fontSize: 11, color: '#808191' }}
                      >
                        {item?.toUpperCase()}
                      </span>
                    </span>
                  </label>
                ))}
              </div>
              {selectedFilter?.label === 'Orders' && (
                <>
                  <h4
                    className='actions__input'
                    style={{
                      padding: '10px 10px 0px 16px',
                      height: 'fit-content',
                      fontFamily: 'Poppins',
                      background: 'transparent',
                    }}
                  >
                    Order Type
                  </h4>
                  <label
                    className='checkbox exchange__item history_filter_item'
                    style={{ marginTop: 5 }}
                  >
                    <input
                      className='checkbox__input'
                      type='checkbox'
                      onChange={(e) => {
                        orderTypeFilter == 'market'
                          ? setOrderTypeFilter(null)
                          : setOrderTypeFilter('market');
                      }}
                      checked={orderTypeFilter == 'market' ? true : false}
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <span
                        className='checkbox__text exchange__currency'
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: 11,
                          color: '#808191',
                        }}
                      >
                        Market
                      </span>
                    </span>
                  </label>
                  <label className='checkbox exchange__item history_filter_item'>
                    <input
                      className='checkbox__input'
                      name='Pending Orders'
                      type='checkbox'
                      onChange={(e) => {
                        orderTypeFilter == 'limit'
                          ? setOrderTypeFilter(null)
                          : setOrderTypeFilter('limit');
                      }}
                      checked={orderTypeFilter == 'limit' ? true : false}
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <span
                        className='checkbox__text exchange__currency'
                        style={{
                          fontFamily: 'Poppins',
                          marginTop: 5,
                          fontSize: 11,
                          color: '#808191',
                        }}
                      >
                        Limit
                      </span>
                    </span>
                  </label>
                  <label
                    className='checkbox exchange__item history_filter_item'
                    style={{ marginTop: 5 }}
                  >
                    <input
                      className='checkbox__input'
                      name='Pending Orders'
                      type='checkbox'
                      onChange={(e) => setPendingOrderFilter(e.target.checked)}
                      checked={pendingOrderFilter ? true : false}
                    />
                    <span className='checkbox__in'>
                      <span className='checkbox__tick'></span>
                      <h6
                        className='checkbox__text exchange__currency'
                        style={{ fontFamily: 'Poppins' }}
                      >
                        Pending Orders
                      </h6>
                    </span>
                  </label>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 12,
                  flexWrap: 'wrap',
                  // marginBottom: 5,
                  // position: 'sticky',
                  // bottom: 0,
                  padding: '5px 0px',
                  backgroundColor: isdark ? '#191b20' : '#ffffff',
                }}
              >
                <CustomButton
                  title='Apply filter'
                  addClassName='btn-sm'
                  onClick={() => {
                    showResult();
                    setDepositOpen(false);
                  }}
                  style={{ height: '37px', minWidth: 44, borderRadius: 7 }}
                />
                <button
                  className='card__btn btn btn_border'
                  style={{
                    height: '37px',
                    minWidth: width > 700 ? 44 : '78%',
                    marginLeft: width > 700 ? 12 : 0,
                    marginTop: width < 700 ? 10 : 0,
                    borderRadius: 7,
                  }}
                  onClick={() => {
                    resetFilter();
                    refetchData();
                    setDepositOpen(false);
                  }}
                >
                  <span className='btn__text'>Reset</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ActivitiesHeader({
  openSideBar,
  selectedFilter,
  setSelectedFilter,
  sortingOptions,
  filterOptions,
  setOpenSideBar,
  refreshHistoryData,
  setRefreshHistoryData,
  refetchOrderData,
  disableRefetch,
  filterByOptions,
  setFilterByOptions,
  checkedValue,
  setCheckedValue,
  marketPair,
  setMarketPair,
  pendingOrderFilter,
  setPendingOrderFilter,
  orderTypeFilter,
  setOrderTypeFilter,
  initialState,
  currentMarketPair,
  setCurrentMarketPair,
  showResult,
  resetFilter,
  handleRemoveExtraQueryParams,
  setOpenWithdrawalInstruction,
  openWithdrawalInstruction,
  refetchData,
  setDepositNetworkFilter,
  depositNetworkFilter,
}) {
  // const {
  //   theme: { isdark },
  //   getBanner: { bannerDetails },
  // } = useSelector((state) => state);
  const { bannerDetails } = useSelector((state) => state.getBanner);
  const { isdark } = useSelector((state) => state.theme);
  const [depositOpen, setDepositOpen] = useState(false);
  const depositDropdownRef = useRef(null);
  const [listening, setListening] = useState(false);
  const { width } = useViewport();

  const handleClose = () => {
    setDepositOpen(false);
  };

  useEffect(
    listenForOutsideClicks(
      listening,
      setListening,
      depositDropdownRef,
      handleClose
    )
  );

  const styles = {
    dropDownContainer: {
      width: 310,
      position: 'relative',
      marginTop: 5,
    },
    depositBtn: {
      right: '120px',
      top: '2px',
      minWidth: width > 700 ? '105px' : '160px',
    },
    depositInnerContainer: {
      fill: '#fff',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 3,
    },
    dropDownItem: {
      width: '100%',
    },
    dropDownItemInnerContainer: {
      display: 'flex',
      placeItems: 'flex-start',
    },
    dropDownItemInnerContainerInfo: {
      textTransform: 'uppercase',
      marginTop: '3px',
      fontSize: 11,
      fontFamily: 'Poppins',
      fontWeight: 800,
    },
  };

  return (
    <>
      <div
        className='activity__stage h6 mobile-show'
        style={{ position: 'relative' }}
      >
        History
        <span
          className={`refresh__balance mobile-show ${
            disableRefetch ? 'disable_btn' : ''
          }`}
          style={{ top: 0, right: 10 }}
        >
          <div onClick={refreshHistoryData}>
            <i
              className='fas fa-redo-alt'
              data-tip={'Refresh after 5s'}
              data-for='refresh'
            ></i>
          </div>
          {disableRefetch && (
            <ReactTooltip
              place='bottom'
              id='refresh'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles'
            />
          )}
        </span>
      </div>
      <div
        className='activity__sorting'
        style={{ justifyContent: 'space-between', position: 'relative' }}
      >
        <div className='activity__select notifications__select tablet-show'>
          <CustomSelect
            defaultValue={selectedFilter}
            options={sortingOptions}
            onChange={(data) => setSelectedFilter(data)}
          />
        </div>
        {openSideBar ? (
          <div
            className='activity__select notifications__select'
            style={{ marginLeft: 0 }}
          >
            <CustomSelect
              defaultValue={selectedFilter}
              value={selectedFilter}
              options={sortingOptions}
              onChange={(data) => {
                setSelectedFilter(data);
                setOpenSideBar(false);
                handleRemoveExtraQueryParams();
              }}
            />
          </div>
        ) : (
          <ActivitiesTags
            style={{ fontSize: openSideBar ? '12px' : 'inherit' }}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            options={sortingOptions}
            addTagclassName='activity__tags tablet-hide'
            addLinksclassName='activity__link'
          />
        )}
        {selectedFilter?.label === 'Withdrawals' &&
          !openWithdrawalInstruction && (
            <div
              style={{
                ...styles.dropDownContainer,
                marginTop: width > 700 ? 5 : 9,
              }}
              onClick={() => {
                setOpenWithdrawalInstruction((prev) => !prev);
              }}
            >
              <div
                className='wallet_deposit_container'
                style={{ justifyContent: 'center' }}
              >
                <button
                  className='card__btn btn btn_border'
                  onClick={() => setDepositOpen((prev) => !prev)}
                  style={{
                    ...styles.depositBtn,
                    position: 'absolute',
                    right: width > 700 ? 50 : 1,
                    minWidth: width > 700 ? '196px' : '196px',
                  }}
                >
                  <span
                    className='btn__text'
                    style={styles.depositInnerContainer}
                  >
                    <span style={{ position: 'relative', marginRight: 5 }}>
                      <i
                        className='fas fa-info-circle'
                        style={{ fontSize: 15 }}
                      ></i>
                    </span>
                    {width > 700 ? 'How withdrawal works?' : 'Withdrawal guide'}
                  </span>
                </button>
              </div>
            </div>
          )}
        {(selectedFilter?.label === 'Orders' ||
          selectedFilter?.label === 'Trades' ||
          selectedFilter?.label === 'Deposits' ||
          selectedFilter?.value === 'fast-withdraws') &&
          bannerDetails && (
            <div
              className='activity__select notifications__select'
              style={{ position: 'relative' }}
            >
              {/* <CustomSelect
              defaultValue={filterByOptions}
              options={filterOptions}
              onChange={(data) => {
                setFilterByOptions(data);
                setOpenSideBar(false);
              }}
            /> */}
              <FilterDropdown
                setDepositOpen={setDepositOpen}
                depositOpen={depositOpen}
                depositDropdownRef={depositDropdownRef}
                checkedValue={checkedValue}
                setCheckedValue={setCheckedValue}
                marketPair={marketPair}
                setMarketPair={setMarketPair}
                pendingOrderFilter={pendingOrderFilter}
                setPendingOrderFilter={setPendingOrderFilter}
                orderTypeFilter={orderTypeFilter}
                setOrderTypeFilter={setOrderTypeFilter}
                initialState={initialState}
                currentMarketPair={currentMarketPair}
                setCurrentMarketPair={setCurrentMarketPair}
                showResult={showResult}
                selectedFilter={selectedFilter}
                resetFilter={resetFilter}
                handleRemoveExtraQueryParams={handleRemoveExtraQueryParams}
                styles={styles}
                refetchData={refetchData}
                depositNetworkFilter={depositNetworkFilter}
                setDepositNetworkFilter={setDepositNetworkFilter}
              />
            </div>
          )}
        <div
          className={`refresh__balance mobile-hide ${
            disableRefetch ? 'disable_btn' : ''
          }`}
          style={{ top: '19px', right: '2px' }}
        >
          <div onClick={refreshHistoryData}>
            <i
              className='fas fa-redo-alt'
              data-tip={'Refresh after 5s'}
              data-for='refresh'
            ></i>
          </div>
          {disableRefetch && (
            <ReactTooltip
              place='bottom'
              id='refresh'
              type={isdark ? 'light' : 'dark'}
              className='tooltip_styles'
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ActivitiesHeader;
