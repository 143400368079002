import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useBalance, useSegment, useWalletMethods } from '../../../utils/hooks';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch } from 'react-redux';
import { CANCEL_ORDER_CLEANUP } from '../../../constants/exchangeConstant';
import { cancelOrder } from '../../../actions/exchangeAction';
import { toast } from 'react-toastify';
import { useState } from 'react';
import CancelConfirmationPopup from '../../exchange/cancel-confirmation-popup/cancel-confirmation-popup';
import {
  ChartSvgIcon,
  CheckSvgIcon,
  ChequeSvgIcon,
  FeeSvgIcon,
  images,
  WalletSvgIcon,
} from '../../../utils/image/img';
import { Image, Icon, IconBtn } from '../../../components';
import { etherscanDomain, networkName } from '../../../utils/urls';
import { NORMAL_WITHDRAWAL_CONFIRMATION_HOURS } from '../../../utils/constants';
import { transformString } from '../activities-history/activities-cell/activities-cell';

const classNameByWithdrawStatus = {
  RECEIVED: 'yellow',
  VERIFIED: 'yellow',
  INITIATED: 'yellow',
  PROCESSING: 'yellow',
  PROCESSED: 'yellow',
  IN_PROGRESS: 'yellow',
  SUCCESS: '',
  FAILED: 'red',
  INVALID: 'red',
  RESOLVED: 'yellow',
  CONFIRMING: 'yellow',
  TRANSFER_INITIATED: 'yellow',
};

function DepositStatusItem({ label, yes, no }) {
  return (
    <li>
      <i
        className={`fas  ${
          !no ? (yes ? 'fa-check-circle' : 'fa-stopwatch') : 'fa-times'
        }`}
        style={{
          color: !no ? (yes ? '#4fbf67' : '#ff9f38') : ' #ff5353',
          fontSize: yes ? 10 : 15,
        }}
      ></i>
      {label}
    </li>
  );
}

function DepositStatusIndicator({ sideBardata, buildStyles }) {
  return (
    <div
      className='activity__parameter'
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 20,
      }}
    >
      <div className='activity__box'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {sideBardata?.blockchain_deposit_status !== 'Success' &&
            sideBardata?.blockchain_deposit_status !== 'Failed' && (
              <p
                style={{
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 12,
                  marginTop: 20,
                  maxWidth: 300,
                }}
              >
                📝 Brine deposit confirmation may take upto 10 mins on{' '}
                {networkName}
              </p>
            )}
        </div>

        <div className='deposit_chart_container'>
          <div
            style={{
              width: 90,
              height: 90,
            }}
          >
            <CircularProgressbar
              value={
                sideBardata?.blockchain_deposit_status === 'Failed' ||
                sideBardata?.brine_deposit_status === 'Failed'
                  ? 0
                  : sideBardata?.blockchain_deposit_status === 'Success' &&
                    sideBardata?.brine_deposit_status === 'Success'
                  ? 100
                  : sideBardata?.blockchain_deposit_status === 'Success' &&
                    sideBardata?.brine_deposit_status !== 'Success'
                  ? 50
                  : 0
              }
              text={`${
                sideBardata?.blockchain_deposit_status === 'Failed' ||
                sideBardata?.brine_deposit_status === 'Failed'
                  ? 0
                  : sideBardata?.blockchain_deposit_status === 'Success' &&
                    sideBardata?.brine_deposit_status === 'Success'
                  ? 2
                  : sideBardata?.blockchain_deposit_status === 'Success' &&
                    sideBardata?.brine_deposit_status !== 'Success'
                  ? 1
                  : 0
              } of 2`}
              styles={buildStyles({
                textSize: '17px',
                pathColor: `#6c5dd3`,
                textColor: '#8173E0',
              })}
              maxValue={100}
            />
          </div>

          <div
            className='comparison'
            style={{
              marginTop: 20,
              width: '100%',
            }}
          >
            <div className='h5'>Status</div>
            {sideBardata?.blockchain_deposit_status === 'Failed' ||
            sideBardata?.brine_deposit_status === 'Failed' ? (
              <ul>
                <DepositStatusItem
                  label={'Blockchain deposit status'}
                  no={true}
                />
                <DepositStatusItem label={'Brine deposit status'} no={true} />
              </ul>
            ) : (
              <ul>
                <DepositStatusItem
                  label={'Blockchain deposit status'}
                  yes={sideBardata?.blockchain_deposit_status === 'Success'}
                />
                <DepositStatusItem
                  label={'Brine deposit status'}
                  yes={sideBardata?.brine_deposit_status === 'Success'}
                />
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ActivitiesSidebar({
  openState,
  sideBardata,
  handleClose,
  selectedFilterValue,
  setOpenSideBar,
  resetFilter,
  showResult,
}) {
  const {
    getInfoAboutCoinsByTokenId,
    getInfoAboutCoins,
    roundUpWithSymbol,
    preventScientific,
  } = useBalance();
  const [withdrawalBalance, setWithdrawalBalance] = useState(null);

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    response: cancelOrderResponse,
    loading: cancelOrderLoading,
    error: cancelOrderError,
  } = useSelector((state) => state.cancelOrder);

  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const { networkConfig } = useWalletMethods();
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollThreshold = 80;

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { sendTrackEvent } = useSegment();

  const getCoinsDetails = (symbol) => {
    const data = coinsDetails?.convertedArray.find(
      (item) => item.symbol === symbol
    );
    return data ? data : null;
  };

  const dispatch = useDispatch();

  const handleCancelOrder = (id) => {
    sendTrackEvent('orderCancellationInitiated');
    dispatch({
      type: CANCEL_ORDER_CLEANUP,
      payload: null,
    });
    // if (window.confirm('Are you sure to cancel this order')) {
    dispatch(cancelOrder(id));
    handleClose();
    // }
  };

  useEffect(() => {
    if (cancelOrderError) {
      sendTrackEvent('orderCancellationFailure', { error: cancelOrderError });
      toast.error(cancelOrderError);
    }
    if (cancelOrderResponse) {
      sendTrackEvent('orderCancellationSuccess', {
        error: cancelOrderError,
      });
      toast.success(cancelOrderResponse.message);
      setCancelId(null);
      setOpenCancelPopup(false);
      // showResult();
    }
    return () => {
      dispatch({
        type: CANCEL_ORDER_CLEANUP,
        payload: null,
      });
      setCancelId(null);
      setOpenCancelPopup(false);
    };
  }, [cancelOrderLoading, cancelOrderResponse, cancelOrderError]);

  const getCountDown = (time) => {
    let date1 = new Date(time);
    let date2 = new Date();
    let hoursDiff = Math.abs(date1 - date2) / 36e5;
    return { hoursDiff: Math.trunc(hoursDiff), date1, date2 };
  };

  useEffect(() => {
    if (selectedFilterValue == 'Withdraws') {
      getCountDown(sideBardata?.created_at);
    }
  }, [sideBardata, selectedFilterValue]);

  useEffect(() => {
    return () => setWithdrawalBalance(null);
  }, [sideBardata]);

  return (
    <div className={`activity__sidebar ${openState ? 'visible' : ''}`}>
      <div
        className={`activity__wrap ${
          scrollPosition > scrollThreshold ? 'fixed' : ''
        }`}
      >
        <button
          className='activity__close'
          onClick={() => {
            handleClose();
            setWithdrawalBalance(null);
          }}
        >
          <Icon icon='close' className='icon' />
        </button>
        <div className='activity__head'>
          {selectedFilterValue !== 'Orders' &&
            selectedFilterValue !== 'Deposits' &&
            selectedFilterValue !== 'Withdraws' &&
            selectedFilterValue !== 'fast-withdraws' && (
              <div className='activity__logo'>
                {selectedFilterValue !== 'Trades' ? (
                  <Image
                    src={getCoinsDetails(sideBardata?.currency)?.logo}
                    alt=''
                  />
                ) : (
                  <Image
                    src={getCoinsDetails(sideBardata?.fee_currency)?.logo}
                    alt=''
                  />
                )}
              </div>
            )}
          {selectedFilterValue === 'Deposits' && (
            <div className='activity__logo' style={{ position: 'relative' }}>
              <Image
                src={getInfoAboutCoinsByTokenId(sideBardata?.token_id)?.logo}
                alt=''
              />
              {networkConfig[sideBardata?.network?.toLowerCase()]?.image && (
                <Image
                  src={
                    networkConfig[sideBardata?.network?.toLowerCase()]?.image
                  }
                  alt=''
                  className='network__logo'
                />
              )}
            </div>
          )}
          {selectedFilterValue === 'fast-withdraws' && (
            <div className='activity__logo' style={{ position: 'relative' }}>
              <Image
                src={getInfoAboutCoins(sideBardata?.token_id)?.logo}
                alt=''
              />
              {networkConfig[sideBardata?.network?.toLowerCase()]?.image && (
                <Image
                  src={
                    networkConfig[sideBardata?.network?.toLowerCase()]?.image
                  }
                  alt=''
                  className='network__logo'
                />
              )}
            </div>
          )}

          {selectedFilterValue === 'Withdraws' && (
            <div className='activity__logo'>
              <Image
                src={getInfoAboutCoins(sideBardata?.token_id)?.logo}
                alt=''
              />
            </div>
          )}
          <div className='activity__details'>
            <div className='activity__info'>
              {selectedFilterValue === 'Trades'
                ? getCoinsDetails(sideBardata?.fee_currency)?.name
                : selectedFilterValue === 'Orders'
                ? getCoinsDetails(sideBardata?.market?.replace('inr', ''))?.name
                : selectedFilterValue === 'Deposits'
                ? getInfoAboutCoinsByTokenId(sideBardata?.token_id)?.name
                : selectedFilterValue === 'Withdraws' ||
                  selectedFilterValue === 'fast-withdraws'
                ? getInfoAboutCoins(sideBardata?.token_id)?.name
                : getCoinsDetails(sideBardata?.currency)?.name}
            </div>
            <div className='activity__date'>
              {moment(sideBardata?.created_at).format('llll')}
            </div>
          </div>
        </div>

        <div className='activity__line'>
          <div className='activity__money'>
            {selectedFilterValue === 'Trades'
              ? sideBardata.total
                ? parseFloat(sideBardata.total)?.toFixed(2)
                : '0.00'
              : selectedFilterValue === 'Orders'
              ? sideBardata.avg_price
                ? parseFloat(sideBardata.avg_price)?.toFixed(2)
                : '0.00'
              : sideBardata.amount
              ? selectedFilterValue === 'Deposits'
                ? isNaN(sideBardata?.amount)
                  ? 0
                  : preventScientific(
                      parseFloat(sideBardata.amount) /
                        parseFloat(
                          Math.pow(
                            10,
                            getInfoAboutCoinsByTokenId(sideBardata?.token_id)
                              ?.quanitization
                          )
                        )
                    )
                : selectedFilterValue === 'Withdraws' ||
                  selectedFilterValue === 'fast-withdraws'
                ? roundUpWithSymbol(sideBardata.amount, sideBardata.token_id)
                : parseFloat(sideBardata.amount)?.toFixed(2)
              : '0.00'}
          </div>

          <div className='activity__currency'>
            {selectedFilterValue === 'Trades'
              ? sideBardata?.market?.includes('usdt')
                ? 'USDT'
                : 'USDC'
              : selectedFilterValue === 'Orders'
              ? sideBardata?.market?.toUpperCase()
              : sideBardata.currency?.toUpperCase()}
          </div>
        </div>
        {selectedFilterValue === 'Deposits' && (
          <DepositStatusIndicator
            sideBardata={sideBardata}
            buildStyles={buildStyles}
          />
        )}
        {(selectedFilterValue === 'Withdraws' ||
          selectedFilterValue === 'fast-withdraws') && (
          <div className='activity__code word_wrap'>
            {sideBardata?.fee_currency === 'inr'
              ? sideBardata.rid
              : sideBardata.rid?.replace('?dt=', '/')}
          </div>
        )}
        {selectedFilterValue === 'Deposits' && (
          <div className='activity__code word_wrap'>{sideBardata.tid}</div>
        )}
        <div className='activity__parameters'>
          {selectedFilterValue !== 'Trades' &&
            selectedFilterValue !== 'Deposits' &&
            selectedFilterValue !== 'Withdraws' &&
            selectedFilterValue !== 'fast-withdraws' && (
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <CheckSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Status</div>
                  <div
                    className={`activity__status ${
                      sideBardata.state === 'rejected'
                        ? 'red'
                        : sideBardata.state === 'wait'
                        ? 'yellow'
                        : ''
                    }`}
                  >
                    {transformString(sideBardata.state?.toUpperCase())}
                  </div>
                </div>
              </div>
            )}

          {selectedFilterValue === 'Withdraws' && (
            <div className='activity__parameter'>
              <div className='activity__preview'>
                <CheckSvgIcon />
              </div>
              <div className='activity__box'>
                <div className='activity__category'>Status</div>
                <div
                  className={`activity__status ${
                    sideBardata.transaction_status === 'CONFIRMING' &&
                    getCountDown(sideBardata?.created_at).hoursDiff >=
                      NORMAL_WITHDRAWAL_CONFIRMATION_HOURS
                      ? 'green'
                      : classNameByWithdrawStatus[
                          sideBardata.transaction_status
                        ]
                  }`}
                >
                  {getCountDown(sideBardata?.created_at).hoursDiff >=
                    NORMAL_WITHDRAWAL_CONFIRMATION_HOURS &&
                  sideBardata.transaction_status === 'CONFIRMING'
                    ? 'READY'
                    : transformString(
                        sideBardata.transaction_status?.toUpperCase()
                      )}
                </div>
              </div>
            </div>
          )}

          {selectedFilterValue === 'fast-withdraws' && (
            <div className='activity__parameter'>
              <div className='activity__preview'>
                <CheckSvgIcon />
              </div>
              <div className='activity__box'>
                <div className='activity__category'>Status</div>
                <div
                  className={`activity__status ${
                    sideBardata.blockchain_status === 'SUCCESS' &&
                    sideBardata.transaction_status === 'TRANSFER_INITIATED'
                      ? 'green'
                      : classNameByWithdrawStatus[
                          sideBardata.transaction_status
                        ]
                  }`}
                >
                  {sideBardata.blockchain_status === 'SUCCESS' &&
                  sideBardata.transaction_status === 'TRANSFER_INITIATED'
                    ? 'SUCCESS'
                    : transformString(
                        sideBardata.transaction_status?.toUpperCase()
                      )}
                </div>
              </div>
            </div>
          )}

          {selectedFilterValue === 'Trades' && (
            <div className='tablet-show'>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <WalletSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Amount</div>
                  <div className='activity__value'>
                    {parseFloat(sideBardata.total)?.toFixed(2)}
                  </div>
                </div>
              </div>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <ChequeSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Price</div>
                  <div className='activity__value'>
                    {parseFloat(sideBardata.price)?.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedFilterValue === 'Orders' && (
            <div className='tablet-show'>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <WalletSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Amount</div>
                  <div className='activity__value'>
                    {parseFloat(sideBardata.executed_volume)?.toFixed(4)}
                  </div>
                </div>
              </div>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <ChequeSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Price</div>
                  <div className='activity__value'>
                    {parseFloat(sideBardata.avg_price)?.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {selectedFilterValue === 'Orders' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <Image src={images.wallet} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Amount</div>
                <div className="activity__value">
                  {sideBardata.origin_volume}
                </div>
              </div>
            </div>
          )} */}
          {/* {selectedFilterValue === 'Orders' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <Image src={CoinsImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Price</div>
                <div className="activity__value">
                  {sideBardata.price
                    ? sideBardata.price
                    : sideBardata?.avg_price}
                </div>
              </div>
            </div>
          )} */}
          {/* {selectedFilterValue !== 'Orders' &&
            selectedFilterValue !== 'Deposits' &&
            selectedFilterValue !== 'Withdraws' && (
              <div className="activity__parameter">
                <div className="activity__preview">
                  <Image src={CoinsImage} alt="" />
                </div>
                <div className="activity__box">
                  <div className="activity__category">Amount</div>
                  <div className="activity__value">{sideBardata.amount}</div>
                </div>
              </div>
            )} */}
          {/* {selectedFilterValue == 'Withdraws' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <Image src={CoinsImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Amount</div>
                <div className="activity__value">
                  {roundUpWithSymbol(sideBardata.amount, sideBardata.token_id)}
                </div>
              </div>
            </div>
          )} */}
          {/* {selectedFilterValue == 'Deposits' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <Image src={CoinsImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Amount</div>
                <div className="activity__value">
                  {sideBardata.amount /
                    parseFloat(
                      Math.pow(
                        10,
                        getInfoAboutCoinsByTokenId(sideBardata.token_id)
                          ?.quanitization
                      )
                    )}
                </div>
              </div>
            </div>
          )} */}
          {selectedFilterValue !== 'Trades' &&
            selectedFilterValue !== 'Orders' &&
            selectedFilterValue !== 'Withdraws' &&
            selectedFilterValue !== 'fast-withdraws' && (
              <>
                <div className='activity__parameter'>
                  <div className='activity__preview'>
                    {/* <WalletSvgIcon /> */}
                    <WalletSvgIcon />
                  </div>
                  <div className='activity__box'>
                    <div className='activity__category'>Address</div>
                    {(selectedFilterValue === 'Withdraws' ||
                      selectedFilterValue === 'fast-withdraws') && (
                      <div className='activity__value'>
                        {sideBardata?.fee_currency === 'inr'
                          ? sideBardata.rid
                          : sideBardata.rid?.replace('?dt=', '/')}
                      </div>
                    )}
                    {selectedFilterValue === 'Deposits' && (
                      <div
                        className='activity__value'
                        style={{ fontSize: 14, minWidth: '20px' }}
                      >
                        {sideBardata.token_id ? sideBardata.token_id : 'fiat'}
                      </div>
                    )}
                  </div>
                </div>
                <div className='activity__parameter'>
                  <div className='activity__preview'>
                    <ChequeSvgIcon />
                  </div>
                  <div className='activity__box'>
                    <div className='activity__category'>Transaction ID</div>
                    {(selectedFilterValue === 'Withdraws' ||
                      selectedFilterValue === 'fast-withdraws') && (
                      <div className='activity__value' style={{ fontSize: 14 }}>
                        {sideBardata.blockchain_txid
                          ? sideBardata.blockchain_txid
                          : '---'}
                      </div>
                    )}

                    {selectedFilterValue === 'Deposits' && (
                      <>
                        <div className='activity__value'>
                          <a
                            href={`${
                              networkConfig[sideBardata?.network?.toLowerCase()]
                                ?.blockExplorerUrl
                            }tx/${sideBardata.deposit_blockchain_hash}`}
                            target='_blank'
                            referrerPolicy='no-referrer'
                            style={{ fontSize: 14 }}
                          >
                            {sideBardata.deposit_blockchain_hash
                              ? sideBardata.deposit_blockchain_hash
                              : '--'}{' '}
                          </a>
                        </div>
                        <a
                          style={{
                            marginTop: 12,
                            color: '#8173E0',
                            fontFamily: 'Poppins',
                            cursor: 'pointer',
                            fontSize: 14,
                          }}
                          target='_blank'
                          referrerPolicy='no-referrer'
                          href={`${
                            networkConfig[sideBardata?.network?.toLowerCase()]
                              ?.blockExplorerUrl
                          }tx/${sideBardata.deposit_blockchain_hash}`}
                        >
                          View on block explorer
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          {selectedFilterValue === 'fast-withdraws' && (
            <>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <FeeSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Fee Amount</div>
                  <div className='activity__value'>
                    {sideBardata?.fee_amount}{' '}
                    {sideBardata?.token_id?.toUpperCase()}
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedFilterValue === 'fast-withdraws' &&
            sideBardata.l1_withdrawal_blockchain_hash &&
            sideBardata.l1_withdrawal_blockchain_hash !== '' && (
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <ChequeSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Transaction ID</div>
                  {selectedFilterValue === 'Withdraws' && (
                    <div className='activity__value' style={{ fontSize: 14 }}>
                      {sideBardata.blockchain_txid
                        ? sideBardata.blockchain_txid
                        : 'fiat'}
                    </div>
                  )}

                  {selectedFilterValue === 'fast-withdraws' &&
                    sideBardata.l1_withdrawal_blockchain_hash &&
                    sideBardata.l1_withdrawal_blockchain_hash !== '' && (
                      <>
                        <div
                          className='activity__value'
                          style={{ fontSize: 14 }}
                        >
                          <a
                            href={`${
                              networkConfig[sideBardata?.network?.toLowerCase()]
                                ?.blockExplorerUrl
                            }tx/${sideBardata.l1_withdrawal_blockchain_hash}`}
                            target='_blank'
                            style={{ fontSize: 14 }}
                            referrerPolicy='no-referrer'
                          >
                            {sideBardata.l1_withdrawal_blockchain_hash
                              ? sideBardata.l1_withdrawal_blockchain_hash
                              : 'fiat'}
                          </a>
                        </div>
                        <a
                          style={{
                            marginTop: 12,
                            color: '#8173E0',
                            fontFamily: 'Poppins',
                            cursor: 'pointer',
                            fontSize: 14,
                          }}
                          target='_blank'
                          referrerPolicy='no-referrer'
                          href={`${
                            networkConfig[sideBardata?.network?.toLowerCase()]
                              ?.blockExplorerUrl
                          }tx/${sideBardata.l1_withdrawal_blockchain_hash}`}
                        >
                          View on block explorer
                        </a>
                      </>
                    )}
                </div>
              </div>
            )}

          {selectedFilterValue === 'Trades' && (
            <>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <FeeSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Fee Amount</div>
                  <div className='activity__value'>
                    {sideBardata?.fee_amount}{' '}
                    {sideBardata?.fee_currency?.toUpperCase()}
                  </div>
                </div>
              </div>
            </>
          )}

          {selectedFilterValue === 'Orders' && (
            <>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <ChartSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Order Type</div>
                  <div className='activity__value'>
                    {sideBardata.ord_type?.toUpperCase()}
                  </div>
                </div>
              </div>
            </>
          )}

          {selectedFilterValue === 'Orders' && (
            <>
              <div className='activity__parameter'>
                <div className='activity__preview'>
                  <FeeSvgIcon />
                </div>
                <div className='activity__box'>
                  <div className='activity__category'>Avg Price</div>
                  <div className='activity__value'>{sideBardata.avg_price}</div>
                </div>
              </div>
            </>
          )}

          {(selectedFilterValue === 'Withdraws' ||
            selectedFilterValue === 'fast-withdraws') &&
            sideBardata.transaction_status === 'CONFIRMING' && (
              <>
                {getCountDown(sideBardata?.created_at).hoursDiff <
                  NORMAL_WITHDRAWAL_CONFIRMATION_HOURS &&
                  withdrawalBalance < sideBardata.amount &&
                  !withdrawalBalance && (
                    <div className='activity_processing'>
                      <i className='fas fa-cog rotate'></i>
                      <span>
                        We are processing your request. It may take{' '}
                        {NORMAL_WITHDRAWAL_CONFIRMATION_HOURS -
                          getCountDown(sideBardata?.created_at).hoursDiff}{' '}
                        {NORMAL_WITHDRAWAL_CONFIRMATION_HOURS -
                          getCountDown(sideBardata?.created_at).hoursDiff ==
                        1
                          ? 'hour'
                          : 'hours'}{' '}
                        to complete
                      </span>
                    </div>
                  )}
                {/* {withdrawalBalance != null && (
                  <div className="card__btns card__btns_mt20">
                    <button
                      disabled={
                        withdrawalBalance < sideBardata.amount &&
                        getCountDown(sideBardata?.created_at).hoursDiff < 6
                      }
                      className="card__btn btn btn_orange"
                      onClick={() =>
                        handleAcceptIt(
                          Number(
                            roundUpWithSymbol(
                              sideBardata.amount,
                              sideBardata.token_id
                            )
                          )
                        )
                      }
                    >
                      <Icon icon="withdraw" className="icon" />
                      <span className="btn__text">Redeem it</span>
                    </button>
                    <button
                      className="card__btn btn btn_border"
                      onClick={handleClose}
                    >
                      <IconBtn
                        className="icon"
                        iconName="deposit"
                        text="Close"
                      />
                    </button>
                  </div>
                )} */}
              </>
            )}
          {selectedFilterValue === 'Orders' && sideBardata.state === 'wait' && (
            <div className='card__btns card__btns_mt40'>
              <button
                className='card__btn btn btn_orange'
                onClick={() => {
                  setCancelId(sideBardata.id);
                  setOpenCancelPopup(true);
                }}
              >
                <Icon icon='withdraw' className='icon' />
                <span className='btn__text'>Cancel</span>
              </button>
              <button
                className='card__btn btn btn_border'
                onClick={() => {
                  handleClose();
                }}
              >
                <IconBtn className='icon' iconName='deposit' text='Close' />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='referral__popup'>
        <CancelConfirmationPopup
          openCancelPopup={openCancelPopup}
          setOpenCancelPopup={setOpenCancelPopup}
          setCancelId={setCancelId}
          cancelId={cancelId}
          handleCancelOrder={handleCancelOrder}
        />
      </div>
    </div>
  );
}

export default ActivitiesSidebar;
