import { useHistory } from 'react-router-dom';
import { Image } from '../../../../components';
import { transformString } from '../../../activities/activities-history/activities-cell/activities-cell';

function LatestActivitiesList({ data, isdeposit, isfastWithdrawal }) {
  const history = useHistory();

  return (
    <div
      className='card__item latest_history'
      onClick={() =>
        history.push(
          `/history?tab=${
            isdeposit
              ? 'deposits'
              : isfastWithdrawal
              ? 'fast-withdrawals'
              : 'withdrawals'
          }&value=${isdeposit ? data.hash : data.id}`
        )
      }
    >
      <div className={`card__icon ${data.bg}`}>
        <Image src={data.image} alt='' />
      </div>
      <div className='card__details'>
        <div className='card__line'>
          <div
            className='card__category'
            style={{ fontSize: isdeposit ? 'inherit' : '14px' }}
          >
            {data.category}
          </div>
          <div className='card__date'>{data.date}</div>
        </div>
        {!isdeposit ? (
          <div
            className={`card__status ${
              data.status == 'READY' || data?.status === 'SUCCESS'
                ? 'color-green'
                : 'color-orange'
            } `}
          >
            {transformString(data?.status)}{' '}
            {data.status !== 'READY' && data?.status !== 'SUCCESS' && (
              <i className='fas fa-cog rotate'></i>
            )}
          </div>
        ) : (
          <>
            <div
              className={`card__status ${
                data?.status === 'Failed'
                  ? 'color-red'
                  : data.status === 'Success'
                  ? 'color-green'
                  : 'color-orange'
              } `}
            >
              {transformString(data?.status)}{' '}
              {data.status === 'Pending' && (
                <i className='fas fa-cog rotate'></i>
              )}
            </div>
          </>
        )}

        <div className='card__price'>{data.price}</div>
      </div>
    </div>
  );
}

export default LatestActivitiesList;
