export const lazyRetry = (componentName, componentImport) => {
  const sessionStorageKey = `brine-retry-lazy-refreshed-${componentName}`;

  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionStorageKey) || 'false'
    );

    const retryImport = () => {
      componentImport()
        .then((component) => {
          window.sessionStorage.setItem(sessionStorageKey, 'false');
          resolve(component);
        })
        .catch((error) => {
          if (!hasRefreshed) {
            window.sessionStorage.setItem(sessionStorageKey, 'true');
            return window.location.reload();
          }
          reject(error);
        });
    };

    retryImport();
  });
};
