import React from 'react';

function PurpleCard({ children, ...otherStyle }) {
  return (
    <div
      className='deposit__card'
      style={{
        marginTop: 30,
      }}
      {...otherStyle}
    >
      {children}
    </div>
  );
}

export default PurpleCard;
