export const GET_SALT_LEADERBOARD_REQUEST = 'GET_SALT_LEADERBOARD_REQUEST';
export const GET_SALT_LEADERBOARD_SUCCESS = 'GET_SALT_LEADERBOARD_SUCCESS';
export const GET_SALT_LEADERBOARD_FAIL = 'GET_SALT_LEADERBOARD_FAIL';

export const GET_SALT_SCORE_REQUEST = 'GET_SALT_SCORE_REQUEST';
export const GET_SALT_SCORE_SUCCESS = 'GET_SALT_SCORE_SUCCESS';
export const GET_SALT_SCORE_FAIL = 'GET_SALT_SCORE_FAIL';

export const GET_SALT_SCORE_FLAG_REQUEST = 'GET_SALT_SCORE_FLAG_REQUEST';
export const GET_SALT_SCORE_FLAG_SUCCESS = 'GET_SALT_SCORE_FLAG_SUCCESS';
export const GET_SALT_SCORE_FLAG_FAIL = 'GET_SALT_SCORE_FLAG_FAIL';
